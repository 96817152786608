import type {
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';
import { css } from 'styled-components';

import type { ThemeColor } from 'storybook/stories/theme';

export type MaterialSymbolOptions = {
  name: string;
  position?: 'before' | 'after';
  color?: ThemeColor;
  weight?: number;
  fill?: number;
  grade?: number;
  opticalSize?: number;
  size?: string;
  /**
   * `FlattenSimpleInterpolation` is the result of a Styled `css` literal.
   * `false` allows conditionals, e.g. `disabled && 'opacity: 0.2'`
   */
  additionalStyling?:
    | string
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
    | FlattenSimpleInterpolation
    | false;
};

// Material Symbols mixin for iconography
// https://developers.google.com/fonts/docs/material_symbols?hl=en
const materialSymbol = ({
  name,
  position = 'before',
  color,
  weight = 300,
  fill = 0,
  grade = 0,
  opticalSize = 24,
  size = '20px',
  additionalStyling,
}: MaterialSymbolOptions) => css`
  &::${position} {
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: inherit;

    content: '${name}';
    font-variation-settings:
      'FILL' ${fill},
      'wght' ${weight},
      'GRAD' ${grade},
      'opsz' ${opticalSize};

    color: ${({ theme }) => (color ? theme.color[color] : 'inherit')};
    font-size: ${size};
    width: ${size};
    height: ${size};

    ${({ theme }) => `
      font-family: ${theme.font.icon};
    `}

    ${additionalStyling}
  }
`;

export default materialSymbol;
