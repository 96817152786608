import { getRequest, postRequest } from 'utils/request';

export const getBuyerOrderReturns = (buyerOrderId, sellerOrderId) =>
  getRequest(`/buyer/orders/${buyerOrderId}/seller-orders/${sellerOrderId}/returns`);

export const requestReturn = (buyerOrderId, sellerOrderId, itemId, data) =>
  postRequest(
    `/buyer/orders/${buyerOrderId}/seller-orders/${sellerOrderId}/items/${itemId}/return`,
    data
  );

// requestSellerOrderReturn is similar to requestReturn, but it's used when the
// order is only a seller order and does not contain a buyer order ID.
export const requestSellerOrderReturn = (sellerOrderId, itemId, data) =>
  postRequest(`/buyer/seller-orders/${sellerOrderId}/items/${itemId}/return`, data);
