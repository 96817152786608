import _isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import Button from 'components/Common/Button';
import UpdatedIndicator from 'components/Common/UpdatedIndicator';
import { getAttributesPatch } from 'hooks/products/useEditProduct';
import { parseFieldPathForAttribute } from 'hooks/products/useProductValidationErrors';
import usePrevious from 'hooks/usePrevious';

import AttributeRow from './AttributeRow';
import CardProductValidationAlert from './CardProductValidationAlert';
import './ProductAttributes.css';

const Wrapper = styled.div`
  border: 1px solid transparent;

  ${({ theme, hasErrors }) =>
    hasErrors &&
    css`
      border-color: ${theme.color.warning500};
    `}
`;

/**
 * ProductAttributes manages the state and display of the product scoped attributes.
 * If a user can edit the attributes, this component holds the local edits and reports the
 * diff back to the parent component via the onEditAttributes to be used in update PATCH
 */
const ProductAttributes = ({
  attributes = {},
  canEdit,
  onEditAttributes,
  clearEditsAt,
  hasEdits,
  validationErrors,
}) => {
  const prevValidationErrors = usePrevious(validationErrors);

  // create a work in progress copy of the attributes object
  const [editList, setEditList] = useState(() =>
    Object.keys(attributes).map((name) => ({ name, value: attributes[name].value, id: uuidv4() }))
  );

  // hold copy of the last clearEditsAt to compare when it changes (ignores other dependencies)
  const clearEditsAtRef = useRef(clearEditsAt);

  useEffect(() => {
    // used to reset local edits only when the parent has specifically said to do so by changing the clearEditsAt
    // reset back to product.attributes values
    if (clearEditsAtRef.current !== clearEditsAt) {
      setEditList(
        Object.keys(attributes).map((name) => ({
          name,
          value: attributes[name].value,
          id: uuidv4(),
        }))
      );
      clearEditsAtRef.current = clearEditsAt;
    }
  }, [attributes, setEditList, clearEditsAt]);

  useEffect(() => {
    if (prevValidationErrors !== validationErrors && !_isEmpty(validationErrors) && canEdit) {
      // only run if validationErrors have changed
      // check validation rules and stub attributes into editList if they are in validationErrors and not already in list
      const missingAttributeMap = {};
      validationErrors.forEach((err) => {
        const attrName = parseFieldPathForAttribute(err.fieldPath);
        if (!attrName) return;
        const inEditList = editList.some((attr) => attr.name === attrName);
        if (!inEditList) {
          missingAttributeMap[attrName] = true;
        }
      });
      const stubAttrs = Object.keys(missingAttributeMap).map((name) => ({
        name,
        value: '',
        id: uuidv4(),
      }));
      if (stubAttrs.length > 0) {
        setEditList((prevList) => [...prevList, ...stubAttrs]);
      }
    }
  }, [validationErrors, editList, prevValidationErrors, canEdit]);

  const onChangeAttribute = (id, field) => (value) => {
    const newEditList = editList.map((attr) =>
      attr.id !== id ? attr : { ...attr, [field]: value }
    );
    setEditList(newEditList);
    onEditAttributes(getAttributesPatch(attributes, newEditList));
  };

  const onAddAttribute = () => {
    const newEditList = [...editList, { name: '', value: '', id: uuidv4() }];
    setEditList(newEditList);
    onEditAttributes(getAttributesPatch(attributes, newEditList));
  };

  const onDeleteAttribute = (id) => {
    const newEditList = editList.filter((attr) => attr.id !== id);
    setEditList(newEditList);
    onEditAttributes(getAttributesPatch(attributes, newEditList));
  };

  return (
    <Wrapper className="card overflow-auto" hasErrors={validationErrors?.length > 0}>
      <div className="d-flex justify-content-between align-items-center p-4">
        <h4 className="mb-0">
          Additional Attributes{' '}
          {hasEdits && (
            <UpdatedIndicator aria-label="product attributes updated" className="ml-1" />
          )}
        </h4>
        {canEdit ? (
          <Button
            size="sm"
            color="white"
            onClick={onAddAttribute}
            id="add-product-attribute-btn"
            aria-label="add product attribute"
          >
            Add
          </Button>
        ) : null}
      </div>

      <div className="card-body pt-0">
        {editList.length === 0 ? (
          <p className="mb-2 text-muted" data-testid="no-product-attribute-msg">
            This product does not have any additional attributes
          </p>
        ) : (
          <>
            <div className="row mb-3">
              <div className="col-6">
                <p id="attribute-name-label" className="mb-0" style={{ fontWeight: 500 }}>
                  Name
                </p>
              </div>
              <div className="col-6">
                <p id="attribute-value-label" className="mb-0" style={{ fontWeight: 500 }}>
                  Value
                </p>
              </div>
            </div>

            {editList.map((attr, idx) => {
              if (!attr) return null;
              return (
                <AttributeRow
                  key={attr.id}
                  name={attr.name}
                  value={attr.value}
                  onChangeName={onChangeAttribute(attr.id, 'name')}
                  onChangeValue={onChangeAttribute(attr.id, 'value')}
                  onDelete={() => onDeleteAttribute(attr.id)}
                  canEdit={canEdit}
                  index={idx}
                />
              );
            })}
          </>
        )}
        {validationErrors?.length > 0 && <CardProductValidationAlert errors={validationErrors} />}
      </div>
    </Wrapper>
  );
};

export default ProductAttributes;
