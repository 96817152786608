import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import type React from 'react';
import { useState } from 'react';
import type { SingleValue } from 'react-select';
import ComboInput from 'storybook/stories/cells/ComboInput';
import Body from 'storybook/stories/molecules/Body';
import Icon from 'storybook/stories/molecules/Icon';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import type { ReactSelectOption } from 'types/general';
import type { Partner } from 'types/models/partner';
import { PerOrderFeeType } from 'types/models/partner';
import type { UpdatePartnerParams } from 'utils/api/partners';
import { getPriceLists } from 'utils/api/priceLists';
import { getCurrencySymbol, toMoney } from 'utils/currencies';

interface GetPerOrderFeeOptionsProps {
  currency: string | undefined;
}

const getPerOrderFeeOptions = ({ currency }: GetPerOrderFeeOptionsProps) => {
  const definedCurrency = getCurrencySymbol(currency || 'USD');
  return [
    {
      description: 'Flat Fee',
      label: `${definedCurrency}`,
      value: PerOrderFeeType.Fixed,
    },
    {
      description: 'Percent',
      label: '%',
      value: PerOrderFeeType.Percentage,
    },
  ];
};

export interface SellerBillingSettingsProps {
  partner: Partner;
  updatePartner: (params: UpdatePartnerParams) => void;
}

const SellerBillingSettings = ({ partner, updatePartner }: SellerBillingSettingsProps) => {
  // State
  const currentPriceList = partner.pricingId ?? '';
  const fetchingPriceLists = useQuery({
    queryKey: ['fetchingPriceLists'],
    queryFn: () => getPriceLists({ page: 0, limit: 250 }),
  });

  const priceLists = fetchingPriceLists.data?.data ?? [];
  const selectedPriceList = priceLists.find((priceList) => priceList.id === currentPriceList);
  const [perOrderFeeType, setPerOrderFeeType] = useState(
    partner.perOrderFeeSettings?.type || PerOrderFeeType.Fixed
  );
  const [perOrderFeePercent, setPerOrderFeePercent] = useState(
    partner.perOrderFeeSettings?.percentage || 0
  );
  const [perOrderFeeFixed, setPerOrderFeeFixed] = useState(
    partner.perOrderFeeSettings?.flatFee.amount || 0
  );
  const selectedPerOrderFeeType = getPerOrderFeeOptions({
    currency: selectedPriceList?.sellerCurrency,
  }).find((option) => option.value === perOrderFeeType);

  const selectedPerOrderFee =
    perOrderFeeType === PerOrderFeeType.Percentage ? perOrderFeePercent : perOrderFeeFixed / 100;

  // Helpers

  const onPerOrderFeeTypeChange = (option: SingleValue<ReactSelectOption>) => {
    if (!option || !selectedPriceList) return;

    const updatedType =
      option?.value === PerOrderFeeType.Percentage
        ? PerOrderFeeType.Percentage
        : PerOrderFeeType.Fixed;

    setPerOrderFeeType(updatedType);
    updatePartner({ perOrderFeeSettings: { type: updatedType } });
  };

  const onPerOrderFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !selectedPriceList) return;

    switch (selectedPerOrderFeeType?.value) {
      case PerOrderFeeType.Fixed:
        // eslint-disable-next-line no-case-declarations
        const newPerOrderFeeFixed = toMoney(
          parseFloat(e.target.value) || 0,
          selectedPriceList.sellerCurrency
        );
        setPerOrderFeeFixed(newPerOrderFeeFixed.amount);
        updatePartner({
          perOrderFeeSettings: { type: PerOrderFeeType.Fixed, flatFee: newPerOrderFeeFixed },
        });
        return;
      case PerOrderFeeType.Percentage:
        // eslint-disable-next-line no-case-declarations
        const newPerOrderFeePercentage = parseInt(e.target.value, 10) || 0;
        setPerOrderFeePercent(newPerOrderFeePercentage);
        updatePartner({
          perOrderFeeSettings: {
            type: PerOrderFeeType.Percentage,
            percentage: newPerOrderFeePercentage,
          },
        });
        return;
      default:
        // eslint-disable-next-line no-case-declarations
        const newPerOrderFeeDefault = { amount: 0, currency: selectedPriceList?.sellerCurrency };
        setPerOrderFeeFixed(newPerOrderFeeDefault.amount);
        updatePartner({
          perOrderFeeSettings: { type: PerOrderFeeType.Fixed, flatFee: newPerOrderFeeDefault },
        });
    }
  };

  return (
    <Flex column gap="4px">
      <Flex.Item>
        <Icon
          as={SupportLink}
          article="d9n6q9u718"
          name="help"
          size="16px"
          color="blue500"
          position="after"
        >
          <Body variant="Body/Header" color="bodyTextSecondary">
            Per-Order Fee
          </Body>
        </Icon>
      </Flex.Item>

      <Flex.Item>
        <ComboInput>
          <ComboInput.Input
            placeholder="Per-order fee"
            onChange={onPerOrderFeeChange}
            value={selectedPerOrderFee}
          />
          <ComboInput.Select
            defaultValue={getPerOrderFeeOptions({ currency: selectedPriceList?.sellerCurrency })[0]}
            options={getPerOrderFeeOptions({ currency: selectedPriceList?.sellerCurrency })}
            onChange={onPerOrderFeeTypeChange}
            value={selectedPerOrderFeeType}
          />
        </ComboInput>
      </Flex.Item>
    </Flex>
  );
};

export default SellerBillingSettings;
