import Flex from '@react-css/flex';
import Body from 'storybook/stories/molecules/Body';
import Icon from 'storybook/stories/molecules/Icon';
import styled, { css } from 'styled-components';
import { useBoolean } from 'usehooks-ts';
import { prettyDate, prettyDateTime } from 'utils/date';

const TimelineIcon = styled(Icon)`
  transform: translateX(calc(-50% - 0.5px));
  padding: 2px;
`;

const IconContainer = styled(Flex.Item)`
  border-left: 1px solid ${({ theme }) => theme.color.gray200};
`;

const EventContentWrapper = styled.div`
  padding-bottom: 8px;
`;

export type EventProps = {
  iconName: string;
  title: React.ReactNode;
  children?: React.ReactNode;
  timestamp: string;
  shouldRenderTime?: boolean;
};

/**
 * An event within the timeline. Intended to be used within the Timeline component.
 */
const Event = ({ iconName, title, children, timestamp, shouldRenderTime = true }: EventProps) => {
  const shouldShowBody = useBoolean(false);
  const onClick = (e: React.MouseEvent) => {
    // If event target is a link, ignore
    if (e.target instanceof HTMLAnchorElement) return;
    shouldShowBody.toggle();
  };

  return (
    <Flex>
      <IconContainer>
        <TimelineIcon name={iconName} color="iconDefault" />
      </IconContainer>

      <EventContentWrapper>
        <Flex column gap="8px">
          <Flex column gap="4px">
            <Flex alignItemsCenter gap="8px" onClick={onClick}>
              <Body variant="Body/Regular" color="bodyTextPrimary">
                {title}
              </Body>
              {/* If the event has a body, hint that it is expandable */}
              {children && <Icon name={shouldShowBody.value ? 'expand_less' : 'expand_more'} />}
            </Flex>

            <Body variant="Inputs/Input Message" color="bodyTextSecondary">
              {shouldRenderTime ? prettyDateTime(timestamp) : prettyDate(timestamp)}
            </Body>
          </Flex>

          {shouldShowBody.value && children && (
            <Body variant="Body/Body Small" color="bodyTextSecondary">
              {children}
            </Body>
          )}
        </Flex>
      </EventContentWrapper>
    </Flex>
  );
};

export type TimelineProps = {
  hasMore?: boolean;
  bgColor?: string;
};

const Base = styled.div<TimelineProps>`
  ${({ hasMore }) =>
    hasMore
      ? css`
          // Last event gets a dashed line
          *:last-child ${IconContainer} {
            border-left: 1px dashed ${({ theme }) => theme.color.gray200};
          }
        `
      : css`
          // Make the last event's line invisible
          // borders affect layout
          *:last-child ${IconContainer} {
            border-left: 1px solid #00000000;
          }
        `}

  // If a background color is provided, apply it to the timeline
  ${({ bgColor }) =>
    bgColor
      ? css`
          ${TimelineIcon} {
            background-color: ${bgColor};
          }
        `
      : css`
          ${TimelineIcon} {
            background-color: ${({ theme }) => theme.color.bodyBackground};
          }
        `}
`;

const Timeline = Object.assign(Base, {
  Event,
});

export default Timeline;
