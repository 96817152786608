import Flex from '@react-css/flex';

import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BulkEditPriceListContext, TableViewOptions } from './context';

import Dropdown from 'storybook/stories/cells/Dropdown';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import RadioButton from 'storybook/stories/molecules/RadioButton';

const ViewByDropdown = () => {
  const { viewMode, setViewMode } = useContext(BulkEditPriceListContext);

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      viewMode,
    },
  });

  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        <TertiaryButton $iconName="visibility" kind="neutral">
          View By
        </TertiaryButton>
      </Dropdown.Trigger>
      <Dropdown.Content>
        {({ close }) => {
          return (
            <form
              onSubmit={handleSubmit((data) => {
                setViewMode(data.viewMode);
                close();
              })}
            >
              <Flex column gap="32px">
                <Dropdown.Header iconName="visibility" heading="View By" />

                <Controller
                  name="viewMode"
                  control={control}
                  render={({ field }) => (
                    <Flex column gap="16px">
                      <RadioButton
                        name="viewMode"
                        checked={field.value === TableViewOptions.TITLES}
                        value={TableViewOptions.TITLES}
                        onChange={field.onChange}
                      >
                        Product Name & Variant
                      </RadioButton>

                      <RadioButton
                        name="viewMode"
                        checked={field.value === TableViewOptions.SKU}
                        value={TableViewOptions.SKU}
                        onChange={field.onChange}
                      >
                        SKU
                      </RadioButton>
                    </Flex>
                  )}
                />

                <Flex justifyEnd gap="8px">
                  <PrimaryButton type="submit">Apply</PrimaryButton>
                </Flex>
              </Flex>
            </form>
          );
        }}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default ViewByDropdown;
