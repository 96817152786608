import styled, { css } from 'styled-components';

import materialSymbol from 'storybook/mixins/materialSymbol';
import resetButtonStyles from 'storybook/mixins/resetButtonStyles';
import typography from 'storybook/mixins/typography';
import Table from 'storybook/stories/cells/Table';
import Body from 'storybook/stories/molecules/Body';
import Icon from 'storybook/stories/molecules/Icon';

export const MainTableWrapper = styled.div`
  ${Table.TH} {
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0.06em;
    padding-bottom: 0;

    ${({ theme }) => css`
      font-family: ${theme.font.secondary};
      color: ${theme.color.gray600};
    `}
  }

  ${Table.TD} {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    white-space: nowrap;

    ${({ theme }) => css`
      color: ${theme.color.gray900};
    `}
    strong {
      font-weight: 700;
    }
  }
`;

export const ProductVariantTableWrapper = styled.div`
  ${Table.TH} {
    font-size: 11px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    padding-top: 8px;
    padding-bottom: 8px;

    ${({ theme }) => css`
      border-top: 1px solid ${theme.color.gray200};
      border-bottom: 1px solid ${theme.color.gray200};
      font-family: ${theme.font.secondary};
      color: ${theme.color.gray600};
    `}
  }

  ${Table.TD} {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    vertical-align: top;

    ${({ theme }) => css`
      color: ${theme.color.gray900};
    `}
  }

  ${Table.TH}:first-of-type, ${Table.TD}:first-of-type {
    padding-left: 80px;
  }

  ${Table.TH}:last-of-type, ${Table.TD}:last-of-type {
    padding-right: 40px;
  }
`;

export const TableMeta = styled.span`
  display: block;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  ${({ theme }) => css`
    font-family: ${theme.font.secondary};
    color: ${theme.color.gray600};
  `}
`;

export const VerticalDivider = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.gray300};
`;

interface ViewVariantsButtonProps {
  isVariantTableVisible: boolean;
}

export const ViewVariantsButton = styled.button<ViewVariantsButtonProps>`
  ${resetButtonStyles}

  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  ${({ theme }) => css`
    color: ${theme.color.blue600};
    font-family: ${theme.font.secondary};
  `}

  ${({ isVariantTableVisible }) =>
    materialSymbol({
      name: isVariantTableVisible ? 'expand_less' : 'expand_more',
      position: 'after',
    })}
`;

export const DateText = styled.span`
  ${typography('Chips/Chip Text Small')};
  color: ${({ theme }) => theme.color.bodyTextSecondary};
`;

export const ClickableBodyText = styled(Body)`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.bodyTextLinksHover};
    text-decoration: underline;
  }
`;

export const PricingInputWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const InnerInputSymbol = styled.span`
  position: absolute;
  left: 12px;
  top: 50%;
  font-size: 15px;
  transform: translateY(-50%);
`;

export const PricingInput = styled.input`
  max-width: 125px;
  border-radius: 25px;
  padding: 5px 5px 5px 50px;
  border: 1px solid transparent;

  font-size: 14px;

  background: ${({ theme }) => theme.color.gray100};
`;

export const SubtractSymbol = styled.div`
  width: 30px;
  height: 2px;
  background-color: ${({ theme }) => theme.color.gray300};
`;

export const EqualSymbolLine = styled.div`
  width: 20px;
  height: 2px;
  background-color: ${({ theme }) => theme.color.gray300};
`;

export const EqualSymbolContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const PartnerName = styled(Body).attrs(({ children }) => ({
  variant: 'Body/Regular Bold',
  title: children,
}))`
  display: inline-block;
  width: 224px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LinkIcon = styled(Icon).attrs(() => ({
  name: 'link',
}))`
  &:hover {
    text-decoration: none;
  }
`;
