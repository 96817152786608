import Flex from '@react-css/flex';
import { isEmpty, isFinite } from 'lodash';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ShortInput } from 'containers/PriceListPage/Settings/Shared.styles';
import { validateRoundingRuleValue } from 'containers/PriceListsPage/CreatePriceList/CurrencyForms/RoundingRulesForm';
import {
  DollarInput,
  DollarSign,
  InputWrapper,
} from 'containers/PriceListsPage/CreatePriceList/Shared.styles';
import type { MarginOptionsType } from 'containers/PriceListsPage/CreatePriceList/Shared.types';
import { MarginOptions, MarginType } from 'containers/PriceListsPage/CreatePriceList/Shared.types';
import type { Partner } from 'types/models/partner';
import type { PriceList } from 'types/models/price-list';
import type { UpdatePriceListParams } from 'utils/api/priceLists';
import { CURRENCY_OPTIONS, getCurrencySymbol } from 'utils/currencies';
import { mapPartnerIdsToOrderFees } from 'utils/prices';

import Card from 'storybook/stories/cells/Card';
import ComboInput from 'storybook/stories/cells/ComboInput';
import Tooltip from 'storybook/stories/cells/Tooltip';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Divider from 'storybook/stories/molecules/Divider';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import Label from 'storybook/stories/molecules/Label';
import Link from 'storybook/stories/molecules/Link';

// Gets the initial dropship margin value based on the margin type
const getInitialDropshipMargin = (margin: number, marginType: string) => {
  if (marginType === MarginType.Percent) {
    return Math.round(margin * 100);
  }

  return margin;
};

// Returns the margin value adjusted for the margin type
const convertMargin = (margin: number, type: string) => {
  return type === MarginType.Percent ? margin / 100 : margin;
};

type PricingCalculationFormFields = UpdatePriceListParams;

interface PricingCalculationCardProps {
  priceList: PriceList;
  partners: Partner[];
  onFormSubmit: (data: UpdatePriceListParams) => void;
  isPriceListUpdated: boolean;
}

const PricingCalculationCard = ({
  priceList,
  partners,
  onFormSubmit,
  isPriceListUpdated,
}: PricingCalculationCardProps) => {
  const sellerCurrency = CURRENCY_OPTIONS.find((item) => item.value === priceList.sellerCurrency);
  const buyerRetailCurrency = CURRENCY_OPTIONS.find(
    (item) => item.value === priceList.buyerRetailCurrency
  );

  const orderFees = mapPartnerIdsToOrderFees(partners);

  const initialDropshipMargin = getInitialDropshipMargin(
    priceList.dropshipMargin,
    priceList.dropshipMarginType
  );

  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitted },
  } = useForm<PricingCalculationFormFields>({
    mode: 'onChange',
    defaultValues: {
      retailCurrencyConversionRate: priceList.retailCurrencyConversionRate,
      retailPriceFormat: priceList.retailPriceFormat,
      dropshipMargin: initialDropshipMargin,
      dropshipMarginType: priceList.dropshipMarginType,
    },
  });

  const conversionRateFormValue = watch('retailCurrencyConversionRate');
  const retailPriceFormatFormValue = watch('retailPriceFormat');
  const dropshipMarginTypeValue = watch('dropshipMarginType');

  const onSubmit = (data: Partial<PricingCalculationFormFields>) => {
    const updatedData: UpdatePriceListParams = {};

    // Only update fields that have changed in order to avoid overwriting individually edited price list entries

    if (data.retailCurrencyConversionRate !== priceList.retailCurrencyConversionRate) {
      updatedData.retailCurrencyConversionRate = data.retailCurrencyConversionRate;
    }

    if (
      data.retailPriceFormat &&
      data.retailPriceFormat.toString() !== priceList.retailPriceFormat?.toString()
    ) {
      updatedData.retailPriceFormat = data.retailPriceFormat.toString();
    }

    // Check for margin type changes
    const didMarginTypeChange = data.dropshipMarginType !== priceList.dropshipMarginType;
    const marginType = data.dropshipMarginType || priceList.dropshipMarginType;
    const marginValue = data.dropshipMargin || initialDropshipMargin;

    if (didMarginTypeChange) {
      updatedData.dropshipMarginType = data.dropshipMarginType;
    }

    // Update dropshipMargin
    if (didMarginTypeChange || marginValue !== initialDropshipMargin) {
      updatedData.dropshipMargin = convertMargin(marginValue, marginType);
    }

    onFormSubmit(updatedData);
  };

  // Used to clear the footer row when form submission is successful
  useEffect(() => {
    if (isPriceListUpdated && isSubmitted) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitted, reset, isPriceListUpdated]);

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex column gap="24px" alignItemsStretch>
          <Card.Head>
            <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
              Pricing Calculation
            </Heading>
            <Body color="bodyTextSecondary">
              You can also set a per-order flat fee that you and your partner have agreed upon in
              the{' '}
              <Link to="/partners" target="_blank">
                partner page
              </Link>
              .
            </Body>
          </Card.Head>

          <Card.Body>
            <Flex column gap="24px">
              {/* Your Currency */}
              <Flex column gap="8px">
                <Label htmlFor="sellerCurrency">Your Currency</Label>

                <Body>{sellerCurrency?.label ?? 'None'}</Body>
                <Body variant="Body/Body Small">Currency you want to be paid in</Body>
              </Flex>

              {/* Partner Currency */}
              <Flex column gap="8px">
                <Label htmlFor="buyerRetailCurrency">Partner&apos;s Currency</Label>

                <Body>{buyerRetailCurrency?.label ?? 'None'}</Body>
                <Body variant="Body/Body Small">Currency your partner uses</Body>
              </Flex>

              {/* Conversion Rate */}
              <Flex column gap="8px">
                <Label htmlFor="retailCurrencyConversionRate">Conversion Rate</Label>

                <ShortInput
                  type="number"
                  min="0"
                  step="0.01"
                  id="retailCurrencyConversionRate"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('retailCurrencyConversionRate', {
                    required: true,
                    valueAsNumber: true,
                  })}
                  placeholder="e.g. 0.80"
                />

                {conversionRateFormValue && isFinite(conversionRateFormValue) && (
                  <Body variant="Body/Regular" color="gray600">
                    <Flex alignItemsCenter gap="8px">
                      <div>{getCurrencySymbol(priceList.sellerCurrency)}1.00</div>
                      <Icon name="arrow_forward" />
                      <div>
                        {getCurrencySymbol(priceList.buyerRetailCurrency)}
                        {conversionRateFormValue.toFixed(2)}
                      </div>
                    </Flex>
                  </Body>
                )}
              </Flex>

              {/* Price Rounding */}
              <Flex column gap="8px">
                <Label htmlFor="retailPriceFormat">Price Rounding</Label>

                <InputWrapper>
                  <DollarSign>$.</DollarSign>
                  <DollarInput
                    type="number"
                    min="0"
                    max="99"
                    id="retailPriceFormat"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('retailPriceFormat', {
                      validate: validateRoundingRuleValue,
                    })}
                    placeholder="99"
                  />
                </InputWrapper>

                {isFinite(retailPriceFormatFormValue) && (
                  <Flex alignItemsCenter gap="8px">
                    <Body variant="Body/Body Small" color="blue800">
                      Rounding Example:
                    </Body>
                    <Body variant="Body/Body Small" color="gray500">
                      <Flex alignItemsCenter gap="4px">
                        {getCurrencySymbol(priceList.sellerCurrency)}123.00
                        <Icon name="arrow_forward" color="gray400" />
                        {getCurrencySymbol(priceList.sellerCurrency)}123.
                        {retailPriceFormatFormValue}
                      </Flex>
                    </Body>
                  </Flex>
                )}
              </Flex>

              {/* Margin */}
              <Flex column gap="8px">
                <Flex gap="8px" alignItemsCenter>
                  <Label htmlFor="retailPriceFormat">Default Margin</Label>
                  <Tooltip>
                    <Tooltip.Trigger asChild>
                      <Icon name="info" color="gray400" size="24px" />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      The default margin for the price list, which will be applied to all products
                      unless updated at the SKU level.
                    </Tooltip.Content>
                  </Tooltip>
                </Flex>

                <ComboInput>
                  <ComboInput.Input
                    type="number"
                    min="0"
                    step={dropshipMarginTypeValue === MarginType.Percent ? '1' : '0.01'}
                    placeholder="0"
                    data-testid="margin-input"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('dropshipMargin', { required: true, valueAsNumber: true })}
                  />
                  <Controller
                    name="dropshipMarginType"
                    control={control}
                    render={({ field }) => (
                      <ComboInput.Select
                        defaultValue={MarginOptions[0]}
                        options={MarginOptions}
                        value={MarginOptions.find(
                          (option: { value: string }) => option.value === field.value
                        )}
                        onChange={(option: MarginOptionsType) =>
                          field.onChange(option?.value ?? MarginOptions[0].value)
                        }
                      />
                    )}
                  />
                </ComboInput>
              </Flex>

              {/* Per-Order Fee */}
              {!isEmpty(orderFees) && (
                <Flex column gap="16px">
                  <Flex column gap="8px">
                    <Heading variant="Headings/H4">Per-Order Fee</Heading>
                    <Body variant="Body/Regular" color="bodyTextSecondary">
                      You can update the per-order fee in the partner’s settings page.
                    </Body>
                  </Flex>

                  <Flex column gap="8px">
                    {Object.entries(orderFees).map(([partnerId, orderFeeData]) => (
                      <Flex key={partnerId} justifyContent="space-between">
                        <Flex gap="8px">
                          <Body variant="Body/Regular">{orderFeeData.buyerName}</Body>
                          <Body color="bodyTextSecondary">{orderFeeData.orderFeeString}</Body>
                        </Flex>

                        <Icon
                          as={Link}
                          name="edit"
                          size="24px"
                          color="bodyTextSecondary"
                          target="_blank"
                          to={`/partners/${partnerId}`}
                        />
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Card.Body>

          {isDirty && (
            <Flex column gap="24px" alignItemsEnd>
              <Divider style={{ alignSelf: 'stretch' }} />

              <Flex gap="4px">
                <TertiaryButton type="reset" size="small" onClick={() => reset()}>
                  Cancel
                </TertiaryButton>

                <PrimaryButton type="submit" size="small" disabled={!isValid}>
                  Save Changes
                </PrimaryButton>
              </Flex>
            </Flex>
          )}
        </Flex>
      </form>
    </Card>
  );
};

export default PricingCalculationCard;
