export enum WebhookHealth {
  Healthy = 'healthy',
  Failed = 'failed',
  Unknown = 'unknown',
}

export interface WebhookSecret {
  id: string;
  secret: string;
  expiresDate: string;
  created: string;
}

export interface Webhook {
  id: string;
  targetUrl: string;
  active: boolean;
  topics: string[];
  secrets: WebhookSecret[];
  limiterRate: number;
  limiterBurst: number;
  health: WebhookHealth;
  lastRun: string;
  created: string;
  updated: string;
}
