import Flex from '@react-css/flex';
import useFloatingChatPrompt from 'hooks/useFloatingChatPrompt';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import NoResultsCard from 'storybook/stories/cells/Card/NoResultsCard';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

const EmptyBuyerProductsPage = () => {
  const { tryPrompting } = useFloatingChatPrompt();

  useEffect(() => {
    tryPrompting();
  }, [tryPrompting]);

  return (
    <NoResultsCard
      data-testid="empty-page"
      title="Your products will appear here"
      description="Once your partners have synced their products you will begin to see them on this page"
    >
      <Flex gap="16px">
        <SecondaryButton as={SupportLink} article="mgkg6potbt" $iconName="help">
          Guide
        </SecondaryButton>

        <PrimaryButton as={Link} to="/partners" $iconName="group_add">
          Go To Partners
        </PrimaryButton>
      </Flex>
    </NoResultsCard>
  );
};

export default EmptyBuyerProductsPage;
