import classNames from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

/**
 * General Bootstrap-styled Button for user interaction
 */
const Button = forwardRef((props, ref) => {
  const {
    outline = false,
    color = false,
    size = false,
    block = false,
    className = false,
    tag = false,
    onClick = noop,
    children,
    ...rest
  } = props;

  const handleClick = (e) => {
    if (rest.disabled) {
      e.preventDefault();
      return;
    }

    onClick(e);
  };

  const cn = classNames(
    'btn',
    {
      [`btn-outline-${color}`]: outline && color,
      [`btn-${color}`]: !outline && color,
      [`btn-${size}`]: size,
      'btn-block': block,
      disabled: !!rest.disabled,
    },
    className
  );

  if (tag === 'a') {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <StyledButton
        as="a"
        className={cn}
        onClick={handleClick}
        ref={ref}
        role="button"
        tabIndex="-1"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {children}
      </StyledButton>
    );
  }

  if (tag === 'link') {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <StyledButton as={Link} className={cn} onClick={handleClick} ref={ref} {...rest}>
        {children}
      </StyledButton>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledButton className={cn} onClick={handleClick} ref={ref} {...rest}>
      {children}
    </StyledButton>
  );
});

Button.propTypes = {
  /**
   * Component to be rendered within the Button
   */
  children: PropTypes.node.isRequired,
  /**
   * Color to apply to the button.
   */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'white',
    'white-20',
    false,
  ]),
  /**
   * When true, the color is applied to the outline only
   */
  outline: PropTypes.bool,
  /**
   * The size of the Button
   */
  size: PropTypes.oneOf(['lg', 'sm', false]),
  /**
   * If the button should be treated as block-level
   */
  block: PropTypes.bool,
  /**
   * Additional class names to apply, passed to the `classNames` library.
   */
  className: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  /**
   * The type of tag or component to render.
   * - 'a' renders an anchor tag
   * - 'link' renders a React Router <Link /> component
   */
  tag: PropTypes.oneOf(['a', 'link', false]),
  /**
   * Additional props to pass to the Button.
   */
  rest: PropTypes.object,
  /**
   * A click handler to pass to the button
   */
  onClick: PropTypes.func,
};

export default Button;
