import type { BaseQueryParams, PaginatedResponse, Response } from 'types/api';
import type { BaseSearchParams, Money } from 'types/general';
import type { PriceList, PriceListCSVPreview } from 'types/models/price-list';
import type { PriceListEntry } from 'types/models/price-list-entry';

import type { PriceListCsvExportOption } from 'containers/PriceListPage/CSV/ExportPriceListCsvModal';
import type { Event } from 'types/models/history';
import type { PriceListEntrySearchData } from 'types/models/search';
import {
  deleteRequest,
  getCsvRequest,
  getRequest,
  patchRequest,
  postCSVRequest,
  postRequest,
} from 'utils/request';

export type GetPriceListEntriesParams = BaseQueryParams;

export type CreatePriceListParams = {
  name: string;
  retailCurrencyConversionRate: number;
  retailPriceFormat: string;
  sellerCurrency: string;
  buyerRetailCurrency: string;
  dropshipMargin: number;
  dropshipMarginType: string;
  shouldAddNewProducts: boolean;
  shouldAddCurrentProducts: boolean;
};

export type UpdatePriceListParams = Partial<{
  name: string;
  retailCurrencyConversionRate: number;
  retailPriceFormat: string;
  dropshipMargin: number;
  dropshipMarginType: string;
  shouldAddNewProducts: boolean;
  shouldIgnoreAutomatedSyncs: boolean;
}>;

export type CreatePriceListEntryParams = {
  variantId: string;
  sellerRetailPrice: Money;
  dropshipPrice: Money;
};

export type UpdatePriceListEntryParams = {
  sellerRetailPrice: Money;
  dropshipPrice: Money;
};

export type PriceListsResponse = PaginatedResponse<PriceList[]>;
export type PriceListResponse = Response<PriceList>;
export type PriceListEntriesResponse = PaginatedResponse<PriceListEntry[]>;
export type PriceListEntryResponse = Response<PriceListEntry>;
export type PriceListHistoryResponse = PaginatedResponse<Event[]>;
export type PriceListEntrySearchResponse = PaginatedResponse<PriceListEntrySearchData>;
export type PriceListCSVPreviewResponse = Response<PriceListCSVPreview>;

export const getPriceLists = async (params: BaseQueryParams): Promise<PriceListsResponse> =>
  getRequest('/price-lists', params);

export const createPriceList = async (params: CreatePriceListParams): Promise<PriceListResponse> =>
  postRequest('/price-lists', params);

export const addAllProductsToPriceList = async (priceListId: string): Promise<PriceListResponse> =>
  postRequest(`/price-lists/${priceListId}/add-all-products`);

export const getPriceList = async (priceListId: string): Promise<PriceListResponse> =>
  getRequest(`/price-lists/${priceListId}`);

export const deletePriceList = async (priceListId: string): Promise<unknown> =>
  deleteRequest(`/price-lists/${priceListId}`);

export const updatePriceList = async (
  priceListId: string,
  params: UpdatePriceListParams
): Promise<PriceListResponse> => patchRequest(`/price-lists/${priceListId}`, params);

export const getPriceListEntries = async (
  priceListId: string,
  params?: GetPriceListEntriesParams
): Promise<PriceListEntriesResponse> => getRequest(`/price-lists/${priceListId}/entries`, params);

export const createPriceListEntry = async (
  priceListId: string,
  params: CreatePriceListEntryParams
): Promise<PriceListEntryResponse> => postRequest(`/price-lists/${priceListId}/entries`, params);

export const updatePriceListEntry = async (
  priceListId: string,
  entryId: string,
  params: UpdatePriceListEntryParams
): Promise<PriceListEntryResponse> =>
  patchRequest(`/price-lists/${priceListId}/entries/${entryId}`, params);

export const deletePriceListEntry = async (
  priceListId: string,
  entryId: string
): Promise<unknown> => deleteRequest(`/price-lists/${priceListId}/entries/${entryId}`);

export interface PriceListCsvExportOptions {
  exportOption?: PriceListCsvExportOption;
}

export const exportPriceListCSV = (priceListId: string, params?: PriceListCsvExportOptions) =>
  getCsvRequest(`/price-lists/${priceListId}/entries/csv`, params);

export const importPriceListCSV = (
  priceListId: string,
  file: FileReader['result']
): Promise<PriceListEntriesResponse> =>
  postCSVRequest(`/price-lists/${priceListId}/entries/csv`, file);

export const previewPriceListCSV = (
  priceListId: string,
  file: FileReader['result']
): Promise<PriceListCSVPreviewResponse> =>
  postCSVRequest(`/price-lists/${priceListId}/entries/csv/preview`, file);

export const getPriceListHistory = async (
  priceListId: string,
  params?: BaseQueryParams
): Promise<PriceListHistoryResponse> => getRequest(`/price-lists/${priceListId}/history`, params);

interface PriceListEntriesSearchFilters {
  tags?: string[];
}

export type PriceListEntriesSearchParams = BaseSearchParams<PriceListEntriesSearchFilters>;

export const getPriceListEntrySearch = (
  priceListId: string,
  params?: PriceListEntriesSearchParams
): Promise<PriceListEntrySearchResponse> =>
  getRequest(`/price-lists/${priceListId}/entries/search`, params);
