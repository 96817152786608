/* eslint-disable react/jsx-props-no-spreading */

import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { EditableInput, RemovedRow } from 'containers/Product/BuyerMetafields/shared.styles';
import React from 'react';
import type { FieldErrors, UseFormRegister } from 'react-hook-form';
import Body from 'storybook/stories/molecules/Body';
import Icon from 'storybook/stories/molecules/Icon';
import type { EditableField } from 'storybook/stories/organisms/MetafieldsEditor';
import type { Metafield, Metafields } from 'types/general';

const enum FieldActions {
  willBeDeleted = 'willBeDeleted',
  isNewNamespace = 'isNewNamespace',
}

interface MetafieldItemsGridProps {
  namespace: string;
  items: EditableField;
  errors: FieldErrors;
  isEditing: boolean;
  onDeleteRow: (namespace: string, key: string) => void;
  register: UseFormRegister<Metafields>;
  itemsLength: number;
}

const MetafieldItemsGrid = ({
  namespace,
  items,
  errors,
  isEditing,
  onDeleteRow,
  register,
  itemsLength,
}: MetafieldItemsGridProps) => {
  return (
    <Grid columns="repeat(3, 1fr)" alignItemsCenter>
      <Body variant="Body/Body Small Bold">Key</Body>
      <Body variant="Body/Body Small Bold">Value</Body>
      <Body variant="Body/Body Small Bold">Description</Body>

      {Object.entries(items).map(([childKey, field]) => {
        const isDeleted = childKey === FieldActions.willBeDeleted;
        if (isDeleted) {
          return <RemovedRow key={childKey}>{childKey} will be deleted</RemovedRow>;
        }

        const metafield = field as Metafield;
        const isNewNamespace = childKey === FieldActions.isNewNamespace;

        // If this is the only row in a newly added namespace, indicate that a key is required
        if (itemsLength === 1 && isNewNamespace) {
          return (
            <Body key={childKey} variant="Body/Regular" color="error500">
              At least 1 key is required
            </Body>
          );
        }

        // If this is a new namespace, but rows have been added, don't show the "add a new key" row
        if (itemsLength > 1 && isNewNamespace) {
          return null;
        }

        if (!metafield.value && metafield.updated !== '') {
          return <RemovedRow key={childKey}>{childKey} will be deleted</RemovedRow>;
        }

        // @ts-ignore - this is a valid way to access the nested error
        const fieldError = errors?.fields?.[namespace]?.[childKey];

        return (
          <React.Fragment key={childKey}>
            {isEditing ? (
              <>
                <div>{childKey}</div>

                <Flex column>
                  <EditableInput
                    data-testid={`${childKey}.value`}
                    defaultValue={metafield.value}
                    placeholder="Enter a value"
                    style={fieldError ? { border: '2px solid #D52315' } : {}}
                    {...register(`fields.${namespace}.${childKey}.value`)}
                  />
                  {fieldError && (
                    <Body variant="Body/Body Small" color="error500">
                      This field is required
                    </Body>
                  )}
                </Flex>

                <Flex style={{ height: 'max-content' }}>
                  <EditableInput
                    data-testid={`${childKey}.description`}
                    placeholder="Enter a description"
                    defaultValue={metafield.description}
                    {...register(`fields.${namespace}.${childKey}.description`)}
                  />
                  <Icon
                    data-testid={`${childKey}.delete`}
                    onClick={() => onDeleteRow(namespace, childKey)}
                    as="button"
                    type="button"
                    color="error500"
                    name="delete"
                  />
                </Flex>
              </>
            ) : (
              <>
                <div>{childKey}</div>
                <div>{metafield.value}</div>
                <div>{metafield.description}</div>
              </>
            )}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export default MetafieldItemsGrid;
