/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import Modal from 'storybook/stories/cells/Modal';

import CreateActionCheckbox from 'containers/PartnerPage/Actions/Common/CreateActionCheckbox';
import CreateActionTextarea from 'containers/PartnerPage/Actions/Common/CreateActionTextarea';
import CreateActionTextbox from 'containers/PartnerPage/Actions/Common/CreateActionTextbox';
import PreviewCreateActionCard from 'containers/PartnerPage/Actions/Common/PreviewCreateActionCard';
import SubmitFormButton from 'containers/PartnerPage/Actions/Common/SubmitFormButton';
import { BTN_DELAY } from 'containers/PartnerPage/Actions/Shared';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

type CreateAcknowledgementActionData = {
  cardTitle: string;
  cardText: string;
  required: boolean;
  fileName: string;
  fileType: string;
  bytes: number[];
  sharedWithCompany: boolean;
};

interface CreateAcknowledgementActionModalProps {
  kind: string;
  showModal: boolean;
  onHide: () => void;
  onCreate: (data: CreateAcknowledgementActionData) => Promise<any>;
  onBackClick: () => void;
  onRefresh: React.Dispatch<void>;
  isActionTemplate: boolean;
}

const CreateAcknowledgementActionModal = ({
  kind,
  showModal,
  onHide,
  onCreate,
  onBackClick,
  onRefresh,
  isActionTemplate,
}: CreateAcknowledgementActionModalProps) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [required, setRequired] = useState(false);
  const [doc, setDoc] = useState<File | null>(null);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (title === '') {
      setError('Error: Title is required');
      return;
    }

    if (!doc) {
      setError('Error: Document is required');
      return;
    }
    if (doc.type !== 'application/pdf') {
      setError('Error: Document must be a PDF');
      return;
    }

    setLoading(true);

    // Upload document
    const reader = new FileReader();
    reader.onload = () => {
      const bytes = Array.from(new Uint8Array(reader.result as ArrayBufferLike));
      onCreate({
        cardTitle: title,
        cardText: text,
        required,
        fileName: doc.name,
        fileType: doc.type,
        bytes,
        sharedWithCompany: true,
      })
        .then((response) => {
          setLoading(false);

          if (response.error && response.error.message) {
            setError(response.error.message);
          } else {
            setSuccess(true);

            setTimeout(() => {
              setTitle('');
              setText('');
              setRequired(false);
              setError('');
              setSuccess(false);
              onRefresh();
            }, BTN_DELAY);
          }
        })
        .catch((err) => {
          console.log('error', err);
          setLoading(false);
          setError('Something went wrong, please contact support@convictional.com');
        });
    };
    reader.onerror = (err) => {
      console.warn(err);
    };
    reader.readAsArrayBuffer(doc);
  };

  return (
    <Modal.Root
      data-testid="action-acknowledgement-modal"
      show={showModal}
      onHide={onHide}
      aria-labelledby="action-acknowledgement-modal"
      size="xl"
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isActionTemplate
            ? 'Create Acknowledgement Action Template'
            : 'Create Acknowledgement Action'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            {error && <div className="alert alert-danger">{error}</div>}
            <CreateActionTextbox
              id="title"
              label="Title"
              value={title}
              placeholder="Ex. Partnership Agreement"
              setValue={setTitle}
            />
            <CreateActionTextarea
              id="text"
              label="Description"
              value={text}
              placeholder="Describe the required steps"
              setValue={setText}
            />
            <CreateActionCheckbox
              id="required"
              label="Required"
              value={required}
              setValue={setRequired}
            />
            <div className="form-group">
              <label htmlFor="create-card-text">Document</label>
              <input
                type="file"
                className="form-control"
                id="create-card-doc"
                data-testid="create-action-file-uploader"
                onChange={(e) => {
                  if (e.target.files) {
                    setDoc(e.target.files[0]);
                  }
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            <PreviewCreateActionCard
              title={title}
              text={text}
              kind={kind}
              required={required}
              optionalDoc={false}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <div className="text-right">
              <SecondaryButton className="mr-3" size="small" onClick={onBackClick}>
                Back
              </SecondaryButton>
              <SubmitFormButton
                id="create-action-form-btn"
                label={isActionTemplate ? 'Create Action Template' : 'Create Action'}
                successfulLabel="Created"
                loading={loading}
                success={success}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal.Root>
  );
};

export default CreateAcknowledgementActionModal;
