/* eslint-disable no-nested-ternary, jsx-a11y/label-has-associated-control, jsx-a11y/control-has-associated-label */
import Spinner from 'components/Common/Spinner';
import {
  ACTION_TEMPLATES_LIMIT,
  getFriendlyNameForActionKind,
} from 'containers/PartnerPage/Actions/Shared';
import ActionsPagination from 'containers/PartnerPage/ActionsPagination';
import CreateActionTemplate from 'containers/PartnersPage/ActionTemplates/CreateActionTemplate';
import EditActionTemplateModal from 'containers/PartnersPage/ActionTemplates/EditActionTemplateModal';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteConsumableActionTemplate,
  listConsumableActionTemplates,
  updateConsumableActionTemplate,
} from 'store/slices/partners';
import Card from 'storybook/stories/cells/Card';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

const ActionTemplateSection = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [error, setError] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editDetails, setEditDetails] = useState(null);

  const { loadingActionTemplates, actionTemplates } = useSelector(({ partners }) => partners);

  // Get action templates
  const refreshListOfActionTemplates = useCallback(() => {
    setError('');
    dispatch(listConsumableActionTemplates({ page, limit: ACTION_TEMPLATES_LIMIT })).then(
      (action) => {
        if (action.error) {
          setError(action.error.message);
        }
      }
    );
  }, [dispatch, page]);
  useEffect(() => {
    refreshListOfActionTemplates();
  }, [refreshListOfActionTemplates]);

  const editActionTemplate = (e, actionTemplateId) => {
    e.preventDefault();
    setEditDetails(actionTemplates.find((actionItem) => actionItem.id === actionTemplateId));
    setShowEditModal(true);
  };
  const deleteActionTemplate = (e, actionTemplateId) => {
    e.preventDefault();
    dispatch(deleteConsumableActionTemplate(actionTemplateId)).then((response) => {
      if (response.error) {
        setError(response.error.message);
      } else {
        refreshListOfActionTemplates();
      }
    });
  };
  const setApplyToNewPartners = (actionTemplateId, checked) => {
    const body = {
      applyToNewPartners: checked,
    };
    dispatch(updateConsumableActionTemplate({ actionTemplateId, body })).then((response) => {
      if (response.error) {
        setError(response.error.message);
      } else {
        refreshListOfActionTemplates();
      }
    });
  };

  return (
    <Card>
      <h2 className="mb-2" id="action-template-title">
        Action Templates
      </h2>
      <p className="small text-muted mb-3" id="action-template-help-text">
        Action Templates are a great way to assign{' '}
        <SupportLink article="gc59nwyivb">Actions</SupportLink> to all new partnerships.
      </p>
      <div>
        <PrimaryButton
          onClick={() => setShowCreateModal(true)}
          outline
          id="create-action-template-btn"
        >
          Create Action Template
        </PrimaryButton>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      {loadingActionTemplates ? (
        <Spinner />
      ) : actionTemplates.length > 0 ? (
        <div>
          <br />
          <table className="table table-sm" id="action-templates-table">
            <thead>
              <tr key="header-row">
                <th scope="col">Card</th>
                <th scope="col">Type</th>
                <th scope="col">Apply to New Partners</th>
                <th scope="col" aria-label="Badge" />
              </tr>
            </thead>
            <tbody>
              {actionTemplates.map((actionTemplate) => {
                // prettier-ignore
                const { id, cardTitle, cardText, kind, required, applyToNewPartners } = actionTemplate
                return (
                  <tr key={Math.random()}>
                    <td>
                      <b>{cardTitle}</b>
                      <br />
                      {cardText}
                    </td>
                    <td>{getFriendlyNameForActionKind(kind)}</td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          className="custom-control-input partnerUpdates"
                          type="checkbox"
                          id={`action-template-apply-new-partners-${id}`}
                          onChange={(e) => setApplyToNewPartners(id, e.target.checked)}
                          defaultChecked={applyToNewPartners}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`action-template-apply-new-partners-${id}`}
                        />
                      </div>
                    </td>
                    <td className="text-right nowrap">
                      {required ? null : (
                        <span className="badge badge-soft-info owned-actions-optional-badge">
                          Optional
                        </span>
                      )}
                      {/* eslint-disable-next-line react/button-has-type */}
                      <button
                        className="modify-card-details-btn"
                        onClick={(e) => editActionTemplate(e, id)}
                      >
                        <Icon name="drive_file_rename_outline" />
                      </button>
                      {/* eslint-disable-next-line react/button-has-type */}
                      <button
                        className="modify-card-details-btn"
                        onClick={(e) => deleteActionTemplate(e, id)}
                      >
                        <Icon name="delete" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <ActionsPagination
            page={page}
            setPage={setPage}
            resultsSize={actionTemplates ? actionTemplates.length : 0}
            limit={ACTION_TEMPLATES_LIMIT}
          />
        </div>
      ) : null}
      <CreateActionTemplate
        showModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
        onRefresh={refreshListOfActionTemplates}
      />
      <EditActionTemplateModal
        show={showEditModal}
        setShowEditModal={setShowEditModal}
        editDetails={editDetails}
        onRefresh={refreshListOfActionTemplates}
      />
    </Card>
  );
};

export default ActionTemplateSection;
