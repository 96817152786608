import Grid from '@react-css/grid';
import styled, { keyframes } from 'styled-components';

import PageHeader from 'components/Common/PageHeader';
import Icon from 'storybook/stories/molecules/Icon';

const loadingTextKeyframe = keyframes`
  0% {
    background: var(--light);
  }
  50% {
    background: #e2ebf6;
  }
  100% {
    background: var(--light);
  }
`;

const LoadingText = styled.div`
  height: 0.9375rem;
  margin-bottom: 0.5rem;
  background: var(--light);
  border-radius: 4px;
  animation: ${loadingTextKeyframe} 2s linear infinite;
`;

const LoadHeading = styled(LoadingText)`
  height: 1.6rem;
  width: 24rem;
`;

const LoadCardText = styled(LoadingText)`
  height: 0.8rem;
`;

const PriceListLoadingRow = () => (
  <tr>
    <td>
      <LoadCardText className="mb-2 mt-2" />
    </td>
    <td>
      <LoadCardText className="mb-2 mt-2" />
    </td>
    <td>
      <LoadCardText className="mb-2 mt-2" />
    </td>
    <td>
      <LoadCardText className="mb-2 mt-2" />
    </td>
  </tr>
);

const LoadingPriceListPage = () => {
  return (
    <>
      <PageHeader>
        <LoadHeading className="mb-3" />
      </PageHeader>
      <Grid>
        <Grid.Item>
          <div className="card">
            <div className="card-header">
              <div className="input-group input-group-flush">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <Icon name="search" />
                  </span>
                </div>
                <input
                  className="list-search form-control"
                  type="search"
                  placeholder="Loading prices"
                  disabled
                  value=""
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-sm table-nowrap card-table PriceListTable">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Cost</th>
                    <th>Margin</th>
                    <th>Suggested Retail Price</th>
                  </tr>
                </thead>
                <tbody className="list font-size-base">
                  <PriceListLoadingRow key={0} />
                  <PriceListLoadingRow key={1} />
                  <PriceListLoadingRow key={2} />
                  <PriceListLoadingRow key={3} />
                  <PriceListLoadingRow key={4} />
                  <PriceListLoadingRow key={5} />
                  <PriceListLoadingRow key={6} />
                  <PriceListLoadingRow key={7} />
                  <PriceListLoadingRow key={8} />
                  <PriceListLoadingRow key={9} />
                  <PriceListLoadingRow key={10} />
                  <PriceListLoadingRow key={11} />
                  <PriceListLoadingRow key={12} />
                  <PriceListLoadingRow key={13} />
                  <PriceListLoadingRow key={14} />
                  <PriceListLoadingRow key={15} />
                  <PriceListLoadingRow key={16} />
                  <PriceListLoadingRow key={17} />
                  <PriceListLoadingRow key={18} />
                  <PriceListLoadingRow key={19} />
                  <PriceListLoadingRow key={20} />
                </tbody>
              </table>
            </div>
          </div>
        </Grid.Item>
      </Grid>
    </>
  );
};

export default LoadingPriceListPage;
