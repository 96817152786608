import Flex from '@react-css/flex';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useBoolean } from 'usehooks-ts';

import AddKeyRow from 'containers/Product/BuyerMetafields/AddKeyRow';
import CreateNamespaceRow from 'containers/Product/BuyerMetafields/CreateNamespaceRow';
import HeaderRow from 'containers/Product/BuyerMetafields/HeaderRow';
import MetafieldItemsGrid from 'containers/Product/BuyerMetafields/MetafieldItemsGrid';
import { editingBorder, RemovedRow } from 'containers/Product/BuyerMetafields/shared.styles';
import { useEffect } from 'react';
import Body from 'storybook/stories/molecules/Body';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import type { Metafield, Metafields } from 'types/general';

export type EditableField = Record<string, Metafield> & {
  isNewNamespace?: boolean;
  willBeDeleted?: boolean;
};

export type EditableMetafields = Record<string, EditableField>;

type NestedObject = {
  [key: string]: any;
};

const fieldsToRemove = ['updated', 'willBeDeleted', 'isNewNamespace'];
const removeFields = (obj: NestedObject): NestedObject => {
  const newObj: NestedObject = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach((key) => {
    if (fieldsToRemove.includes(key)) {
      // Skip this field
    } else if (obj[key] && typeof obj[key] === 'object') {
      newObj[key] = removeFields(obj[key]);
    } else {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

export interface MetafieldsEditorProps {
  metafields: EditableMetafields | null | undefined;
  updateMetafields: (metafields: Metafields) => void;
}

const MetafieldsEditor = ({ metafields, updateMetafields }: MetafieldsEditorProps) => {
  const isEditing = useBoolean(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { isValid, isSubmitting, errors },
  } = useForm({
    mode: 'onChange',
  });
  const currentMetafields = getValues('fields') ?? {};

  const onSubmit = (data: Metafields) => {
    const newMetafields = removeFields(data.fields);
    const update = {
      metafields: newMetafields,
    };
    updateMetafields(update);
    setValue('fields', newMetafields);
    isEditing.setFalse();
  };

  const onClickCancel = () => {
    isEditing.setFalse();
    setValue('fields', metafields);
  };

  const onAddNewNamespace = (namespace: string) => {
    const newMetafields = { ...currentMetafields };
    newMetafields[namespace] = {};
    newMetafields[namespace].isNewNamespace = true;
    setValue('fields', newMetafields);
    trigger();
  };

  const onAddNewKey = (namespace: string, key: string) => {
    setValue(`fields.${namespace}.${key}`, {
      value: '',
      description: '',
      updated: '',
    });
    trigger();
  };

  const onDeleteNameSpace = (namespace: string) => {
    if (currentMetafields[namespace].isNewNamespace) {
      const newMetafields = { ...currentMetafields };
      delete newMetafields[namespace];
      setValue('fields', newMetafields);
    } else {
      const newMetafields = { ...currentMetafields };
      newMetafields[namespace] = { willBeDeleted: true };
      setValue('fields', newMetafields);
    }

    trigger();
  };

  const onDeleteRow = (namespace: string, key: string) => {
    // @ts-ignore - Empty object indicates deletion action, safe to ignore
    setValue(`fields.${namespace}.${key}`, {});

    // If this is the last row in the namespace, completely remove the namespace
    if (Object.keys(currentMetafields[namespace]).length === 1) {
      onDeleteNameSpace(namespace);
      return;
    }

    // Check if every field in namespace is going to be deleted, and remove entire namespace instead
    // @ts-ignore - field.value is valid
    if (Object.values(currentMetafields[namespace]).every((field) => !field.value)) {
      onDeleteNameSpace(namespace);
    }

    trigger();
  };

  useEffect(() => {
    if (metafields) {
      setValue('fields', metafields);
      trigger();
    }
  }, [metafields, setValue, trigger]);

  const isEmptyMetafields = isEmpty(metafields);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HeaderRow
        buttonText={isEmptyMetafields ? 'Add' : 'Edit'}
        buttonIconName={isEmptyMetafields ? 'add_circle' : 'edit'}
        isEditing={isEditing.value}
        isValid={isValid}
        isSubmitting={isSubmitting}
        onClickEdit={isEditing.setTrue}
        onClickCancel={onClickCancel}
      />

      {isEmptyMetafields && !isEditing.value && (
        <Body variant="Body/Regular">No metafields have been added yet</Body>
      )}

      <Flex column gap="24px">
        {Object.entries(currentMetafields).map(([namespace, fields]) => {
          // @ts-ignore - willBeDeleted is valid
          if (fields.willBeDeleted) {
            return <RemovedRow key={namespace}>{namespace} will be deleted</RemovedRow>;
          }

          return (
            <div key={namespace} style={isEditing.value ? editingBorder : {}}>
              <Flex justifySpaceBetween>
                <Body variant="Body/Regular Bold">{namespace}</Body>

                {isEditing.value && (
                  <TertiaryButton
                    size="small"
                    kind="destructive"
                    onClick={() => onDeleteNameSpace(namespace)}
                  >
                    Delete Namespace
                  </TertiaryButton>
                )}
              </Flex>

              <MetafieldItemsGrid
                namespace={namespace}
                // @ts-ignore - fields is valid
                items={fields}
                errors={errors}
                isEditing={isEditing.value}
                onDeleteRow={onDeleteRow}
                register={register}
                itemsLength={Object.entries(currentMetafields[namespace]).length}
              />

              {isEditing.value && <AddKeyRow namespace={namespace} onAddNewKey={onAddNewKey} />}
            </div>
          );
        })}

        {isEditing.value && <CreateNamespaceRow onAddNewNamespace={onAddNewNamespace} />}
      </Flex>
    </form>
  );
};

export default MetafieldsEditor;
