import Flex from '@react-css/flex';
import DashboardPage from 'components/Dashboard/DashboardPage';
import PrivateRoute from 'containers/App/PrivateRoute';
import ProtectedRoute from 'containers/App/ProtectedRoute';
import CompanyTab from 'containers/SettingsPage/CompanyTab';
import DropshippingTab from 'containers/SettingsPage/DropshippingTab';
import PreferencesTab from 'containers/SettingsPage/PreferencesTab';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { selectIsDropshipSetup } from 'store/selectors/me/company';
import Page from 'storybook/stories/cells/Page';
import Tabs from 'storybook/stories/cells/Tabs';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import { useDocumentTitle } from 'usehooks-ts';

enum TabSegments {
  Preferences = 'preferences',
  Company = 'company',
  Dropshipping = 'dropshipping',
}

const SettingsPage = () => {
  useDocumentTitle('Settings');

  const { tab } = useParams<{ tab?: TabSegments }>();
  const isDropshipSetup = useSelector(selectIsDropshipSetup);

  const selectedTab =
    tab && Object.values(TabSegments).includes(tab) ? tab : TabSegments.Preferences;

  /**
   * Render
   */

  return (
    <DashboardPage>
      <Page>
        <Page.Head title="Settings">
          <SecondaryButton size="small" as={Link} to="/logout" $iconName="logout">
            Logout
          </SecondaryButton>
        </Page.Head>

        <Flex column gap="24px">
          <Tabs>
            <Tabs.Item
              as={Link}
              to="/settings/preferences"
              $iconName="manage_accounts"
              selected={selectedTab === TabSegments.Preferences}
            >
              Preferences
            </Tabs.Item>

            <Tabs.Item
              as={Link}
              to="/settings/company"
              $iconName="apartment"
              selected={selectedTab === TabSegments.Company}
            >
              Company
            </Tabs.Item>

            {isDropshipSetup && (
              <Tabs.Item
                as={Link}
                to="/settings/dropshipping"
                $iconName="package_2"
                selected={selectedTab === TabSegments.Dropshipping}
              >
                Dropshipping
              </Tabs.Item>
            )}
          </Tabs>

          <Switch>
            <PrivateRoute exact path="/settings/preferences/:section?" component={PreferencesTab} />

            <PrivateRoute exact path="/settings/company/:section?" component={CompanyTab} />

            <ProtectedRoute
              isAllowed={isDropshipSetup}
              redirectTo="/settings/preferences/account"
              exact
              path="/settings/dropshipping/:section?"
              component={DropshippingTab}
            />

            <Route path="*">
              <Redirect to="/settings/preferences/account" />
            </Route>
          </Switch>
        </Flex>
      </Page>
    </DashboardPage>
  );
};

export default SettingsPage;
