import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import backArrowSrc from 'images/back-arrow.svg';

const Arrow = styled.img.attrs({
  src: backArrowSrc,
})`
  height: 1rem;
  width: 1rem;
  margin-right: 0.3rem;
`;

const Text = styled.p`
  color: #2c7be5;
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
`;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.7rem;
  width: auto;
  border-radius: 5px;
`;

const BackLink = ({ backUrl = '', backPageName }) => {
  const topLevelUrl = /^\/[a-z]+/i;
  if (!topLevelUrl.test(backUrl)) {
    console.error('Error: Please provide a valid url for backLink');
    return null;
  }

  return (
    <Link to={backUrl}>
      <Container>
        <Arrow />
        <Text>back to {backPageName || backUrl.charAt(1).toUpperCase() + backUrl.slice(2)}</Text>
      </Container>
    </Link>
  );
};

BackLink.propTypes = {
  backUrl: PropTypes.string.isRequired,
};

export default BackLink;
