import type { MerchandisingSettings, Metafields, Money } from 'types/general';
import type { Platform } from 'types/models/company';
import type { ShippingMethod, ShippingSettings } from 'types/models/shipping';

export enum InvoicePoster {
  Stripe = 'stripe',
  StripeCharge = 'stripecharge', // Deprecated, but present on very old invoices
  None = '',
  External = 'external',
}

export enum InvoicePosterReason {
  BuyerPaymentsDisabled = 'buyerPaymentsDisabled',
  SellerPaymentsDisabled = 'sellerPaymentsDisabled',
  PartnerBillingDisabled = 'partnerBillingDisabled',
  OrderIsTest = 'orderIsTest',
  NoGateway = 'noGateway',
  None = '',
}

export interface PartnerBillingSettings {
  generateOrderInvoices: boolean;
  autoTax: boolean;
  postInvoices: boolean;
  enablePaymentTerms: boolean;
  paymentTerms: {
    numBillingDelayDays: number;
  };
  invoicePoster: InvoicePoster;
  invoicePosterReason: InvoicePosterReason;
}

export interface PartnerOnboardingState {
  required: boolean;
  status: string;
}

export interface PartnerGettingStartedSteps {
  connectPlatform: PartnerOnboardingState;
  connectShopify: PartnerOnboardingState;
  connectBigCommerce: PartnerOnboardingState;
  connectWooCommerce: PartnerOnboardingState;
  connectMagento2: PartnerOnboardingState;
  connectStripe: PartnerOnboardingState;
  addPaymentMethod: PartnerOnboardingState;
  addBillingAddress: PartnerOnboardingState;
  inviteTeam: PartnerOnboardingState;
  invitePartners: PartnerOnboardingState;
  activeProducts: PartnerOnboardingState;
  createdPriceList: PartnerOnboardingState;
  createSFTPCreds: PartnerOnboardingState;
  createExternalSFTPCredentials: PartnerOnboardingState;
  uploadSFTPFile: PartnerOnboardingState;
  createCSVMappings: PartnerOnboardingState;
  EDISchemaSetup: PartnerOnboardingState;
  testOrder: PartnerOnboardingState;
  actionsAssigned: PartnerOnboardingState;
  shippingMethods: PartnerOnboardingState;
  configureEDISettings: PartnerOnboardingState;
  viewEDISpecifications: PartnerOnboardingState;
}

interface PartnerGettingStarted extends PartnerGettingStartedSteps {
  requiredCount: number;
  doneCount: number;
  signedUp: boolean;
}

export enum PerOrderFeeType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE',
}

export interface PartnerPerOrderFeeSettings {
  type: PerOrderFeeType;
  flatFee: Money;
  percentage: number;
}

export interface Partner {
  _id: string;
  email: string;
  active: boolean;
  invited: boolean;
  billing: boolean;
  pricingId: string;
  pricingMarginMin: number;
  pricingMarginMax: number;
  priceListName: string;
  created: string;
  updated: string;
  deletedAt?: string;
  companyId: string;
  sellerCompanyId: string;
  sellerCompanyObjectId: string;
  sellerAssignedCode: string;
  buyerCompanyId: string;
  buyerCompanyObjectId: string;
  buyerAssignedCode: string;
  autoAcceptReturns: boolean;
  reference: string;
  averageShipTime: number;
  averageReturnRate: number; // This is unset in the backend
  sellerName: string;
  buyerName: string;
  metafields: Metafields | null;
  sellerOnboardingCompleted: boolean;
  buyerOnboardingCompleted: boolean;
  gettingStarted: PartnerGettingStarted | null;
  shippingMethods?: ShippingMethod[];
  buyerProductPlatform: Platform;
  buyerOrderPlatform: Platform;
  sellerProductPlatform: Platform;
  sellerOrderPlatform: Platform;
  billingSettings: PartnerBillingSettings;
  requireFulfillmentTrackingInfo: boolean;
  merchandisingSettings: MerchandisingSettings;
  shippingSettings: ShippingSettings;
  effectiveShippingSettings?: ShippingSettings;
  perOrderFeeSettings?: PartnerPerOrderFeeSettings;
  syncSellerCompareAtPrice?: boolean;
  maxShipTime: number;
  effectiveMaxShipTime: number;
  maxReturnRate: number;
  effectiveMaxReturnRate: number;
}
