import useAiChartRef from 'hooks/useAiChartRef';
import { Remarkable } from 'remarkable';
import styled from 'styled-components';

const md = new Remarkable({
  linkTarget: '_blank',
});

const Wrapper = styled.div`
  p {
    margin: 1rem 0;
  }

  p:first-child {
    margin-top: 0;
  }
`;

interface AnswerProps {
  answer?: string;
}

const Answer = ({ answer = '' }: AnswerProps) => {
  const ref = useAiChartRef(answer);
  return <Wrapper ref={ref} dangerouslySetInnerHTML={{ __html: md.render(answer) }} />;
};

export default Answer;
