import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { v4 as uuid4 } from 'uuid';

import type { RootState } from 'store';
import { inviteUser as inviteUserFromAPI, type InviteUserParams } from 'utils/api/signup';

const initialState = {
  isInvitingUser: false,
};

export type InviteUserData = InviteUserParams['user'];

export const inviteUser = createAsyncThunk<
  Promise<{}>,
  InviteUserData,
  {
    state: RootState;
  }
>(
  'settings/team/inviteUser',
  (userData) => inviteUserFromAPI({ user: userData }).then(() => ({ ...userData, userId: uuid4() })) // add fake _id for now since user _id is not returned
);

const slice = createSlice({
  name: 'settings/team',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(inviteUser.pending, (draft) => {
        draft.isInvitingUser = true;
      })
      .addMatcher(isAnyOf(inviteUser.fulfilled, inviteUser.rejected), (draft) => {
        draft.isInvitingUser = false;
      });
  },
});

export default slice.reducer;
