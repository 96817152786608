import { css } from 'styled-components';

export enum Elevations {
  One = '1',
  Two = '2',
  Four = '4',
  Eight = '8',
}

export type Elevation = `${Elevations}`;

const elevate = (key: Elevation) => {
  switch (key) {
    case Elevations.One:
      return css`
        box-shadow: 0px 2px 6px rgba(62, 89, 108, 0.1);
      `;
    case Elevations.Two:
      return css`
        box-shadow:
          1px 3px 3px rgba(62, 89, 108, 0.09),
          2px 7px 12px rgba(62, 89, 108, 0.09);
      `;
    case Elevations.Four:
      return css`
        box-shadow:
          1px 3px 5px rgba(62, 89, 108, 0.09),
          3px 12px 16px rgba(62, 89, 108, 0.14);
      `;
    case Elevations.Eight:
      return css`
        box-shadow:
          7px 9px 9px rgba(62, 89, 108, 0.05),
          6px 21px 37px rgba(62, 89, 108, 0.2);
      `;
    default:
      return css``;
  }
};

export default elevate;
