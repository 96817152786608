import Flex from '@react-css/flex';
import Spinner from 'components/Common/Spinner';
import useFetchAddresses from 'hooks/useAddresses';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import Link from 'storybook/stories/molecules/Link';
import AddressForm from './AddressForm';

export interface AddBillingAddressCardProps {
  isDone: boolean;
  isConnected: boolean;
}

const AddBillingAddressCard = ({ isDone, isConnected }: AddBillingAddressCardProps) => {
  const { addresses, isLoading } = useFetchAddresses(isConnected);

  return (
    <GetStartedCard
      isComplete={isDone}
      title="Add a Billing Address"
      completedUi={
        <Body as="p">
          Manage your locations from your{' '}
          <Link to="/settings/company/locations">Location settings</Link>.
        </Body>
      }
    >
      {isConnected && (
        <Flex>
          {isLoading ? (
            <Flex gap="4px" alignItemsCenter>
              <Spinner size="sm" />
              <Body>Retrieving address information from platform...</Body>
            </Flex>
          ) : (
            <Body as="p">
              Based on your platform connection, we have pre-filled your addresses below. Please
              confirm that the information is correct and add any missing data before saving.
            </Body>
          )}
        </Flex>
      )}

      <AddressForm addresses={addresses} addressType="billing" />
    </GetStartedCard>
  );
};

export default AddBillingAddressCard;
