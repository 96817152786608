export const ISA_QUALIFIERS: { [key: string | number]: string } = {
  '01': 'Duns (Dun & Bradstreet)',
  '08': 'UCC EDI Communications ID (Comm ID)',
  12: 'Phone (Telephone Companies)',
  14: 'Duns Plus Suffix',
  16: 'Duns Number With 4-Character Suffix',
  ZZ: 'Mutually Defined',
};

export const MIN_ISA_ID_LENGTH = 15;
export const isaIDSpec = new RegExp(`^[A-Z0-9]{1,${MIN_ISA_ID_LENGTH}}$`, 'i');

type SelectOption = {
  value: string;
  label: string;
};

export const CONVICTIONAL_ACCEPTED_EDI_FILE_EXTENSIONS: SelectOption[] = [
  { label: '.x12', value: '.x12' },
  { label: '.txt', value: '.txt' },
  { label: '.edi', value: '.edi' },
  { label: '.dat', value: '.dat' },
];

// These are the encoding options that are supported by Go. The default names in Go are used.
export const EDI_FILE_ENCODING_OPTIONS: SelectOption[] = [
  { value: 'us-ascii', label: 'US-ASCII' },
  { value: 'iso_8859-1:1987', label: 'ISO-8859-1:1987' },
  { value: 'iso_8859-2:1987', label: 'ISO-8859-2:1987' },
  { value: 'iso_8859-3:1988', label: 'ISO-8859-3:1988' },
  { value: 'iso_8859-4:1988', label: 'ISO-8859-4:1988' },
  { value: 'iso_8859-5:1988', label: 'ISO-8859-5:1988' },
  { value: 'iso_8859-6:1987', label: 'ISO-8859-6:1987' },
  { value: 'iso_8859-7:1987', label: 'ISO-8859-7:1987' },
  { value: 'iso_8859-8:1988', label: 'ISO-8859-8:1988' },
  { value: 'iso_8859-9:1989', label: 'ISO-8859-9:1989' },
  { value: 'iso-8859-10', label: 'ISO-8859-10' },
  { value: 'shift_jis', label: 'SHIFT_JIS' },
  {
    value: 'extended_unix_code_packed_format_for_japanese',
    label: 'Extended UNIX code packed format for Japanese',
  },
  { value: 'euc-kr', label: 'EUC-KR' },
  { value: 'iso-2022-jp', label: 'ISO-2022-JP' },
  { value: 'iso_8859-6-e', label: 'ISO-8859-6-E' },
  { value: 'iso_8859-6-i', label: 'ISO-8859-6-I' },
  { value: 'iso_8859-8-e', label: 'ISO-8859-8-E' },
  { value: 'iso_8859-8-i', label: 'ISO-8859-8-I' },
  { value: 'utf-8', label: 'UTF-8' },
  { value: 'iso-8859-13', label: 'ISO-8859-13' },
  { value: 'iso-8859-14', label: 'ISO-8859-14' },
  { value: 'iso-8859-15', label: 'ISO-8859-15' },
  { value: 'iso-8859-16', label: 'ISO-8859-16' },
  { value: 'gbk', label: 'GBK' },
  { value: 'gb18030', label: 'GB18030' },
  { value: 'utf-16be', label: 'UTF-16BE' },
  { value: 'utf-16le', label: 'UTF-16LE' },
  { value: 'utf-16', label: 'UTF-16' },
  { value: 'ibm850', label: 'IBM850' },
  { value: 'ibm852', label: 'IBM852' },
  { value: 'ibm437', label: 'IBM437' },
  { value: 'ibm862', label: 'IBM862' },
  { value: 'big5', label: 'BIG5' },
  { value: 'macintosh', label: 'Macintosh' },
  { value: 'ibm037', label: 'IBM037' },
  { value: 'ibm855', label: 'IBM855' },
  { value: 'ibm860', label: 'IBM860' },
  { value: 'ibm863', label: 'IBM863' },
  { value: 'ibm865', label: 'IBM865' },
  { value: 'koi8-r', label: 'KOI8-R' },
  { value: 'hz-gb-2312', label: 'HZ-GB-2312' },
  { value: 'ibm866', label: 'IBM866' },
  { value: 'koi8-u', label: 'KOI8-U' },
  { value: 'ibm00858', label: 'IBM00858' },
  { value: 'ibm01140', label: 'IBM01140' },
  { value: 'ibm1047', label: 'IBM1047' },
  { value: 'windows-874', label: 'Windows-874' },
  { value: 'windows-1250', label: 'Windows-1250' },
  { value: 'windows-1251', label: 'Windows-1251' },
  { value: 'windows-1252', label: 'Windows-1252' },
  { value: 'windows-1253', label: 'Windows-1253' },
  { value: 'windows-1254', label: 'Windows-1254' },
  { value: 'windows-1255', label: 'Windows-1255' },
  { value: 'windows-1256', label: 'Windows-1256' },
  { value: 'windows-1257', label: 'Windows-1257' },
  { value: 'windows-1258', label: 'Windows-1258' },
];

export const mapEDIFileExtensionToOption = (fileExtensions: string[] = []) => {
  return fileExtensions.map((fileExtension) => {
    return { value: fileExtension, label: fileExtension };
  });
};

// DocumentDescription describes human friendly name and description information about an EDI Document
type DocumentDescription = {
  name: string;
  number: number;
  sellerDescription: string;
  buyerDescription: string;
};

// EDI_DOCUMENTS describes the different EDI documents that Modern Dropship supports
export const EDI_DOCUMENTS: Array<DocumentDescription> = [
  {
    name: 'Inventory Update',
    number: 846,
    sellerDescription: 'Send inventory counts and availability information for products',
    buyerDescription: 'Receive inventory counts and availability information for products',
  },
  {
    name: 'Purchase Order',
    number: 850,
    sellerDescription: 'Receive orders for your available products',
    buyerDescription: 'Place orders for products',
  },
  {
    name: 'Purchase Order Acknowledgement',
    number: 855,
    sellerDescription: 'Accept or decline orders you receive',
    buyerDescription: 'See if your orders have been accepted or declined',
  },
  {
    name: 'Advance Ship Notice',
    number: 856,
    sellerDescription: 'Send shipping information for orders you have fulfilled',
    buyerDescription: 'Receive shipping information for orders that have been fulfilled',
  },
  {
    name: 'Invoice',
    number: 810,
    sellerDescription: 'Send invoice information',
    buyerDescription: 'Receive invoice information',
  },
  {
    name: 'Price Catalog',
    number: 832,
    sellerDescription: 'Send pricing information for the products you sell',
    buyerDescription: 'Receive pricing information for the products you buy',
  },
];

const EDI_BUYER_DOCUMENT_NUMBERS = [846, 850, 855, 856, 810];
const EDI_SELLER_DOCUMENT_NUMBERS = [846, 850, 855, 856, 810, 832];

export const supportEDIDocumentNumbers = (isBuyer: boolean) => {
  return EDI_DOCUMENTS.filter((doc) => {
    if (isBuyer && EDI_BUYER_DOCUMENT_NUMBERS.includes(doc.number)) {
      return doc;
    }
    if (!isBuyer && EDI_SELLER_DOCUMENT_NUMBERS.includes(doc.number)) {
      return doc;
    }
    return null;
  });
};

export const RELATION_TYPE_VARIABLE = 'variable';
export const RELATION_TYPE_CONSTANT = 'constant';
export const RELATION_TYPE_METAFIELD = 'metafield';
export const RELATION_TYPE_ATTRIBUTE = 'attribute';

// formatKey normalizes key formatting for individual data elements in an EDI document
export const formatKey = (segmentID: string, segmentIndex: number, elementIndex: number) => {
  return `${segmentID}_${segmentIndex}_${elementIndex}`;
};

type DocumentTree = { [key: string]: Array<Array<number>> };

// segmentTracker is used by recurseSequence to keep track of segment indexes
const segmentTracker: { [key: string]: number } = {};

/*
  recurseSequence handles recursion logic for sortSegmentsAndScopes,
  returning a subset of sorted segments and scopes with each call.
  sortSegmentsAndScopes uses these sorted subsets of segments to compile
  the full list for an example document.
  */
type SequenceItem = {
  type: string;
  segment: string;
  scopeName: string;
  sequence: Array<SequenceItem>;
};
const recurseSequence = (sequenceItem: SequenceItem, scopeName: string, tree: DocumentTree) => {
  const segments = [];
  let scopes: Array<string> = [];
  if (sequenceItem.type === 'segment') {
    const { segment } = sequenceItem;
    const segmentIndex = segmentTracker[segment] !== undefined ? segmentTracker[segment] : 0;
    const segmentData = {
      scopeName,
      segmentID: segment,
      segmentIndex,
      elements: tree[segment][segmentIndex],
    };
    segments.push(segmentData);
    if (segmentIndex === 0) {
      segmentTracker[segment] = 1;
    } else {
      segmentTracker[segment] += 1;
    }
  } else if (sequenceItem.type === 'sequence') {
    scopes.push(sequenceItem.scopeName);
    sequenceItem.sequence.forEach((item: SequenceItem) => {
      const { segments: innerSegments, scopes: innerScopes } = recurseSequence(
        item,
        sequenceItem.scopeName,
        tree
      );
      segments.push(...innerSegments);
      scopes = [...scopes, ...innerScopes];
    });
  }
  return { segments, scopes };
};

/*
  sortSegmentsAndScopes builds a list of sorted segments from
  the tree (with elements), and a list of sorted scopes.

  The segments are sorted in the order defined by the sequence
  and allow us to decouple render logic from state management logic.

  The scopes are sorted in the order they occur and are used as
  utility for efficient getter/setter behavior for mappings in
  the original schema structure.

  It is not intended to return all segments within the supplied tree,
  only the ones correlating with the first iteration of a scope.
  */
export const sortSegmentsAndScopes = (sequence: [], tree: DocumentTree) => {
  const sortedSegments: Array<{}> = [];
  const sortedScopes: Array<string> = [];
  if (Array.isArray(sequence)) {
    sequence.forEach((item) => {
      const { segments, scopes } = recurseSequence(item, '', tree);
      sortedSegments.push(...segments);
      sortedScopes.push(...scopes);
    });
  }
  return { sortedSegments, sortedScopes };
};

type Mapping = {
  segmentId: string;
  segmentIndex: number;
  elementIndex: number;
  mappings: { [key: string]: Mapping };
  relation: {
    type: string;
  };
};

type MappingError = {
  message: string;
  segmentID: string;
  segmentIndex: number;
  elementIndex: number;
};

const formatError = (message: string, mapping: Mapping) => {
  const { segmentId, segmentIndex, elementIndex } = mapping;
  return { message, segmentID: segmentId, segmentIndex, elementIndex };
};

const validateMapping = (mapping: Mapping) => {
  const errors = [];
  if (!mapping.relation.type) {
    errors.push(formatError('missing mapping', mapping));
  }
  return errors;
};

type ValidateMappingsFunction = (mappings: { [key: string]: Mapping }) => Array<MappingError>;

export const validateMappings: ValidateMappingsFunction = (mappings) => {
  const errorList = [];
  // eslint-disable-next-line
  for (const [_, mapping] of Object.entries(mappings)) {
    if (mapping.relation.type !== 'scope') {
      errorList.push(...validateMapping(mapping));
    } else {
      errorList.push(...validateMappings(mapping.mappings));
    }
  }
  return errorList;
};

export const formatFriendlyType = (type: string) => {
  // X12_ID --> ID
  return type.split('_')[1];
};
