import { round } from 'lodash';
import { flow } from 'lodash/fp';
import pluralize from 'pluralize';

/**
 * Returns a function that runs the input function if the input is not 0
 * @param {Function} fn Function to run if the input is not equal to 0
 * @returns {(x: number) => () => '' | fn } A function with param x that runs fn if x != 0
 */
const ifNotZero = (fn) => (x) => (x !== 0 ? fn(x) : '');

/**
 * @param {number} total number of hours as a floating point from which to extract the number of minutes
 * @returns {number} Number of minutes
 */
export const extractMinutes = (total) =>
  flow(
    (t) => t % 1,
    (x) => round(x, 2),
    (x) => x * 60,
    Math.floor
  )(total);

/**
 * @param {number} total number of hours as a floating point from which to extract exact number of hours
 * @returns {number} Number of hours
 */
export const extractHours = (total) => Math.floor(total);

const minOrMins = (amount) => pluralize('min', amount);
const hrOrHrs = (amount) => pluralize('hr', amount);

/**
 * Returns a final constructor function for the unit function supplied
 * @param { (amount: number) => string } unitFn Function to call on the amount to determine the unit for the string
 * @returns { (amount: number) => string } Function that constructs the output string
 */
const buildString = (unitFn) => (amount) => `${amount} ${unitFn(amount)}`;

/**
 * Creates a human readable string with min or mins as the unit
 * @param {number} Number of minutes
 * @returns {string}
 */
export const buildMinuteString = buildString(minOrMins);
/**
 * Creates a human readable string wit hr or hrs as the unit
 * @param {number} Number of hours
 * @returns {string}
 */
export const buildHourString = buildString(hrOrHrs);

/**
 * Formats a time as a number of hours to a human readable string of
 * XX hr(s) YY min(s)
 * @param {string | number} time To format (must be number of hours)
 * @example formatHoursToTimeString(1.5) == '1 hr 30 mins'
 * @returns { string }
 */
export const formatHoursToTimeString = (time) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(parseInt(time, 10))) return 'N/A';

  const minutes = flow(extractMinutes, ifNotZero(buildMinuteString));

  const hours = flow(extractHours, ifNotZero(buildHourString));

  return flow(
    parseFloat,
    (t) => round(t, 2),
    (t) => `${hours(t)} ${minutes(t)}`,
    (t) => (typeof t === 'string' ? t.trim() : t),
    (t) => (t === '' ? '0 mins' : t)
  )(time);
};
