/* eslint-disable jsx-a11y/label-has-associated-control */
import Grid from '@react-css/grid';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from 'components/Common/Button';
import { FormFieldLabel } from 'components/Common/FormField';
import ShopifyFormField from 'components/Common/FormField/ShopifyFormField';
import Spinner from 'components/Common/Spinner';
import Icon from 'storybook/stories/molecules/Icon';

const FormGroupButton = styled(Button)`
  margin-bottom: 1.375rem;
  width: 95%;
`;

const AccessTokenField = ({ error, register }) => {
  const cn = classNames('form-group');
  const accessTokenInputClassNames = classNames(
    'form-control',
    'form-control-prepended',
    'form-control-appended',
    {
      [`form-control-sm`]: 'sm',
    }
  );
  const [showAccessToken, setShowAccessToken] = useState(false);

  return (
    <div className={cn}>
      <FormFieldLabel size="sm" htmlFor="input-field">
        Shopify Access Token
      </FormFieldLabel>
      <div className="input-group input-group-sm">
        <input
          className={accessTokenInputClassNames}
          type={showAccessToken ? 'text' : 'password'}
          id="input-field"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('updatedToken', {
            defaultValue: '',
            required: true,
          })}
        />
        <div className="input-group-append">
          <div className="input-group-text">
            <Icon
              name={showAccessToken ? 'visibility_off' : 'visibility'}
              onClick={() => setShowAccessToken(!showAccessToken)}
            />
          </div>
        </div>
      </div>
      {error ? <div className="invalid-feedback d-block">{error.message}</div> : null}
    </div>
  );
};

const EditShopifySettings = ({ isEditable, isEditingAccessToken, onSubmit, onCancel, store }) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });

  const pendingUpdate = useSelector(({ company: c }) => c.isUpdatingPlatformAuth);

  useEffect(() => reset(), [reset]);

  const submitForm = (values) => handleSubmit(onSubmit)(values).then(() => reset());

  return (
    <form onSubmit={submitForm}>
      <Grid>
        {isEditable && (
          <ShopifyFormField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('updatedStore', {
              required: 'Please enter a store name',
              pattern: {
                value: /^[-a-zA-Z0-9]+$/,
                message: 'Please enter a valid store name',
              },
            })}
            error={errors.store}
            size="sm"
            defaultValue={store}
          />
        )}
        {isEditingAccessToken && (
          <AccessTokenField error={errors.updatedToken} register={register} />
        )}
      </Grid>
      <Grid columns="1fr 1fr 1fr 1fr" alignContentEnd justifyItemsEnd>
        <div />
        <div />
        <FormGroupButton size="sm" type="button" color="secondary" outline onClick={onCancel}>
          Cancel
        </FormGroupButton>
        <FormGroupButton
          size="sm"
          type="submit"
          color={isValid ? 'primary' : 'dark'}
          disabled={!isValid}
        >
          {pendingUpdate ? <Spinner color="primary" small className="mx-3" /> : 'Save'}
        </FormGroupButton>
      </Grid>
    </form>
  );
};

export default EditShopifySettings;
