import type { Size } from 'storybook/stories/cells/Modal';
import Modal from 'storybook/stories/cells/Modal';

import { ButtonKinds } from 'storybook/stories/molecules/Button';
import type { PrimaryButtonKinds } from 'storybook/stories/molecules/Button/PrimaryButton';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

export interface ConfirmationModalProps {
  show?: boolean;
  title?: string;
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonKind?: PrimaryButtonKinds;
  cancelButtonKind?: ButtonKinds;
  size?: Size;
}

const ConfirmationModal = ({
  show = false,
  title = 'Are you sure?',
  children = null,
  confirmText = 'Confirm',
  cancelText = 'Back',
  onConfirm = () => {},
  onCancel = () => {},
  confirmButtonKind = ButtonKinds.Action,
  cancelButtonKind = ButtonKinds.Action,
  size,
}: ConfirmationModalProps) => {
  return (
    <Modal.Root show={show} onHide={() => onCancel()} size={size} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <SecondaryButton
          aria-label="confirmation-cancel-button"
          size="small"
          onClick={onCancel}
          kind={cancelButtonKind}
        >
          {cancelText}
        </SecondaryButton>

        <PrimaryButton
          aria-label="confirmation-confirm-button"
          size="small"
          onClick={onConfirm}
          kind={confirmButtonKind}
        >
          {confirmText}
        </PrimaryButton>
      </Modal.Footer>
    </Modal.Root>
  );
};

export default ConfirmationModal;
