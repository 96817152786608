import { useSelector } from 'react-redux';

import { selectCompany } from 'store/selectors/me/company';

export const DISABLED_STORE_RENAME_REASONS = {
  isAuthorized:
    'You cannot change your store name because you have already connected your store. Please contact support@convictional.com for more information on how to change your store name.',
  default:
    'Please contact support@convictional.com for more information on how to change your store name.',
};

export const useCanEditStoreName = () => {
  const company = useSelector(selectCompany);

  const isShopifyConnected = company?.productPlatformActive ?? false;

  // Allow any disconnected user to change their store name
  if (!isShopifyConnected) return [true, null];

  // Block any connected user from changing their store name
  if (isShopifyConnected) return [false, DISABLED_STORE_RENAME_REASONS.isAuthorized];

  // Disabled by default
  return [false, DISABLED_STORE_RENAME_REASONS.default];
};
