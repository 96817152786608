import type { Order20210101 } from 'types/models/order';
import { ChangeList, type ChangeListProps, type ChangeRenderer } from '.';

export const orderChangeRenderers: Record<string, ChangeRenderer<Order20210101>> = {
  note: {
    friendlyName: 'Note',
    from: (val) => val,
    to: (val) => val,
  },
};

const OrderChangeList = ({
  changes,
  snapshot,
}: Omit<ChangeListProps<Order20210101>, 'renderers'>) => {
  return <ChangeList changes={changes} snapshot={snapshot} renderers={orderChangeRenderers} />;
};

export default OrderChangeList;
