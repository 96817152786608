import Timeline from 'storybook/stories/organisms/Timeline';
import type { EventComponentProps } from '..';
import { willRenderChanges } from '../ChangeList';
import PriceListChangeList, { priceListChangeRenderers } from '../ChangeList/PriceListChangeList';

const PriceListUpdatedPayload = ({ event }: EventComponentProps) => {
  if (!willRenderChanges(event.changes, priceListChangeRenderers())) {
    return (
      <Timeline.Event iconName="edit" timestamp={event.createdAt} title="Price List Updated" />
    );
  }

  return (
    <Timeline.Event iconName="edit" timestamp={event.createdAt} title="Price List Updated">
      <PriceListChangeList changes={event.changes} snapshot={event.snapshot} />
    </Timeline.Event>
  );
};

export default PriceListUpdatedPayload;
