import Flex from '@react-css/flex';
import { Link } from 'react-router-dom';

import DoneCheckmark from 'components/Common/DoneCheckmark';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Chip from 'storybook/stories/molecules/Chip';
import type { PartnerAction } from 'types/models/partner-action';

import { ActionCard, CardText, CardTitle } from '../Shared.styles';

interface TermsAndConditionsActionProps {
  action: PartnerAction;
}

const TermsAndConditionsAction = ({ action }: TermsAndConditionsActionProps) => {
  const { id, cardTitle, cardText, required, completed, agreementId } = action;

  return (
    <ActionCard>
      <Flex.Item>
        <DoneCheckmark done={completed} className="" />
      </Flex.Item>

      <Flex.Item>
        <Flex gap="8px">
          <CardTitle>{cardTitle}</CardTitle>
          {!required && <Chip size="small">Optional</Chip>}
        </Flex>

        <CardText>{cardText}</CardText>

        {completed && <p className="small">Agreement has been signed.</p>}

        {!completed && agreementId && (
          <SecondaryButton as={Link} to={`/agreements/${agreementId}?action=${id}`}>
            Sign Agreement
          </SecondaryButton>
        )}
      </Flex.Item>
    </ActionCard>
  );
};

export default TermsAndConditionsAction;
