import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getProduct } from 'utils/api/products';

/**
 * Hook used to fetch product data by ID
 */
const useFetchProduct = ({ productId }) => {
  const queryClient = useQueryClient();

  const queryKey = ['getProduct', productId];

  const { data, error, isLoading } = useQuery({
    queryKey,
    queryFn: () => getProduct(productId),
    onSuccess: (productData) => {
      console.info('Fetched Product', productData);
    },
    onError: (err) => {
      console.error('Fetch Product Error', err);
    },
  });

  const invalidateProductQuery = () => {
    queryClient.invalidateQueries(queryKey);
  };

  const removeProductQuery = () => {
    queryClient.removeQueries(queryKey);
  };

  const setProductQueryData = (param) => {
    queryClient.setQueriesData(queryKey, param);
  };

  return {
    product: data?.data,
    isLoadingProduct: isLoading,
    fetchProductError: error,
    invalidateProductQuery,
    removeProductQuery,
    setProductQueryData,
  };
};

export default useFetchProduct;
