import useOnboardingStorage from 'hooks/useOnboardingStorage';
import { Redirect } from 'react-router';
import { useAppSelector } from 'store';
import {
  selectCompany,
  selectIsDropshipSetup,
  selectIsGetStartedComplete,
} from 'store/selectors/me/company';
import { selectUser } from 'store/selectors/me/user';

const HomePage = () => {
  const user = useAppSelector(selectUser);
  const company = useAppSelector(selectCompany);
  const isGetStartedComplete = useAppSelector(selectIsGetStartedComplete);
  const isDropshipSetup = useAppSelector(selectIsDropshipSetup);
  const { onboardingStorage } = useOnboardingStorage();

  // If we're not authenticated, redirect to login
  if (!user) return <Redirect to="/login" />;

  // If a user is new or if they're creating another company, redirect to onboarding
  if (!company || onboardingStorage?.isCreatingAnotherCompany) return <Redirect to="/onboarding" />;

  // If a user's company is a dropship company, and the user hasn't completed the get started flow, redirect to get started
  if (isDropshipSetup && !isGetStartedComplete) return <Redirect to="/get-started" />;

  // Otherwise, redirect the dropship user orders
  return <Redirect to="/orders" />;
};

export default HomePage;
