import Flex from '@react-css/flex';
import Card from 'storybook/stories/cells/Card';
import Heading from 'storybook/stories/molecules/Heading';
import Toggle from 'storybook/stories/molecules/Toggle';
import type { Partner } from 'types/models/partner';
import type { UpdatePartnerParams } from 'utils/api/partners';

interface PartnerFlagSettingsProps {
  partner: Partner;
  updatePartner: (params: UpdatePartnerParams) => void;
}

const PartnerFlagSettings = ({ partner, updatePartner }: PartnerFlagSettingsProps) => {
  return (
    <Card>
      <Card.Head>
        <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
          Partner Flags
        </Heading>
      </Card.Head>

      <Card.Body>
        <Flex column gap="16px">
          <Toggle
            defaultChecked={partner.billingSettings?.autoTax}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updatePartner({ billingSettings: { autoTax: event.target.checked } })
            }
          >
            Auto Tax
          </Toggle>

          <Toggle
            defaultChecked={partner.requireFulfillmentTrackingInfo}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updatePartner({ requireFulfillmentTrackingInfo: event.target.checked })
            }
          >
            Require Fulfillment Tracking Info
          </Toggle>

          <Toggle
            defaultChecked={partner.syncSellerCompareAtPrice}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updatePartner({ syncSellerCompareAtPrice: event.target.checked })
            }
          >
            Sync Seller Compare-At Price
          </Toggle>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default PartnerFlagSettings;
