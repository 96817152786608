import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import useDateFilterSelectOptions from 'hooks/useDateFilterSelectOptions';
import type { DateSelectOption } from 'hooks/useDateSelectOptions';
import usePartnerFilterSelectOptions from 'hooks/usePartnerFilterSelectOptions';
import { isEmpty, merge } from 'lodash';
import { Controller, useForm, type SubmitHandler } from 'react-hook-form';
import type { SingleValue } from 'react-select';
import type { DropdownOptions } from 'storybook/stories/cells/Dropdown';
import Dropdown from 'storybook/stories/cells/Dropdown';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Label from 'storybook/stories/molecules/Label';
import RadioButton from 'storybook/stories/molecules/RadioButton';
import Select from 'storybook/stories/molecules/Select';
import type { ReactSelectOption } from 'types/general';
import type { OrderSearchAggregationData, OrderSearchFacets } from 'types/models/search';
import type { OrderSearchFilters, OrderSearchParams } from 'utils/api/orders';

const DEFAULT_FACETS: OrderSearchFacets = {
  hasPendingReturns: {
    false: 0,
    true: 0,
  },
  hasReturns: {
    false: 0,
    true: 0,
  },
  invoiced: {
    false: 0,
    true: 0,
  },
  paid: {
    false: 0,
    true: 0,
  },
  posted: {
    false: 0,
    true: 0,
  },
  shipped: {
    false: 0,
    true: 0,
  },
  flagged: {
    false: 0,
    true: 0,
  },
  cancelled: {
    false: 0,
    true: 0,
  },
  buyerId: {},
  sellerId: {},
};

export type OrderFiltersFormData = Omit<OrderSearchFilters, 'buyerId' | 'sellerId'> & {
  partner: SingleValue<ReactSelectOption>;
  dateRange: SingleValue<DateSelectOption>;
};

interface FiltersDropdownProps extends DropdownOptions {
  aggregations?: OrderSearchAggregationData['aggregations'];
  onFiltersFormSubmit: SubmitHandler<OrderFiltersFormData>;
  onFiltersFormReset: () => void;
  searchParams: OrderSearchParams;
}

const FiltersDropdown = ({
  aggregations,
  onFiltersFormSubmit,
  onFiltersFormReset,
  searchParams,
  ...dropdownOptions
}: FiltersDropdownProps) => {
  const facets = merge({}, DEFAULT_FACETS, aggregations?.facets);

  const filters = searchParams?.filters;
  const createdAt = searchParams?.createdAt;

  const { dateSelectOptions, defaultDateSelectOption } = useDateFilterSelectOptions(createdAt);
  const { partnerSelectOptions, defaultPartnerSelectOption } = usePartnerFilterSelectOptions(
    facets,
    filters
  );

  const { register, handleSubmit, control, reset, setValue, formState } =
    useForm<OrderFiltersFormData>({
      mode: 'onChange',
      defaultValues: {
        partner: undefined,
        dateRange: undefined,
        paid: undefined,
        posted: undefined,
        shipped: undefined,
        hasPendingReturns: undefined,
        hasReturns: undefined,
        invoiced: undefined,
        flagged: undefined,
        cancelled: undefined,
      },
    });

  return (
    <Dropdown {...dropdownOptions}>
      <Dropdown.Trigger asChild>
        {isEmpty(filters) && isEmpty(createdAt) ? (
          <SecondaryButton $iconName="filter_alt">Filters</SecondaryButton>
        ) : (
          <PrimaryButton $iconName="filter_alt">Filters</PrimaryButton>
        )}
      </Dropdown.Trigger>
      <Dropdown.Content>
        {({ close }) => (
          <form
            onSubmit={handleSubmit((values) => {
              onFiltersFormSubmit(values);
              close();
            })}
          >
            <Flex column gap="32px">
              <Dropdown.Header iconName="filter_alt" heading="Filters" />

              <Grid columns="1fr 1fr" gap="32px">
                <Flex column gap="16px">
                  <Label htmlFor="dateRange">Date Range</Label>
                  <Controller
                    name="dateRange"
                    control={control}
                    defaultValue={defaultDateSelectOption}
                    render={({ field: { ref, ...restFieldProps } }) => (
                      <Select
                        innerRef={ref}
                        {...restFieldProps}
                        options={dateSelectOptions}
                        isSearchable={false}
                        isClearable={false}
                        inputId="dateRange"
                      />
                    )}
                  />
                </Flex>

                <Flex column gap="16px">
                  <Label htmlFor="partner">Partner</Label>
                  <Controller
                    name="partner"
                    control={control}
                    defaultValue={defaultPartnerSelectOption}
                    render={({ field: { ref, ...restFieldProps } }) => (
                      <Select
                        innerRef={ref}
                        {...restFieldProps}
                        isFullWidth
                        options={partnerSelectOptions}
                        inputId="partner"
                      />
                    )}
                  />
                </Flex>
              </Grid>

              <Grid columns="1fr 1fr" gap="32px">
                <Flex column gap="16px">
                  <Label htmlFor="paid">Paid</Label>

                  <RadioButton
                    {...register('paid')}
                    value="true"
                    defaultChecked={filters?.paid === 'true'}
                  >
                    Paid ({facets.paid.true})
                  </RadioButton>

                  <RadioButton
                    {...register('paid')}
                    value="false"
                    defaultChecked={filters?.paid === 'false'}
                  >
                    Not Paid ({facets.paid.false})
                  </RadioButton>
                </Flex>

                <Flex column gap="16px">
                  <Label htmlFor="posted">Posted</Label>

                  <RadioButton
                    {...register('posted')}
                    value="true"
                    defaultChecked={filters?.posted === 'true'}
                  >
                    Posted ({facets.posted.true})
                  </RadioButton>

                  <RadioButton
                    {...register('posted')}
                    value="false"
                    defaultChecked={filters?.posted === 'false'}
                  >
                    Not Posted ({facets.posted.false})
                  </RadioButton>
                </Flex>

                <Flex column gap="16px">
                  <Label htmlFor="shipped">Shipped</Label>

                  <RadioButton
                    {...register('shipped')}
                    value="true"
                    defaultChecked={filters?.shipped === 'true'}
                  >
                    Shipped ({facets.shipped.true})
                  </RadioButton>

                  <RadioButton
                    {...register('shipped')}
                    value="false"
                    defaultChecked={filters?.shipped === 'false'}
                  >
                    Not Shipped ({facets.shipped.false})
                  </RadioButton>
                </Flex>

                <Flex column gap="16px">
                  <Label htmlFor="hasPendingReturns">Pending Returns</Label>

                  <RadioButton
                    {...register('hasPendingReturns')}
                    value="true"
                    defaultChecked={filters?.hasPendingReturns === 'true'}
                  >
                    With Pending Returns ({facets.hasPendingReturns.true})
                  </RadioButton>

                  <RadioButton
                    {...register('hasPendingReturns')}
                    value="false"
                    defaultChecked={filters?.hasPendingReturns === 'false'}
                  >
                    Without Pending Returns ({facets.hasPendingReturns.false})
                  </RadioButton>
                </Flex>

                <Flex column gap="16px">
                  <Label htmlFor="hasReturns">Returns</Label>

                  <RadioButton
                    {...register('hasReturns')}
                    value="true"
                    defaultChecked={filters?.hasReturns === 'true'}
                  >
                    With Returns ({facets.hasReturns.true})
                  </RadioButton>

                  <RadioButton
                    {...register('hasReturns')}
                    value="false"
                    defaultChecked={filters?.hasReturns === 'false'}
                  >
                    Without Returns ({facets.hasReturns.false})
                  </RadioButton>
                </Flex>

                <Flex column gap="16px">
                  <Label htmlFor="invoiced">Invoiced</Label>

                  <RadioButton
                    {...register('invoiced')}
                    value="true"
                    defaultChecked={filters?.invoiced === 'true'}
                  >
                    Invoiced ({facets.invoiced.true})
                  </RadioButton>

                  <RadioButton
                    {...register('invoiced')}
                    value="false"
                    defaultChecked={filters?.invoiced === 'false'}
                  >
                    Not Invoiced ({facets.invoiced.false})
                  </RadioButton>
                </Flex>

                <Flex column gap="16px">
                  <Label htmlFor="flagged">Flagged</Label>

                  <RadioButton
                    {...register('flagged')}
                    value="true"
                    defaultChecked={filters?.flagged === 'true'}
                  >
                    Flagged ({facets.flagged.true})
                  </RadioButton>

                  <RadioButton
                    {...register('flagged')}
                    value="false"
                    defaultChecked={filters?.flagged === 'false'}
                  >
                    Not Flagged ({facets.flagged.false})
                  </RadioButton>
                </Flex>

                <Flex column gap="16px">
                  <Label htmlFor="cancelled">Cancelled</Label>

                  <RadioButton
                    {...register('cancelled')}
                    value="true"
                    defaultChecked={filters?.cancelled === 'true'}
                  >
                    Cancelled ({facets.cancelled.true})
                  </RadioButton>

                  <RadioButton
                    {...register('cancelled')}
                    value="false"
                    defaultChecked={filters?.cancelled === 'false'}
                  >
                    Not Cancelled ({facets.cancelled.false})
                  </RadioButton>
                </Flex>
              </Grid>

              <Flex justifyEnd gap="8px">
                <TertiaryButton
                  type="reset"
                  onClick={() => {
                    reset(formState.defaultValues);
                    setValue('partner', partnerSelectOptions[0]);
                    setValue('dateRange', dateSelectOptions[0]);
                    onFiltersFormReset();
                    close();
                  }}
                >
                  Clear All
                </TertiaryButton>

                <PrimaryButton type="submit">Apply</PrimaryButton>
              </Flex>
            </Flex>
          </form>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default FiltersDropdown;
