import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { updateOnboardingStatus } from 'store/slices/company';
import useInviteUser from 'utils/onboarding';

import Flex from '@react-css/flex';
import CardFormField from 'components/Common/CardFormField';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import TeamMemberTable from './TeamMemberTable';

const InviteTeamCard = ({ done, company, user }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const { inviteUserError, inviteUserPending, inviteUser } = useInviteUser();
  const dispatch = useDispatch();
  const teamMembers = company.users ? company.users.filter((u) => u.userId !== user._id) : [];
  const hasInvitedSomeone = teamMembers.length > 0;

  const onSubmit = ({ firstName, email }) => {
    inviteUser({ firstName, email });
    reset();
  };

  const updateInviteUserStatus = (status) => {
    dispatch(updateOnboardingStatus({ inviteTeamCompleted: status }));
  };

  return (
    <GetStartedCard
      isComplete={done}
      title="Invite your Team"
      completedUi={
        <Body>
          Manage your team from your{' '}
          <Link to="/settings/company/team-members">Team Member settings</Link>.
        </Body>
      }
    >
      <Flex column gap="16px">
        <Body as="p">
          You can invite other team members to help you manage your company&apos;s account.{' '}
          <SupportLink article="dvzdivcsdu">More Info</SupportLink>
        </Body>

        <TeamMemberTable teamMembers={teamMembers} />

        {inviteUserError && (
          <Body color="error500">{`Error inviting user: ${inviteUserError}`}</Body>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex column gap="16px">
            <CardFormField
              type="text"
              id="invite-fname-input"
              label="First Name"
              placeholder="Jane"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('firstName', {
                required: 'Please enter a first name',
              })}
              error={errors.firstName}
            />
            <CardFormField
              type="email"
              id="invite-email-input"
              label="Email"
              placeholder="jane@example.com"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('email', {
                required: 'Please enter an email',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please enter a valid email address',
                },
              })}
              error={errors.email}
            />
            <Flex.Item alignSelfEnd>
              <Flex gap="8px">
                <SecondaryButton
                  size="small"
                  onClick={() =>
                    updateInviteUserStatus(hasInvitedSomeone ? 'completed' : 'skipped')
                  }
                >
                  {hasInvitedSomeone ? 'Mark as Completed' : 'Skip'}
                </SecondaryButton>

                <PrimaryButton size="small" type="submit" disabled={inviteUserPending}>
                  Send Invite
                </PrimaryButton>
              </Flex>
            </Flex.Item>
          </Flex>
        </form>
      </Flex>
    </GetStartedCard>
  );
};

export default InviteTeamCard;
