/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';

import Button from 'components/Common/Button';
import { getSupportButtons } from 'containers/TradeOpsIssues/Shared';
import Icon from 'storybook/stories/molecules/Icon';
import { archiveTradeOpsIssues, reopenTradeOpsIssues } from 'utils/api/issues';

import { prettyDateTime } from 'utils/date';
import SeverityBadge from './SeverityBadge';

// getResourceCell returns a clickable link for some resources
const getResourceCell = (selectedError) => {
  const CLICKABLE_RESOURCES = ['products', 'orders', 'prices', 'partners']; // Frontend page that relates
  if (CLICKABLE_RESOURCES.includes(selectedError.resource)) {
    return (
      <a
        href={`/${selectedError.resource}/${selectedError.resourceId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <code>{selectedError.resourceId}</code> <Icon name="link" />
      </a>
    );
  }
  return <code>{selectedError.resourceId}</code>;
};

const SelectedErrorMenu = ({ selectedError, onAfterReopen, onAfterArchive }) => {
  const [isLoading, setIsLoading] = useState(false);

  const nextSteps = getSupportButtons(selectedError);

  const reopenSelectedError = (e) => {
    e.preventDefault();

    reopenTradeOpsIssues({
      errors: [selectedError?.id],
    })
      .then(onAfterReopen(selectedError?.id))
      .catch(() => {})
      .then(() => setIsLoading(false));
  };

  const archiveSelectedError = (e) => {
    e.preventDefault();

    archiveTradeOpsIssues({
      errors: [selectedError?.id],
    })
      .then(onAfterArchive(selectedError?.id))
      .catch(() => {})
      .then(() => setIsLoading(false));
  };

  return (
    <div data-testid="selected-error-menu">
      {nextSteps && (
        <div className="next-steps-wrapper">
          <label className="message-label">Best Next Steps:</label>
          <div>{nextSteps}</div>
        </div>
      )}
      <table className="table table-sm">
        <tbody>
          <tr>
            <td>Code</td>
            <td>
              <code>{selectedError.errorCode}</code>
            </td>
          </tr>
          <tr>
            <td>Key</td>
            <td>
              <code>{selectedError.id}</code>
            </td>
          </tr>
          <tr>
            <td>Resource</td>
            <td>
              <code>{selectedError.resource}</code>
            </td>
          </tr>
          <tr>
            <td>Resource ID</td>
            <td>{getResourceCell(selectedError)}</td>
          </tr>
          <tr>
            <td>Created</td>
            <td>{prettyDateTime(selectedError.created)}</td>
          </tr>
          <tr>
            <td>Severity</td>
            <td>
              <SeverityBadge severity={selectedError.severity} />
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              {selectedError.archived ? (
                <span className="badge bg-success-soft">Archived</span>
              ) : (
                <span className="badge bg-primary-soft">Open</span>
              )}
            </td>
          </tr>
          {selectedError.archived && (
            <>
              <tr>
                <td>Archived By</td>
                <td>{selectedError.archivedBy}</td>
              </tr>
              <tr>
                <td>Archived At</td>
                <td>{prettyDateTime(selectedError.archivedAt)}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      {!isLoading && selectedError.archived ? (
        <Button
          size="sm"
          outline
          color="secondary"
          className="archive-btn"
          onClick={reopenSelectedError}
        >
          <Icon name="visibility">Reopen</Icon>
        </Button>
      ) : (
        <Button
          size="sm"
          outline
          color="secondary"
          className="archive-btn"
          onClick={archiveSelectedError}
        >
          <Icon name="done">Archive</Icon>
        </Button>
      )}
    </div>
  );
};

export default SelectedErrorMenu;
