import PropTypes from 'prop-types';
import styled from 'styled-components';

const Element = styled.button`
  align-items: center;
  padding: 4px 6px;
  margin: -4px -6px;
  height: 30px;
  margin-bottom: 5px;
  cursor: pointer;
  color: #2c7be5;
  white-space: nowrap;
  margin-right: 20px;
  border: none;
  background: none;
`;

const selected = {
  borderRadius: '4px',
  backgroundColor: '#2c7be5',
  color: '#f9fbfd',
};

const formatIDForDisplay = (segmentID, elementIndex) => {
  return `${segmentID}-${(elementIndex + 1).toString().padStart(2, '0')}`;
};

const ElementID = (props) => {
  const {
    segmentID = '',
    elementIndex = -1,
    isSelected = false,
    onClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
  } = props;

  const formattedID = formatIDForDisplay(segmentID, elementIndex);

  return (
    <Element
      style={isSelected ? selected : {}}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {formattedID}
    </Element>
  );
};

export default ElementID;

ElementID.propTypes = {
  segmentID: PropTypes.string,
  elementIndex: PropTypes.number,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};
