import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';

import useAlertQueue from 'hooks/useAlertQueue';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import TextAreaInput from 'storybook/stories/molecules/Input/TextAreaInput';
import Label from 'storybook/stories/molecules/Label';
import { requestSupport } from 'utils/api/support';

interface SupportModalProps {
  show: boolean;
  onHide: () => void;
}

const SupportModal = ({ show, onHide }: SupportModalProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const requestingSupport = useMutation({
    mutationFn: (message: string) => requestSupport(message),
    onSuccess: () => {
      addSuccessAlert('Success', 'Your message has been sent.');
      onHide();
    },
    onError: (error) => {
      addErrorAlert('Something went wrong', 'We were unable to send your support request.');
      console.error('Error submitting support request', error);
    },
  });

  interface FormData {
    message: string;
  }

  const { register, handleSubmit, formState } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      message: '',
    },
  });

  const onSubmit = (data: FormData) => {
    requestingSupport.mutate(data.message);
  };

  const isCtaDisabled = !formState.isValid || requestingSupport.isLoading;

  return (
    <Modal.Root show={show} onHide={onHide} centered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Submit an Issue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex column gap="24px">
            <Body variant="Body/Body Small" color="bodyTextSecondary">
              Send us a bug report. In the meantime, you can try searching though our{' '}
              <a
                href="https://support.moderndropship.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                support documentation
              </a>{' '}
              to look for help there.
            </Body>
            <Flex column gap="20px">
              <Flex column gap="8px">
                <Label htmlFor="message" isRequired>
                  Message
                </Label>
                <TextAreaInput
                  id="message"
                  {...register('message', { required: true })}
                  placeholder="Please describe the issue you are experiencing"
                />
              </Flex>
            </Flex>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <TertiaryButton kind="neutral" onClick={onHide}>
            Cancel
          </TertiaryButton>

          <PrimaryButton type="submit" disabled={isCtaDisabled} $iconName="send" $iconAfter>
            Send Message
          </PrimaryButton>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default SupportModal;
