import useOnboardingStorage from 'hooks/useOnboardingStorage';
import { SHOPIFY_APP_STORE_URL } from 'utils/constants';

const ResetShopifyLink: React.FC<{}> = () => {
  const { setOnboardingStorage } = useOnboardingStorage();
  const onClick = () =>
    setOnboardingStorage((previous) => {
      if (previous) {
        // Retain CommerceType and Platform, putting user back at Step 3 for Shopify Store URL
        return { ...previous, currentStep: 3 };
      }
      return undefined;
    });
  return (
    <a href={SHOPIFY_APP_STORE_URL} rel="noopener noreferrer" onClick={onClick}>
      go back to Shopify and install the Modern Dropship app in the desired store
    </a>
  );
};

export default ResetShopifyLink;
