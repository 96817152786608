import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import { archiveTradeOpsIssues } from 'utils/api/issues';

const TradeOpsIssuesPageNav = ({
  searchQuery,
  resources,
  showArchived,
  errors,
  isSeveritySelected,
  isLoading,
}) => {
  if (isEmpty(searchQuery) && isEmpty(resources)) return null;
  if (isSeveritySelected) return null;
  if (isEmpty(errors)) return null;
  if (showArchived) return null;

  const archiveAllIssues = (event) => {
    event.preventDefault();

    archiveTradeOpsIssues({
      errors: errors.map(({ id }) => id),
    });
  };

  return (
    <SecondaryButton size="small" onClick={archiveAllIssues} disabled={isLoading}>
      Archive
    </SecondaryButton>
  );
};

TradeOpsIssuesPageNav.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  resources: PropTypes.array.isRequired,
  showArchived: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  isSeveritySelected: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TradeOpsIssuesPageNav;
