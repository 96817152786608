import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import { selectUserMemberships } from 'store/selectors/me/user';
import Card from 'storybook/stories/cells/Card';
import PendingInvite from 'storybook/stories/organisms/PendingInvite';
import { getPartners } from 'utils/api/partners';

const PendingInviteCard = () => {
  const company = useAppSelector(selectCompany);
  const memberships = useSelector(selectUserMemberships);
  const fetchingPendingPartnerInvites = useQuery({
    queryKey: ['getPartners', { pending: true }],
    queryFn: () => getPartners({ pending: true }),
  });

  const pendingPartners = fetchingPendingPartnerInvites.data?.data ?? [];

  if (pendingPartners.length === 0) return null;

  return (
    <Card>
      <>
        <h2 className="mb-3">Pending Invites</h2>

        {pendingPartners.map((partner) => {
          return (
            <PendingInvite
              key={partner._id}
              selectedMembership={company}
              selectableMemberships={memberships}
              pendingPartner={partner}
            />
          );
        })}
      </>
    </Card>
  );
};

export default PendingInviteCard;
