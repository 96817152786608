import FloatingChatUI from 'components/Dashboard/FloatingChatUI';
import SudoWarning from 'components/Dashboard/SudoWarning';
import LeftMenu from './LeftMenu';
import TradeOpsIssuesAlert from './TradeOpsIssuesAlert';

const DashboardPage = ({ mainContentRef = undefined, children }) => {
  return (
    <>
      <LeftMenu />

      <div
        id="main-content"
        className="main-content"
        ref={mainContentRef}
        data-testid="main-content"
      >
        <div className="container-fluid">
          <TradeOpsIssuesAlert />

          <SudoWarning />

          {children}

          <FloatingChatUI />
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
