import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { prettyDateTime } from 'utils/date';

import SeverityBadge from './SeverityBadge';
import { getBadgeBySeverity, getFriendlyErrorCode } from './Shared';

const TradeOpsIssuesList = ({ isLoading, errors, onErrorClick, selectedError }) => {
  if (isEmpty(errors) && !isLoading) {
    return (
      <div>
        <p className="no-results-text">No Results</p>
        <p className="no-results-subtext muted">Try filtering with filters above</p>
      </div>
    );
  }

  return (
    <>
      <table className="table table-sm">
        <tbody>
          {errors.map((error) => (
            <tr
              className={classNames('error-row', {
                'error-row-selected': selectedError?.id === error.id,
              })}
              onClick={(event) => onErrorClick(event, error)}
              key={error.id}
            >
              <td>
                <div className="error-message-wrapper">{error.message}</div>
                {error.severity === 'critical' && (
                  <div className="severity-wrapper">
                    <SeverityBadge severity={error.severity} />
                  </div>
                )}
                <span className={`badge error-code-badge ${getBadgeBySeverity(error.severity)}`}>
                  {getFriendlyErrorCode(error.errorCode)}
                </span>
                <span className="date-span">{prettyDateTime(error.created)}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isLoading && (
        <div className="row">
          <div className="col-md-12 col-lg-8">
            <div className="spinner-border text-primary" role="status" />
          </div>
        </div>
      )}
    </>
  );
};

TradeOpsIssuesList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  onErrorClick: PropTypes.func.isRequired,
  selectedError: PropTypes.object.isRequired,
};

export default TradeOpsIssuesList;
