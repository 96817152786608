import useHtmlClassObserver from 'hooks/useHtmlClassObserver';
import { useEffect, useState } from 'react';
import Modal from 'storybook/stories/cells/Modal';
import Body from 'storybook/stories/molecules/Body';

const GoogleTranslateWarningModal = () => {
  const htmlElementClassNames = useHtmlClassObserver(document.documentElement);

  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [hasModalBeenSeen, setHasModalBeenSeen] = useState(false);

  useEffect(() => {
    if (htmlElementClassNames.some((className) => className.startsWith('translated'))) {
      setShouldShowModal(true);
    } else {
      setShouldShowModal(false);
      setHasModalBeenSeen(false);
    }
  }, [htmlElementClassNames]);

  return (
    <Modal.Root
      show={shouldShowModal && !hasModalBeenSeen}
      centered
      onHide={() => setHasModalBeenSeen(true)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Google Chrome Translation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Body as="p">
          Due to a bug in Google Chrome, we are currently unable to support the Google Translate
          feature. If you choose to continue using Google Translate, you may experience errors while
          using this application. You may choose to disable Google Translate or use a different
          browser. We&apos;re sorry for the inconvenience and are working to remedy this issue.
        </Body>
      </Modal.Body>
    </Modal.Root>
  );
};

export default GoogleTranslateWarningModal;
