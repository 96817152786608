import Grid from '@react-css/grid';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import type { SingleValue } from 'react-select';
import Select from 'react-select';
import styled, { css } from 'styled-components';

import Icon from 'storybook/stories/molecules/Icon';
import Input from 'storybook/stories/molecules/Input';
import type { FieldValidationRules } from 'types/models/buyer-product-validation';

import type { Option } from './constants';
import { PRODUCT_FIELD_OPTIONS, REACT_SELECT_CUSTOM_STYLES, ValidationTypes } from './constants';
import WrapperButton from './index.shared';

const ConditionLabel = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  letter-spacing: -0.01em;
  border-left: 0px;
  border-radius: 8px;

  ${({ theme }) => css`
    color: ${theme.color.gray600};
    background: ${theme.color.gray100};
    font-family: ${theme.font.primary};
    border: 1px solid ${theme.color.gray200};
  `};
`;

const DeleteIcon = styled(Icon).attrs({ name: 'delete_forever' })`
  margin-left: 20px;
  float: right;

  ${({ theme }) => css`
    color: ${theme.color.gray500};
  `};
`;

enum FieldTypes {
  String = 'string',
  Int = 'int',
  Float = 'float',
  Bool = 'bool',
  Images = 'images',
  GoogleProductCategory = 'googleProductCategory',
}

export const PRODUCT_FIELD_TYPES: { [key: string]: FieldTypes } = {
  sellerReference: FieldTypes.String,
  description: FieldTypes.String,
  images: FieldTypes.Images,
  tags: FieldTypes.String,
  title: FieldTypes.String,
  brand: FieldTypes.String,
  googleProductCategory: FieldTypes.GoogleProductCategory,
  'variant.title': FieldTypes.String,
  'variant.retailPrice': FieldTypes.Float,
  'variant.inventoryAmount': FieldTypes.Int,
  'variant.skipCount': FieldTypes.Bool,
  'variant.weight': FieldTypes.Float,
  'variant.weightUnits': FieldTypes.String,
  'variant.dimensions.length': FieldTypes.Float,
  'variant.dimensions.width': FieldTypes.Float,
  'variant.dimensions.height': FieldTypes.Float,
  'variant.dimensions.units': FieldTypes.String,
  'variant.sku': FieldTypes.String,
  'variant.barcode': FieldTypes.String,
  'variant.barcodeType': FieldTypes.String,
};

export const STRING_VALIDATIONS = [
  {
    label: 'is greater than',
    value: ValidationTypes.Min,
  },
  {
    label: 'is less than',
    value: ValidationTypes.Max,
  },
  {
    label: 'must be',
    value: ValidationTypes.Equals,
  },
  {
    label: 'cannot be',
    value: ValidationTypes.NotEquals,
  },
  {
    label: 'is one of',
    value: ValidationTypes.OneOf,
  },
];

export const STRING_UNIQUE_VALIDATIONS = [
  ...STRING_VALIDATIONS,
  {
    label: 'is unique',
    value: ValidationTypes.Unique,
  },
];

export const INT_VALIDATIONS = [
  {
    label: 'is greater than',
    value: ValidationTypes.Min,
  },
  {
    label: 'is less than',
    value: ValidationTypes.Max,
  },
  {
    label: 'must be',
    value: ValidationTypes.Equals,
  },
  {
    label: 'cannot be',
    value: ValidationTypes.NotEquals,
  },
  {
    label: 'is one of',
    value: ValidationTypes.OneOf,
  },
];

export const FLOAT_VALIDATIONS = [
  {
    label: 'is greater than',
    value: ValidationTypes.Min,
  },
  {
    label: 'is less than',
    value: ValidationTypes.Max,
  },
  {
    label: 'must be',
    value: ValidationTypes.Equals,
  },
  {
    label: 'cannot be',
    value: ValidationTypes.NotEquals,
  },
  {
    label: 'is one of',
    value: ValidationTypes.OneOf,
  },
];

export const BOOL_VALIDATIONS = [
  {
    label: 'must be',
    value: ValidationTypes.Equals,
  },
];

export const GOOGLE_PRODUCT_CATEGORY_VALIDATIONS = [
  {
    label: 'code must be',
    value: ValidationTypes.CodeEquals,
  },
  {
    label: 'name must be',
    value: ValidationTypes.NameEquals,
  },
  {
    label: 'code is one of',
    value: ValidationTypes.CodeOneOf,
  },
  {
    label: 'name is one of',
    value: ValidationTypes.NameOneOf,
  },
];

export const IMAGES_VALIDATIONS = [
  {
    label: 'contains greater than',
    value: ValidationTypes.Min,
  },
  {
    label: 'contains less than',
    value: ValidationTypes.Max,
  },
];

const splitValidationRule = (
  fieldValidationRules: FieldValidationRules
): [field: string, validation: string, condition: any] => {
  if (fieldValidationRules.rules.length > 0) {
    return [
      fieldValidationRules.field,
      fieldValidationRules.rules[0].validation,
      fieldValidationRules.rules[0].condition,
    ];
  }
  return ['', '', ''];
};

const getAvailableValidationTypes = (field: string): Option[] => {
  switch (PRODUCT_FIELD_TYPES[field]) {
    case FieldTypes.String:
      if (field === 'variant.sku' || field === 'variant.barcode') {
        return STRING_UNIQUE_VALIDATIONS;
      }
      return STRING_VALIDATIONS;
    case FieldTypes.Int:
      return INT_VALIDATIONS;
    case FieldTypes.Float:
      return FLOAT_VALIDATIONS;
    case FieldTypes.Bool:
      return BOOL_VALIDATIONS;
    case FieldTypes.Images:
      return IMAGES_VALIDATIONS;
    case FieldTypes.GoogleProductCategory:
      return GOOGLE_PRODUCT_CATEGORY_VALIDATIONS;
    default:
      return [];
  }
};

const getSelectOptionData = (options: Option[], selectedValue: string): Option | undefined => {
  return options.find((option: Option) => option.value === selectedValue);
};

const getConditionType = (validationType: string, fieldType: string): string => {
  // no condition required
  if (validationType === ValidationTypes.Required || validationType === ValidationTypes.Unique) {
    return '';
  }

  // set as string type since we need comma seperated values
  if (validationType === ValidationTypes.OneOf) {
    return 'text';
  }

  if (
    validationType === ValidationTypes.Min ||
    validationType === ValidationTypes.Max ||
    fieldType === FieldTypes.Int ||
    fieldType === FieldTypes.Float ||
    (fieldType === FieldTypes.GoogleProductCategory &&
      validationType === ValidationTypes.CodeEquals)
  ) {
    return 'number';
  }
  // default
  return 'text';
};

const getConditionLabel = (field: string, validationType: string): string => {
  if (
    PRODUCT_FIELD_TYPES[field] === FieldTypes.String &&
    (validationType === ValidationTypes.Min || validationType === ValidationTypes.Max)
  ) {
    return 'characters';
  }
  if (
    PRODUCT_FIELD_TYPES[field] === FieldTypes.Images &&
    (validationType === ValidationTypes.Min || validationType === ValidationTypes.Max)
  ) {
    return 'images';
  }
  // default
  return '';
};

const formatCondition = (validation: string, condition: any): string | number => {
  if (validation === ValidationTypes.OneOf && isArray(condition)) {
    return condition.join(', ');
  }
  return condition;
};

const formatOneOfCondition = (
  oneOfConditionValue: string,
  fieldType: string,
  validationType: string
): number[] | string[] => {
  if (
    fieldType === FieldTypes.String ||
    (fieldType === FieldTypes.GoogleProductCategory && validationType === ValidationTypes.NameOneOf)
  ) {
    const oneOfArray = oneOfConditionValue.split(',');
    return oneOfArray.map((element) => {
      return element.trim();
    });
  }

  if (
    fieldType === FieldTypes.Int ||
    fieldType === FieldTypes.Float ||
    (fieldType === FieldTypes.GoogleProductCategory && validationType === ValidationTypes.CodeOneOf)
  ) {
    const oneOfArray = oneOfConditionValue.split(',');

    return oneOfArray.reduce((accumulator: number[], oneOfCondition: string) => {
      // eslint-disable-next-line no-restricted-globals
      if (oneOfCondition && !isNaN(oneOfCondition as any)) {
        accumulator.push(parseFloat(oneOfCondition.trim()));
      } else {
        accumulator.push(NaN);
      }
      return accumulator;
    }, [] as number[]);
  }

  return [];
};

export const isNumericCondition = (field: string, validation: string): boolean => {
  if (
    [FieldTypes.Int, FieldTypes.Float, FieldTypes.Images].includes(PRODUCT_FIELD_TYPES[field]) &&
    validation !== ValidationTypes.Required
  ) {
    return true;
  }

  if (
    PRODUCT_FIELD_TYPES[field] === FieldTypes.String &&
    (validation === ValidationTypes.Min || validation === ValidationTypes.Max)
  ) {
    return true;
  }

  if (
    PRODUCT_FIELD_TYPES[field] === FieldTypes.GoogleProductCategory &&
    validation === ValidationTypes.CodeEquals
  ) {
    return true;
  }
  return false;
};

export const isBoolCondition = (field: string, validation: string): boolean => {
  if (PRODUCT_FIELD_TYPES[field] === FieldTypes.Bool) {
    return true;
  }

  if (validation === ValidationTypes.Required) {
    return true;
  }
  return false;
};

interface AdvancedRulesProps {
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
  onCreate: (field: string, validation: string, condition: any) => void;
  onReset: () => void;
  existingRule: FieldValidationRules | null;
}
const AdvancedRules = ({ onDelete, onCreate, onReset, existingRule }: AdvancedRulesProps) => {
  const [selectedField, setSelectedField] = useState('');
  const [selectedValidation, setSelectedValidation] = useState('');
  const [selectValidationOption, setSelectValidationOption] = useState<Option>();
  const [validationTypes, setValidationTypes] = useState<Option[]>();
  const [inputtedCondition, setInputtedCondition] = useState<any>();
  const [conditionType, setConditionType] = useState('');
  const [conditionLabel, setConditionLabel] = useState('');
  const [isInitialSetUp, setIsInitialSetUp] = useState(true);

  // Set existing rule as default state
  useEffect(() => {
    if (existingRule && isInitialSetUp) {
      const [existingField, existingValidation, existingCondition] =
        splitValidationRule(existingRule);
      setSelectedField(existingField);
      setSelectedValidation(existingValidation);
      setInputtedCondition(formatCondition(existingValidation, existingCondition));
      setIsInitialSetUp(false);
    }
  }, [existingRule, isInitialSetUp]);

  // Show corresponding sets of validation types once a field has been selected
  useEffect(() => {
    const availableValidationTypes = getAvailableValidationTypes(selectedField);
    setValidationTypes(availableValidationTypes);
  }, [selectedField, setValidationTypes]);

  // Set corresponding condition type and label based on field and validation type selections
  useEffect(() => {
    if (selectedField && selectedValidation) {
      setSelectValidationOption(
        getSelectOptionData(getAvailableValidationTypes(selectedField), selectedValidation)
      );
      setConditionType(getConditionType(selectedValidation, PRODUCT_FIELD_TYPES[selectedField]));
      setConditionLabel(getConditionLabel(selectedField, selectedValidation));
    }
  }, [selectedField, selectedValidation]);

  const selectField = (field: SingleValue<Option>) => {
    if (field) {
      setSelectedField(field.value);
    } else {
      setSelectedField('');
    }
    // Reset rule
    setSelectedValidation('');
    setSelectValidationOption({ label: '--', value: '' });
    setInputtedCondition('');
    onReset();
  };

  const selectValidation = (validationOption: SingleValue<Option>) => {
    if (validationOption) {
      setSelectedValidation(validationOption.value);
      setConditionType(
        getConditionType(validationOption.value, PRODUCT_FIELD_TYPES[selectedField])
      );
      setConditionLabel(getConditionLabel(selectedField, validationOption.value));
    } else {
      setSelectedValidation('');
      setSelectValidationOption({ label: '--', value: '' });
    }
    // Reset condition
    setInputtedCondition('');
    onReset();

    // Special case, no condition required
    if (validationOption && validationOption.value === ValidationTypes.Unique) {
      onCreate(selectedField, validationOption.value, true);
    }
  };

  const onConditionChange = (conditionValue: any) => {
    if (selectedField && selectedValidation) {
      let typedConditionValue;
      if (
        selectedValidation === ValidationTypes.OneOf ||
        selectedValidation === ValidationTypes.CodeOneOf ||
        selectedValidation === ValidationTypes.NameOneOf
      ) {
        typedConditionValue = formatOneOfCondition(
          conditionValue,
          PRODUCT_FIELD_TYPES[selectedField],
          selectedValidation
        );
      } else if (
        conditionValue &&
        isNumericCondition(selectedField, selectedValidation) &&
        // eslint-disable-next-line no-restricted-globals
        !isNaN(conditionValue)
      ) {
        typedConditionValue = parseFloat(conditionValue);
      } else if (isBoolCondition(selectedField, selectedValidation)) {
        typedConditionValue = !!conditionValue;
      } else {
        typedConditionValue = conditionValue;
      }
      setInputtedCondition(conditionValue);
      onCreate(selectedField, selectedValidation, typedConditionValue);
    }
  };

  return (
    <Grid
      columns="250px 250px auto 20px"
      alignItemsCenter
      gap="16px"
      data-testid="input-advanced-rules"
    >
      <Select
        styles={REACT_SELECT_CUSTOM_STYLES}
        isClearable={false}
        options={PRODUCT_FIELD_OPTIONS}
        placeholder="Select Property"
        onChange={(singleValue) => selectField(singleValue)}
        value={getSelectOptionData(PRODUCT_FIELD_OPTIONS, selectedField)}
      />

      <Select
        styles={REACT_SELECT_CUSTOM_STYLES}
        isClearable={false}
        isDisabled={validationTypes === undefined}
        options={validationTypes}
        placeholder="--"
        onChange={(singleValue) => selectValidation(singleValue)}
        value={selectValidationOption}
      />

      <Grid.Item>
        {selectedValidation && conditionType && (
          <div className="input-group">
            <Input
              aria-label="condition input"
              className="form-control"
              value={inputtedCondition}
              placeholder={
                selectedValidation === ValidationTypes.OneOf
                  ? 'separate conditions with a comma (,)'
                  : ''
              }
              type={conditionType}
              onChange={(e) => onConditionChange(e.target.value)}
            />
            {conditionLabel && (
              <div className="input-group-append">
                <ConditionLabel className="input-group-text">{conditionLabel}</ConditionLabel>
              </div>
            )}
          </div>
        )}
      </Grid.Item>

      <WrapperButton onClick={(e) => onDelete(e)}>
        <DeleteIcon aria-label="delete rule" />
      </WrapperButton>
    </Grid>
  );
};

export default AdvancedRules;
