import { FloatingChatContext } from 'containers/App/Contexts/FloatingChatContext';
import { useContext, useEffect, useState } from 'react';
import type { Conversation } from 'types/models/conversation';
import { useLocalStorage } from 'usehooks-ts';

export const CONVERSATION_DATA_STORAGE_KEY = 'conversation';

export interface ChatConversationData {
  conversation: Conversation;
  currentPartnerCompanyId: string;
  currentPartnerName: string;
}

export interface UseChatConversationOptions {
  /**
   * Persists data to local storage. This is useful for when you want to
   * persist data across page refreshes.
   */
  isPersisted?: boolean;
}

/**
 * Handles the conversation data for the chat. This data is stored in local storage,
 * meaning that any component can make use of this hook.
 */
const useChatConversation = (options: UseChatConversationOptions = {}) => {
  const { openWindow } = useContext(FloatingChatContext);

  const [data, setData] = useState<Partial<ChatConversationData> | null>(null);

  const [persistedData, setPersistedData] = useLocalStorage<Partial<ChatConversationData> | null>(
    CONVERSATION_DATA_STORAGE_KEY,
    null
  );

  /**
   * Helpers
   */

  const conversationData = options.isPersisted ? persistedData : data;
  const setConversationData = options.isPersisted ? setPersistedData : setData;

  const destroyConversationData = () => {
    setConversationData(null);
  };

  const updateConversationData = (newData: Partial<ChatConversationData>) => {
    setConversationData((prev) => ({ ...prev, ...newData }));
  };

  /**
   * Side Effects
   */

  // Intended to open a persisted chat window (e.g. FloatingChatUI) if a conversation is ongoing
  useEffect(() => {
    if (!options.isPersisted) return;
    if (!conversationData?.conversation) return;
    if (!openWindow) return;

    openWindow();
  }, [conversationData?.conversation, openWindow, options.isPersisted]);

  /**
   * Export
   */

  return {
    destroyConversationData,
    updateConversationData,
    setConversationData,
    ...conversationData,
  };
};

export default useChatConversation;
