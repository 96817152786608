import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';

import useAlertQueue from 'hooks/useAlertQueue';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import type { Return } from 'types/models/return';
import { completeReturn } from 'utils/api/orders';

interface CompleteReturnModalProps {
  orderReturn: Return;
  shouldShowModal: boolean;
  onDismiss: () => void;
  refetchReturns: () => void;
}

const CompleteReturnModal = ({
  orderReturn,
  shouldShowModal,
  onDismiss,
  refetchReturns,
}: CompleteReturnModalProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  const completingReturn = useMutation({
    mutationFn: () => completeReturn(orderReturn.orderId, orderReturn.id),
    onSuccess: () => {
      refetchReturns();
      reset();
      onDismiss();
      addSuccessAlert('Success', 'You have marked the return as completed.');
    },
    onError: (error) => {
      addErrorAlert('Something went wrong', 'The return was not completed.');
      console.error('Error completing return', error);
    },
  });

  return (
    <Modal.Root centered show={shouldShowModal} onHide={onDismiss} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Complete Return</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(() => completingReturn.mutate())}>
        <Modal.Body>
          <Body>
            Are you sure you want to mark this return as completed? This action cannot be undone.
          </Body>
        </Modal.Body>

        <Modal.Footer>
          <TertiaryButton size="small" onClick={onDismiss}>
            Cancel
          </TertiaryButton>

          <PrimaryButton size="small" disabled={isSubmitting} kind="action" type="submit">
            Complete Return
          </PrimaryButton>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default CompleteReturnModal;
