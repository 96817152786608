import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import Button from 'components/Common/Button';
import Spinner from 'components/Common/Spinner';
import {
  SettingsFooter,
  SettingsMain,
  SettingsPageHeader,
} from 'containers/SettingsPage/SettingsLayout';
import useAlertQueue from 'hooks/useAlertQueue';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import Body from 'storybook/stories/molecules/Body';
import Heading from 'storybook/stories/molecules/Heading';
import RadioButton from 'storybook/stories/molecules/RadioButton';
import Toggle from 'storybook/stories/molecules/Toggle';
import type { DeepPartial } from 'types/general';
import { CommerceType } from 'types/models/company';
import type { UpdateCompanyParams } from 'utils/api/companies';
import { updateCompany } from 'utils/api/companies';

interface FormFields {
  syncToPlatform: boolean;
  shouldRefundOnReturnComplete: string;
}

const ReturnsSettings = () => {
  const company = useAppSelector(selectCompany);
  const alertQueue = useAlertQueue();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, isSubmitting },
  } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      syncToPlatform: company?.returnsSettings.syncToPlatform ?? false,
      shouldRefundOnReturnComplete: company?.returnsSettings.shouldRefundOnReturnComplete
        ? 'true'
        : 'false',
    },
  });

  const shouldRefundOnReturnComplete = watch('shouldRefundOnReturnComplete');

  const onClickRefundOnReturnCompleteFalse = () => {
    setValue('shouldRefundOnReturnComplete', 'false');
  };

  const onClickRefundOnReturnCompleteTrue = () => {
    setValue('shouldRefundOnReturnComplete', 'true');
  };

  const updatingCompany = useMutation({
    mutationFn: (data: DeepPartial<UpdateCompanyParams>) => updateCompany(company?._id ?? '', data),
    onSuccess: () => {
      alertQueue.addSuccessAlert('Success', 'Your Returns Settings have been updated.');
    },
    onError: (error) => {
      alertQueue.addErrorAlert('Something went wrong', 'Unable to update Returns Settings.');
      console.error('Unable to update Returns Settings', error);
    },
  });

  const onSubmit = (data: FormFields) => {
    const formattedData = {
      ...data,
      shouldRefundOnReturnComplete: data.shouldRefundOnReturnComplete === 'true',
    };
    updatingCompany.mutate({
      returnsSettings: formattedData,
    });
  };

  const shouldSaveButtonBeDisabled = !isValid || isSubmitting;

  return (
    <>
      <SettingsMain>
        <SettingsPageHeader title="Returns" />
        <Flex column gap="16px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex column gap="32px">
              {/* Syncing Settings */}
              <Flex column gap="20px">
                <Heading variant="Headings/H2">Syncing Settings</Heading>
                <Flex alignItemsCenter gap="10px">
                  <Toggle {...register('syncToPlatform')} />
                  <Flex column>
                    <Body variant="Body/Regular Bold">Sync to Platform</Body>
                    <Body variant="Body/Body Small" color="bodyTextSecondary">
                      When toggled on, we will automatically sync returns to your platform. Turn off
                      to disable syncing.
                    </Body>
                  </Flex>
                </Flex>
              </Flex>

              {/* Refund Settings */}
              {company?.commerceType === CommerceType.Buyer && (
                <Flex column gap="20px">
                  <Heading variant="Headings/H2">Refund Settings</Heading>

                  <Flex column gap="20px">
                    <Flex
                      aria-roledescription="radio"
                      aira-label="Refund on Accept"
                      aria-checked={shouldRefundOnReturnComplete === 'false'}
                      alignItemsCenter
                      gap="10px"
                      onClick={onClickRefundOnReturnCompleteFalse}
                    >
                      <RadioButton value="false" {...register('shouldRefundOnReturnComplete')} />
                      <Flex column>
                        <Body variant="Body/Regular Bold">Refund on Accept</Body>
                        <Body variant="Body/Body Small" color="bodyTextSecondary">
                          Refund will be issued when your return request is approved by the seller.
                        </Body>
                      </Flex>
                    </Flex>

                    <Flex
                      aria-roledescription="radio"
                      aira-label="Refund on Complete"
                      aria-checked={shouldRefundOnReturnComplete === 'true'}
                      alignItemsCenter
                      gap="10px"
                      onClick={onClickRefundOnReturnCompleteTrue}
                    >
                      <RadioButton value="true" {...register('shouldRefundOnReturnComplete')} />
                      <Flex column>
                        <Body variant="Body/Regular Bold">Refund on Complete</Body>
                        <Body variant="Body/Body Small" color="bodyTextSecondary">
                          Refund will be issued when the return is marked as complete by the seller
                          in Modern Dropship or in their order platform (i.e. Shopify)
                        </Body>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </form>
        </Flex>
      </SettingsMain>
      <SettingsFooter>
        {/* @ts-ignore */}
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={shouldSaveButtonBeDisabled}
          size="sm"
          color="primary"
          className="mr-2"
        >
          {isSubmitting ? <Spinner color="white" className="mx-2" small /> : 'Save'}
        </Button>
      </SettingsFooter>
    </>
  );
};

export default ReturnsSettings;
