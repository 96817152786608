import { useDispatch, useSelector } from 'react-redux';

import Flex from '@react-css/flex';
import { createBillingPortal } from 'store/slices/stripe';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Link from 'storybook/stories/molecules/Link';
import { useSafeState } from 'utils/hooks';

const AddPaymentMethodCard = ({ done }) => {
  const [error, setError] = useSafeState('');
  const dispatch = useDispatch();
  const pending = useSelector(({ stripe }) => stripe.isCreatingBillingPortal);

  const connect = () => {
    setError('');
    dispatch(createBillingPortal()).then((action) => {
      if (action.error) {
        setError(action.error.message);
      }
    });
  };

  return (
    <GetStartedCard
      isComplete={done}
      title="Add a Payment Method"
      completedUi={
        <Body as="p">
          Manage your payment method from your{' '}
          <Link to="/settings/dropshipping/billing">Billing settings</Link>
        </Body>
      }
    >
      <Body as="p">
        Please add your payment method (credit card). This card will receive the charges for both
        your Modern Dropship platform fee as well as for your seller&apos;s invoices.
      </Body>

      {error && <Body color="error500">{`Error: ${error}`}</Body>}

      <Flex justifyEnd>
        <PrimaryButton size="small" disabled={pending} onClick={connect}>
          Add Payment Method
        </PrimaryButton>
      </Flex>
    </GetStartedCard>
  );
};

export default AddPaymentMethodCard;
