/* eslint-disable jsx-a11y/label-has-associated-control */
import { Badge } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from 'components/Common/Button';
import { selectIsBuyer, selectIsSeller } from 'store/selectors/me/company';
import { updateUser } from 'store/slices/settings/account';
import { capitalizeFirstChar } from 'utils/strings';

import useAlertQueue from 'hooks/useAlertQueue';
import { useAppDispatch, useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';
import { SettingsFooter, SettingsMain, SettingsPageHeader } from '../SettingsLayout';

// Implementation note on this component:
// Since the API is looking for inverse boolean values (i.e.
// "skipWeeklyOverviewEmail") and the checkboxes styling/rendering is based on
// normal boolean values (i.e. "weeklyOverviewEmail"), we need to convert the
// API data to form data for  display and vice-versa for submission.

const NOTIFICATIONS = [
  'weeklyOverviewEmail',
  'actionCreated',
  'criticalCompanyErrorCreated',
  'fulfillmentPostedToBuyer',
  'orderPostedToSeller',
  'orderItemsCancelled',
  'productAvailableToBuyer',
];

export const convertAPIDataToFormData = (data) => {
  return NOTIFICATIONS.reduce((memo, notification) => {
    return { ...memo, [notification]: !data[`skip${capitalizeFirstChar(notification)}`] };
  }, {});
};

export const convertFormDataToAPIData = (data) => {
  return NOTIFICATIONS.reduce((memo, notification) => {
    return { ...memo, [`skip${capitalizeFirstChar(notification)}`]: !data[notification] };
  }, {});
};

const NotificationsSettings = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const alertQueue = useAlertQueue();
  const formId = 'notifications-form';
  const notificationDefaultValues = convertAPIDataToFormData(user?.notifications || {});
  const { handleSubmit, register, formState, reset, watch } = useForm({
    defaultValues: notificationDefaultValues,
  });
  const { isDirty } = formState;
  const notificationValues = NOTIFICATIONS.reduce((memo, notification) => {
    return { ...memo, [notification]: watch(notification) };
  }, {});
  const isBuyer = useSelector(selectIsBuyer);
  const isSeller = useSelector(selectIsSeller);

  const onFormSubmit = (formData) => {
    const apiData = convertFormDataToAPIData(formData);

    const result = updateUser(apiData);
    dispatch(result).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
        return;
      }
      alertQueue.addSuccessAlert('Success', 'Notification preferences updated');
      reset(convertAPIDataToFormData(apiData), {
        keepDefaultValues: false,
        keepValues: false,
        keepDirty: false,
      });
    });
  };

  return (
    <>
      <SettingsMain>
        <SettingsPageHeader title="Notifications" />
        <form id={formId} onSubmit={handleSubmit(onFormSubmit)}>
          <p className="text-muted small mt-2 mb-4">
            Control which notifications you receive from us. To prevent your inbox from getting
            flooded, we&#39;ll bundle notifications together and send at most one email per hour.
          </p>

          <h3 className="mb-4">Activity</h3>
          {isBuyer && (
            <div
              role="group"
              className="d-flex justify-content-between align-items-center form-group"
            >
              <div className="custom-control custom-switch">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="fulfillmentPostedToBuyer"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('fulfillmentPostedToBuyer')}
                  data-testid="fulfillmentPostedToBuyer"
                />
                <label className="custom-control-label" htmlFor="fulfillmentPostedToBuyer">
                  Order items have been shipped
                </label>
              </div>
              {notificationValues.fulfillmentPostedToBuyer ? (
                <Badge variant="secondary" pill>
                  Receive
                </Badge>
              ) : (
                <Badge variant="light" pill>
                  Silent
                </Badge>
              )}
            </div>
          )}

          {isSeller && (
            <div
              role="group"
              className="d-flex justify-content-between align-items-center form-group"
            >
              <div className="custom-control custom-switch">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="orderPostedToSeller"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('orderPostedToSeller')}
                  data-testid="orderPostedToSeller"
                />
                <label className="custom-control-label" htmlFor="orderPostedToSeller">
                  Order has been placed
                </label>
              </div>
              {notificationValues.orderPostedToSeller ? (
                <Badge variant="secondary" pill>
                  Receive
                </Badge>
              ) : (
                <Badge variant="light" pill>
                  Silent
                </Badge>
              )}
            </div>
          )}

          <div
            role="group"
            className="d-flex justify-content-between align-items-center form-group"
          >
            <div className="custom-control custom-switch">
              <input
                className="custom-control-input"
                type="checkbox"
                id="orderItemsCancelled"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('orderItemsCancelled')}
                data-testid="orderItemsCancelled"
              />
              <label className="custom-control-label" htmlFor="orderItemsCancelled">
                Order items have been cancelled
              </label>
            </div>
            {notificationValues.orderItemsCancelled ? (
              <Badge variant="secondary" pill>
                Receive
              </Badge>
            ) : (
              <Badge variant="light" pill>
                Silent
              </Badge>
            )}
          </div>

          <div
            role="group"
            className="d-flex justify-content-between align-items-center form-group"
          >
            <div className="custom-control custom-switch">
              <input
                className="custom-control-input"
                type="checkbox"
                id="actionCreated"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('actionCreated')}
                data-testid="actionCreated"
              />
              <label className="custom-control-label" htmlFor="actionCreated">
                Signature requested on an Action
              </label>
            </div>
            {notificationValues.actionCreated ? (
              <Badge variant="secondary" pill>
                Receive
              </Badge>
            ) : (
              <Badge variant="light" pill>
                Silent
              </Badge>
            )}
          </div>

          <div
            role="group"
            className="d-flex justify-content-between align-items-center form-group"
          >
            <div className="custom-control custom-switch">
              <input
                className="custom-control-input"
                type="checkbox"
                id="criticalCompanyErrorCreated"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('criticalCompanyErrorCreated')}
                data-testid="criticalCompanyErrorCreated"
              />
              <label className="custom-control-label" htmlFor="criticalCompanyErrorCreated">
                Critical TradeOps issues opened
              </label>
            </div>
            {notificationValues.criticalCompanyErrorCreated ? (
              <Badge variant="secondary" pill>
                Receive
              </Badge>
            ) : (
              <Badge variant="light" pill>
                Silent
              </Badge>
            )}
          </div>

          {isBuyer && (
            <div
              role="group"
              className="d-flex justify-content-between align-items-center form-group"
            >
              <div className="custom-control custom-switch">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="productAvailableToBuyer"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('productAvailableToBuyer')}
                  data-testid="productAvailableToBuyer"
                />
                <label className="custom-control-label" htmlFor="productAvailableToBuyer">
                  New products are available
                </label>
              </div>
              {notificationValues.productAvailableToBuyer ? (
                <Badge variant="secondary" pill>
                  Receive
                </Badge>
              ) : (
                <Badge variant="light" pill>
                  Silent
                </Badge>
              )}
            </div>
          )}

          <h3 className="mb-4">Reports</h3>
          <div
            role="group"
            className="d-flex justify-content-between align-items-center form-group"
          >
            <div className="custom-control custom-switch">
              <input
                className="custom-control-input"
                type="checkbox"
                id="weeklyOverviewEmail"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('weeklyOverviewEmail')}
                data-testid="weeklyOverviewEmail"
              />
              <label className="custom-control-label" htmlFor="weeklyOverviewEmail">
                Weekly overview email, on Tuesday mornings
              </label>
            </div>
            {notificationValues.weeklyOverviewEmail ? (
              <Badge variant="secondary" pill>
                Receive
              </Badge>
            ) : (
              <Badge variant="light" pill>
                Silent
              </Badge>
            )}
          </div>
        </form>
      </SettingsMain>
      <SettingsFooter>
        <Button
          type="submit"
          form={formId}
          disabled={!isDirty}
          size="sm"
          color="primary"
          className="mr-2"
          data-testid="save"
        >
          Save
        </Button>
      </SettingsFooter>
    </>
  );
};

export default NotificationsSettings;
