/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Button from 'components/Common/Button';
import UpdatedIndicator from 'components/Common/UpdatedIndicator';
import {
  arrayToIdMap,
  getArrayPatch,
  getSortedIdList,
  updateIdMapPositions,
} from 'hooks/products/useEditProduct';
import Icon from 'storybook/stories/molecules/Icon';

/**
 * ProductOptions card manages the state and display of the product options.
 * If a user can edit these options, the edits are held in this components state and
 * whenever changes, the diff for the PATCH request is calculated and reported back
 * to the parent component via onEditOptions.
 */
const ProductOptions = ({ options = [], canEdit, onEditOptions, clearEditsAt, hasEdits }) => {
  // create an editable map of option values
  const [editMap, setEditMap] = useState(() => arrayToIdMap(options));
  // create list of IDs sorted by position
  const [optionIds, setOptionIds] = useState(() => getSortedIdList(options));
  // hold copy of the last clearEditsAt to compare when it changes (ignores other dependencies)
  const clearEditsAtRef = useRef(clearEditsAt);

  useEffect(() => {
    // used to reset local edits only when the parent has specifically said to do so
    // reset back to product.options values
    if (clearEditsAtRef.current !== clearEditsAt) {
      setOptionIds(getSortedIdList(options));
      setEditMap(arrayToIdMap(options));
      clearEditsAtRef.current = clearEditsAt;
    }
  }, [clearEditsAt, options]);

  const onOptionChange =
    (id) =>
    ({ target: { value } }) => {
      const newEditMap = { ...editMap, [id]: { ...editMap[id], name: value } };
      setEditMap(newEditMap);
      onEditOptions(getArrayPatch(options, newEditMap));
    };

  const onAddOption = () => {
    const id = uuidv4();
    let newEditMap = { ...editMap, [id]: { name: '' } };
    const newOptionIds = [...optionIds, id];
    newEditMap = updateIdMapPositions(newOptionIds, newEditMap);
    setEditMap(newEditMap);
    setOptionIds(newOptionIds);
  };

  const onOptionDeleted = (id) => {
    let newEditMap = { ...editMap };
    delete newEditMap[id];
    const newOptionIds = optionIds.filter((optId) => optId !== id);
    newEditMap = updateIdMapPositions(newOptionIds, newEditMap);
    setOptionIds(newOptionIds);
    setEditMap(newEditMap);
    onEditOptions(
      getArrayPatch(
        options.filter((opt) => opt._id !== id),
        newEditMap
      )
    );
  };

  const onDelete = (id) => () => {
    const option = editMap[id];
    if (!option) return;
    // if this option doesn't have an _id, it has never been persisted, just remove from state
    if (!option._id) {
      onOptionDeleted(id);
    }
    // else ask for confirmation
    // TODO: when we have option delete from API
  };

  return (
    <div className="card p-4 overflow-auto">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 className="mb-0">Options {hasEdits ? <UpdatedIndicator className="ml-1" /> : null}</h4>
        {canEdit ? (
          <Button size="sm" color="white" onClick={onAddOption}>
            Add
          </Button>
        ) : null}
      </div>

      {optionIds.length === 0 ? (
        <p className="mt-2 mb-2 text-muted">This product does not have any options</p>
      ) : canEdit ? (
        optionIds.map((id, idx) => {
          if (!editMap[id]) return null;
          const isNew = !editMap[id]._id;
          return (
            <div className="input-group input-group-merge input-group-sm mb-2" key={id}>
              <input
                aria-label={`option ${idx + 1}`}
                type="text"
                className={`form-control form-control-sm form-control-prepended ${
                  isNew ? 'form-control-appended' : ''
                }`}
                name={`option${idx + 1}`}
                value={editMap[id].name}
                onChange={onOptionChange(id)}
              />
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span className="ml-1 mr-2">{idx + 1}</span>
                </div>
              </div>
              {isNew && (
                <div className="input-group-append">
                  <Button size="sm" className="input-group-text" onClick={onDelete(id)}>
                    <Icon name="delete" className="text-danger" />
                  </Button>
                </div>
              )}
            </div>
          );
        })
      ) : (
        optionIds.map((id, idx) => {
          if (!editMap[id]) return null;
          return (
            <p className="mb-2" aria-label={`option ${idx + 1}`} key={id}>
              {idx + 1}: {editMap[id].name}
            </p>
          );
        })
      )}
    </div>
  );
};

export default ProductOptions;
