import Flex from '@react-css/flex';
import { isEmpty } from 'lodash';
import { useContext } from 'react';

import { getCurrencyLabel, getCurrencySymbol } from 'utils/currencies';
import type { SelectablePartner } from './Shared.types';
import { CreatePriceListContext } from './context';

import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import Divider from 'storybook/stories/molecules/Divider';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';

const SummaryCard = () => {
  const {
    currentStep,
    priceListName,
    addExistingProducts,
    addNewProducts,
    assignedPartners,
    yourCurrency,
    partnerCurrency,
    conversionRate,
  } = useContext(CreatePriceListContext);

  const partners = isEmpty(assignedPartners) ? [] : Object.values(assignedPartners);

  const shouldShowCurrenciesSummary = ![
    'PriceListNameForm',
    'AddExistingProductsForm',
    'PartnersForm',
    'CurrencyForm',
  ].includes(currentStep);
  const shouldShowConversionRateSummary =
    yourCurrency !== partnerCurrency && currentStep !== 'ConversionRateForm';

  const shouldShowProductSummary = !['PriceListNameForm', 'AddExistingProductsForm'].includes(
    currentStep
  );

  return (
    <Flex column>
      <Card data-testid="summary-card">
        <Flex column gap="24px">
          <Heading variant="Headings/H2">Summary</Heading>
          <Divider />

          {priceListName !== '' && (
            <Flex column gap="8px">
              <Body variant="Body/Header" color="bodyTextSecondary">
                Price List Name
              </Body>
              <Body variant="Body/Regular">{priceListName}</Body>
            </Flex>
          )}

          {shouldShowProductSummary && (
            <Flex column gap="8px">
              <Body variant="Body/Header" color="bodyTextSecondary">
                Price List Products
              </Body>
              <Body variant="Body/Regular">
                Add existing products: {addExistingProducts ? 'yes' : 'no'}
              </Body>
              <Body variant="Body/Regular">Add new products: {addNewProducts ? 'yes' : 'no'}</Body>
            </Flex>
          )}

          {partners.length > 0 && (
            <Flex column gap="8px">
              <Body variant="Body/Header" color="bodyTextSecondary">
                Assigned Partners
              </Body>
              {partners.map((partner: SelectablePartner) => (
                <Flex column gap="8px" key={partner.id}>
                  <Body variant="Body/Regular">{partner.name}</Body>
                </Flex>
              ))}
            </Flex>
          )}

          {shouldShowCurrenciesSummary && (
            <>
              {yourCurrency !== '' && (
                <Flex column gap="8px">
                  <Body variant="Body/Header" color="bodyTextSecondary">
                    Your Currency
                  </Body>
                  <Body variant="Body/Regular">{getCurrencyLabel(yourCurrency)}</Body>
                </Flex>
              )}

              {partnerCurrency !== '' && (
                <Flex column gap="8px">
                  <Body variant="Body/Header" color="bodyTextSecondary">
                    Partner&apos;s Currency
                  </Body>
                  <Body variant="Body/Regular">{getCurrencyLabel(partnerCurrency)}</Body>
                </Flex>
              )}
            </>
          )}

          {shouldShowConversionRateSummary && (
            <Flex column gap="8px">
              <Body variant="Body/Header" color="bodyTextSecondary">
                Currency Conversion
              </Body>
              <Body variant="Body/Regular">
                <Flex alignItemsCenter gap="8px">
                  <div>{getCurrencySymbol(yourCurrency)}1.00</div>
                  <Icon name="arrow_forward" color="gray400" />
                  <div>
                    {getCurrencySymbol(partnerCurrency)}
                    {conversionRate}
                  </div>
                </Flex>
              </Body>
            </Flex>
          )}
        </Flex>
      </Card>
    </Flex>
  );
};

export default SummaryCard;
