import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import type { Company } from 'types/models/company';
import { CommerceType } from 'types/models/company';
import type { Invoice } from 'types/models/invoice';

export type InvoiceDashboardButtonProps = {
  invoice?: Invoice;
  company?: Company | null;
};

const InvoiceDashboardButton = ({ invoice, company }: InvoiceDashboardButtonProps) => {
  // Nothing to display if we don't have data
  if (!invoice || !company) {
    return null;
  }

  // Only sellers need to see a link to the invoice in their dashboard
  if (company.commerceType !== CommerceType.Seller) {
    return null;
  }

  // Invoice must have a source and seller code to be able to link out to it
  if (!invoice.source || !invoice.sellerCode) {
    return null;
  }

  // Stripe invoices are the only ones we can link out to
  if (invoice.source !== 'stripe' || !company.stripeAccountId) {
    return null;
  }

  return (
    <PrimaryButton
      size="small"
      $iconName="payments"
      as="a"
      rel="noopener noreferrer"
      href={`https://dashboard.stripe.com/${company.stripeAccountId!}/invoices/${
        invoice.sellerCode
      }`}
      target="_blank"
      data-testid="invoice-dashboard-button"
    >
      Managed in Stripe
    </PrimaryButton>
  );
};

export default InvoiceDashboardButton;
