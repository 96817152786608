import { useDispatch, useSelector } from 'react-redux';

import Flex from '@react-css/flex';
import useAlertQueue from 'hooks/useAlertQueue';
import { Link } from 'react-router-dom';
import { connectStripe, skipStripeSetup } from 'store/slices/stripe';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { PLATFORM_EDI_EXTERNAL_SFTP, PLATFORM_EDI_SFTP } from 'utils/constants';

const ConnectStripeCard = ({ skipped, done }) => {
  const dispatch = useDispatch();
  const alertQueue = useAlertQueue();
  const { isConnecting, isSkippingSetup } = useSelector(({ stripe }) => stripe);
  const { company } = useSelector(({ me }) => me);
  const isEDIPlatform =
    company.orderPlatform === PLATFORM_EDI_EXTERNAL_SFTP ||
    company.orderPlatform === PLATFORM_EDI_SFTP;

  const connect = () => {
    dispatch(connectStripe()).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
      }
    });
  };

  const skipPayment = () => {
    dispatch(skipStripeSetup()).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
      }
    });
  };

  return (
    <GetStartedCard
      isComplete={done}
      title="Payments and Invoicing"
      completedUi={
        <Body as="p">
          Manage your payment method from your{' '}
          <Link to="/settings/dropshipping/billing">Billing settings</Link>
        </Body>
      }
    >
      {skipped ? (
        <Body as="p">
          You have chosen to manage your own invoicing. You can change this by activating Stripe
          from your <Link to="/settings/dropshipping/billing">Billing settings</Link>.
        </Body>
      ) : (
        <>
          <Body as="p">
            We recommend using Stripe so your partners can easily pay you. Please connect a Stripe
            account to automate billing.{' '}
            <SupportLink article="8lwln18tin">Learn how invoicing works</SupportLink>.
          </Body>

          {isEDIPlatform ? (
            <Body>
              Select <strong>Manual Billing</strong> if invoicing through 810s.
            </Body>
          ) : (
            <Body>
              Select <strong>Manual Billing</strong> if you would rather manage your own invoicing.
            </Body>
          )}

          <Body as="p">
            <strong>Important:</strong> You will need to configure your tax location/registration or
            tax rates in Stripe.{' '}
            <SupportLink article="8lwln18tin#setting_tax_rates">
              Learn more about enabling taxes
            </SupportLink>
          </Body>

          <Flex justifyEnd gap="16px">
            <SecondaryButton size="small" disabled={isSkippingSetup} onClick={skipPayment}>
              Manual Billing
            </SecondaryButton>

            <PrimaryButton size="small" disabled={isConnecting} onClick={connect}>
              Connect Stripe
            </PrimaryButton>
          </Flex>
        </>
      )}
    </GetStartedCard>
  );
};

export default ConnectStripeCard;
