import { Redirect, Route, type RouteProps } from 'react-router-dom';
import { useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...routeProps }: PrivateRouteProps) => {
  const user = useAppSelector(selectUser);

  return (
    <Route
      {...routeProps}
      render={(props) => (user ? <Component {...props} /> : <Redirect to="/signin" />)}
    />
  );
};

export default PrivateRoute;
