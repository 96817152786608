import styled from 'styled-components';

export const ActionCard = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 24px;
  margin-bottom: 8px;
`;
export const CardTitle = styled.h4`
  font-weight: bold;
  margin-bottom: 8px;
`;
export const CardText = styled.p`
  margin-bottom: 16px;
`;

export const RadioButtonLabel = styled.p`
  margin-bottom: 4px;
`;
