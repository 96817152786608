import Flex from '@react-css/flex';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';

import type { CreatePriceListContextValues } from './context';
import { CreatePriceListContext } from './context';

import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Toggle from 'storybook/stories/molecules/Toggle';
import ProgressBar from 'storybook/stories/organisms/ProgressBar';

const Footer = () => {
  const { updateState } = useContext(CreatePriceListContext);

  const navigateBack = () => {
    updateState({ currentStep: 'PriceListNameForm' });
  };

  return (
    <Card>
      <Flex justifySpaceBetween>
        <TertiaryButton size="small" $iconName="navigate_before" onClick={navigateBack}>
          Back
        </TertiaryButton>

        <Flex gap="24px">
          <PrimaryButton type="submit" size="small" $iconName="navigate_next" $iconAfter>
            Next
          </PrimaryButton>
        </Flex>
      </Flex>
    </Card>
  );
};

const AddExistingProductsForm = () => {
  const { addExistingProducts, addNewProducts, updateState } = useContext(CreatePriceListContext);

  const { register, handleSubmit } = useForm<Partial<CreatePriceListContextValues>>({
    defaultValues: {
      addExistingProducts: addExistingProducts || true,
      addNewProducts: addNewProducts || true,
    },
  });

  const onSubmit = (data: Partial<CreatePriceListContextValues>) => {
    updateState({
      addExistingProducts: data.addExistingProducts,
      addNewProducts: data.addNewProducts,
      currentStep: 'PartnersForm',
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex column gap="16px">
        <Card>
          <Flex column gap="28px">
            <ProgressBar totalSteps={5} completedSteps={2} />

            <Flex column gap="32px">
              <Flex column gap="8px">
                <Heading variant="Headings/H3">
                  What products do you want to add to this price list?
                </Heading>
                <Body variant="Body/Regular" color="bodyTextSecondary">
                  All active products will be automatically added to this price list at your
                  specified margin. If you would like to create an empty price list and add products
                  one by one, deselect the option below. Any product that is added to Modern
                  Dropship in the future will automatically be added to the price list. You can
                  disable this option below.
                </Body>
              </Flex>

              <Flex column gap="16px">
                <Toggle id="addExistingProducts" {...register('addExistingProducts')}>
                  Add all active products to this price list
                </Toggle>
                <Toggle id="addNewProducts" {...register('addNewProducts')}>
                  Automatically keep this price list up to date with products added to Modern
                  Dropship
                </Toggle>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Footer />
      </Flex>
    </form>
  );
};

export default AddExistingProductsForm;
