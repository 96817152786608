import Flex from '@react-css/flex';
import AutonomousMerchandisingSettings from 'containers/SettingsPage/DropshippingTab/MerchandisingSettings/AutonomousMerchandisingSettings';
import Card from 'storybook/stories/cells/Card';
import Heading from 'storybook/stories/molecules/Heading';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

import type { Partner } from 'types/models/partner';
import type { UpdatePartnerParams } from 'utils/api/partners';

type PartnerMerchandisingSettingsParams = {
  partner: Partner;
  updatePartner: (params: UpdatePartnerParams) => void;
};

const PartnerMerchandisingSettings = ({
  partner,
  updatePartner,
}: PartnerMerchandisingSettingsParams) => {
  return (
    <Card>
      <Card.Head>
        <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
          Merchandising
        </Heading>
      </Card.Head>

      <Card.Body>
        <Flex column gap="24px">
          <span>
            Updates to these settings will automatically apply to products that are created after
            settings are saved. <SupportLink article="wxnwwal0y6">Learn more</SupportLink>
          </span>

          <span>
            These settings affect this partnership. If you would like to affect all partnerships,
            please email <a href="mailto:support@convictional.com">support@convictional.com</a>.
          </span>

          <AutonomousMerchandisingSettings
            settings={partner.merchandisingSettings}
            onSettingsChange={(merchandisingSettings) => updatePartner({ merchandisingSettings })}
          />
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default PartnerMerchandisingSettings;
