import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { getProductValidationErrorsForBuyer } from 'utils/api/buyer/products';
import { getProductValidationErrorsForSeller } from 'utils/api/products';

// regex used to capture the variant ID and remaining variantFieldPath from a full error fieldPath
const VARIANT_FIELD_REGEX = /^variants\.([^.]+)\.(.+)$/;

// regex used to capture the attribute name from an error fieldPath
const ATTRIBUTE_FIELD_REGEX = /^attributes\.(.+)$/;

/**
 * takes a full fieldPath and extracts the variant ID and remaining fieldPath
 */
const parseFieldPathForVariant = (fieldPath) => {
  const matchResult = fieldPath.match(VARIANT_FIELD_REGEX) || [];
  const variantId = matchResult[1];
  const variantFieldPath = matchResult[2]; // the remaining field path with variants.variantId stripped
  return [variantId, variantFieldPath];
};

/**
 * takes a fieldPath and returns the attribute name
 */
export const parseFieldPathForAttribute = (fieldPath) => {
  const matchResult = fieldPath.match(ATTRIBUTE_FIELD_REGEX) || [];
  const attributeName = matchResult[1];
  return attributeName;
};

/**
 * helper to add or create a new error list for a given property of an object
 */
const addOrMergeError = (obj, key, error) =>
  Object.prototype.hasOwnProperty.call(obj, key) ? [...obj[key], error] : [error];

/**
 * Takes current variantErrorMap and returns the same map with an error added to the appropriate ID and variant field
 */
const addToVariantErrorMap = (error, variantMap = {}) => {
  const variantMapCopy = { ...variantMap };
  const { fieldPath } = error;
  const [variantId, variantFieldPath] = parseFieldPathForVariant(fieldPath);
  // return early if we don't have the right fieldPath elements
  if (!variantId || !variantFieldPath) return variantMapCopy;
  // add empty map for variantId if it does not exist
  if (!Object.prototype.hasOwnProperty.call(variantMapCopy, variantId)) {
    variantMapCopy[variantId] = {};
  }
  // handle variant attributes
  if (/^attributes/.test(variantFieldPath)) {
    variantMapCopy[variantId].attributes = addOrMergeError(
      variantMapCopy[variantId],
      'attributes',
      {
        ...error,
        variantFieldPath,
      }
    );
  } else {
    variantMapCopy[variantId][variantFieldPath] = addOrMergeError(
      variantMapCopy[variantId],
      variantFieldPath,
      { ...error, variantFieldPath }
    );
  }
  return variantMapCopy;
};

/**
 * generates a map of validation errors indexed by the fieldPath
 */
const makeErrorMap = (errorList = []) => {
  return errorList.reduce((accumulator, error) => {
    const { fieldPath } = error;
    // variants has a new error map indexed by variantId for easy access
    if (/^variants/.test(fieldPath)) {
      accumulator.variants = addToVariantErrorMap(error, accumulator.variants);
    } else if (/^attributes/.test(fieldPath)) {
      accumulator.attributes = addOrMergeError(accumulator, 'attributes', error);
    } else {
      accumulator[fieldPath] = addOrMergeError(accumulator, fieldPath, error);
    }
    return accumulator;
  }, {});
};

/**
 * creates an object where errors are indexed by the buyer they apply to (seller only)
 */
const makeErrorsByBuyer = (errorList = []) => {
  return errorList.reduce((accumulator, error) => {
    accumulator[error.buyerCompanyObjectId] = addOrMergeError(
      accumulator,
      error.buyerCompanyObjectId,
      error
    );
    return accumulator;
  }, {});
};

/**
 * Hook used to manage the product validation errors
 */
const useProductValidationErrors = ({ productId, isSeller }) => {
  // object representing all the product validation errors indexed by field
  const [errorMap, setErrorMap] = useState({});
  // object representing all the product validation errors indexed by buyerCompanyId
  const [errorsByBuyer, setErrorsByBuyer] = useState({});

  const queryClient = useQueryClient();

  const apiCall = isSeller
    ? getProductValidationErrorsForSeller
    : getProductValidationErrorsForBuyer;

  const queryKey = [
    isSeller ? 'getProductValidationErrorsForSeller' : 'getProductValidationErrorsForBuyer',
    productId,
  ];

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: () => apiCall(productId),
    onSuccess: ({ data: errors }) => {
      console.info('Fetched Product Validation Errors', errors);
      setErrorMap(makeErrorMap(errors));
      if (isSeller) {
        setErrorsByBuyer(makeErrorsByBuyer(errors));
      }
    },
    onError: (err) => {
      if (err.code !== 404) {
        console.error('Fetch Product Validation Error', err);
      }
    },
  });

  const invalidateProductValidationQuery = () => {
    queryClient.invalidateQueries(queryKey);
  };

  return {
    validationErrorList: data?.data || [],
    validationErrorMap: errorMap,
    validationErrorsByBuyer: errorsByBuyer,
    isLoadingValidationErrors: isLoading,
    invalidateProductValidationQuery,
  };
};

export default useProductValidationErrors;
