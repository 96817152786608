import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import UpdatedIndicator from 'components/Common/UpdatedIndicator';
import { parseFieldPathForAttribute } from 'hooks/products/useProductValidationErrors';
import usePrevious from 'hooks/usePrevious';
import _isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import Body from 'storybook/stories/molecules/Body';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import { v4 as uuidv4 } from 'uuid';

import AttributeRow from '../AttributeRow';

const SelectedVariantAttributes = ({
  attributes = [],
  hasEdits,
  onChangeAttributes,
  canEdit,
  validationErrors,
}) => {
  const prevValidationErrors = usePrevious(validationErrors);

  useEffect(() => {
    if (prevValidationErrors !== validationErrors && !_isEmpty(validationErrors) && canEdit) {
      // only run if validationErrors have changed
      // check validation rules and stub attributes into current list if they are in validationErrors and not already in list
      const missingAttributeMap = {};
      validationErrors.forEach((err) => {
        const attrName = parseFieldPathForAttribute(err.variantFieldPath);
        if (!attrName) return;
        const inAttributes = attributes.some((attr) => attr.name === attrName);
        if (!inAttributes) {
          missingAttributeMap[attrName] = true;
        }
      });
      const stubAttrs = Object.keys(missingAttributeMap).map((name) => ({
        name,
        value: '',
        id: uuidv4(),
      }));
      if (stubAttrs.length > 0) {
        onChangeAttributes([...attributes, ...stubAttrs]);
      }
    }
  }, [validationErrors, attributes, prevValidationErrors, onChangeAttributes, canEdit]);

  const onChangeAttributeField = (id, field) => (value) => {
    const newAttributes = attributes.map((attr) =>
      attr.id !== id ? attr : { ...attr, [field]: value }
    );
    onChangeAttributes(newAttributes);
  };

  const onAddAttribute = () => {
    const newAttributes = [...attributes, { name: '', value: '', id: uuidv4() }];
    onChangeAttributes(newAttributes);
  };

  const onDeleteAttribute = (id) => {
    const newAttributes = attributes.filter((attr) => attr.id !== id);
    onChangeAttributes(newAttributes);
  };

  return (
    <Flex column gap="8px">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <Heading variant="Headings/H4">
          Attributes{' '}
          {hasEdits && (
            <UpdatedIndicator aria-label="variant attributes updated" className="ml-1" />
          )}
        </Heading>
      </div>

      {attributes.length === 0 ? (
        <Body color="bodyTextSecondary">This variant has no additional attributes</Body>
      ) : (
        <Grid columns="3fr 3fr 1fr">
          <Heading variant="Headings/H4" as="strong">
            Name
          </Heading>
          <Heading variant="Headings/H4" as="strong">
            Value
          </Heading>
        </Grid>
      )}

      {attributes.map((attr, idx) => {
        if (!attr) return null;
        const { name, value, id } = attr;
        return (
          <AttributeRow
            key={id}
            name={name}
            value={value}
            onChangeName={onChangeAttributeField(id, 'name')}
            onChangeValue={onChangeAttributeField(id, 'value')}
            onDelete={() => onDeleteAttribute(id)}
            canEdit={canEdit}
            index={idx}
          />
        );
      })}

      {canEdit && (
        <Flex>
          <TertiaryButton
            aria-label="add variant attribute"
            onClick={onAddAttribute}
            $iconName="add"
          >
            Add Attribute
          </TertiaryButton>
        </Flex>
      )}
    </Flex>
  );
};

export default SelectedVariantAttributes;
