import Flex from '@react-css/flex';
import Card from 'storybook/stories/cells/Card';
import StatusChip from 'storybook/stories/molecules/Chip/StatusChip';
import Heading from 'storybook/stories/molecules/Heading';
import StatusIcon from 'storybook/stories/molecules/Icon/StatusIcon';

interface GetStartedCardProps {
  /**
   * The title of the card.
   */
  title: string;
  /**
   * Content to be displayed if step is not complete.
   */
  children: React.ReactNode;
  /**
   * Is the card complete?
   */
  isComplete?: boolean;
  /**
   * A custom UI to be displayed if the card is complete.
   */
  completedUi?: React.ReactNode;
}

/**
 * Provides a consistent layout for Get Started cards.
 */
const GetStartedCard = ({
  isComplete,
  children,
  title,
  completedUi = null,
}: GetStartedCardProps) => {
  return (
    <Card>
      <Flex alignItemsStart gap="24px">
        <StatusIcon size="26px" variant={isComplete ? 'checked' : 'unchecked'} />

        <Flex.Item grow={1}>
          <Flex column gap="16px">
            <Heading as="strong" variant="Headings/H3">
              {title}
            </Heading>

            {isComplete ? completedUi : children}
          </Flex>
        </Flex.Item>

        {isComplete && <StatusChip kind="success">Complete</StatusChip>}
      </Flex>
    </Card>
  );
};

export default GetStartedCard;
