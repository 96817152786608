import { forwardRef } from 'react';
import styled from 'styled-components';

import Icon from 'storybook/stories/molecules/Icon';
import Input, { INPUT_HEIGHT } from 'storybook/stories/molecules/Input';

const ICON_CONTAINER_SIZE = INPUT_HEIGHT;

const StyledInput = styled(Input).attrs(() => ({ type: 'email' }))`
  padding-left: ${ICON_CONTAINER_SIZE - 6}px;
`;

type WrapperProps = Pick<EmailInputProps, 'disabled' | 'isFullWidth'>;

const Wrapper = styled.div<WrapperProps>`
  display: ${({ isFullWidth }) => (isFullWidth ? 'flex' : 'inline-flex')};
  position: relative;
  overflow: hidden;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  ${StyledInput} {
    flex-grow: 1;
  }
`;

type DateIconProps = Pick<EmailInputProps, 'disabled'>;

const StyledIcon = styled(Icon).attrs<DateIconProps>(({ disabled }) => ({
  color: disabled ? 'bodyTextDisabled' : 'iconDefault',
}))<DateIconProps>`
  position: absolute;
  inset: 0 auto 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ICON_CONTAINER_SIZE}px;
  height: ${ICON_CONTAINER_SIZE}px;
`;

interface EmailInputProps extends React.ComponentProps<typeof Input> {}

/**
 * Extends an `Input` component's styling and wraps it to provide iconography. Props
 * passed to `EmailInput` will be curried to the underlying `Input` component.
 */
const EmailInput = forwardRef<HTMLInputElement, React.ComponentProps<typeof StyledInput>>(
  ({ disabled, isFullWidth, ...inputProps }: EmailInputProps, ref) => (
    <Wrapper disabled={disabled} isFullWidth={isFullWidth}>
      <StyledIcon name="mail" disabled={disabled} />
      <StyledInput ref={ref} disabled={disabled} isFullWidth={isFullWidth} {...inputProps} />
    </Wrapper>
  )
);

export default EmailInput;
