import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ACKNOWLEDGEMENT,
  FILE_UPLOAD,
  HELLO_SIGN,
  TERMS_AND_CONDITIONS,
} from 'containers/PartnerPage/Actions/Kinds';
import { selectMe } from 'store/selectors/me';
import { createConsumableAction, getActionsSent } from 'store/slices/partners';
import type { PartnerAction } from 'types/models/partner-action';
import { createAgreement } from 'utils/api/agreements';
import { uploadFile } from 'utils/api/documents';

import CreateAcknowledgementActionModal from './Acknowledgement/CreateAcknowledgementActionModal';
import CreateAgreementActionModal from './Agreement/CreateAgreementActionModal';
import type { SelectActionKindFormParams } from './Common/CreateActionKindModal';
import CreateActionKindModal from './Common/CreateActionKindModal';
import type { CreateFileUploadActionData } from './FileUpload/CreateFileUploadActionModal';
import CreateFileUploadActionModal from './FileUpload/CreateFileUploadActionModal';
import CreateTermsAndConditions from './TermsAndConditions';

const LIMIT = 5;
const ACTIONS_TAG = 'actions';

const enum ModalOptions {
  ShowFileUploadFlow,
  ShowAcknowledgementFlow,
  ShowAgreementFlow,
  ShowTermsAndConditionsFlow,
  HideAll,
}

interface CreateActionProps {
  partnerId: string;
  showModal: boolean;
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateAction = ({ partnerId, showModal, setShowCreateModal }: CreateActionProps) => {
  const dispatch = useDispatch();
  const [kind, setKind] = useState(FILE_UPLOAD);
  const [currentModal, setCurrentModal] = useState(ModalOptions.HideAll);

  const { company } = useSelector(selectMe);

  const onSelectActionKind = (data: SelectActionKindFormParams) => {
    if (data.actionKind === FILE_UPLOAD) {
      setCurrentModal(ModalOptions.ShowFileUploadFlow);
    } else if (data.actionKind === HELLO_SIGN) {
      setCurrentModal(ModalOptions.ShowAgreementFlow);
    } else if (data.actionKind === ACKNOWLEDGEMENT) {
      setCurrentModal(ModalOptions.ShowAcknowledgementFlow);
    } else if (data.actionKind === TERMS_AND_CONDITIONS) {
      setCurrentModal(ModalOptions.ShowTermsAndConditionsFlow);
    }

    setKind(data.actionKind);
    setShowCreateModal(false);
  };

  const onBackClick = () => {
    setCurrentModal(ModalOptions.HideAll);
    setKind('');
    setShowCreateModal(true);
  };

  const onRefresh = () => {
    // @ts-ignore
    dispatch(getActionsSent({ assignedFrom: company._id, partnerId, page: 0, limit: LIMIT }));
    setCurrentModal(ModalOptions.HideAll);
  };

  let actionCreationFlow = null;
  if (kind === FILE_UPLOAD) {
    const onCreate = (body: CreateFileUploadActionData) => {
      const bodyCopy = { ...body };
      bodyCopy.partnerId = partnerId;
      if (!bodyCopy.bytes) {
        // @ts-ignore
        return dispatch(createConsumableAction(bodyCopy));
      }
      return uploadFile({
        fileName: bodyCopy.fileName ?? '',
        fileType: bodyCopy.fileType ?? '',
        bytes: bodyCopy.bytes,
        partnersWithViewAccess: [{ partnerId, name: partnerId }],
        sharedWithCompany: true,
        tags: [ACTIONS_TAG, FILE_UPLOAD],
      }).then((response) => {
        const { data } = response;
        const { documentId } = data;
        bodyCopy.optionalDocId = documentId;
        // @ts-ignore
        return dispatch(createConsumableAction(bodyCopy));
      });
    };
    actionCreationFlow = (
      <CreateFileUploadActionModal
        kind={kind}
        showModal={currentModal === ModalOptions.ShowFileUploadFlow}
        onHide={() => setCurrentModal(ModalOptions.HideAll)}
        // @ts-ignore
        onCreate={onCreate}
        onRefresh={onRefresh}
        onBackClick={onBackClick}
        isActionTemplate={false}
      />
    );
  } else if (kind === HELLO_SIGN) {
    const submitAgreementAction = (body: PartnerAction) => {
      const { cardTitle, cardText, required, agreementId } = body;

      return dispatch(
        // @ts-ignore
        createConsumableAction({
          cardTitle,
          cardText,
          required,
          agreementId,
          partnerId,
          kind,
        })
      );
    };
    const onCreate = (body: PartnerAction) => {
      const bodyCopy = { ...body };
      const { cardTitle, file, emailSubject, emailMessage, signers } = bodyCopy;
      return createAgreement({
        name: cardTitle,
        file: file ?? [],
        emailSubject: emailSubject ?? '',
        emailMessage,
        signers: signers ?? [],
        partnerId,
      }).then((response) => {
        if (response.error.length > 0) {
          throw response.error[0];
        } else {
          const { data } = response;
          const { agreementId } = data;
          bodyCopy.agreementId = agreementId;
          return submitAgreementAction(bodyCopy);
        }
      });
    };
    actionCreationFlow = (
      <CreateAgreementActionModal
        kind={HELLO_SIGN}
        showModal={currentModal === ModalOptions.ShowAgreementFlow}
        onHide={() => setCurrentModal(ModalOptions.HideAll)}
        // @ts-ignore
        onCreate={onCreate}
        onRefresh={onRefresh}
        ignoreSigners={false}
        onBackClick={onBackClick}
        isActionTemplate={false}
      />
    );
  } else if (kind === ACKNOWLEDGEMENT) {
    const onCreate = (body: PartnerAction) => {
      const bodyCopy = { ...body };
      const {
        fileName = '',
        fileType = '',
        bytes = [],
        sharedWithCompany,
        cardTitle,
        cardText,
        required,
      } = bodyCopy;
      return uploadFile({
        fileName,
        fileType,
        bytes,
        partnersWithViewAccess: [{ partnerId, name: partnerId }],
        sharedWithCompany,
        tags: [ACTIONS_TAG, ACKNOWLEDGEMENT],
      }).then((response) => {
        // @ts-ignore
        if (response.error.length > 0) {
          // @ts-ignore
          throw response.error[0];
        } else {
          const { data } = response;
          const { documentId } = data;
          bodyCopy.documentId = documentId;
          return dispatch(
            // @ts-ignore
            createConsumableAction({
              cardTitle,
              cardText,
              required,
              documentId,
              partnerId,
              kind,
            })
          );
        }
      });
    };
    actionCreationFlow = (
      <CreateAcknowledgementActionModal
        kind={ACKNOWLEDGEMENT}
        showModal={currentModal === ModalOptions.ShowAcknowledgementFlow}
        onHide={() => setCurrentModal(ModalOptions.HideAll)}
        // @ts-ignore
        onCreate={onCreate}
        onBackClick={onBackClick}
        onRefresh={onRefresh}
        isActionTemplate={false}
      />
    );
  } else if (kind === TERMS_AND_CONDITIONS) {
    const submitAgreementAction = (body: PartnerAction) => {
      const { cardTitle, cardText, required, agreementId, terms } = body;

      return dispatch(
        // @ts-ignore
        createConsumableAction({
          cardTitle,
          cardText,
          required,
          agreementId,
          partnerId,
          kind,
          terms,
        })
      );
    };
    const uploadHelloSignDocument = (body: PartnerAction) => {
      return uploadFile({
        fileName: body.fileName ?? '',
        fileType: body.fileType ?? '',
        bytes: body.file ?? [],
        partnersWithViewAccess: [{ partnerId, name: partnerId }],
        sharedWithCompany: true,
        tags: [ACTIONS_TAG, TERMS_AND_CONDITIONS],
      }).then(() => {
        return submitAgreementAction(body);
      });
    };

    const onCreate = (body: PartnerAction) => {
      const bodyCopy = { ...body };
      const { cardTitle, file, emailSubject, emailMessage, signers } = bodyCopy;

      return createAgreement({
        name: cardTitle,
        file: file ?? [],
        emailSubject: emailSubject ?? '',
        emailMessage,
        signers: signers ?? [],
        partnerId,
      }).then((response) => {
        bodyCopy.partnerId = partnerId;

        if (response.error.length > 0) {
          throw response.error[0];
        } else {
          const { data } = response;
          const { agreementId } = data;
          bodyCopy.agreementId = agreementId;
          return uploadHelloSignDocument(bodyCopy);
        }
      });
    };
    actionCreationFlow = (
      <CreateTermsAndConditions
        showModal={currentModal === ModalOptions.ShowTermsAndConditionsFlow}
        onHide={() => setCurrentModal(ModalOptions.HideAll)}
        onCreate={onCreate}
        onRefresh={onRefresh}
        onGeneralBackClick={onBackClick}
        isActionTemplate={false}
      />
    );
  }

  return (
    <>
      <CreateActionKindModal
        showModal={showModal}
        setShowCreateActionKindModal={setShowCreateModal}
        onSelectKind={onSelectActionKind}
        isActionTemplate={false}
      />
      {actionCreationFlow}
    </>
  );
};

export default CreateAction;
