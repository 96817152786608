import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import elevate from 'storybook/mixins/elevate';
import materialSymbol from 'storybook/mixins/materialSymbol';
import typography from 'storybook/mixins/typography';

const CHECKBOX_SIZE = 20;

type LabelWrapperProps = Pick<CheckboxProps, 'disabled'>;

const LabelWrapper = styled.label<LabelWrapperProps>`
  display: inline-flex;
  gap: 8px;
  align-items: center;

  ${typography('Navigation/Nav Link')};

  ${({ theme, disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          color: ${theme.color.bodyTextDisabled};
          font-style: italic;
        `
      : css`
          cursor: pointer;
          color: ${theme.color.bodyTextPrimary};
        `}
`;

interface CheckboxInputProps {
  /**
   * Pass this prop to visually change the checked icon. This is a visual change
   * only and does not actually set the checkbox into a real "indeterminate" state.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes
   */
  indeterminate?: boolean;
}

const CheckboxInput = styled.input.attrs(() => ({
  type: 'checkbox',
}))<CheckboxInputProps>`
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: ${CHECKBOX_SIZE}px;
  height: ${CHECKBOX_SIZE}px;
  display: grid;
  place-content: center;
  cursor: pointer;

  ${({ theme }) => css`
    border: 2px solid ${theme.color.gray300};
    border-radius: 4px;
  `};

  &::before {
    background-color: CanvasText;

    ${({ theme }) => css`
      background-color: ${theme.color.blue500};
      border-radius: 4px;
    `};
  }

  &:checked {
    ${elevate('1')};

    ${({ theme, indeterminate }) => css`
      ${materialSymbol({
        name: indeterminate ? 'horizontal_rule' : 'check',
        size: `${CHECKBOX_SIZE}px`,
        position: 'before',
        color: 'white',
        weight: 400,
      })}

      background-color: ${theme.color.blue500};
    `};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.gray300};
    cursor: not-allowed;
  }
`;

interface CheckboxProps extends React.ComponentProps<typeof CheckboxInput> {
  /**
   * The name of the form control
   */
  name: string;
  /**
   * The text accompanying the checkbox
   */
  children?: React.ReactNode;
}

/**
 * An accessible checkbox control. Additional props passed directly to the underlying styled checkbox `input`.
 */
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, children, className, disabled, ...rest }, ref) => (
    <LabelWrapper className={className} disabled={disabled}>
      <CheckboxInput name={name} ref={ref} disabled={disabled} {...rest} />
      {children}
    </LabelWrapper>
  )
);

export default Checkbox;
