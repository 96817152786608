import Flex from '@react-css/flex';
import classNames from 'classnames';
import type { ReactNode } from 'react';

interface PageHeaderProps {
  className?: string;
  children: ReactNode;
}

const PageHeader = ({ className, children }: PageHeaderProps) => {
  const cn = classNames('header mt-md-4 mb-4', className);

  return (
    <div className={cn}>
      <div className="header-body">
        <Flex alignItemsCenter row justifySpaceBetween>
          {children}
        </Flex>
      </div>
    </div>
  );
};

export default PageHeader;
