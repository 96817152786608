import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { selectMe } from 'store/selectors/me';

const useFlagEnabled = (flag: string): boolean => {
  const { user } = useSelector(selectMe);
  const flags = user?.flags ?? {};
  return !!get(flags, flag);
};

export default useFlagEnabled;
