import type { CreateAgreement } from 'types/models/agreement';
import { getRequest, postRequest } from 'utils/request';

export const createAgreement = (data: CreateAgreement) => postRequest('/agreements/', data);

export const getEmbeddedAgreement = (agreementId: string) =>
  getRequest(`/agreements/${agreementId}/embedded`);

export const getAgreement = (agreementId: string) => getRequest(`/agreements/${agreementId}/`);

export const getAgreementFile = (agreementId: string) =>
  getRequest(`/agreements/${agreementId}/download`);
