import Flex from '@react-css/flex';
import MarkEmailReadImage from 'images/mark_email_read.svg';
import { useHistory, useLocation } from 'react-router';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import Layout from 'storybook/stories/species/Layout';
import styled from 'styled-components';

const MainImage = styled.img.attrs(() => ({
  src: MarkEmailReadImage,
  alt: 'Check email icon',
}))`
  width: 160px;
  height: 160px;
  max-width: 400px;
`;

const SignInSuccessPage = () => {
  const history = useHistory();
  const location = useLocation<{ email?: string; backPath?: string }>();

  return (
    <Layout.Root>
      <Layout.Content>
        <Card padding="48px">
          <Flex column gap="32px" justifySpaceBetween style={{ height: '100%' }}>
            <Flex column gap="32px">
              <Flex.Item alignSelfCenter>
                <MainImage />
              </Flex.Item>

              <Heading variant="Headings/H2">
                Please check your email for a secure log in link.
              </Heading>

              <Body>
                We&apos;ve sent an email to{' '}
                {location.state?.email ? (
                  <strong>{location.state.email}</strong>
                ) : (
                  'your email address'
                )}
                . Click the link in the email to activate your account.
              </Body>
            </Flex>

            <Flex column gap="24px">
              <Flex column gap="8px">
                <Heading variant="Headings/H4" as="strong">
                  Questions?
                </Heading>

                <Body>Didn&apos;t receive an email? Check your spam folder.</Body>

                <Body>
                  Learn more about Modern Dropship’s{' '}
                  <SupportLink article="getting-starteonBackButtonClickd-on-convictional/passwordless-sign-in">
                    passwordless log in
                  </SupportLink>
                  .
                </Body>

                <Body>
                  Having issues? <a href="mailto:support@convictional.com">Contact Support</a>.
                </Body>
              </Flex>

              {location.state?.backPath && (
                <Flex.Item>
                  <TertiaryButton
                    $iconName="navigate_before"
                    onClick={() => history.push(location.state.backPath!)}
                    $removePadding="left"
                  >
                    Back
                  </TertiaryButton>
                </Flex.Item>
              )}
            </Flex>
          </Flex>
        </Card>
      </Layout.Content>
    </Layout.Root>
  );
};

export default SignInSuccessPage;
