import { useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import FormField from 'components/Common/FormField';
import Spinner from 'components/Common/Spinner';
import useAlertQueue from 'hooks/useAlertQueue';
import { useCanEditStoreName } from 'hooks/useCanEditStoreName';
import { updateWooCommerceAuth } from 'store/slices/company';
import Tooltip from 'storybook/stories/cells/Tooltip';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import { getPlatformUrl } from 'utils/platform';

const ConnectWooCommerce = ({ dispatch, company }) => {
  const alertQueue = useAlertQueue();
  const pending = useSelector(({ company: c }) => c.isUpdatingPlatformAuth);
  const connected = company.productPlatformActive;
  const [showForm, setShowForm] = useState(!connected);
  const storeUrl = getPlatformUrl(company, 'woocommerce');
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      platformUrl: storeUrl,
    },
  });

  const [isStoreNameEditable, storeNameNotEditableReason] = useCanEditStoreName();

  const onSubmit = ({ platformUrl, wooCommerceKey, wooCommerceSecret }) => {
    dispatch(
      updateWooCommerceAuth({
        wooCommerceAuth: {
          platform: 'woocommerce',
          platformUrl: isStoreNameEditable ? platformUrl : storeUrl,
          wooCommerceKey,
          wooCommerceSecret,
        },
      })
    ).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
        return;
      }
      setShowForm(false);
      alertQueue.addSuccessAlert('Success', 'WooCommerce credentials updated');
    });
  };

  return (
    <>
      <hr className="my-4" />
      <h4>
        Connect your WooCommerce Store
        {connected && (
          <Badge variant="success" pill className="ml-3 mt-n1">
            Connected
          </Badge>
        )}
      </h4>
      {!connected && (
        <p className="small text-muted mb-1">
          Please input your WooCommerce credentials so Modern Dropship can connect to your store.{' '}
        </p>
      )}
      {showForm ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          {!isStoreNameEditable ? (
            <Tooltip>
              <Tooltip.Trigger>
                Store: <strong>{storeUrl}</strong>
              </Tooltip.Trigger>
              <Tooltip.Content>{storeNameNotEditableReason}</Tooltip.Content>
            </Tooltip>
          ) : (
            <FormField
              type="text"
              size="sm"
              id="settings-woo-key-input"
              label="Store"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('platformUrl', {
                required: 'WooCommerce Store URL is required',
              })}
              placeholder="Store URL"
              error={errors.platformUrl}
            />
          )}
          <FormField
            type="text"
            size="sm"
            id="settings-woo-key-input"
            label="Consumer Key"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('wooCommerceKey', {
              required: 'WooCommerce Consumer Key is required',
            })}
            placeholder="WooCommerce Consumer Key"
            error={errors.wooCommerceKey}
          />
          <FormField
            type="text"
            size="sm"
            id="settings-woo-secret-input"
            label="Consumer Secret"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('wooCommerceSecret', {
              required: 'WooCommerce Consumer Secret is required',
            })}
            placeholder="WooCommerce Consumer Secret"
            error={errors.wooCommerceSecret}
          />

          <div className="d-flex">
            <SecondaryButton size="small" type="submit" color="primary" outline>
              {pending ? <Spinner color="primary" small className="mx-3" /> : 'Save Credentials'}
            </SecondaryButton>
            {connected && showForm && (
              <SecondaryButton
                kind="destructive"
                size="small"
                type="button"
                className="ml-3"
                onClick={() => setShowForm(false)}
              >
                Cancel
              </SecondaryButton>
            )}
          </div>
        </form>
      ) : (
        <SecondaryButton
          size="small"
          type="submit"
          color="white"
          onClick={() => setShowForm(true)}
          outline
        >
          Change Credentials
        </SecondaryButton>
      )}
    </>
  );
};

export default ConnectWooCommerce;
