import Flex from '@react-css/flex';
import useOnboardingStorage from 'hooks/useOnboardingStorage';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import ProgressBar from 'storybook/stories/organisms/ProgressBar';
import { CommerceType, Platform } from 'types/models/company';
import { friendlyPlatformName } from 'utils/platform';
import { getDomainFromUrl } from 'utils/strings';

interface ReviewProps {
  currentStep: number;
  totalSteps: number;
  onBackButtonClick: () => void;
  isShopifyInstall: boolean;
}

const Review = ({ currentStep, totalSteps, onBackButtonClick, isShopifyInstall }: ReviewProps) => {
  const { watch, reset, formState } = useFormContext();
  const { setOnboardingStorage } = useOnboardingStorage();

  const onClickSubmit = () => {
    setOnboardingStorage(undefined);
  };

  const onClickStartOver = useCallback(() => {
    if (isShopifyInstall) {
      // Keep Shopify Store URL
      reset({
        commerceType: CommerceType.Seller,
        platform: Platform.Shopify,
      });
    } else {
      reset({
        commerceType: CommerceType.Seller,
        platform: Platform.Shopify,
        storeUrl: undefined,
      });
    }
    onBackButtonClick();
  }, [isShopifyInstall, onBackButtonClick, reset]);

  const commerceType = watch('commerceType');
  const platform = watch('platform');
  const storeUrl = watch('storeUrl');

  return (
    <Flex column gap="48px">
      <ProgressBar totalSteps={totalSteps} completedSteps={currentStep} />

      <Flex column gap="32px">
        <Flex column gap="8px">
          <Heading variant="Headings/H3" as="h1">
            Let&apos;s review your dropship settings
          </Heading>

          <Body as="p">Please double-check to confirm this information is accurate.</Body>
        </Flex>

        <Flex column gap="24px">
          <Flex column gap="4px">
            <Body variant="Body/Header" color="bodyTextSecondary">
              You&apos;d like to use Modern Dropship to
            </Body>

            <Heading variant="Headings/H4" as="strong">
              {commerceType === CommerceType.Buyer
                ? 'Buy products from a vendor'
                : 'Sell products to a retailer'}
            </Heading>
          </Flex>
        </Flex>

        <Flex column gap="4px">
          <Body variant="Body/Header" color="bodyTextSecondary">
            You&apos;d like to connect to Modern Dropship via
          </Body>

          <Heading variant="Headings/H4" as="strong">
            {friendlyPlatformName(platform, commerceType)}
          </Heading>
        </Flex>

        {storeUrl && (
          <Flex column gap="4px">
            <Body variant="Body/Header" color="bodyTextSecondary">
              Your store URL is
            </Body>

            <Heading variant="Headings/H4" as="strong">
              https://{getDomainFromUrl(storeUrl)}
            </Heading>
          </Flex>
        )}
      </Flex>

      <Flex justifySpaceBetween>
        <TertiaryButton
          $iconName="navigate_before"
          $removePadding="left"
          onClick={onClickStartOver}
        >
          Start Over
        </TertiaryButton>

        <PrimaryButton
          $iconAfter
          type="submit"
          disabled={formState.isSubmitting}
          onClick={onClickSubmit}
        >
          Confirm Settings
        </PrimaryButton>
      </Flex>
    </Flex>
  );
};

export default Review;
