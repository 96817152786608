import styled from 'styled-components';

import { getListOfActionsGroupByPartner } from 'containers/PartnerPage/Actions/Shared';
import SingleAction from 'containers/PartnerPage/Actions/SingleAction';
import Card from 'storybook/stories/cells/Card';

const PartnerActionsWrapper = styled.div`
  padding: 0.313rem;
`;
const HorizontalSpacer = styled.div`
  width: 87%;
  margin-left: auto;
  margin-right: auto;
  height: 2px;
  background-color: #e8e8e8;
  margin-bottom: 0.625rem;
`;

const PartnerActions = ({ partners, actions }) => {
  if (!actions) {
    return null;
  }
  if (!partners) {
    return null;
  }
  if (actions && actions.length === 0) {
    return null;
  }
  const actionsGroupedByPartner = getListOfActionsGroupByPartner(partners, actions);
  return (
    <Card>
      <h2 className="mb-2">Action Items</h2>
      <p className="text-muted mb-3">Your partners have assigned you actions to be completed.</p>
      {Object.keys(actionsGroupedByPartner).map((partnerId) => {
        const partnership = actionsGroupedByPartner[partnerId];
        return (
          <PartnerActionsWrapper>
            <h3 className="mb-2">{partnership.name}</h3>
            <div>
              {partnership.actions.map((action, index) => (
                <>
                  <SingleAction action={action} partnerId={partnerId} />
                  {index !== partnership.actions.length - 1 && <HorizontalSpacer />}
                </>
              ))}
            </div>
          </PartnerActionsWrapper>
        );
      })}
    </Card>
  );
};

export default PartnerActions;
