import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createCSVMappings as apiCreateCSVMappings } from 'utils/api/csvMappings';
import {
  createSFTPCreds as apiCreateSFTPCreds,
  deleteSFTPCreds as apiDeleteSFTPCreds,
  getSFTPCreds as apiGetSFTPCreds,
  updateSFTPCreds as apiUpdateSFTPCreds,
} from 'utils/api/sftp';
import { getFileData as apiGetFileData, getResources as apiGetResources } from 'utils/api/storage';
import {
  inventoryFieldMappings,
  productFieldMappings,
  variantFieldMappings,
} from 'utils/csvMappings';
import { useSafeState } from 'utils/hooks';

/*
sftpUser slice contains all details pertaining to a sftp user. This includes interactions with the sftp user object (mainly credentials) and their corresponding CSV mappings.
*/

const TABS = '\t';
export const CSV_FILE_TYPE = 'csv';

/*
SFTP credentials related functionality
*/
export const getSFTPCreds = createAsyncThunk('sftpUser/getOne', () => {
  return apiGetSFTPCreds();
});

export const createSFTPCreds = createAsyncThunk('sftpUser/create', (createBody) => {
  return apiCreateSFTPCreds(createBody);
});

export const updateSFTPCreds = createAsyncThunk('sftpUser/update', (updateBody, thunkAPI) => {
  const { sftpUser } = thunkAPI.getState();
  const { sftpUsername } = sftpUser;
  return apiUpdateSFTPCreds(sftpUsername, updateBody);
});

export const deleteSFTPCreds = createAsyncThunk('sftpUser/delete', (_, thunkAPI) => {
  const { sftpUser } = thunkAPI.getState();
  const { sftpUsername } = sftpUser;
  return apiDeleteSFTPCreds(sftpUsername);
});

/*
CSV Mappings related functionality
*/
export const getFiles = createAsyncThunk('csvMappings/getFiles', (queryParams) => {
  return apiGetResources(queryParams);
});

export const getProductFileSampleData = createAsyncThunk(
  'csvMappings/getProductFileSampleData',
  async (data) => {
    const { productFilename, delimiter, fileType } = data;
    const queryParam = {
      key: productFilename,
      delimiter,
      fileType,
    };
    // productFilename is used later on
    return apiGetFileData(queryParam);
  }
);

export const getInventoryFileSampleData = createAsyncThunk(
  'csvMappings/getInventoryFileSampleData',
  async (data) => {
    const { inventoryFilename, delimiter } = data;
    const queryParam = {
      key: inventoryFilename,
      delimiter,
      fileType: CSV_FILE_TYPE,
    };
    return apiGetFileData(queryParam);
  }
);

// type can be constant (ex. instead of optionName, could be size as that's constant for the entire column) or headerName
// NOTE: for now just use headerName to avoid confusing users on UI about this distinction
function createCSVFieldMapping(fieldType, name) {
  return {
    [fieldType]: name,
  };
}

// formats the form info into the csv mapping structure
export const createCSVMappings = createAsyncThunk(
  'csvMappings/create',
  async (mappings, thunkAPI) => {
    const { sftpUser } = thunkAPI.getState();
    const { productFilename, inventoryFilename, delimiter, inventoryFileSelected } = sftpUser;

    const fileVariantFieldMappings = {};
    const fileProductFieldMappings = {};
    const fileInventoryFieldMappings = {};
    const csvMappings = {};

    // setup variantFieldMappings
    for (let i = 0; i < variantFieldMappings.length; i += 1) {
      // skip setting inventory in variantFieldMappings if an inventoryFile was selected
      const isFileOverriding = variantFieldMappings[i] === 'inventory' && inventoryFileSelected;

      if (!isFileOverriding && mappings[variantFieldMappings[i]]) {
        // need to rename some fields to match the data model
        let variantFieldName;
        switch (variantFieldMappings[i]) {
          case 'variantCode':
            variantFieldName = 'code';
            break;
          case 'variantTitle':
            variantFieldName = 'title';
            break;
          default:
            variantFieldName = variantFieldMappings[i];
        }
        fileVariantFieldMappings[variantFieldName] = createCSVFieldMapping(
          'headerName',
          mappings[variantFieldMappings[i]]
        );
      }
    }

    // setup productFieldMappings
    fileProductFieldMappings.variants = fileVariantFieldMappings;
    for (let i = 0; i < productFieldMappings.length; i += 1) {
      if (mappings[productFieldMappings[i]]) {
        // tags is an array can map to multiple headers, currently only allow 1-1
        if (productFieldMappings[i] === 'tags') {
          fileProductFieldMappings[productFieldMappings[i]] = [
            createCSVFieldMapping('headerName', mappings[productFieldMappings[i]]),
          ];
        } else {
          // need to rename some fields to match the data model
          let productFieldName;
          switch (productFieldMappings[i]) {
            case 'productCode':
              productFieldName = 'code';
              break;
            case 'productTitle':
              productFieldName = 'title';
              break;
            default:
              productFieldName = productFieldMappings[i];
          }
          fileProductFieldMappings[productFieldName] = createCSVFieldMapping(
            'headerName',
            mappings[productFieldMappings[i]]
          );
        }
      }
    }

    if (inventoryFileSelected) {
      // setup inventoryFieldMappings
      for (let i = 0; i < inventoryFieldMappings.length; i += 1) {
        if (mappings[inventoryFieldMappings[i]]) {
          fileInventoryFieldMappings[inventoryFieldMappings[i]] = createCSVFieldMapping(
            'headerName',
            mappings[inventoryFieldMappings[i]]
          );
        }
      }
    }

    // setup csvMappings
    csvMappings.productFilename = productFilename;
    csvMappings.inventoryFilename = inventoryFilename;
    csvMappings.delimiter = delimiter;
    csvMappings.trimLeadingSpace = true;
    if (delimiter === TABS) {
      csvMappings.trimLeadingSpace = false;
    }
    csvMappings.productFileMappings = fileProductFieldMappings;
    csvMappings.inventoryFileMappings = fileInventoryFieldMappings;
    csvMappings.live = true;
    csvMappings.testing = true;

    try {
      const res = await apiCreateCSVMappings(csvMappings);
      return res;
    } catch (apiError) {
      // because we need the ApiError.errors, we need to explicitly reject the action with our errors.
      // this is because createAsyncThunk will serialize the ApiError to a standard and remove the errors array
      // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      // Note: the action returned from a rejected thunk with have error details at action.payload instead of action.error
      return thunkAPI.rejectWithValue({ message: apiError.message, errors: apiError.errors });
    }
  }
);

/*
Currently just for loading UI.
*/
const sftpUserSlice = createSlice({
  name: 'sftpUser',
  initialState: {
    sftpUserExists: false,
    sftpUsername: '',
    isCreatingSFTPUser: false,
    isUpdatingSFTPUser: false,
    files: [],
    isGettingFiles: false,
    productFileSelected: false,
    inventoryFileSelected: false,
    productFilename: '',
    inventoryFilename: '',
    delimiter: '',
    productFileHeaders: [],
    productFileSampleData: [],
    inventoryFileHeaders: [],
    inventoryFileSampleData: [],
    // productFileHeaderToIndex and inventoryFileHeaderToIndex are used to facilitate displaying preview info
    productFileHeaderToIndex: {},
    inventoryFileHeaderToIndex: {},
    hasEmptyFileHeaders: false,
    createdCSVMappings: false,
  },
  reducers: {
    resetSelectedFiles(draft) {
      draft.productFilename = '';
      draft.inventoryFilename = '';
      draft.delimiter = '';
      draft.productFileSelected = false;
      draft.productFileHeaders = [];
      draft.productFileSampleData = [];
      draft.inventoryFileSelected = false;
      draft.inventoryFileHeaders = [];
      draft.inventoryFileSampleData = [];
      draft.hasEmptyFileHeaders = false;
      draft.createdCSVMappings = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSFTPCreds.fulfilled, (draft, action) => {
        const { username } = action.payload.data;
        draft.sftpUserExists = true;
        draft.sftpUsername = username;
      })
      .addCase(createSFTPCreds.pending, (draft) => {
        draft.isCreatingSFTPUser = true;
      })
      .addCase(createSFTPCreds.fulfilled, (draft, action) => {
        const { username } = action.meta.arg;
        draft.isCreatingSFTPUser = false;
        draft.sftpUserExists = true;
        draft.sftpUsername = username;
      })
      .addCase(createSFTPCreds.rejected, (draft) => {
        draft.isCreatingSFTPUser = false;
      })
      .addCase(updateSFTPCreds.pending, (draft) => {
        draft.isUpdatingSFTPUser = true;
      })
      .addCase(updateSFTPCreds.rejected, (draft) => {
        draft.isUpdatingSFTPUser = false;
      })
      .addCase(updateSFTPCreds.fulfilled, (draft) => {
        draft.isUpdatingSFTPUser = false;
      })
      .addCase(deleteSFTPCreds.fulfilled, (draft) => {
        draft.sftpUserExists = false;
        draft.sftpUsername = '';
      })
      .addCase(getFiles.pending, (draft) => {
        draft.isGettingFiles = true;
      })
      .addCase(getFiles.rejected, (draft) => {
        draft.isGettingFiles = false;
      })
      .addCase(getFiles.fulfilled, (draft, action) => {
        draft.isGettingFiles = false;
        draft.files = action.payload.data;
      })
      .addCase(getProductFileSampleData.fulfilled, (draft, action) => {
        const sampleData = action.payload.data;
        const { productFilename, delimiter } = action.meta.arg;
        draft.productFileSelected = true;
        // file contents are empty
        if (sampleData.length === 0) {
          return;
        }
        // headers cannot be empty
        const productFileHeaders = sampleData[0];
        const hasEmptyHeaders = productFileHeaders.filter((header) => header === '').length !== 0;
        if (hasEmptyHeaders) {
          draft.hasEmptyFileHeaders = true;
          return;
        }
        // setup header and sample data
        const productFileSampleData = [];
        for (let i = 1; i < sampleData.length; i += 1) {
          productFileSampleData.push(sampleData[i]);
        }
        const productFileHeaderToIndex = {};
        for (let i = 0; i < productFileHeaders.length; i += 1) {
          productFileHeaderToIndex[productFileHeaders[i]] = i;
        }
        draft.productFileHeaders = productFileHeaders;
        draft.productFileSampleData = productFileSampleData;
        draft.productFileHeaderToIndex = productFileHeaderToIndex;
        draft.productFilename = productFilename;
        draft.delimiter = delimiter;
      })
      .addCase(getInventoryFileSampleData.fulfilled, (draft, action) => {
        const sampleData = action.payload.data;
        const { inventoryFilename } = action.meta.arg;
        draft.inventoryFileSelected = true;
        // file contents are empty
        if (sampleData.length === 0) {
          return;
        }
        // headers cannot be empty
        const inventoryFileHeaders = sampleData[0];
        const hasEmptyHeaders = inventoryFileHeaders.filter((header) => header === '').length !== 0;
        if (hasEmptyHeaders) {
          draft.hasEmptyFileHeaders = true;
          return;
        }
        // setup header and sample data
        const inventoryFileSampleData = [];
        for (let i = 1; i < sampleData.length; i += 1) {
          inventoryFileSampleData.push(sampleData[i]);
        }
        const inventoryFileHeaderToIndex = {};
        for (let i = 0; i < inventoryFileHeaders.length; i += 1) {
          inventoryFileHeaderToIndex[inventoryFileHeaders[i]] = i;
        }
        draft.inventoryFileHeaders = inventoryFileHeaders;
        draft.inventoryFileSampleData = inventoryFileSampleData;
        draft.inventoryFileHeaderToIndex = inventoryFileHeaderToIndex;
        draft.inventoryFilename = inventoryFilename;
      })
      .addCase(createCSVMappings.fulfilled, (draft) => {
        draft.createdCSVMappings = true;
      });
  },
});

export const { resetSelectedFiles } = sftpUserSlice.actions;

export default sftpUserSlice.reducer;

// called on sftp settings mount to load available sftp user details for the company
export const useGetSFTPCreds = () => {
  const [loading, setLoading] = useSafeState(true);
  const [error, setError] = useSafeState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSFTPCreds()).then((action) => {
      if (action.error && !action.error.message.includes('not found')) {
        console.error('Unable to get sftp creds', action.error.message);
        setError(action.error.message);
      }
      setLoading(false);
    });
  }, [dispatch, setError, setLoading]);

  return {
    loading,
    error,
  };
};

// called on csv mapping modal on mount to reload available product and inventory files for the company (in their GCS bucket)
export const useGetFiles = (downloadDirectory) => {
  const [loading, setLoading] = useSafeState(true);
  const [error, setError] = useSafeState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getFiles({
        fileType: CSV_FILE_TYPE,
        key: downloadDirectory,
      })
    ).then((action) => {
      if (action.error) {
        console.error('Unable to get CSV files', action.error.message);
        setError(action.error.message);
      }
      setLoading(false);
    });
  }, [dispatch, setError, setLoading, downloadDirectory]);

  return {
    loading,
    error,
  };
};
