import typography from 'storybook/mixins/typography';
import styled, { css } from 'styled-components';

// CELLS

interface CommonTableCellProps {
  /**
   * Make the content bold
   */
  bold?: boolean;
  /**
   * Horizontally align the content
   */
  align?: string;
  /**
   * Width of the cell
   */
  width?: string;
  /**
   * Truncate the cell
   */
  truncate?: boolean;
  /**
   * Don't wrap cell contents
   */
  nowrap?: boolean;
}

const TH = styled.th<CommonTableCellProps>`
  ${typography('Body/Header')};
  color: ${({ theme }) => theme.color.bodyTextSecondary};

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}

  ${({ align = 'left' }) => css`
    text-align: ${align};
  `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ nowrap }) =>
    nowrap &&
    css`
      white-space: nowrap;
    `}

  ${({ truncate }) =>
    truncate &&
    css`
      width: 100%;
      max-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const TD = styled.td<CommonTableCellProps>`
  ${typography('Body/Regular')};
  color: ${({ theme }) => theme.color.bodyTextPrimary};

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  
  ${({ nowrap }) =>
    nowrap &&
    css`
      white-space: nowrap;
    `}

  ${({ truncate }) =>
    truncate &&
    css`
      width: 100%;
      max-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

// ROWS

interface TRProps {
  /**
   * Make all content bold
   */
  bold?: boolean;
  /**
   * Horizontally align all content
   */
  align?: string;
  /**
   * Vertically align all content
   */
  valign?: string;
  /**
   * Apply some basic styling for non-interactive rows
   */
  disabled?: boolean;
  /**
   * Optional click handler will apply hover styling
   */
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
}

const TR = styled.tr.attrs<TRProps>(({ onClick, tabIndex }) => ({
  tabIndex: onClick ? 0 : tabIndex,
}))<TRProps>`
  ${({ bold }) =>
    bold &&
    css`
      ${TH},
      ${TD} {
        font-weight: bold;
      }
    `}

  ${({ align }) =>
    align &&
    css`
      ${TH},
      ${TD} {
        text-align: ${align}};
      }
    `}

  ${({ valign }) =>
    valign &&
    css`
      ${TH},
      ${TD} {
        vertical-align: ${valign}};
      }
    `}
  
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.color.gray100};

        ${TH},
        ${TD} {
          box-shadow: 1px 3px 3px ${({ theme }) => theme.color.gray200};
        }

        ${TH}:first-child,
        ${TD}:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        ${TH}:last-child,
        ${TD}:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

// CONTAINERS

interface CommonTableContainerProps {
  /**
   * Make all content bold
   */
  bold?: boolean;
  /**
   * Horizontally align all content
   */
  align?: string;
}

const THead = styled.thead<CommonTableContainerProps>`
  ${({ bold }) =>
    bold &&
    css`
      ${TH} {
        font-weight: bold;
      }
    `}

  ${({ align }) =>
    align &&
    css`
      ${TH} {
        text-align: ${align}};
      }
    `}
`;

const TBody = styled.tbody<CommonTableContainerProps>`
  ${({ align }) =>
    align &&
    css`
      ${TD} {
        text-align: ${align}};
      }
    `}
`;

const TFoot = styled.tfoot<CommonTableContainerProps>`
  ${({ bold }) =>
    bold &&
    css`
      ${TD} {
        font-weight: bold;
      }
    `}

  ${({ align }) =>
    align &&
    css`
      ${TD} {
        text-align: ${align}};
      }
    `}
`;

// MAIN

enum TableVariants {
  Default = 'default',
  Data = 'data',
  List = 'list',
}

interface TableProps {
  /**
   * Optional variant to change styling.
   */
  variant?: `${TableVariants}`;
}

const Base = styled.table<TableProps>`
  width: 100%;
  font-size: 13px;

  // Supports rounding table data cells
  border-collapse: separate;
  border-spacing: 0;

  ${TH},
  ${TD} {
    padding: 4px 8px;
  }

  ${({ theme, variant }) => {
    switch (variant) {
      case 'data':
        return css`
          ${TH} {
            text-transform: uppercase;
            padding-bottom: 24px;
          }

          ${TD} {
            font-size: 14px;
            padding-top: 24px;
            padding-bottom: 24px;
          }

          ${TFoot} ${TD} {
            padding-bottom: 0;
          }
        `;
      case 'list':
        return css`
          border-collapse: collapse;

          ${TH} {
            padding-bottom: 8px;
            padding: 8px 16px;
          }
          ${TD} {
            padding: 8px 0px;
          }
          ${TR}:nth-child(even) {
            background-color: ${theme.color.gray100};
            border-radius: 8px;
          }

          // Add rounded border and padding to first cell
          ${TD}:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            padding-left: 16px;
          }

          // Add rounded border and padding to last cell
          ${TD}:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            padding-right: 16px;
          }
          ${THead} ${TR} {
            border-bottom: 1px solid ${theme.color.gray200};
          }
        `;
      default:
        return css``;
    }
  }}
`;

/**
 * A table component providing basic styling.
 */
const Table = Object.assign(Base, {
  THead,
  TBody,
  TFoot,
  TR,
  TH,
  TD,
});

export default Table;
