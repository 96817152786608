import type { BaseQueryParams, PaginatedResponse, Response } from 'types/api';
import type { BaseSearchParams } from 'types/general';
import type { SellerValidationError } from 'types/models/buyer-product-validation';
import type { LegacyBuyerProduct } from 'types/models/legacy-buyer-product';
import type {
  BuyerProductSearchAggregationData,
  BuyerProductSearchData,
  SellerProductSearchAggregationData,
  SellerProductSearchData,
} from 'types/models/search';
import type { SellerProduct } from 'types/models/seller-product';

import { deleteRequest, getRequest, patchRequest, postCSVRequest, putRequest } from 'utils/request';

interface GetProductsParams extends BaseQueryParams {
  title?: string;
  tagsStr?: string;
  activeStr?: string;
  vendor?: string;
  productCode?: string;
  companyId?: string;
  brand?: string;
  updatedMin?: string;
}

export type GetProductsResponse = PaginatedResponse<LegacyBuyerProduct[] | SellerProduct[]>;

export const getProducts = (params: GetProductsParams): Promise<GetProductsResponse> =>
  getRequest('/products', params);

type GetProductResponse = Response<LegacyBuyerProduct | SellerProduct>;

export const getProduct = (productId: string): Promise<GetProductResponse> =>
  getRequest(`/products/${productId}`);

type PatchProductResponse = LegacyBuyerProduct | SellerProduct;

export const patchProduct = (
  productId: string,
  data: Partial<LegacyBuyerProduct | SellerProduct>
): Promise<PatchProductResponse> => patchRequest(`/products/${productId}`, data);

type UploadProductCSVResponse = LegacyBuyerProduct[] | SellerProduct[];

export const uploadProductCSV = (file: FileReader['result']): Promise<UploadProductCSVResponse> =>
  postCSVRequest('/products/csv', file, { 'Content-Type': 'text/csv', Accept: 'application/json' });

type DeleteProductResponse = { deleted: string };

export const deleteProduct = (productId: string): Promise<DeleteProductResponse> =>
  deleteRequest(`/products/${productId}`);

type SelectProductResponse = {};

export const selectProduct = (productId: string, data = {}): Promise<SelectProductResponse> =>
  putRequest(`/products/${productId}/select`, data);

type DeselectProductResponse = {};

export const deselectProduct = (productId: string, data = {}): Promise<DeselectProductResponse> =>
  putRequest(`/products/${productId}/deselect`, data);

type DeleteImageResponse = {};

export const deleteProductImage = (
  productId: string,
  imageId: string
): Promise<DeleteImageResponse> => deleteRequest(`/products/${productId}/images/${imageId}`);

type GetProductValidationErrorsForSellerResponse = Response<SellerValidationError[]>;

export const getProductValidationErrorsForSeller = (
  productId: string
): Promise<GetProductValidationErrorsForSellerResponse> =>
  getRequest(`/products/${productId}/validation-errors`);

interface ProductSearchFilters {
  active?: string;
  sellerId?: string;
  brand?: string;
  selected?: string;
  selectedAvailable?: string;
}

export type ProductSearchParams = BaseSearchParams<ProductSearchFilters>;

export type ProductSearchResponse = PaginatedResponse<
  BuyerProductSearchData | SellerProductSearchData
>;

export const getProductSearch = (params?: ProductSearchParams): Promise<ProductSearchResponse> =>
  getRequest('/products/search', params);

export type ProductSearchAggregationResponse = Response<
  BuyerProductSearchAggregationData | SellerProductSearchAggregationData
>;

export const getProductSearchAggregations = (
  params?: ProductSearchParams
): Promise<ProductSearchAggregationResponse> => getRequest('/products/search/aggregations', params);
