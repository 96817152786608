import styled from 'styled-components';

const UpdatedIndicator = styled.span`
  height: 8px;
  width: 8px;
  background: var(--warning);
  display: inline-block;
  border-radius: 4px;
`;

export default UpdatedIndicator;
