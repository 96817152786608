import Flex from '@react-css/flex';
import styled from 'styled-components';

import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';

import PageHeader from 'components/Common/PageHeader';
import { Link } from 'react-router-dom';
import Icon from 'storybook/stories/molecules/Icon';

interface NoOrderPageProps {
  error: string | undefined;
}

const EmptyText = styled.div`
  height: 0.9375rem;
  margin-bottom: 0.5rem;
  background: var(--light);
  border-radius: 4px;
`;

const EmptyHeading = styled(EmptyText)`
  height: 1.6rem;
  width: 24rem;
`;

const EmptySubheading = styled(EmptyText)`
  height: 1rem;
  width: 16rem;
`;

const LargeIcon = styled(Icon)`
  font-size: 5rem;
`;

const NoOrderPage = ({ error }: NoOrderPageProps) => {
  return (
    <>
      <PageHeader>
        <Flex>
          <Flex.Item>
            <TertiaryButton as={Link} to="/orders">
              <Icon name="west" />
            </TertiaryButton>
          </Flex.Item>
        </Flex>
        <Flex>
          <EmptyHeading className="mb-3" />
          <EmptySubheading />
        </Flex>
      </PageHeader>
      <div className="p-5 d-flex justify-content-center align-items-center flex-column">
        <LargeIcon name="warning" className={error ? 'text-danger' : 'text-warning'} />
        <h2 className="mb-4">
          {error ? 'An error occurred while fetching this order' : 'This order could not be found'}
        </h2>
        <SecondaryButton as={Link} to="/orders">
          Go To Orders
        </SecondaryButton>
      </div>
    </>
  );
};

export default NoOrderPage;
