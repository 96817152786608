import Flex from '@react-css/flex';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import type { Company } from 'types/models/company';
import type { PriceList } from 'types/models/price-list';

import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Divider from 'storybook/stories/molecules/Divider';
import Heading from 'storybook/stories/molecules/Heading';
import Toggle from 'storybook/stories/molecules/Toggle';
import type { UpdatePriceListParams } from 'utils/api/priceLists';
import { isPlatformModern } from 'utils/platform';

const ignoreUpdatesTrue =
  'Lock Prices: Your prices will remain static from the date the price list was created';
const ignoreUpdatesFalseIntegrated =
  'Automatic Price Updates: Prices will update in conjunction with your connected platform';
const ignoreUpdatesFalseOther =
  'Automatic Price Updates: Prices will update in conjunction with your product details in Modern Dropship';

type AutomationFormFields = UpdatePriceListParams;

interface AutomationCardProps {
  company?: Company | null;
  priceList: PriceList;
  onFormSubmit: (data: UpdatePriceListParams) => void;
  isPriceListUpdated: boolean;
}

const getUpdateStatus = (shouldIgnoreAutomatedSyncs: boolean, company?: Company | null) => {
  if (shouldIgnoreAutomatedSyncs) {
    return ignoreUpdatesTrue;
  }

  return company && isPlatformModern(company.productPlatform)
    ? ignoreUpdatesFalseIntegrated
    : ignoreUpdatesFalseOther;
};

const AutomationCard = ({
  company,
  priceList,
  onFormSubmit,
  isPriceListUpdated,
}: AutomationCardProps) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isValid, isSubmitted },
  } = useForm<AutomationFormFields>({
    mode: 'onChange',
    defaultValues: {
      shouldAddNewProducts: priceList.shouldAddNewProducts,
      shouldIgnoreAutomatedSyncs: priceList.shouldIgnoreAutomatedSyncs,
    },
  });

  // Used to clear the footer row when form submission is successful
  useEffect(() => {
    if (isPriceListUpdated && isSubmitted) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitted, reset, isPriceListUpdated]);

  return (
    <Card>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Flex column gap="24px" alignItemsStretch>
          <Card.Head>
            <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
              Automation
            </Heading>
          </Card.Head>

          <Card.Body>
            <Flex column gap="34px">
              <Toggle
                {...register('shouldAddNewProducts')}
                description={
                  <span>
                    <Body variant="Body/Body Small" color="gray600">
                      New products synced into Modern Dropship{' '}
                      {watch('shouldAddNewProducts') ? 'will' : 'will not'} be added to this price
                      list for dropshipping.
                    </Body>
                  </span>
                }
              >
                Apply to New Products
              </Toggle>

              <Toggle
                {...register('shouldIgnoreAutomatedSyncs')}
                description={
                  <span>
                    <Body variant="Body/Body Small" color="gray600">
                      {getUpdateStatus(watch('shouldIgnoreAutomatedSyncs') ?? false, company)}
                    </Body>
                  </span>
                }
              >
                Ignore Product Updates
              </Toggle>
            </Flex>
          </Card.Body>

          {isDirty && (
            <Flex column gap="24px" alignItemsEnd>
              <Divider style={{ alignSelf: 'stretch' }} />

              <Flex gap="4px">
                <TertiaryButton
                  type="reset"
                  size="small"
                  onClick={() =>
                    reset({
                      shouldAddNewProducts: priceList.shouldAddNewProducts,
                      shouldIgnoreAutomatedSyncs: priceList.shouldIgnoreAutomatedSyncs,
                    })
                  }
                >
                  Cancel
                </TertiaryButton>

                <PrimaryButton type="submit" size="small" disabled={!isValid}>
                  Save Changes
                </PrimaryButton>
              </Flex>
            </Flex>
          )}
        </Flex>
      </form>
    </Card>
  );
};

export default AutomationCard;
