import Timeline from 'storybook/stories/organisms/Timeline';
import type { EventComponentProps } from 'storybook/stories/organisms/Timeline/HistoryTimeline';
import type { Order20210101 } from 'types/models/order';

const OrderItemsCancelledPayload = ({ event }: EventComponentProps) => {
  const snapshot = event.snapshot as Order20210101;

  const allItemsCancelled = (snapshot.items || []).every((item) => item.cancelled);

  return (
    <Timeline.Event
      iconName="close"
      timestamp={event.createdAt}
      title={allItemsCancelled ? 'Order Cancelled' : 'Order Partially Cancelled'}
    />
  );
};

export default OrderItemsCancelledPayload;
