import AcknowledgementAction from 'containers/PartnerPage/Actions/Acknowledgement/AcknowledgementAction';
import AgreementAction from 'containers/PartnerPage/Actions/Agreement/AgreementAction';
import FileUploadAction from 'containers/PartnerPage/Actions/FileUpload/FileUploadAction';
import {
  ACKNOWLEDGEMENT,
  FILE_UPLOAD,
  HELLO_SIGN,
  TERMS_AND_CONDITIONS,
} from 'containers/PartnerPage/Actions/Kinds';
import TermsAndConditionsAction from 'containers/PartnerPage/Actions/TermsAndConditions/TermsAndConditionsAction';

import './SingleAction.css';

const SingleAction = (props) => {
  const {
    action: { kind },
  } = props;

  /* eslint-disable react/jsx-props-no-spreading */
  if (kind === FILE_UPLOAD) {
    return <FileUploadAction {...props} />;
  }
  if (kind === HELLO_SIGN) {
    return <AgreementAction {...props} />;
  }
  if (kind === ACKNOWLEDGEMENT) {
    return <AcknowledgementAction {...props} />;
  }
  if (kind === TERMS_AND_CONDITIONS) {
    return <TermsAndConditionsAction {...props} />;
  }
  /* eslint-enable react/jsx-props-no-spreading */

  return (
    <div className="invalid-type-wrapper">
      <p className="invalid-type-text">Something went wrong</p>
      <p className="invalid-type-subtext small">
        We lost track what type of action this is. Please contact support@convictional.com
      </p>
    </div>
  );
};

export default SingleAction;
