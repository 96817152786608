// CSS for this component is provided by public/index.css.
// This markup should be identical to the preloader in public/index.html
const PageLoader = () => {
  return (
    <div className="preloader-container" data-testid="page-loader">
      <div className="preloader">
        <div />
        <div />
        <div />
        <div />

        <svg
          width="75"
          height="75"
          viewBox="0 0 141 141"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M126.892 0H6.05504C2.71584 0 0.00888062 2.70696 0.00888062 6.04616C0.00888062 7.6497 0.64588 9.18756 1.77975 10.3214L130.679 139.22C133.04 141.581 136.868 141.581 139.229 139.22C140.363 138.086 141 136.549 141 134.945V14.1081C141 6.31642 134.684 0 126.892 0Z"
            fill="#228AFF"
          />
          <path
            d="M22.0926 133.016H90.5294C93.8686 133.016 96.5756 130.309 96.5756 126.969C96.5756 125.366 95.9386 123.828 94.8047 122.694L18.3059 46.1953C15.9448 43.8342 12.1165 43.8342 9.75538 46.1953C8.6215 47.3292 7.9845 48.8671 7.9845 50.4706L7.9845 118.907C7.9845 126.699 14.3009 133.016 22.0926 133.016Z"
            fill="#50E3C2"
          />
        </svg>
      </div>
    </div>
  );
};

export default PageLoader;
