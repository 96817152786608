import { useSelect } from 'downshift';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from 'components/Common/Button';
import FormField from 'components/Common/FormField';
import Spinner from 'components/Common/Spinner';
import doneIcon from 'images/checkmark.svg';
import downArrow from 'images/down-arrow.svg';
import editIcon from 'images/pencil.svg';
import deleteIcon from 'images/trash-can-danger.svg';
import { billingAddressAdded } from 'store/slices/getStarted';
import { deleteLocationFromUserCompany, updateUserCompany } from 'store/slices/settings/company';
import { separateCamelCase, toProperCase } from 'utils/strings';

import useAlertQueue from 'hooks/useAlertQueue';
import { useAppDispatch } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import { SettingsFooter, SettingsMain, SettingsPageHeader } from '../SettingsLayout';

const ADDRESS_TYPES = {
  billing: 'Billing',
  shipping: 'Shipping',
  hq: 'HQ Location',
  payables: 'Payables',
  receivables: 'Receivables',
  returns: 'Returns',
  fulfillment: 'Fulfillment',
};

// used for sorting and checking empty state
const FIELDS = [
  'phoneNumber',
  'name',
  'company',
  'addressOne',
  'addressTwo',
  'city',
  'state',
  'country',
  'zip',
];

const LOCATION_TEMPLATE = {
  type: '',
  phoneNumber: '',
  company: '',
  name: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  country: '',
  zip: '',
  inEditMode: true,
  updated: false,
  deleted: false,
  errors: {},
};

const LOCATION_PLACEHOLDERS = {
  phoneNumber: '416 416 4161',
  company: 'Example, Inc.',
  name: 'John Smith',
  addressOne: '1524 Wilshire Dr',
  addressTwo: 'Unit 903',
  city: 'Vancouver',
  state: 'BC',
  country: 'CA',
  zip: 'V6B 6A7',
};

const DEFAULT_LOCATION_ID = 'new-location-0';

// Styles

const LocationCard = styled.fieldset`
  padding: 1rem;
  margin: 2rem 0;
  border: 1px solid ${({ hasError }) => (hasError ? '#C6504B' : '#d2ddec')};
  border-radius: 4px;
  background-color: #f9fbfd;
`;

const LocationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${({ hasError }) => (hasError ? '#C6504B' : '#d2ddec')};
  border-radius: 4px;
  background-color: white;
  &:hover {
    border: 1px solid rgb(44, 123, 229);
  }
`;

const DropdownLabel = styled.label`
  padding: 0.25rem 0 0.25rem 0.5rem;
  margin: 0;
`;

const DropdownButton = styled.button`
  width: 1rem;
  height: 2rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: white;
  background-image: url(${downArrow});
  background-repeat: no-repeat;
  background-position: center;
`;

const DropdownMenu = styled.ul`
  ${({ isOpen }) => !isOpen && 'display: none;'}
  list-style-type: none;
  position: absolute;
  top: 2rem;
  margin-top: 0.1rem;
  width: 9.125rem;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #d2ddec;
  border-radius: 4px;
  margin-bottom: 0;
`;

const DropdownItem = styled.li`
  &:hover {
    padding-left: 0.5rem;
    margin: 0 -0.5rem;
    background-color: #f3f7fc;
  }
`;

const AddressLine = styled.p`
  margin-bottom: 0.25rem;
`;

const IconButton = styled(Button)`
  height: 1.3rem;
  width: 1.3rem;
  padding: 0;
  background-image: url(${(props) => props.icon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const UpdatesBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  padding: 0 0.5rem;
  margin-left: 0.5rem;
  color: #283e59;
  border-radius: 4px;
  border: 1px solid transparent;

  ${({ color }) => {
    switch (color) {
      case 'green':
        return css`
          border-color: #ccf7e5;
          background-color: #f5fffa;
        `;
      case 'red':
        return css`
          border-color: #fad7dd;
          background-color: #fff0f0;
        `;
      default:
        return css`
          border-color: #d2ddec;
          background-color: #f9fbfd;
        `;
    }
  }}
`;

const mapLocationsToState = (data) =>
  data.reduce(
    (allLocations, location) => ({
      ...allLocations,
      [location._id]: {
        ...location,
        inEditMode: false,
        updated: false,
        deleted: false,
        errors: {},
      },
    }),
    {}
  );

// Helpers

const LocationTypeSelector = ({ id, items, initialSelectedItem, onChange, error }) => {
  const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps } =
    useSelect({
      items,
      onSelectedItemChange: ({ selectedItem: item }) => onChange({ selectedItem: item, id }),
    });

  return (
    <DropdownContainer hasError={error}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DropdownLabel {...getLabelProps()}>
        {ADDRESS_TYPES[selectedItem] || ADDRESS_TYPES[initialSelectedItem] || 'Choose Type...'}
      </DropdownLabel>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DropdownButton type="button" {...getToggleButtonProps()} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DropdownMenu isOpen={isOpen} {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key, react/jsx-props-no-spreading
            <DropdownItem key={`${item}${index}`} {...getItemProps({ item, index })}>
              {ADDRESS_TYPES[item]}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

const LocationInfo = ({ company, name, addressOne, addressTwo, city, state, zip, country }) => (
  <>
    <AddressLine>{name}</AddressLine>
    <AddressLine>{company}</AddressLine>
    <AddressLine>{addressOne}</AddressLine>
    {addressTwo && <AddressLine>{addressTwo}</AddressLine>}
    {city && state && zip && <AddressLine>{`${city}, ${state} ${zip}`}</AddressLine>}
    {country}
  </>
);

const Status = ({ creates, updates, deletes }) => (
  <div className="d-flex justify-content-center align-item-center" style={{ marginLeft: 'auto' }}>
    {creates > 0 && <UpdatesBadge color="green">Adding {creates}</UpdatesBadge>}
    {updates > 0 && <UpdatesBadge color="neutral">Updating {updates}</UpdatesBadge>}
    {deletes > 0 && <UpdatesBadge color="red">Deleting {deletes}</UpdatesBadge>}
  </div>
);

const getNumWipLocations = (locations) =>
  Object.values(locations).reduce((total, { deleted }) => (deleted ? total : total + 1), 0);

const getValidationMessage = (label, value) => {
  if (label === 'type') return 'Please choose an address type';
  if (/type|addressOne|city|state|country|zip/.test(label) && value === '') return 'Enter value';

  return 'Invalid character';
};

const getFormFieldStyle = (label) => {
  if (/name|phoneNumber|company|city|state|country/.test(label)) return { width: '12rem' };
  if (/addressOne|addressTwo/.test(label)) return { width: '18rem' };

  return { width: '6rem' };
};

const mapLocationsToValidation = (locations) => {
  return Object.entries(locations).reduce((updates, [id, location]) => {
    // don't validate if deleted, but carry deleted active location to create delete promise onSubmit
    if (location.deleted) return { ...updates, [id]: location };

    // don't validate empty state form if sole form and untouched
    if (id === DEFAULT_LOCATION_ID && !location.updated && getNumWipLocations(locations) === 1) {
      return { ...updates, [id]: location };
    }

    // don't add error if meets success case, add correct message if fails
    const errors = Object.entries(location).reduce((accumulator, [label, value]) => {
      if (/_id|label|inEditMode|updated|deleted|errors/.test(label)) return accumulator;

      if (/^[a-z0-9&.,'-\s]+$/i.test(value)) return accumulator;

      if (/name|company|phoneNumber|addressTwo/.test(label) && value === '') return accumulator;

      return { ...accumulator, [label]: { message: getValidationMessage(label, value) } };
    }, {});

    return Object.keys(errors).length > 0
      ? { ...updates, [id]: { ...location, errors, inEditMode: true } }
      : { ...updates, [id]: { ...location, errors: {} } };
  }, {});
};

const getLocationsErrors = (wipLocations) => {
  const validatedUpdates = mapLocationsToValidation(wipLocations);
  const hasError = Object.values(validatedUpdates).some(
    ({ errors }) => Object.keys(errors).length > 0
  );

  return [validatedUpdates, hasError];
};

// isCountryError returns true if 'values' contains an ApiError regarding the country field.
const isCountryError = (values) => {
  // Pretty vague check, in the future we should take advantage of the structured validation errors returned by the API
  return values.some((response) => response?.error?.message?.includes('country'));
};

const CompanyLocationSettings = () => {
  const dispatch = useAppDispatch();
  const company = useSelector(selectCompany);
  const alertQueue = useAlertQueue();
  const formId = 'company-locations-form';
  const { locations } = company;
  const { isUpdatingCompany } = useSelector(({ settings }) => settings.company);
  const [wipLocations, setWipLocations] = useState(mapLocationsToState(locations));
  const [submittedLocations, setSubmittedLocations] = useState({});
  const [numWIPLocations, setNumWipLocations] = useState(getNumWipLocations(wipLocations));
  const [locationsAreClean, setLocationsAreClean] = useState(true);
  const [status, setStatus] = useState({});

  // Keep locations state in sync when Company.locations changes
  useEffect(() => {
    // remove deleted (previously saved) locations on update
    const cleanLocations = locations.filter((l) =>
      submittedLocations[l._id] ? !submittedLocations[l._id].deleted : true
    );
    const updatedCleanLocations = mapLocationsToState(cleanLocations);
    const numWIPCleanLocations = getNumWipLocations(cleanLocations);

    setWipLocations(updatedCleanLocations);
    setNumWipLocations(numWIPCleanLocations);
    setLocationsAreClean(true);
    setStatus({});
  }, [locations, submittedLocations]);

  // Add location template form if none exist
  useEffect(() => {
    if (numWIPLocations < 1) {
      setWipLocations((prevState) => ({
        ...prevState,
        [DEFAULT_LOCATION_ID]: LOCATION_TEMPLATE,
      }));
      setNumWipLocations(1);
    }
  }, [numWIPLocations]);

  // Update status so user knows what they're saving
  useEffect(() => {
    const updatedStatus = Object.entries(wipLocations).reduce(
      (changes, [id, location]) => ({
        creates:
          (id !== DEFAULT_LOCATION_ID && !location.deleted && id.includes('new')) ||
          (id === DEFAULT_LOCATION_ID && location.updated)
            ? changes.creates + 1
            : changes.creates,
        updates:
          location.updated && !location.deleted && !id.includes('new')
            ? changes.updates + 1
            : changes.updates,
        deletes: location.deleted && !id.includes('new') ? changes.deletes + 1 : changes.deletes,
      }),
      { creates: 0, deletes: 0, updates: 0 }
    );
    setStatus(updatedStatus);
  }, [wipLocations]);

  const onEditModeOn = ({ target: { id } }) => {
    const [locationId] = id.split('.');
    setWipLocations((prevState) => ({
      ...prevState,
      [locationId]: { ...prevState[locationId], inEditMode: true },
    }));
  };

  const onEditModeOff = ({ target: { id } }) => {
    const [locationId] = id.split('.');
    setWipLocations((prevState) => {
      const locationsWithEdit = {
        ...prevState,
        [locationId]: { ...prevState[locationId], inEditMode: false },
      };

      // validate edit
      const [locationWithValidation, locationsHasError] = getLocationsErrors(locationsWithEdit);
      if (locationsHasError) {
        setLocationsAreClean(true); // disable submit button
        alertQueue.addErrorAlert('Something went wrong', 'Please correct the errors shown in red');
      }

      return locationWithValidation;
    });
  };

  const onFieldUpdate = ({ target: { id, value: fieldUpdate } }) => {
    const [locationId, locationValue] = id.split('.');
    setWipLocations((prevState) => ({
      ...prevState,
      [locationId]: { ...prevState[locationId], [locationValue]: fieldUpdate, updated: true },
    }));
    setLocationsAreClean(false);
  };

  const onSelectUpdate = ({ id, selectedItem }) => {
    const [locationId, locationValue] = id.split('.');
    setWipLocations((prevState) => ({
      ...prevState,
      [locationId]: { ...prevState[locationId], [locationValue]: selectedItem, updated: true },
    }));
    setLocationsAreClean(false);
  };

  const onDeleteLocation = ({ target: { id } }) => {
    const [locationId] = id.split('.');

    // if newly created, remove from state, else tag to create delete promise onSubmit
    setWipLocations((prevState) => {
      if (prevState[locationId]._id) {
        return {
          ...prevState,
          [locationId]: { ...prevState[locationId], deleted: true, updated: false },
        };
      }

      const prevStateCopy = { ...prevState };
      delete prevStateCopy[locationId];
      return prevStateCopy;
    });

    setNumWipLocations((prevCount) => prevCount - 1);
    setLocationsAreClean(false);
  };

  const onAddLocation = () => {
    const newLocationId = `new-location-${numWIPLocations}`;
    setWipLocations((prevState) => ({
      ...prevState,
      [newLocationId]: LOCATION_TEMPLATE,
    }));
    setNumWipLocations((prevCount) => prevCount + 1);
    setLocationsAreClean(false);
  };

  const onFormSubmit = (evt) => {
    evt.preventDefault();

    // validate form
    const [formWithValidation, formHasError] = getLocationsErrors(wipLocations);
    if (formHasError) {
      setWipLocations(formWithValidation);
      setLocationsAreClean(true); // disable submit button
      alertQueue.addErrorAlert('Something went wrong', 'Please correct the errors shown in red');
      return;
    }

    // filter updates
    const updates = Object.values(wipLocations)
      .filter(({ updated }) => updated)
      .map((update) => {
        const { inEditMode, updated, deleted, ...cleanUpdate } = update;
        return cleanUpdate;
      });

    // prepare promises
    const addedBilling = updates.some((l) => l.type === 'billing');
    const updatePromise = dispatch(updateUserCompany({ locations: updates })).then((action) => {
      if (addedBilling) {
        dispatch(billingAddressAdded());
      }
      return action;
    });
    const deletePromises = Object.entries(wipLocations).reduce(
      (promises, [id, { deleted }]) =>
        deleted ? [...promises, dispatch(deleteLocationFromUserCompany(id))] : promises,
      []
    );

    Promise.all([...deletePromises, updatePromise]).then((values) => {
      const hasError = values.some((val) => val.error);
      if (hasError) {
        if (isCountryError(values)) {
          alertQueue.addErrorAlert('Something went wrong', 'An error occurred: invalid country');
        } else {
          alertQueue.addErrorAlert('Something went wrong', 'An error occurred');
        }
      } else {
        setSubmittedLocations(wipLocations);
        alertQueue.addSuccessAlert('Success', 'Company locations were updated');
      }
    });
  };

  return (
    <>
      <SettingsMain>
        <SettingsPageHeader title="Company Locations">
          <Button color="primary" outline size="sm" onClick={onAddLocation}>
            Add Location
          </Button>
        </SettingsPageHeader>
        <h4 style={{ lineHeight: 1.4 }}>
          Manage your company&apos;s locations, including billing, shipping, returns and HQ
        </h4>
        <p className="text-muted small">
          These locations will be visible to your partners, and we&apos;ll use them to meet specific
          requests, such as providing &quot;billing&quot; and &quot;returns&quot; locations to those
          that request them.
        </p>
        <form id={formId} onSubmit={onFormSubmit}>
          {Object.entries(wipLocations).map(([id, location]) => {
            if (wipLocations[id] && wipLocations[id].deleted) {
              return null;
            }

            return (
              <LocationCard key={id} hasError={Object.keys(location.errors).length > 0}>
                {wipLocations[id] && wipLocations[id].inEditMode ? (
                  Object.entries(location)
                    .sort(([labelA], [labelB]) => FIELDS.indexOf(labelA) - FIELDS.indexOf(labelB))
                    .map(([label, value]) => {
                      const fieldId = `${id}.${label}`;
                      const locationIsNew = id === DEFAULT_LOCATION_ID || fieldId.includes('new');

                      if (/_id|label|inEditMode|updated|deleted|errors/.test(label)) {
                        return null;
                      }

                      if (label === 'type') {
                        return (
                          <LocationHeader key={`${id}.header`} className="mb-4">
                            <LocationTypeSelector
                              id={fieldId}
                              name={fieldId}
                              key={fieldId}
                              items={Object.keys(ADDRESS_TYPES)}
                              initialSelectedItem={value}
                              onChange={onSelectUpdate}
                              error={location.errors.type}
                            />
                            <div>
                              <IconButton
                                id={`${id}.editModeOff`}
                                className="mr-2"
                                type="button"
                                icon={doneIcon}
                                onClick={onEditModeOff}
                              />
                              {id !== DEFAULT_LOCATION_ID && (
                                <IconButton
                                  id={`${id}.deleteButton`}
                                  type="button"
                                  icon={deleteIcon}
                                  onClick={onDeleteLocation}
                                />
                              )}
                            </div>
                          </LocationHeader>
                        );
                      }

                      return (
                        <div
                          key={`${fieldId}.container`}
                          className="d-flex align-items-baseline justify-content-between mb-2"
                        >
                          <label htmlFor={id}>{toProperCase(separateCamelCase(label))}</label>
                          <FormField
                            id={fieldId}
                            name={fieldId}
                            key={fieldId}
                            error={location.errors[label]}
                            errorAlignRight
                            placeholder={locationIsNew ? LOCATION_PLACEHOLDERS[label] : null}
                            value={locationIsNew && !value ? null : value}
                            size="sm"
                            onChange={onFieldUpdate}
                            style={getFormFieldStyle(label)}
                          />
                        </div>
                      );
                    })
                ) : (
                  <>
                    <LocationHeader className="mb-2">
                      {ADDRESS_TYPES[location.type] ? (
                        <p className="mb-0 font-weight-bold">{ADDRESS_TYPES[location.type]}</p>
                      ) : (
                        <p className="mb-0 font-weight-bold text-danger">NONE</p>
                      )}

                      <div>
                        <IconButton
                          id={`${id}.editModeOn`}
                          className="mr-2"
                          type="button"
                          icon={editIcon}
                          onClick={onEditModeOn}
                        />
                        {id !== DEFAULT_LOCATION_ID && (
                          <IconButton
                            id={`${id}.deleteButton`}
                            type="button"
                            icon={deleteIcon}
                            onClick={onDeleteLocation}
                          />
                        )}
                      </div>
                    </LocationHeader>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <LocationInfo {...location} />
                  </>
                )}
              </LocationCard>
            );
          })}
        </form>
      </SettingsMain>
      <SettingsFooter>
        <Button
          type="submit"
          form={formId}
          disabled={locationsAreClean || isUpdatingCompany}
          size="sm"
          color="primary"
          className="mr-2"
        >
          {isUpdatingCompany ? <Spinner color="white" className="mx-2" small /> : 'Save'}
        </Button>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {status && <Status {...status} />}
      </SettingsFooter>
    </>
  );
};

export default CompanyLocationSettings;
