const CreateActionCheckbox = ({ id, label, value, setValue }) => (
  <div className="form-group">
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        id={`create-card-${id}`}
        value={value}
        onChange={(e) => setValue(e.target.checked)}
      />
      <label className="custom-control-label" htmlFor={`create-card-${id}`}>
        {label}
      </label>
    </div>
  </div>
);

export default CreateActionCheckbox;
