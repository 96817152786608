import _isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import Button from 'components/Common/Button';
import UpdatedIndicator from 'components/Common/UpdatedIndicator';
import Icon from 'storybook/stories/molecules/Icon';

import CardProductValidationAlert from './CardProductValidationAlert';

const EditableTagBadge = styled.span`
  color: #283e59;
  background-color: #edf2f9;
  padding-left: 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
`;

const TagBadge = styled.span`
  color: #283e59;
  background-color: #edf2f9;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 400;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
`;

const Tag = ({ tag, onRemove, canEdit }) => {
  return canEdit ? (
    <EditableTagBadge>
      <span title={tag}>{tag}</span>
      <Button
        type="button"
        aria-label={`Remove ${tag}`}
        size="sm"
        color="light"
        className="ml-1"
        onClick={onRemove}
      >
        <Icon name="close" />
      </Button>
    </EditableTagBadge>
  ) : (
    <TagBadge>
      <span title={tag}>{tag}</span>
    </TagBadge>
  );
};

const ProductTags = ({ product, edits, onEditTags, canEdit, validationErrors }) => {
  const { register, handleSubmit, watch, setValue } = useForm();
  const tags = useMemo(
    () => (Object.prototype.hasOwnProperty.call(edits, 'tags') ? edits.tags : product.tags || []),
    [edits, product.tags]
  );
  const tagValue = watch('tag');

  useEffect(() => {
    if (tagValue && tagValue.length && /,\s*$/.test(tagValue) && tagValue.replace(/,/g, '')) {
      onEditTags([...tags, tagValue.replace(/,/g, '')]);
      setValue('tag', '');
    }
  }, [tagValue, tags, onEditTags, setValue]);

  const onAddTag = ({ tag }) => {
    if (tag) {
      onEditTags([...tags, tag]);
      setValue('tag', '');
    }
  };

  const onRemoveTag = (index) => () => {
    const newTags = tags.filter((_, idx) => index !== idx);
    onEditTags(newTags);
  };

  const cardStyle = validationErrors?.length > 0 ? { border: '1px solid #ffe4a0' } : {};

  return (
    <div className="card p-4 overflow-auto" id="product-tags-card" style={cardStyle}>
      <h4 className="mb-3">
        Tags{' '}
        {Object.prototype.hasOwnProperty.call(edits, 'tags') ? (
          <UpdatedIndicator className="ml-1" />
        ) : null}
      </h4>
      {canEdit && (
        <form onSubmit={handleSubmit(onAddTag)}>
          <div className="input-group mb-3">
            <input
              className="form-control form-control-sm"
              aria-label="Add product tag"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('tag')}
              placeholder="Add tag (comma delimited)"
            />
            <div className="input-group-append">
              <Button size="sm" outline color="secondary" type="submit">
                Add
              </Button>
            </div>
          </div>
        </form>
      )}
      <div className="d-flex" style={{ flexWrap: 'wrap' }}>
        {_isEmpty(tags) ? (
          <p className="mb-2 mt-2 text-muted">This product does not have any tags</p>
        ) : (
          tags.map((tag, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tag tag={tag} onRemove={onRemoveTag(idx)} key={idx + tag} canEdit={canEdit} />
          ))
        )}
      </div>
      {validationErrors?.length > 0 && <CardProductValidationAlert errors={validationErrors} />}
    </div>
  );
};

export default ProductTags;
