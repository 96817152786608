/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'storybook/stories/cells/Modal';

import SubmitFormButton from 'containers/PartnerPage/Actions/Common/SubmitFormButton';
import { BTN_DELAY } from 'containers/PartnerPage/Actions/Shared';
import { updateConsumableActionTemplate } from 'store/slices/partners';

const EditActionTemplateModal = ({ show, setShowEditModal, editDetails, onRefresh }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [required, setRequired] = useState(false);
  const [applyToNewPartners, setApplyToNewPartners] = useState(false);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const { editingActionTemplate } = useSelector(({ partners }) => partners);

  useEffect(() => {
    if (editDetails) {
      setTitle(editDetails.cardTitle);
      setText(editDetails.cardText);
      setRequired(editDetails.required);
      setApplyToNewPartners(editDetails.applyToNewPartners);
    }
  }, [editDetails]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (title === '') {
      setError('Error: Title is required');
      return;
    }

    const body = {
      cardTitle: title,
      cardText: text,
      required,
      applyToNewPartners,
    };
    dispatch(updateConsumableActionTemplate({ actionTemplateId: editDetails.id, body })).then(
      (response) => {
        if (response.error && response.error.message) {
          setError(response.error.message);
        } else {
          setSuccess(true);

          setTimeout(() => {
            setError('');
            setSuccess(false);
            onRefresh();
          }, BTN_DELAY);
        }
      }
    );
  };

  return (
    <Modal.Root show={show} onHide={() => setShowEditModal(false)} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Action Template</Modal.Title>
      </Modal.Header>

      <form onSubmit={onSubmit}>
        <Modal.Body>
          {error && <div className="alert alert-danger">{error}</div>}

          <div className="form-group">
            <label htmlFor="modify-card-title">Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Ex. Partnership Agreement"
              id="edit-action-template-title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="modify-card-text">Text</label>
            <textarea
              className="form-control"
              placeholder="Describe the required steps."
              id="edit-action-template-text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <div className="form-group">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="edit-action-template-required"
                checked={required}
                onChange={(e) => setRequired(e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="edit-action-template-required">
                Required
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="edit-action-template-apply-to-new-partners"
                checked={applyToNewPartners}
                onChange={(e) => setApplyToNewPartners(e.target.checked)}
              />
              <label
                className="custom-control-label"
                htmlFor="edit-action-template-apply-to-new-partners"
              >
                Apply to New Partners
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <SubmitFormButton
            label="Save"
            successfulLabel="Saved"
            success={success}
            loading={editingActionTemplate}
          />
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default EditActionTemplateModal;
