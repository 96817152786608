import { useDispatch, useSelector } from 'react-redux';

import ExternalSFTPSettingsForm from 'containers/SettingsPage/DropshippingTab/IntegrationsSettings/ExternalSFTPSettingsForm';
import { updateSFTPCreds } from 'store/slices/company';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import Link from 'storybook/stories/molecules/Link';
import { useSafeState } from 'utils/hooks';

const ConnectExternalSFTPCard = ({ done }) => {
  const [error, setError] = useSafeState('');
  const company = useSelector(({ me }) => me.company);
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useSafeState(false);

  const handleUpdateSFTPCreds = (body) => {
    const bodyCopy = { ...body };
    setError('');
    setIsUpdating(true);
    if (bodyCopy.port !== '') {
      bodyCopy.port = parseInt(bodyCopy.port, 10);
    }
    dispatch(updateSFTPCreds(bodyCopy)).then((action) => {
      if (action.error) {
        setError(action.error.message);
      }

      setIsUpdating(false);
    });
  };

  return (
    <GetStartedCard
      isComplete={done}
      title="Connect Modern Dropship to your SFTP Server"
      completedUi={
        <Body>
          Manage your integration from your{' '}
          <Link to="/settings/dropshipping/integrations">Integration settings</Link>.
        </Body>
      }
    >
      <Body as="p">
        Please input your SFTP credentials so Modern Dropship can connect to your server.
      </Body>

      <Body>
        Before completing the form below, please allow Modern Dropship&apos;s IP address to connect
        to your SFTP server. Our IP address is <strong>35.223.28.122</strong>.
      </Body>

      <ExternalSFTPSettingsForm
        company={company}
        onSubmit={handleUpdateSFTPCreds}
        showSubmitButton={!done}
        disableSubmitButton={isUpdating}
        submitButtonText="Submit"
      />

      {error && <Body color="error500">{error}</Body>}
    </GetStartedCard>
  );
};

export default ConnectExternalSFTPCard;
