import Flex from '@react-css/flex';
import pluralize from 'pluralize';
import Body from 'storybook/stories/molecules/Body';
import Icon from 'storybook/stories/molecules/Icon';
import type { ThemeColor } from 'storybook/stories/theme';

export enum PreviewStatus {
  Created = 'created',
  Updated = 'updated',
  Removed = 'removed',
}

interface StatusDetails {
  iconName: string;
  color: ThemeColor;
  message: string;
}

const getStatusRowIconProps = (status: PreviewStatus): StatusDetails => {
  let details: StatusDetails = { iconName: '', color: 'iconDefault', message: '' };

  switch (status) {
    case PreviewStatus.Created:
      details = {
        iconName: 'check_circle',
        color: 'success700',
        message: 'added to this price list',
      };
      break;
    case PreviewStatus.Updated:
      details = { iconName: 'error', color: 'warning700', message: 'updated on this price list' };
      break;
    case PreviewStatus.Removed:
      details = { iconName: 'cancel', color: 'error500', message: 'removed from this price list' };
      break;
    default:
      break;
  }

  return details;
};

interface PreviewStatusRowProps {
  status: PreviewStatus;
  count: number;
}

const PreviewStatusRow = ({ status, count }: PreviewStatusRowProps) => {
  const { iconName, color, message } = getStatusRowIconProps(status);

  return (
    <Flex gap="8px" alignItemsCenter>
      <Icon name={iconName} color={color} size="28px" />
      <Body color="bodyTextSecondary">
        {pluralize('SKU entry', count, true)} {message}
      </Body>
    </Flex>
  );
};

export default PreviewStatusRow;
