import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';

import * as Sentry from '@sentry/react';
import { BodyHeaderText, BoldText } from 'containers/OrderPage/Shared.styles';
import useAlertQueue from 'hooks/useAlertQueue';
import { useSelector } from 'react-redux';
import { selectIsProductPlatformShopify } from 'store/selectors/me/company';
import { ButtonKinds } from 'storybook/stories/molecules/Button';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Input from 'storybook/stories/molecules/Input';
import TextAreaInput from 'storybook/stories/molecules/Input/TextAreaInput';
import Label from 'storybook/stories/molecules/Label';
import type { Order20210101, OrderItem } from 'types/models/order';
import { useBoolean } from 'usehooks-ts';
import { requestReturn, requestSellerOrderReturn } from 'utils/api/buyer/orders';

interface ReturnItemFormData {
  reason: string;
  buyerCode: string;
  quantity: number;
}

interface ReturnOrderItemModalProps {
  order: Order20210101;
  item: OrderItem;
  shouldShowModal: boolean;
  maxReturnableItems: number;
  onDismiss: () => void;
  refetchReturns: () => void;
}

const ReturnOrderItemModal = ({
  order,
  item,
  shouldShowModal,
  maxReturnableItems,
  onDismiss,
  refetchReturns,
}: ReturnOrderItemModalProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();
  const isUsingShopify = useSelector(selectIsProductPlatformShopify);

  const shouldShowCodeInput = useBoolean(!isUsingShopify);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm<ReturnItemFormData>({
    mode: 'onChange',
    defaultValues: {
      reason: '',
      quantity: item.quantity,
    },
  });

  const requestingReturn = useMutation({
    mutationFn: (data: ReturnItemFormData) =>
      requestReturn(order.buyerOrderId, order._id, item._id, data),
    onSuccess: () => {
      refetchReturns();
      reset();
      onDismiss();
      addSuccessAlert('Success', 'Your return request has been sent to your partner.');
    },
    onError: (error: { message: string }) => {
      addErrorAlert('Something went wrong', error.message);
      console.error('Unable to request return', error);
    },
  });

  // Used in place of `requestingReturn` when this is not a buyer order.
  // This happens when buyerOrderId does not exist.
  const requestingSellerOrderReturn = useMutation({
    mutationFn: (data: ReturnItemFormData) => requestSellerOrderReturn(order._id, item._id, data),
    onSuccess: () => {
      refetchReturns();
      reset();
      onDismiss();
      addSuccessAlert('Success', 'Your return request has been sent to your partner.');
    },
    onError: (error: { message: string }) => {
      addErrorAlert('Something went wrong', error.message);
      Sentry.captureException(error);
    },
  });

  const handleFormSubmit = (data: ReturnItemFormData) => {
    if (order.buyerOrderId) {
      requestingReturn.mutate(data);
    } else {
      requestingSellerOrderReturn.mutate(data);
    }
  };

  return (
    <Modal.Root centered show={shouldShowModal} onHide={onDismiss} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Order Update</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Modal.Body>
          <BodyHeaderText>Please provide a reason for returning this item:</BodyHeaderText>

          <Flex column key={item._id} gap="16px">
            <Flex>
              <BoldText>Returning Item:</BoldText>
              {item.title}
            </Flex>

            <Flex column gap="22px">
              <Flex gap="48px">
                {shouldShowCodeInput.value && (
                  <Flex column gap="4px">
                    <Label isRequired htmlFor="buyer-code">
                      Code
                    </Label>
                    <Input
                      {...register('buyerCode', { required: true })}
                      placeholder="RMA#1234"
                      required
                    />
                  </Flex>
                )}

                <Flex column gap="4px">
                  <Label isRequired htmlFor="quantity">
                    Quantity
                  </Label>
                  <Input
                    {...register('quantity', { required: true, valueAsNumber: true })}
                    type="number"
                    min={0}
                    max={maxReturnableItems}
                    placeholder="0"
                    required
                  />
                </Flex>
              </Flex>

              <Flex column gap="8px">
                <Label isRequired htmlFor="reason">
                  Return Reason
                </Label>
                <TextAreaInput
                  {...register('reason', { required: true })}
                  placeholder="Wrong size, color, etc."
                />
              </Flex>
            </Flex>
          </Flex>
        </Modal.Body>

        <Modal.Footer>
          <TertiaryButton onClick={onDismiss}>Cancel</TertiaryButton>

          <PrimaryButton
            disabled={!isValid || isSubmitting}
            kind={ButtonKinds.Action}
            type="submit"
          >
            Update Order
          </PrimaryButton>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default ReturnOrderItemModal;
