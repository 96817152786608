import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'components/Common/Spinner';
import useAlertQueue from 'hooks/useAlertQueue';
import { capitalize } from 'lodash';
import { useAppSelector } from 'store';
import { selectIsSeller } from 'store/selectors/me/company';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import Heading from 'storybook/stories/molecules/Heading';
import Link from 'storybook/stories/molecules/Link';
import StreetAddress from 'storybook/stories/molecules/StreetAddress';
import type { Partner } from 'types/models/partner';
import { getCompanyLocations } from 'utils/api/companies';

type PartnerLocationsParams = {
  partner: Partner;
};

const PartnerLocations = ({ partner }: PartnerLocationsParams) => {
  const isSeller = useAppSelector(selectIsSeller);
  const { addErrorAlert } = useAlertQueue();
  const counterpartyId = isSeller ? partner.buyerCompanyObjectId : partner.sellerCompanyObjectId;

  const fetchingLocations = useQuery({
    queryKey: ['getCompanyLocations', counterpartyId],
    queryFn: () => getCompanyLocations(counterpartyId),
    onError: () => {
      addErrorAlert('Failed to fetch locations', 'Please try again later');
    },
  });

  return (
    <Card>
      <Card.Head>
        <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
          Locations
        </Heading>
      </Card.Head>

      <Card.Body>
        <Flex gap="8px" column>
          <Flex gap="48px" wrap>
            {fetchingLocations.isLoading && <Spinner />}
            {fetchingLocations.data?.data.map((location) => (
              <Flex column gap="4px" key={location._id}>
                <Body variant="Body/Regular Bold">{capitalize(location.type)}</Body>
                <Body variant="Body/Body Small">
                  <StreetAddress address={location} />
                </Body>
              </Flex>
            ))}
          </Flex>
          {!fetchingLocations.isLoading && fetchingLocations.data?.data.length === 0 && (
            <Body variant="Body/Body Small">Your partner has not added any locations yet.</Body>
          )}
          <Body variant="Body/Body Small" color="bodyTextSecondary">
            Locations added in <Link to="/settings/company/locations">settings</Link> are shared
            with your partners.
          </Body>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default PartnerLocations;
