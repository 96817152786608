const config = {
  apiURL: process.env.REACT_APP_API_URL,
  chatApiURL: process.env.REACT_APP_CHAT_API_URL,
  shopify: {
    clientId: process.env.REACT_APP_SHOPIFY_CLIENT_ID,
    redirect: process.env.REACT_APP_SHOPIFY_REDIRECT,
    scope:
      'write_orders,write_draft_orders,write_fulfillments,write_inventory,write_products,write_assigned_fulfillment_orders,write_third_party_fulfillment_orders,write_returns',
  },
  hellosign: {
    clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
    skipDomainVerification: process.env.REACT_APP_HELLOSIGN_SKIP_DOMAIN_VERIFICATION === 'true',
  },
  isDevelopment: process.env.REACT_APP_ENV === 'development',
  isStaging: process.env.REACT_APP_ENV === 'staging',
  isProduction: process.env.REACT_APP_ENV === 'production',
  isTest: process.env.REACT_APP_ENV === 'test',
  pathBasename: process.env.REACT_APP_BASENAME,
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
};

export default config;
