import { useAppDispatch, useAppSelector } from 'store';
import { resetSelectedFiles } from 'store/slices/sftpUser';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import { useBoolean } from 'usehooks-ts';
import CreateCSVMappingsModal from './CreateCSVMappingsModal';
import ProductFilesDetailsModal from './ProductFilesDetailsModal';

const CSVMappingsButton = () => {
  const dispatch = useAppDispatch();
  const { productFileSelected } = useAppSelector(({ sftpUser }) => sftpUser);
  const csvMappingsModalVisibility = useBoolean(false);

  const resetModal = () => {
    dispatch(resetSelectedFiles());
  };

  const hideModal = () => {
    resetModal();
    csvMappingsModalVisibility.setFalse();
  };

  return (
    <>
      <PrimaryButton onClick={csvMappingsModalVisibility.setTrue} $iconName="edit_note">
        Create CSV Mappings
      </PrimaryButton>

      {!productFileSelected ? (
        <ProductFilesDetailsModal
          showCSVMappingsModal={csvMappingsModalVisibility.value}
          hideModal={hideModal}
        />
      ) : (
        <CreateCSVMappingsModal
          showCSVMappingsModal={csvMappingsModalVisibility.value}
          hideModal={hideModal}
          resetModal={resetModal}
        />
      )}
    </>
  );
};

export default CSVMappingsButton;
