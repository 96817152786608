import Flex from '@react-css/flex';
import CSVMappingsButton from 'containers/Products/CSVMappings/CSVMappingsButton';
import UploadProductCSVButton from 'containers/Products/SellerProductsPage/UploadProductCSVButton';
import useFloatingChatPrompt from 'hooks/useFloatingChatPrompt';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectIsProductPlatformOther,
  selectIsProductPlatformSFTP,
  selectProductPlatform,
} from 'store/selectors/me/company';
import NoResultsCard from 'storybook/stories/cells/Card/NoResultsCard';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { Platform } from 'types/models/company';

const SELLER_ARTICLES: Record<string, string> = {
  [Platform.Shopify]: 'eqxyqt0mtj',
  [Platform.WooCommerce]: '3ad5l1ysc6',
  [Platform.BigCommerce]: 'mvbcncoh60',
  [Platform.CsvSftp]: 'delewtxyn5',
  default: '7og0t9go64',
};

const getPlatformText = (isPlatformOther: boolean, isPlatformSFTP: boolean) => {
  switch (true) {
    case isPlatformOther:
      return {
        title: 'Upload your products',
        description:
          'Click the button below to upload a CSV containing your product data. Click the Guide button for more information.',
      };
    case isPlatformSFTP:
      return {
        title: 'Map your products',
        description:
          'After uploading your product CSV to the SFTP server, click the button below to map your product data to Modern Dropship. Click the Guide button for more information.',
      };
    default:
      return {
        title: 'Your products are syncing',
        description: 'You should see your products appear here very soon.',
      };
  }
};

export interface EmptySellerProductsPageProps {
  onFileUploaded?: () => void;
}

const EmptySellerProductsPage = ({ onFileUploaded }: EmptySellerProductsPageProps) => {
  const { tryPrompting } = useFloatingChatPrompt();

  useEffect(() => {
    tryPrompting();
  }, [tryPrompting]);

  const platform = useSelector(selectProductPlatform);
  const isPlatformOther = useSelector(selectIsProductPlatformOther);
  const isPlatformSFTP = useSelector(selectIsProductPlatformSFTP);

  const shouldDisplayUploadButton = isPlatformOther && onFileUploaded;
  const shouldDisplayMappingsButton = isPlatformSFTP;

  const { title, description } = getPlatformText(isPlatformOther, isPlatformSFTP);
  const article = (platform && SELLER_ARTICLES[platform]) || SELLER_ARTICLES.default;

  return (
    <NoResultsCard title={title} description={description} data-testid="empty-page">
      <Flex gap="16px">
        <TertiaryButton as={SupportLink} article={article} $iconName="help">
          Guide
        </TertiaryButton>

        {shouldDisplayUploadButton && (
          <>
            <SecondaryButton
              as="a"
              target="_blank"
              $iconName="dataset"
              href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSxVUU0YZkgZ9E6ZijWUTvRMLzxOn47CVcleJPi_onCrqB9xu5cQj_REnyYIU5TK1SPdrhLxb_k3mYT/pub?output=csv"
            >
              CSV Template
            </SecondaryButton>

            <UploadProductCSVButton onFileUploaded={onFileUploaded} />
          </>
        )}

        {shouldDisplayMappingsButton && <CSVMappingsButton />}
      </Flex>
    </NoResultsCard>
  );
};

export default EmptySellerProductsPage;
