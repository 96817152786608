import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getSegmentInfo as getSegmentInfoFromApi } from 'utils/api/edi';

export const getSegmentInfo = createAsyncThunk('ediInfo/getSegmentInfo', (params) => {
  return getSegmentInfoFromApi(params);
});

// initialState describes the core structure of state for this slice
const initialState = {
  infoLoading: false,
  infoLoaded: false,
  info: {
    name: undefined,
    elements: undefined,
    variables: undefined,
  },
};

const infoSlice = createSlice({
  name: 'ediInfo',
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSegmentInfo.pending, (draft) => {
        draft.infoLoading = true;
      })
      .addCase(getSegmentInfo.rejected, (draft) => {
        draft.infoLoading = false;
      })
      .addCase(getSegmentInfo.fulfilled, (draft, action) => {
        const { data } = action.payload;
        draft.info = data;
        draft.infoLoaded = true;
        draft.infoLoading = false;
      });
  },
});

export default infoSlice.reducer;
