import type { EnhancedStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PageLoader from 'components/Common/PageLoader';
import AppError from 'containers/App/AppError';
import FloatingChatContextProvider from 'containers/App/Contexts/FloatingChatContext';
import GoogleTranslateWarningModal from 'containers/App/GoogleTranslateWarningModal';
import IFramePage from 'containers/IFrame/IFramePage';
import useCSRFToken from 'hooks/useCSRFToken';
import useInitSentry from 'hooks/useInitSentry';
import useLoadApp from 'hooks/useLoadApp';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import type { RootState } from 'store';
import store from 'store';
import {
  BootstrapStyleOverride,
  GlobalStyles,
  TypographyStyles,
} from 'storybook/stories/globalStyles';
import AlertQueue from 'storybook/stories/organisms/AlertQueue';
import theme from 'storybook/stories/theme';
import { ThemeProvider } from 'styled-components';
import config from 'utils/config';
import { inIFrame } from 'utils/iframe';
import AppRouter from './AppRouter';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

interface AppWrapperProps {
  reduxStore?: EnhancedStore<RootState>;
  queryClient?: QueryClient;
  children: React.ReactNode;
}

// AppWrapper contains all of the global context providers and styling
// that makes the app function properly. It is exported to be importable
// in the test environment. It provides a customizable reduxStore prop for
// testing overrides.
export const AppWrapper = ({
  reduxStore = store,
  queryClient = client,
  children,
}: AppWrapperProps) => (
  <StrictMode>
    <Provider store={reduxStore}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <TypographyStyles />
          <BootstrapStyleOverride />
          {children}
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  </StrictMode>
);

// AppComponent is the actual app wrapped in a React Router instance.
// It's broken out for isolated testing.
export const AppComponent = () => {
  // useLoadApp hook will fetch current user and company
  const { isAppLoading } = useLoadApp();
  const { isCSRFReady } = useCSRFToken({ isAppLoading });

  useInitSentry();

  if (inIFrame()) {
    return <IFramePage />;
  }

  // continue to show loader until app has loaded everything inits
  if (isAppLoading || !isCSRFReady) {
    return <PageLoader />;
  }

  return (
    <Sentry.ErrorBoundary fallback={AppError} showDialog>
      <BrowserRouter basename={config.pathBasename ? config.pathBasename : '/'}>
        <FloatingChatContextProvider>
          <AppRouter />
        </FloatingChatContextProvider>

        <AlertQueue />
        <GoogleTranslateWarningModal />
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
};

// A simple default export for this component to really tie the room together
const App = () => (
  <AppWrapper>
    <AppComponent />
  </AppWrapper>
);

export default App;
