import { useState } from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/Common/Button';
import Spinner from 'components/Common/Spinner';
import useAlertQueue from 'hooks/useAlertQueue';
import Divider from 'storybook/stories/molecules/Divider';
import Heading from 'storybook/stories/molecules/Heading';
import Toggle from 'storybook/stories/molecules/Toggle';
import { updateCompany } from 'utils/api/companies';

const DescriptionText = styled.p`
  font-size: 14px;
  margin-top: 4px;

  ${({ theme }) => css`
    color: ${theme.color.gray600};
  `};
`;

const SyncingSettings = ({ company }) => {
  const { addSuccessAlert, addErrorAlert } = useAlertQueue();
  const [pending, setPending] = useState(false);
  const [syncUnpublishedProducts, setSyncUnpublishedProducts] = useState(
    company?.flags?.syncUnpublishedProducts ?? false
  );

  const onSubmit = (e) => {
    e.preventDefault();

    setPending(true);
    updateCompany(company._id, { flags: { syncUnpublishedProducts } }).then((response) => {
      setPending(false);
      if (response.error) {
        addErrorAlert(response.error.message);
        return;
      }
      addSuccessAlert('Sync settings updated');
    });
  };

  if (company.commerceType === 'buyer') {
    // Syncing settings are only available for sellers
    return null;
  }

  return (
    <div>
      <br />
      <Divider />
      <br />
      <Heading variant="Headings/H3">Syncing</Heading>
      <br />
      <form onSubmit={onSubmit}>
        <Toggle
          checked={syncUnpublishedProducts}
          onChange={(e) => setSyncUnpublishedProducts(e.target.checked)}
        >
          Sync Unpublished Products
          <DescriptionText>
            By including unpublished products, you can make products available to your partners that
            are not published on your ecommerce platform.
          </DescriptionText>
        </Toggle>
        <br />
        <br />
        <div className="d-flex">
          <Button
            size="sm"
            color="primary"
            type="submit"
            disabled={pending || company.flags.syncUnpublishedProducts === syncUnpublishedProducts}
            outline
          >
            {pending ? <Spinner color="primary" small className="mx-3" /> : 'Save Sync Settings'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SyncingSettings;
