import type { CompanyResponse } from 'types/models/company';
import type { Order20210101 } from 'types/models/order';
import { prettyDate, prettyTime } from 'utils/date';

import { getFriendlyOrderDescription, getOrderDisplayCode, getPlatformOrderUrl } from 'utils/order';

export interface OrderDetailProps {
  order: Order20210101;
  company: CompanyResponse;
}

interface DetailProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const Detail = ({ title, children, className = '' }: DetailProps) => (
  <div className={`d-flex align-items-center flex-wrap ${className}`}>
    <p className="mb-0 mr-3">{title}</p>
    <p className="text-muted mb-0" style={{ wordBreak: 'break-word' }}>
      {children}
    </p>
  </div>
);

const OrderDetails = ({ order, company }: OrderDetailProps) => {
  const platformOrderUrl = getPlatformOrderUrl(order, company);

  return (
    <div className="card p-4 overflow-auto">
      <h4 className="mb-4">Details</h4>

      <Detail title="Modern Dropship Order ID:">{order._id}</Detail>
      <hr />
      <Detail title="Buyer PO #:" className="mb-2">
        {order.buyerOrderCode || '-'}
      </Detail>
      <Detail title="Seller Order #:" className="mb-2">
        {order.sellerOrderCode || '-'}
      </Detail>
      <Detail title="Customer Order #:" className="mb-2">
        {order.customerOrderCode || '-'}
      </Detail>
      <Detail title={getFriendlyOrderDescription(company)} className="mb-2">
        {platformOrderUrl && (
          <a
            href={platformOrderUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-2"
            key={platformOrderUrl}
          >
            {getOrderDisplayCode(order, company)}
          </a>
        )}
      </Detail>
      <hr />
      <Detail title="Created:" className="mb-2">
        {`${prettyDate(order.created)} ${prettyTime(order.created)}`}
      </Detail>
      <Detail title="Updated:">
        {`${prettyDate(order.updated)} ${prettyTime(order.updated)}`}
      </Detail>
    </div>
  );
};

export default OrderDetails;
