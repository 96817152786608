import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

/**
 * A styled link component. This is a wrapper around the react-router-dom `Link`
 * component. Use this component to create links to other pages in the app.
 */
const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.color.bodyTextLinks};

  &:hover {
    color: ${({ theme }) => theme.color.bodyTextLinksHover};
    cursor: pointer;
  }
`;

export default Link;
