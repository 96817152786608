import { Link } from 'react-router-dom';

const PartnersTable = ({ partners = {}, partnerIds = [], isSeller }) => {
  if (partnerIds.length === 0) {
    return null;
  }

  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th className="p-2">{isSeller ? 'Buyer' : 'Seller'}</th>
          <th className="p-2">Syncing?</th>
          <th className="p-2">Price List</th>
        </tr>
      </thead>
      <tbody className="list">
        {partners &&
          partnerIds.map((partnerId) => {
            const p = partners[partnerId];
            return (
              <tr key={p._id}>
                {/* have to default to p.reference because response from inviting partner does not include buyerName/sellerName */}
                <td className="small p-2">
                  {(isSeller ? p.buyerName : p.sellerName) || p.reference}
                </td>
                <td className="small p-2">{p.active ? 'Yes' : 'No'}</td>
                <td className="small p-2">
                  {isSeller
                    ? p.priceListName || (
                        <Link className="font-weight-bold" to={`/partners/${p._id}`}>
                          Assign Prices
                        </Link>
                      )
                    : p.priceListName || '-'}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default PartnersTable;
