import { isAccountTypeBuyer } from 'utils/cookies';

import { find } from 'lodash';
import type { Partner } from 'types/models/partner';
import type { PartnerAction } from 'types/models/partner-action';
import { ACKNOWLEDGEMENT, FILE_UPLOAD, HELLO_SIGN } from './Kinds';

export const ACTIONS_LIMIT = 5;
export const ACTION_TEMPLATES_LIMIT = 25;
export const BTN_DELAY = 2000; // 2 seconds

export const getFriendlyNameForActionKind = (type: string) => {
  if (type === FILE_UPLOAD) {
    return 'File';
  }
  if (type === HELLO_SIGN) {
    return 'Agreement';
  }
  if (type === ACKNOWLEDGEMENT) {
    return 'Acknowledgement of Document';
  }
  return type;
};

type GroupedActions = {
  [key: string]: ActionGroup;
};

type ActionGroup = {
  partnerName: string;
  actions: PartnerAction[];
};

// getListOfActionsGroupByPartner forms a list of actions per partner. Ex.
// Demo Partner Name:
//  - Action 1
//  - Action 2
// Demo Partner 2
//  - Action 3
export const getListOfActionsGroupByPartner = (
  partners: Partner[],
  actions: PartnerAction[]
): GroupedActions => {
  const partnerActions: GroupedActions = {};

  for (let i = 0; i < actions.length; i += 1) {
    const action = actions[i];
    const partner = find(partners, { _id: action.partnerId });

    if (action.partnerId && partner) {
      // Setup a new instance of partner
      if (!(action.partnerId in partnerActions)) {
        let partnerName = '';
        if (isAccountTypeBuyer()) {
          partnerName = partner.sellerName;
        } else {
          partnerName = partner.buyerName;
        }
        partnerActions[action.partnerId] = {
          partnerName: partnerName || '(missing partner)',
          actions: [],
        };
      }

      // Add the additional action
      partnerActions[action.partnerId].actions.push(action);
    }
  }
  return partnerActions;
};
