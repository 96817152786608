import classNames from 'classnames';

const Spinner = (props) => {
  const { color, small, grow, className, ...rest } = props;

  const cn = classNames(
    `spinner-${grow ? 'grow' : 'border'}`,
    {
      [`spinner-${grow ? 'grow' : 'border'}-sm`]: small,
      [`text-${color}`]: color,
    },
    className
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={cn} role="status" {...rest}>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Spinner;
