import type { BaseQueryParams, Response } from 'types/api';
import type { DeepPartial, MerchandisingSettings, Metafields } from 'types/general';
import type {
  Partner,
  PartnerBillingSettings,
  PartnerPerOrderFeeSettings,
} from 'types/models/partner';

import type { ShippingSettings } from 'types/models/shipping';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'utils/request';

export interface GetPartnersParams extends BaseQueryParams {
  completed?: string;
  showOnboardingStatus?: string;
  buyerName?: string;
  sellerName?: string;
  reference?: string;
  pending?: boolean;
  signedUp?: string;
  withDeleted?: boolean;
}

export type PartnersResponse = Response<Partner[]>;

export const getPartners = (params?: GetPartnersParams): Promise<PartnersResponse> =>
  getRequest('/partners', params);

interface GetPartnerParams {}

type PartnerResponse = Partner;

export const getPartner = (
  partnerId: string,
  params?: GetPartnerParams
): Promise<PartnerResponse> => getRequest(`/partners/${partnerId}`, params);

export interface InvitePartnerParams {
  email: string;
  priceId?: string;
  sellerAssignedCode?: string;
  buyerAssignedCode?: string;
}

export type InvitePartnerResponse = Partner;

export const invitePartner = (params: InvitePartnerParams): Promise<InvitePartnerResponse> =>
  postRequest('/partners', params);

type DeletePartnerResponse = { deleted: string };

export const deletePartner = (partnerId: string): Promise<DeletePartnerResponse> =>
  deleteRequest(`/partners/${partnerId}`);

export interface UpdatePartnerParams {
  active?: boolean;
  billing?: boolean;
  autoAcceptReturns?: boolean;
  sellerAssignedCode?: string;
  buyerAssignedCode?: string;
  pricingId?: string;
  metafields?: Metafields;
  billingSettings?: DeepPartial<PartnerBillingSettings>;
  assignToCompanyObjectId?: string;
  merchandisingSettings?: DeepPartial<MerchandisingSettings>;
  shippingSettings?: DeepPartial<ShippingSettings>;
  perOrderFeeSettings?: DeepPartial<PartnerPerOrderFeeSettings>;
  requireFulfillmentTrackingInfo?: boolean;
  syncSellerCompareAtPrice?: boolean;
  maxShipTime?: number;
  maxReturnRate?: number;
}

export type UpdatePartnerResponse = Partner;

export const updatePartner = (
  partnerId: string,
  params: UpdatePartnerParams
): Promise<UpdatePartnerResponse> => patchRequest(`/partners/${partnerId}`, params);

interface GetPartnerByCompanyIdParams {}

export type GetPartnerByCompanyIdResponse = Partner;

export const getPartnerByCompanyId = (
  companyObjectId: string,
  params?: GetPartnerByCompanyIdParams
): Promise<GetPartnerByCompanyIdResponse> =>
  getRequest(`/partners/companyId/${companyObjectId}`, params);

export const reinvitePartner = (partnerId: string): Promise<void> =>
  postRequest(`/partners/${partnerId}/reinvite`);
