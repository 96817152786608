import type { Attribute } from '../types/general';
import type { Company, CompanyResponse } from '../types/models/company';
import { CommerceType } from '../types/models/company';
import type { Order20210101 } from '../types/models/order';
import { friendlyPlatformName, getPlatformUrl, getShopifyStore } from './platform';

export const SHOPIFY_ORDER_NUMBER_KEY = 'shopify_order_number';

export const getPlatformOrderUrl = (order: Order20210101, company: CompanyResponse) => {
  const { orderPlatform, commerceType } = company;
  const { sellerOrderCode, buyerOrderCode } = order;
  if (orderPlatform === 'shopify') {
    if (commerceType === 'seller' && sellerOrderCode) {
      return `https://${getShopifyStore(company)}.myshopify.com/admin/orders/${sellerOrderCode}`;
    }

    if (commerceType === 'buyer' && buyerOrderCode) {
      return `https://${getShopifyStore(company)}.myshopify.com/admin/orders/${buyerOrderCode}`;
    }
  }

  if (orderPlatform === 'woocommerce' && sellerOrderCode) {
    const url = getPlatformUrl(company, 'woocommerce');
    if (url) {
      return `${url}/wp-admin/post.php?post=${sellerOrderCode}&action=edit`;
    }
  }

  if (orderPlatform === 'bigcommerce' && sellerOrderCode) {
    const url = getPlatformUrl(company, 'bigcommerce');
    if (url) {
      return `${url}/manage/orders/${sellerOrderCode}/edit`;
    }
  }

  return '';
};

export const getFriendlyOrderDescription = (company: Company) => {
  const platform = friendlyPlatformName(company.orderPlatform);

  if (
    company.orderPlatform === 'shopify' ||
    company.orderPlatform === 'woocommerce' ||
    company.orderPlatform === 'bigcommerce'
  ) {
    return `${platform} Order #:`;
  }

  return `Order #:`;
};

const buildOrderLink = (
  platform: string,
  orderCode: string,
  attributes?: Record<string, Attribute> | null
) => {
  if (platform === 'shopify') {
    if (attributes === undefined) {
      // This returns the default code
      return `View order in Shopify`;
    }

    let orderNumber: string | undefined;

    if (attributes && attributes[SHOPIFY_ORDER_NUMBER_KEY] !== undefined) {
      const orderAttribute = attributes[SHOPIFY_ORDER_NUMBER_KEY];
      orderNumber = orderAttribute?.value?.toString();
    }

    return orderNumber === undefined || orderNumber === null
      ? `View order in Shopify`
      : orderNumber;
  }

  return orderCode || '-';
};

export const getOrderDisplayCode = (order: Order20210101, company: Company) => {
  const { orderPlatform, commerceType } = company;

  // If seller, return seller order number if shopify, otherwise return seller order code
  if (commerceType === CommerceType.Seller) {
    return buildOrderLink(orderPlatform, order.sellerOrderCode, order.sellerAttributes);
  }
  if (commerceType === CommerceType.Buyer) {
    return buildOrderLink(orderPlatform, order.buyerOrderCode, order.buyerAttributes);
  }

  return '-';
};

export const orderIsFulfilled = (order: Order20210101 | null) => {
  const itemQuantitiesByItemId = (order?.items || []).reduce(
    (accumulator, item) => {
      if (item.cancelled) return accumulator;
      accumulator[item._id] = item.quantity;
      return accumulator;
    },
    {} as Record<string, number>
  );

  const fulfilledItemQuantitiesByItemId =
    order?.fulfillments?.reduce(
      (accumulator, fulfillment) => {
        fulfillment.items?.forEach((item) => {
          if (item.orderItemId === null) return;
          accumulator[item.orderItemId] = (accumulator[item.orderItemId] || 0) + item.quantity;
        });
        return accumulator;
      },
      {} as Record<string, number>
    ) || {};

  return Object.entries(itemQuantitiesByItemId).every(([itemId, quantity]) => {
    const fulfilledQuantity = fulfilledItemQuantitiesByItemId[itemId] || 0;
    return fulfilledQuantity >= quantity;
  });
};
