import { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'storybook/stories/cells/Modal';
import styled from 'styled-components';

import Button from 'components/Common/Button';
import Message, { useMessage } from 'components/Common/Message';
import { selectIsSeller } from 'store/selectors/me/company';
import Icon from 'storybook/stories/molecules/Icon';
import { deleteBuyerProductImage } from 'utils/api/buyer/products';
import { deleteProductImage } from 'utils/api/products';

const MainImage = styled.img`
  max-width: 100%;
  max-height: 800px;
  padding: 0.25rem;
  background-color: #f9fbfd;
  border: 1px solid #e3ebf6;
  border-radius: 0.375rem;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SelectedImageModal = ({
  onImageDeleted,
  imageIdx,
  show,
  onDismiss,
  image = {},
  title,
  numberOfImgs,
  canEdit,
  productId,
  imageId,
  refreshProduct,
}) => {
  const [deleting, setDeleting] = useState(false);
  const { showErrorMessage, messageState, hideMessage } = useMessage();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const isSeller = useSelector(selectIsSeller);

  const onCloseModal = () => {
    hideMessage();
    setShowDeleteConfirm(false);
    setDeleting(false);
    onDismiss();
  };

  const onDownloadClick = async () => {
    const filename = image.src.split('\\').pop().split('/').pop();
    const a = window.document.createElement('a');
    a.style.display = 'none';
    try {
      const response = await window.fetch(image.src, {
        headers: new window.Headers({
          Origin: window.location.origin,
        }),
        mode: 'cors',
      });
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      a.download = filename;
      a.href = blobUrl;
    } catch (err) {
      // open image in another tab if download fails
      a.target = '_blank';
      a.href = image.src;
    }
    window.document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const onConfirmDelete = async () => {
    const deleteImageEndpoint = isSeller ? deleteProductImage : deleteBuyerProductImage;

    setDeleting(true);
    try {
      await deleteImageEndpoint(productId, imageId);
      onCloseModal();
      onImageDeleted(imageId);
      refreshProduct();
    } catch (err) {
      showErrorMessage({ title: 'Error deleting image', message: err.message });
      setDeleting(false);
    }
  };

  const onDelete = () => {
    if (!image._id) {
      onImageDeleted(imageId);
      return;
    }
    setShowDeleteConfirm(true);
  };

  return (
    <Modal.Root
      show={show}
      size="lg"
      aria-labelledby="update-product-image-modal"
      centered
      onHide={onCloseModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Image {imageIdx + 1} of {numberOfImgs}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Message
          show={messageState.show}
          onClose={hideMessage}
          kind={messageState.kind}
          className="mt-0"
        >
          <p className="m-0 mb-1">
            <strong>{messageState.title}</strong>
          </p>
          <p className="m-0">{messageState.message}</p>
        </Message>

        <ImageContainer>
          <MainImage src={image.src} alt={title} referrerPolicy="no-referrer" />
        </ImageContainer>
      </Modal.Body>

      <Modal.Footer>
        {showDeleteConfirm ? (
          <>
            <strong>Are you sure you want to delete this image?</strong>
            <Button
              size="sm"
              color="white"
              type="button"
              onClick={() => setShowDeleteConfirm(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color="danger"
              type="button"
              onClick={onConfirmDelete}
              disabled={deleting}
            >
              Delete
            </Button>
          </>
        ) : (
          <>
            {canEdit && (
              <Button size="sm" color="danger" type="button" onClick={onDelete} className="mr-3">
                <Icon name="delete" />
              </Button>
            )}

            {image.src && (
              <Button size="sm" color="white" type="button" onClick={onDownloadClick}>
                <Icon name="download" />
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal.Root>
  );
};

export default SelectedImageModal;
