import Flex from '@react-css/flex';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

interface HeaderRowProps {
  buttonText: string;
  buttonIconName: string;
  isEditing: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  onClickEdit: () => void;
  onClickCancel: () => void;
}

const HeaderRow = ({
  buttonText,
  buttonIconName,
  isEditing,
  isValid,
  isSubmitting,
  onClickEdit,
  onClickCancel,
}: HeaderRowProps) => {
  return (
    <Wrapper>
      <Flex justifySpaceBetween alignContentCenter alignItemsCenter>
        <Heading variant="Headings/H3">Metafields</Heading>

        {!isEditing ? (
          <TertiaryButton size="large" $iconAfter $iconName={buttonIconName} onClick={onClickEdit}>
            {buttonText}
          </TertiaryButton>
        ) : (
          <Flex gap="12px" alignContentCenter justifyCenter>
            <SecondaryButton size="small" kind="destructive" onClick={onClickCancel}>
              Cancel
            </SecondaryButton>

            <PrimaryButton type="submit" size="small" disabled={!isValid || isSubmitting}>
              Save
            </PrimaryButton>
          </Flex>
        )}
      </Flex>
    </Wrapper>
  );
};

export default HeaderRow;
