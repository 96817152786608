import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import Card from 'storybook/stories/cells/Card';
import MetafieldsEditor from 'storybook/stories/organisms/MetafieldsEditor';
import styled from 'styled-components';
import type { Metafields } from 'types/general';
import { getBuyerProduct, updateProductMetafields } from 'utils/api/buyer/products';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

export interface BuyerMetaFieldsProps {
  productId: string;
}

const BuyerMetafields = ({ productId }: BuyerMetaFieldsProps) => {
  const queryClient = useQueryClient();
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const fetchingBuyerProduct = useQuery({
    queryKey: ['getBuyerProduct', productId],
    queryFn: () => getBuyerProduct(productId),
    onError: () => {
      addErrorAlert('Error!', 'Something went wrong. Please try again.');
    },
  });

  const updatingMetafields = useMutation({
    mutationFn: (body: Metafields) => updateProductMetafields(productId, body),
    onSuccess: () => {
      addSuccessAlert('Success', 'Your product has been updated.');
      queryClient.invalidateQueries(['getBuyerProduct', productId]);
    },
    onError: () => {
      addErrorAlert('Error!', 'Something went wrong. Please try again.');
    },
  });

  return (
    <Wrapper>
      <Card>
        <MetafieldsEditor
          metafields={fetchingBuyerProduct.data?.data.metafields}
          updateMetafields={updatingMetafields.mutate}
        />
      </Card>
    </Wrapper>
  );
};

export default BuyerMetafields;
