import * as Sentry from '@sentry/react';

const useScopedSentryMessage =
  (severity: Sentry.SeverityLevel) => (message: string, extra?: Record<string, any>) => {
    Sentry.withScope((scope) => {
      if (extra)
        Object.entries(extra).forEach(([key, value]) => {
          scope.setExtra(key, value);
        });

      Sentry.captureMessage(message, severity);
    });
  };

export default useScopedSentryMessage;
