/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { FormFieldLabel } from 'components/Common/FormField';
import { forwardRef } from 'react';

const ShopifyFormField = forwardRef((props, ref) => {
  const { error, name, size, className, defaultValue, readOnly, ...inputProps } = props;
  const cn = classNames('form-group', className);
  const inputCN = classNames('form-control', 'form-control-prepended', 'form-control-appended', {
    [`form-control-${size}`]: size,
    'is-invalid': error,
  });
  const inputGroupCN = classNames('input-group-text');

  return (
    <div className={cn}>
      <FormFieldLabel size={size} htmlFor="shopify-store-url-input">
        Shopify Store Name
      </FormFieldLabel>
      <div className={`input-group input-group-merge input-group-${size}`}>
        <input
          data-testid="shopify-store-url-input"
          id="shopify-store-url-input"
          type="text"
          name={name}
          className={inputCN}
          placeholder="store"
          ref={ref}
          defaultValue={defaultValue}
          readOnly={readOnly}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
        />
        <div className="input-group-prepend">
          <div className={inputGroupCN}>
            <span>https://</span>
          </div>
        </div>
        <div className="input-group-append">
          <div className={inputGroupCN}>
            <span>.myshopify.com</span>
          </div>
        </div>
      </div>
      {error ? <div className="invalid-feedback d-block">{error.message}</div> : null}
    </div>
  );
});

export default ShopifyFormField;
