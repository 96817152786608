import { useState } from 'react';
import Modal from 'storybook/stories/cells/Modal';
import styled from 'styled-components';

import CreateActionCheckbox from 'containers/PartnerPage/Actions/Common/CreateActionCheckbox';
import CreateActionTextarea from 'containers/PartnerPage/Actions/Common/CreateActionTextarea';
import CreateActionTextbox from 'containers/PartnerPage/Actions/Common/CreateActionTextbox';
import PreviewCreateActionCard from 'containers/PartnerPage/Actions/Common/PreviewCreateActionCard';
import SubmitFormButton from 'containers/PartnerPage/Actions/Common/SubmitFormButton';
import { BTN_DELAY } from 'containers/PartnerPage/Actions/Shared';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

const OptionalDocText = styled.p`
  font-size: 0.75rem;
  padding-top: 0.313rem;
`;

export type CreateFileUploadActionData = {
  cardTitle: string;
  cardText: string;
  kind: string;
  required: boolean;
  bytes: number[] | null;
  fileName?: string;
  fileType?: string;
  partnerId?: string;
  optionalDocId?: string;
};

interface CreateFileUploadActionModalProps {
  kind: string;
  showModal: boolean;
  onHide: () => void;
  onCreate: (body: CreateFileUploadActionData) => Promise<any>;
  onRefresh: React.Dispatch<void>;
  onBackClick: () => void;
  isActionTemplate: boolean;
}

const CreateFileUploadActionModal = ({
  kind,
  showModal,
  onHide,
  onCreate,
  onRefresh,
  onBackClick,
  isActionTemplate,
}: CreateFileUploadActionModalProps) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [required, setRequired] = useState(false);
  const [optionalDoc, setOptionalDoc] = useState<File | null>(null);

  const completeFormSubmission = (bytes: number[] | null, fileName?: string, fileType?: string) => {
    onCreate({
      cardTitle: title,
      cardText: text,
      kind,
      required,
      bytes,
      fileName,
      fileType,
    })
      .then((response) => {
        setLoading(false);

        if (response.error && response.error.message) {
          setError(response.error.message);
        } else {
          setSuccess(true);
          setTimeout(() => {
            setTitle('');
            setText('');
            setRequired(false);
            setError('');
            setSuccess(false);
            onRefresh();
          }, BTN_DELAY);
        }
      })
      .catch((err) => {
        console.warn('error', err);
        setLoading(false);

        if (err.code === 400) {
          setError('Invalid request');
        }
      });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (title === '') {
      setError('Error: Title is required');
      return;
    }

    setLoading(true);

    if (optionalDoc) {
      // Upload the optional doc
      const reader = new FileReader();
      reader.onload = () => {
        const bytes = Array.from(new Uint8Array(reader.result as ArrayBufferLike));
        completeFormSubmission(bytes, optionalDoc.name, optionalDoc.type);
      };
      reader.onerror = (err) => {
        console.warn(err);
      };
      reader.readAsArrayBuffer(optionalDoc);
    } else {
      completeFormSubmission(null);
    }
  };

  return (
    <Modal.Root
      data-testid="action-file-upload-modal"
      show={showModal}
      onHide={onHide}
      aria-labelledby="action-file-upload-modal"
      animation={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isActionTemplate ? 'Create File Upload Action Template' : 'Create File Upload Action'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            {error && <div className="alert alert-danger">{error}</div>}
            <CreateActionTextbox
              id="title"
              label="Title"
              value={title}
              placeholder="Ex. Partnership Agreement"
              setValue={setTitle}
            />
            <CreateActionTextarea
              id="text"
              label="Description"
              value={text}
              placeholder="Describe the required steps"
              setValue={setText}
            />
            <CreateActionCheckbox
              id="required"
              label="Required"
              value={required}
              setValue={setRequired}
            />
            <div className="form-group">
              <label htmlFor="modify-card-text">
                Downloadable Document{' '}
                <span className="badge badge-soft-info" id="download-doc-optional-badge">
                  Optional
                </span>
              </label>
              <input
                type="file"
                className="form-control"
                id="create-card-optional-document"
                onChange={(e) => {
                  if (e.target.files) {
                    setOptionalDoc(e.target.files[0]);
                  }
                }}
              />
              <OptionalDocText className="text-muted" id="download-doc-help-text">
                You can use this field to provide your partner with a file. This can be helpful if
                you want them to fill in a form or provide them with a template. If you make
                reference to your document in the description, we will try and link out from that
                reference (Ex. &quot;our document&quot;). Otherwise, we provide a download button to
                your partner.
              </OptionalDocText>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            <PreviewCreateActionCard
              title={title}
              text={text}
              kind={kind}
              required={required}
              optionalDoc={optionalDoc}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <div className="text-right">
              <SecondaryButton className="mr-3" size="small" onClick={onBackClick}>
                Back
              </SecondaryButton>
              <SubmitFormButton
                id="create-action-form-btn"
                label={isActionTemplate ? 'Create Action Template' : 'Create Action'}
                successfulLabel="Created"
                loading={loading}
                success={success}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal.Root>
  );
};

export default CreateFileUploadActionModal;
