import { forwardRef } from 'react';
import styled from 'styled-components';

import Input from 'storybook/stories/molecules/Input';

const StyledInput = styled(Input)`
  border-radius: 16px;
  min-height: 135px;
  height: auto;
`;

const TextAreaInput = forwardRef<HTMLTextAreaElement, React.ComponentProps<typeof StyledInput>>(
  (props, ref) => <StyledInput as="textarea" ref={ref} {...props} />
);

export default TextAreaInput;
