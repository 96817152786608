import { combineReducers } from '@reduxjs/toolkit';

import account from './account';
import company from './company';
import integrations from './integrations';
import sla from './sla';
import team from './team';

const settingsReducer = combineReducers({
  integrations,
  account,
  company,
  team,
  sla,
});

export default settingsReducer;
