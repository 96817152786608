import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { updateOnboardingStatus } from 'store/slices/company';
import { addPartner, getPartners } from 'store/slices/partners';
import { getPriceLists } from 'utils/api/priceLists';

import Flex from '@react-css/flex';
import CardFormField from 'components/Common/CardFormField';
import useAlertQueue from 'hooks/useAlertQueue';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import LoadingCard from './LoadingCard';
import PartnersTable from './PartnersTable';

export const getNameOrEmail = ({ buyerName, sellerName, reference: email }, isSeller) => {
  if (isSeller && isEmpty(buyerName)) return email;
  if (!isSeller && isEmpty(sellerName)) return email;

  return isSeller ? buyerName : sellerName;
};

const PriceListSelect = ({ prices, register, isLoading }) => {
  if (isLoading) {
    return <p className="small mb-0">Loading prices...</p>;
  }

  if (isEmpty(prices)) {
    return (
      <p className="small mb-0">
        You don&apos;t have any price lists.{' '}
        <Link className="font-weight-bold" to="/prices">
          Create price list
        </Link>
      </p>
    );
  }

  return (
    <select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...register('priceId')}
      className="form-control form-control-sm"
      id="prices-select"
      data-testid="prices-select"
    >
      <option key="empty" value="">
        --
      </option>
      {prices.map((p) => (
        <option key={p.id} value={p.id}>
          {p.name}
        </option>
      ))}
    </select>
  );
};

const InvitePartnersCard = ({ done, company, disableInvite }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const alertQueue = useAlertQueue();
  const dispatch = useDispatch();
  const isSeller = company.commerceType === 'seller';
  // hacks because partnersState and pricesState are initially null
  const { partners, partnerIds, loadingPartners } = useSelector(({ partners: p }) => p);
  const hasPartner = partnerIds && partnerIds.length > 0;
  const hasAssignedPrice =
    partnerIds && partnerIds.some((id) => partners[id] && partners[id].pricingId);

  useEffect(() => {
    dispatch(getPartners({ page: 0, limit: 250 }));
  }, [dispatch]);

  const fetchingPriceLists = useQuery({
    queryKey: ['priceLists'],
    queryFn: () => getPriceLists({ page: 0, limit: 250 }),
    enabled: isSeller,
  });

  const prices = fetchingPriceLists.data?.data || [];

  const onSubmit = async (partnerData) => {
    setIsSubmitting(true);

    const { error, payload: partner } = await dispatch(addPartner(partnerData));

    setIsSubmitting(false);

    if (error) {
      alertQueue.addErrorAlert('Something went wrong', error.message);
      return;
    }

    const nameOrEmail = getNameOrEmail(partner, isSeller);

    if (isSeller) {
      alertQueue.addSuccessAlert(
        'Success',
        `Your partnership invitation to ${nameOrEmail} has been sent. ${nameOrEmail} will receive an email with instructions on how to partner with you and make their products available to your company.`
      );
    } else {
      alertQueue.addSuccessAlert(
        'Success',
        `Your partnership invitation to ${nameOrEmail} has been sent. Your products are now available for ${nameOrEmail} to select once they have accepted your partnership invitation.`
      );
    }

    reset();
  };

  const updateInvitePartnersStatus = (status) => {
    dispatch(updateOnboardingStatus({ invitePartnerCompleted: status }));
  };

  if (loadingPartners) {
    return <LoadingCard />;
  }

  return (
    <GetStartedCard
      isComplete={done}
      title="Invite a Partner"
      completedUi={
        <Body as="p">
          Manage your partners from the <Link to="/partners">Partners page</Link>.
        </Body>
      }
    >
      <Flex column gap="16px">
        <Body as="p">
          You need to invite a partner to trade with.{' '}
          {isSeller && "You'll need to assign a price list to a partner as well. "}
          <SupportLink article={isSeller ? 'dvwv9wl2ws' : 'myjn4aiax9'}>More Info</SupportLink>
        </Body>

        {!disableInvite && (
          <PartnersTable
            partners={partners}
            isSeller={isSeller}
            prices={prices}
            partnerIds={partnerIds}
          />
        )}

        {disableInvite ? (
          <Body as="p">
            You must add a payment method and billing address before you can invite partners to
            trade with.
          </Body>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex column gap="16px">
              <CardFormField
                type="email"
                id="partner-email-input"
                label="Email"
                placeholder="jane@example.com"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('email', {
                  required: 'Please enter an email',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please enter a valid email address',
                  },
                })}
                error={errors.email}
              />
              {isSeller && (
                <div className="form-group row align-items-center mb-3">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    htmlFor="prices-select"
                    className="col-sm-12 col-md-3 col-form-label col-form-label-sm"
                  >
                    Price List
                  </label>

                  <div className="col-sm-12 col-md-9">
                    <PriceListSelect
                      id="prices-select"
                      prices={prices}
                      isLoading={fetchingPriceLists.isLoading}
                      register={register}
                    />
                  </div>
                </div>
              )}

              <Flex.Item alignSelfEnd>
                <Flex gap="8px">
                  {(!isSeller || hasAssignedPrice) && (
                    <SecondaryButton
                      size="small"
                      onClick={() =>
                        updateInvitePartnersStatus(hasPartner ? 'completed' : 'skipped')
                      }
                    >
                      {hasPartner ? 'Mark as Completed' : 'Skip'}
                    </SecondaryButton>
                  )}

                  <PrimaryButton size="small" type="submit" disabled={isSubmitting}>
                    Send Partner Invite
                  </PrimaryButton>
                </Flex>
              </Flex.Item>
            </Flex>
          </form>
        )}
      </Flex>
    </GetStartedCard>
  );
};

export default InvitePartnersCard;
