import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { connectStripe as apiConnectStripe } from 'utils/api/auth';
import {
  createBillingPortal as apiCreateBillingPortal,
  createUSBillingPortal as apiCreateUSBillingPortal,
  updateCompany,
} from 'utils/api/companies';

export const connectStripe = createAsyncThunk('stripe/connect', () => {
  return apiConnectStripe().then((data) => {
    window.location.assign(data.authorizeURL);
    return data;
  });
});

export const skipStripeSetup = createAsyncThunk('stripe/skipSetup', (_, thunkAPI) => {
  const {
    me: { company },
  } = thunkAPI.getState();
  if (!company) return company;
  return updateCompany(company._id, { gateway: 'other' });
});

export const createBillingPortal = createAsyncThunk('stripe/createBillingPortal', () =>
  apiCreateBillingPortal().then((data) => {
    window.location.assign(data.redirectURL);
    return data;
  })
);

export const createUSBillingPortal = createAsyncThunk('stripe/createUSBillingPortal', () =>
  apiCreateUSBillingPortal().then((data) => {
    window.location.assign(data.redirectURL);
    return data;
  })
);

const stripeSlice = createSlice({
  name: 'stripe',
  initialState: {
    isConnecting: false,
    isSkippingSetup: false,
    isCreatingBillingPortal: false,
    isCreatingUSBillingPortal: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(connectStripe.pending, (draft) => {
        draft.isConnecting = true;
      })
      .addCase(skipStripeSetup.pending, (draft) => {
        draft.isSkippingSetup = true;
      })
      .addCase(createBillingPortal.pending, (draft) => {
        draft.isCreatingBillingPortal = true;
      })
      .addCase(createUSBillingPortal.pending, (draft) => {
        draft.isCreatingUSBillingPortal = true;
      })
      .addMatcher(isAnyOf(connectStripe.fulfilled, connectStripe.rejected), (draft) => {
        draft.isConnecting = false;
      })
      .addMatcher(isAnyOf(skipStripeSetup.fulfilled, skipStripeSetup.rejected), (draft) => {
        draft.isSkippingSetup = false;
      })
      .addMatcher(isAnyOf(createBillingPortal.fulfilled, createBillingPortal.rejected), (draft) => {
        draft.isCreatingBillingPortal = false;
      });
  },
});

export default stripeSlice.reducer;
