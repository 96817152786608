import type { PriceList } from 'types/models/price-list';
import findChangeByPath from 'utils/history';
import { toMarginString } from 'utils/prices';
import { ChangeList, type ChangeListProps, type ChangeRenderer } from '.';

// priceListChangeRenderers returns a list of change renderers for the price list
export const priceListChangeRenderers = () => {
  const renderers: Record<string, ChangeRenderer<PriceList>> = {
    name: {
      friendlyName: 'Name',
      from: (value) => value,
      to: (value) => value,
    },
    dropshipMargin: {
      friendlyName: 'Dropship Margin',
      // From margin depends on margin type at the time of the change
      from: (value, changes, snapshot) =>
        toMarginString({
          margin: value,
          marginType:
            findChangeByPath(changes, 'dropshipMarginType')?.from || snapshot.dropshipMarginType,
          currency: snapshot.sellerCurrency,
        }),
      // To margin will always use the newer snapshotted margin type
      to: (value, _, snapshot) =>
        toMarginString({
          margin: value,
          marginType: snapshot.dropshipMarginType,
          currency: snapshot.sellerCurrency,
        }),
    },
  };

  return renderers;
};

const PriceListChangeList = ({
  changes,
  snapshot,
}: Omit<ChangeListProps<PriceList>, 'renderers'>) => {
  return (
    <ChangeList changes={changes} snapshot={snapshot} renderers={priceListChangeRenderers()} />
  );
};

export default PriceListChangeList;
