import HelloSign from 'hellosign-embedded';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import DashboardPage from 'components/Dashboard/DashboardPage';
import useAlertQueue from 'hooks/useAlertQueue';
import { getAgreement, getEmbeddedAgreement } from 'store/slices/agreements';
import Page from 'storybook/stories/cells/Page';
import Icon from 'storybook/stories/molecules/Icon';
import { syncAction } from 'utils/api/actions';
import config from 'utils/config';

const SignedIconWrapper = styled.div`
  padding: 25px;
`;
const SignedIcon = styled(Icon)`
  font-size: 2.25rem;
  border: 2px solid #04b431;
  border-radius: 50%;
  padding: 10px;
  color: #04b431;
`;

const AgreementPage = () => {
  const history = useHistory();
  const { agreementId } = useParams();
  const dispatch = useDispatch();
  const { agreement, embeddedData } = useSelector(({ agreements }) => agreements);
  const client = useMemo(() => new HelloSign(config.hellosign), []);
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const [documentHasOpened, setDocumentHasOpened] = useState(false);
  const [error, setError] = useState('');
  const [agreementSigned, setAgreementSigned] = useState(false);

  useEffect(() => {
    dispatch(getAgreement(agreementId)).then((response) => {
      if (response.error) {
        setError(response.error.message);
      }
    });
  }, [dispatch, agreementId]);
  useEffect(() => {
    if (agreement && agreement.id) {
      dispatch(getEmbeddedAgreement(agreement.id)).then((response) => {
        if (response.error && response.error.message === 'agreement already signed') {
          setAgreementSigned(true);
        } else if (response.error) {
          setError(response.error.message);
        }
      });
    }
  }, [agreement, dispatch]);

  useEffect(() => {
    if (client) {
      setDocumentHasOpened(true);
      if (embeddedData.link) {
        client.open(embeddedData.link);
        client.on('sign', (data) => {
          console.log('The document has been signed!');
          console.log(`Signature ID: ${data.signatureId}`);
          // Sync action if available (Response doesnt matter)
          const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
          if (queryParams.action) {
            syncAction(queryParams.action)
              .then((response) => {
                console.log('syncAction :: response', response);
                addSuccessAlert('Success', 'You have successfully accepted the agreement.');
                client.close();
                history.push('/partners');
              })
              .catch((e) => {
                console.log('syncAction :: error', e);
                addErrorAlert('Something went wrong', e.message ?? 'Please try again later.');
              });
          } else {
            client.close();
            setAgreementSigned(true);
          }
        });
      }
    }
  }, [embeddedData, agreement, client, addErrorAlert, addSuccessAlert, history]);

  if (agreementSigned) {
    return (
      <DashboardPage>
        <Page>
          <Page.Head title={agreement?.name} />
          <Page.Body>
            <SignedIconWrapper>
              <SignedIcon name="done" />
            </SignedIconWrapper>
            <h2 className="mb-2">Agreement Signed</h2>
            <p className="text-muted mb-2">
              This agreement has been signed. If you need further support, please contact the owner
              or our support team at support@convictional.com.
            </p>
          </Page.Body>
        </Page>
      </DashboardPage>
    );
  }

  return (
    <DashboardPage>
      <Page>
        <Page.Head title={agreement?.name} />
        <Page.Body>
          {error && error !== '' ? <div className="alert alert-danger">{error}</div> : null}
          {documentHasOpened ? (
            <p className="text-muted small">
              Agreement has opened above this window. If you do not see the agreement, please
              contact support@convictional.com.
            </p>
          ) : (
            <p>Loading...</p>
          )}
        </Page.Body>
      </Page>
    </DashboardPage>
  );
};

export default AgreementPage;
