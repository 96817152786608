import Flex from '@react-css/flex';
import CopyIcon from 'storybook/stories/molecules/Icon/CopyIcon';
import { CommerceType } from 'types/models/company';
import type { Order } from 'types/models/order';
import { SHOPIFY_ORDER_NUMBER_KEY } from 'utils/order';

interface OrderCodeProps {
  order: Order;
  type: CommerceType;
}

const OrderCode = ({ order, type }: OrderCodeProps) => {
  const attributes = type === CommerceType.Seller ? order.sellerAttributes : order.buyerAttributes;
  const shopifyOrderCode = attributes?.[SHOPIFY_ORDER_NUMBER_KEY]?.value;
  const convictionalOrderCode =
    type === CommerceType.Seller ? order.sellerOrderCode : order.buyerOrderCode;
  const orderCode = shopifyOrderCode || convictionalOrderCode;

  if (!orderCode) return <span>N/A</span>;

  return (
    <Flex inline alignItemsCenter gap="8px">
      {orderCode}
      <CopyIcon textToCopy={orderCode} />
    </Flex>
  );
};

export default OrderCode;
