/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'storybook/stories/cells/Modal';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

import { isRejected } from '@reduxjs/toolkit';
import Message, { useMessage } from 'components/Common/Message';
import { useAppDispatch } from 'store';
import { deletePartner as deletePartnerAction } from 'store/slices/partners';

type CreateDeletePartnerModalProps = {
  name: string;
  partnerId: string;
  showModal: boolean;
  onDismiss: () => void;
  isBuyer: boolean;
};

const CreateDeletePartnerModal = ({
  name,
  partnerId,
  showModal,
  onDismiss,
  isBuyer,
}: CreateDeletePartnerModalProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [deleting, setDeleting] = useState(false);
  const { showSuccessMessage, showErrorMessage, messageState, hideMessage } = useMessage();

  const onCloseModal = () => {
    hideMessage();
    onDismiss();
  };

  if (!partnerId) return null;
  const deletePartner = () => {
    setDeleting(true);
    dispatch(deletePartnerAction(partnerId)).then((action) => {
      setDeleting(false);
      if (isRejected(action)) {
        showErrorMessage({ title: 'Error deleting partner', message: action.error.message });
      } else {
        showSuccessMessage({ title: 'Success', message: 'Your partner has been deleted' });
        history.replace('/partners');
      }
    });
  };
  return (
    <Modal.Root
      show={showModal}
      size="lg"
      aria-labelledby="delete-partner-modal-title"
      centered
      animation={false}
      onHide={onCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Message
          show={messageState.show}
          onClose={hideMessage}
          kind={messageState.kind}
          className="mt-0"
        >
          <p className="m-0 mb-1">
            <strong>{messageState.title}</strong>
          </p>
          <p className="m-0">{messageState.message}</p>
        </Message>

        <p>
          Deleting your partnership will remove all of{' '}
          {isBuyer ? (name ? `${name}'s` : 'their') : 'your'} products from{' '}
          {isBuyer ? 'your' : name ? `${name}'s` : 'their'} platform, disabling{' '}
          {isBuyer ? 'you' : 'them'} from submitting new orders. This action will not impact
          previous orders.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <SecondaryButton
          size="small"
          type="submit"
          kind="destructive"
          disabled={deleting}
          onClick={deletePartner}
        >
          Delete this partner
        </SecondaryButton>
      </Modal.Footer>
    </Modal.Root>
  );
};

export default CreateDeletePartnerModal;
