import classNames from 'classnames';
import pluralize from 'pluralize';
import { useState } from 'react';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Icon from 'storybook/stories/molecules/Icon';

interface CardProductValidationError {
  buyerCompanyObjectId: string;
  field?: string;
  fieldPath: string;
  message: string;
}

interface CardProductValidationAlertProps {
  className: string;
  errors?: CardProductValidationError[];
  numVariantsWithErrors?: number;
}

const CardProductValidationAlert = ({
  errors = [],
  className,
  numVariantsWithErrors,
}: CardProductValidationAlertProps) => {
  const [showErrors, setShowErrors] = useState<boolean>();

  return (
    <div className={classNames(className, 'd-flex align-items-center')}>
      <Icon name="warning" className="text-warning" />

      <div>
        {numVariantsWithErrors ? (
          <p className="mt-0 mb-0 font-weight-bold">
            {numVariantsWithErrors} variant{numVariantsWithErrors > 1 ? 's have' : ' has'}{' '}
            validation errors
          </p>
        ) : (
          <TertiaryButton
            className="font-weight-bold"
            onClick={() => setShowErrors((prev) => !prev)}
            aria-label="show validation errors"
          >
            {pluralize('validation error', errors.length, true)}
            <Icon name={showErrors ? 'expand_less' : 'expand_more'} />
          </TertiaryButton>
        )}
        {showErrors &&
          errors?.map((err, idx) => {
            return (
              <p
                // eslint-disable-next-line react/no-array-index-key
                key={idx + err.fieldPath + err.message + err.buyerCompanyObjectId}
                className="m-0 mt-1 small"
              >
                <strong>{err.field || err.fieldPath}</strong> - {err.message}
              </p>
            );
          })}
      </div>
    </div>
  );
};

export default CardProductValidationAlert;
