import styled, { css } from 'styled-components';

import Button, { getButtonTypography, type ButtonProps } from 'storybook/stories/molecules/Button';

const Base = styled.nav`
  all: unset;
  display: flex;
  gap: 8px;
`;

type ButtonOverrideProps = Omit<ButtonProps, 'kind' | 'size' | 'iconAfter' | 'gap'>;

interface ItemProps extends ButtonOverrideProps {
  selected?: boolean;
}

const Item = styled(Button).attrs<ItemProps>(() => ({
  size: 'small',
}))<ItemProps>`
  color: ${({ theme }) => theme.color.bodyTextSecondary};
  padding-left: 8px;
  padding-right: 8px;
  height: 34px;
  border-bottom: 2px solid transparent;

  ${({ size, disabled }) => getButtonTypography({ size, disabled })}

  ${({ theme, disabled }) =>
    disabled
      ? css`
          color: ${theme.color.bodyTextDisabled};
        `
      : css`
          &:hover {
            color: ${theme.color.bodyTextLinks};
          }
        `}

  ${({ theme, selected }) =>
    selected &&
    css`
      color: ${theme.color.bodyTextLinks};
      border-color: ${theme.color.bodyTextLinks};
    `}


  // Make the focus state more subtle
  ${({ theme }) => css`
    &:focus {
      color: ${theme.color.bodyTextLinks};

      &::after {
        border-color: transparent;
      }
    }
  `}
`;

/**
 * A simple, visually distinct Tabs UI. It supports `Tabs.Item` subcomponents.
 */
const Tabs = Object.assign(Base, { Item });

export default Tabs;
