import { differenceInSeconds, format, getYear, parseISO } from 'date-fns';
import pluralize from 'pluralize';

// isZeroDate only cares about the year being set to 0001
export const isZeroDate = (arg?: Date | string | null) => {
  if (!arg) return true;

  const date = typeof arg === 'string' ? parseISO(arg) : arg;

  return getYear(date) === 1;
};

export const prettyDate = (arg?: Date | string | null) => {
  if (!arg || isZeroDate(arg)) return '';

  const date = typeof arg === 'string' ? parseISO(arg) : arg;

  return format(date, 'MMM d, yyyy');
};

export const prettyTime = (arg?: Date | string | null) => {
  if (!arg || isZeroDate(arg)) return '';

  const date = typeof arg === 'string' ? parseISO(arg) : arg;

  return format(date, 'h:mm a');
};

export const prettyDateTime = (arg?: Date | string | null) => {
  if (!arg || isZeroDate(arg)) return '';

  const date = typeof arg === 'string' ? parseISO(arg) : arg;

  return format(date, "MMM d, yyyy 'at' h:mm a");
};

export const prettyTimeBetween = (
  startDate: string | null,
  endDate: string | null,
  maxHours = 12
) => {
  if (!startDate || !endDate) return '';
  if (isZeroDate(startDate) || isZeroDate(endDate)) return '';

  const [start, end] = [parseISO(startDate), parseISO(endDate)];

  const distance = differenceInSeconds(end, start);

  if (distance <= 0) return 'ongoing';
  if (distance < 60) return pluralize('second', distance, true);
  if (distance < 60 * 60) return pluralize('minute', Math.round(distance / 60), true);

  if (distance <= 60 * 60 * maxHours)
    return pluralize('hour', Math.round(distance / 60 / 60), true);

  return `more than ${maxHours} hours`;
};
