import Flex from '@react-css/flex';
import Dropdown from 'storybook/stories/cells/Dropdown';

import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Divider from 'storybook/stories/molecules/Divider';
import Icon from 'storybook/stories/molecules/Icon';

interface ActionsDropdownProps {
  onActivateProductsClick: React.MouseEventHandler<HTMLButtonElement>;
  onDeactivateProductsClick: React.MouseEventHandler<HTMLButtonElement>;
  onAddTagsClick: React.MouseEventHandler<HTMLButtonElement>;
  onRemoveTagsClick: React.MouseEventHandler<HTMLButtonElement>;
  onAssignCategoryClick: React.MouseEventHandler<HTMLButtonElement>;
  onDeleteProductsClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ActionsDropdown = ({
  onActivateProductsClick,
  onDeactivateProductsClick,
  onAddTagsClick,
  onRemoveTagsClick,
  onAssignCategoryClick,
  onDeleteProductsClick,
}: ActionsDropdownProps) => {
  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        <SecondaryButton $iconName="more_vert">Actions</SecondaryButton>
      </Dropdown.Trigger>
      <Dropdown.Content>
        {() => {
          return (
            <Flex column gap="32px">
              <Dropdown.Header iconName="more_vert" heading="More Actions" />

              <Flex column gap="16px">
                <Icon as="button" name="cloud_done" onClick={onActivateProductsClick}>
                  Activate Products
                </Icon>

                <Icon as="button" name="cloud_off" onClick={onDeactivateProductsClick}>
                  Deactivate Products
                </Icon>

                <Divider />

                <Icon as="button" name="new_label" onClick={onAddTagsClick}>
                  Add Tags
                </Icon>

                <Icon as="button" name="label_off" onClick={onRemoveTagsClick}>
                  Remove Tags
                </Icon>

                <Divider />

                <Icon as="button" name="category" onClick={onAssignCategoryClick}>
                  Assign Category
                </Icon>

                <Divider />

                <Icon as="button" name="delete" color="error500" onClick={onDeleteProductsClick}>
                  Delete Products
                </Icon>
              </Flex>
            </Flex>
          );
        }}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default ActionsDropdown;
