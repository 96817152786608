import { useSelector } from 'react-redux';
import { useDocumentTitle } from 'usehooks-ts';

import BuyerProductsPage from 'containers/Products/BuyerProductsPage';
import SellerProductsPage from 'containers/Products/SellerProductsPage';
import { selectIsBuyer } from 'store/selectors/me/company';

const ProductsPage = () => {
  useDocumentTitle('Products');

  const isBuyer = useSelector(selectIsBuyer);

  return isBuyer ? <BuyerProductsPage /> : <SellerProductsPage />;
};

export default ProductsPage;
