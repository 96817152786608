import Answer from 'containers/ReportingPage/Answer';
import Modal from 'storybook/stories/cells/Modal';
import type { Question } from 'types/models/question';

interface ReportModalProps {
  question: Question | null;
  onHide: () => void;
}

const ReportModal: React.FC<ReportModalProps> = ({ question, onHide }) => {
  return (
    <Modal.Root show={!!question} onHide={onHide} size="xl" centered data-testid="report-modal">
      <Modal.Header closeButton>
        <Modal.Title>{question?.question}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Answer answer={question?.answer} />
      </Modal.Body>
    </Modal.Root>
  );
};

export default ReportModal;
