import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';

import type { PartnerAction, Terms } from 'types/models/partner-action';
import { listConsumableActionTemplates } from 'utils/api/actionTemplates';

import { TERMS_AND_CONDITIONS } from '../Kinds';
import { BTN_DELAY } from '../Shared';
import ActionHeaderInfoModal from './ActionHeaderInfoModal';
import DefineMappingsModal from './DefineMappingsModal';
import EnterValuesModal from './EnterValuesModal';
import SelectTemplateModal from './SelectTemplateModal';

const kind = TERMS_AND_CONDITIONS;

const enum ModalOptions {
  ShowSelectTemplateModal,
  ShowActionHeaderInfoModal,
  ShowMappingModal,
  ShowEnterValuesModal,
  HideAll,
}

interface CreateTermsAndCondtionsProps {
  showModal: boolean;
  onHide: () => void;
  onCreate: (data: any) => Promise<any>;
  onRefresh: React.Dispatch<void>;
  onGeneralBackClick: () => void;
  isActionTemplate: boolean;
}

const CreateTermsAndConditions = ({
  showModal,
  onHide,
  onCreate,
  onRefresh,
  onGeneralBackClick,
  isActionTemplate,
}: CreateTermsAndCondtionsProps) => {
  const [currentModal, setCurrentModal] = useState(
    !isActionTemplate
      ? ModalOptions.ShowSelectTemplateModal
      : ModalOptions.ShowActionHeaderInfoModal
  );

  const [termsData, setTermsData] = useState({} as Terms);
  const [selectedTemplate, setSelectedTemplate] = useState({} as PartnerAction);
  const [actionData, setActionData] = useState<Partial<PartnerAction>>({});

  const isRefreshedTimeout = useRef<number>();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);

  // Bytes of the PDF file
  const [uploadedFileBytes, setUploadedFileBytes] = useState<number[]>([]);

  // Side Effects

  // Hide all modals
  useEffect(() => {
    let isMounted = true;

    if (isMounted && !showModal) {
      setCurrentModal(ModalOptions.HideAll);
    }

    return () => {
      isMounted = false;
    };
  }, [showModal]);

  useEffect(() => {
    return () => {
      if (isRefreshedTimeout.current) clearTimeout(isRefreshedTimeout.current);
    };
  }, []);

  const { data: actionTemplates, isLoading } = useQuery({
    queryKey: ['templates'],
    queryFn: () => listConsumableActionTemplates({ kind }),
    onSuccess: ({ data: templates }) => {
      if (templates?.length === 0) {
        setCurrentModal(ModalOptions.ShowActionHeaderInfoModal);
      }
    },
  });

  // Event Handlers

  // User selects an action template
  const onSelectTemplateNextClick = () => {
    setSelectedTemplate(selectedTemplate);
    setCurrentModal(ModalOptions.ShowActionHeaderInfoModal);
  };

  // User enters header data, such as Email Subject and Action Title
  const onActionHeaderInfoNextClick = (data: any) => {
    setActionData(data);
    setUploadedFileBytes(data.bytes);
    setCurrentModal(ModalOptions.ShowMappingModal);
  };

  const onMappingBackClick = () => {
    setCurrentModal(ModalOptions.ShowActionHeaderInfoModal);
  };

  const onMappingNextClick = (data: any) => {
    const termsDataCopy = { ...termsData, templateMapping: data };
    setTermsData(termsDataCopy);
    setCurrentModal(ModalOptions.ShowEnterValuesModal);
  };

  const onValueBackClick = () => {
    setCurrentModal(ModalOptions.ShowMappingModal);
  };

  const onValueNextClick = (valuesData: any, updatedFileBytes: number[]) => {
    setIsActionLoading(true);

    const data = {
      cardTitle: actionData.cardTitle,
      cardText: actionData.cardText,
      required: actionData.required,
      fileName: actionData.fileName,
      fileType: actionData.fileType,
      file: updatedFileBytes,
      emailSubject: actionData.emailSubject,
      emailMessage: actionData.emailMessage,
      terms: { ...termsData, templateValue: valuesData },
      signers: actionData.signers,
      action: actionData,
    };

    onCreate(data).then((response) => {
      setIsActionLoading(false);

      if (response.error && response.error.message) {
        console.error('error', response.error);
        setIsActionLoading(false);
      } else {
        setIsSuccess(true);

        isRefreshedTimeout.current = window.setTimeout(() => {
          setIsSuccess(false);
          onRefresh();
          setCurrentModal(ModalOptions.HideAll);
          setActionData({});
        }, BTN_DELAY);
      }
    });
  };

  // Render

  return (
    <>
      <SelectTemplateModal
        showModal={currentModal === ModalOptions.ShowSelectTemplateModal}
        onHide={onHide}
        actionTemplates={actionTemplates}
        isLoading={isLoading}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        onNextClick={onSelectTemplateNextClick}
      />

      <ActionHeaderInfoModal
        showModal={currentModal === ModalOptions.ShowActionHeaderInfoModal}
        onHide={onHide}
        isActionTemplate={isActionTemplate}
        onBackClick={onGeneralBackClick}
        onNextClick={onActionHeaderInfoNextClick}
        action={selectedTemplate}
      />

      <DefineMappingsModal
        showModal={currentModal === ModalOptions.ShowMappingModal}
        onHide={onHide}
        onBackClick={onMappingBackClick}
        onNextClick={onMappingNextClick}
        isActionTemplate={isActionTemplate}
        bytes={uploadedFileBytes}
        defaultMappingData={termsData.templateMapping}
        action={actionData}
      />

      <EnterValuesModal
        showModal={currentModal === ModalOptions.ShowEnterValuesModal}
        onHide={onHide}
        onBackClick={onValueBackClick}
        onNextClick={onValueNextClick}
        isActionTemplate={isActionTemplate}
        bytes={uploadedFileBytes}
        mappingData={termsData.templateMapping}
        action={actionData}
        loading={isActionLoading}
        success={isSuccess}
      />
    </>
  );
};

export default CreateTermsAndConditions;
