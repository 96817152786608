import StatusChip from 'storybook/stories/molecules/Chip/StatusChip';
import { capitalizeFirstChar } from 'utils/strings';

interface InvoiceStatusPillProps {
  status: string;
}

const InvoiceStatusPill = ({ status }: InvoiceStatusPillProps) => {
  if (status === 'paid') {
    return <StatusChip kind="success">{capitalizeFirstChar(status)}</StatusChip>;
  }

  if (status === 'open') {
    return <StatusChip kind="info">{capitalizeFirstChar(status)}</StatusChip>;
  }

  // Defaults to 'draft' status style
  return <StatusChip kind="pending">{capitalizeFirstChar(status)}</StatusChip>;
};

export default InvoiceStatusPill;
