import { useMutation } from '@tanstack/react-query';
import { createRef, useState } from 'react';
import styled from 'styled-components';

import Spinner from 'components/Common/Spinner';
import useAlertQueue from 'hooks/useAlertQueue';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import type ApiError from 'utils/ApiError';
import { uploadProductCSV } from 'utils/api/products';
import UploadProductCSVModal from './UploadProductCSVModal';

const CSV_REGEX = /\.csv$/i;

const HiddenFileInput = styled.input.attrs(() => ({ type: 'file' }))`
  display: none;
`;

interface UploadProductCSVButtonProps {
  onFileUploaded: () => void;
}

const UploadProductCSVButton = ({ onFileUploaded }: UploadProductCSVButtonProps) => {
  const inputRef = createRef<HTMLInputElement>();
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [error, setError] = useState({});

  const uploadingProductCSV = useMutation({
    mutationFn: (result: FileReader['result']) => uploadProductCSV(result),
    onSuccess: () => {
      addSuccessAlert('File uploaded successfully', "We're refreshing your products now!");
      onFileUploaded();
    },
    onError: (err: ApiError) => {
      setShowCreateModal(true);
      setError(err.errorBody as Record<string, string[]>);
      console.error('Unable to import product csv', err);
    },
  });

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) return;

    const [file] = Array.from(event.target.files);

    if (!file?.name?.match(CSV_REGEX)) {
      addErrorAlert('Wrong File Type', 'Please upload a CSV file.');
      return;
    }

    const reader = new FileReader();

    reader.onload = () => uploadingProductCSV.mutate(reader.result);

    reader.onerror = () => {
      addErrorAlert('Something went wrong', 'The file you tried to upload was unreadable');
    };

    reader.readAsArrayBuffer(file);

    if (inputRef?.current) inputRef.current.value = '';
  };

  return (
    <>
      <HiddenFileInput
        data-testid="hidden-product-csv-file-input"
        onChange={handleInputChange}
        ref={inputRef}
      />

      <PrimaryButton
        disabled={uploadingProductCSV.isLoading}
        onClick={() => {
          if (inputRef?.current) inputRef.current.click();
        }}
      >
        {uploadingProductCSV.isLoading ? (
          <Spinner small />
        ) : (
          <Icon name="file_upload">Upload CSV</Icon>
        )}
      </PrimaryButton>
      <UploadProductCSVModal
        showModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
        error={error}
      />
    </>
  );
};

export default UploadProductCSVButton;
