import Flex from '@react-css/flex';
import { isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import Dropdown from 'storybook/stories/cells/Dropdown';
import Badge from 'storybook/stories/molecules/Badge';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Label from 'storybook/stories/molecules/Label';
import Select from 'storybook/stories/molecules/Select';

const BOOLEAN_OPTIONS = [
  { label: '--', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const BooleanSelect = ({ value, onChange }: { value: string; onChange: (v?: string) => void }) => {
  return (
    <Select
      options={BOOLEAN_OPTIONS}
      onChange={(newValue) => onChange(newValue?.value)}
      value={BOOLEAN_OPTIONS.find((option) => option.value === value)}
      styles={{
        container: (provided) => ({
          flexGrow: 1,
          ...provided,
        }),
        input: (provided) => ({
          minWidth: '0px',
          ...provided,
        }),
      }}
    />
  );
};

export type PartnerFilters = {
  completed?: string;
  signedUp?: string;
};

type PartnerFiltersForm = {
  completed: string;
  signedUp: string;
};

type PartnerFiltersUiProps = {
  filters: PartnerFilters;
  setFilters: (filters: PartnerFilters) => void;
};

export const PartnerFiltersUi = ({ filters, setFilters }: PartnerFiltersUiProps) => {
  const { handleSubmit, control, reset } = useForm<PartnerFiltersForm>({});

  const onSubmit = handleSubmit((data) => {
    const partnerFilters: PartnerFilters = {};
    if (data.completed !== '') {
      partnerFilters.completed = data.completed;
    }
    if (data.signedUp !== '') {
      partnerFilters.signedUp = data.signedUp;
    }
    setFilters(partnerFilters);
  });

  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        {isEmpty(filters) ? (
          <TertiaryButton $iconName="filter_alt" kind="neutral">
            Filters
          </TertiaryButton>
        ) : (
          <TertiaryButton $iconName="filter_alt" kind="action">
            Filters
            <Badge variant="info" count={Object.keys(filters).length} />
          </TertiaryButton>
        )}
      </Dropdown.Trigger>
      <Dropdown.Content>
        {({ close }) => (
          <form
            onSubmit={(e) => {
              onSubmit(e);
              close();
            }}
          >
            <Flex column gap="32px">
              <Dropdown.Header iconName="filter_alt" heading="Filters" />

              <Flex column gap="16px">
                <Label htmlFor="completed">Onboarding Complete</Label>
                <Controller
                  name="completed"
                  control={control}
                  defaultValue={filters.completed || ''}
                  render={({ field }) => (
                    <BooleanSelect
                      onChange={(newValue) => field.onChange(newValue)}
                      value={field.value}
                    />
                  )}
                />
              </Flex>
              <Flex column gap="16px">
                <Label htmlFor="signedUp">Signed Up</Label>
                <Controller
                  name="signedUp"
                  control={control}
                  defaultValue={filters.signedUp || ''}
                  render={({ field }) => (
                    <BooleanSelect
                      onChange={(newValue) => field.onChange(newValue)}
                      value={field.value}
                    />
                  )}
                />
              </Flex>

              <Flex justifyEnd gap="8px">
                <TertiaryButton
                  type="reset"
                  onClick={() => {
                    reset({
                      completed: '',
                      signedUp: '',
                    });
                  }}
                >
                  Clear All
                </TertiaryButton>

                <PrimaryButton type="submit">Apply</PrimaryButton>
              </Flex>
            </Flex>
          </form>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
};
