import Flex from '@react-css/flex';
import * as Sentry from '@sentry/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectCompany } from 'store/selectors/me/company';
import Card from 'storybook/stories/cells/Card';
import PageNavigation from 'storybook/stories/cells/PageNavigation';
import Table from 'storybook/stories/cells/Table';
import Body from 'storybook/stories/molecules/Body';
import Button from 'storybook/stories/molecules/Button';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import styled from 'styled-components';
import { CommerceType } from 'types/models/company';
import type { Partner } from 'types/models/partner';
import { getPartners, reinvitePartner } from 'utils/api/partners';
import type ApiError from 'utils/ApiError';
import { prettyDate } from 'utils/date';

const LIMIT = 10;

const TableContainer = styled.div`
  overflow-y: auto;
  max-height: 512px;
`;

const PaddedIcon = styled(Icon)`
  margin-right: 12px;
`;

type InvitedPartnerProps = {
  partner: Partner;
  isSeller: boolean;
};

const InvitedPartner = ({ partner, isSeller }: InvitedPartnerProps) => {
  const alertQueue = useAlertQueue();

  const resendInvite = useMutation({
    mutationFn: () => reinvitePartner(partner._id),
    onSuccess: () => {
      alertQueue.addSuccessAlert('Success', 'Invitation has been resent');
    },
    onError: (error: ApiError) => {
      if (error.isUnprocessableEntity) {
        alertQueue.addErrorAlert(
          'Error',
          'You must wait at least 8 hours before sending another reminder'
        );
      } else {
        alertQueue.addErrorAlert('Error', 'Failed to send reminder');
        Sentry.captureException(error);
      }
    },
  });

  return (
    <Table.TR>
      <Table.TD truncate>
        <Flex column gap="4px">
          <Link to={`/partners/${partner._id}`}>
            <Body variant="Body/Body Small">
              {isSeller ? partner.buyerName : partner.sellerName}
            </Body>
          </Link>
          <Body variant="Body/Caption" color="gray500">
            {prettyDate(partner.created)}
          </Body>
        </Flex>
      </Table.TD>
      <Table.TD align="right">
        <PaddedIcon
          name="email"
          color="bodyTextLinks"
          as={Button}
          onClick={() => resendInvite.mutate()}
        />
      </Table.TD>
    </Table.TR>
  );
};

const PendingInvitesWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Card>
      <Flex column gap="16px">
        <Heading variant="Headings/H2">Pending Invites</Heading>
        {children}
      </Flex>
    </Card>
  );
};

const PartnerOutboundInvites = () => {
  const company = useSelector(selectCompany);
  const isSeller = company?.commerceType === CommerceType.Seller;
  const { addErrorAlert } = useAlertQueue();
  const [page, setPage] = useState(0);

  const { data: partnersNotSignedUpResponse, isLoading } = useQuery({
    queryKey: ['getPartners', { signedUp: 'false', page, limit: LIMIT }],
    queryFn: () => getPartners({ signedUp: 'false', page, limit: LIMIT }),
    onError: () => {
      addErrorAlert('Error', 'Failed to load pending invites');
    },
  });

  const invitedPartners = partnersNotSignedUpResponse?.data;

  if (isLoading) return <PendingInvitesWrapper>Loading</PendingInvitesWrapper>;

  if (invitedPartners?.length === 0) return null;

  const hasNextPage = invitedPartners?.length === LIMIT;
  const hasPreviousPage = page > 0;

  return (
    <PendingInvitesWrapper>
      <Body variant="Body/Body Small" color="bodyTextSecondary">
        These partners have been invited but haven&apos;t created an account yet.
      </Body>
      <TableContainer>
        <Table variant="list">
          <Table.THead>
            <Table.TR>
              <Table.TH>Partner Name</Table.TH>
              <Table.TH align="right">Remind</Table.TH>
            </Table.TR>
          </Table.THead>
          <Table.TBody>
            {invitedPartners?.map((partner) => (
              <InvitedPartner key={partner._id} partner={partner} isSeller={isSeller} />
            ))}
          </Table.TBody>
        </Table>
      </TableContainer>
      {(hasNextPage || hasPreviousPage) && (
        <Flex justifyEnd>
          <PageNavigation
            hasNext={hasNextPage}
            hasPrevious={hasPreviousPage}
            onNextClick={() => {
              setPage(page + 1);
            }}
            onPreviousClick={() => {
              setPage(page - 1);
            }}
          />
        </Flex>
      )}
    </PendingInvitesWrapper>
  );
};

export default PartnerOutboundInvites;
