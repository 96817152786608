import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  getAgreement as getAgreementFromAPI,
  getEmbeddedAgreement as getEmbeddedAgreementFromAPI,
} from 'utils/api/agreements';

export const getAgreement = createAsyncThunk('agreements/getAgreement', (agreementId) => {
  return getAgreementFromAPI(agreementId);
});

export const getEmbeddedAgreement = createAsyncThunk(
  'agreements/getEmbeddedAgreement',
  (agreementId) => {
    return getEmbeddedAgreementFromAPI(agreementId);
  }
);

const agreementsSlice = createSlice({
  name: 'agreements',
  initialState: {
    agreement: {},
    embeddedData: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgreement.fulfilled, (draft, action) => {
        draft.agreement = action.payload.data;
      })
      .addCase(getEmbeddedAgreement.fulfilled, (draft, action) => {
        draft.embeddedData = action.payload.data;
      });
  },
});

export default agreementsSlice.reducer;
