import Flex from '@react-css/flex';
import useFloatingChatPrompt from 'hooks/useFloatingChatPrompt';
import RequestQuoteSVG from 'images/request_quote.svg';
import { useEffect } from 'react';
import NoResultsCard from 'storybook/stories/cells/Card/NoResultsCard';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

export interface EmptyPriceListsPageProps {
  hasProducts: boolean;
}

const EmptyPriceListsPage = ({ hasProducts }: EmptyPriceListsPageProps) => {
  const { tryPrompting } = useFloatingChatPrompt();

  useEffect(() => {
    tryPrompting();
  }, [tryPrompting]);

  return (
    <NoResultsCard
      data-testid="empty-page"
      imageSrc={RequestQuoteSVG}
      title={hasProducts ? 'Create a price list' : "You'll need some products first"}
      description={
        hasProducts
          ? 'Click the button below to start creating your first price list.'
          : 'Click the button below to visit the Products page to complete this step.'
      }
    >
      <Flex gap="16px">
        <SecondaryButton as={SupportLink} article="ia2nwgi8be" $iconName="help">
          Guide
        </SecondaryButton>

        {hasProducts ? (
          <PrimaryButton as={Link} to="/prices/create" $iconName="attach_money">
            Create Price List
          </PrimaryButton>
        ) : (
          <PrimaryButton as={Link} to="/products" $iconName="widgets">
            Go to Products
          </PrimaryButton>
        )}
      </Flex>
    </NoResultsCard>
  );
};

export default EmptyPriceListsPage;
