import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'store';

export const ACTIVE_ALERT_SIZE = 3;

const selectAlertQueue = (state: RootState) => state.alerts.queue;

export const selectActiveAlerts = createSelector(selectAlertQueue, (queue) =>
  queue.slice(ACTIVE_ALERT_SIZE * -1)
);
