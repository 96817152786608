import { useAppSelector } from 'store';
import {
  selectCompany,
  selectHasRequiredShopifyPermissions,
  selectIsIntegratedWithShopify,
} from 'store/selectors/me/company';
import Announcement from 'storybook/stories/cells/Announcement';
import { getShopifyAuthUrl, getShopifyStore } from 'utils/platform';

enum AnnouncementKind {
  None,
  Shopify,
}

const useAnnouncementToDisplay = () => {
  const isIntegratedWithShopify = useAppSelector(selectIsIntegratedWithShopify);
  const hasRequiredShopifyPermissions = useAppSelector(selectHasRequiredShopifyPermissions);

  if (isIntegratedWithShopify && !hasRequiredShopifyPermissions) {
    return AnnouncementKind.Shopify;
  }

  return AnnouncementKind.None;
};

const ShopifyAnnouncement = () => {
  const company = useAppSelector(selectCompany);

  const store = getShopifyStore(company);
  const shopifyAuthUrl = getShopifyAuthUrl(store);

  return (
    <Announcement
      kind="action"
      title="Action Required"
      ctaButtonText="Connect"
      onCtaButtonClick={() => window.open(shopifyAuthUrl, '_blank', 'noopener,noreferrer')}
    >
      You need to update your Shopify connection to continue syncing products and orders.
    </Announcement>
  );
};

const Announcements = () => {
  const announcementToDisplay = useAnnouncementToDisplay();

  switch (announcementToDisplay) {
    case AnnouncementKind.Shopify:
      return <ShopifyAnnouncement />;
    default:
      return null;
  }
};

export default Announcements;
