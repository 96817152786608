import Flex from '@react-css/flex';
import { filter, isEmpty } from 'lodash';
import Chip, { type ChipVariants } from 'storybook/stories/molecules/Chip';

const MAXIMUM_VISIBLE_CHIP_COUNT = 5;

interface ChipListProps {
  /**
   * List of items to display as chips
   */
  items: string[];
  /**
   * Maximum number of chips to display. Pass `false` to display all chips.
   * @default 5
   */
  max?: number | false;
  /**
   * The visual presentation of the UI
   */
  variant?: `${ChipVariants}`;
}

/**
 * A `ChipList` provides layout for a list `string[]` items as `Chip` components.
 * It will filter out empty values in the `items` array.
 */
const ChipList = ({
  items = [],
  max = MAXIMUM_VISIBLE_CHIP_COUNT,
  variant = 'default',
}: ChipListProps) => {
  const filteredItems = filter(items, (item) => !isEmpty(item));

  if (filteredItems.length === 0) return null;

  const hasTooManyItems = max !== false && filteredItems.length > max;
  const visibleItems = hasTooManyItems ? filteredItems.slice(0, max - 1) : filteredItems;

  return (
    <Flex alignItemsCenter wrap gap="8px">
      {visibleItems.map((item) => (
        <Chip key={item} variant={variant} size="small">
          {item}
        </Chip>
      ))}

      {hasTooManyItems && (
        <Chip variant={variant} size="small">
          +{filteredItems.length - max + 1}
        </Chip>
      )}
    </Flex>
  );
};

export default ChipList;
