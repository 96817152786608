import { useMutation } from '@tanstack/react-query';
import Button from 'components/Common/Button';
import DoneCheckmark from 'components/Common/DoneCheckmark';
import download from 'downloadjs';
import useAlertQueue from 'hooks/useAlertQueue';
import useScopedSentryMessage from 'hooks/useScopedSentryMessage';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import reactStringReplace from 'react-string-replace';
import { resetConsumableAction, uploadFileToAction } from 'store/slices/partners';
import styled from 'styled-components';
import { getDocument } from 'utils/api/documents';

const FileInputWrapper = styled.div`
  padding-left: 0rem;
  padding-bottom: 1.25rem;
`;
const HintText = styled.p`
  margin-bottom: 0.188rem;
`;
const UploadedText = styled.p`
  margin-bottom: 0rem;
`;
const ViewOptionalDocButton = styled.button`
  background-color: rgba(0, 0, 0, 0);
  border-width: 0px;
  padding: 0rem;
  text-decoration: underline;
  color: #2c7be5;
`;

const getDescriptionFormatted = (cardText, optionalDocId, onViewOptionalDocument) => {
  if (!optionalDocId) {
    return [cardText, false];
  }
  const VALID_KEYWORDS = [
    'our document',
    'our form',
    'this document',
    'this form',
    'our template',
    'this template',
  ];
  for (let i = 0; i < VALID_KEYWORDS.length; i += 1) {
    const keyword = VALID_KEYWORDS[i];
    if (cardText.includes(keyword)) {
      return [
        <span>
          {reactStringReplace(cardText, keyword, () => (
            <ViewOptionalDocButton onClick={onViewOptionalDocument}>
              {keyword}
            </ViewOptionalDocButton>
          ))}
        </span>,
        false,
      ];
    }
  }

  return [cardText, true];
};

const FileUploadAction = ({ action, disable, refreshPage }) => {
  const dispatch = useDispatch();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [error, setError] = useState('');
  const { cardTitle, cardText, required, documentId, optionalDocId, completed } = action;
  const alertQueue = useAlertQueue();
  const sendSentryError = useScopedSentryMessage('error');

  const downloadingDocument = useMutation({
    mutationFn: () => getDocument(documentId),
    onSuccess: async (blob) => {
      download(blob);
    },
    onError: (apiError) => {
      setError('Unable to download file');
      alertQueue.addErrorAlert('Something went wrong', 'Please contact support@convictional.com');
      sendSentryError('Unable to download file', { extra: { apiError } });
    },
  });

  const onFileUpload = (f) => {
    if (disable) {
      return;
    }
    setUploadingFile(true);
    const reader = new FileReader();
    reader.onload = () => {
      const bytes = Array.from(new Uint8Array(reader.result));
      dispatch(
        uploadFileToAction({
          bytes,
          partnerId: action.partnerId,
          actionId: action.id,
          fileType: f.type,
          fileName: f.name,
        })
      ).then(() => {
        // Refresh the page data
        setUploadingFile(false);
        if (refreshPage) {
          refreshPage();
        }
      });
    };
    reader.onerror = (err) => {
      console.warn(err);
    };
    reader.readAsArrayBuffer(f);
  };

  const onResetAction = (e) => {
    e.preventDefault();

    dispatch(resetConsumableAction(action.id)).then(() => {
      // Refresh the page data
      if (refreshPage) {
        refreshPage();
      }
    });
  };

  const onViewOptionalDocument = (e) => {
    e.preventDefault();

    if (!disable) {
      downloadingDocument.mutate();
    }
  };

  const [descriptionContent, showDownloadOptionalDocument] = getDescriptionFormatted(
    cardText,
    optionalDocId,
    onViewOptionalDocument
  );
  return (
    <div className="single-action-wrapper align-items-center row">
      <div className="col-auto">
        <DoneCheckmark done={completed} />
      </div>
      <div className="ml-n2 col">
        {error && <div className="alert alert-danger alert-dismissible fade show">{error}</div>}
        <h4 className="mb-2">
          <span className="single-action-title">{cardTitle}</span>
          {required ? null : <span className="badge badge-soft-info">Optional</span>}
          {showDownloadOptionalDocument && (
            <Button size="sm" outline color="secondary" onClick={onViewOptionalDocument}>
              View Document
            </Button>
          )}
        </h4>
        <HintText className="text-muted single-action-description">{descriptionContent}</HintText>
        {completed ? (
          <FileInputWrapper className="col-12 col-md-6">
            <UploadedText className="small">
              File has been uploaded.{' '}
              {documentId && (
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  className="file-upload-reset-btn"
                  onClick={onResetAction}
                >
                  Reset
                </Button>
              )}
            </UploadedText>
          </FileInputWrapper>
        ) : (
          <FileInputWrapper className="col-12 col-md-6">
            {uploadingFile ? (
              <UploadedText className="text-muted small">Uploading...</UploadedText>
            ) : (
              <input type="file" onChange={(e) => onFileUpload(e.target.files[0])} />
            )}
          </FileInputWrapper>
        )}
      </div>
    </div>
  );
};

export default FileUploadAction;
