import Grid from '@react-css/grid';
import { Card } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';

import DoneCheckmark from 'components/Common/DoneCheckmark';

const loadKeyframe = keyframes`
  from {
    left: -3rem;
  }
  to {
    left: 100%;
  }
`;

const loadKeyframeDouble = keyframes`
  from {
    left: -3rem;
  }
  to {
    left: 200%;
  }
`;

const LoadingText = styled.div`
  height: 0.9375rem;
  margin-bottom: 0.5rem;
  background: var(--light);
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 3rem;
    top: 0;
    height: 100%;
    width: 3rem;
    background: linear-gradient(to right, transparent 0%, white 50%, transparent 100%);
    animation: ${loadKeyframe} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
`;
const EmptyTitle = styled(LoadingText)`
  width: 50%;
  &::before {
    animation: ${loadKeyframeDouble} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
`;

const EmptySubtitle = styled(LoadingText)`
  height: 0.5rem;
  margin-bottom: 0.25rem;
`;

const EmptyButton = styled.div`
  height: 1.825rem;
  background: var(--light);
  border-radius: 0.25rem;
  width: 6rem;
`;

const LoadingCard = () => {
  return (
    <Card body className="mb-3" data-testid="loading-card">
      <Grid columns="30px auto 100px" gap="16px" className="align-items-center">
        <Grid.Item>
          <DoneCheckmark done={false} />
        </Grid.Item>
        <Grid.Item>
          <EmptyTitle />
          <EmptySubtitle />
          <EmptySubtitle />
        </Grid.Item>
        <Grid.Item>
          <EmptyButton />
        </Grid.Item>
      </Grid>
    </Card>
  );
};

export default LoadingCard;
