import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from 'store';
import type { Partner } from 'types/models/partner';
import type { Partners } from 'types/redux';
import {
  completeConsumableAction as completeConsumableActionFromAPI,
  createConsumableAction as createConsumableActionFromAPI,
  deleteConsumableAction as deleteConsumableActionFromAPI,
  listConsumableActions as listConsumableActionsFromAPI,
  resetConsumableAction as resetConsumableActionFromAPI,
  updateConsumableAction as updateConsumableActionFromAPI,
  type CreateConsumableActionBody,
  type ListConsumableActionsQuery,
  type UpdateConsumableActionBody,
} from 'utils/api/actions';
import {
  createConsumableActionTemplate as createConsumableActionTemplateFromAPI,
  deleteConsumableActionTemplate as deleteConsumableActionTemplateFromAPI,
  listConsumableActionTemplates as listConsumableActionTemplatesFromAPI,
  updateConsumableActionTemplate as updateConsumableActionTemplateFromAPI,
  type CreateConsumableActionTemplateBody,
  type ListConsumableActionTemplatesQuery,
  type UpdateConsumableActionTemplateBody,
} from 'utils/api/actionTemplates';
import { uploadFile, uploadImage, type UploadFileParams } from 'utils/api/documents';
import {
  deletePartner as deletePartnerFromAPI,
  getPartner as getPartnerFromAPI,
  getPartners as getPartnersFromAPI,
  invitePartner as invitePartnerFromAPI,
  updatePartner as updatePartnerFromAPI,
  type GetPartnersParams,
  type InvitePartnerParams,
  type UpdatePartnerParams,
} from 'utils/api/partners';

// Define a type for the slice state
const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

export const getPartner = createAppAsyncThunk('partners/getPartner', (partnerId: string) => {
  return getPartnerFromAPI(partnerId, {});
});

export const getPartners = createAppAsyncThunk(
  'partners/getPartners',
  (params: GetPartnersParams) => {
    return getPartnersFromAPI(params);
  }
);

export const getPendingPartners = createAppAsyncThunk('partners/getPendingPartners', () => {
  return getPartnersFromAPI({ pending: true });
});

export const addPartner = createAppAsyncThunk(
  'partners/addPartner',
  (body: InvitePartnerParams) => {
    return invitePartnerFromAPI(body);
  }
);

export const deletePartner = createAppAsyncThunk('partners/deletePartner', (partnerId: string) => {
  return deletePartnerFromAPI(partnerId);
});

export const updatePartner = createAppAsyncThunk(
  'partners/updatePartner',
  ({ partnerId, body }: { partnerId: string; body: UpdatePartnerParams }) => {
    return updatePartnerFromAPI(partnerId, body);
  }
);

export const getActionsSent = createAppAsyncThunk(
  'consumableActions/getActionsSent',
  (params: ListConsumableActionsQuery) => {
    return listConsumableActionsFromAPI(params);
  }
);

export const getActionsReceived = createAppAsyncThunk(
  'consumableActions/getActionsReceived',
  (params: ListConsumableActionsQuery) => {
    return listConsumableActionsFromAPI(params);
  }
);

export const getAllActionsReceived = createAppAsyncThunk(
  'consumableActions/getAllActionsReceived',
  (params: ListConsumableActionsQuery) => {
    return listConsumableActionsFromAPI(params);
  }
);

export const createConsumableAction = createAppAsyncThunk(
  'consumableActions/createConsumableAction',
  (body: CreateConsumableActionBody) => {
    return createConsumableActionFromAPI(body);
  }
);

export const updateConsumableAction = createAppAsyncThunk(
  'consumableActions/updateConsumableActions',
  ({ actionId, body }: { actionId: string; body: UpdateConsumableActionBody }) => {
    return updateConsumableActionFromAPI(actionId, body);
  }
);

export const deleteConsumableAction = createAppAsyncThunk(
  'consumableActions/deleteConsumableAction',
  (actionId: string) => {
    return deleteConsumableActionFromAPI(actionId);
  }
);

interface UploadFileToActionParams extends UploadFileParams {
  partnerId: string;
  partnerName: string;
  actionId: string;
}

export const uploadFileToAction = createAppAsyncThunk(
  'consumableActions/uploadFileToAction',
  (params: UploadFileToActionParams) => {
    const { bytes, partnerId, partnerName, actionId, fileName, fileType } = params;
    const apiEndpoint = fileType.split('/')[0] === 'image' ? uploadImage : uploadFile;

    // Uploads document
    return apiEndpoint({
      bytes,
      fileName,
      fileType,
      sharedWithCompany: true, // Share with other team members
      partnersWithViewAccess: [{ partnerId, name: partnerName }], // Share with partner
    })
      .then((response) => {
        const { documentId } = response.data;
        return completeConsumableActionFromAPI(actionId, {
          documentId,
        });
      })
      .catch((error) => {
        console.error('Error uploading document', error);
      });
  }
);

export const acknowledgeConsumableAction = createAppAsyncThunk(
  'consumableActions/acknowledgeConsumableAction',
  (actionId: string) => {
    return completeConsumableActionFromAPI(actionId, {});
  }
);

export const resetConsumableAction = createAppAsyncThunk(
  'consumableActions/resetConsumableAction',
  (actionId: string) => {
    return resetConsumableActionFromAPI(actionId);
  }
);

export const listConsumableActionTemplates = createAppAsyncThunk(
  'consumableActionTemplates/listConsumableActionTemplates',
  (query: ListConsumableActionTemplatesQuery) => {
    return listConsumableActionTemplatesFromAPI(query);
  }
);

export const createConsumableActionTemplate = createAppAsyncThunk(
  'consumableActions/createConsumableActionTemplate',
  (body: CreateConsumableActionTemplateBody) => {
    return createConsumableActionTemplateFromAPI(body);
  }
);

export const deleteConsumableActionTemplate = createAppAsyncThunk(
  'consumableActions/deleteConsumableActionTemplate',
  (actionTemplateId: string) => {
    return deleteConsumableActionTemplateFromAPI(actionTemplateId);
  }
);

export const updateConsumableActionTemplate = createAppAsyncThunk(
  'consumableActions/updateConsumableActionTemplate',
  ({
    actionTemplateId,
    body,
  }: {
    actionTemplateId: string;
    body: UpdateConsumableActionTemplateBody;
  }) => {
    return updateConsumableActionTemplateFromAPI(actionTemplateId, body);
  }
);

const initialState: Partners = {
  partners: {}, // List of partners with all details. partners[_id] = { ...Partner... }
  pendingPartners: [], // List of incomplete partners associated with only the authenticated user
  partner: null,
  partnerIds: [],
  actionsSent: [],
  actionsReceived: [],
  allActionsReceived: [], // All actions received from all partners
  creatingAction: false,
  onboardingSteps: [],
  error: null,
  inviting: false, // The inviting API call is currently out
  deleting: false, // The delete API call is currently out
  loadingPartners: false,
  loadingPendingPartners: false,
  actionTemplates: [],
  loadingActionTemplates: false,
  editingActionTemplate: false,
  uploadingFileForAction: false,
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setPartners: (draft, action) => {
      draft.partners = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPartners.pending, (draft) => {
        draft.loadingPartners = true;
      })
      .addCase(getPartners.rejected, (draft) => {
        draft.loadingPartners = false;
      })
      .addCase(getPartners.fulfilled, (draft, action) => {
        const { data } = action.payload;
        const partnersObj: Record<string, Partner> = {};
        const partnerIds = [];
        for (let i = 0; i < data.length; i += 1) {
          const partner = data[i];
          partnersObj[partner._id] = partner;
          partnerIds.push(partner._id);
        }
        draft.partners = partnersObj;
        draft.partnerIds = partnerIds;
        draft.loadingPartners = false;
      })
      .addCase(getPendingPartners.pending, (draft) => {
        draft.loadingPendingPartners = true;
      })
      .addCase(getPendingPartners.rejected, (draft) => {
        draft.loadingPendingPartners = false;
      })
      .addCase(getPendingPartners.fulfilled, (draft, action) => {
        const { data } = action.payload;
        draft.pendingPartners = data;
        draft.loadingPendingPartners = false;
      })
      .addCase(getPartner.fulfilled, (draft, action) => {
        draft.partner = action.payload;
      })
      .addCase(addPartner.pending, (draft) => {
        draft.inviting = true;
      })
      .addCase(addPartner.rejected, (draft) => {
        draft.inviting = false;
      })
      .addCase(addPartner.fulfilled, (draft, action) => {
        const partner = action.payload;

        draft.partners[partner._id] = partner;
        draft.partnerIds.unshift(partner._id);
      })
      .addCase(getActionsSent.fulfilled, (draft, action) => {
        draft.actionsSent = action.payload.data;
      })
      .addCase(getActionsReceived.fulfilled, (draft, action) => {
        draft.actionsReceived = action.payload.data;
      })
      .addCase(getAllActionsReceived.fulfilled, (draft, action) => {
        draft.allActionsReceived = action.payload.data;
      })
      .addCase(createConsumableAction.fulfilled, (draft) => {
        draft.creatingAction = false;
      })
      .addCase(createConsumableAction.pending, (draft) => {
        draft.creatingAction = true;
      })
      .addCase(createConsumableAction.rejected, (draft) => {
        draft.creatingAction = false;
      })
      .addCase(uploadFileToAction.pending, (draft) => {
        draft.uploadingFileForAction = true;
      })
      .addCase(uploadFileToAction.rejected, (draft) => {
        draft.uploadingFileForAction = false;
      })
      .addCase(uploadFileToAction.fulfilled, (draft, action) => {
        draft.uploadingFileForAction = false;
        draft.allActionsReceived.forEach((_, index) => {
          if (draft.allActionsReceived[index].id === action.meta.arg.actionId) {
            draft.allActionsReceived[index].completed = true;
          }
        });
      })
      .addCase(acknowledgeConsumableAction.fulfilled, (draft, action) => {
        draft.allActionsReceived.forEach((_, index) => {
          if (draft.allActionsReceived[index].id === action.meta.arg) {
            draft.allActionsReceived[index].completed = true;
          }
        });
      })
      .addCase(listConsumableActionTemplates.pending, (draft) => {
        draft.loadingActionTemplates = true;
      })
      .addCase(listConsumableActionTemplates.rejected, (draft) => {
        draft.loadingActionTemplates = false;
      })
      .addCase(listConsumableActionTemplates.fulfilled, (draft, action) => {
        draft.loadingActionTemplates = false;
        draft.actionTemplates = action.payload.data;
      })
      .addCase(updateConsumableActionTemplate.pending, (draft) => {
        draft.editingActionTemplate = true;
      })
      .addCase(updateConsumableActionTemplate.rejected, (draft) => {
        draft.editingActionTemplate = false;
      })
      .addCase(updateConsumableActionTemplate.fulfilled, (draft) => {
        draft.editingActionTemplate = false;
      });
  },
});

export default partnersSlice.reducer;
