import type { BaseQueryParams, Response } from 'types/api';
import type { PartnerAction, Terms } from 'types/models/partner-action';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'utils/request';

export interface ListConsumableActionsQuery extends BaseQueryParams {
  completed?: boolean;
  hasPartner?: boolean;
  assignedTo?: string;
  assignedFrom?: string;
  partnerId?: string;
}

export type ListConsumableActionsResponse = Response<PartnerAction[]>;

export const listConsumableActions = (
  query: ListConsumableActionsQuery
): Promise<ListConsumableActionsResponse> => getRequest(`/actions/`, query);

export interface CreateConsumableActionBody {
  cardTitle: string;
  cardText: string;
  kind: string;
  required: boolean;
  partnerId: string;
  agreementId?: string;
  documentId?: string;
  optionalDocId?: string;
  terms?: Terms;
}

export type CreateConsumableActionResponse = Response<{ id: string }>;

export const createConsumableAction = (
  body: CreateConsumableActionBody
): Promise<CreateConsumableActionResponse> => postRequest(`/actions/`, body);

export const deleteConsumableAction = (actionId: string): Promise<Response<null>> =>
  deleteRequest(`/actions/${actionId}`);

export interface CompleteConsumableActionBody {
  documentId?: string;
}

export const completeConsumableAction = (
  actionId: string,
  body: CompleteConsumableActionBody
): Promise<Response<null>> => {
  return patchRequest(`/actions/${actionId}/status`, { ...body, status: 'complete' });
};

export const resetConsumableAction = (actionId: string): Promise<Response<null>> =>
  patchRequest(`/actions/${actionId}/status`, { status: 'reset' });

export interface UpdateConsumableActionBody {
  cardTitle?: string;
  cardText?: string;
  required?: boolean;
}

export const updateConsumableAction = (
  actionId: string,
  body: UpdateConsumableActionBody
): Promise<Response<{ signed: boolean }>> => patchRequest(`/actions/${actionId}`, body);

export const syncAction = (actionId: string) => patchRequest(`/actions/${actionId}/sync`, {});
