const CreateActionTextarea = ({ id, label, placeholder, value, setValue }) => (
  <div className="form-group">
    <label htmlFor={`create-card-${id}`}>{label}</label>
    <textarea
      className="form-control"
      placeholder={placeholder}
      id={`create-card-${id}`}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  </div>
);

export default CreateActionTextarea;
