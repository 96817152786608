import { isRejected } from '@reduxjs/toolkit';
import DoneCheckmark from 'components/Common/DoneCheckmark';
import Message from 'components/Common/Message';
import { Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'store';
import { createCSVMappings } from 'store/slices/sftpUser';
import Modal from 'storybook/stories/cells/Modal';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import type { SftpUserSlice } from 'types/models/sftp';
import { convictionalFields } from 'utils/csvMappings';
import { useSafeState } from 'utils/hooks';

const CSVMappingErrorMessage = ({ hasEmptyFileHeaders }: { hasEmptyFileHeaders: boolean }) => (
  <Message kind="error" show animate={false} onClose={() => {}}>
    {hasEmptyFileHeaders ? (
      <p>
        Your selected file(s) contains empty column headers. Please add in column headers and try
        again.
      </p>
    ) : (
      <p>
        We found your selected file(s) but were unable to extract its contents. Verify that the file
        selected has the appropriate data to enable product syncing.
      </p>
    )}
  </Message>
);

const CSVMappingErrorHelp = ({ resetModal }: { resetModal: () => void }) => (
  <div className="d-flex justify-content-center mt-4">
    <SecondaryButton as={SupportLink} article="delewtxyn5#csv_mappings_errors" className="mr-3">
      <Icon name="help">Product File Requirements</Icon>
    </SecondaryButton>

    <PrimaryButton onClick={resetModal}>
      <Icon name="edit_note">Reselect Product File(s)</Icon>
    </PrimaryButton>
  </div>
);

const CSVMappingsForm = () => {
  const {
    productFileHeaders,
    productFileSampleData,
    productFileHeaderToIndex,
    inventoryFileSelected,
    inventoryFileHeaders,
    inventoryFileSampleData,
    inventoryFileHeaderToIndex,
  }: SftpUserSlice = useSelector(({ sftpUser }: RootState) => sftpUser);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();
  const [mappingErrors, setMappingErrors] = useSafeState<Array<{ message: string }>>([]);
  const dispatch = useDispatch();

  const submitMappings = (data: { [key: string]: string }) => {
    // @ts-ignore
    dispatch(createCSVMappings(data)).then(
      (action: { payload: { errors?: [{ message: string }]; message: string } }) => {
        setMappingErrors([]);
        if (isRejected(createCSVMappings)(action)) {
          // list of response errors can be found at action.payload.errors
          setMappingErrors(action.payload.errors || [{ message: action.payload.message }]);
        }
      }
    );
  };
  // Note: data from the backend comes in the form of a nested array of strings (array of row values from csv file),
  // this function will go through all rows and extract the appropriate column values
  const displaySampleData = (convictionalField: string, fileHeader: string) => {
    let sampleData;
    let numEmptyRows = 0;
    if (inventoryFileSelected && convictionalField === 'inventory') {
      sampleData = inventoryFileSampleData.map((inventoryFileSampleRow, idx) => {
        const headerIndex = inventoryFileHeaderToIndex[fileHeader];
        if (inventoryFileSampleRow[headerIndex]) {
          // limit preview to 100 characters
          if (inventoryFileSampleRow[headerIndex].length > 100) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`inventory-${fileHeader}-${headerIndex}-${idx}`}>
                {`${inventoryFileSampleRow[headerIndex].substring(0, 100)}...`}
              </li>
            );
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`inventory-${fileHeader}-${headerIndex}-${idx}`}>
              {inventoryFileSampleRow[headerIndex]}
            </li>
          );
        }
        numEmptyRows += 1;
        // eslint-disable-next-line react/no-array-index-key
        return <li key={`inventory-${fileHeader}-${headerIndex}-${idx}`}>Row value is empty</li>;
      });
    } else {
      sampleData = productFileSampleData.map((productFileSampleRow, idx) => {
        const headerIndex = productFileHeaderToIndex[fileHeader];
        if (productFileSampleRow[headerIndex]) {
          // limit preview to 100 characters
          if (productFileSampleRow[headerIndex].length > 100) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`product-${fileHeader}-${headerIndex}-${idx}`}>
                {`${productFileSampleRow[headerIndex].substring(0, 100)}...`}
              </li>
            );
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`product-${fileHeader}-${headerIndex}-${idx}`}>
              {productFileSampleRow[headerIndex]}
            </li>
          );
        }
        numEmptyRows += 1;
        // eslint-disable-next-line react/no-array-index-key
        return <li key={`product-${fileHeader}-${headerIndex}-${idx}`}>Row value is empty</li>;
      });
    }
    // if no sample data available
    if (sampleData.length === 0 || numEmptyRows === productFileSampleData.length) {
      return (
        <ul>
          <li>No preview info available</li>
        </ul>
      );
    }
    return <ul>{sampleData}</ul>;
  };
  return (
    <form onSubmit={handleSubmit(submitMappings)}>
      <Table hover>
        <thead>
          <tr>
            <th style={{ width: '10%' }}>Status</th>
            <th style={{ width: '30%' }}>Modern Dropship Fields</th>
            <th style={{ width: '30%' }}>Column Header From File</th>
            <th style={{ width: '30%' }}>Preview Information</th>
          </tr>
        </thead>
        <tbody>
          {convictionalFields.map((field) => (
            <tr key={`mapping-row-${field.convictionalField}`}>
              <td style={{ textAlign: 'center' }}>
                {errors[field.name] && errors[field.name]?.message}
                {watch(field.name) && <DoneCheckmark done className="DoneCheckmarkCentered" />}
              </td>
              <td>
                <h5>
                  {field.convictionalField}
                  {field.required && ' (required)'}
                </h5>
                <p>{field.description}</p>
              </td>
              <td>
                <select
                  data-toggle="select"
                  className="form-control"
                  id={field.convictionalField}
                  defaultValue=""
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register(field.name, {
                    required: field.required && `${field.convictionalField} must have a mapping`,
                  })}
                >
                  <option key="mapping-key-empty" value="" id="mapping-option-empty">
                    ---
                  </option>

                  {field.name === 'inventory' && inventoryFileHeaders.length !== 0 ? (
                    <>
                      {inventoryFileHeaders.map((fileHeader) => (
                        <option
                          key={fileHeader}
                          value={fileHeader}
                          id={`mapping-option-${fileHeader}`}
                        >
                          {fileHeader}
                        </option>
                      ))}
                    </>
                  ) : (
                    <>
                      {productFileHeaders.map((fileHeader) => (
                        <option
                          key={fileHeader}
                          value={fileHeader}
                          id={`mapping-option-${fileHeader}`}
                        >
                          {fileHeader}
                        </option>
                      ))}
                    </>
                  )}
                </select>
              </td>
              <td style={{ wordBreak: 'break-word' }}>
                {watch(field.name) && displaySampleData(field.name, watch(field.name))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Message show={mappingErrors.length > 0} kind="error" className="mt-0" onClose={() => {}}>
        <p className="m-0 mb-1">
          <strong>
            CSV Mappings contains errors. For more details about errors and potential resolutions,
            please refer to this{' '}
            <SupportLink article="delewtxyn5#csv_mappings_errors">error guide</SupportLink>.
          </strong>
        </p>
        <div className="m-0">
          <ul className="list-group list-group-flush">
            {mappingErrors.map((err, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <li className="list-group-item" key={`errorkey${i}`}>
                  {err.message}{' '}
                </li>
              );
            })}
          </ul>
        </div>
      </Message>
      <div className="form-group mb-4">
        <PrimaryButton type="submit">Submit Mappings</PrimaryButton>
      </div>
    </form>
  );
};

const CreateCSVMappingsModal = ({
  showCSVMappingsModal,
  hideModal,
  resetModal,
}: {
  showCSVMappingsModal: boolean;
  hideModal: () => void;
  resetModal: () => void;
}) => {
  const {
    productFileHeaders,
    inventoryFileSelected,
    inventoryFileHeaders,
    hasEmptyFileHeaders,
    createdCSVMappings,
  } = useSelector(({ sftpUser }: RootState) => sftpUser);

  if (createdCSVMappings) {
    return (
      <Modal.Root
        show={showCSVMappingsModal}
        size="xl"
        aria-labelledby="products-csv-mappings-modal"
        centered
        onHide={hideModal}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create CSV Mappings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ lineHeight: 1.3 }}>
            Your mappings have been successfully created! Your products will be synced within the
            next 2 hours. Please check back again later.
          </h4>
        </Modal.Body>
      </Modal.Root>
    );
  }

  const hasCSVMappingErrors =
    productFileHeaders.length === 0 ||
    (inventoryFileSelected && inventoryFileHeaders.length === 0) ||
    hasEmptyFileHeaders;

  return (
    <Modal.Root
      show={showCSVMappingsModal}
      size="xl"
      aria-labelledby="products-csv-mappings-modal"
      centered
      onHide={hideModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create CSV Mappings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {hasCSVMappingErrors ? (
          <>
            <CSVMappingErrorMessage hasEmptyFileHeaders={hasEmptyFileHeaders} />
            <CSVMappingErrorHelp resetModal={resetModal} />
          </>
        ) : (
          <>
            <SecondaryButton color="white" className="btn-import mb-4" onClick={resetModal}>
              <Icon name="edit_note">Reselect Product File(s)</Icon>
            </SecondaryButton>

            <h4 className="mb-4" style={{ lineHeight: 1.3 }}>
              Select the appropriate mappings between your file headers and our Modern Dropship
              fields. Upon selecting a file header, sample data from the first 3 rows of that column
              will be displayed under &quot;Preview Information&quot;.
            </h4>
            <CSVMappingsForm />
          </>
        )}
      </Modal.Body>
    </Modal.Root>
  );
};

export default CreateCSVMappingsModal;
