import { postRequest } from 'utils/request';

export const sendFeedback = (message: string) =>
  postRequest('/support/request', {
    message,
    page: window.location.pathname,
    type: 'feedback',
  });

export const requestSupport = (message: string) =>
  postRequest('/support/request', {
    message,
    page: window.location.pathname,
    type: 'support',
  });

interface UserIndicatedInterestParams {
  bcc: string;
  itemOfInterest: string;
}

export const sendUserIndicatedInterestEmail = (data: UserIndicatedInterestParams) =>
  postRequest('/support/indicate-interest', data);

export interface SendPartnerFeedbackParams {
  feedback: string;
}

export type SendPartnerFeedbackResponse = void;

export const sendPartnerFeedback = (
  params: SendPartnerFeedbackParams
): Promise<SendPartnerFeedbackResponse> => postRequest('/support/partner-feedback', params);

export interface SendPartnerInviteRequestParams {
  requestedCompanyId: string;
  margin: string;
  traffic: string;
  links: string;
  pitch: string;
}

type SendPartnerInviteRequestResponse = void;

export const sendPartnerInviteRequest = (
  params: SendPartnerInviteRequestParams
): Promise<SendPartnerInviteRequestResponse> =>
  postRequest('/support/partner-invite-request', params);
