import styled from 'styled-components';

import SingleAction from 'containers/PartnerPage/Actions/SingleAction';

const PreviewWrapper = styled.div`
  padding: 0.625rem 0.938rem;
  margin-bottom: 0.938rem;
`;

const PreviewContentWrapper = styled.div`
  border: 1px solid #d2ddec;
  border-radius: 0.375rem;
  padding: 0.625rem 0.938rem;
`;

const EmptyPreviewWrapper = styled.div`
  padding: 1.875rem;
  padding-top: 7.813rem;
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const PreviewCreateActionCard = ({ title, text, kind, required, optionalDoc }) => {
  if (title === '') {
    return (
      <EmptyPreviewWrapper>
        <h3 className="mb-2 create-action-preview-title">Action Preview</h3>
        <p className="text-muted create-action-preview-description">
          Begin adding a title and we will show you what the action will look like for your partner.
        </p>
      </EmptyPreviewWrapper>
    );
  }

  return (
    <PreviewWrapper>
      <h3 className="mb-2 create-action-preview-title">Action Preview</h3>
      <p className="text-muted create-action-preview-description">
        Below you will see a sample of the action that your partner will see. The circle on the left
        of the action will change to a checkmark once it has been completed.
      </p>
      <PreviewContentWrapper>
        <SingleAction
          disable
          action={{
            cardTitle: title,
            cardText: text,
            required,
            kind,
            optionalDocId: optionalDoc ? 'demodocumentId' : '',
          }}
        />
      </PreviewContentWrapper>
    </PreviewWrapper>
  );
};

export default PreviewCreateActionCard;
