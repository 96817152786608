import Grid from '@react-css/grid';
import PrivateRoute from 'containers/App/PrivateRoute';
import ProtectedRoute from 'containers/App/ProtectedRoute';
import AccountSettings from 'containers/SettingsPage/PreferencesTab/AccountSettings';
import AdminSettings from 'containers/SettingsPage/PreferencesTab/AdminSettings';
import SecuritySettings from 'containers/SettingsPage/PreferencesTab/SecuritySettings';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { useAppSelector } from 'store';
import { selectIsSuperUser } from 'store/selectors/me/user';
import Card from 'storybook/stories/cells/Card';
import Menu from 'storybook/stories/cells/Menu';
import styled from 'styled-components';

const MenuWrapper = styled(Card)`
  padding: 24px;
  position: sticky;
  top: 24px;
`;

enum SectionSegments {
  Account = 'account',
  Security = 'security',
  Admin = 'admin',
}

const Preferences = () => {
  const { section } = useParams<{ section?: SectionSegments }>();
  const isSuperUser = useAppSelector(selectIsSuperUser);

  const selectedSection =
    section && Object.values(SectionSegments).includes(section) ? section : SectionSegments.Account;

  return (
    <Grid columns="240px 1fr" gap="24px">
      <Grid.Item>
        <MenuWrapper>
          <Menu>
            <Menu.Link
              $iconName="account_circle"
              $isActive={selectedSection === SectionSegments.Account}
              to="/settings/preferences/account"
            >
              Account
            </Menu.Link>

            <Menu.Link
              $iconName="lock"
              $isActive={selectedSection === SectionSegments.Security}
              to="/settings/preferences/security"
            >
              Security
            </Menu.Link>

            {isSuperUser && (
              <Menu.Link
                $iconName="admin_panel_settings"
                $isActive={selectedSection === SectionSegments.Admin}
                to="/settings/preferences/admin"
              >
                Admin
              </Menu.Link>
            )}
          </Menu>
        </MenuWrapper>
      </Grid.Item>

      <Card>
        <Switch>
          <PrivateRoute path="/settings/preferences/account" component={AccountSettings} />
          <PrivateRoute path="/settings/preferences/security" component={SecuritySettings} />
          <ProtectedRoute
            isAllowed={isSuperUser}
            redirectTo="/settings/preferences/account"
            path="/settings/preferences/admin"
            component={AdminSettings}
          />

          <Route path="*">
            <Redirect to="/settings/preferences/account" />
          </Route>
        </Switch>
      </Card>
    </Grid>
  );
};

export default Preferences;
