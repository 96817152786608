import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { updateWooCommerceAuth } from 'store/slices/company';
import { useSafeState } from 'utils/hooks';

import Flex from '@react-css/flex';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

const ConnectWooCommerceCard = ({ done, storeUrl }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [error, setError] = useSafeState('');
  const pending = useSelector(({ company }) => company.isUpdatingPlatformAuth);

  const onSubmit = ({ wooCommerceKey, wooCommerceSecret }) => {
    setError('');
    dispatch(
      updateWooCommerceAuth({
        wooCommerceAuth: {
          platform: 'woocommerce',
          platformUrl: storeUrl,
          wooCommerceKey,
          wooCommerceSecret,
        },
      })
    ).then((action) => {
      if (action.error) {
        setError(action.error.message);
      }
    });
  };

  return (
    <GetStartedCard
      isComplete={done}
      title="Connect your WooCommerce store"
      completedUi={
        <Body>
          Manage your integration from your{' '}
          <Link to="/settings/dropshipping/integrations">Integration settings</Link>.
        </Body>
      }
    >
      <Body as="p">
        Please input your WooCommerce credentials so Modern Dropship can connect to your store.{' '}
        <SupportLink article="3ad5l1ysc6">More Info</SupportLink>
      </Body>

      <Body>
        Store URL: <strong>{storeUrl}</strong>
      </Body>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex column gap="16px">
          <Flex column gap="8px">
            <Label htmlFor="woo-consumer-key-input">Consumer Key</Label>
            <Input
              placeholder="WooCommerce Consumer Key"
              {...register('wooCommerceKey', {
                required: 'WooCommerce Consumer Key is required',
              })}
            />
            {errors.wooCommerceKey && (
              <Body color="error500" variant="Body/Body Small">
                {errors.wooCommerceKey.message}
              </Body>
            )}
          </Flex>

          <Flex column gap="8px">
            <Label htmlFor="woo-secret-key-input">Secret Key</Label>
            <Input
              placeholder="WooCommerce Secret Key"
              {...register('wooCommerceSecret', {
                required: 'WooCommerce Secret Key is required',
              })}
            />
            {errors.wooCommerceSecret && (
              <Body color="error500" variant="Body/Body Small">
                {errors.wooCommerceSecret.message}
              </Body>
            )}
          </Flex>

          <Flex.Item alignSelfEnd>
            <PrimaryButton size="small" type="submit" disabled={pending}>
              Connect
            </PrimaryButton>
          </Flex.Item>
        </Flex>
      </form>

      {error && <Body color="error500">{`Error saving WooCommerce credentials: ${error}`}</Body>}
    </GetStartedCard>
  );
};

export default ConnectWooCommerceCard;
