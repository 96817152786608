import { useState } from 'react';
import Modal from 'storybook/stories/cells/Modal';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

import Message, { useMessage } from 'components/Common/Message';
import { retryEDIRecord } from 'utils/api/edi';

import type { EdiRecord } from 'types/models/edi';
import './EDIStyles.css';

interface CreateEDIRetryModalProps {
  record: EdiRecord | undefined;
  showModal: boolean;
  onDismiss: () => void;
}

const CreateEDIRetryModal = ({ record, showModal, onDismiss }: CreateEDIRetryModalProps) => {
  const [sending, setSending] = useState(false);
  const { showErrorMessage, messageState, hideMessage } = useMessage();

  const onCloseModal = () => {
    hideMessage();
    onDismiss();
  };

  if (!record) return null;
  if (record.routing !== 'outbound' && record.routing !== 'inbound') {
    return null;
  }
  const retryAndClose = () => {
    setSending(true);
    retryEDIRecord(record.id)
      .then(() => {
        setSending(false);
        onDismiss();
      })
      .catch((err) => {
        showErrorMessage({ title: 'Error retrying document', message: err.message });
        setSending(false);
      });
  };
  return (
    <Modal.Root
      show={showModal}
      size="md"
      aria-labelledby="edi-retry-modal-title"
      centered
      onHide={onCloseModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Message
          show={messageState.show}
          onClose={hideMessage}
          kind={messageState.kind}
          className="mt-0"
        >
          <p className="m-0 mb-1">
            <strong>{messageState.title}</strong>
          </p>
          <p className="m-0">{messageState.message}</p>
        </Message>

        <p>
          {record.routing === 'outbound'
            ? `Retrying this document will send a completely new document into your system, which may result in double processing. You should only retry documents if your system has failed to process the first one.`
            : `Retrying this document will only succeed if it was processed the first time with errors.`}
        </p>
      </Modal.Body>

      <Modal.Footer>
        <SecondaryButton
          size="small"
          type="button"
          color="primary"
          disabled={sending}
          onClick={retryAndClose}
        >
          Retry this document
        </SecondaryButton>
      </Modal.Footer>
    </Modal.Root>
  );
};

export default CreateEDIRetryModal;
