import Flex from '@react-css/flex';
import OnboardingSteps from 'containers/PartnersPage/OnboardingSteps';
import InvoicingStatus from 'containers/PartnersPage/PartnerSearch/InvoicingStatus';
import {
  CardFooter,
  GrowingIcon,
  InnerCard,
  OuterCard,
  WordBreakFlex,
} from 'containers/PartnersPage/PartnerSearch/Shared.styles';
import Body from 'storybook/stories/molecules/Body';
import Icon from 'storybook/stories/molecules/Icon';
import StatusIcon from 'storybook/stories/molecules/Icon/StatusIcon';
import Link from 'storybook/stories/molecules/Link';
import type { Partner } from 'types/models/partner';

const PartnerCardContents = ({ isBuyer, partner, partnerId }: PartnerCardProps) => {
  return (
    <Flex alignItemsCenter gap="32px">
      <Flex.Item grow={1}>
        <Flex column gap="16px">
          <Flex gap="32px">
            <Flex.Item grow={1}>
              <WordBreakFlex column gap="4px">
                <Link to={`/partners/${partnerId}`}>
                  <Body variant="Body/Regular Bold" color="bodyTextPrimary">
                    {isBuyer ? partner.sellerName : partner.buyerName}
                  </Body>
                </Link>
              </WordBreakFlex>
            </Flex.Item>
            <Flex column gap="4px">
              <Body variant="Body/Caption" color="bodyTextSecondary">
                Avg Ship Time
              </Body>
              <Body variant="Body/Regular">{partner.averageShipTime.toFixed(1)} hours</Body>
            </Flex>
            <Flex column gap="4px">
              <Body variant="Body/Caption" color="bodyTextSecondary">
                Avg Return Rate
              </Body>
              <Body variant="Body/Regular">{partner.averageReturnRate.toFixed(1)}%</Body>
            </Flex>
          </Flex>
          <Flex justifyEnd alignItemsCenter gap="16px">
            {partner.priceListName ? (
              <GrowingIcon name="request_quote" color="iconDefault">
                {!isBuyer ? (
                  <Link to={`/prices/${partner.pricingId}`}>
                    <Body variant="Body/Caption" color="bodyTextSecondary">
                      {partner.priceListName}
                    </Body>
                  </Link>
                ) : (
                  <Body variant="Body/Caption">{partner.priceListName}</Body>
                )}
              </GrowingIcon>
            ) : (
              <GrowingIcon name="scan_delete" color="iconDefault">
                <Body variant="Body/Caption" color="bodyTextDisabled">
                  No Price List
                </Body>
              </GrowingIcon>
            )}

            <StatusIcon variant={partner.active ? 'checked' : 'error'}>
              <Body variant="Body/Caption" color="bodyTextSecondary">
                Active
              </Body>
            </StatusIcon>
            <InvoicingStatus partner={partner} />
          </Flex>
        </Flex>
      </Flex.Item>
      <Link to={`/partners/${partnerId}`}>
        <Icon name="edit" size="24px" />
      </Link>
    </Flex>
  );
};

type PartnerCardProps = {
  isBuyer: boolean;
  partner: Partner;
  partnerId: string;
};

const PartnerCard = ({ isBuyer, partner, partnerId }: PartnerCardProps) => {
  return (
    <OuterCard>
      <InnerCard elevation="2">
        <PartnerCardContents isBuyer={isBuyer} partner={partner} partnerId={partnerId} />
      </InnerCard>
      <CardFooter>
        <OnboardingSteps partner={partner} />
      </CardFooter>
    </OuterCard>
  );
};

export default PartnerCard;
