import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import { rollAPIKey as rollAPIKeyFromAPI } from 'utils/api/users';

interface Integrations {
  isRollingKey: boolean;
}

const initialState: Integrations = {
  isRollingKey: false,
};

export const rollApiKey = createAsyncThunk<
  { apiKey: string },
  any,
  {
    state: RootState;
  }
>('settings/integrations/rollKey', async (_, thunkAPI) => {
  const { me } = thunkAPI.getState();

  if (me.user && me.user._id) {
    return rollAPIKeyFromAPI(me.user._id);
  }

  return Promise.resolve();
});

const slice = createSlice({
  name: 'settings/integrations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(rollApiKey.pending, (draft) => {
      draft.isRollingKey = true;
    });

    builder.addCase(rollApiKey.fulfilled, (draft) => {
      draft.isRollingKey = false;
    });

    builder.addCase(rollApiKey.rejected, (draft) => {
      draft.isRollingKey = false;
    });
  },
});

export default slice.reducer;
