import Flex from '@react-css/flex';
import { isFinite } from 'lodash';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';

import type { CreatePriceListContextValues } from 'containers/PriceListsPage/CreatePriceList/context';
import { CreatePriceListContext } from 'containers/PriceListsPage/CreatePriceList/context';
import {
  DollarInput,
  DollarSign,
  InputWrapper,
} from 'containers/PriceListsPage/CreatePriceList/Shared.styles';
import type { CommonFooterProps } from 'containers/PriceListsPage/CreatePriceList/Shared.types';
import { getCurrencySymbol } from 'utils/currencies';

import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import Label from 'storybook/stories/molecules/Label';
import ProgressBar from 'storybook/stories/organisms/ProgressBar';

// The retailPriceFormat must be a number between 0 and 100, or null/undefined
// if the user didn't enter a value.
export const validateRoundingRuleValue = (value: number | string | undefined | null): boolean => {
  if (!value) return true;
  const safeValue = typeof value === 'string' ? parseInt(value, 10) : value;
  return safeValue >= 0 && safeValue <= 99;
};

const Footer = ({ isNextButtonDisabled }: CommonFooterProps) => {
  const { updateState } = useContext(CreatePriceListContext);

  const onClickBack = () => updateState({ currentStep: 'ConversionRateForm' });

  const skipForward = () => {
    updateState({ currentStep: 'MarginForm' });
  };

  return (
    <Card>
      <Flex justifySpaceBetween>
        <TertiaryButton size="small" $iconName="navigate_before" onClick={onClickBack}>
          Back
        </TertiaryButton>

        <Flex gap="24px">
          <SecondaryButton size="small" onClick={skipForward}>
            Decide Later
          </SecondaryButton>

          <PrimaryButton type="submit" size="small" disabled={isNextButtonDisabled}>
            Next
          </PrimaryButton>
        </Flex>
      </Flex>
    </Card>
  );
};

const RoundingRulesForm = () => {
  const { retailPriceFormat, yourCurrency, updateState } = useContext(CreatePriceListContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      retailPriceFormat: retailPriceFormat ?? null,
    },
  });

  const retailPriceFormatFormValue = watch('retailPriceFormat');

  const onSubmit = (data: Partial<CreatePriceListContextValues>) => {
    updateState({
      retailPriceFormat: data.retailPriceFormat,
      currentStep: 'MarginForm',
    });
  };

  const shouldNextButtonBeDisabled = !isValid || retailPriceFormat === undefined;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex column gap="16px">
        <Card>
          <Flex column gap="28px">
            <ProgressBar totalSteps={5} completedSteps={4} />

            <Flex column gap="32px">
              <Flex column gap="8px">
                <Heading variant="Headings/H3">
                  Would you like to round your retail prices? (Optional)
                </Heading>

                <Body variant="Body/Regular" color="bodyTextSecondary">
                  When adjusting margins and exchange rates, final suggested retail prices may not
                  be optimal. Apply a rounding rule for more consistent pricing.
                </Body>
              </Flex>

              <Flex justifySpaceBetween>
                <Flex column gap="8px">
                  <Label htmlFor="retailPriceFormat">Price Rounding</Label>

                  <InputWrapper>
                    <DollarSign>$.</DollarSign>
                    <DollarInput
                      type="number"
                      min="0"
                      max="99"
                      id="retailPriceFormat"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('retailPriceFormat', {
                        validate: validateRoundingRuleValue,
                      })}
                      placeholder="99"
                    />
                  </InputWrapper>

                  {isFinite(retailPriceFormatFormValue) && (
                    <Flex alignItemsCenter gap="8px">
                      <Body variant="Body/Body Small" color="blue800">
                        Rounding Example:
                      </Body>
                      <Body variant="Body/Body Small" color="gray500">
                        <Flex alignItemsCenter gap="4px">
                          {getCurrencySymbol(yourCurrency)}123.00
                          <Icon name="arrow_forward" color="gray400" />
                          {getCurrencySymbol(yourCurrency)}123.{retailPriceFormatFormValue}
                        </Flex>
                      </Body>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Footer isNextButtonDisabled={shouldNextButtonBeDisabled} />
      </Flex>
    </form>
  );
};

export default RoundingRulesForm;
