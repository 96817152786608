export interface CommonFooterProps {
  isNextButtonDisabled: boolean;
}

export interface SelectablePartner {
  id: string;
  name: string;
}

export enum MarginType {
  Fixed = 'fixed',
  Percent = 'percent',
}

export interface MarginOptionsType {
  description: string;
  label: string;
  value: MarginType;
}

export const MarginOptions: MarginOptionsType[] = [
  {
    description: 'Percent',
    label: '%',
    value: MarginType.Percent,
  },
  {
    description: 'Fixed Margin',
    label: '$',
    value: MarginType.Fixed,
  },
];
