const OrderNotes = ({ note, onNoteChange }) => {
  return (
    <div className="card p-4">
      <h4 className="mb-4">Notes</h4>
      <div className="form-group mb-0">
        <textarea
          className="form-control form-control-flush form-control-auto"
          placeholder="No order notes"
          style={{ resize: 'vertical' }}
          rows={4}
          value={note}
          name="note"
          onChange={(e) => onNoteChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default OrderNotes;
