import { useMutation } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import type { ReactNode } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { updatePartner } from 'utils/api/partners';
import type { CreatePriceListParams } from 'utils/api/priceLists';
import { createPriceList } from 'utils/api/priceLists';

import type { SelectablePartner } from './Shared.types';
import { MarginType } from './Shared.types';

export interface CreatePriceListContextValues {
  currentStep: string;
  priceListName: string;
  assignedPartners: Record<string, SelectablePartner>; // Map of PartnerID to the SelectablePartner object
  yourCurrency: string;
  partnerCurrency: string;
  conversionRate: number | null;
  margin: number | null;
  marginType: MarginType;
  retailPriceFormat: number | null;
  addNewProducts: boolean;
  addExistingProducts: boolean;
  updateState: (newState: Partial<CreatePriceListContextValues>) => void;
  submitNewPriceList: (margin: number, marginType: MarginType) => void;
}

const defaultValues: CreatePriceListContextValues = {
  currentStep: 'PriceListNameForm',
  priceListName: '',
  assignedPartners: {},
  yourCurrency: 'CAD',
  partnerCurrency: 'CAD',
  conversionRate: null,
  margin: null,
  marginType: MarginType.Percent,
  retailPriceFormat: null,
  addNewProducts: true,
  addExistingProducts: true,
  updateState: () => {}, // placeholder function; this will be replaced with actual implementation on init
  submitNewPriceList: () => {}, // placeholder function
};

export const CreatePriceListContext = createContext<CreatePriceListContextValues>(defaultValues);

interface CreatePriceListProviderProps {
  children: ReactNode;
}

export const CreatePriceListProvider = ({ children }: CreatePriceListProviderProps) => {
  const { addErrorAlert } = useAlertQueue();
  const history = useHistory();

  const [state, setState] = useState<CreatePriceListContextValues>(() => {
    const storedState = localStorage.getItem('createPriceListState');
    return {
      ...defaultValues,
      ...(storedState ? JSON.parse(storedState) : {}),
    };
  });

  const updateState = useCallback((newState: Partial<CreatePriceListContextValues>) => {
    setState((prevState) => {
      const updatedState = { ...prevState, ...newState };
      localStorage.setItem('createPriceListState', JSON.stringify(updatedState));
      return updatedState;
    });
  }, []);

  const assigningPartner = useMutation({
    mutationFn: ({ partner, pricingId }: { partner: SelectablePartner; pricingId: string }) =>
      updatePartner(partner.id, { pricingId }),
    onError: (error) => {
      addErrorAlert(
        'Something went wrong',
        'We were unable to assign a partner to the price list.'
      );
      console.error('Error assigning partner', error);
    },
  });

  const creatingPriceList = useMutation({
    mutationFn: (params: CreatePriceListParams) => createPriceList(params),
    onSuccess: ({ data: { id } }) => {
      localStorage.removeItem('createPriceListState');

      // Iterate over the selected partners and assign them to the new price list
      const partnerAssignments = Object.values(state.assignedPartners).map(
        (partner: SelectablePartner) => {
          return assigningPartner.mutateAsync({ partner, pricingId: id });
        }
      );

      // Wait for all the mutations to finish before navigating to the new price list
      Promise.all(partnerAssignments)
        .then(() => {
          history.push(`/prices/${id}?isNew=true`);
        })
        .catch((error) => {
          console.error('Error assigning partners', error);
        });
    },
    onError: (error) => {
      addErrorAlert('Something went wrong', 'We were unable to create a new price list.');
      console.error('Error submitting feedback', error);
    },
  });

  // submitNewPriceList makes a call to the API to create a new price list. We need to pass in the margin and marginType
  // instead of pulling it from the context's state because we can't count on the context being updated before this mutation is called.
  const submitNewPriceList = useCallback(
    (margin: number, marginType: MarginType) => {
      const formattedMargin = marginType === 'percent' ? margin / 100 : margin;

      const newPriceList: CreatePriceListParams = {
        name: state.priceListName,
        retailCurrencyConversionRate: state.conversionRate ?? 1,
        sellerCurrency: state.yourCurrency,
        buyerRetailCurrency: state.partnerCurrency,
        dropshipMargin: formattedMargin,
        dropshipMarginType: marginType,
        shouldAddNewProducts: state.addNewProducts,
        shouldAddCurrentProducts: state.addExistingProducts,
        retailPriceFormat: state.retailPriceFormat?.toString() ?? '',
      };

      creatingPriceList.mutate(newPriceList);
    },
    [
      creatingPriceList,
      state.conversionRate,
      state.partnerCurrency,
      state.priceListName,
      state.retailPriceFormat,
      state.yourCurrency,
      state.addExistingProducts,
      state.addNewProducts,
    ]
  );

  const value = useMemo(
    () => ({
      ...state,
      updateState,
      submitNewPriceList,
    }),
    [state, updateState, submitNewPriceList]
  );

  return (
    <CreatePriceListContext.Provider value={value}>{children}</CreatePriceListContext.Provider>
  );
};
