import { forwardRef, type ForwardedRef } from 'react';
import Icon from 'storybook/stories/molecules/Icon';
import styled, { css } from 'styled-components';

interface ProductImageProps {
  src: string;
  alt: string;
}

const Image = styled.div`
  width: 50px;
  height: 50px;

  ${({ theme }) => css`
    border-radius: 4px;
    background-color: ${theme.color.gray200};
  `};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ProductImage = ({ src, alt }: ProductImageProps) => {
  return <Image>{src && <img src={src} alt={alt} referrerPolicy="no-referrer" />}</Image>;
};

interface ProductImageThumbnailProps {
  imageSrc?: string;
}

const ImageThumbnail = styled.div`
  width: 56px;
  height: 56px;

  display: grid;
  place-content: center;

  ${({ theme }) => css`
    background-color: ${theme.color.gray100};
    border: 1px solid ${theme.color.gray200};
    border-radius: 4px;
  `}
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ProductImageThumbnail = forwardRef(
  ({ imageSrc }: ProductImageThumbnailProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <ImageThumbnail ref={ref}>
        {imageSrc ? (
          <img src={imageSrc} alt="" referrerPolicy="no-referrer" />
        ) : (
          <Icon name="imagesmode" color="gray300" size="24px" />
        )}
      </ImageThumbnail>
    );
  }
);
