import Flex from '@react-css/flex';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

const AssignedActions = ({ done }) => {
  return (
    <GetStartedCard
      isComplete={done}
      title="Onboarding with Partners"
      completedUi={
        <Body as="p">
          View your assigned actions from the <Link to="/partners">Partners page</Link>.
        </Body>
      }
    >
      <Flex column gap="16px">
        <Body as="p">
          Please visit the partners page to complete the actions that are assigned to you by your
          partner.{' '}
          <SupportLink article="gc59nwyivb#what_is_the_difference_between_actions_action_templates">
            More Info
          </SupportLink>
        </Body>

        <Flex.Item alignSelfEnd>
          <PrimaryButton size="small" as={Link} to="/partners">
            Go To Partners
          </PrimaryButton>
        </Flex.Item>
      </Flex>
    </GetStartedCard>
  );
};

export default AssignedActions;
