import Flex from '@react-css/flex';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useBoolean } from 'usehooks-ts';

import AcceptReturnModal from 'containers/OrderPage/Returns/AcceptReturnModal';
import CompleteReturnModal from 'containers/OrderPage/Returns/CompleteReturnModal';
import RejectReturnModal from 'containers/OrderPage/Returns/RejectReturnModal';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import StatusChip, { StatusChipKinds } from 'storybook/stories/molecules/Chip/StatusChip';
import Divider from 'storybook/stories/molecules/Divider';
import Heading from 'storybook/stories/molecules/Heading';
import Link from 'storybook/stories/molecules/Link';
import type { Order20210101, OrderItem } from 'types/models/order';
import type { Return } from 'types/models/return';
import { prettyDate } from 'utils/date';

const AcceptButton = styled(TertiaryButton)`
  color: ${({ theme }) => theme.color.green600};
`;

const ProductLink = styled(Link)`
  font-size: 18px;
`;

const WideFlexRow = styled(Flex)`
  width: 100%;
`;

const getOrderItemForReturn = (items: OrderItem[], itemId: string) =>
  items.find((item) => item._id === itemId);

const getReturnLabel = (status: string) => {
  switch (status) {
    case 'completed':
      return { kind: StatusChipKinds.Success, label: 'Completed' };
    case 'accepted':
      return { kind: StatusChipKinds.Success, label: 'Accepted' };
    case 'rejected':
      return { kind: StatusChipKinds.Error, label: 'Rejected' };
    case 'pending':
      return { kind: StatusChipKinds.Pending, label: 'Pending' };
    case 'cancelled':
      return { kind: StatusChipKinds.Error, label: 'Cancelled' };
    default:
      return { kind: StatusChipKinds.Pending, label: 'Pending' };
  }
};

interface OrderReturnRowProps {
  items: OrderItem[];
  orderReturn: Return;
  index: number;
  isSeller: boolean;
  showAcceptReturnModal: () => void;
  showRejectReturnModal: () => void;
  showCompleteReturnModal: () => void;
  setCurrentReturn: Dispatch<SetStateAction<Return>>;
}

const OrderReturnRow = ({
  items,
  orderReturn,
  index,
  isSeller,
  showAcceptReturnModal,
  showRejectReturnModal,
  showCompleteReturnModal,
  setCurrentReturn,
}: OrderReturnRowProps) => {
  const item = getOrderItemForReturn(items, orderReturn.orderItemId);
  const { kind, label } = getReturnLabel(orderReturn.status);

  const shouldShowDivider = items.length > 1 && index === items.length;
  const isWaitingOnSellerResponse = ['pending', 'accepted'].includes(orderReturn.status);

  return (
    <Flex justifySpaceBetween alignContentCenter>
      <WideFlexRow column gap="2px">
        <Body variant="Body/Body Small" color="bodyTextSecondary">
          {prettyDate(orderReturn.createdAt)}
        </Body>

        <WideFlexRow justifySpaceBetween alignItemsCenter>
          <ProductLink
            target="_blank"
            rel="noopener noreferrer"
            to={`/products/${item?.productId}`}
            className="mb-0"
          >
            {orderReturn.quantity}x {item?.title || `Item #${index + 1}`}
          </ProductLink>

          {!isSeller && (
            <Flex gap="4px">
              <StatusChip hasIcon kind={kind}>
                {label}
              </StatusChip>
            </Flex>
          )}

          {isSeller && orderReturn.status === 'accepted' && (
            <Flex gap="4px" alignItemsCenter>
              <AcceptButton
                $iconName="check_circle"
                kind="action"
                size="small"
                onClick={() => {
                  setCurrentReturn(orderReturn);
                  showCompleteReturnModal();
                }}
              >
                Mark as Completed
              </AcceptButton>
            </Flex>
          )}

          {isSeller && orderReturn.status === 'pending' && (
            <Flex gap="4px" alignItemsCenter>
              <Flex>
                <TertiaryButton
                  $iconName="cancel"
                  kind="destructive"
                  size="small"
                  onClick={() => {
                    setCurrentReturn(orderReturn);
                    showRejectReturnModal();
                  }}
                >
                  Reject
                </TertiaryButton>
                <AcceptButton
                  $iconName="check_circle"
                  kind="action"
                  size="small"
                  onClick={() => {
                    setCurrentReturn(orderReturn);
                    showAcceptReturnModal();
                  }}
                >
                  Accept
                </AcceptButton>
              </Flex>
            </Flex>
          )}

          {isSeller && !isWaitingOnSellerResponse && (
            <Flex gap="4px" alignItemsCenter>
              <StatusChip hasIcon kind={kind}>
                {label}
              </StatusChip>
            </Flex>
          )}
        </WideFlexRow>

        <Body variant="Body/Body Small" color="bodyTextSecondary">
          Return Reason: {orderReturn.reason}
        </Body>

        {orderReturn.status === 'rejected' && (
          <Body variant="Body/Body Small" color="bodyTextSecondary">
            Rejection Reason: {orderReturn.rejectionReason}
          </Body>
        )}
      </WideFlexRow>

      {shouldShowDivider && <Divider />}
    </Flex>
  );
};

export interface OrderReturnsProps {
  order: Order20210101;
  isSeller: boolean;
  returns: Return[] | undefined;
  refetchReturns: () => void;
}

const OrderReturns = ({ order, isSeller, returns, refetchReturns }: OrderReturnsProps) => {
  const shouldShowAccceptReturnModal = useBoolean(false);
  const shouldShowRejectReturnModal = useBoolean(false);
  const shouldShowCompleteReturnModal = useBoolean(false);
  const [currentReturn, setCurrentReturn] = useState({} as Return);

  if (!returns || returns.length === 0) {
    return null;
  }

  return (
    <>
      <Card className="mb-4">
        <Heading variant="Headings/H3" className="mb-4">
          Returns
        </Heading>

        <Flex column gap="6px">
          {returns?.map((orderReturn: Return, i: number) => (
            <OrderReturnRow
              key={orderReturn.orderItemId}
              items={order.items || []}
              orderReturn={orderReturn}
              index={i}
              isSeller={isSeller}
              showAcceptReturnModal={shouldShowAccceptReturnModal.setTrue}
              showRejectReturnModal={shouldShowRejectReturnModal.setTrue}
              showCompleteReturnModal={shouldShowCompleteReturnModal.setTrue}
              setCurrentReturn={setCurrentReturn}
            />
          ))}
        </Flex>
      </Card>

      <AcceptReturnModal
        orderReturn={currentReturn}
        shouldShowModal={shouldShowAccceptReturnModal.value}
        onDismiss={shouldShowAccceptReturnModal.setFalse}
        refetchReturns={refetchReturns}
      />

      <RejectReturnModal
        orderReturn={currentReturn}
        shouldShowModal={shouldShowRejectReturnModal.value}
        onDismiss={shouldShowRejectReturnModal.setFalse}
        refetchReturns={refetchReturns}
      />

      <CompleteReturnModal
        orderReturn={currentReturn}
        shouldShowModal={shouldShowCompleteReturnModal.value}
        onDismiss={shouldShowCompleteReturnModal.setFalse}
        refetchReturns={refetchReturns}
      />
    </>
  );
};

export default OrderReturns;
