import Flex from '@react-css/flex';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';

import type { CreatePriceListContextValues } from './context';
import { CreatePriceListContext } from './context';

import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import ProgressBar from 'storybook/stories/organisms/ProgressBar';

interface FooterProps {
  isNextButtonDisabled: boolean;
}

const Footer = ({ isNextButtonDisabled }: FooterProps) => {
  return (
    <Card>
      <Flex justifyEnd>
        <PrimaryButton
          type="submit"
          size="small"
          $iconName="navigate_next"
          $iconAfter
          disabled={isNextButtonDisabled}
        >
          Next
        </PrimaryButton>
      </Flex>
    </Card>
  );
};

const PriceListNameForm = () => {
  const { priceListName, updateState } = useContext(CreatePriceListContext);

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      priceListName: priceListName || '',
    },
  });

  const onSubmit = (data: Partial<CreatePriceListContextValues>) => {
    updateState({ priceListName: data.priceListName, currentStep: 'AddExistingProductsForm' });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex column gap="16px">
        <Card>
          <Flex column gap="28px">
            <ProgressBar totalSteps={5} completedSteps={1} />

            <Flex column gap="32px">
              <Flex column gap="8px">
                <Heading variant="Headings/H3">
                  What would you like to name this price list?
                </Heading>
                <Body variant="Body/Regular" color="bodyTextSecondary">
                  An example of a price list name may be &quot;(Retailer) Price List -
                  (Season/Year)&quot;.
                </Body>
              </Flex>

              <Flex column gap="8px">
                <Label htmlFor="priceListName" isRequired>
                  Price List Name
                </Label>

                <Input
                  type="text"
                  id="priceListName"
                  {...register('priceListName', { required: true })}
                  placeholder="Example Price List - Fall 20XX"
                />
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Footer isNextButtonDisabled={!isValid} />
      </Flex>
    </form>
  );
};

export default PriceListNameForm;
