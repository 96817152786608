import styled, { css } from 'styled-components';

import type { IconProps } from 'storybook/stories/molecules/Icon';
import Icon from 'storybook/stories/molecules/Icon';
import type { ThemeColor } from 'storybook/stories/theme';

export enum StatusIconVariants {
  Checked = 'checked',
  Unchecked = 'unchecked',
  Error = 'error',
  Syncing = 'syncing',
  Warning = 'warning',
  PartialSuccess = 'partial_success',
}

interface StatusIconProps {
  /**
   * Controls stateful presentation
   */
  variant: `${StatusIconVariants}`;
  /**
   * Size will proportionally control the icon & text
   */
  size?: string;
  /**
   * Weight will control the font-weight of the text
   */
  weight?: number;
}

/**
 * Creates a colorized Icon specifically used for stateful status indication
 */
const StatusIcon = styled(Icon).attrs<StatusIconProps, IconProps>(({ variant, size, weight }) => {
  const names: Record<StatusIconVariants, string> = {
    checked: 'check_circle',
    unchecked: 'circle',
    error: 'cancel',
    syncing: 'sync',
    warning: 'error',
    partial_success: 'pending',
  };

  const colors: Record<StatusIconVariants, ThemeColor> = {
    checked: 'success700',
    unchecked: 'gray400',
    error: 'error500',
    syncing: 'iconDefault',
    warning: 'warning700',
    partial_success: 'success700',
  };

  return {
    name: names[variant],
    color: colors[variant],
    size: size || '20px',
    weight: weight || 400,
  };
})<StatusIconProps>`
  &::before,
  &::after {
    border-radius: 100%;

    ${({ size }) => css`
      font-size: calc(${size} + ${size} / 4); // Boosts the icon to the edge of its container
    `}

    ${({ theme, variant }) => {
      switch (variant) {
        case StatusIconVariants.Checked:
          return css`
            background-color: ${theme.color.success100};
          `;
        case StatusIconVariants.Error:
          return css`
            background-color: ${theme.color.error100};
          `;
        case StatusIconVariants.Warning:
          return css`
            background-color: ${theme.color.warning100};
          `;
        case StatusIconVariants.PartialSuccess:
          return css`
            background-color: ${theme.color.success100};
          `;
        default:
          return css`
            background-color: transparent;
          `;
      }
    }}
  }
`;

export default StatusIcon;
