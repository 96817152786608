import classNames from 'classnames';

import Icon from 'storybook/stories/molecules/Icon';

import './DoneCheckmark.css';

const DoneCheckmark = ({ done, className }) => {
  const cn = classNames('DoneCheckmark', { DoneCheckmark__checked: done }, className);

  return <div className={cn}>{done && <Icon name="done" />}</div>;
};

export default DoneCheckmark;
