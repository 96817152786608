import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty, omitBy, uniq } from 'lodash';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { useBoolean } from 'usehooks-ts';

import Spinner from 'components/Common/Spinner';
import DashboardPage from 'components/Dashboard/DashboardPage';
import { getDuplicateVariantSkus, getSelectedProducts } from 'containers/Products/helpers';
import ActionsDropdown from 'containers/Products/SellerProductsPage/ActionsDropdown';
import DeleteConfirmationModal from 'containers/Products/SellerProductsPage/DeleteConfirmationModal';
import DuplicateSkuAlert from 'containers/Products/SellerProductsPage/DuplicateSkuAlert';
import EmptySellerProductsPage from 'containers/Products/SellerProductsPage/EmptySellerProductsPage';
import type { ProductFiltersFormInputs } from 'containers/Products/SellerProductsPage/FiltersDropdown';
import FiltersDropdown from 'containers/Products/SellerProductsPage/FiltersDropdown';
import GoogleCategoryModal from 'containers/Products/SellerProductsPage/GoogleCategoryModal';
import HeaderRowButtons from 'containers/Products/SellerProductsPage/HeaderRowButtons';
import type { TagsModalFormInputs } from 'containers/Products/SellerProductsPage/TagsModal';
import TagsModal from 'containers/Products/SellerProductsPage/TagsModal';
import useAlertQueue from 'hooks/useAlertQueue';
import { PageSelectedState, useBulkSelect } from 'hooks/useBulkSelect';
import Page from 'storybook/stories/cells/Page';
import type { ProductSearchFormInputs } from 'storybook/stories/organisms/SearchResults/SellerProductSearchResults';
import SellerProductSearchResults from 'storybook/stories/organisms/SearchResults/SellerProductSearchResults';
import type { GoogleProductCategory } from 'types/general';
import type { SellerProductSearchAggregationData } from 'types/models/search';
import type { SellerProduct } from 'types/models/seller-product';
import {
  deleteProduct,
  getProductSearch,
  getProductSearchAggregations,
  patchProduct,
  type ProductSearchParams,
} from 'utils/api/products';
import getUpdatedUrl from 'utils/searchResults';

interface PageWrapperProps {
  children: React.ReactNode;
  onFileUploaded?: () => void;
  hasProducts?: boolean;
}

const PageWrapper = ({ children, hasProducts, onFileUploaded }: PageWrapperProps) => (
  <DashboardPage>
    <Page>
      <Page.Head title="Products">
        <HeaderRowButtons onFileUploaded={onFileUploaded} hasProducts={hasProducts} />
      </Page.Head>
      <Page.Body>{children}</Page.Body>
    </Page>
  </DashboardPage>
);

const SellerProductsPage = () => {
  // State

  const [isAggregationQueryEnabled, setIsAggregationQueryEnabled] = useState(false);

  const { search, pathname } = useLocation();
  const history = useHistory();

  const { value: isCategoryModalVisible, toggle: toggleCategoryModalVisibility } = useBoolean();
  const { value: isDeleteModalVisible, toggle: toggleDeleteModalVisibility } = useBoolean();
  const { value: isAddTagsModalVisible, toggle: toggleAddTagsModalVisibility } = useBoolean();
  const { value: isRemoveTagsModalVisible, toggle: toggleRemoveTagsModalVisibility } = useBoolean();

  const searchParams = qs.parse(search, { ignoreQueryPrefix: true }) as ProductSearchParams;

  // Alert Handling

  const { addErrorAlert } = useAlertQueue();

  // Queries

  const fetchingSearchResults = useQuery({
    queryKey: ['getProductSearch', 'seller', searchParams] as const,
    queryFn: ({ queryKey }) => getProductSearch(queryKey[2]),
    onError: (error) => {
      addErrorAlert('Something went wrong', 'Unable to fetch product search results.');
      console.error('Unable to fetch seller product search results', error);
    },
  });

  const fetchingSearchResultAggregations = useQuery({
    queryKey: ['getProductSearchAggregations', 'seller', searchParams] as const,
    queryFn: ({ queryKey }) => getProductSearchAggregations(queryKey[2]),
    enabled: isAggregationQueryEnabled,
    onError: (error) => {
      addErrorAlert('Something went wrong', 'Unable to fetch product search aggregations.');
      console.error('Unable to fetch seller product search aggregations', error);
    },
  });

  // Mutations

  type UpdatingProductMutationArgs = {
    id: string;
    data: Partial<SellerProduct>;
  };

  const updatingProduct = useMutation({
    mutationFn: ({ id, data }: UpdatingProductMutationArgs) => patchProduct(id, data),
    onError: (error, { id, data }) => {
      addErrorAlert('Something went wrong', 'Unable to update product');
      console.error(`Unable to update product ${id}`, data, error);
    },
  });

  type DeletingProductMutationArgs = {
    id: string;
  };

  const deletingProduct = useMutation({
    mutationFn: ({ id }: DeletingProductMutationArgs) => deleteProduct(id),
    onError: (error, { id }) => {
      addErrorAlert('Something went wrong', 'Unable to delete product');
      console.error(`Unable to delete product ${id}`, error);
    },
  });

  // Helpers

  const results = fetchingSearchResults.data;
  const currentPage = parseInt(searchParams.page || '1', 10);

  const aggregations = fetchingSearchResultAggregations.data?.data?.aggregations;

  const products = useMemo(
    () => (results?.data?.products || []) as SellerProduct[],
    [results?.data?.products]
  );

  const isProductsEmpty = products.length === 0 && isEmpty(searchParams);

  const {
    pageSelectedState,
    selectedIds,
    select,
    deselect,
    selectAll,
    deselectAll,
    isSelected,
    isAnySelected,
    isSelectable,
  } = useBulkSelect(products.length);

  const selectedProducts = useMemo(
    () => getSelectedProducts(products, selectedIds),
    [products, selectedIds]
  );

  const duplicateVariantSkus = useMemo(() => getDuplicateVariantSkus(products), [products]);

  // Side Effects

  useEffect(() => {
    deselectAll();
  }, [fetchingSearchResults.isFetching, deselectAll]);

  // Event Handlers

  const onSearchFormSubmit: SubmitHandler<ProductSearchFormInputs> = (values) => {
    history.push(getUpdatedUrl(pathname, searchParams, values));
  };

  const onFiltersFormSubmit: SubmitHandler<ProductFiltersFormInputs> = (values) => {
    history.push(getUpdatedUrl(pathname, searchParams, { filters: omitBy(values, isEmpty) }));
  };

  const onFiltersFormReset = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { filters: undefined }));
  };

  const onPreviousClick = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: `${currentPage - 1}` }));
  };

  const onNextClick = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: `${currentPage + 1}` }));
  };

  const onPageClick = (pageNumber: number) => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: pageNumber.toString() }));
  };

  const onSearchResultClick = (productId: string) => {
    history.push(`/products/${productId}`, { backUrlQueryParams: qs.stringify(searchParams) });
  };

  const onProductActiveChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    product: SellerProduct
  ) => {
    await updatingProduct.mutateAsync({ id: product.id, data: { active: event.target.checked } });
    fetchingSearchResults.refetch();
  };

  const onSelectPageChange = () => {
    if (pageSelectedState === PageSelectedState.All) {
      deselectAll();
    } else {
      selectAll(products.map(({ id }) => id));
    }
  };

  const onSelectRowChange = (id: string) => {
    if (isSelected(id)) {
      deselect(id);
    } else {
      select(id);
    }
  };

  const onBulkActivate = async () => {
    await Promise.all(
      selectedIds.map((id) => updatingProduct.mutateAsync({ id, data: { active: true } }))
    );

    fetchingSearchResults.refetch();
  };

  const onBulkDeactivate = async () => {
    await Promise.all(
      selectedIds.map((id) => updatingProduct.mutateAsync({ id, data: { active: false } }))
    );

    fetchingSearchResults.refetch();
  };

  const onBulkAddTags: SubmitHandler<TagsModalFormInputs> = async (values) => {
    const newTags = values.tags.map((tag) => tag.value);

    if (newTags.length === 0) {
      deselectAll();
      toggleAddTagsModalVisibility();
      return;
    }

    await Promise.all(
      selectedProducts.map((product) => {
        const tags = uniq([...product.tags, ...newTags]);
        return updatingProduct.mutateAsync({ id: product.id, data: { tags } });
      })
    );

    toggleAddTagsModalVisibility();
    fetchingSearchResults.refetch();
  };

  const onBulkRemoveTags: SubmitHandler<TagsModalFormInputs> = async (values) => {
    const newTags = values.tags.map((tag) => tag.value);

    if (newTags.length === 0) {
      deselectAll();
      toggleRemoveTagsModalVisibility();
      return;
    }

    await Promise.all(
      selectedProducts.map((product) => {
        const tags = product.tags.filter((tag) => !newTags.includes(tag));
        return updatingProduct.mutateAsync({ id: product.id, data: { tags } });
      })
    );

    toggleRemoveTagsModalVisibility();
    fetchingSearchResults.refetch();
  };

  const onBulkAssignCategory = async (googleProductCategory: GoogleProductCategory) => {
    await Promise.all(
      selectedIds.map((id) => updatingProduct.mutateAsync({ id, data: { googleProductCategory } }))
    );

    toggleCategoryModalVisibility();
    fetchingSearchResults.refetch();
  };

  const onBulkDeleteProducts = async () => {
    await Promise.all(selectedIds.map((id) => deletingProduct.mutateAsync({ id })));

    toggleDeleteModalVisibility();
    fetchingSearchResults.refetch();
  };

  const onFileUploaded = () => {
    fetchingSearchResults.refetch();
  };

  // Render Guards

  if (!fetchingSearchResults.isSuccess)
    return (
      <PageWrapper>
        <Spinner />
      </PageWrapper>
    );

  if (isProductsEmpty)
    return (
      <PageWrapper>
        <EmptySellerProductsPage onFileUploaded={onFileUploaded} />
      </PageWrapper>
    );

  // Render

  const headerActionsUi = isAnySelected && (
    <ActionsDropdown
      onActivateProductsClick={onBulkActivate}
      onDeactivateProductsClick={onBulkDeactivate}
      onAddTagsClick={toggleAddTagsModalVisibility}
      onRemoveTagsClick={toggleRemoveTagsModalVisibility}
      onAssignCategoryClick={toggleCategoryModalVisibility}
      onDeleteProductsClick={toggleDeleteModalVisibility}
    />
  );

  const headerFiltersUi = (
    <FiltersDropdown
      aggregations={aggregations as SellerProductSearchAggregationData['aggregations']}
      onFiltersFormSubmit={onFiltersFormSubmit}
      onFiltersFormReset={onFiltersFormReset}
      searchParams={searchParams}
      onOpen={() => setIsAggregationQueryEnabled(true)}
      isLoading={fetchingSearchResultAggregations.isLoading}
    />
  );

  return (
    <PageWrapper onFileUploaded={onFileUploaded} hasProducts={products.length > 0}>
      <DuplicateSkuAlert duplicateVariantSkus={duplicateVariantSkus} />

      <SellerProductSearchResults
        results={results}
        onSearchFormSubmit={onSearchFormSubmit}
        defaultSearchFormInputValue={searchParams.query}
        currentPage={currentPage}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
        onPageClick={onPageClick}
        onSearchResultClick={onSearchResultClick}
        onProductActiveChange={onProductActiveChange}
        headerActionsUi={headerActionsUi}
        headerFiltersUi={headerFiltersUi}
        onSelectPageChange={onSelectPageChange}
        onSelectRowChange={onSelectRowChange}
        isSelected={isSelected}
        isSelectable={isSelectable}
        pageSelectedState={pageSelectedState}
      />

      <GoogleCategoryModal
        show={isCategoryModalVisible}
        onDismiss={toggleCategoryModalVisibility}
        onAssignCategory={onBulkAssignCategory}
      />

      <DeleteConfirmationModal
        show={isDeleteModalVisible}
        onDismiss={toggleDeleteModalVisibility}
        onAccept={onBulkDeleteProducts}
        selectedProducts={selectedProducts}
      />

      <TagsModal
        type="add"
        show={isAddTagsModalVisible}
        onDismiss={toggleAddTagsModalVisibility}
        onSubmit={onBulkAddTags}
      />

      <TagsModal
        type="remove"
        show={isRemoveTagsModalVisible}
        onDismiss={toggleRemoveTagsModalVisibility}
        onSubmit={onBulkRemoveTags}
      />
    </PageWrapper>
  );
};

export default SellerProductsPage;
