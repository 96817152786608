import Flex from '@react-css/flex';
import { isEmpty, omitBy } from 'lodash';
import qs from 'qs';
import { useContext } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';

import { PageSelectedState } from 'hooks/useBulkSelect';
import type { PriceListEntriesSearchParams } from 'utils/api/priceLists';
import { publish } from 'utils/events';
import getUpdatedUrl from 'utils/searchResults';

import type { EditEntryOptions } from 'containers/PriceListPage/BulkEditing/context';
import { BulkEditPriceListContext } from 'containers/PriceListPage/BulkEditing/context';
import EditPricingHeaderDropDownMenu from 'containers/PriceListPage/BulkEditing/EditPricingHeaderDropDownMenu';
import type { PriceListEntryFiltersFormInputs } from 'containers/PriceListPage/BulkEditing/FiltersDropdown';
import FiltersDropdown from 'containers/PriceListPage/BulkEditing/FiltersDropdown';
import SaveAndCancelHeaderButton from 'containers/PriceListPage/BulkEditing/SaveAndCancelHeaderButton';
import ViewByDropdown from 'containers/PriceListPage/BulkEditing/ViewByDropdown';
import type { MarginType } from 'containers/PriceListsPage/CreatePriceList/Shared.types';

import NoResultsCard from 'storybook/stories/cells/Card/NoResultsCard';
import type { UploadedFile } from 'storybook/stories/cells/FileUpload';
import PageList from 'storybook/stories/cells/PageList';
import PageNavigation from 'storybook/stories/cells/PageNavigation';
import SearchForm, { SearchFormButton, SearchFormInput } from 'storybook/stories/cells/SearchForm';
import PriceListEntrySearchResult from 'storybook/stories/cells/SearchResult/PriceListEntrySearchResult';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Checkbox from 'storybook/stories/molecules/Checkbox';
import Link from 'storybook/stories/molecules/Link';
import SearchResults, {
  SearchResultsFooter,
  SearchResultsHeader,
} from 'storybook/stories/organisms/SearchResults';
import PriceListEntrySearchResultsEmptyState from './PriceListEntrySearchResultsEmptyState';

export type PriceListEntrySearchFormInputs = {
  query: string;
};

interface PriceListEntrySearchFormProps {
  onSearchFormSubmit: SubmitHandler<PriceListEntrySearchFormInputs>;
  defaultSearchFormInputValue?: string;
}

const PriceListEntrySearchForm = ({
  onSearchFormSubmit,
  defaultSearchFormInputValue,
}: PriceListEntrySearchFormProps) => {
  const { register, handleSubmit } = useForm<PriceListEntrySearchFormInputs>();

  return (
    <SearchForm onSubmit={handleSubmit(onSearchFormSubmit)}>
      <SearchFormInput
        placeholder="Search by Product Title or Tags"
        {...register('query')}
        defaultValue={defaultSearchFormInputValue}
      />
      <SearchFormButton data-testid="submit-query" />
    </SearchForm>
  );
};

interface PriceListEntrySearchResultsProps {
  onSearchFormSubmit: SubmitHandler<PriceListEntrySearchFormInputs>;
  defaultSearchFormInputValue?: string;
  currentPage: number;
  onPreviousClick: React.MouseEventHandler<HTMLButtonElement>;
  onNextClick: React.MouseEventHandler<HTMLButtonElement>;
  onSelectPageChange: React.ChangeEventHandler<HTMLInputElement>;
  onSelectRowChange: (id: string) => void;
  onClickBulkEditAction: (action: EditEntryOptions) => void;
  headerAddNewButton: React.ReactNode;
  isSelected: (id: string) => boolean;
  isSelectable: boolean;
  marginType: MarginType;
  pageSelectedState: PageSelectedState;
  hasMore: boolean;
  shouldDisplayFooter: boolean;
  onProcessFile: (file: UploadedFile) => Promise<string | ArrayBuffer | null>;
  onGeneratePreview: (rawFile: string | ArrayBuffer | null) => void;
  onClickExportCsv: () => void;
  onClickAddAllProducts: () => void;
  onClickSelectProduct: () => void;
}

/**
 * `SearchResults` implementation for Price List Entry search results
 */
const PriceListEntrySearchResults = ({
  onSearchFormSubmit,
  defaultSearchFormInputValue,
  currentPage,
  onPreviousClick,
  onNextClick,
  onSelectPageChange,
  onSelectRowChange,
  onClickBulkEditAction,
  headerAddNewButton,
  isSelected,
  isSelectable,
  marginType,
  pageSelectedState,
  hasMore,
  shouldDisplayFooter,
  onProcessFile,
  onGeneratePreview,
  onClickExportCsv,
  onClickAddAllProducts,
  onClickSelectProduct,
}: PriceListEntrySearchResultsProps) => {
  const { rows, selectedRowIds } = useContext(BulkEditPriceListContext);
  const { search, pathname } = useLocation();
  const history = useHistory();
  const searchParams = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as PriceListEntriesSearchParams;
  const priceListEntries = rows.map((row) => row.entry);

  const hasPrevious = currentPage > 1;
  const hasSearchValues = !isEmpty(searchParams.query) || !isEmpty(searchParams.filters);

  const onFiltersFormSubmit: SubmitHandler<PriceListEntryFiltersFormInputs> = (values) => {
    history.push(getUpdatedUrl(pathname, searchParams, { filters: omitBy(values, isEmpty) }));
  };

  const onFiltersFormReset = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { filters: undefined }));
  };

  const onBulkSave = () => {
    publish('bulkSubmitEditPriceListEntries', {});
  };

  const onBulkCancel = () => {
    publish('bulkCancelEditPriceListEntries', {});
  };

  const headerSearchFormUi = (
    <PriceListEntrySearchForm
      onSearchFormSubmit={onSearchFormSubmit}
      defaultSearchFormInputValue={defaultSearchFormInputValue}
    />
  );

  const headerCheckboxUi = (
    <Checkbox
      name="selectPage"
      onChange={onSelectPageChange}
      checked={pageSelectedState !== PageSelectedState.None}
      indeterminate={pageSelectedState === PageSelectedState.Some}
      disabled={!isSelectable}
      data-testid="select-all-button"
      aria-label={
        pageSelectedState !== PageSelectedState.None
          ? 'Deselect All Price List Entries on Page'
          : 'Select All Price List Entries on Page'
      }
    />
  );

  const headerFiltersUi = (
    <FiltersDropdown
      onFiltersFormSubmit={onFiltersFormSubmit}
      onFiltersFormReset={onFiltersFormReset}
      searchParams={searchParams}
    />
  );

  const isBulkEditing = rows.some(
    (row) => row.isEditingYourRetailPrice || row.isEditingMargin || row.isEditingYouEarn
  );

  const actionsUi = (
    <Flex gap="16px">
      {isBulkEditing ? (
        <SaveAndCancelHeaderButton onCancel={onBulkCancel} onSave={onBulkSave} />
      ) : (
        selectedRowIds.length > 1 && (
          <EditPricingHeaderDropDownMenu onClickEditProperty={onClickBulkEditAction} />
        )
      )}

      {headerAddNewButton}
    </Flex>
  );

  const viewByUi = <ViewByDropdown />;

  // The values being passed in don't match the names of the props 1:1 on purpose.
  // This was done to achieve the desired order of the elements in the header.
  const headerUi = (
    <SearchResultsHeader
      searchFormUi={headerSearchFormUi}
      checkboxUi={headerCheckboxUi}
      actionsUi={actionsUi}
      filtersUi={viewByUi}
      addNewButton={headerFiltersUi}
    />
  );

  const footerPageListUi = <PageList currentPage={currentPage} />;

  const footerNavigationUi = (
    <PageNavigation
      hasPrevious={hasPrevious}
      hasNext={hasMore}
      onPreviousClick={onPreviousClick}
      onNextClick={onNextClick}
    />
  );

  const footerUi = shouldDisplayFooter && (
    <SearchResultsFooter pageListUi={footerPageListUi} navigationUi={footerNavigationUi} />
  );

  // Price List is empty
  if (!hasSearchValues && priceListEntries.length === 0) {
    return (
      <PriceListEntrySearchResultsEmptyState
        onProcessFile={onProcessFile}
        onGeneratePreview={onGeneratePreview}
        onClickExportCsv={onClickExportCsv}
        onClickAddAllProducts={onClickAddAllProducts}
        onClickSelectProduct={onClickSelectProduct}
      />
    );
  }

  if (priceListEntries.length === 0) {
    return (
      <SearchResults headerUi={headerUi} footerUi={footerUi}>
        <NoResultsCard
          title="No entries match your search"
          description="You can reset your search by clicking the button below"
        >
          <PrimaryButton $iconName="restart_alt" as={Link} to={pathname}>
            Reset Search
          </PrimaryButton>
        </NoResultsCard>
      </SearchResults>
    );
  }

  return (
    <SearchResults headerUi={headerUi} footerUi={footerUi}>
      {priceListEntries.map((priceListEntry) => {
        const checkboxUi = (
          <Checkbox
            name="selectRow"
            onClick={(event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation()}
            onChange={() => onSelectRowChange(priceListEntry.id)}
            checked={isSelected(priceListEntry.id)}
            aria-label={
              isSelected(priceListEntry.id)
                ? `Deselect ${priceListEntry.variant.sku}`
                : `Select ${priceListEntry.variant.sku}`
            }
          />
        );

        return (
          <PriceListEntrySearchResult
            key={priceListEntry.id}
            priceListEntryId={priceListEntry.id}
            marginType={marginType}
            checkboxUi={checkboxUi}
            onClick={() => {
              onSelectRowChange(priceListEntry.id);
            }}
          />
        );
      })}
    </SearchResults>
  );
};

export default PriceListEntrySearchResults;
