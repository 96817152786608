import {
  FloatingChatContext,
  type FloatingChatContextValue,
} from 'containers/App/Contexts/FloatingChatContext';
import { useContext } from 'react';
import FloatingChatButton, {
  BUTTON_SIZE,
} from 'storybook/stories/species/Chat/FloatingChat/FloatingChatButton';
import FloatingChatHeaderActions from 'storybook/stories/species/Chat/FloatingChat/FloatingChatHeaderActions';
import FloatingChatPrompt from 'storybook/stories/species/Chat/FloatingChat/FloatingChatPrompt';
import FloatingChatWindow, {
  WINDOW_OFFSET,
} from 'storybook/stories/species/Chat/FloatingChat/FloatingChatWindow';
import styled, { css } from 'styled-components';

type FloatingChatWrapperProps = Pick<FloatingChatContextValue, 'isWindowExpanded' | 'isWindowOpen'>;

const FloatingChatWrapper = styled.div<FloatingChatWrapperProps>`
  position: fixed;
  z-index: 1;
  bottom: ${WINDOW_OFFSET}px;
  right: ${WINDOW_OFFSET}px;

  ${({ isWindowOpen }) =>
    !isWindowOpen &&
    css`
      width: ${BUTTON_SIZE}px;
      height: ${BUTTON_SIZE}px;
    `}

  ${({ isWindowExpanded }) =>
    isWindowExpanded &&
    css`
      top: ${WINDOW_OFFSET}px;
      left: calc(250px + ${WINDOW_OFFSET}px);
    `}
`;

interface FloatingChatProps {
  children: React.ReactNode;
}

const Base = ({ children }: FloatingChatProps) => {
  const { isWindowOpen, isWindowExpanded } = useContext(FloatingChatContext);

  return (
    <FloatingChatWrapper isWindowExpanded={isWindowExpanded} isWindowOpen={isWindowOpen}>
      {children}
    </FloatingChatWrapper>
  );
};

const FloatingChat = Object.assign(Base, {
  Button: FloatingChatButton,
  Prompt: FloatingChatPrompt,
  Window: FloatingChatWindow,
  HeaderActions: FloatingChatHeaderActions,
});

export default FloatingChat;
