import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

const ActivateProductsCardContent = ({ disabled, isSeller, syncing }) => {
  if (disabled) {
    return (
      <Body as="p">
        You need to connect your platform before you can {isSeller ? 'activate' : 'select'}{' '}
        products. <SupportLink article="9vzrzqkyqh">More Info</SupportLink>
      </Body>
    );
  }

  if (syncing) {
    return (
      <Body as="p">
        Your products are syncing. This may take a few minutes.{' '}
        <SupportLink article="9vzrzqkyqh">More Info</SupportLink>
      </Body>
    );
  }

  if (isSeller) {
    return (
      <Body as="p">
        You will need to mark products as active and create a price list before they can be synced
        to your buyers. You may need to wait for your products to sync to our platform.{' '}
        <SupportLink article="9vzrzqkyqh">More Info</SupportLink>
      </Body>
    );
  }

  return (
    <Body as="p">
      You will need to select products that you want to sync to your store. Your sellers will have
      to price and activate their products before you can select them.{' '}
      <SupportLink article="mgkg6potbt">More Info</SupportLink>
    </Body>
  );
};

const ActivateProductsCard = ({ done, disabled, isSeller, syncing }) => {
  return (
    <GetStartedCard
      title={`${isSeller ? 'Activate' : 'Select'} Products`}
      isComplete={done}
      completedUi={
        <Body>
          Manage your products from the <Link to="/products">Products page</Link>.
        </Body>
      }
    >
      <ActivateProductsCardContent disabled={disabled} isSeller={isSeller} syncing={syncing} />
    </GetStartedCard>
  );
};

export default ActivateProductsCard;
