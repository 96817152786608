import { updateSFTPCreds } from 'store/slices/company';
import { useSafeState } from 'utils/hooks';

import useAlertQueue from 'hooks/useAlertQueue';
import ExternalSFTPSettingsForm from './ExternalSFTPSettingsForm';

const ExternalSFTPSettings = ({ dispatch, company }) => {
  const alertQueue = useAlertQueue();
  const [isUpdating, setIsUpdating] = useSafeState(false);

  const handleUpdateSFTPCreds = (body) => {
    const bodyCopy = { ...body };
    setIsUpdating(true);
    if (bodyCopy.port !== '') {
      bodyCopy.port = parseInt(bodyCopy.port, 10);
    }
    dispatch(updateSFTPCreds(bodyCopy)).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert(
          'Something went wrong',
          `${action.error.message}. Credentials were not saved.`
        );
      } else {
        alertQueue.addSuccessAlert('Success', 'SFTP credentials updated');
      }

      setIsUpdating(false);
    });
  };

  return (
    <>
      <hr className="my-4" />
      <h4>External SFTP Settings</h4>
      <p className="small text-muted mb-3">
        Configure your SFTP server settings. Our IP is 35.223.28.122 if you require an IP for
        white-listing.
      </p>
      <ExternalSFTPSettingsForm
        company={company}
        onSubmit={handleUpdateSFTPCreds}
        showSubmitButton
        disableSubmitButton={isUpdating}
        submitButtonText="Update SFTP settings"
      />
    </>
  );
};

export default ExternalSFTPSettings;
