import classNames from 'classnames';

import Button from 'components/Common/Button';
import { forwardRef } from 'react';
import type { FieldError } from 'react-hook-form';
import Tooltip from 'storybook/stories/cells/Tooltip';
import Icon from 'storybook/stories/molecules/Icon';

export interface CardFormFieldProps extends React.HTMLProps<HTMLInputElement> {
  error?: { message: string } | FieldError;
  label: string;
  id: string;
  type: string;
  className?: string;
  tooltipText?: string;
  tooltipPlacement?: string;
  appendInput?: {
    name: string;
    icon: string;
    event: (eventData: any) => void;
    eventData: any;
  }[];
}

const CardFormField = forwardRef<HTMLInputElement, CardFormFieldProps>((props, ref) => {
  const {
    error,
    label,
    id,
    type,
    className,
    tooltipText,
    tooltipPlacement,
    appendInput,
    ...inputProps
  } = props;
  const cn = classNames('form-group', 'row', 'align-items-center', 'mb-3', className);

  const inputCN = classNames('form-control', 'form-control-sm', { 'is-invalid': error });

  const inputDivCN = classNames('col-sm-12', 'col-md-9', { 'input-group': appendInput });

  return (
    <div className={cn}>
      <label htmlFor={id} className="col-sm-12 col-md-3 col-form-label col-form-label-sm">
        {label}
        {tooltipText && (
          <Tooltip>
            <Tooltip.Trigger asChild>
              <Icon name="help" position="after" />
            </Tooltip.Trigger>
            <Tooltip.Content>{tooltipText}</Tooltip.Content>
          </Tooltip>
        )}
      </label>
      <div className={inputDivCN}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input type={type} className={inputCN} id={id} ref={ref} {...inputProps} />
        {appendInput &&
          appendInput.map((input, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="input-group-append" key={`${input.name}-${index}`}>
              {/* @ts-ignore */}
              <Button
                type="button"
                outline
                color="secondary"
                size="sm"
                onClick={() => input.event(input.eventData)}
              >
                <Icon name={input.icon} />
              </Button>
            </div>
          ))}

        {error ? <div className="invalid-feedback">{error.message}</div> : null}
      </div>
    </div>
  );
});

export default CardFormField;
