/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import Modal from 'storybook/stories/cells/Modal';
import styled from 'styled-components';

import CreateActionCheckbox from 'containers/PartnerPage/Actions/Common/CreateActionCheckbox';
import CreateActionTextarea from 'containers/PartnerPage/Actions/Common/CreateActionTextarea';
import CreateActionTextbox from 'containers/PartnerPage/Actions/Common/CreateActionTextbox';
import PreviewCreateActionCard from 'containers/PartnerPage/Actions/Common/PreviewCreateActionCard';
import PreviewCreateActionEmail from 'containers/PartnerPage/Actions/Common/PreviewCreateActionEmail';
import SubmitFormButton from 'containers/PartnerPage/Actions/Common/SubmitFormButton';
import { BTN_DELAY } from 'containers/PartnerPage/Actions/Shared';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import type { Signer } from 'types/models/partner-action';

const EmailMessageTextbox = styled.textarea`
  min-height: 12.5rem;
`;

export type CreateAgreementActionData = {
  cardTitle: string;
  cardText: string;
  required: boolean;
  name: string;
  file: number[];
  fileName: string;
  fileType: string;
  emailSubject: string;
  emailMessage: string;
  signers: Signer[];
  documentId?: string;
};

interface CreateAgreementActionModalProps {
  kind: string;
  showModal: boolean;
  onHide: () => void;
  onCreate: (data: CreateAgreementActionData) => Promise<any>;
  onRefresh: React.Dispatch<void>;
  onBackClick: () => void;
  isActionTemplate: boolean;
}

const CreateAgreementActionModal = ({
  kind,
  showModal,
  onHide,
  onCreate,
  onRefresh,
  onBackClick,
  isActionTemplate,
}: CreateAgreementActionModalProps) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [required, setRequired] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [agreement, setAgreement] = useState<File | null>(null);
  const [signers, setSigners] = useState('');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (title === '') {
      setError('Error: Title is required');
      return;
    }

    if (!agreement) {
      setError('Error: Agreement is required');
      return;
    }
    if (agreement.type !== 'application/pdf') {
      setError('Error: Agreement must be a PDF');
      return;
    }
    if (!emailSubject || emailSubject === '') {
      setError('Error: Email subject is required');
      return;
    }
    if (signers === '' && !isActionTemplate) {
      setError('Error: Please provide at least one signer');
      return;
    }

    setLoading(true);

    const listOfSigners = signers.split(',').map((email) => ({
      name: email.trim(),
      email: email.trim(),
    }));

    // Upload and create contract
    const reader = new FileReader();
    reader.onload = () => {
      const bytes = Array.from(new Uint8Array(reader.result as ArrayBufferLike));
      onCreate({
        cardTitle: title,
        cardText: text,
        required,
        name: title,
        file: bytes,
        fileName: agreement.name,
        fileType: agreement.type,
        emailSubject,
        emailMessage,
        signers: listOfSigners,
      })
        .then((response) => {
          setLoading(false);

          if (response.error && response.error.message) {
            setError(response.error.message);
          } else {
            setSuccess(true);
            setTimeout(() => {
              setTitle('');
              setText('');
              setRequired(false);
              setAgreement(null);
              setSigners('');
              setEmailSubject('');
              setEmailMessage('');
              setError('');
              setSuccess(false);
              onRefresh();
            }, BTN_DELAY);
          }
        })
        .catch((err) => {
          console.log('error', err);
          setLoading(false);
          setError('Something went wrong, please contact support@convictional.com');
        });
    };
    reader.onerror = (err) => {
      console.warn(err);
    };
    reader.readAsArrayBuffer(agreement);
  };

  return (
    <Modal.Root
      data-testid="action-agreement-modal"
      show={showModal}
      onHide={onHide}
      aria-labelledby="action-agreement-modal"
      size="xl"
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isActionTemplate ? 'Create Agreement Action Template' : 'Create Agreement Action'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            {error && <div className="alert alert-danger">{error}</div>}
            <CreateActionTextbox
              id="title"
              label="Title"
              value={title}
              placeholder="Ex. Partnership Agreement"
              setValue={setTitle}
            />
            <CreateActionTextarea
              id="text"
              label="Description"
              value={text}
              placeholder="Describe the required steps"
              setValue={setText}
            />
            <CreateActionCheckbox
              id="required"
              label="Required"
              value={required}
              setValue={setRequired}
            />
            <div>
              <p className="text-muted">
                We use{' '}
                <a
                  href="https://hellosign.com?ref=convictional"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HelloSign
                </a>{' '}
                to manage our agreements. Your partner will sign the agreement through the Modern
                Dropship app. HelloSign will be contacting the signers through email.
              </p>
              <div className="form-group">
                <label htmlFor="modify-card-text">Agreement</label>
                <input
                  type="file"
                  className="form-control"
                  id="modify-card-contract"
                  data-testid="create-action-file-uploader"
                  onChange={(e) => {
                    if (e.target.files) {
                      setAgreement(e.target.files[0]);
                    }
                  }}
                />
              </div>
              {isActionTemplate ? (
                <div className="form-group">
                  <label htmlFor="modify-card-title">Signers</label>
                  <p className="text-muted">An email will be sent to partner invite email</p>
                </div>
              ) : (
                <div className="form-group">
                  <label htmlFor="modify-card-title">Signers</label>
                  <textarea
                    className="form-control"
                    placeholder="Inserts signers email (Comma delimited)"
                    id="modify-card-signers"
                    data-testid="agreement-signers-input"
                    value={signers}
                    onChange={(e) => setSigners(e.target.value)}
                  />
                </div>
              )}
              <div className="form-group">
                <label htmlFor="modify-card-title">Email Subject</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex. Partnership Agreement"
                  id="modify-card-title"
                  value={emailSubject}
                  data-testid="email-subject-input"
                  onChange={(e) => setEmailSubject(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="modify-card-text">Email Message</label>
                <EmailMessageTextbox
                  className="form-control"
                  placeholder="Notes for the email."
                  id="modify-card-text"
                  value={emailMessage}
                  onChange={(e) => setEmailMessage(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            <PreviewCreateActionCard
              title={title}
              text={text}
              kind={kind}
              required={required}
              optionalDoc={false}
            />
            <PreviewCreateActionEmail
              kind={kind}
              emailSubject={emailSubject}
              emailMessage={emailMessage}
              signers={signers}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <div className="text-right">
              <SecondaryButton className="mr-3" size="small" onClick={onBackClick}>
                Back
              </SecondaryButton>
              <SubmitFormButton
                id="create-action-form-btn"
                label={isActionTemplate ? 'Create Action Template' : 'Create Action'}
                successfulLabel="Created"
                loading={loading}
                success={success}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal.Root>
  );
};

export default CreateAgreementActionModal;
