import Flex from '@react-css/flex';
import { useLocation } from 'react-router-dom';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Link from 'storybook/stories/molecules/Link';
import Layout from 'storybook/stories/species/Layout';

const NotFoundPage = () => {
  const { pathname } = useLocation();

  return (
    <Layout.Root>
      <Layout.Content>
        <Flex column gap="32px" alignItemsCenter>
          <Layout.Logo />

          <Card padding="48px">
            <Heading variant="Headings/H1" align="center">
              Page not found
            </Heading>

            <Flex column gap="24px">
              <Body align="center">
                <strong>{pathname}</strong> does not exist
              </Body>

              <PrimaryButton as={Link} to="/">
                Back to Home
              </PrimaryButton>
            </Flex>
          </Card>
        </Flex>
      </Layout.Content>
    </Layout.Root>
  );
};

export default NotFoundPage;
