import Flex from '@react-css/flex';
import type { Dispatch } from '@reduxjs/toolkit';
import { useState, type MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import type { Order20210101 } from 'types/models/order';

import PageHeader from 'components/Common/PageHeader';
import BackLink from 'components/Dashboard/BackLink';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Chip from 'storybook/stories/molecules/Chip';
import { prettyDate, prettyTime } from 'utils/date';

import CancelOrderModal from 'containers/OrderPage/CancelOrderModal';
import { selectIsOrderPlatformEdi, selectIsOrderPlatformOther } from 'store/selectors/me/company';

interface OrderPageHeaderProps {
  dispatch: Dispatch;
  showSave: boolean;
  onDiscardEdits: () => void;
  onSaveEdits: () => void;
  updating: boolean;
  order: Order20210101;
  isSeller: boolean;
  orderCancelled: boolean;
  backUrl: string;
}

interface OrderPageHeaderButtonsProps {
  dispatch: Dispatch;
  showSave: boolean;
  showCancelOrderButton: boolean;
  onDiscardEdits: () => void;
  onSaveEdits: () => void;
  updating: boolean;
  order: Order20210101;
}

const TestOrderChip = styled(Chip)`
  ${({ theme }) => css`
    background-color: ${theme.color.gray500};
    color: ${theme.color.white};
    border-color: ${theme.color.gray500};
  `};
`;

const OrderPageHeaderButtons = ({
  dispatch,
  showSave,
  showCancelOrderButton,
  onDiscardEdits,
  onSaveEdits,
  updating,
  order,
}: OrderPageHeaderButtonsProps) => {
  const [showCancel, setShowCancel] = useState(false);

  const onCancelClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowCancel(true);
  };

  if (showSave)
    return (
      <Flex column>
        <SecondaryButton onClick={onDiscardEdits}>Discard</SecondaryButton>
        <PrimaryButton onClick={onSaveEdits} disabled={updating}>
          Save
        </PrimaryButton>
      </Flex>
    );

  if (showCancelOrderButton)
    return (
      <>
        <SecondaryButton kind="destructive" onClick={(e) => onCancelClick(e)}>
          Cancel Order
        </SecondaryButton>
        <CancelOrderModal
          order={order}
          showModal={showCancel}
          onDismiss={() => setShowCancel(false)}
          dispatch={dispatch}
        />
      </>
    );

  return null;
};

const OrderPageHeader = ({
  order,
  isSeller,
  orderCancelled,
  dispatch,
  showSave,
  onDiscardEdits,
  onSaveEdits,
  updating,
  backUrl,
}: OrderPageHeaderProps) => {
  const isOrderPlatformOther = useSelector(selectIsOrderPlatformOther);
  const isOrderPlatformEdi = useSelector(selectIsOrderPlatformEdi);
  const title = isSeller
    ? `Order from ${order.buyerName || 'Buyer'}`
    : `Order for ${order.sellerName || 'Seller'}`;
  const createdDate = prettyDate(order.created);
  const createdTime = prettyTime(order.created);
  const showCancelOrderButton =
    !order.shipped && !orderCancelled && (isOrderPlatformOther || isOrderPlatformEdi);

  return (
    <PageHeader>
      <Flex column>
        <BackLink backUrl={backUrl} backPageName="Orders" />
        <div className="d-flex align-items-center">
          <h1 className="header-title">{title}</h1>
          {order.isTest && <TestOrderChip className="ml-3">Test Order</TestOrderChip>}
        </div>
        <p className="header-subtitle">
          {createdDate} at {createdTime}
        </p>
      </Flex>
      <OrderPageHeaderButtons
        dispatch={dispatch}
        showSave={showSave}
        showCancelOrderButton={showCancelOrderButton}
        onDiscardEdits={onDiscardEdits}
        onSaveEdits={onSaveEdits}
        updating={updating}
        order={order}
      />
    </PageHeader>
  );
};

export default OrderPageHeader;
