import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import Button from 'components/Common/Button';
import UpdatedIndicator from 'components/Common/UpdatedIndicator';
import {
  arrayToIdMap,
  getArrayPatch,
  getSortedIdList,
  updateIdMapPositions,
} from 'hooks/products/useEditProduct';

import ProductImagesGrid from 'containers/Product/ProductImages/ProductImagesGrid';
import CardProductValidationAlert from '../CardProductValidationAlert';
import AddImageModal from './AddImageModal';
import SelectedImageModal from './SelectedImageModal';

const Wrapper = styled.div`
  border: 1px solid transparent;

  ${({ theme, hasErrors }) =>
    hasErrors &&
    css`
      border-color: ${theme.color.warning500};
    `}
`;

const ProductImagesHeader = ({ canEdit, hasEdits, setShowAddImage }) => (
  <div className="d-flex justify-content-between align-items-center mb-4">
    <h4 className="mb-0">
      Images {hasEdits && <UpdatedIndicator aria-label="images updated" className="ml-1" />}
    </h4>

    {canEdit && (
      <Button size="sm" color="white" aria-label="add image" onClick={() => setShowAddImage(true)}>
        Add
      </Button>
    )}
  </div>
);

const ProductImages = ({
  productId,
  productTitle,
  images = [],
  canEdit,
  onEditImages,
  clearEditsAt,
  hasEdits,
  refreshProduct,
  validationErrors,
}) => {
  // create an editable map of images values
  const [editMap, setEditMap] = useState(() => arrayToIdMap(images));
  // create list of IDs sorted by position
  const [imageIds, setImageIds] = useState(() => getSortedIdList(images));
  // hold copy of the last clearEditsAt to compare when it changes (ignores other dependencies)
  const clearEditsAtRef = useRef(clearEditsAt);

  const [showAddImage, setShowAddImage] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const selectedImageId =
    selectedIndex >= 0 && imageIds[selectedIndex] ? imageIds[selectedIndex] : '';

  useEffect(() => {
    // used to reset local edits only when the parent has specifically said to do so
    // reset back to product.images values
    if (clearEditsAtRef.current !== clearEditsAt) {
      setImageIds(getSortedIdList(images));
      setEditMap(arrayToIdMap(images));
      clearEditsAtRef.current = clearEditsAt;
    }
  }, [clearEditsAt, images]);

  // called when the sortable image is moved
  const onPositionChange = ({ oldIndex, newIndex }) => {
    const movedImageId = imageIds[oldIndex];
    const newImageIds = imageIds.filter((_, idx) => idx !== oldIndex);
    newImageIds.splice(newIndex, 0, movedImageId);
    // imageId list is now in different order
    setImageIds(newImageIds);
    // update the position values in the editMap to reflect new order
    const newEditMap = updateIdMapPositions(newImageIds, editMap);
    setEditMap(newEditMap);
    onEditImages(getArrayPatch(images, newEditMap));
  };

  // called when the image url is saved from the AddImageModal
  const onAddImage = (src) => {
    const id = uuidv4();
    let newEditMap = { ...editMap, [id]: { src } };
    const newImageIds = [...imageIds, id];
    newEditMap = updateIdMapPositions(newImageIds, newEditMap);
    setEditMap(newEditMap);
    setImageIds(newImageIds);
    onEditImages(getArrayPatch(images, newEditMap));
  };

  const onImageDeleted = (id) => {
    let newEditMap = { ...editMap };
    delete newEditMap[id];
    const newImageIds = imageIds.filter((imgId) => imgId !== id);
    newEditMap = updateIdMapPositions(newImageIds, newEditMap);
    setImageIds(newImageIds);
    setEditMap(newEditMap);
    onEditImages(
      getArrayPatch(
        images.filter((img) => img._id !== id),
        newEditMap
      )
    );
  };

  return (
    <Wrapper className="card p-4 overflow-auto" hasErrors={validationErrors?.length > 0}>
      <ProductImagesHeader
        canEdit={canEdit}
        hasEdits={hasEdits}
        setShowAddImage={setShowAddImage}
      />

      {imageIds.length === 0 ? (
        <p className="mt-0 mb-2 text-muted">This product does not have any images</p>
      ) : (
        <ProductImagesGrid
          imageIds={imageIds}
          editMap={editMap}
          productTitle={productTitle}
          canEdit={canEdit}
          setSelectedIndex={setSelectedIndex}
          onPositionChange={onPositionChange}
        />
      )}

      {validationErrors?.length > 0 && <CardProductValidationAlert errors={validationErrors} />}

      <AddImageModal
        show={showAddImage}
        onDismiss={() => setShowAddImage(false)}
        onAddImage={onAddImage}
      />

      <SelectedImageModal
        show={!!selectedImageId}
        onDismiss={() => setSelectedIndex(-1)}
        onImageDeleted={onImageDeleted}
        image={selectedImageId ? editMap[selectedImageId] : {}}
        title={productTitle}
        numberOfImgs={imageIds.length}
        canEdit={canEdit}
        imageIdx={selectedIndex}
        productId={productId}
        imageId={selectedImageId}
        refreshProduct={refreshProduct}
      />
    </Wrapper>
  );
};

export default ProductImages;
