import Flex from '@react-css/flex';
import { useInfiniteQuery } from '@tanstack/react-query';
import Spinner from 'components/Common/Spinner';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import { HistoryTimeline } from 'storybook/stories/organisms/Timeline/HistoryTimeline';
import orderEventComponents from 'storybook/stories/organisms/Timeline/HistoryTimeline/OrderEvents';
import styled from 'styled-components';
import { getOrderHistory } from 'utils/api/orders';

const ORDER_HISTORY_QUERY_KEY = 'order-history';

const TimelineWrapper = styled.div`
  padding: 16px;
`;

type OrderHistoryProps = {
  orderId: string;
};

const OrderHistory = ({ orderId }: OrderHistoryProps) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    [ORDER_HISTORY_QUERY_KEY, orderId],
    ({ pageParam = 0 }) => {
      return getOrderHistory(orderId, { limit: 5, page: pageParam });
    },
    {
      enabled: !!orderId,
      getNextPageParam: (lastPage) => {
        // Parse next from last page
        if (!lastPage.next) return undefined;
        const url = new URL(lastPage.next);
        const page = url.searchParams.get('page');
        return lastPage.hasMore ? page : undefined;
      },
    }
  );

  // Combine events from every page
  const allEvents = data?.pages.flatMap((page) => page.data) ?? [];

  return (
    <div>
      <Heading variant="Headings/H2">History</Heading>
      <TimelineWrapper>
        <Flex column gap="8px">
          <HistoryTimeline
            events={allEvents}
            hasMore={hasNextPage || false}
            eventComponents={orderEventComponents}
          />

          {isFetchingNextPage && <Spinner />}

          {hasNextPage && !isFetchingNextPage && (
            <Flex.Item>
              <TertiaryButton
                onClick={() => {
                  fetchNextPage();
                }}
              >
                Load more
              </TertiaryButton>
            </Flex.Item>
          )}
        </Flex>
      </TimelineWrapper>
    </div>
  );
};

export default OrderHistory;
