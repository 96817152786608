import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import styled from 'styled-components';

interface AvailableMappingsProps {
  mappingSchemas: Array<{ id: string; created: string; name: string }>;
  onSetLiveSchema: (id: string) => void;
  formatDate: (date: string) => string;
}

const OuterContainer = styled.div`
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  padding: 20px;
  width: 100%;
`;

const Item = styled.div`
  flex: 1;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Summary = styled.div`
  flex: 1;
  > p {
    margin-bottom: 0px;
    font-weight: 600;
  }
  > span {
    font-size: 12px;
  }
`;

const AvailableMappings = ({
  mappingSchemas,
  onSetLiveSchema,
  formatDate,
}: AvailableMappingsProps) => {
  return (
    <>
      <h4>Available Mappings</h4>
      <OuterContainer>
        {mappingSchemas.map((s) => (
          <Item key={s.id}>
            <InnerContainer>
              <Summary>
                <p>{s.name}</p>
                <span>{`Created ${formatDate(s.created)}`}</span>
              </Summary>
              <div>
                <SecondaryButton onClick={() => onSetLiveSchema(s.id)}>Activate</SecondaryButton>
              </div>
            </InnerContainer>
          </Item>
        ))}
      </OuterContainer>
    </>
  );
};

export default AvailableMappings;
