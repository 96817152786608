import type { ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import Accordion from 'storybook/stories/cells/Accordion';

interface SegmentCardProps {
  header: ReactNode;
  body: ReactNode;
  isOpen: boolean;
  onHeaderClick: () => void;
  onCardEnter: () => void;
  onCardLeave: () => void;
}

const SegmentCard = ({
  header,
  body,
  isOpen,
  onHeaderClick,
  onCardEnter,
  onCardLeave,
}: SegmentCardProps) => {
  return (
    <Card
      style={{ overflow: 'inherit' }}
      className="mb-0"
      onMouseEnter={onCardEnter}
      onMouseLeave={onCardLeave}
    >
      <Card.Header onClick={onHeaderClick}>{header}</Card.Header>
      <Accordion.Root isOpen={isOpen}>
        <Accordion.Body>
          <Card.Body>{body}</Card.Body>
        </Accordion.Body>
      </Accordion.Root>
    </Card>
  );
};

export default SegmentCard;
