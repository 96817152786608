import useDateSelectOptions, { type DateSelectOption } from 'hooks/useDateSelectOptions';
import type { SingleValue } from 'react-select';

const useDateFilterSelectOptions = <CreatedAt extends { after?: string; before?: string }>(
  createdAt?: CreatedAt,
  date?: Date
) => {
  const dateSelectOptions = useDateSelectOptions(date);

  const defaultDateSelectOption: SingleValue<DateSelectOption> =
    dateSelectOptions.find((option) => option.value[0] === createdAt?.after) ||
    dateSelectOptions[0];

  return { dateSelectOptions, defaultDateSelectOption };
};

export default useDateFilterSelectOptions;
