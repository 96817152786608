import Flex from '@react-css/flex';
import { Controller, useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';

import {
  ACKNOWLEDGEMENT,
  FILE_UPLOAD,
  HELLO_SIGN,
  TERMS_AND_CONDITIONS,
} from 'containers/PartnerPage/Actions/Kinds';
import RadioButton from 'storybook/stories/molecules/RadioButton';

import { RadioButtonLabel } from '../Shared.styles';

interface CreateActionKindModalProps {
  showModal: boolean;
  setShowCreateActionKindModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSelectKind: ({ actionKind }: SelectActionKindFormParams) => void;
  isActionTemplate: boolean;
}

export type SelectActionKindFormParams = {
  actionKind: string;
};

const CreateActionKindModal = ({
  showModal,
  setShowCreateActionKindModal,
  onSelectKind,
  isActionTemplate,
}: CreateActionKindModalProps) => {
  const { handleSubmit, control } = useForm<SelectActionKindFormParams>({
    defaultValues: {
      actionKind: FILE_UPLOAD,
    },
  });

  return (
    <Modal.Root
      data-testid="action-kind-modal"
      show={showModal}
      aria-labelledby="create-action-kind-modal"
      onHide={() => {
        setShowCreateActionKindModal(false);
      }}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Action {isActionTemplate ? 'Template' : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mb-4">What type of action?</h4>
        <form onSubmit={handleSubmit(onSelectKind)}>
          <Flex column gap="18px">
            <Controller
              name="actionKind"
              data-testid="actionKind"
              control={control}
              render={({ field }) => (
                <>
                  <RadioButton
                    name="actionKind"
                    id="create-action-kind-file-upload"
                    value={FILE_UPLOAD}
                    defaultChecked
                    onChange={field.onChange}
                  >
                    <div className="mb-2">
                      <RadioButtonLabel>File Upload</RadioButtonLabel>
                      <span className="small text-muted">
                        I want my partner to provide me with a file
                      </span>
                    </div>
                  </RadioButton>

                  <RadioButton
                    name="actionKind"
                    id="create-action-kind-agreement"
                    value={HELLO_SIGN}
                    onChange={field.onChange}
                  >
                    <div className="mb-2">
                      <RadioButtonLabel>Agreement</RadioButtonLabel>
                      <span className="small text-muted">
                        I want my partner to sign an agreement (E-Signature)
                      </span>
                    </div>
                  </RadioButton>

                  <RadioButton
                    name="actionKind"
                    id="create-action-kind-acknowledgement"
                    value={ACKNOWLEDGEMENT}
                    onChange={field.onChange}
                  >
                    <div className="mb-2">
                      <RadioButtonLabel>Acknowledgement of Document</RadioButtonLabel>
                      <span className="small text-muted">
                        I want to send my partner a document and have them confirm they have seen it
                      </span>
                    </div>
                  </RadioButton>

                  <RadioButton
                    name="actionKind"
                    id="create-action-kind-terms-and-conditions"
                    value={TERMS_AND_CONDITIONS}
                    onChange={field.onChange}
                  >
                    <div className="mb-2">
                      <RadioButtonLabel>Terms and Conditions</RadioButtonLabel>
                      <span className="small text-muted">
                        I want to send my partner a terms and conditions document for them to sign
                      </span>
                    </div>
                  </RadioButton>
                </>
              )}
            />
          </Flex>
          <div className="d-flex justify-content-end mt-3">
            <PrimaryButton size="small" type="submit">
              Select action
            </PrimaryButton>
          </div>
        </form>
      </Modal.Body>
    </Modal.Root>
  );
};

export default CreateActionKindModal;
