import type React from 'react';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

type ActionsPaginationProps = {
  page: number;
  setPage: (page: number) => void;
  resultsSize: number;
  limit: number;
};

const ActionsPagination = ({ page, setPage, resultsSize, limit }: ActionsPaginationProps) => {
  if (page === 0 && resultsSize <= limit) {
    return null;
  }
  const onPreviousPage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (page > 0) {
      setPage(page - 1);
    }
  };
  const nextPage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPage(page + 1);
  };
  return (
    <div className="text-center">
      {page > 0 ? (
        <SecondaryButton color="secondary" id="previousPagingBtn" onClick={onPreviousPage}>
          Previous
        </SecondaryButton>
      ) : null}
      <SecondaryButton color="secondary" disabled>
        {page + 1}
      </SecondaryButton>
      {resultsSize >= limit && (
        <SecondaryButton color="secondary" id="nextPagingBtn" onClick={nextPage}>
          Next
        </SecondaryButton>
      )}
    </div>
  );
};

export default ActionsPagination;
