import Flex from '@react-css/flex';
import Spinner from 'components/Common/Spinner';
import { SettingsPageHeader } from 'containers/SettingsPage/SettingsLayout';
import useAlertQueue from 'hooks/useAlertQueue';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import { selectUser } from 'store/selectors/me/user';
import { connectStripe, createBillingPortal, createUSBillingPortal } from 'store/slices/stripe';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { ACCOUNT_TYPE_BUYER, ACCOUNT_TYPE_SELLER } from 'utils/constants';
import BillingSettingsForm from './BillingSettingsForm';

const ManagePaymentMethod = ({
  commerceType,
  hasUS,
  onPortalButtonClick,
  isPortalButtonDisabled,
  onUsPortalButtonClick,
  isUsPortalButtonDisabled,
}) => {
  return (
    <>
      <h4>Manage Payment Method</h4>
      <p className="text-muted small">
        You can manage your payment method (credit card) through the Stripe billing portal.{' '}
        {commerceType === ACCOUNT_TYPE_SELLER
          ? 'Sellers on the standard payment plan do not need to add a payment method.'
          : "This card will receive the charges for both your Modern Dropship platform fee as well as for your seller's invoices."}
      </p>
      {hasUS === true && (
        <p className="text-muted small">
          To see subscriptions and invoices issued from our US Stripe account, go to the US Billing
          Portal.
        </p>
      )}
      <div className="d-flex">
        <SecondaryButton
          size="small"
          onClick={onPortalButtonClick}
          disabled={isPortalButtonDisabled}
        >
          {isPortalButtonDisabled ? (
            <Spinner color="primary" className="mx-4" small />
          ) : (
            'Access Billing Portal'
          )}
        </SecondaryButton>

        {hasUS === true && (
          <SecondaryButton
            size="small"
            onClick={onUsPortalButtonClick}
            disabled={isUsPortalButtonDisabled}
            className="ml-2"
          >
            {isUsPortalButtonDisabled ? (
              <Spinner color="primary" className="mx-4" small />
            ) : (
              'Access US Billing Portal'
            )}
          </SecondaryButton>
        )}
      </div>
    </>
  );
};

const ConnectStripe = ({ stripeConnected, gateway, stripeAccountId, disabled, onClick }) => {
  return (
    <>
      <hr className="my-4" />
      <h4>
        Connect Stripe Account
        {stripeConnected && (
          <Badge variant="success" pill className="ml-3">
            Connected
          </Badge>
        )}
      </h4>
      <p className="text-muted small">
        {stripeConnected &&
          'You have successfully connected a Stripe account. You can manage the details of that account through Stripe.'}

        {!stripeConnected &&
          (gateway === 'other'
            ? 'You have chosen to manage your own billing for orders. Connect your Stripe account if you would like to automate it.'
            : 'As a seller, you can automatically bill your customers by connecting a Stripe account. Once connected, this account will receive payouts for orders processed through Modern Dropship.')}

        {gateway !== 'stripe' && (
          <a href="https://stripe.com/en-ca/pricing" target="_blank" rel="noopener noreferrer">
            {' '}
            Read about Stripe&#39;s terms.
          </a>
        )}
      </p>
      {gateway !== 'stripe' || !stripeAccountId ? (
        <SecondaryButton size="small" disabled={disabled} onClick={onClick}>
          {disabled ? <Spinner color="primary" className="mx-3" small /> : 'Connect Stripe'}
        </SecondaryButton>
      ) : null}
      <p className="small mt-3">
        <strong>Important: </strong>You will need to configure tax rates in Stripe.{' '}
        <SupportLink article="8lwln18tin#stripe_taxes">Learn more about enabling taxes</SupportLink>
        .
      </p>
    </>
  );
};

const BillingSettings = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const user = useAppSelector(selectUser);
  const alertQueue = useAlertQueue();
  const { isConnecting, isCreatingBillingPortal, isCreatingUSBillingPortal } = useSelector(
    (state) => state.stripe
  );
  const stripeConnected = company.gateway === 'stripe' && company.stripeAccountId;
  const isBuyer = company.commerceType === ACCOUNT_TYPE_BUYER;

  const accessBillingPortal = () => {
    dispatch(createBillingPortal()).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
      }
    });
  };

  const accessUSBillingPortal = () => {
    dispatch(createUSBillingPortal()).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
      }
    });
  };

  const connectStripeAccount = () => {
    dispatch(connectStripe()).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
      }
    });
  };

  return (
    <div className="p-4">
      <SettingsPageHeader title="Billing" />

      <Flex column gap="20px">
        {isBuyer && <BillingSettingsForm company={company} />}

        <ManagePaymentMethod
          commerceType={company.commerceType}
          hasUS={user?.subscriptions?.hasUS}
          onPortalButtonClick={accessBillingPortal}
          isPortalButtonDisabled={isCreatingBillingPortal}
          onUsPortalButtonClick={accessUSBillingPortal}
          isUsPortalButtonDisabled={isCreatingUSBillingPortal}
        />

        {company.commerceType === ACCOUNT_TYPE_SELLER && (
          <ConnectStripe
            stripeConnected={stripeConnected}
            gateway={company.gateway}
            stripeAccountId={company.stripeAccountId}
            disabled={isConnecting}
            onClick={connectStripeAccount}
          />
        )}
      </Flex>
    </div>
  );
};

export default BillingSettings;
