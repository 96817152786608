import Flex from '@react-css/flex';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Spinner from 'components/Common/Spinner';
import useAlertQueue from 'hooks/useAlertQueue';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectCompany } from 'store/selectors/me/company';
import { isDone } from 'store/slices/getStarted';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import EmailInput from 'storybook/stories/molecules/Input/EmailInput';
import Label from 'storybook/stories/molecules/Label';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import Select from 'storybook/stories/molecules/Select';
import type { ReactSelectOption } from 'types/general';
import { CommerceType } from 'types/models/company';
import { useBoolean } from 'usehooks-ts';
import { fetchGetStarted } from 'utils/api/auth';
import { invitePartner, type InvitePartnerParams } from 'utils/api/partners';
import { getPriceLists } from 'utils/api/priceLists';
import { getNameOrEmail } from 'utils/partners';

interface InvitePartnerForm {
  email: string;
  priceId: string;
}

const InvitePartner = () => {
  const company = useSelector(selectCompany);
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();
  const queryClient = useQueryClient();
  const isBuyer = company?.commerceType === CommerceType.Buyer;

  // Initial state
  // const [disableInvite, setDisableInvite] = useState(false);
  const shouldDisableInvite = useBoolean(false);
  const [priceListOptions, setPriceListOptions] = useState<ReactSelectOption[]>([]);

  // Populate price list options for sellers
  const { isFetching: isFetchingPrices } = useQuery({
    queryKey: ['priceLists', { page: 0, limit: 250 }],
    queryFn: () => getPriceLists({ page: 0, limit: 250 }),
    enabled: !isBuyer,
    onError: (error: { message: string }) => {
      addErrorAlert('Error fetching price lists', error.message || '');
    },
    onSuccess: (response) => {
      setPriceListOptions(
        response.data.map((priceList) => ({
          label: priceList.name,
          value: priceList.id,
        })) || []
      );
    },
  });

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<InvitePartnerForm>({
    mode: 'onChange',
  });

  const addingPartner = useMutation({
    mutationFn: (data: InvitePartnerParams) => invitePartner(data),
    onSuccess: (partner) => {
      queryClient.invalidateQueries(['getPartners']);
      const nameOrEmail = getNameOrEmail(partner, isBuyer);
      if (isBuyer) {
        addSuccessAlert(
          'Invite Sent',
          `Your partnership invitation to ${nameOrEmail} has been sent. Your products are now available for ${nameOrEmail} to select once they have accepted your partnership invitation.`
        );
      } else {
        addSuccessAlert(
          'Invite Sent',
          `Your partnership invitation to ${nameOrEmail} has been sent. ${nameOrEmail} will receive an email with instructions on how to partner with you and make their products available to your company.`
        );
      }
    },
    onError: (error: { message: string }) => {
      addErrorAlert("Couldn't send invite", error.message || '');
    },
  });

  const onSubmit = handleSubmit(({ email, priceId }) => {
    const partnerData = isBuyer ? { email } : { email, priceId };

    addingPartner.mutate(partnerData, {
      onSuccess: () => {
        reset();
      },
    });
  });

  // Disable invite if buyer hasn't set up billing
  useQuery({
    queryKey: ['gettingStarted', isBuyer],
    queryFn: () => fetchGetStarted(),
    onSuccess: (data) => {
      const { addBillingAddress, addPaymentMethod } = data.data;
      if (
        addBillingAddress?.required &&
        addPaymentMethod?.required &&
        !(isDone(addBillingAddress.status) && isDone(addPaymentMethod.status))
      ) {
        shouldDisableInvite.setTrue();
      }
    },
    enabled: isBuyer && !company?.gettingStarted?.allStepsCompleted,
  });

  // Use direct to API call and skipping Redux till prices is ported to new
  // notation.
  if (isFetchingPrices) {
    return (
      <Card>
        <Flex column alignItemsCenter>
          <Body variant="Body/Body Small" color="bodyTextSecondary">
            Fetching prices...
          </Body>
        </Flex>
      </Card>
    );
  }
  if (!isBuyer && priceListOptions.length === 0) {
    return (
      <Card>
        <Flex column alignItemsCenter gap="16px">
          <Heading variant="Headings/H2">Create a Price List</Heading>

          <Body variant="Body/Body Small" color="bodyTextSecondary">
            Before you can invite a partner, you need to <a href="/prices">create a price list</a>.
          </Body>
        </Flex>
      </Card>
    );
  }

  // if the buyer hasn't set up billing, disable inviting
  if (isBuyer && shouldDisableInvite.value) {
    return (
      <Card>
        <Flex column alignItemsCenter gap="16px">
          <Heading variant="Headings/H2">Invite a New Seller</Heading>
          <Body
            variant="Body/Body Small"
            color="bodyTextSecondary"
            aria-label="setup billing message"
          >
            You need to add a <Link to="/settings/dropshipping/billing">payment method</Link> and{' '}
            <Link to="/settings/company/locations">billing address</Link> before you can invite
            partners to transact with.
          </Body>
        </Flex>
      </Card>
    );
  }

  return (
    <Card>
      <Flex column gap="16px">
        <Heading variant="Headings/H2">Invite a New {isBuyer ? 'Seller' : 'Buyer'}</Heading>
        {isBuyer ? (
          <Body variant="Body/Body Small" color="bodyTextSecondary">
            Once they accept, their products will appear in <Link to="/products">Products.</Link>{' '}
          </Body>
        ) : (
          <Body variant="Body/Body Small" color="bodyTextSecondary">
            You can update their Price List after they accept by clicking on &quot;Manage&quot;.
          </Body>
        )}
        <form onSubmit={onSubmit}>
          <Flex column gap="16px">
            <Flex column gap="8px">
              <Label htmlFor="email">Email</Label>
              <EmailInput
                id="email"
                {...register('email', { required: true })}
                placeholder="jane@example.com"
              />
            </Flex>
            {!isBuyer && (
              <Flex column gap="8px">
                <Label htmlFor="priceId">Price List</Label>
                <Controller
                  name="priceId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="priceId"
                      options={priceListOptions}
                      onChange={(newValue) => field.onChange(newValue?.value)}
                      value={priceListOptions.find((option) => option.value === field.value)}
                      placeholder="Select a price list"
                      // Force the select element to fit width
                      styles={{
                        container: (provided) => ({
                          flexGrow: 1,
                          ...provided,
                        }),
                        input: (provided) => ({
                          minWidth: '0px',
                          ...provided,
                        }),
                      }}
                    />
                  )}
                />
              </Flex>
            )}
            <Flex alignItemsCenter gap="8px">
              {isSubmitting ? (
                <PrimaryButton size="small" type="submit" id="createPartnerSubmit" disabled>
                  <Spinner color="white" className="mx-2" small />
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  size="small"
                  type="submit"
                  id="createPartnerSubmit"
                  data-testid="submit-button"
                >
                  Send Invite
                </PrimaryButton>
              )}
              <TertiaryButton
                size="small"
                as={SupportLink}
                article={isBuyer ? 'myjn4aiax9' : 'dvwv9wl2ws'}
              >
                Learn more
              </TertiaryButton>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Card>
  );
};

export default InvitePartner;
