import { get, isArray, isString, last } from 'lodash';
import type { ErrorMessage } from 'types/api';

export enum ResponseStatus {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  TIMEOUT = 408,
  CONFLICT = 409,
  LOCKED = 423,
  INTERNAL_SERVER_ERROR = 500,
  UNPROCESSABLE_ENTITY = 422,
}

export enum ServerErrorKeys {
  CLIENT_MUST_REAUTHENTICATE = 'client_must_reauthenticate',
  REFRESH_REQUIRED = 'refresh_required',
  SESSION_NOT_PROVIDED = 'session_not_provided',
  INVALID_SESSION = 'invalid_session',
}

type ErrorBody = string | { message: string }[] | Record<string, string[]>;

export default class ApiError extends Error {
  errorBody: ErrorBody;

  responseStatus?: Response['status'];

  serverErrorKey?: string;

  serverErrorMessages?: ErrorMessage[] = [];

  constructor(errorBody: ErrorBody, responseStatus?: Response['status'], serverErrorKey?: string) {
    super();

    this.name = 'ApiError';

    this.errorBody = errorBody;
    this.responseStatus = responseStatus;

    this.setMessages();
    this.setServerErrorKey(serverErrorKey);
  }

  private setServerErrorKey(serverErrorKey?: string) {
    // Attempt to extract authErr from new-style errors if available,
    // otherwise default to internal server error
    const errorCodes = get(this.errorBody, 'errorCode', [serverErrorKey]);

    // Use the last error code in the list with the assumption that it's the most relevant
    this.serverErrorKey = last(errorCodes);
  }

  private setMessages() {
    if (isString(this.errorBody)) {
      this.message = this.errorBody;
      this.serverErrorMessages = [{ message: this.errorBody }];
    } else if (isArray(this.errorBody)) {
      this.message = this.errorBody[0].message;
      this.serverErrorMessages = this.errorBody;
    } else {
      // Assume new-style error object
      const errorMessages: ErrorMessage[] = Object.entries(this.errorBody).map(([key, value]) => ({
        message: `${key}: ${value.join(', ')}`,
      }));

      this.message = errorMessages.map((error) => error.message).join(', ');
      this.serverErrorMessages = errorMessages;
    }
  }

  /**
   * Response Status Helpers
   */

  public get isUnauthorized() {
    return this.responseStatus === ResponseStatus.UNAUTHORIZED;
  }

  public get isUnprocessableEntity() {
    return this.responseStatus === ResponseStatus.UNPROCESSABLE_ENTITY;
  }

  public get isNotFound() {
    return this.responseStatus === ResponseStatus.NOT_FOUND;
  }

  public get isConflict() {
    return this.responseStatus === ResponseStatus.CONFLICT;
  }

  public get isLocked() {
    return this.responseStatus === ResponseStatus.PAYMENT_REQUIRED;
  }

  /**
   * Server Error Key Helpers
   */

  public get isCookieInvalid() {
    return (
      this.serverErrorKey === ServerErrorKeys.SESSION_NOT_PROVIDED ||
      this.serverErrorKey === ServerErrorKeys.INVALID_SESSION
    );
  }

  public get shouldRefresh() {
    return this.serverErrorKey === ServerErrorKeys.REFRESH_REQUIRED;
  }

  public get shouldReauthenticate() {
    return this.serverErrorKey === ServerErrorKeys.CLIENT_MUST_REAUTHENTICATE;
  }
}
