import { Component } from 'react';

import { iframeIsFromShopify } from 'utils/iframe';
import './IFramePage.css';

class IFramePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: null,
    };
  }

  render() {
    const { companyId } = this.state;

    let convictionalLink = 'https://app.convictional.com/settings';
    if (iframeIsFromShopify()) {
      convictionalLink += `?s=shopify-embedded-app`;
    } else {
      convictionalLink += `?s=other-embedded-app`;
    }
    if (companyId) {
      convictionalLink += `&company=${companyId}`;
    }

    // const statusContent = (
    //   <div className="shopify-page-status-wrapper">
    //     <p className="shopify-page-status-text">
    //       <div className="shopify-page-status status-green" />
    //       Your Shopify account is setup.
    //     </p>
    //   </div>
    // )
    // const statusContent = (
    //   <div className="shopify-page-status-wrapper">
    //     <p className="shopify-page-status-text">
    //       <div className="shopify-page-status status-red" />
    //       Your Shopify account not setup. Connect it through the settings page in the <a href={convictionalLink} target="_blank noreferrer">Modern Dropship app</a>.
    //     </p>
    //   </div>
    // )
    //
    const statusContent = null;

    return (
      <div className="container-fluid">
        <div className="row shopify-page-row">
          <div className="col-md-3" />
          <div className="col-md-6">
            <div className="text-center">
              <img src="/logo.png" className="shopify-page-logo" alt="Modern Dropship logo" />
            </div>
            <p className="shopify-page-text-style">
              We have removed the ability to access Modern Dropship from the embedded app for
              security reasons. You can still access your Modern Dropship account through our
              website:
            </p>
            <div className="text-center shopify-page-go-to-btn-wrapper">
              <a href={convictionalLink} target="_parent" className="shopify-page-go-to-btn">
                Go to moderndropship.com
              </a>
            </div>
            {statusContent}
          </div>
        </div>
      </div>
    );
  }
}

export default IFramePage;
