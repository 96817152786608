/* eslint-disable no-nested-ternary, jsx-a11y/label-has-associated-control */
import { isRejected } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'storybook/stories/cells/Modal';

import Button from 'components/Common/Button';
import Message from 'components/Common/Message';
import { useAppSelector } from 'store';
import { selectIsProductPlatformEdi } from 'store/selectors/me/company';
import {
  createCSVMappings,
  CSV_FILE_TYPE,
  getFiles,
  getInventoryFileSampleData,
  getProductFileSampleData,
  useGetFiles,
} from 'store/slices/sftpUser';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { convictionalFields, supportedFileDelimiters } from 'utils/csvMappings';
import { useSafeState } from 'utils/hooks';

// Form options for CSV Mappings corresponding to the product file template fields
const CSV_MAPPINGS_DEFAULT = convictionalFields.reduce(
  (m, { name, convictionalField }) => ({ ...m, [name]: convictionalField }),
  {}
);

const ProductFilesDetailsModal = ({ showCSVMappingsModal, hideModal }) => {
  const [isUsingProvidedTemplate, setIsUsingProvidedTemplate] = useSafeState(true);
  const isProductPlatformEdi = useAppSelector(selectIsProductPlatformEdi);
  const { files } = useSelector(({ sftpUser }) => sftpUser);
  const { company } = useSelector(({ me }) => me);

  const {
    handleSubmit,
    formState,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      inventoryFilename: '',
      productFilename: '',
      delimiter: ',',
    },
  });
  const { dirtyFields } = formState;

  const [error, setError] = useSafeState('');
  const dispatch = useDispatch();

  const downloadDirectory = company?.sftp?.downloadDirectory ?? '';

  // Get product files before displaying modal contents
  const { loading } = useGetFiles(downloadDirectory);

  const shouldDisplayInventorySelect = !isUsingProvidedTemplate && !isProductPlatformEdi;

  const retryGetFiles = () => {
    setError('');
    const queryParams = {
      fileType: CSV_FILE_TYPE,
      key: downloadDirectory,
    };
    dispatch(getFiles(queryParams)).then((action) => {
      if (action.error) {
        setError(action.error.message);
        console.error('Unable to get files', action.error.message);
      }
    });
  };

  const submit = (data) => {
    const { productFilename, inventoryFilename, delimiter } = data;

    const query = {
      productFilename: `${downloadDirectory}${productFilename}`,
      delimiter,
      fileType: CSV_FILE_TYPE,
    };
    setError('');
    dispatch(getProductFileSampleData(query))
      .then((action) => {
        if (action.error) {
          // Stop promise chain early
          console.error('Unable to get product file sample data', action.error.message);
          throw new Error(action.error.message);
        } else if (inventoryFilename !== '') {
          const inventoryFileData = { inventoryFilename, delimiter };
          dispatch(getInventoryFileSampleData(inventoryFileData)).then((response) => {
            if (response.error) {
              // This promise chain has to handle errors seperately
              setError(response.error.message);
              console.error('Unable to get inventory file sample data', response.error.message);
            }
          });
        }
      })
      .then(() => {
        if (isUsingProvidedTemplate) {
          dispatch(createCSVMappings(CSV_MAPPINGS_DEFAULT)).then((action) => {
            if (isRejected(createCSVMappings)(action)) {
              // list of response errors can be found at action.payload.errors
              // This promise chain has to handle errors separately
              setError(
                (action.payload.errors || []).reduce(
                  (previous, current) => `${previous}\n${current.message}`,
                  ''
                )
              );
              console.error('Unable to create CSV mappings', action.payload.errors);
            }
          });
        }
      })
      .catch((thrownError) => {
        setError(thrownError.message);
      });
  };

  return (
    <Modal.Root
      show={showCSVMappingsModal}
      size="xl"
      aria-labelledby="products-csv-mappings-modal"
      centered
      onHide={hideModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create CSV Mappings</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : files.length === 0 ? (
          <>
            <h4 style={{ lineHeight: 1.3 }}>
              We could not find any of your product files. Please upload files to our SFTP Server
              before attempting to create CSV Mappings.
            </h4>
            <div className="d-flex justify-content-center mt-4">
              <SecondaryButton
                as={SupportLink}
                article="delewtxyn5#step_2_upload_a_products_file"
                className="mr-3"
              >
                <Icon name="help">File Formats</Icon>
              </SecondaryButton>

              <PrimaryButton onClick={retryGetFiles}>
                <Icon name="cached">Retry</Icon>
              </PrimaryButton>
            </div>
          </>
        ) : (
          <>
            <h4 className="mb-4">
              Add your product file details so we can retrieve the file data.
            </h4>

            {error && (
              <Message kind="error" show animate={false}>
                {error}
              </Message>
            )}

            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group mb-4">
                <label>
                  Does your product file conform to our{' '}
                  <SupportLink article="7og0t9go64#template">template</SupportLink>?
                </label>
                <br />
                <label>
                  <input
                    data-testid="using-provided-template-yes"
                    type="radio"
                    value
                    checked={isUsingProvidedTemplate}
                    onChange={() => setIsUsingProvidedTemplate(true)}
                  />{' '}
                  Yes
                </label>
                <br />
                <label>
                  <input
                    data-testid="using-provided-template-no"
                    type="radio"
                    value={false}
                    checked={!isUsingProvidedTemplate}
                    onChange={() => setIsUsingProvidedTemplate(false)}
                  />{' '}
                  No
                </label>
              </div>

              <div className="form-group mb-4">
                <label htmlFor="productFilename">Select your product file</label>
                <select
                  data-toggle="select"
                  className="form-control"
                  id="productFilename"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('productFilename', { required: 'Product file must be chosen' })}
                  defaultValue=""
                >
                  <option key="productfile-empty" value="" id="product-default">
                    ---
                  </option>
                  {files.map((filename) => (
                    <option key={filename} value={filename} id={`product-${filename}`}>
                      {filename}
                    </option>
                  ))}
                </select>
                {errors.productFilename && (
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    {errors.productFilename?.message}
                  </div>
                )}
              </div>
              <div className="form-group mb-4">
                <label htmlFor="delimiter">
                  Specify your file delimiter. If your delimiter is not present, please contact
                  support.
                </label>
                <select
                  data-toggle="select"
                  className="form-control"
                  id="delimiter"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('delimiter', { required: 'File delimiter cannot be empty' })}
                  defaultValue=""
                >
                  <option key="delimiter-empty" value="" id="delimiter-default">
                    ---
                  </option>
                  {supportedFileDelimiters.map((delimiter) => (
                    <option
                      key={delimiter.type}
                      value={delimiter.value}
                      id={`delimiter-${delimiter.type}`}
                    >
                      {delimiter.text}
                    </option>
                  ))}
                </select>
                {errors.delimiter && (
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    {errors.delimiter?.message}
                  </div>
                )}
              </div>
              {shouldDisplayInventorySelect && (
                <div className="form-group mb-4">
                  <label htmlFor="inventoryFilename">
                    (Optional) Select your inventory file (if inventory is not included in the
                    product file)
                  </label>
                  <select
                    data-toggle="select"
                    className="form-control"
                    id="inventoryFilename"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('inventoryFilename')}
                    defaultValue=""
                  >
                    <option key="inventory-empty" value="" id="inventory-default">
                      ---
                    </option>
                    {files.map((filename) => (
                      <option key={filename} value={filename} id={`inventory-${filename}`}>
                        {filename}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="form-group mb-4">
                <Button block color="primary" type="submit" disabled={!dirtyFields.productFilename}>
                  Find File
                </Button>
              </div>
            </form>
          </>
        )}
      </Modal.Body>
    </Modal.Root>
  );
};

export default ProductFilesDetailsModal;
