/* eslint-disable no-nested-ternary */
import Flex from '@react-css/flex';
import { useState } from 'react';

import Button from 'components/Common/Button';
import PageHeader from 'components/Common/PageHeader';
import PlatformBadge from 'components/Common/PlatformBadge';
import BackLink from 'components/Dashboard/BackLink';
import Icon from 'storybook/stories/molecules/Icon';
import { friendlyPlatformName } from 'utils/platform';

import Tooltip from 'storybook/stories/cells/Tooltip';
import DeleteProductModal from './DeleteProductModal';
import SelectProductButton from './SelectProductButton';

const ProductPageHeader = ({
  product,
  isSeller,
  platform,
  hasEdits,
  onDiscardEdits,
  onSaveEdits,
  updating,
  backUrl = '/products',
  onProductDeleted,
  onSelectToggled,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const title = product ? product.title : 'Product Details';
  const friendlyName = friendlyPlatformName(platform);

  const onDeleteClick = (e) => {
    e.preventDefault();
    setShowDelete(true);
  };

  return (
    <PageHeader>
      <Flex.Item>
        <BackLink backUrl={backUrl} backPageName="Products" />
        <div className="d-flex align-items-center">
          <h1 className="header-title mr-2">{title}</h1>
          {!isSeller && product.synced ? (
            <span className="badge badge-success ml-2 mt-1">Synced</span>
          ) : null}
          {!isSeller && product.selected ? (
            <span className="badge badge-soft-secondary ml-2 mt-1">Selected</span>
          ) : null}
        </div>
      </Flex.Item>
      <Flex.Item>
        {isSeller ? (
          hasEdits ? (
            <>
              <Button color="white" onClick={onDiscardEdits} className="mr-2">
                Discard
              </Button>
              <Button color="primary" onClick={onSaveEdits} disabled={updating}>
                Save
              </Button>
            </>
          ) : platform === 'other' ? (
            <Button color="danger" onClick={onDeleteClick} disabled={showDelete}>
              Delete
            </Button>
          ) : platform === 'csv-sftp' ? (
            <PlatformBadge>
              <Tooltip>
                <Tooltip.Trigger asChild>
                  <Icon name="help" position="after">
                    Managed with SFTP
                  </Icon>
                </Tooltip.Trigger>
                <Tooltip.Content>
                  This product information is synced from your product file(s) in the SFTP server.
                  You can manage active status and attributes here. If you want to make other
                  changes, please update your product file(s) and reupload them in the SFTP server.
                </Tooltip.Content>
              </Tooltip>
            </PlatformBadge>
          ) : platform !== 'other' ? (
            <PlatformBadge>
              <Tooltip>
                <Tooltip.Trigger asChild>
                  <Icon name="help" position="after">
                    Managed in {friendlyName}
                  </Icon>
                </Tooltip.Trigger>
                <Tooltip.Content>
                  You can manage active status, attributes and tags here. If you want to make other
                  changes, please visit your {friendlyName} account.
                </Tooltip.Content>
              </Tooltip>
            </PlatformBadge>
          ) : null
        ) : (
          <SelectProductButton product={product} onSelectToggled={onSelectToggled} />
        )}
      </Flex.Item>
      <DeleteProductModal
        product={product}
        show={showDelete}
        onDismiss={() => setShowDelete(false)}
        onProductDeleted={onProductDeleted}
      />
    </PageHeader>
  );
};

export default ProductPageHeader;
