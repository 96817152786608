import Flex from '@react-css/flex';
import Spinner from 'components/Common/Spinner';
import { PartnerFiltersUi, type PartnerFilters } from 'containers/PartnersPage/PartnerFilters';
import { InnerCard } from 'containers/PartnersPage/PartnerSearch/Shared.styles';
import { useEffect, useState } from 'react';
import SearchForm, { SearchFormButton, SearchFormInput } from 'storybook/stories/cells/SearchForm';
import { useDebounce } from 'usehooks-ts';

type PartnerSearchFormProps = {
  filters: PartnerFilters;
  setFilters: (filters: PartnerFilters) => void;
  onUserFinishedTyping: (inputValue: string) => void;
};

const PartnerSearchForm = ({
  filters,
  setFilters,
  onUserFinishedTyping,
}: PartnerSearchFormProps) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 500);

  const isUserTyping = inputValue !== debouncedInputValue;

  // Fire off the debounced value to the parent component
  // each time a user stops typing for long enough
  useEffect(() => {
    onUserFinishedTyping(debouncedInputValue);
  }, [debouncedInputValue, onUserFinishedTyping]);

  return (
    <InnerCard>
      <Flex gap="16px" alignItemsCenter>
        {isUserTyping && (
          <Flex.Item alignSelfCenter>
            <Spinner animation="border" role="status" size="sm">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Flex.Item>
        )}

        <Flex.Item grow={1}>
          <SearchForm onSubmit={(e) => e.preventDefault()}>
            <SearchFormInput
              placeholder="Search for Partner"
              onChange={(e) => setInputValue(e.target.value)}
            />
            <SearchFormButton />
          </SearchForm>
        </Flex.Item>

        <PartnerFiltersUi filters={filters} setFilters={setFilters} />
      </Flex>
    </InnerCard>
  );
};

export default PartnerSearchForm;
