import type { PaginatedResponse, Response } from 'types/api';
import type { ShippingMethod } from 'types/models/shipping';

import { deleteRequest, getRequest, patchRequest, postRequest } from 'utils/request';

export type ShippingMethodParams = {
  maxDays?: number;
  carrierNames?: string[];
  description?: string;
};

export const createShippingMethod = (
  data: ShippingMethodParams
): Promise<Response<ShippingMethod>> => postRequest(`/shipping/methods`, data);

export const updateShippingMethod = (
  id: string,
  data: ShippingMethodParams
): Promise<Response<ShippingMethod>> => patchRequest(`/shipping/methods/${id}`, data);

export const getShippingMethods = (): Promise<PaginatedResponse<ShippingMethod[]>> =>
  getRequest('/shipping/methods', {});

export const deleteShippingMethod = (id: string): Promise<Response<void>> =>
  deleteRequest(`/shipping/methods/${id}`);

export const downloadShippingLabel = (labelId: string): Promise<Response<string>> =>
  getRequest(`/shipping/labels/${labelId}/download`);
