import Flex from '@react-css/flex';
import Button from 'components/Common/Button';
import { useMessage } from 'components/Common/Message';
import Spinner from 'components/Common/Spinner';
import { SettingsMain, SettingsPageHeader } from 'containers/SettingsPage/SettingsLayout';
import useAlertQueue from 'hooks/useAlertQueue';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import { shippingMethodAdded } from 'store/slices/getStarted';
import { updateUserCompany } from 'store/slices/settings/company';
import Body from 'storybook/stories/molecules/Body';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import Label from 'storybook/stories/molecules/Label';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import Select from 'storybook/stories/molecules/Select';
import { createShippingMethod, getShippingMethods } from 'utils/api/shipping';
import { ACCOUNT_TYPE_SELLER } from 'utils/constants';
import { formatShippingLevel } from 'utils/shipping';
import ShippingMethod from './ShippingMethod';
import ShippingMethodForm from './ShippingMethodForm';

const ShippingMethodFormArea = ({
  isLoading,
  fetchError,
  showCreateForm,
  setShowCreateForm,
  onCreateShippingMethod,
  onCreateSuccess,
  isSeller,
  shippingMethods,
  onDeleteSuccess,
  onUpdateSuccess,
  fastestShippingLevel,
}) => {
  if (isLoading)
    return (
      <div className="d-flex align-items-center">
        <Spinner color="primary" small />
        <p className="small text-muted mb-0 ml-3">Loading Shipping Methods</p>
      </div>
    );

  if (fetchError)
    return (
      <div className="d-flex align-items-center">
        <Icon name="warning" className="text-danger" />
        <p className="small text-danger mb-0 ml-3">Error fetching shipping methods: {fetchError}</p>
      </div>
    );

  if (showCreateForm)
    return (
      <ShippingMethodForm
        onCancel={() => setShowCreateForm(false)}
        onSave={onCreateShippingMethod}
        onSaveSuccess={onCreateSuccess}
        isSeller={isSeller}
      />
    );

  if (shippingMethods.length === 0)
    return (
      <p className="mt-4 text-muted">
        You don&apos;t have any {isSeller ? 'supported' : 'required'} shipping methods configured
      </p>
    );

  return (
    <>
      {shippingMethods.map((method) => {
        return (
          <ShippingMethod
            key={method.id}
            shippingMethod={method}
            isSeller={isSeller}
            onDeleteSuccess={onDeleteSuccess}
            onUpdateSuccess={onUpdateSuccess}
          />
        );
      })}
      <p className="text-muted mt-4 mb-1 small">
        Currently, your fastest shipping level is{' '}
        <strong>{formatShippingLevel(fastestShippingLevel)}</strong>
      </p>
    </>
  );
};

const ShippingSettings = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const alertQueue = useAlertQueue();
  const isSeller = company.commerceType === ACCOUNT_TYPE_SELLER;
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [shippingMethods, setShippingMethods] = useState([]);
  const shippingSettings = company.shippingSettings || {};
  const shippingLabelOwner = shippingSettings?.shippingLabelOwner && {
    label: shippingSettings.shippingLabelOwner === 'buyer' ? 'Your company' : 'Partner',
    value: shippingSettings.shippingLabelOwner,
  };
  const { showSuccessMessage } = useMessage();

  useEffect(() => {
    setIsLoading(true);
    getShippingMethods()
      .then(({ data }) => {
        if (data?.length === 0) {
          setShowCreateForm(true);
          return;
        }
        setShippingMethods(data.sort((a, b) => a.maxDays - b.maxDays));
      })
      .catch((err) => {
        //
        setFetchError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const fastestShippingLevel = useMemo(() => {
    if (shippingMethods?.length === 0) return null;
    let min = shippingMethods[0].maxDays;
    shippingMethods.forEach((method) => {
      if (method.maxDays < min) {
        min = method.maxDays;
      }
    });
    return min;
  }, [shippingMethods]);

  const onCreateShippingMethod = (data) => createShippingMethod(data);

  const onCreateSuccess = ({ data }) => {
    setShippingMethods((prevState) => [...prevState, data].sort((a, b) => a.maxDays - b.maxDays));
    setShowCreateForm(false);
    dispatch(shippingMethodAdded());
  };

  const onDeleteSuccess = (methodId) => {
    setShippingMethods((prevState) => prevState.filter((method) => method.id !== methodId));
  };

  const onUpdateSuccess = ({ data }) => {
    setShippingMethods((prevState) =>
      prevState.map((method) => (method.id === data.id ? data : method))
    );
  };

  const updateShippingLabelOwner = (event) => {
    const shippingSettingsUpdate = {
      ShippingLabelOwner: event?.value || '',
    };
    dispatch(updateUserCompany({ shippingSettings: shippingSettingsUpdate })).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
        console.error(action.error);
        return;
      }
      showSuccessMessage({ message: 'Saved shipping preferences' });
    });
  };

  return (
    <SettingsMain>
      <SettingsPageHeader title="Shipping" />
      <Flex column gap="16px">
        <Body variant="Body/Body Small" className="text-muted">
          You can manage the individual partner settings on the{' '}
          <a href="https://app.convictional.com/partners" target="_blank" rel="noopener noreferrer">
            partnerships page
          </a>
          .
        </Body>

        <Heading variant="Headings/H3">Shipping Responsibility</Heading>

        <p className="small text-muted">
          To update your shipping preferences, you must either{' '}
          <Link to="/settings/dropshipping/integrations">connect to EasyPost</Link> or add a
          shipping account below first. <SupportLink article="anymr8mmb3">More Info</SupportLink>
        </p>

        <Flex column gap="4px">
          <Label htmlFor="shippingLabelOwnership" className="mb-2">
            Shipping label provided by
          </Label>
          <Select
            isClearable
            onChange={updateShippingLabelOwner}
            id="shippingLabelOwnership"
            name="shippingLabelOwnership"
            isDisabled={isSeller}
            value={shippingLabelOwner}
            options={[
              {
                label: 'Your company',
                value: 'buyer',
              },
              {
                label: 'Partner',
                value: 'seller',
              },
            ]}
            placeholder="Select..."
          />
        </Flex>
      </Flex>

      <hr className="my-4" />

      <div className="d-flex justify-content-between align-items-center">
        {showCreateForm ? (
          <h4>{isSeller ? 'Add Supported Shipping Method' : 'Add Required Shipping Method'}</h4>
        ) : (
          <>
            <h4>{isSeller ? 'Supported Shipping Methods' : 'Required Shipping Methods'}</h4>

            <Button
              size="sm"
              color="white"
              onClick={() => {
                setShowCreateForm(true);
              }}
            >
              Add Shipping Method
            </Button>
          </>
        )}
      </div>
      <p className="text-muted small mb-4">
        {isSeller
          ? 'Set up the different shipping methods that you support'
          : 'Set up the different shipping methods that you would want a seller to support'}
      </p>

      <ShippingMethodFormArea
        isLoading={isLoading}
        fetchError={fetchError}
        showCreateForm={showCreateForm}
        setShowCreateForm={setShowCreateForm}
        onCreateShippingMethod={onCreateShippingMethod}
        onCreateSuccess={onCreateSuccess}
        isSeller={isSeller}
        shippingMethods={shippingMethods}
        onDeleteSuccess={onDeleteSuccess}
        onUpdateSuccess={onUpdateSuccess}
        fastestShippingLevel={fastestShippingLevel}
      />
    </SettingsMain>
  );
};

export default ShippingSettings;
