import type { OrderItem } from 'types/models/order';
import type { ShippingLabel, ShippingLabelQuantities } from 'types/models/shipping';

// calculateTotalWeight calculates the total weight of all items in a list of fulfillments
export const calculateTotalWeight = (orderItems: OrderItem[]): number => {
  return orderItems.reduce((totalWeight, orderItem) => {
    return totalWeight + orderItem.weight * orderItem.quantity;
  }, 0);
};

// getItemQuantites maps the order item id to the quantity of that item in its fulfillment
export const getItemQuantities = (orderItems: OrderItem[]): ShippingLabelQuantities => {
  return orderItems.reduce((itemQuantities: ShippingLabelQuantities, orderItem) => {
    return { ...itemQuantities, [orderItem._id]: orderItem.quantity };
  }, {});
};

// getOrderItemsAvailableToShip loops through shipping labels and fulfillments, returning any fulfillments that haven't been fully included in a shipping label
export const getOrderItemsAvailableToShip = (
  shippingLabels: ShippingLabel[] | undefined,
  orderItems: OrderItem[] | undefined
): OrderItem[] => {
  if (!shippingLabels || !orderItems) {
    return [] as OrderItem[];
  }

  // Create a set of all orderItemIds in the shipping labels
  const orderItemIdsInShippingLabels = new Set<string>();
  shippingLabels.forEach((shippingLabel) => {
    Object.keys(shippingLabel.quantities).forEach((orderItemId) => {
      orderItemIdsInShippingLabels.add(orderItemId);
    });
  });

  // Find available items for shipping in the orderItems array
  return orderItems.filter((item) => {
    return item._id && !orderItemIdsInShippingLabels.has(item._id);
  });
};
