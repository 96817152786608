import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import download from 'downloadjs';
import useAlertQueue from 'hooks/useAlertQueue';
import qs from 'qs';
import { useLocation, useParams } from 'react-router';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import Layout from 'storybook/stories/species/Layout';
import { getOTAPackingSlipForOrder } from 'utils/api/orders';
import type ApiError from 'utils/ApiError';

const OrderPackingSlipPage = () => {
  const { search } = useLocation();
  const alertQueue = useAlertQueue();
  const { orderId } = useParams<{ orderId: string }>();
  const tokenParam = qs.parse(search, { ignoreQueryPrefix: true }).token as string;

  const generatingPackingSlipForOrder = useQuery({
    queryKey: ['getOTAPackingSlipForOrder', orderId, tokenParam],
    queryFn: ({ queryKey }) => getOTAPackingSlipForOrder(queryKey[1], queryKey[2]),
    enabled: !!orderId,
    onSuccess: (data) => {
      download(new Blob([data]), 'packingslip.pdf', 'application/pdf');
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Error download packing slip', error.message);
    },
  });

  return (
    <Layout.Root>
      <Layout.Content>
        <Card>
          <Flex column gap="24px">
            <Body variant="Body/Regular">
              {generatingPackingSlipForOrder.isLoading
                ? 'Loading...'
                : 'Your packing slip should download momentarily'}
            </Body>
          </Flex>
        </Card>
      </Layout.Content>
    </Layout.Root>
  );
};

export default OrderPackingSlipPage;
