import Flex from '@react-css/flex';
import Body from 'storybook/stories/molecules/Body';
import styled, { css } from 'styled-components';

/**
 * A wrapper for the `ProgressBar` component.
 */
const ProgressBarContainer = styled.div`
  display: flex;
  gap: 8px;
`;

interface ProgressBarStepProps {
  /**
   * Whether the step has been completed
   */
  isCompleted: boolean;
}

/**
 * A single step in the progress bar. Intended to be used with the `ProgressBar` component.
 */
export const ProgressBarStep = styled.div<ProgressBarStepProps>`
  border-radius: 10px;
  min-width: 32px;
  height: 8px;

  ${({ theme, isCompleted }) => css`
    background-color: ${isCompleted ? theme.color.blue500 : theme.color.gray200};
  `}
`;

export interface ProgressBarProps {
  /**
   * The total number of steps in the progress bar
   */
  totalSteps: number;
  /**
   * The number of steps that have been completed
   */
  completedSteps: number;
}

/**
 * A progress bar that displays the number of completed steps.
 *
 * For example, if `totalSteps` is 4 and `completedSteps` is 2, the progress bar will display 2/4 completed steps.
 */
const ProgressBar = ({ totalSteps, completedSteps }: ProgressBarProps) => (
  <Flex column gap="8px">
    <Body variant="Body/Header" color="gray600">
      Step {completedSteps} of {totalSteps}
    </Body>

    <ProgressBarContainer>
      {[...Array(totalSteps)].map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ProgressBarStep key={`step-${i}`} isCompleted={i < completedSteps} />
      ))}
    </ProgressBarContainer>
  </Flex>
);

export default ProgressBar;
