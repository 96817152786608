import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import type { User, UserUpdate } from 'types/models/user';
import { updateUser as updateUserFromAPI } from 'utils/api/users';

const initialState = {
  isUpdatingUser: false,
};

export const updateUser = createAsyncThunk<
  User,
  UserUpdate,
  {
    state: RootState;
  }
>('settings/account/updateUser', async (userUpdate, thunkAPI) => {
  const { me } = thunkAPI.getState();
  const { user } = me;
  if (user && user._id) {
    const response = await updateUserFromAPI(user._id, userUpdate);
    return response;
  }
  throw Error('User not found');
});

const slice = createSlice({
  name: 'settings/account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (draft) => {
        draft.isUpdatingUser = true;
      })
      .addCase(updateUser.fulfilled, (draft) => {
        draft.isUpdatingUser = false;
      })
      .addCase(updateUser.rejected, (draft) => {
        draft.isUpdatingUser = false;
      });
  },
});

export default slice.reducer;
