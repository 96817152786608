import { merge } from 'lodash';

import { getCSRF } from 'utils/cookies';

// CSRFSafeRoutes are the routes we do *not* want to check CSRF tokens for
const CSRF_SAFE_ROUTES = ['GET', 'HEAD', 'OPTIONS', 'TRACE'];

export interface RequestOptions extends RequestInit {
  headers?: Record<string, string>; // Override RequestInit['headers'] to only accept object shapes
  shouldReturnFullResponse?: boolean; // Sidestep grabbing only the response body and return the full response
}

const baseOptions = (
  method: string,
  additionalHeaders: Record<string, string>,
  additionalOptions: RequestOptions
): RequestOptions => {
  const baseHeaders: Record<string, string> = {};

  if (!CSRF_SAFE_ROUTES.includes(method)) {
    const token = getCSRF();
    if (token) {
      baseHeaders['X-CSRF-Token'] = token;
    } else {
      console.error('CSRF token not set');
    }
  }

  return merge(
    {
      method,
      headers: merge({}, baseHeaders, additionalHeaders),
      credentials: 'include' as RequestCredentials,
    },
    additionalOptions
  );
};

export const postJSONOptions = (
  body: any,
  headers: Record<string, string> = {},
  options: RequestOptions = {}
): RequestOptions =>
  baseOptions(
    'POST',
    merge({ Accept: 'application/json', 'Content-Type': 'application/json' }, headers),
    merge({ body: JSON.stringify(body) }, options)
  );

export const postFormDataOptions = (
  body: FormData,
  customHeaders: Record<string, string> = {},
  options: RequestOptions = {}
): RequestOptions => {
  // Don't set 'Content-Type' so the browser sets it with the correct boundary.
  const baseHeaders: Record<string, string> = { Accept: 'application/json' };
  const headers = { ...baseHeaders, ...customHeaders };
  return baseOptions('POST', headers, { ...options, body });
};

export const putJSONOptions = (
  body: any,
  headers: Record<string, string> = {},
  options: RequestOptions = {}
): RequestOptions =>
  baseOptions(
    'PUT',
    merge({ Accept: 'application/json', 'Content-Type': 'application/json' }, headers),
    merge({ body: JSON.stringify(body) }, options)
  );

export const patchJSONOptions = (
  body: any,
  headers: Record<string, string> = {},
  options: RequestOptions = {}
): RequestOptions =>
  baseOptions(
    'PATCH',
    merge({ Accept: 'application/json', 'Content-Type': 'application/json' }, headers),
    merge({ body: JSON.stringify(body) }, options)
  );

export const deleteJSONOptions = (
  body: any,
  headers: Record<string, string> = {},
  options: RequestOptions = {}
): RequestOptions =>
  baseOptions(
    'DELETE',
    merge({ Accept: 'application/json', 'Content-Type': 'application/json' }, headers),
    merge({ body: JSON.stringify(body) }, options)
  );

export const postCSVOptions = (
  body: any,
  headers: Record<string, string> = {},
  options: RequestOptions = {}
): RequestOptions =>
  baseOptions(
    'POST',
    merge({ Accept: 'application/json', 'Content-Type': 'text/csv' }, headers),
    merge({ body }, options)
  );

// Query

export const getOptions = (
  headers: Record<string, string> = {},
  options: RequestOptions = {}
): RequestOptions => baseOptions('GET', headers, options);

export const getJSONOptions = (
  headers: Record<string, string> = {},
  options: RequestOptions = {}
): RequestOptions => baseOptions('GET', merge({ Accept: 'application/json' }, headers), options);

export const getCsvOptions = (
  headers: Record<string, string> = {},
  options: RequestOptions = {}
): RequestOptions =>
  baseOptions('GET', merge({ 'Content-Type': 'text/csv', Accept: 'text/csv' }, headers), options);
