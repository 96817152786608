import { Link } from 'react-router-dom';

import DoneCheckmark from 'components/Common/DoneCheckmark';
import { CardText } from 'containers/PartnerPage/Actions/Shared.styles';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

const AgreementAction = ({ action }) => {
  const { id, cardTitle, cardText, required, agreementId, completed } = action;
  return (
    <div className="single-action-wrapper align-items-center row">
      <div className="col-auto">
        <DoneCheckmark done={completed} />
      </div>
      <div className="ml-n2 col">
        <h4 className="mb-2">
          <span className="single-action-title">{cardTitle}</span>
          {required ? null : <span className="badge badge-soft-info">Optional</span>}
        </h4>
        <CardText>{cardText}</CardText>
        {completed && <p className="small">Agreement has been signed.</p>}

        {!completed && agreementId && (
          <SecondaryButton as={Link} to={`/agreements/${agreementId}?action=${id}`}>
            Sign Agreement
          </SecondaryButton>
        )}
      </div>
    </div>
  );
};

export default AgreementAction;
