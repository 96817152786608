import styled from 'styled-components';

/**
 * Add a semantic horizontal rule element.
 */
const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: ${({ theme }) => theme.color.gray200};
  margin: 0;
  width: 100%;
`;

export default Divider;
