import _get from 'lodash/get';
import qs from 'qs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getCurrentUserAndCompany, sudoUser } from 'store/slices/me';
import { useSafeState } from 'utils/hooks';

function removePreloader() {
  const preloaderContainer = window.document.getElementById('preloader-container');
  if (preloaderContainer) preloaderContainer.remove();
}

const useLoadApp = () => {
  const [isAppLoading, setIsAppLoading] = useSafeState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // load authed user and their company
    dispatch(getCurrentUserAndCompany()).then((action) => {
      const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (queryParams.sudoCompanyObjectId) {
        if (_get(action, 'payload.user.superUser', false)) {
          dispatch(sudoUser({ companyObjectId: queryParams.sudoCompanyObjectId })).then(
            (response) => {
              if (response.error) {
                removePreloader();
                setIsAppLoading(false);
                return;
              }
              window.location.replace(window.location.pathname);
            }
          );
          return;
        }
        // if not a superuser, just remove the query param by reloading the page without it
        window.location.replace(window.location.pathname);
        return;
      }
      removePreloader();
      setIsAppLoading(false);
    });
  }, [dispatch, setIsAppLoading]);

  return {
    isAppLoading,
  };
};

export default useLoadApp;
