import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Redirect } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';
import { resetMe } from 'store/slices/me';
import Card from 'storybook/stories/cells/Card';
import Heading from 'storybook/stories/molecules/Heading';
import Layout from 'storybook/stories/species/Layout';
import { logMeOut } from 'utils/api/auth';
import { destroyAuthCookies } from 'utils/cookies';

const LogoutPage = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  // Destructure the isLoading and mutate function from the useMutation hook
  // because the result of useMutation is not stable and the mutation must run
  // immediately and only once on mount.
  const { isLoading, mutate: logOut } = useMutation({
    mutationFn: () => logMeOut(),
    onSettled: () => {
      destroyAuthCookies();
      dispatch(resetMe());
    },
  });

  useEffect(() => {
    logOut();
  }, [logOut]);

  if (isLoading || user !== null)
    return (
      <Layout.Root>
        <Layout.Content>
          <Flex column gap="32px" alignItemsCenter>
            <Layout.Logo />

            <Card padding="48px">
              <Heading variant="Headings/H1" align="center">
                Logging out...
              </Heading>
            </Card>
          </Flex>
        </Layout.Content>
      </Layout.Root>
    );

  return <Redirect to="/signin" />;
};

export default LogoutPage;
