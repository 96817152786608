import type { Response } from 'types/api';
import type { SftpUser } from 'types/models/sftp';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'utils/request';

interface SftpUserCredentialParams {
  username: string | null;
  password: string;
}

interface SftpUserCredentialResponse {
  status: string;
}

export const getSFTPCreds = (): Promise<Response<SftpUser>> => getRequest(`/sftp`);

export const createSFTPCreds = (
  params: SftpUserCredentialParams
): Promise<Response<SftpUserCredentialResponse>> => postRequest(`/sftp`, params);

export const updateSFTPCreds = (
  username: string,
  params: SftpUserCredentialParams
): Promise<Response<SftpUserCredentialResponse>> => patchRequest(`/sftp/${username}`, params);

export const deleteSFTPCreds = (username: string): Promise<Response<SftpUserCredentialResponse>> =>
  deleteRequest(`/sftp/${username}`);
