import classNames from 'classnames';

import { FormFieldLabel } from 'components/Common/FormField';
import { forwardRef } from 'react';
import type { FieldError } from 'react-hook-form';

interface UrlFormFieldProps {
  error?: FieldError;
  label?: string;
  id?: string;
  type?: string;
  size?: 'sm' | 'lg';
  className?: string;
  placeholder?: string;
  autoComplete?: 'on' | 'off';
}

const UrlFormField = forwardRef<HTMLInputElement, UrlFormFieldProps>((props, ref) => {
  const { error, label, id, type, size, className, ...inputProps } = props;
  const cn = classNames('form-group', className);
  const inputCN = classNames('form-control', 'form-control-prepended', {
    [`form-control-${size}`]: size,
    'is-invalid': error,
  });
  const inputGroupCN = classNames('input-group', 'input-group-merge', {
    [`input-group-${size}`]: size,
  });

  return (
    <div className={cn}>
      {label && id && (
        <FormFieldLabel size={size} htmlFor={id}>
          {label}
        </FormFieldLabel>
      )}
      <div className={inputGroupCN}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input id={id} type={type} className={inputCN} ref={ref} {...inputProps} />
        <div className="input-group-prepend">
          <div className="input-group-text">
            <span className="">https://</span>
          </div>
        </div>
      </div>
      {error ? <div className="invalid-feedback d-block">{error.message}</div> : null}
    </div>
  );
});

export default UrlFormField;
