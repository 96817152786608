import { useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Spinner from 'components/Common/Spinner';
import useAlertQueue from 'hooks/useAlertQueue';
import type { AppDispatch, RootState } from 'store';
import { getCurrentUserAndCompany } from 'store/slices/me';
import { rollApiKey } from 'store/slices/settings/integrations';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Icon from 'storybook/stories/molecules/Icon';

const VisibilityButton = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  border-color: #d2ddec;
  border-radius: 0 6px 6px 0;
  border-width: 1px;
`;

interface ApiKeySettingsProps {
  dispatch: AppDispatch;
  apiKey: string | undefined;
}

const ApiKeySettings = ({ dispatch, apiKey }: ApiKeySettingsProps) => {
  const alertQueue = useAlertQueue();
  const [showKey, setShowKey] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const { isRollingKey } = useSelector(({ settings }: RootState) => settings.integrations);

  const rollKey = () => {
    // @ts-ignore
    dispatch(rollApiKey()).then(async (action: { error?: { message: string } }) => {
      setConfirm(false);
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
        return;
      }
      alertQueue.addSuccessAlert('Success', 'API Key Updated');
      await dispatch(getCurrentUserAndCompany());
    });
  };

  return (
    <>
      <h4>Modern Dropship API Key</h4>

      <Form.Group controlId="shopify-store-url-input">
        <InputGroup>
          <FormControl disabled type={showKey ? 'text' : 'password'} value={apiKey} />
          <InputGroup.Append>
            <VisibilityButton onClick={() => setShowKey(!showKey)} className="px-3">
              <Icon name={showKey ? 'visibility_off' : 'visibility'} />
            </VisibilityButton>
          </InputGroup.Append>
        </InputGroup>

        <Form.Text muted>
          This is your secret key for accessing our API, please keep it safe.{' '}
          <a href="https://developers.moderndropship.com" target="_blank" rel="noopener noreferrer">
            API Reference
          </a>
        </Form.Text>
      </Form.Group>

      {confirm ? (
        <>
          <p className="mb-2 small">Are you sure you want to change your API key?</p>
          <div className="d-flex">
            <PrimaryButton size="small" onClick={rollKey} disabled={isRollingKey}>
              {isRollingKey ? <Spinner color="primary" className="mx-4" small /> : 'Update API Key'}
            </PrimaryButton>
            <SecondaryButton size="small" onClick={() => setConfirm(false)} className="ml-2">
              Cancel
            </SecondaryButton>
          </div>
        </>
      ) : (
        <SecondaryButton size="small" onClick={() => setConfirm(true)}>
          Roll API Key
        </SecondaryButton>
      )}
    </>
  );
};

export default ApiKeySettings;
