import Flex from '@react-css/flex';
import { isBoolean } from 'lodash';
import { useState } from 'react';
import { useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import Body from 'storybook/stories/molecules/Body';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import Toggle from 'storybook/stories/molecules/Toggle';
import type { Partner, PartnerPerOrderFeeSettings } from 'types/models/partner';
import { PerOrderFeeType } from 'types/models/partner';
import type { UpdatePartnerParams } from 'utils/api/partners';
import { humanizeMoney } from 'utils/currencies';

interface DaysToDelayInvoicesDescriptionProps {
  isSettingEnabled: boolean;
  settingValue: number;
  defaultSetting?: number;
}

const DaysToDelayInvoicesDescription = ({
  isSettingEnabled,
  settingValue,
  defaultSetting = 0,
}: DaysToDelayInvoicesDescriptionProps) => {
  if (!isSettingEnabled) return null;

  if (defaultSetting > 0 && settingValue > 0) {
    return (
      <strong>
        Set this value to <code>0</code> to use{' '}
        <Link to="/settings/dropshipping/billing">your default setting</Link> of{' '}
        <code>{defaultSetting}</code>
      </strong>
    );
  }

  if (defaultSetting > 0 && settingValue === 0) {
    return (
      <strong>
        Now using <Link to="/settings/dropshipping/billing">your default setting</Link> of{' '}
        <code>{defaultSetting}</code>
      </strong>
    );
  }

  return (
    <strong>
      Change <Link to="/settings/dropshipping/billing">your default setting</Link> for all
      partnerships.
    </strong>
  );
};

interface PerOrderFeeDescriptionProps {
  perOrderFeeSettings?: PartnerPerOrderFeeSettings;
}

const perOrderFeeDescription = ({ perOrderFeeSettings }: PerOrderFeeDescriptionProps) => {
  if (
    perOrderFeeSettings?.type === PerOrderFeeType.Fixed &&
    perOrderFeeSettings?.flatFee.amount > 0
  ) {
    return humanizeMoney(perOrderFeeSettings.flatFee);
  }

  if (
    perOrderFeeSettings?.type === PerOrderFeeType.Percentage &&
    perOrderFeeSettings?.percentage > 0
  ) {
    return `${perOrderFeeSettings.percentage}%`;
  }

  return 'No per order fee';
};

export interface BuyerBillingSettingsProps {
  partner: Partner;
  updatePartner: (params: UpdatePartnerParams) => void;
}

const BuyerBillingSettings = ({ partner, updatePartner }: BuyerBillingSettingsProps) => {
  const company = useAppSelector(selectCompany);

  const [enablePaymentTerms, setEnablePaymentTerms] = useState(
    partner.billingSettings.enablePaymentTerms
  );

  const [numBillingDelayDays, setNumBillingDelayDays] = useState(
    partner.billingSettings.paymentTerms.numBillingDelayDays
  );

  const onEnablePaymentTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setEnablePaymentTerms(checked);
    updatePartner({
      billingSettings: {
        enablePaymentTerms: checked,
      },
    });
  };

  const onNumBillingDelayDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNumBillingDelayDays(Number(value));
    updatePartner({
      billingSettings: {
        paymentTerms: {
          numBillingDelayDays: Number(value),
        },
      },
    });
  };

  return (
    <>
      {isBoolean(partner?.billingSettings?.autoTax) && (
        <Flex column gap="4px">
          <Flex.Item>
            <Icon
              as={SupportLink}
              article="v3t009ajik"
              name="help"
              size="16px"
              color="blue500"
              position="after"
            >
              <Body variant="Body/Header" color="bodyTextSecondary">
                Automatic Tax Calculation with TaxJar
              </Body>
            </Icon>
          </Flex.Item>

          {partner?.billingSettings?.autoTax ? 'Enabled' : 'Disabled'}
        </Flex>
      )}

      <Heading variant="Headings/H4">Delayed Invoicing</Heading>

      <Toggle
        name="enablePaymentTerms"
        checked={enablePaymentTerms}
        onChange={onEnablePaymentTermsChange}
        description={
          <span>
            Toggle on to delay invoicing for this partnership.{' '}
            <SupportLink article="kczy0l6p9n">Learn more</SupportLink>
          </span>
        }
      >
        Enable Delayed Invoicing
      </Toggle>

      {enablePaymentTerms && (
        <Flex column gap="4px">
          <Label htmlFor="numBillingDelayDays">Days to Delay Invoices</Label>
          <Flex.Item>
            <Input
              type="number"
              id="numBillingDelayDays"
              name="numBillingDelayDays"
              onChange={onNumBillingDelayDaysChange}
              value={enablePaymentTerms ? numBillingDelayDays : 0}
              disabled={!enablePaymentTerms}
              min={0}
              max={120}
            />
          </Flex.Item>
          <Body
            variant="Inputs/Input Message"
            color="gray500"
            data-testid="days-to-delay-invoices-description"
          >
            The number of days to delay creating invoices for this partner.
            <br />
            <DaysToDelayInvoicesDescription
              isSettingEnabled={enablePaymentTerms}
              settingValue={numBillingDelayDays}
              defaultSetting={company?.defaultBillingSettings?.paymentTerms?.numBillingDelayDays}
            />
          </Body>
        </Flex>
      )}

      <Flex column gap="4px">
        <Flex.Item>
          <Icon
            as={SupportLink}
            article="qmh3qu3lx8"
            name="help"
            size="16px"
            color="blue500"
            position="after"
          >
            <Body variant="Body/Header" color="bodyTextSecondary">
              Per-Order Fee
            </Body>
          </Icon>
        </Flex.Item>

        {perOrderFeeDescription({
          perOrderFeeSettings: partner?.perOrderFeeSettings,
        })}
      </Flex>
    </>
  );
};

export default BuyerBillingSettings;
