import { get as _get, reduce as _reduce } from 'lodash';
import theme, { type ThemeBreakpoints } from 'storybook/stories/theme';
import { css, type CSSObject } from 'styled-components';

type Breakpoints = keyof typeof ThemeBreakpoints;

type BreakpointValue = {
  [key in Breakpoints]: CSSObject;
};

interface BreakpointProps {
  values: BreakpointValue;
}

const addCssProps = (value: CSSObject) => {
  const vals = Object.values(value);
  const keys = Object.keys(value);

  return _reduce(
    keys,
    (m, key, index) => {
      // eslint-disable-next-line
      return (m += `${key}: ${vals[index]};`);
    },
    ''
  );
};

const breakpoints = ({ values }: BreakpointProps) => {
  const breakpointProps = _reduce(
    values,
    (m, value, index) => {
      if (!value) return m;
      const breakpoint = _get(theme.breakpoints, index);
      // eslint-disable-next-line no-return-assign,no-param-reassign
      return (m += `
      @media screen and (${breakpoint}) {
        ${addCssProps(value)}
      }
    `);
    },
    ''
  );
  return css([breakpointProps] as unknown as TemplateStringsArray);
};

export default breakpoints;
