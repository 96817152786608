import PropTypes from 'prop-types';
import styled from 'styled-components';

import DocumentElement from 'containers/EDISchemas/DocumentElement';
import { formatKey } from 'utils/edi';

const Container = styled.div`
  padding: 0px 10px;
  background-color: transparent;
`;

const DocumentSegment = (props) => {
  const {
    segmentActive,
    elementSelected,
    elementHovered,
    segment: { segmentID, segmentIndex, elements },
  } = props;

  return (
    <Container style={segmentActive ? { backgroundColor: '#d2ddec' } : {}}>
      <span>{segmentID}</span>
      {elements.map((element, index) => {
        const formattedKey = formatKey(segmentID, segmentIndex, index);
        return (
          <DocumentElement
            key={formattedKey}
            element={element}
            active={elementSelected === formattedKey || elementHovered === formattedKey}
          />
        );
      })}
      <span>~</span>
    </Container>
  );
};

export default DocumentSegment;

DocumentSegment.propTypes = {
  segmentActive: PropTypes.bool,
  segment: PropTypes.shape({
    segmentID: PropTypes.string,
    elements: PropTypes.arrayOf(PropTypes.string),
  }),
};
