import Flex from '@react-css/flex';
import Message from 'components/Common/Message';
import { useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import { selectIsSudoing } from 'store/selectors/me/user';
import Body from 'storybook/stories/molecules/Body';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';

const SudoWarning = () => {
  const company = useAppSelector(selectCompany);
  const isSudoing = useAppSelector(selectIsSudoing);

  const companyName = company?.name ?? company?.legacyCompanyId ?? 'another company';

  if (!isSudoing) return null;

  return (
    // @ts-ignore
    <Message show animate={false} kind="warning">
      <Flex alignItemsCenter gap="16px">
        <Flex.Item grow={1}>
          <Body variant="Body/Body Small">
            You are currently viewing the application as a user of {companyName}
          </Body>
        </Flex.Item>

        <Body variant="Body/Header">{company?._id}</Body>

        <SecondaryButton
          as={Link}
          to="/settings/preferences/admin"
          size="small"
          kind="neutral"
          $iconName="admin_panel_settings"
        >
          Switch
        </SecondaryButton>
      </Flex>
    </Message>
  );
};

export default SudoWarning;
