// getFromValueFromChanges returns the from value of a change if it exists

import type { Change } from 'types/models/history';

// findChangeByPath returns a change from a list of them if it exists
// It's used to get the historical value of a field that has been changed
const findChangeByPath = (changes: Change[], path: string): Change | null => {
  const change = changes.find((c) => c.path === path);
  if (change) {
    return change;
  }
  return null;
};

export default findChangeByPath;
