import { useDispatch, useSelector } from 'react-redux';

import InternalSFTPSettingsForm from 'containers/SettingsPage/DropshippingTab/IntegrationsSettings/InternalSFTPSettingsForm';
import { createSFTPCreds, useGetSFTPCreds } from 'store/slices/sftpUser';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import Link from 'storybook/stories/molecules/Link';
import { useSafeState } from 'utils/hooks';
import { generatePassword, generateUsername } from 'utils/sftpUser';

const ConnectInternalSFTPCard = ({ done }) => {
  useGetSFTPCreds();
  const dispatch = useDispatch();
  const [error, setError] = useSafeState('');
  const company = useSelector(({ me }) => me.company);
  const { isCreatingSFTPUser, sftpUsername } = useSelector(({ sftpUser }) => sftpUser);
  const username = sftpUsername || generateUsername(company.name, company.legacyCompanyId);

  const handleCreateSFTPCreds = ({ password }) => {
    setError('');

    dispatch(createSFTPCreds({ username, password })).then((action) => {
      if (action.error) {
        setError(action.error.message);
      }
    });
  };

  return (
    <GetStartedCard
      isComplete={done}
      title="Connect your SFTP client"
      completedUi={
        <Body>
          View or modify your connection information in your{' '}
          <Link to="/settings/dropshipping/integrations">Integration settings</Link>.
        </Body>
      }
    >
      <Body as="p">
        Here are all of the details you need to connect your SFTP client to Modern Dropship.
        We&apos;ve suggested a strong password for you in the form below, which you may change if
        you wish.
      </Body>

      <Body as="p">
        Once your SFTP client has been configured, please click the <strong>Set password</strong>{' '}
        button below. This will complete your integration and allow you to connect with your chosen
        password.
      </Body>

      <InternalSFTPSettingsForm
        sftpUsername={username}
        sftpPassword={generatePassword()}
        sftpSettings={company.sftp}
        onSubmit={handleCreateSFTPCreds}
        showPasswordField
        showSubmitButton
        disableSubmitButton={isCreatingSFTPUser}
        submitButtonText="Set password"
        isPasswordDefaultVisible
      />

      {error && <Body color="error500">{error}</Body>}
    </GetStartedCard>
  );
};

export default ConnectInternalSFTPCard;
