import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { updateBigCommerceAuth } from 'store/slices/company';
import { useSafeState } from 'utils/hooks';

import Flex from '@react-css/flex';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

const ConnectBigCommerceCard = ({ done, storeUrl }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [error, setError] = useSafeState('');
  const pending = useSelector(({ company }) => company.isUpdatingPlatformAuth);

  const onSubmit = ({ bigCommerceClientId, bigCommerceAccessToken, bigCommerceHash }) => {
    setError('');
    dispatch(
      updateBigCommerceAuth({
        bigCommerceAuth: {
          platform: 'bigcommerce',
          platformUrl: storeUrl,
          bigCommerceClientId,
          bigCommerceAccessToken,
          bigCommerceHash,
        },
      })
    ).then((action) => {
      if (action.error) {
        setError(action.error.message);
      }
    });
  };

  return (
    <GetStartedCard
      isComplete={done}
      title="Connect your BigCommerce store"
      completedUi={
        <Body>
          Manage your integration from your{' '}
          <Link to="/settings/dropshipping/integrations">Integration settings</Link>.
        </Body>
      }
    >
      <Body as="p">
        Please input your BigCommerce credentials so Modern Dropship can connect to your store.{' '}
        <SupportLink article="mvbcncoh60">More Info</SupportLink>
      </Body>

      <Body>
        Store URL: <strong>{storeUrl}</strong>
      </Body>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex column gap="16px">
          <Flex column gap="8px">
            <Label htmlFor="big-company-hash-input">Company Hash</Label>
            <Input
              placeholder="BigCommerce Company Hash"
              {...register('bigCommerceHash', {
                required: 'BigCommerce Company Hash is required',
              })}
            />
            {errors.bigCommerceHash && (
              <Body color="error500" variant="Body/Body Small">
                {errors.bigCommerceHash.message}
              </Body>
            )}
          </Flex>

          <Flex column gap="8px">
            <Label htmlFor="big-client-id-input">Client ID</Label>
            <Input
              placeholder="BigCommerce API Client ID"
              {...register('bigCommerceClientId', {
                required: 'BigCommerce Client ID is required',
              })}
            />
            {errors.bigCommerceClientId && (
              <Body color="error500" variant="Body/Body Small">
                {errors.bigCommerceClientId.message}
              </Body>
            )}
          </Flex>

          <Flex column gap="8px">
            <Label htmlFor="big-access-token-input">Access Token</Label>
            <Input
              placeholder="BigCommerce Access Token"
              {...register('bigCommerceAccessToken', {
                required: 'BigCommerce Access Token is required',
              })}
            />
            {errors.bigCommerceAccessToken && (
              <Body color="error500" variant="Body/Body Small">
                {errors.bigCommerceAccessToken.message}
              </Body>
            )}
          </Flex>

          <Flex.Item alignSelfEnd>
            <PrimaryButton size="small" type="submit" disabled={pending}>
              Connect
            </PrimaryButton>
          </Flex.Item>
        </Flex>
      </form>

      {error && <Body color="error500">{`Error saving WooCommerce credentials: ${error}`}</Body>}
    </GetStartedCard>
  );
};

export default ConnectBigCommerceCard;
