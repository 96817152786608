import Timeline from 'storybook/stories/organisms/Timeline';
import type { EventComponentProps } from 'storybook/stories/organisms/Timeline/HistoryTimeline';
import { willRenderChanges } from '../ChangeList';
import OrderChangeList, { orderChangeRenderers } from '../ChangeList/OrderChangeList';

const OrderUpdatedPayload = ({ event }: EventComponentProps) => {
  const changeList = <OrderChangeList changes={event.changes} snapshot={event.snapshot} />;

  if (!willRenderChanges(event.changes, orderChangeRenderers)) {
    return <Timeline.Event iconName="edit" timestamp={event.createdAt} title="Order Updated" />;
  }

  return (
    <Timeline.Event iconName="edit" timestamp={event.createdAt} title="Order Updated">
      {changeList}
    </Timeline.Event>
  );
};
export default OrderUpdatedPayload;
