import { useMutation, useQuery } from '@tanstack/react-query';
import Spinner from 'components/Common/Spinner';
import AutonomousMerchandisingSettings from 'containers/SettingsPage/DropshippingTab/MerchandisingSettings/AutonomousMerchandisingSettings';
import useAlertQueue from 'hooks/useAlertQueue';
import { isEmpty, merge } from 'lodash';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCompanyId } from 'store/selectors/me/company';

import type {
  DeepPartial,
  MerchandisingSettings as MerchandisingSettingsType,
} from 'types/general';
import type { Company } from 'types/models/company';
import { getCompany, updateCompany } from 'utils/api/companies';

import {
  SettingsFooter,
  SettingsMain,
  SettingsPageHeader,
} from 'containers/SettingsPage/SettingsLayout';
import Body from 'storybook/stories/molecules/Body';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

interface ContentProps {
  isLoading: boolean;
  company?: Company;
  onSettingsChange: (data: DeepPartial<MerchandisingSettingsType>) => void;
}

const Content = ({ isLoading, company, onSettingsChange }: ContentProps) => {
  if (isLoading) return <Spinner />;
  if (isEmpty(company)) return <Body variant="Body/Header">Unable to fetch Company</Body>;

  return (
    <AutonomousMerchandisingSettings
      settings={company.merchandisingSettings}
      onSettingsChange={onSettingsChange}
    />
  );
};

const MerchandisingSettings = () => {
  const companyId = useSelector(selectCompanyId);
  const alertQueue = useAlertQueue();

  const [updates, setUpdates] = useState<MerchandisingSettingsType>();

  const fetchingCompany = useQuery({
    queryKey: ['getCompany', companyId],
    queryFn: () => getCompany(companyId || ''),
    onError: (error) => {
      console.error('Could not fetch company', error);
    },
  });

  const updatingCompany = useMutation({
    mutationFn: (data: DeepPartial<Company>) => updateCompany(companyId || '', data),
    onSuccess: () => {
      alertQueue.addSuccessAlert('Success', 'Merchandising settings saved.');
    },
    onError: (error) => {
      alertQueue.addErrorAlert('Something went wrong', 'Unable to save merchandising settings.');
      console.error('Unable to save merchandising settings', error);
    },
  });

  const handleSettingsChange = (nextUpdates: DeepPartial<MerchandisingSettingsType>) => {
    setUpdates((prevUpdates) => {
      const newUpdates = merge({}, prevUpdates, nextUpdates);

      if (nextUpdates?.defaultProductTags)
        newUpdates.defaultProductTags = nextUpdates.defaultProductTags as string[];

      return newUpdates;
    });
  };

  const onSaveClick = async () => {
    await updatingCompany.mutateAsync({ merchandisingSettings: updates });
    fetchingCompany.refetch();
  };

  const company = fetchingCompany.data;

  return (
    <>
      <SettingsMain>
        <SettingsPageHeader title="Merchandising" />

        <p>
          Updates to these settings will automatically apply to products that are created after
          settings are saved. <SupportLink article="wxnwwal0y6">Learn more</SupportLink>
        </p>

        <Content
          isLoading={fetchingCompany.isLoading}
          company={company}
          onSettingsChange={handleSettingsChange}
        />
      </SettingsMain>

      <SettingsFooter>
        <Button
          type="button"
          size="sm"
          variant="primary"
          className="mr-2"
          onClick={onSaveClick}
          disabled={isEmpty(updates)}
        >
          Save
        </Button>
      </SettingsFooter>
    </>
  );
};

export default MerchandisingSettings;
