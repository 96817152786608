import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getSessions } from 'utils/api/audit';
import { useSafeState } from 'utils/hooks';

const initialState = {
  sessions: null,
};

export const fetchSessions = createAsyncThunk('security/fetchSessions', () => getSessions());

const slice = createSlice({
  name: 'security',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSessions.fulfilled, (draft, action) => {
      draft.sessions = action.payload.sessions;
    });
  },
});

export default slice.reducer;

// Hooks

// called by settings/security
export const useFetchSessions = (sessions = null) => {
  const [loading, setLoading] = useSafeState(true);
  const [error, setError] = useSafeState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sessions) {
      dispatch(fetchSessions()).then((action) => {
        if (action.error) {
          setError(action.error.message);
        }
        setLoading(false);
      });
    }
    setLoading(false);
  }, [dispatch, sessions, setError, setLoading]);

  return [loading, error];
};
