import Flex from '@react-css/flex';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useBoolean } from 'usehooks-ts';

import AssignPriceListModal from 'containers/PriceListPage/Settings/AssignPriceListModal';
import { ShortInput } from 'containers/PriceListPage/Settings/Shared.styles';
import type { Partner } from 'types/models/partner';
import type { PriceList } from 'types/models/price-list';
import type { UpdatePriceListParams } from 'utils/api/priceLists';

import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Divider from 'storybook/stories/molecules/Divider';
import Heading from 'storybook/stories/molecules/Heading';
import Label from 'storybook/stories/molecules/Label';

type BasicInfoCardFormFields = UpdatePriceListParams;

interface BasicInfoCardProps {
  priceList: PriceList;
  partners: Partner[];
  onPartnerUpdated: () => void;
  onFormSubmit: (data: UpdatePriceListParams) => void;
  isPriceListUpdated: boolean;
}

const BasicInfoCard = ({
  priceList,
  partners,
  onPartnerUpdated,
  onFormSubmit,
  isPriceListUpdated,
}: BasicInfoCardProps) => {
  const assignedPartnerList = partners.filter((partner) => partner.pricingId === priceList.id);
  const assignedPartnerNames = assignedPartnerList.map((partner) => partner.buyerName);
  const shouldShowAssignPriceListModal = useBoolean(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isSubmitted },
  } = useForm<BasicInfoCardFormFields>({
    mode: 'onChange',
    defaultValues: {
      name: priceList.name,
    },
  });

  // Used to clear the footer row when form submission is successful
  useEffect(() => {
    if (isPriceListUpdated && isSubmitted) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitted, reset, isPriceListUpdated]);

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Flex column gap="24px" alignItemsStretch>
            <Card.Head>
              <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
                Basic Info
              </Heading>
            </Card.Head>

            <Card.Body>
              <Flex column gap="24px" alignItemsStretch>
                <Flex column gap="8px">
                  <Label htmlFor="priceListName">Price List Name</Label>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <ShortInput {...register('name', { required: true })} required />
                </Flex>

                <Flex justifySpaceBetween>
                  <Flex column gap="8px">
                    <Label htmlFor="assignedPartners">Assigned Partners</Label>

                    {assignedPartnerList.length === 0 ? (
                      <Body variant="Body/Regular">None</Body>
                    ) : (
                      <Body variant="Body/Regular">{assignedPartnerNames.join(', ')}</Body>
                    )}
                  </Flex>

                  <SecondaryButton
                    kind="neutral"
                    size="small"
                    onClick={shouldShowAssignPriceListModal.setTrue}
                  >
                    Edit
                  </SecondaryButton>
                </Flex>

                {isDirty && (
                  <Flex column gap="24px" alignItemsEnd>
                    <Divider style={{ alignSelf: 'stretch' }} />

                    <Flex gap="4px">
                      <TertiaryButton
                        type="reset"
                        size="small"
                        onClick={() =>
                          reset({
                            name: priceList.name,
                          })
                        }
                      >
                        Cancel
                      </TertiaryButton>

                      <PrimaryButton type="submit" size="small" disabled={!isValid}>
                        Save Changes
                      </PrimaryButton>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Card.Body>
          </Flex>
        </form>
      </Card>

      <AssignPriceListModal
        priceList={priceList}
        onPartnerUpdated={onPartnerUpdated}
        show={shouldShowAssignPriceListModal.value}
        onDismiss={shouldShowAssignPriceListModal.setFalse}
      />
    </>
  );
};

export default BasicInfoCard;
