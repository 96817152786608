import Flex from '@react-css/flex';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import Spinner from 'components/Common/Spinner';
import Card from 'storybook/stories/cells/Card';
import SearchForm, { SearchFormButton, SearchFormInput } from 'storybook/stories/cells/SearchForm';
import Table from 'storybook/stories/cells/Table';
import type { SellerProduct } from 'types/models/seller-product';
import { getProductSearch, type ProductSearchParams } from 'utils/api/products';

import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import AddPriceListEntryModal from './AddPriceListEntryModal';

export interface ProductSelectionProps {
  priceListId: string;
  pricedVariantIds: string[] | undefined;
  sellerCurrency: string;
  onBackButtonClick: () => void;
  onProductSelected: () => void;
}

const ProductImage = styled.img`
  display: block;
  max-width: 60px;

  border: 1px solid ${({ theme }) => theme.color.gray300};
  border-radius: 4px;
`;

const BackButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 0;
  padding: 0;
  color: ${({ theme }) => theme.color.blue500};

  &::before {
    content: 'arrow_back';
    display: inline-block;
    margin-right: 8px;
    font-family: ${({ theme }) => theme.font.icon};
  }
`;

const ProductSelection = ({
  priceListId,
  pricedVariantIds,
  sellerCurrency,
  onBackButtonClick,
  onProductSelected,
}: ProductSelectionProps) => {
  const [selectedProduct, setSelectedProduct] = useState({} as SellerProduct);
  const [currentQuery, setCurrentQuery] = useState('');

  /**
   * Form
   */

  type FormFields = Partial<ProductSearchParams>;

  const { register, handleSubmit } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      query: '',
    },
  });

  /**
   * Queries
   */

  const fetchingProductSearch = useQuery({
    queryKey: ['getProductSearch', currentQuery] as const,
    queryFn: ({ queryKey }) => getProductSearch({ query: queryKey[1] }),
  });

  const products: SellerProduct[] = useMemo(
    () => fetchingProductSearch.data?.data?.products ?? [],
    [fetchingProductSearch.data?.data?.products]
  );

  /**
   * Helpers
   */

  const productsWithUnpricedVariantsFiltered = useMemo(() => {
    if (!pricedVariantIds) return products;
    if (pricedVariantIds.length === 0) return products;

    return products
      .map((product) => ({
        ...product,
        variants: product.variants.filter((variant) => !pricedVariantIds.includes(variant._id)),
      }))
      .filter((product) => product.variants.length > 0);
  }, [pricedVariantIds, products]);

  const resetSelectedProduct = () => {
    setSelectedProduct({} as SellerProduct);
  };

  const handleSuccessfulSave = (variantId: string) => {
    pricedVariantIds?.push(variantId);
    onProductSelected();
    fetchingProductSearch.refetch();
  };

  /**
   * Event Handlers
   */

  // This simply triggers a refetch of products using the current query
  const onSearchFormSubmit = (data: FormFields) => {
    setCurrentQuery(data.query ?? '');
  };

  const handleTableRowClick = (product: SellerProduct) => {
    setSelectedProduct(product);
  };

  /**
   * Render
   */

  return (
    <>
      <Flex column gap="24px">
        <Card>
          <Flex alignItemsStart>
            <BackButton type="button" onClick={onBackButtonClick}>
              Back to Price List
            </BackButton>
          </Flex>
        </Card>

        <Card>
          <Flex column gap="16px">
            <Flex justifySpaceBetween alignItemsCenter>
              <Flex.Item grow={1}>
                <h1 className="mb-0">Search Products</h1>
              </Flex.Item>

              <Flex.Item grow={2}>
                <SearchForm onSubmit={handleSubmit(onSearchFormSubmit)}>
                  <SearchFormInput
                    id="title"
                    placeholder="Search for Products"
                    aria-label="Search for Products"
                    {...register('query')}
                  />

                  <SearchFormButton disabled={fetchingProductSearch.isLoading} />
                </SearchForm>
              </Flex.Item>
            </Flex>

            {fetchingProductSearch.isLoading ? (
              <Spinner />
            ) : (
              <Table variant="data" data-testid="product-selection-table">
                <Table.THead>
                  <Table.TR>
                    <Table.TH aria-label="Product Image" width="60px" />
                    <Table.TH>Product Title</Table.TH>
                    <Table.TH>Code</Table.TH>
                    <Table.TH>Vendor</Table.TH>
                  </Table.TR>
                </Table.THead>

                {productsWithUnpricedVariantsFiltered.length > 0 && (
                  <Table.TBody>
                    {productsWithUnpricedVariantsFiltered.map((product) => {
                      const [primaryImage] = product?.images ?? [];

                      return (
                        <Table.TR key={product.id} onClick={() => handleTableRowClick(product)}>
                          <Table.TD>
                            {primaryImage && (
                              <ProductImage
                                src={primaryImage.src}
                                alt={`Image for ${product.title}`}
                                referrerPolicy="no-referrer"
                              />
                            )}
                          </Table.TD>
                          <Table.TD>{product.title}</Table.TD>
                          <Table.TD>{product.code}</Table.TD>
                          <Table.TD>{product.vendor}</Table.TD>
                        </Table.TR>
                      );
                    })}
                  </Table.TBody>
                )}
              </Table>
            )}
          </Flex>
        </Card>
      </Flex>

      {!isEmpty(selectedProduct) && (
        <AddPriceListEntryModal
          selectedProduct={selectedProduct}
          onHide={resetSelectedProduct}
          onSuccessCallback={handleSuccessfulSave}
          priceListId={priceListId}
          sellerCurrency={sellerCurrency}
        />
      )}
    </>
  );
};

export default ProductSelection;
