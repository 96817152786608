import Grid from '@react-css/grid';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Modal from 'storybook/stories/cells/Modal';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

import FormField from 'components/Common/FormField';
import Spinner from 'components/Common/Spinner';

import Label from 'storybook/stories/molecules/Label';
import type { PriceListEntry } from 'types/models/price-list-entry';
import type { TestOrderParams } from './TestOrderCard';

interface TestOrderModalProps {
  showModal: boolean;
  onCreateOrder: ({ variant, quantity }: TestOrderParams) => void;
  setShowTestOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
  priceListEntries: PriceListEntry[];
  isCreatingOrder: boolean;
}

const TestOrderModal = ({
  showModal,
  onCreateOrder,
  setShowTestOrderModal,
  priceListEntries,
  isCreatingOrder,
}: TestOrderModalProps) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<TestOrderParams>();
  return (
    <Modal.Root
      data-testid="test-order-modal"
      show={showModal}
      aria-labelledby="create-test-order-modal-title"
      centered
      onHide={() => {
        setShowTestOrderModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Test Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="small mb-3">
          Place the test order or select a product you’d like to test the order for.
        </p>
        <Form onSubmit={handleSubmit(onCreateOrder)}>
          <Grid style={{ minHeight: '250px' }} columns="350px 100px" justifyContent="space-between">
            <Grid.Item>
              <Form.Group>
                <Label htmlFor="variant">VARIANT</Label>
                <Controller
                  name="variant"
                  control={control}
                  defaultValue={{
                    value: priceListEntries[0]?.variantId,
                    label: priceListEntries[0]?.variant.title,
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="variant"
                      options={priceListEntries?.map(({ variantId, variant, productTitle }) => {
                        return {
                          value: variantId,
                          label: `${productTitle} - ${variant.title} - ${variant.code}`,
                        };
                      })}
                    />
                  )}
                />
              </Form.Group>
            </Grid.Item>
            <Grid.Item>
              <Form.Group>
                <Label htmlFor="quantity">QUANTITY</Label>
                <FormField
                  type="text"
                  id="quantity"
                  defaultValue={1}
                  size="md"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('quantity', {
                    required: 'Please enter a quantity',
                    pattern: {
                      value: /^\d*$/i,
                      message: 'Please enter a valid quantity',
                    },
                  })}
                  error={errors.quantity}
                />
              </Form.Group>
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item justifySelfEnd>
              <SecondaryButton type="submit" size="small">
                {isCreatingOrder ? (
                  <Spinner color="white" className="mx-2" small />
                ) : (
                  'Place Test Order'
                )}
              </SecondaryButton>
            </Grid.Item>
          </Grid>
        </Form>
      </Modal.Body>
    </Modal.Root>
  );
};

export default TestOrderModal;
