import Flex from '@react-css/flex';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Message, { useMessage } from 'components/Common/Message';
import Spinner from 'components/Common/Spinner';
import DashboardPage from 'components/Dashboard/DashboardPage';
import CreateAction from 'containers/PartnerPage/Actions/CreateAction';
import ActionsToBeCompleted from 'containers/PartnerPage/ActionsToBeCompleted';
import CreateDeletePartnerModal from 'containers/PartnerPage/CreateDeletePartnerModal';
import EditActionCardModal, {
  type EditActionCardModalState,
} from 'containers/PartnerPage/EditActionCardModal';
import OwnedActions from 'containers/PartnerPage/OwnedActions';
import PartnerBillingSettings from 'containers/PartnerPage/PartnerBillingSettings';
import PartnerCustomInformation from 'containers/PartnerPage/PartnerCustomInformation';
import PartnerDetails from 'containers/PartnerPage/PartnerDetails';
import PartnerFlagSettings from 'containers/PartnerPage/PartnerFlagSettings';
import PartnerInformation from 'containers/PartnerPage/PartnerInformation';
import PartnerMerchandisingSettings from 'containers/PartnerPage/PartnerMerchandisingSettings';
import PartnerShippingMethods from 'containers/PartnerPage/PartnerShippingMethods';
import useAlertQueue from 'hooks/useAlertQueue';
import { merge } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAppSelector } from 'store';
import { selectIsAutonomousMerchandisingAvailable } from 'store/selectors/me/company';
import { selectIsSuperUser } from 'store/selectors/me/user';
import Page from 'storybook/stories/cells/Page';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import styled from 'styled-components';
import type { UpdatePartnerParams } from 'utils/api/partners';
import { getPartner, updatePartner as updatePartnerApi } from 'utils/api/partners';
import PartnerLocations from './PartnerLocations';

const Container = styled.div`
  padding: 40px 0;
  max-width: 1054px;
  margin: 0 auto;
`;

type PartnerPageParams = {
  partnerId: string;
};

interface UpdatePartnerFormData {
  id: string;
  body: UpdatePartnerParams;
}

const PartnerPage = () => {
  const history = useHistory();
  const params = useParams<PartnerPageParams>();
  const alertQueue = useAlertQueue();
  const queryClient = useQueryClient();
  const { partnerId } = params;
  const [cardEditDetails, setCardEditDetails] = useState<EditActionCardModalState>({
    id: '',
    cardTitle: '',
    cardText: '',
    required: false,
    kind: '',
  });

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [partnerUpdates, setPartnerUpdates] = useState<UpdatePartnerParams>({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { showErrorMessage, showSuccessMessage, hideMessage, messageState } = useMessage();

  const isAutonomousMerchandisingAvailable = useAppSelector(
    selectIsAutonomousMerchandisingAvailable
  );

  const isSuperUser = useAppSelector(selectIsSuperUser);

  const fetchingPartner = useQuery({
    queryKey: ['getPartner', partnerId],
    queryFn: (queryKey) => {
      const [, id] = queryKey.queryKey;
      return getPartner(id);
    },
    onError: (err: { message: string }) => {
      alertQueue.addErrorAlert('Unable to fetch partner', err.message);
    },
  });

  const updatingPartner = useMutation({
    mutationFn: ({ id, body }: UpdatePartnerFormData) => {
      return updatePartnerApi(id, body);
    },
    onError: (err: { message: string }) => {
      showErrorMessage({ title: 'Error updating partner', message: err.message });
    },
    onSuccess: () => {
      showSuccessMessage({ title: 'Success', message: 'Your partner has been updated' });
      fetchingPartner.refetch();

      // Reset updates to hide the save button
      setPartnerUpdates({});

      // Invalidate the partner query to refetch the updated data
      queryClient.invalidateQueries(['getPartner']);
      queryClient.invalidateQueries(['getPartnerByCompanyId']);
    },
  });

  const partner = fetchingPartner.data;
  const isPartnerUpdating = updatingPartner.isLoading;
  const company = useAppSelector((state) => state.me.company);

  if (!partner || !company) {
    return (
      <DashboardPage>
        <p>Loading...</p>
      </DashboardPage>
    );
  }

  const isAccountTypeBuyer = company.commerceType === 'buyer';

  const showSaveButton = !_isEmpty(partnerUpdates);

  const updatePartner = (newUpdates: UpdatePartnerParams) => {
    // newUpdates contains only fields that have just been updated
    // We use these smaller updates to build a full update on save
    setPartnerUpdates((prevUpdates) => {
      const updates = merge({}, prevUpdates, newUpdates);

      // Array-type updates are strictly applied
      // Otherwise, we can end up with duplicate entries
      if (newUpdates.merchandisingSettings?.defaultProductTags) {
        updates.merchandisingSettings!.defaultProductTags =
          newUpdates.merchandisingSettings.defaultProductTags;
      }

      if (newUpdates.merchandisingSettings?.translationSettings?.locales) {
        updates.merchandisingSettings!.translationSettings!.locales =
          newUpdates.merchandisingSettings.translationSettings.locales;
      }

      // This function will get called on every metafield keypress
      // Unless we explicitly set metafields, we would create duplicates when actively editing a text field
      // Note: metafields can currently only be added or updated, not deleted
      if (newUpdates.metafields) updates.metafields = newUpdates.metafields;

      return updates;
    });
  };

  return (
    <DashboardPage>
      <Page>
        <Page.Head
          backButton={{
            onClick: () => history.push('/partners'),
            text: 'Back to Partners',
          }}
          title={partner && (isAccountTypeBuyer ? partner.sellerName : partner.buyerName)}
        >
          {partner && (
            <>
              <div className="col-auto">
                {showSaveButton && (
                  <PrimaryButton
                    size="small"
                    onClick={() => updatingPartner.mutate({ id: partnerId, body: partnerUpdates })}
                  >
                    {isPartnerUpdating ? <Spinner color="white" className="mx-2" small /> : 'Save'}
                  </PrimaryButton>
                )}
              </div>
              <div className="col-auto">
                <PrimaryButton
                  size="small"
                  kind="destructive"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete
                </PrimaryButton>
              </div>
            </>
          )}
        </Page.Head>

        <Page.Body>
          <Message show={messageState.show} onClose={hideMessage} kind={messageState.kind}>
            <p className="m-0 mb-1">
              <strong>{messageState.title}</strong>
            </p>
            <p className="m-0">{messageState.message}</p>
          </Message>

          <Container>
            <Flex column gap="24px">
              {isSuperUser && (
                <PartnerFlagSettings partner={partner} updatePartner={updatePartner} />
              )}
              <PartnerDetails partner={partner} updatePartner={updatePartner} />
              <PartnerShippingMethods shippingMethods={partner?.shippingMethods} />
              <PartnerBillingSettings partner={partner} updatePartner={updatePartner} />

              {isAutonomousMerchandisingAvailable && (
                <PartnerMerchandisingSettings partner={partner} updatePartner={updatePartner} />
              )}

              <PartnerCustomInformation partner={partner} updatePartner={updatePartner} />
              <PartnerLocations partner={partner} />
              <PartnerInformation partner={partner} updatePartner={updatePartner} />

              <OwnedActions
                name={isAccountTypeBuyer ? partner.sellerName : partner.buyerName}
                partnerId={partnerId}
                setCardEditDetails={setCardEditDetails}
                setShowCreateModal={setShowCreateModal}
                setShowEditModal={setShowEditModal}
              />

              <ActionsToBeCompleted
                name={isAccountTypeBuyer ? partner.buyerName : partner.sellerName}
                partnerId={partnerId}
              />
            </Flex>
          </Container>

          <CreateAction
            partnerId={partnerId}
            showModal={showCreateModal}
            setShowCreateModal={setShowCreateModal}
          />

          <EditActionCardModal
            cardEditDetails={cardEditDetails}
            partnerId={partnerId}
            show={showEditModal}
            setShowEditModal={setShowEditModal}
          />
          <CreateDeletePartnerModal
            name={isAccountTypeBuyer ? partner.sellerName : partner.buyerName}
            partnerId={partnerId}
            showModal={showDeleteModal}
            onDismiss={() => setShowDeleteModal(false)}
            isBuyer={isAccountTypeBuyer}
          />
        </Page.Body>
      </Page>
    </DashboardPage>
  );
};

export default PartnerPage;
