import type { BaseQueryParams, PaginatedResponse, Response } from 'types/api';
import type { Invoice } from 'types/models/invoice';
import { getCsvRequest, getRequest, postRequest } from 'utils/request';

export interface InvoicesParams extends BaseQueryParams {
  paid?: boolean;
  sellerCompanyObjectId?: string;
  buyerCompanyObjectId?: string;
  createdMin?: string;
  createdMax?: string;
}

export const getInvoices = async (params: InvoicesParams): Promise<PaginatedResponse<Invoice[]>> =>
  getRequest('/invoices', params);

export const getInvoice = async (invoiceId: string): Promise<Response<Invoice>> =>
  getRequest(`/invoices/${invoiceId}`);

export const downloadInvoices = async (params?: InvoicesParams): Promise<string> =>
  getCsvRequest('/invoices/export', params);

export const resetInvoice = async (invoiceId: string): Promise<Response<void>> =>
  postRequest(`/invoices/${invoiceId}/reset`, {});

export const payInvoice = async (invoiceId: string): Promise<Response<void>> =>
  postRequest(`/invoices/${invoiceId}/pay`, {});
