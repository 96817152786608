/* eslint-disable no-nested-ternary */
import Flex from '@react-css/flex';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import ConfirmationModal from 'components/Common/ConfirmationModal';
import Spinner from 'components/Common/Spinner';
import { makeUserOwnerOfCompany, removeUserFromCompany } from 'store/slices/company';

import { inviteUser } from 'store/slices/settings/team';
import Table from 'storybook/stories/cells/Table';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import EMAIL_REGEX from 'utils/email';

import useAlertQueue from 'hooks/useAlertQueue';
import { useAppDispatch, useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import Dropdown from 'storybook/stories/cells/Dropdown';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Input from 'storybook/stories/molecules/Input';
import EmailInput from 'storybook/stories/molecules/Input/EmailInput';
import Label from 'storybook/stories/molecules/Label';
import { SettingsMain, SettingsPageHeader } from '../SettingsLayout';

const sortByFirstName = (users) =>
  users
    ? [...users].sort((a, b) => (a.firstName === '' ? 1 : a.firstName > b.firstName ? 1 : -1))
    : [];

const getUserName = (user) => {
  const firstName = user.firstName === user.email ? '' : user.firstName;
  return [firstName, user.lastName].join(' ');
};

const TeamMembersTableHeader = () => (
  <Table.THead>
    <Table.TR>
      <Table.TH scope="col">Name</Table.TH>
      <Table.TH scope="col">Email</Table.TH>
      <Table.TH scope="col" className="text-center">
        Actions
      </Table.TH>
    </Table.TR>
  </Table.THead>
);

const TeamMemberTableRow = ({ user, index, onClickRemoveUser, onClickMakeOwner }) => (
  <Table.TR data-testid={`team-member-table-row-${index}`}>
    <Table.TD nowrap>{getUserName(user)}</Table.TD>
    <Table.TD truncate>{user.email}</Table.TD>
    <Table.TD>
      <Dropdown>
        <Dropdown.Trigger asChild>
          <TertiaryButton $iconName="more_vert" kind="neutral">
            Manage
          </TertiaryButton>
        </Dropdown.Trigger>
        <Dropdown.Content>
          {({ close }) => (
            <>
              <div>
                <TertiaryButton
                  $iconName="supervisor_account"
                  type="button"
                  onClick={() => {
                    onClickMakeOwner(user);
                    close();
                  }}
                  disabled={index === 0}
                >
                  Make owner
                </TertiaryButton>
              </div>
              <div>
                <TertiaryButton
                  $iconName="delete"
                  type="button"
                  onClick={() => {
                    onClickRemoveUser(user);
                    close();
                  }}
                  disabled={index === 0}
                >
                  Delete user
                </TertiaryButton>
              </div>
            </>
          )}
        </Dropdown.Content>
      </Dropdown>
    </Table.TD>
  </Table.TR>
);

const TeamMembersTable = ({ users, onClickRemoveUser, onClickMakeOwner }) => (
  <Table className="table table-sm">
    <TeamMembersTableHeader />
    <Table.TBody>
      {users.map((user, index) => (
        <TeamMemberTableRow
          key={user.userId}
          user={user}
          index={index}
          onClickRemoveUser={onClickRemoveUser}
          onClickMakeOwner={onClickMakeOwner}
        />
      ))}
    </Table.TBody>
  </Table>
);

const TeamMembersSettings = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const alertQueue = useAlertQueue();
  const formId = 'invite-user-form';
  const { handleSubmit, reset, formState, register } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });
  const { isInvitingUser } = useSelector(({ settings }) => settings.team);
  const { isValid } = formState;

  const onFormSubmit = (data) => {
    dispatch(inviteUser(data)).then((result) => {
      if (result.error) {
        const { message } = result.error;
        alertQueue.addErrorAlert(
          'Something went wrong',
          /email in use/i.test(message)
            ? 'This email is already being used by a Modern Dropship user'
            : message
        );
        return;
      }
      alertQueue.addSuccessAlert('Success', `Invite has been sent to ${data.email}`);
      reset();
    });
  };

  // Sort users by first name, keeping the root user at the head of the list
  const [rootUser, ...restOfUsers] = company.users;
  const sortedUsers = useMemo(() => sortByFirstName(restOfUsers), [restOfUsers]);
  const [users, setUsers] = useState([rootUser, ...sortedUsers]);

  const [isConfirmingRemoveUser, setIsConfirmingRemoveUser] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const onClickRemoveUser = (user) => {
    setUserToRemove(user);
    setIsConfirmingRemoveUser(true);
  };
  const handleConfirmRemoveUser = () => {
    dispatch(removeUserFromCompany(userToRemove.userId)).then((action) => {
      setIsConfirmingRemoveUser(false);
      if (action.error) {
        alertQueue.addErrorAlert('Unable to remove user from company', action.error.message);
        return;
      }

      setUsers(users.filter((user) => user.userId !== userToRemove.userId));

      alertQueue.addSuccessAlert(
        'Success',
        `User ${userToRemove.firstName} ${userToRemove.lastName} has been removed`
      );
    });
  };

  const [isConfirmingNextOwner, setIsConfirmingNextOwner] = useState(false);
  const [nextOwner, setNextOwner] = useState(null);
  const onClickMakeOwner = (user) => {
    setNextOwner(user);
    setIsConfirmingNextOwner(true);
  };

  const handleConfirmMakeOwner = () => {
    dispatch(makeUserOwnerOfCompany(nextOwner.userId)).then((action) => {
      setIsConfirmingNextOwner(false);
      if (action.error) {
        alertQueue.addErrorAlert('Unable to make user owner of company', action.error.message);
        return;
      }
      const otherUsers = users.filter((user) => user.userId !== nextOwner.userId);
      const sortedOtherUsers = sortByFirstName(otherUsers);
      setUsers([nextOwner, ...sortedOtherUsers]);

      alertQueue.addSuccessAlert('Success', `User ${nextOwner.email} has been made the new owner`);
    });
  };

  const formatUserDisplay = (user) => {
    const hasFullName = user?.firstName && user?.lastName;
    if (hasFullName) {
      return `${user.firstName} ${user.lastName} (${user?.email})`;
    }
    return user?.email;
  };

  return (
    <SettingsMain>
      <SettingsPageHeader title="Team Members" />

      <h4 className="mb-4">Invite New User</h4>

      <form id={formId} onSubmit={handleSubmit(onFormSubmit)}>
        <Flex column gap="16px">
          <Flex column gap="4px">
            <Label htmlFor="an-input" className="mb-2">
              First Name
            </Label>

            <Input
              type="text"
              id="firstName"
              {...register('firstName', { required: 'First name is required' })}
              label="First Name"
              placeholder="John"
              error={formState.errors.firstName}
            />
          </Flex>

          <Flex column gap="4px">
            <Label htmlFor="an-input" className="mb-2">
              Last Name
            </Label>

            <Input
              type="text"
              id="lastName"
              {...register('lastName', { required: 'Last name is required' })}
              label="Last Name"
              placeholder="Doe"
              error={formState.errors.lastName}
            />
          </Flex>

          <Flex column gap="4px" alignItemsStretch>
            <Label htmlFor="an-input" className="mb-2">
              Email
            </Label>

            <EmailInput
              type="email"
              id="email"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Entered value does not match email format',
                },
              })}
              placeholder="email@example.com"
              error={formState.errors.email}
            />
          </Flex>

          <Body variant="Body/Body Small">
            We will send this team member an email to create their account
          </Body>

          <Flex gap="8px">
            <PrimaryButton
              type="submit"
              form={formId}
              disabled={!isValid || isInvitingUser}
              size="small"
            >
              {isInvitingUser ? (
                <Spinner color="white" className="mx-4" small />
              ) : (
                <Icon color="white" name="person_add">
                  Invite User
                </Icon>
              )}
            </PrimaryButton>
          </Flex>
        </Flex>
      </form>

      <hr className="my-4" />

      <TeamMembersTable
        users={users}
        onClickRemoveUser={onClickRemoveUser}
        onClickMakeOwner={onClickMakeOwner}
      />
      <ConfirmationModal
        show={isConfirmingNextOwner}
        onConfirm={handleConfirmMakeOwner}
        onCancel={() => {
          setIsConfirmingNextOwner(false);
        }}
        title="Change account owner?"
        cancelText="Cancel"
      >
        Are you sure you want to make <strong>{formatUserDisplay(nextOwner)}</strong> the new owner
        of this account? This action cannot be undone.
      </ConfirmationModal>
      <ConfirmationModal
        show={isConfirmingRemoveUser}
        onConfirm={handleConfirmRemoveUser}
        onCancel={() => {
          setIsConfirmingRemoveUser(false);
        }}
        title="Remove user?"
        cancelText="Cancel"
      >
        Are you sure you want to remove <strong>{formatUserDisplay(userToRemove)}</strong>? This
        action cannot be undone.
      </ConfirmationModal>
    </SettingsMain>
  );
};

export default TeamMembersSettings;
