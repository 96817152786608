import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import type { ManageWithCsvDropdownProps } from 'containers/PriceListPage/CSV/ManageWithCsvDropdown';
import Card from 'storybook/stories/cells/Card';
import FileUpload, { type UploadedFile } from 'storybook/stories/cells/FileUpload';
import Body from 'storybook/stories/molecules/Body';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import SearchResults from 'storybook/stories/organisms/SearchResults';

interface AllActiveProductsCardProps {
  onClickAddAllProducts: () => void;
}

const AllActiveProductsCard = ({ onClickAddAllProducts }: AllActiveProductsCardProps) => (
  <Card>
    <Flex column alignItemsCenter gap="16px" style={{ height: '100%' }}>
      <Icon name="widgets" size="48px" color="iconDefault" />
      <Heading variant="Headings/H3" as="strong" align="center">
        All Active Products
      </Heading>
      <Body variant="Body/Body Small" color="bodyTextSecondary" align="center">
        Import all active products from your product list using your default margin.
      </Body>

      <Flex.Item grow={1} />

      <SecondaryButton size="small" $iconName="add" onClick={onClickAddAllProducts}>
        Add All Products
      </SecondaryButton>
    </Flex>
  </Card>
);

interface SelectedProductsCardProps {
  onClickSelectProduct: () => void;
}

const SelectedProductsCard = ({ onClickSelectProduct }: SelectedProductsCardProps) => (
  <Card>
    <Flex column alignItemsCenter gap="16px" style={{ height: '100%' }}>
      <Icon name="add_box" size="48px" color="iconDefault" />

      <Heading variant="Headings/H3" as="strong" align="center">
        Individual Products
      </Heading>

      <Body variant="Body/Body Small" color="bodyTextSecondary" align="center">
        Select and add individual products to your product list.
      </Body>

      <Flex.Item grow={1} />

      <SecondaryButton size="small" $iconName="add" onClick={onClickSelectProduct}>
        Select Product
      </SecondaryButton>
    </Flex>
  </Card>
);

interface UploadCSVCardProps extends ManageWithCsvDropdownProps {}

const UploadCSVCard = ({
  onProcessFile,
  onGeneratePreview,
  onClickExportCsv,
}: UploadCSVCardProps) => {
  return (
    <Card style={{ padding: '0px 0px 24px 0px' }}>
      <Flex column gap="16px">
        <FileUpload
          onUpload={(data: UploadedFile) => {
            onProcessFile(data).then((file) => {
              onGeneratePreview(file);
            });
          }}
          borderRadius="16px 16px 0 0"
        >
          <Flex column alignItemsCenter gap="16px" style={{ height: '100%' }}>
            <Icon name="upload_file" size="48px" color="iconDefault" />

            <Heading variant="Headings/H3" as="strong" align="center">
              Upload CSV
            </Heading>

            <Flex column gap="16px" alignItemsCenter>
              <Body variant="Body/Body Small" align="center" color="bodyTextSecondary">
                Drag and drop or{' '}
                <Body variant="Body/Body Small" align="center" color="blue600">
                  click here to select a CSV file
                </Body>{' '}
              </Body>

              <SupportLink article="am55txvvky#price_list_editing_using_a_csv_file">
                <Flex gap="8px">
                  <Body variant="Body/Body Small">Price List CSV Guide</Body>
                  <Icon color="blue500" name="open_in_new" />
                </Flex>
              </SupportLink>
            </Flex>

            <Flex.Item grow={1} />
          </Flex>
        </FileUpload>

        <Flex.Item alignSelfCenter>
          <SecondaryButton size="small" $iconName="download" onClick={onClickExportCsv}>
            Export Template
          </SecondaryButton>
        </Flex.Item>
      </Flex>
    </Card>
  );
};

interface PriceListEntrySearchResultsEmptyStateProps extends ManageWithCsvDropdownProps {
  onClickAddAllProducts: () => void;
  onClickSelectProduct: () => void;
}

const PriceListEntrySearchResultsEmptyState = ({
  onProcessFile,
  onGeneratePreview,
  onClickExportCsv,
  onClickAddAllProducts,
  onClickSelectProduct,
}: PriceListEntrySearchResultsEmptyStateProps) => {
  return (
    <SearchResults>
      <Card>
        <Card.Body>
          <Flex column gap="40px">
            <Flex column gap="8px">
              <Heading variant="Headings/H2" align="center">
                Add Products to Price List
              </Heading>

              <Body color="bodyTextSecondary" align="center">
                Three easy ways to add products
              </Body>
            </Flex>

            <Flex column gap="24px">
              <Grid columns="1fr 1fr 1fr" columnGap="24px">
                <AllActiveProductsCard onClickAddAllProducts={onClickAddAllProducts} />
                <SelectedProductsCard onClickSelectProduct={onClickSelectProduct} />
                <UploadCSVCard
                  onProcessFile={onProcessFile}
                  onGeneratePreview={onGeneratePreview}
                  onClickExportCsv={onClickExportCsv}
                />
              </Grid>
            </Flex>
          </Flex>
        </Card.Body>
      </Card>
    </SearchResults>
  );
};

export default PriceListEntrySearchResultsEmptyState;
