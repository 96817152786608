export const CARRIERS = [
  'Canada Post',
  'United States Postal Service',
  'U.S. Xpress',
  'UPS',
  'UPS International',
  'Purolator',
  'Purolator Canada',
  'DHL',
  'DPD',
  'FEDEX GROUND',
  'FedEx Express (AIR)',
  'AAFES PRIVATE FLEET',
  'ABF FREIGHT',
  'ALLIED VAN LINES',
  'AMERICAN FREIGHTWAYS',
  'ALIANCA',
  'AMINO TRANSPORT',
  'Averitt Express',
  'BAX GLOBAL',
  'Bulk FR8 (Liquid Bulk)',
  'Bridge Logistics',
  'BAX GLOBAL (air)',
  'BT EXPRESS FREIGHT SYSTEMS',
  'CHEEMA TRANSPORT INC',
  'CARBONITE LOGISTICS',
  'CENTRAL TRANSPORT',
  'Carolina Transportation Inc',
  'Carolina Logistics Inc',
  'Celadon',
  'Chermak Cartage, LLC',
  'CJR Trucking, Inc.',
  'Cheeseman Transport',
  'CMA CGM',
  'China Ocean Shipping Lines',
  'Country Transport',
  'Container Port Group',
  'Continental Expedited Services, Inc.',
  'Cowan systems, LLC',
  'CON-WAY CENTRAL EXPRESS',
  'CON-WAY INTERMODAL',
  'CON-WAY SOUTHERN EXPRESS',
  'CON-WAY WESTERN EXPRESS',
  'CSX TRANSPORTATION',
  'CUSHING TRANSPORTATION,',
  'Daylight Transport LLC',
  'DAYTON FREIGHT',
  'DBO Bahnoperator',
  'Diamond Logistics',
  'DOT-LINE TRANSPORTATION',
  'Farrow Logistics',
  'Ease Logistics',
  'Evergreen',
  'Evri',
  'EMERY WORLDWIDE',
  'Estes Express',
  'Expeditors International Ocean',
  'FedEx Custom Critical',
  'FLT LOGISTICS LLC',
  'Fortune Transportation',
  'FIFTH WHEEL FREIGHT LLC',
  'FedEx Freight',
  'FedEx LTL Freight East',
  'FedEx LTL Freight West (formerly VIKN - Viking)',
  'FedEx Freight National (formerly Watkins)',
  'G & P Trucking, Inc.',
  'GILBERT EAST CORP',
  'GILBERT EXPRESS',
  'GULICK TRUCKING, INC.',
  'Heartland Express',
  'Hier Logistic Group S. de R.L. de C.V.',
  'J.B. HUNT',
  'J.B. Hunt Transport Services, Inc.',
  'HOYER Deep Sea',
  'Hercules Forwarding Inc',
  'Hub Group',
  'Intelcom Express',
  'Integrity Express Logistics',
  'INTERMODAL SERVICES',
  'ITF LLC',
  'KANE IS ABLE',
  'KUEHNE + NAGEL',
  'Knight Transportation',
  'Logistic Dynamics, Inc.',
  'Loomis',
  'LDI Trucking, Inc.',
  'Maersk Line',
  'MCC Transport Singapore Pte Ltd.',
  'MGM TRANSPORT',
  'Mediterranean Shipping Company S.A.',
  'Marten Transport LTD.',
  'Nacex',
  'NATIONAL FREIGHT',
  'North American Van Lines, Inc.',
  'National Xpress Logistics',
  'NYK',
  'OLD DOMINION FREIGHT LINE',
  'Orient Overseas Container Line',
  'PAYSTAR LOGISTICS',
  'Peninsula Truck Lines',
  'PACIFIC LOGISTICS CORP',
  'PAM Transport Inc',
  'PERFORMANCE LOGISTICS',
  'PIEDMONT EXPRESS',
  'Precision Logistics Group',
  'Prime Inc. (Tanker)',
  'Panalpina (Pantainer Express Line)',
  'Prime Inc. (OTR)',
  'Prime Inc. (Intermodal)',
  'Prime Inc. (Logistics)',
  'A. Duie Pyle',
  'R and L Carriers',
  'Roadway (YRC)',
  'Radiant Global Logistics',
  'Rowl Trucking',
  'Route Transportation & Logistics',
  'Royal Mail',
  'Royal Oil',
  'Rail Delivery Services, Inc.',
  'Russel Delivery Services, Inc.',
  'Safmarine',
  'Saia LTL Freight',
  'Schneider National',
  'Same Day Air Logistics',
  'Silver Enterprises',
  'Stevens Transport',
  'Hamburg Süd',
  'SWIFT TRANSPORTATION',
  'Truckers America',
  'TEU Global (Trade Expeditors USA, INC. DBA TEU GLOBAL)',
  'Triple A Logistics',
  'TURKON LINE INC',
  'USAW - USA Logistics',
  'UPS FREIGHT (FKA Overnite Transportation Co / OVNT)',
  'UPS SURE POST',
  'U.S. Government',
  'UNIQUE EXPRESS',
  'UNYSON',
  'USA Truck',
  'U.S. Northwest Express or USNW Express',
  'Vocar Transportation Services, LLC.',
  'VANEDGE LOGISTICS',
  'Vistar Transportation Ltd.',
  'WTA Global Inc.',
  'Xpress United Inc.',
  'XPO Logistics',
  'First International courier sys.',
  'Yodel',
  'YRC Freight',
  'General Logistics Solutions',
  'B2C Europe',
  'Australia Post',
  'ICS Courier',
  'Chit Chats',
  'Sendle',
  'Chronopost',
  'Colissimo',
  'Mondial Relay',
  'FedEx International Economy',
  'DX Delivery',
];

export const formatShippingLevel = (maxDays?: string | number) => {
  if (maxDays === '' || maxDays === undefined) return 'Unknown Shipping Level';
  if (maxDays === 0) return 'Overnight';
  if (maxDays === 1) return 'Next Day Delivery';
  return `Deliver in ${maxDays} days`;
};
