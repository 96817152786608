import Link from 'storybook/stories/molecules/Link';

interface SupportLinkProps extends React.ComponentProps<typeof Link> {
  article: string;
}

/**
 * Glean the `article` prop from HelpDocs.io by logging into HelpDocs.io before
 * navigating to an article and clicking the Edit button at the bottom of the screen.
 */
const SupportLink = ({ article, ...linkProps }: SupportLinkProps) => {
  const href = `https://support.moderndropship.com/article/${article}`;

  return <Link {...linkProps} as="a" href={href} target="_blank" rel="noopener noreferrer" />;
};

export default SupportLink;
