/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import Modal from 'storybook/stories/cells/Modal';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import styled from 'styled-components';

import CreateActionCheckbox from 'containers/PartnerPage/Actions/Common/CreateActionCheckbox';
import CreateActionTextarea from 'containers/PartnerPage/Actions/Common/CreateActionTextarea';
import CreateActionTextbox from 'containers/PartnerPage/Actions/Common/CreateActionTextbox';
import PreviewCreateActionCard from 'containers/PartnerPage/Actions/Common/PreviewCreateActionCard';
import PreviewCreateActionEmail from 'containers/PartnerPage/Actions/Common/PreviewCreateActionEmail';
import { TERMS_AND_CONDITIONS } from 'containers/PartnerPage/Actions/Kinds';
import type { PartnerAction } from 'types/models/partner-action';

const kind = TERMS_AND_CONDITIONS;

const EmailMessageTextbox = styled.textarea`
  min-height: 12.5rem;
`;

interface ActionHeaderInfoModalProps {
  showModal: boolean;
  onHide: () => void;
  onBackClick: () => void;
  onNextClick: (data: Partial<PartnerAction>) => void;
  isActionTemplate: boolean;
  action?: PartnerAction;
}

const ActionHeaderInfoModal = ({
  showModal,
  onHide,
  onBackClick,
  onNextClick,
  isActionTemplate,
  action,
}: ActionHeaderInfoModalProps) => {
  const [error, setError] = useState('');

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [required, setRequired] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [signers, setSigners] = useState('');

  useEffect(() => {
    if (showModal) {
      setTitle(action?.cardTitle ?? '');
      setText(action?.cardText ?? '');
      setRequired(action?.required ?? false);
      setEmailSubject(action?.emailSubject ?? '');
      setEmailMessage(action?.emailMessage ?? '');
    }
  }, [action, showModal]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (title === '') {
      setError('Error: Title is required');
      return;
    }

    if (!file) {
      setError('Error: Terms and conditions document is required');
      return;
    }
    if (file.type !== 'application/pdf') {
      setError('Error: Terms and conditions document must be a PDF');
      return;
    }
    if (!emailSubject || emailSubject === '') {
      setError('Error: Email subject is required');
      return;
    }
    if (signers === '' && !isActionTemplate) {
      setError('Error: Please provide at least one signer');
      return;
    }

    const listOfSigners = signers.split(',').map((signer) => ({
      name: signer.trim(),
      email: signer.trim(),
    }));

    const reader = new FileReader();
    reader.onload = async () => {
      const bytes = Array.from(new Uint8Array(reader.result as ArrayBufferLike));
      onNextClick({
        cardTitle: title,
        cardText: text,
        required,
        name: title,
        bytes,
        fileName: file.name,
        fileType: file.type,
        emailSubject,
        emailMessage,
        signers: listOfSigners,
      });
    };
    reader.onerror = (err) => {
      console.error(err);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <Modal.Root
      show={showModal}
      onHide={onHide}
      aria-labelledby="action-terms-and-conditions-general-modal"
      animation={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isActionTemplate
            ? 'Create Terms and Conditions Action Template'
            : 'Create Terms and Conditions Action'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            {error && <div className="alert alert-danger">{error}</div>}
            <CreateActionTextbox
              id="title"
              label="Title"
              value={title}
              placeholder="Terms and Conditions"
              setValue={setTitle}
            />
            <CreateActionTextarea
              id="text"
              label="Description"
              value={text}
              placeholder="Describe what the terms are for"
              setValue={setText}
            />
            <div className="form-group">
              <label htmlFor="modify-card-text">Upload Terms & Conditions Template</label>
              <input
                type="file"
                className="form-control"
                id="modify-card-contract"
                data-testid="terms-file-upload"
                onChange={(e) => {
                  if (e.target.files) {
                    setFile(e.target.files[0]);
                  }
                }}
              />
            </div>
            <CreateActionCheckbox
              id="required"
              label="Required"
              value={required}
              setValue={setRequired}
            />
            <div>
              <p className="text-muted">
                We use{' '}
                <a
                  href="https://hellosign.com?ref=convictional"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HelloSign
                </a>{' '}
                to manage our agreements. Your partner will sign the agreement through the Modern
                Dropship app. HelloSign will be contacting the signers through email.
              </p>
              {isActionTemplate ? (
                <div className="form-group">
                  <label htmlFor="modify-card-title">Signers</label>
                  <p className="text-muted">An email will be sent to your partner</p>
                </div>
              ) : (
                <div className="form-group">
                  <label htmlFor="modify-card-title">Signers</label>
                  <textarea
                    className="form-control"
                    placeholder="Add the email(s) of individuals signing the agreement (comma delimited)"
                    id="modify-card-signers"
                    data-testid="terms-signers"
                    value={signers}
                    onChange={(e) => setSigners(e.target.value)}
                  />
                </div>
              )}
              <div className="form-group">
                <label htmlFor="modify-card-title">Email Subject</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Terms and Conditions"
                  id="modify-card-title"
                  value={emailSubject}
                  data-testid="email-subject-input"
                  onChange={(e) => setEmailSubject(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="modify-card-text">Email Message</label>
                <EmailMessageTextbox
                  className="form-control"
                  placeholder="Notes for the email."
                  id="modify-card-text"
                  value={emailMessage}
                  onChange={(e) => setEmailMessage(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            <PreviewCreateActionCard
              title={title}
              text={text}
              kind={kind}
              required={required}
              optionalDoc={false}
            />
            <PreviewCreateActionEmail
              kind={kind}
              emailSubject={emailSubject}
              emailMessage={emailMessage}
              signers={signers}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <div className="text-right">
              <SecondaryButton className="mr-3" size="small" onClick={onBackClick}>
                Back
              </SecondaryButton>
              <PrimaryButton className="mr-3" size="small" type="submit">
                Next
              </PrimaryButton>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal.Root>
  );
};

export default ActionHeaderInfoModal;
