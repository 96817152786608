import Flex from '@react-css/flex';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Link from 'storybook/stories/molecules/Link';

const AddShippingMethodsCard = ({ done }) => {
  return (
    <GetStartedCard
      isComplete={done}
      title="Setup Shipping Methods"
      completedUi={
        <Body>
          Manage your shipping methods from your{' '}
          <Link to="/settings/dropshipping/shipping">Shipping settings</Link>.
        </Body>
      }
    >
      <Flex column gap="16px">
        <Body as="p">
          Optional: Configure your shipping methods to help your partners better understand the
          carriers and service levels you support.
        </Body>

        <Flex.Item alignSelfEnd>
          <PrimaryButton size="small" as={Link} to="/settings/dropshipping/shipping">
            Configure
          </PrimaryButton>
        </Flex.Item>
      </Flex>
    </GetStartedCard>
  );
};

export default AddShippingMethodsCard;
