import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from 'store';
import { selectIsDropshipSetup } from 'store/selectors/me/company';
import Menu from 'storybook/stories/cells/Menu';
import Badge from 'storybook/stories/molecules/Badge';
import { listTradeOpsIssues } from 'utils/api/issues';

const FETCHING_TRADE_OPS_ISSUES_QUERY_KEY = [
  'listTradeOpsIssues',
  {
    page: 0,
    limit: 100,
    archived: false,
    severityLevels: ['error', 'critical'],
  },
] as const;

interface FooterMenuProps {
  isActive: (path: string) => boolean;
  openSupportModal: () => void;
}

const FooterMenu = ({ isActive, openSupportModal }: FooterMenuProps) => {
  const isDropshipSetup = useAppSelector(selectIsDropshipSetup);

  /**
   * Queries
   */

  const fetchingTradeOpsIssues = useQuery({
    queryKey: FETCHING_TRADE_OPS_ISSUES_QUERY_KEY,
    queryFn: ({ queryKey }) => listTradeOpsIssues(queryKey[1]),
    enabled: isDropshipSetup,
  });

  const tradeOpsIssues = fetchingTradeOpsIssues.data?.data?.errors ?? [];

  /**
   * Render
   */

  return (
    <Menu>
      <Menu.Link $iconName="manage_search" to="/reporting" $isActive={isActive('/reporting')}>
        Reporting
      </Menu.Link>

      {isDropshipSetup && (
        <Menu.Link to="/issues" $iconName="error" $isActive={isActive('/issues')}>
          Issues
          <Badge variant="info" count={tradeOpsIssues.length} data-testid="issue-count-badge" />
        </Menu.Link>
      )}

      <Menu.Link
        as="a"
        $iconName="list_alt"
        href="https://support.moderndropship.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        Help Docs
      </Menu.Link>

      <Menu.Link as="button" $iconName="support_agent" onClick={openSupportModal}>
        Submit an Issue
      </Menu.Link>

      <Menu.Link to="/settings" $iconName="settings">
        Settings
      </Menu.Link>
    </Menu>
  );
};

export default FooterMenu;
