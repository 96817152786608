import Body from 'storybook/stories/molecules/Body';
import type { Change } from 'types/models/history';

// ChangeValueRenderer takes a from/to value, associated changes, and a snapshot of the resource
// It returns a string representing the value
export type ChangeValueRenderer<T> = (value: any, changes: Change[], snapshot: T) => string;

// ChangeRenderer takes a from/to value renderer and returns a friendly name for the change
export type ChangeRenderer<T> = {
  friendlyName: string;
  from: ChangeValueRenderer<T>;
  to: ChangeValueRenderer<T>;
};

// Generic Change List Component
export type ChangeListProps<T> = {
  changes?: Change[];
  snapshot: T;
  renderers: Record<string, ChangeRenderer<T>>;
};

// PriceListChanges displays a list of changes to a resource in a common way
export const ChangeList = <T,>({ changes, snapshot, renderers }: ChangeListProps<T>) => {
  if (!changes) {
    return null;
  }

  // Return null if no renderers apply
  if (!changes.some((change) => change.path in renderers)) {
    return null;
  }
  return (
    <ul>
      {changes
        ?.map((change) => {
          const changeRenderer = renderers[change.path];

          if (!changeRenderer) {
            return null;
          }
          const { friendlyName, from, to } = changeRenderer;
          return (
            <li>
              <Body variant="Body/Body Small">
                {friendlyName}: {from(change.from, changes, snapshot)}
                {' → '}
                {to(change.to, changes, snapshot)}
              </Body>
            </li>
          );
        })
        .filter((change) => change !== null)}
    </ul>
  );
};

export const willRenderChanges = (
  changes: Change[],
  renderers: Record<string, ChangeRenderer<any>>
) => {
  if (!changes) {
    return false;
  }

  // Return null if no renderers apply
  if (!changes.some((change) => change.path in renderers)) {
    return false;
  }

  return true;
};
