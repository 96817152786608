import Flex from '@react-css/flex';
import HourglassBottomBranded from 'images/hourglass_bottom_branded.svg';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import Heading from 'storybook/stories/molecules/Heading';
import styled from 'styled-components';

const HourglassBottomBrandedIcon = styled.img.attrs(() => ({
  alt: 'Hourglass',
  src: HourglassBottomBranded,
}))`
  max-width: 375px;
`;

const NewPriceListLoadingCard = () => {
  return (
    <Card>
      <Flex
        column
        gap="32px"
        alignItemsCenter
        justifyCenter
        style={{ padding: 'max(25px, calc(10vw)) 50px' }}
      >
        <HourglassBottomBrandedIcon />

        <Flex column gap="4px" alignItemsCenter>
          <Heading variant="Headings/H2">Creating Your Price List</Heading>

          <Body variant="Body/Body Small" color="bodyTextSecondary">
            We&apos;re currently populating your price list with your products. This may take a few
            minutes. Check back soon!
          </Body>
        </Flex>
      </Flex>
    </Card>
  );
};

export default NewPriceListLoadingCard;
