import { useMutation, useQuery } from '@tanstack/react-query';
import Spinner from 'components/Common/Spinner';
import DashboardPage from 'components/Dashboard/DashboardPage';
import ActionsDropdown from 'containers/OrdersPage/ActionsDropdown';
import DownloadCsvButton from 'containers/OrdersPage/DownloadCsvButton';
import EmptyOrdersPage from 'containers/OrdersPage/EmptyOrdersPage';
import FiltersDropdown, { type OrderFiltersFormData } from 'containers/OrdersPage/FiltersDropdown';
import download from 'downloadjs';
import useAlertQueue from 'hooks/useAlertQueue';
import { PageSelectedState, useBulkSelect } from 'hooks/useBulkSelect';
import { isEmpty, omitBy } from 'lodash';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { selectIsSeller } from 'store/selectors/me/company';
import Page from 'storybook/stories/cells/Page';
import BuyerOrderSearchResults, {
  type OrderSearchFormInputs,
} from 'storybook/stories/organisms/SearchResults/BuyerOrderSearchResults';
import SellerOrderSearchResults from 'storybook/stories/organisms/SearchResults/SellerOrderSearchResults';
import type { ErrorMessage } from 'types/api';
import { useDocumentTitle } from 'usehooks-ts';
import {
  getOrderSearch,
  getOrderSearchAggregations,
  getPackingSlipForOrder,
  type OrderSearchFilters,
  type OrderSearchParams,
} from 'utils/api/orders';
import getUpdatedUrl from 'utils/searchResults';

interface PageWrapperProps {
  children: React.ReactNode;
  shouldShowDownloadCSVButton?: boolean;
}

const PageWrapper = ({ children, shouldShowDownloadCSVButton }: PageWrapperProps) => (
  <DashboardPage>
    <Page>
      <Page.Head title="Orders">{shouldShowDownloadCSVButton && <DownloadCsvButton />}</Page.Head>
      <Page.Body>{children}</Page.Body>
    </Page>
  </DashboardPage>
);

const NewOrdersPage = () => {
  useDocumentTitle('Orders');

  const history = useHistory();
  const { search, pathname } = useLocation();
  const alertQueue = useAlertQueue();
  const isSeller = useSelector(selectIsSeller);
  const [isAggregationQueryEnabled, setIsAggregationQueryEnabled] = useState(false);

  const searchParams = qs.parse(search, { ignoreQueryPrefix: true }) as OrderSearchParams;

  /**
   * Queries
   */

  const fetchingSearchResults = useQuery({
    queryKey: ['getOrderSearch', searchParams] as const,
    queryFn: ({ queryKey }) => getOrderSearch(queryKey[1]),
    onError: (error: ErrorMessage) => {
      alertQueue.addErrorAlert('Something went wrong', error.message);
      console.error('Unable to fetch order search results.', error);
    },
  });

  const results = fetchingSearchResults.data;
  const orders = useMemo(() => results?.data?.orders || [], [results?.data?.orders]);

  const fetchingSearchResultAggregations = useQuery({
    queryKey: ['getOrderSearchAggregations', searchParams] as const,
    queryFn: ({ queryKey }) => getOrderSearchAggregations(queryKey[1]),
    enabled: isAggregationQueryEnabled,
    onError: (error: ErrorMessage) => {
      alertQueue.addErrorAlert('Something went wrong', error.message);
      console.error('Unable to fetch order search filters', error);
    },
  });

  const aggregations = fetchingSearchResultAggregations.data?.data?.aggregations;

  /**
   * Mutations
   */

  const generatingPackingSlipForOrder = useMutation({
    mutationFn: ({ id }: { id: string }) => getPackingSlipForOrder(id),
    onSuccess: (response, { id }) => {
      download(response, `packing-slip-${id}.pdf`, 'application/pdf');
    },
    onError: (error, { id }) => {
      alertQueue.addErrorAlert(
        'Something went wrong',
        'Unable to generate packing slip for order.'
      );

      console.error(`Unable to generate packing slip for order ${id}`, error);
    },
  });

  /**
   * Helpers
   */

  const currentPage = parseInt(searchParams.page || '1', 10);
  const hasOrders = orders.length > 0;

  const {
    pageSelectedState,
    selectedIds,
    select,
    deselect,
    selectAll,
    deselectAll,
    isSelected,
    isAnySelected,
    isSelectable,
  } = useBulkSelect(orders.length);

  /**
   * Side Effects
   */

  useEffect(() => {
    deselectAll();
  }, [fetchingSearchResults.isFetching, deselectAll]);

  /**
   * Event Handlers
   */

  const onGeneratePackingSlipClick = async () => {
    await Promise.all(selectedIds.map((id) => generatingPackingSlipForOrder.mutateAsync({ id })));
    alertQueue.addSuccessAlert('Packing slips generated', 'Your downloads should begin shortly');
  };

  const onSearchFormSubmit: SubmitHandler<OrderSearchFormInputs> = (values) => {
    history.push(getUpdatedUrl(pathname, searchParams, values));
  };

  const onFiltersFormSubmit: SubmitHandler<OrderFiltersFormData> = (values) => {
    const { partner, dateRange, ...rest } = values;
    const filters = rest as OrderSearchFilters;

    // Take the data from the partner select and put it in the filters object.
    if (isSeller) filters.buyerId = partner?.value;
    else filters.sellerId = partner?.value;

    // Take the data from the date range select and create the createdAt object.
    const [after, before] = dateRange?.value || [];
    const createdAt = { after, before };

    history.push(
      getUpdatedUrl(pathname, searchParams, {
        filters: omitBy(filters, isEmpty),
        createdAt: omitBy(createdAt, isEmpty),
      })
    );
  };

  const onFiltersFormReset = () => {
    history.push(
      getUpdatedUrl(pathname, searchParams, { filters: undefined, createdAt: undefined })
    );
  };

  const onPreviousClick = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: `${currentPage - 1}` }));
  };

  const onNextClick = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: `${currentPage + 1}` }));
  };

  const onPageClick = (pageNumber: number) => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: pageNumber.toString() }));
  };

  const onSearchResultClick = (orderId: string) => {
    history.push(`/orders/${orderId}`, { backUrlQueryParams: qs.stringify(searchParams) });
  };

  const onSelectPageChange = () => {
    if (pageSelectedState === PageSelectedState.All) {
      deselectAll();
    } else {
      selectAll(orders.map(({ _id }) => _id));
    }
  };

  const onSelectRowChange = (id: string) => {
    if (isSelected(id)) {
      deselect(id);
    } else {
      select(id);
    }
  };

  /**
   * Render Guards
   */

  if (!fetchingSearchResults.isSuccess)
    return (
      <PageWrapper>
        <Spinner />
      </PageWrapper>
    );

  if (!hasOrders && isEmpty(searchParams))
    return (
      <PageWrapper>
        <EmptyOrdersPage />
      </PageWrapper>
    );

  /**
   * Render
   */

  const headerActionsUi = isAnySelected && (
    <ActionsDropdown onGeneratePackingSlipClick={onGeneratePackingSlipClick} />
  );

  const headerFiltersUi = (
    <FiltersDropdown
      aggregations={aggregations}
      onFiltersFormSubmit={onFiltersFormSubmit}
      onFiltersFormReset={onFiltersFormReset}
      searchParams={searchParams}
      onOpen={() => setIsAggregationQueryEnabled(true)}
      isLoading={fetchingSearchResultAggregations.isLoading}
    />
  );

  const SearchResultsComponent = isSeller ? SellerOrderSearchResults : BuyerOrderSearchResults;

  return (
    <PageWrapper shouldShowDownloadCSVButton={orders.length > 0}>
      <SearchResultsComponent
        results={results}
        onSearchFormSubmit={onSearchFormSubmit}
        defaultSearchFormInputValue={searchParams.query}
        currentPage={currentPage}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
        onPageClick={onPageClick}
        onSearchResultClick={onSearchResultClick}
        headerActionsUi={headerActionsUi}
        headerFiltersUi={headerFiltersUi}
        onSelectPageChange={onSelectPageChange}
        onSelectRowChange={onSelectRowChange}
        isSelected={isSelected}
        isSelectable={isSelectable}
        pageSelectedState={pageSelectedState}
      />
    </PageWrapper>
  );
};

export default NewOrdersPage;
