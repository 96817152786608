import { Redirect, Switch } from 'react-router-dom';

import AgreementPage from 'containers/Agreements/AgreementPage';
import DownloadPage from 'containers/Download/DownloadPage';
import EDISchemaPage from 'containers/EDISchemas/EDISchemaPage';
import EDISchemasPage from 'containers/EDISchemas/EDISchemasPage';
import GetStartedPage from 'containers/GetStarted/GetStartedPage';
import HomePage from 'containers/HomePage';
import InvoicePage from 'containers/Invoices/InvoicePage';
import InvoicesPage from 'containers/Invoices/InvoicesPage';
import LockedPage from 'containers/LockedPage';
import LoginSuccessPage from 'containers/LoginSuccessPage';
import LogoutPage from 'containers/LogoutPage';
import OnboardingPage from 'containers/OnboardingPage';
import OrderPage from 'containers/OrderPage';
import OrderPackingSlipPage from 'containers/OrderPage/OrderPackingSlipPage';
import OTAOrderPackingSlipPage from 'containers/OrderPage/OTAOrderPackingSlipPage';
import OrdersPage from 'containers/OrdersPage';
import PartnerPage from 'containers/PartnerPage';
import PartnersPage from 'containers/PartnersPage';
import PriceListPage from 'containers/PriceListPage';
import PricesPage from 'containers/PriceListsPage';
import CreatePriceList from 'containers/PriceListsPage/CreatePriceList';
import ProductPage from 'containers/Product/ProductPage';
import ProductsPage from 'containers/Products';
import ReportingPage from 'containers/ReportingPage';
import SettingsPage from 'containers/SettingsPage';
import SignInPage from 'containers/SignInPage';
import SignInSuccessPage from 'containers/SignInSuccessPage';
import SignUpPage from 'containers/SignUpPage';
import TradeOpsIssuesPage from 'containers/TradeOpsIssues/TradeOpsIssuesPage';
import NotFoundPage from './NotFoundPage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const AppRouter = () => {
  return (
    <Switch>
      {/* Home */}

      <PrivateRoute exact path="/" component={HomePage} />

      {/* Auth */}

      <Redirect exact from="/login" to="/signin" />

      <PublicRoute exact path="/signup" component={SignUpPage} />
      <PublicRoute exact path="/signin" component={SignInPage} />
      <PublicRoute exact path="/signin/success" component={SignInSuccessPage} />

      <PublicRoute exact path="/login/:token" component={LoginSuccessPage} />
      <PublicRoute exact path="/logout" component={LogoutPage} />
      <PublicRoute exact path="/locked" component={LockedPage} />
      {/* Onboarding */}

      <PrivateRoute exact path="/onboarding" component={OnboardingPage} />
      <PrivateRoute exact path="/get-started" component={GetStartedPage} />

      {/* Orders */}

      <PrivateRoute exact path="/orders" component={OrdersPage} />
      <PrivateRoute exact path="/orders/:orderId" component={OrderPage} />
      <PrivateRoute exact path="/orders/:orderId/packingslip" component={OrderPackingSlipPage} />
      <PublicRoute
        exact
        path="/ota/orders/:orderId/packingslip"
        component={OTAOrderPackingSlipPage}
      />

      {/* Partners */}

      <PrivateRoute exact path="/partners" component={PartnersPage} />
      <PrivateRoute exact path="/partners/:partnerId" component={PartnerPage} />

      {/* Products */}

      <PrivateRoute exact path="/products" component={ProductsPage} />
      <PrivateRoute exact path="/products/:productId" component={ProductPage} />

      {/* Settings */}

      <PrivateRoute exact path="/settings/:tab?/:section?" component={SettingsPage} />

      {/* TradeOps */}

      <PrivateRoute exact path="/issues" component={TradeOpsIssuesPage} />

      {/* Pricing */}

      <PrivateRoute exact path="/prices" component={PricesPage} />
      <PrivateRoute exact path="/prices/create" component={CreatePriceList} />
      <PrivateRoute exact path="/prices/:priceId" component={PriceListPage} />

      {/* Invoices */}

      <PrivateRoute exact path="/invoices" component={InvoicesPage} />
      <PrivateRoute exact path="/invoices/:id" component={InvoicePage} />

      {/* Agreements */}

      <PrivateRoute exact path="/agreements/:agreementId" component={AgreementPage} />

      {/* EDI */}

      <PrivateRoute exact path="/edi/documents" component={EDISchemasPage} />
      <PrivateRoute exact path="/edi/documents/:documentNumber" component={EDISchemaPage} />

      {/* Download */}

      <PrivateRoute exact path="/download/:documentId" component={DownloadPage} />

      {/* Reporting */}

      <PrivateRoute exact path="/reporting" component={ReportingPage} />

      {/* 404 */}

      <PublicRoute component={NotFoundPage} />
    </Switch>
  );
};

export default AppRouter;
