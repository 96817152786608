/* eslint-disable no-nested-ternary, jsx-a11y/label-has-associated-control */
import { useCombobox } from 'downshift';
import { forwardRef, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import Icon from 'storybook/stories/molecules/Icon';

const CategoryListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  &:hover {
    cursor: pointer;
  }
  &.highlighted {
    color: #12263f;
    background-color: #edf2f9;
  }
`;

const SimpleScroll = forwardRef(({ children, ...rest }, ref) => {
  return (
    <SimpleBar {...rest} scrollableNodeProps={{ ref }}>
      {children}
    </SimpleBar>
  );
});

// the list renders different type of list items which look different and cause different state changes
const listItemTypes = {
  searchResult: 'searchResult', // the list item is a result from searching for full category path
  category: 'category', // the list item is a subcategory, used when drilling down the categories
  back: 'back', // the item renders the current category path and will go back to a parent category if selected
  select: 'select', // the item that renders "None of the above, select current category"
};

// filters the search results against all the category paths
const getSearchResults = ({ query, categoryList }) => {
  if (!query || !categoryList) return [];
  const regex = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
  return categoryList.filter((category) => regex.test(category.name));
};

// the selection list can render either the search results or the categories based on categoryPath
const getListItems = ({ searchResults, subcategories, categoryPath, codeForCategoryPath }) => {
  // if there are search results, they should be shown
  if (searchResults && searchResults.length > 0) {
    return searchResults.map((result) => ({ ...result, itemType: listItemTypes.searchResult }));
  }
  // else make the current subcategories the list items
  const items = subcategories.map((category) => ({
    name: category,
    itemType: listItemTypes.category,
  }));
  // if the current category path can be a selected category, add "None of the above, select category" to the list to reflect that option
  if (codeForCategoryPath) {
    items.push({
      name: 'None of the above, select category',
      itemType: listItemTypes.select,
    });
  }
  // if there is a category path, add a "Back" option to front of the list that displays current category path
  return categoryPath ? [{ name: categoryPath, itemType: listItemTypes.back }, ...items] : items;
};

/**
 * Default export
 */
const GoogleCategorySelect = ({
  onSelectCategory,
  categoryTrie,
  categoryList,
  categoryPath,
  setCategoryPath,
}) => {
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const searchTimerRef = useRef();

  const uuid = uuidv4(); // used in list key to ignore list render optimizations
  const subcategories = (categoryTrie && categoryTrie.getSubcategoriesByPath(categoryPath)) || [];
  const codeForCategoryPath = categoryTrie && categoryTrie.getCodeByPath(categoryPath);

  // get the list of items to display in the combobox
  const listItems = getListItems({
    searchResults,
    subcategories,
    categoryPath,
    codeForCategoryPath,
  });

  // a string representation of the list item that is selected (used internally in downshiftjs)
  const itemToString = (item) => {
    if (!item) return '';
    if (item.itemType === listItemTypes.back) {
      const lastCategory = categoryPath.split(' > ').pop();
      return `Back to ${lastCategory}`;
    }
    return item.name;
  };

  // handler for when a list item is selected
  // selectedItem is a list item and not necessarily a "selected category"
  // list item can be several different things, so we need to dynamically figure out how to change state based on itemType
  const onSelectedItemChange = ({ selectedItem }) => {
    if (!selectedItem || !categoryTrie) return;
    // selecting a search result selects that category (choosing a full category path)
    if (selectedItem.itemType === listItemTypes.searchResult) {
      onSelectCategory({ name: selectedItem.name, code: selectedItem.code });
      return;
    }
    // selecting the "back button" takes the user back up one category in the hierarchy
    if (selectedItem.itemType === listItemTypes.back) {
      const tmpPath = categoryPath.split(' > ');
      tmpPath.pop();
      setCategoryPath(tmpPath.join(' > '));
      return;
    }
    // if there is no current category path, user selected a top level category, set that as the category path and subcategories are shown
    if (!categoryPath) {
      setCategoryPath(selectedItem.name);
      return;
    }
    // if selecting "None of the above, select category", the user didn't find a subcategory that works, so the current category path is the deepest they could go
    if (selectedItem.itemType === listItemTypes.select) {
      onSelectCategory({
        code: codeForCategoryPath,
        name: categoryPath,
      });
      return;
    }

    // user chose a subcategory, build new path and see if it is the end or if more subcategories exist
    const newCategoryPath = `${categoryPath} > ${selectedItem.name}`;
    const nextCategories = categoryTrie.getSubcategoriesByPath(newCategoryPath) || [];
    // if there are no subcategories, this is the most detailed, select this category
    if (nextCategories.length === 0) {
      const code = categoryTrie.getCodeByPath(newCategoryPath);
      onSelectCategory({
        code,
        name: newCategoryPath,
      });
      return;
    }

    // drill down to next subcategories
    setCategoryPath(newCategoryPath);
  };

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: listItems,
    itemToString,
    inputValue: search,
    isOpen: true,
    onSelectedItemChange,
  });

  // render
  return (
    <>
      <div
        className="input-group input-group-flush py-0 px-4"
        style={{ borderBottom: '1px solid #edf2f9' }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getComboboxProps()}
      >
        <div className="input-group-prepend">
          <span className="input-group-text">
            <Icon name="search" />
          </span>
        </div>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <label {...getLabelProps({ className: 'visuallyhidden' })}>Search Categories</label>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getInputProps({
            className: 'form-control list-search',
            placeholder: 'Search Categories',
            onChange: (evt) => {
              const query = evt.target.value;
              setSearch(query);
              clearTimeout(searchTimerRef.current);
              searchTimerRef.current = setTimeout(() => {
                setSearchResults(getSearchResults({ query, categoryList }));
              }, 400);
            },
          })}
          onBlur={() => {}}
        />
      </div>
      <SimpleScroll
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getMenuProps()}
        style={{ minHeight: '12rem', maxHeight: '20rem', paddingBottom: '1rem' }}
      >
        {listItems.map((item, index) =>
          item.itemType === listItemTypes.searchResult ? (
            <CategoryListItem
              key={item.name}
              className={highlightedIndex === index ? 'highlighted' : ''}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getItemProps({ item, index })}
            >
              <p className="m-0 text-muted">{item.name}</p>
            </CategoryListItem>
          ) : item.itemType === listItemTypes.category ? (
            <CategoryListItem
              key={item.name + uuid}
              className={highlightedIndex === index ? 'highlighted' : ''}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getItemProps({ item, index })}
            >
              <Icon name="chevron_right" position="after">
                {item.name}
              </Icon>
            </CategoryListItem>
          ) : item.itemType === listItemTypes.select ? (
            <CategoryListItem
              key={item.name + uuid}
              className={highlightedIndex === index ? 'highlighted' : ''}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getItemProps({ item, index })}
            >
              <p className="m-0">{item.name}</p>
            </CategoryListItem>
          ) : item.itemType === listItemTypes.back ? (
            <CategoryListItem
              key={`Back: ${item.name}`}
              className={highlightedIndex === index ? 'highlighted' : ''}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getItemProps({ item, index })}
            >
              <p className="m-0">
                <strong>
                  <Icon name="chevron_left">{categoryPath}</Icon>
                </strong>
              </p>
            </CategoryListItem>
          ) : null
        )}
      </SimpleScroll>
    </>
  );
};

export default GoogleCategorySelect;
