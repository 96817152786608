import qs from 'qs';
import { useLocation } from 'react-router';
import type { UserOnboardingAttributes } from 'types/models/user';

const useOnboardingAttributeParams = () => {
  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const keyMapping: Record<string, keyof UserOnboardingAttributes> = {
    reCompanyId: 'referralCompanyId',
    companyName: 'companyName',
    commerceType: 'commerceType',
    platform: 'platform',
    storeUrl: 'storeUrl',
    source: 'source',
    firstName: 'firstName',
    lastName: 'lastName',
    companyUrl: 'companyUrl',
    temporaryTokenId: 'temporaryTokenId',
  };

  return Object.entries(searchParams).reduce((accumulator, [key, value]) => {
    if (!value) return accumulator;

    const mappedKey = keyMapping[key];
    if (mappedKey) {
      accumulator[mappedKey] = value as any;
    }

    return accumulator;
  }, {} as Partial<UserOnboardingAttributes>);
};

export default useOnboardingAttributeParams;
