import { basicEventComponent, type EventComponent } from '..';
import OrderFulfilledPayload from './OrderFulfilledPayload';
import OrderItemsCancelledPayload from './OrderItemsCancelledPayload';
import OrderUpdatedPayload from './OrderUpdatedPayload';

const orderEventComponents: Record<string, EventComponent> = {
  OrderUpdatedPayload,
  OrderFulfilledPayload,
  OrderItemsCancelledPayload,
  OrderCreatedPayload: basicEventComponent('add_circle', 'Order Created'),
  PostedToSellerPayload: basicEventComponent('sync', "Order Posted to Seller's Store"),
  FulfillmentPostedToBuyerPayload: basicEventComponent(
    'sync',
    "Fulfillment Posted to Buyer's Store"
  ),
};

export default orderEventComponents;
