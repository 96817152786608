import type { ButtonProps } from 'storybook/stories/molecules/Button';
import Button, {
  ButtonKinds,
  ButtonSizes,
  getButtonPadding,
  getButtonTypography,
} from 'storybook/stories/molecules/Button';
import styled, { css } from 'styled-components';
import type { PickEnum } from 'types/general';

export type PrimaryButtonKinds = PickEnum<
  ButtonKinds,
  ButtonKinds.Action | ButtonKinds.Destructive
>;

type GetButtonColorSchemeArg = Pick<PrimaryButtonProps, '$iconAfter' | 'kind'>;

const getButtonColorScheme = ({
  $iconAfter,
  kind = ButtonKinds.Action,
}: GetButtonColorSchemeArg) => {
  if (kind === ButtonKinds.Action) {
    return css`
      background: ${({ theme }) => theme.color.blue500};
      background-blend-mode: overlay, normal;
      color: ${({ theme }) => theme.color.white} !important; // Overrides Link colors

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.color.white} !important; // Overrides Link colors
        background: ${({ theme }) => theme.color.gray700};
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${({ theme }) => theme.color.white};
      }
    `;
  }

  if (kind === ButtonKinds.Destructive) {
    return css`
      background: ${({ theme }) => theme.color.error500};
      color: ${({ theme }) => theme.color.white} !important; // Overrides Link colors

      &:hover,
      &:focus {
        background: ${({ theme }) => theme.color.error700};
        color: ${({ theme }) => theme.color.white} !important; // Overrides Link colors
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${({ theme }) => theme.color.white};
      }
    `;
  }

  return css``;
};

interface PrimaryButtonProps extends ButtonProps {
  /**
   * The kind of action this button describes
   */
  kind?: `${PrimaryButtonKinds}`;
}

/**
 * A composed `Button` component that represents the primary action a user can take on a page.
 * - `size` is defaulted to `ButtonSizes.Large`
 * - `kind` is defaulted to `ButtonKinds.Action`
 */
const PrimaryButton = styled(Button).attrs<PrimaryButtonProps>(({ size = ButtonSizes.Large }) => ({
  size,
}))<PrimaryButtonProps>`
  position: relative;
  border-radius: 100px;
  border: ${({ size }) => (size === ButtonSizes.Large ? '2px' : '1px')} solid transparent;

  /* Set up inset ring for focus, using the pseudo selector not bound to an icon */
  ${({ $iconName, $iconAfter, size }) => css`
    &::${$iconName && $iconAfter ? 'before' : 'after'} {
      position: absolute;
      inset: 3px;
      display: block;
      content: '';
      border: ${size === ButtonSizes.Large ? '2px' : '1px'} solid transparent;
      border-radius: 100px;
    }
  `}

  /* Determine appropriate typography */
  ${({ size, disabled }) => getButtonTypography({ size, disabled })}

  /* Determine appropriate padding */
  ${({ size, $iconName, $iconAfter }) => getButtonPadding({ size, $iconName, $iconAfter })}

  /* Determine color scheme */
  ${({ kind, $iconAfter }) => getButtonColorScheme({ kind, $iconAfter })}

  /* Maintain a static disabled state */
  &:disabled {
    background: ${({ theme }) => theme.color.gray200};
    background-blend-mode: normal;
    color: ${({ theme }) => theme.color.bodyTextDisabled} !important; // Overrides Link colors
  }
`;

export default PrimaryButton;
