import type { Response } from 'types/api';
import type { Question } from 'types/models/question';
import { deleteRequest, getRequest, postRequest } from 'utils/request';

export type ListQuestionsResponse = Response<Question[]>;
export type QuestionResponse = Response<Question>;

export const fetchReportingQuestions = (): Promise<ListQuestionsResponse> =>
  getRequest(`/reporting/questions`);

export const createReportingQuestion = async (
  params: Partial<Question>
): Promise<QuestionResponse> => postRequest('/reporting/questions', params);

export const askReportingQuestion = async (questionId: string): Promise<QuestionResponse> =>
  getRequest(`/reporting/questions/${questionId}/ask`);

export const sendReportingQuestionEmail = async (questionId: string): Promise<QuestionResponse> =>
  postRequest(`/reporting/questions/${questionId}/email`);

export const deleteReportingQuestion = async (questionId: string): Promise<QuestionResponse> =>
  deleteRequest(`/reporting/questions/${questionId}`);
