import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import qs from 'qs';
import { useContext, useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';

import Spinner from 'components/Common/Spinner';
import useAlertQueue from 'hooks/useAlertQueue';
import { PageSelectedState, useBulkSelect } from 'hooks/useBulkSelect';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import type { PriceListEntrySearchFormInputs } from 'storybook/stories/organisms/SearchResults/PriceListEntrySearchResults';
import PriceListEntrySearchResults from 'storybook/stories/organisms/SearchResults/PriceListEntrySearchResults';

import ConfirmationModal from 'components/Common/ConfirmationModal';
import BulkEditPriceListEntryMarginModal from 'containers/PriceListPage/PriceListEntries/BulkEditPriceListEntryMarginModal';
import NewPriceListLoadingCard from 'containers/PriceListPage/PriceListEntries/NewPriceListLoadingCard';
import type { MarginType } from 'containers/PriceListsPage/CreatePriceList/Shared.types';
import type { UploadedFile } from 'storybook/stories/cells/FileUpload';
import { ButtonKinds } from 'storybook/stories/molecules/Button';
import { useBoolean } from 'usehooks-ts';
import {
  addAllProductsToPriceList,
  getPriceListEntries,
  getPriceListEntrySearch,
  type GetPriceListEntriesParams,
  type PriceListEntriesResponse,
  type PriceListEntriesSearchParams,
  type PriceListEntrySearchResponse,
} from 'utils/api/priceLists';
import getUpdatedUrl from 'utils/searchResults';
import { BulkEditPriceListContext, EditEntryOptions } from '../BulkEditing/context';
import ProductSelection from './ProductSelection';

export interface PriceListEntriesProps {
  priceListId: string;
  defaultMargin: number;
  marginType: MarginType;
  sellerCurrency: string;
  onProcessFile: (file: UploadedFile) => Promise<string | ArrayBuffer | null>;
  onGeneratePreview: (rawFile: string | ArrayBuffer | null) => void;
  onClickExportCsv: () => void;
}

const PriceListEntries = ({
  priceListId,
  defaultMargin,
  marginType,
  sellerCurrency,
  onProcessFile,
  onGeneratePreview,
  onClickExportCsv,
}: PriceListEntriesProps) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { search, pathname } = useLocation();
  const searchParams = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as PriceListEntriesSearchParams;
  const { addSuccessAlert, addErrorAlert } = useAlertQueue();

  const queryString = qs.parse(search, { ignoreQueryPrefix: true });
  const [isNewPriceListLoading, setIsNewPriceListLoading] = useState(queryString.isNew === 'true');

  const [isAddingEntries, setIsAddingEntries] = useState(false);
  const bulkEditMarginModalVisibility = useBoolean(false);
  const shouldShowAddAllProductsModal = useBoolean(false);

  const { setRows, markRowsForEditing, selectedRowIds, selectedRows, setSelectedRowIds } =
    useContext(BulkEditPriceListContext);

  const hasSearchQuery = !!searchParams?.query || !!searchParams?.filters;
  const isEntriesQueryEnabled = !hasSearchQuery && !!priceListId;
  const isSearchQueryEnabled = hasSearchQuery && !!priceListId;

  const fetchingPriceListEntries = useQuery({
    queryKey: ['getPriceListEntries', priceListId, searchParams],
    queryFn: ({ queryKey }) => {
      const params = queryKey[2] as GetPriceListEntriesParams;
      // getPriceListEntries pagination is 0-indexed, so we need to subtract 1 from the page number
      const page = params?.page ? params.page - 1 : 0;
      return getPriceListEntries(priceListId, { page });
    },
    enabled: isEntriesQueryEnabled,
    onSuccess: (data) => {
      if (isAddingEntries && (data?.data ?? []).length > 0) {
        setIsNewPriceListLoading(false);
      } else {
        setIsNewPriceListLoading(false);
      }
    },
    refetchInterval: (data) => {
      // Refetch every 10s until some data exists, i.e. Faktory is finished
      if ((data?.data ?? []).length > 0) return false;
      return 10000;
    },
    onError: (error) => {
      addErrorAlert('Something went wrong', 'Unable to fetch price list entries.');
      console.error(`Unable to fetch seller price list entries for ${priceListId}`, error);
    },
  });

  const fetchingSearchResults = useQuery({
    queryKey: ['getPriceListEntrySearch', priceListId, searchParams],
    queryFn: ({ queryKey }) => {
      const params = queryKey[2] as PriceListEntriesSearchParams;
      return getPriceListEntrySearch(priceListId, params);
    },
    enabled: isSearchQueryEnabled,
    onError: (error) => {
      addErrorAlert('Something went wrong', 'Unable to fetch price list entry search results.');
      console.error(`Unable to fetch seller price list entry search results ${priceListId}`, error);
    },
  });

  const addingAllProductsToPriceList = useMutation({
    mutationFn: () => addAllProductsToPriceList(priceListId),
    onSuccess: () => {
      shouldShowAddAllProductsModal.setFalse();
      addSuccessAlert(
        'Success',
        "We're currently adding your products to your price list. This may take a few minutes."
      );
      queryClient.invalidateQueries(['getPriceList']);
      queryClient.invalidateQueries(['getPriceListEntries', priceListId]);
      queryClient.invalidateQueries(['getPriceListEntrySearch']);
    },
    onError: (error: { message: string }) => {
      addErrorAlert('Something went wrong', error.message);
    },
  });

  // Helpers

  const results = isSearchQueryEnabled ? fetchingSearchResults.data : fetchingPriceListEntries.data;
  const currentPage = parseInt(searchParams.page || '1', 10);

  const isFetching =
    (isSearchQueryEnabled && fetchingSearchResults.isFetching) ||
    (isEntriesQueryEnabled && fetchingPriceListEntries.isFetching);

  const isSuccess =
    (isSearchQueryEnabled && fetchingSearchResults.isSuccess) ||
    (isEntriesQueryEnabled && fetchingPriceListEntries.isSuccess);

  const priceListEntries = useMemo(() => {
    if (isEntriesQueryEnabled) return (results as PriceListEntriesResponse)?.data || [];
    return (results as PriceListEntrySearchResponse)?.data?.priceListEntries || [];
  }, [isEntriesQueryEnabled, results]);

  const {
    pageSelectedState,
    selectedIds,
    select,
    deselect,
    selectAll,
    deselectAll,
    isSelected,
    isSelectable,
  } = useBulkSelect(priceListEntries.length);

  // editableRows represents a list of editable price list entries. It includes some metadata
  // specific to editing that the base PriceListEntry does not, such as `isEditingMargin`.
  const editableRows = useMemo(() => {
    return priceListEntries.map((entry) => {
      const imageUrl = entry.variant.images[0]?.src ?? entry.defaultImageUrl;

      return {
        entry,
        imgSrc: imageUrl,
        isEditingMargin: false,
        isEditingYourRetailPrice: false,
        isEditingYouEarn: false,
        hasUpdates: false,
      };
    });
  }, [priceListEntries]);

  // Event Handlers

  const onSearchFormSubmit: SubmitHandler<PriceListEntrySearchFormInputs> = (values) => {
    history.push(getUpdatedUrl(pathname, searchParams, values));
  };

  const onPreviousClick = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: `${currentPage - 1}` }));
  };

  const onNextClick = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: `${currentPage + 1}` }));
  };

  const onEntriesUpdated = () => {
    queryClient.invalidateQueries(['getPriceListEntries', priceListId]);
    queryClient.invalidateQueries(['getPriceListEntrySearch', priceListId]);
  };

  const onSelectPageChange = () => {
    let newRowIds: string[];

    if (pageSelectedState === PageSelectedState.All) {
      deselectAll();
      newRowIds = [];
    } else {
      const ids = priceListEntries.map(({ id }) => id);
      selectAll(ids);
      newRowIds = ids;
    }

    if (setSelectedRowIds) {
      setSelectedRowIds(newRowIds);
    }
  };

  const onSelectRowChange = (id: string) => {
    const newRows = isSelected(id)
      ? selectedRowIds.filter((selectedId) => selectedId !== id)
      : [...selectedRowIds, id];

    if (newRows && setSelectedRowIds) {
      setSelectedRowIds(newRows);
    }

    if (isSelected(id)) {
      deselect(id);
    } else {
      select(id);
    }
  };

  const onBackButtonClick = () => {
    setIsAddingEntries(false);
    queryClient.invalidateQueries(['getPriceListEntries', priceListId]);
    queryClient.invalidateQueries(['getPriceListEntrySearch', priceListId]);
  };

  // SIDE EFFECTS

  useEffect(() => {
    deselectAll();
  }, [isFetching, deselectAll]);

  useEffect(() => {
    setRows(editableRows);
  }, [editableRows, setRows]);

  // RENDER GUARDS

  if (isNewPriceListLoading) return <NewPriceListLoadingCard />;

  if (!isSuccess) return <Spinner />;

  // RENDER

  const headerAddNewButton = (
    <TertiaryButton kind="neutral" onClick={() => setIsAddingEntries(true)} $iconName="add">
      Add Product
    </TertiaryButton>
  );

  if (isAddingEntries)
    return (
      <ProductSelection
        priceListId={priceListId}
        pricedVariantIds={priceListEntries.map(({ variantId }) => variantId)}
        sellerCurrency={sellerCurrency}
        onBackButtonClick={onBackButtonClick}
        onProductSelected={onEntriesUpdated}
      />
    );

  const onClickBulkEditAction = (action: EditEntryOptions) => {
    if (action === EditEntryOptions.MARGIN_BULK) {
      bulkEditMarginModalVisibility.setTrue();
    } else {
      markRowsForEditing(selectedIds, action);
    }
  };

  return (
    <>
      <PriceListEntrySearchResults
        onSearchFormSubmit={onSearchFormSubmit}
        defaultSearchFormInputValue={searchParams.query}
        currentPage={currentPage}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
        onSelectPageChange={onSelectPageChange}
        onSelectRowChange={onSelectRowChange}
        onClickBulkEditAction={onClickBulkEditAction}
        headerAddNewButton={headerAddNewButton}
        isSelected={isSelected}
        isSelectable={isSelectable}
        marginType={marginType}
        pageSelectedState={pageSelectedState}
        hasMore={!!results?.hasMore}
        shouldDisplayFooter={isEntriesQueryEnabled}
        onProcessFile={onProcessFile}
        onGeneratePreview={onGeneratePreview}
        onClickExportCsv={onClickExportCsv}
        onClickAddAllProducts={shouldShowAddAllProductsModal.setTrue}
        onClickSelectProduct={() => setIsAddingEntries(true)}
      />

      <BulkEditPriceListEntryMarginModal
        show={bulkEditMarginModalVisibility.value}
        onHide={bulkEditMarginModalVisibility.setFalse}
        marginType={marginType}
        defaultMargin={defaultMargin}
        initialRows={selectedRows}
      />

      {/* Add All Products Confirmation Modal */}
      <ConfirmationModal
        show={shouldShowAddAllProductsModal.value}
        onConfirm={() => addingAllProductsToPriceList.mutate()}
        onCancel={shouldShowAddAllProductsModal.setFalse}
        confirmButtonKind={ButtonKinds.Action}
        confirmText="Add All Products"
      >
        Do you want to add all active products to this price list?
      </ConfirmationModal>
    </>
  );
};

export default PriceListEntries;
