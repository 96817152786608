import {
  FloatingChatContext,
  type FloatingChatContextValue,
} from 'containers/App/Contexts/FloatingChatContext';
import BotImage from 'images/bot.png';
import { noop } from 'lodash';
import { useContext } from 'react';
import elevate from 'storybook/mixins/elevate';
import materialSymbol from 'storybook/mixins/materialSymbol';
import Button from 'storybook/stories/molecules/Button';
import styled, { css } from 'styled-components';

export const BUTTON_SIZE = 64;

type StyledButtonProps = Pick<FloatingChatContextValue, 'isWindowOpen' | 'isWindowExpanded'>;

const StyledButton = styled(Button)<StyledButtonProps>`
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  border-radius: 100%;
  ${elevate('4')};

  ${({ theme, isWindowOpen }) =>
    isWindowOpen
      ? css`
          background: ${theme.color.gray700};
          ${materialSymbol({ name: 'close', color: 'white', size: '40px' })};
        `
      : css`
          background: url(${BotImage}) no-repeat center / 64px;
        `}

  ${({ isWindowExpanded }) =>
    isWindowExpanded &&
    css`
      position: absolute;
      bottom: 0;
      right: 0;
    `}
`;

interface FloatingChatButtonProps {
  onClick?: () => void;
}

const FloatingChatButton = ({ onClick = noop }: FloatingChatButtonProps) => {
  const { isWindowOpen, isWindowExpanded } = useContext(FloatingChatContext);

  return (
    <StyledButton
      aria-label="Open chat window"
      isWindowOpen={isWindowOpen}
      isWindowExpanded={isWindowExpanded}
      onClick={onClick}
    />
  );
};

export default FloatingChatButton;
