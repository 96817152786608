import BotImage from 'images/bot.png';
import typography from 'storybook/mixins/typography';
import Spinner from 'storybook/stories/molecules/Spinner';
import styled, { css } from 'styled-components';

export const MessageContent = styled.div`
  display: inline-block;
  ${typography('Body/Regular')};
  border-radius: 16px;
  padding: 8px 16px;

  p {
    margin: 0 0 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  pre {
    white-space: pre-wrap;
  }
`;

interface AiIconWrapperProps {
  isLoading?: boolean;
}

export const AiIconWrapper = styled.div.attrs<AiIconWrapperProps>(({ isLoading }) => ({
  children: isLoading && <Spinner size="24px" color="gray100" />,
}))<AiIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;

  ${({ theme, isLoading }) =>
    isLoading
      ? css`
          background: ${theme.color.gray300};
        `
      : css`
          background: url(${BotImage}) no-repeat center / 100%;
        `};
`;
