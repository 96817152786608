import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import TestOrderModal from 'containers/GetStarted/TestOrderModal';
import useAlertQueue from 'hooks/useAlertQueue';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useAppDispatch } from 'store';
import { updateOnboardingStatus } from 'store/slices/company';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import styled from 'styled-components';
import type { Partner } from 'types/models/partner';
import type { PriceList } from 'types/models/price-list';
import type { PriceListEntry } from 'types/models/price-list-entry';
import { getOrders, type GetOrdersParams } from 'utils/api/orders';
import { getPartners, invitePartner } from 'utils/api/partners';
import { getPriceListEntries, getPriceLists } from 'utils/api/priceLists';
import { createTestOrder } from 'utils/api/testOrders';
import { CONVICTIONAL_TEST_BUYER_EMAIL } from 'utils/onboarding';

const InputLabel = styled.label`
  font-size: 0.875rem;
`;

interface TestOrderCardProps {
  done: boolean;
}

export type TestOrderParams = {
  variant: SelectOption;
  quantity: string;
};

type SelectOption = {
  value: string;
  label: string;
};

interface TestOrderCardContentProps {
  disabled: boolean;
  hasTestingPartnership: boolean;
  testingPartnership: Partner;
  allPriceLists: PriceList[];
  selectedPriceList: SelectOption;
  setSelectedPriceList: (option: SelectOption) => void;
  isLoading: boolean;
  hasTestOrder: boolean;
  onMarkComplete: () => void;
  onSelectTestOrderProducts: (
    event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>
  ) => void;
}

const TestOrderCardContent = ({
  disabled,
  hasTestingPartnership,
  testingPartnership,
  allPriceLists,
  selectedPriceList,
  setSelectedPriceList,
  isLoading,
  hasTestOrder,
  onMarkComplete,
  onSelectTestOrderProducts,
}: TestOrderCardContentProps) => {
  if (disabled) {
    return (
      <Body as="p">
        You need to create a price list before you can test order. If you are connecting through
        EDI, you need to also have the EDI connection ready.{' '}
        <SupportLink article="jkhh9bgxgx">More Info</SupportLink>
      </Body>
    );
  }

  if (hasTestingPartnership) {
    return (
      <Flex column gap="16px">
        <Body as="p">
          Select products for your test order or change the price list by managing your testing
          partnership in the <a href="/partners">Partners</a> page.{' '}
          <SupportLink article="jkhh9bgxgx">More Info</SupportLink>
        </Body>
        <Body>
          <strong>Price List: </strong>
          {testingPartnership.priceListName}
        </Body>
        <Flex.Item alignSelfEnd>
          <Flex gap="8px">
            <SecondaryButton size="small" onClick={onMarkComplete}>
              {hasTestOrder ? 'Mark as Completed' : 'Skip'}
            </SecondaryButton>

            <PrimaryButton size="small" disabled={isLoading} onClick={onSelectTestOrderProducts}>
              Select Products for Test Order
            </PrimaryButton>
          </Flex>
        </Flex.Item>
      </Flex>
    );
  }

  return (
    <Flex column gap="16px">
      <Body as="p">
        Select a pricelist and start creating the test order.{' '}
        <SupportLink article="jkhh9bgxgx">More Info</SupportLink>
      </Body>

      <form onSubmit={onSelectTestOrderProducts} data-testid="prices-select-form">
        <Flex column gap="16px">
          <Flex column gap="8px">
            <InputLabel htmlFor="prices-select">Price List</InputLabel>

            <Select
              options={allPriceLists.map((priceList) => {
                return { value: priceList.id, label: priceList.name };
              })}
              isSearchable
              menuPlacement="auto"
              onChange={(option) => {
                if (option?.value) {
                  setSelectedPriceList(option);
                }
              }}
              value={selectedPriceList}
              id="prices-select"
            />
          </Flex>

          <Flex.Item alignSelfEnd>
            <Flex gap="8px">
              <SecondaryButton size="small" onClick={onMarkComplete}>
                {hasTestOrder ? 'Mark as Completed' : 'Skip'}
              </SecondaryButton>

              <PrimaryButton size="small" type="submit" disabled={isLoading}>
                Select Products for Test Order
              </PrimaryButton>
            </Flex>
          </Flex.Item>
        </Flex>
      </form>
    </Flex>
  );
};

const TestOrderCard = ({ done }: TestOrderCardProps) => {
  const dispatch = useAppDispatch();
  const [testingPartnership, setTestingPartnership] = useState({} as Partner);
  const [isLoading, setIsLoading] = useState(false);
  const [allPriceLists, setAllPriceLists] = useState([] as PriceList[]);
  const [selectedPriceList, setSelectedPriceList] = useState({} as SelectOption);
  const [priceListEntries, setPriceListEntries] = useState([] as PriceListEntry[]);
  const [showTestOrderModal, setShowTestOrderModal] = useState(false);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const hasTestingPartnership = !isEmpty(testingPartnership);
  const alertQueue = useAlertQueue();

  const disabled = allPriceLists.length === 0;

  const fetchingTestOrders = useQuery({
    queryKey: ['getOrders', { page: 0, limit: 1, isTest: true }],
    queryFn: ({ queryKey }) => {
      const params = queryKey[1] as GetOrdersParams;
      return getOrders(params);
    },
  });

  const hasTestOrder = (fetchingTestOrders.data ?? []).length > 0;

  useEffect(() => {
    getPriceLists({ page: 0, limit: 250 })
      .then((response) => {
        setAllPriceLists(response.data);
        if (response.data.length > 0) {
          setSelectedPriceList({ value: response.data[0].id, label: response.data[0].name });
        }
      })
      .catch(() => {
        setAllPriceLists([]);
      });

    getPartners({ limit: 1, reference: CONVICTIONAL_TEST_BUYER_EMAIL }).then((response) => {
      if (response.data.length > 0) {
        setTestingPartnership(response.data[0]);
      }
    });
  }, [setSelectedPriceList]);

  const onMarkComplete = () => {
    dispatch(updateOnboardingStatus({ testOrderCompleted: true }));
  };

  const onSelectTestOrderProducts = (
    event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    const priceListId = hasTestingPartnership
      ? testingPartnership.pricingId
      : selectedPriceList.value;

    // Create testing partnership
    if (!hasTestingPartnership) {
      const partnerData = {
        email: CONVICTIONAL_TEST_BUYER_EMAIL,
        priceId: priceListId,
      };
      invitePartner(partnerData)
        .then((response) => {
          const partnership = { ...response, priceListName: selectedPriceList.label };
          setTestingPartnership(partnership);

          alertQueue.addSuccessAlert(
            'Success',
            'Created Testing Partnership with Modern Dropship Test Buyer'
          );
        })
        .catch((error) => {
          alertQueue.addErrorAlert('Something went wrong', error.message);
        });
    }

    if (priceListId) {
      setIsLoading(true);
      getPriceListEntries(priceListId, { page: 0, limit: 250 })
        .then((response) => {
          setPriceListEntries(response.data);
          setShowTestOrderModal(true);
        })
        .catch((error) => alertQueue.addErrorAlert('Something went wrong', error.message))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onCreateOrder = ({ variant, quantity }: TestOrderParams) => {
    setIsCreatingOrder(true);
    createTestOrder({
      items: [{ variantId: variant.value, quantity: parseInt(quantity, 10) }],
    })
      .then(() => {
        alertQueue.addSuccessAlert('Success', 'Successfully created test order');
        fetchingTestOrders.refetch();
      })
      .catch((error) => alertQueue.addErrorAlert('Something went wrong', error.message))
      .finally(() => {
        setIsCreatingOrder(false);
        setShowTestOrderModal(false);
      });
  };

  return (
    <>
      <GetStartedCard
        isComplete={done}
        title="Test Order"
        completedUi={
          <Body as="p">
            You can check your test order on the <Link to="/orders">Orders page</Link>.
          </Body>
        }
      >
        <TestOrderCardContent
          disabled={disabled}
          hasTestingPartnership={hasTestingPartnership}
          testingPartnership={testingPartnership}
          allPriceLists={allPriceLists}
          selectedPriceList={selectedPriceList}
          setSelectedPriceList={setSelectedPriceList}
          isLoading={isLoading}
          hasTestOrder={hasTestOrder}
          onMarkComplete={onMarkComplete}
          onSelectTestOrderProducts={onSelectTestOrderProducts}
        />
      </GetStartedCard>

      <TestOrderModal
        showModal={showTestOrderModal}
        setShowTestOrderModal={setShowTestOrderModal}
        priceListEntries={priceListEntries}
        onCreateOrder={onCreateOrder}
        isCreatingOrder={isCreatingOrder}
      />
    </>
  );
};

export default TestOrderCard;
