import { useCallback, useEffect, useReducer } from 'react';

export enum PageSelectedState {
  Some = 'some',
  All = 'all',
  None = 'none',
}

type BulkSelectState = {
  pageSelectedState: PageSelectedState;
  selectedIds: string[];
  totalSelectable: number;
};

export const INITIAL_BULK_SELECT_STATE: BulkSelectState = {
  pageSelectedState: PageSelectedState.None,
  selectedIds: [],
  totalSelectable: 0,
};

const SELECT_ID_ACTION = 'select_id';
const DESELECT_ID_ACTION = 'deselect_id';
const SELECT_ALL_IDS_ACTION = 'select_all_ids';
const DESELECT_ALL_IDS_ACTION = 'deselect_all_ids';
const SET_TOTAL_SELECTABLE = 'set_total_selectable';

type BulkSelectAction =
  | { type: typeof SELECT_ID_ACTION; payload: string }
  | { type: typeof DESELECT_ID_ACTION; payload: string }
  | { type: typeof SELECT_ALL_IDS_ACTION; payload: string[] }
  | { type: typeof DESELECT_ALL_IDS_ACTION }
  | { type: typeof SET_TOTAL_SELECTABLE; payload: number };

const bulkSelectReducer = (state: BulkSelectState, action: BulkSelectAction) => {
  switch (action.type) {
    case SET_TOTAL_SELECTABLE: {
      return { ...state, totalSelectable: action.payload };
    }
    case SELECT_ID_ACTION: {
      const selectedIds = [...state.selectedIds, action.payload];
      const isAllSelected = selectedIds.length === state.totalSelectable;
      const pageSelectedState = isAllSelected ? PageSelectedState.All : PageSelectedState.Some;

      return {
        ...state,
        selectedIds,
        pageSelectedState,
      };
    }
    case DESELECT_ID_ACTION: {
      const selectedIds = state.selectedIds.filter((id) => id !== action.payload);
      const isNoneSelected = selectedIds.length === 0;
      const pageSelectedState = isNoneSelected ? PageSelectedState.None : PageSelectedState.Some;

      return {
        ...state,
        selectedIds,
        pageSelectedState,
      };
    }
    case SELECT_ALL_IDS_ACTION: {
      return {
        ...state,
        selectedIds: action.payload,
        pageSelectedState: PageSelectedState.All,
      };
    }
    case DESELECT_ALL_IDS_ACTION: {
      return {
        ...INITIAL_BULK_SELECT_STATE,
        totalSelectable: state.totalSelectable,
      };
    }
    default:
      return state;
  }
};

export const useBulkSelect = (totalSelectable: number) => {
  const [state, dispatch] = useReducer(bulkSelectReducer, INITIAL_BULK_SELECT_STATE);

  useEffect(() => {
    dispatch({ type: SET_TOTAL_SELECTABLE, payload: totalSelectable });
  }, [totalSelectable]);

  const { selectedIds, pageSelectedState } = state;

  const select = (id: string) => dispatch({ type: SELECT_ID_ACTION, payload: id });
  const deselect = (id: string) => dispatch({ type: DESELECT_ID_ACTION, payload: id });
  const selectAll = (ids: string[]) => dispatch({ type: SELECT_ALL_IDS_ACTION, payload: ids });
  const deselectAll = () => dispatch({ type: DESELECT_ALL_IDS_ACTION });

  const isSelected = (id: string) => selectedIds.includes(id);
  const isAnySelected = selectedIds.length > 0;
  const isSelectable = totalSelectable > 0;

  return {
    pageSelectedState,
    selectedIds,
    select: useCallback(select, []),
    deselect: useCallback(deselect, []),
    selectAll: useCallback(selectAll, []),
    deselectAll: useCallback(deselectAll, []),
    isSelected: useCallback(isSelected, [selectedIds]),
    isAnySelected,
    isSelectable,
  };
};
