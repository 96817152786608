import UniversalCookie, { type CookieSetOptions } from 'universal-cookie';

import { ACCOUNT_TYPE_BUYER } from './constants';

const DEFAULT_PATH = '/';
const DEFAULT_SAME_SITE = 'lax';

enum CookieNames {
  UserId = 'userId',
  CompanyId = 'companyId',
  Email = 'email',
  AccountType = 'accountType',
  CSRF = 'csrf_token_read',
}

const Cookies = new UniversalCookie();

/**
 * Setup helpers
 */

const getIsSecure = () => {
  if (window.location.hostname === 'localhost') {
    return false;
  }

  return true;
};

const getDefaultExpiry = (now = new Date()) => {
  const thisYear = now.getFullYear();

  const nextYear = now;
  nextYear.setFullYear(thisYear + 1);

  return nextYear;
};

const getCookieSetOptions = (options?: CookieSetOptions): CookieSetOptions => {
  return {
    path: DEFAULT_PATH,
    sameSite: DEFAULT_SAME_SITE,
    expires: getDefaultExpiry(),
    secure: getIsSecure(),
    ...options,
  };
};

/**
 * Setters
 */

export const setUserId = (value: string, options?: CookieSetOptions) => {
  Cookies.set(CookieNames.UserId, value, getCookieSetOptions(options));
};

export const setEmail = (value: string, options?: CookieSetOptions) => {
  Cookies.set(CookieNames.Email, value, getCookieSetOptions(options));
};

export const setAccountType = (value: string, options?: CookieSetOptions) => {
  Cookies.set(CookieNames.AccountType, value, getCookieSetOptions(options));
};

/**
 * Getters
 */

export const getEmail = () => {
  return Cookies.get(CookieNames.Email, {});
};

export const getUserId = () => {
  return Cookies.get(CookieNames.UserId, {});
};

export const getAccountType = () => {
  return Cookies.get(CookieNames.AccountType, {});
};

export const getCSRF = () => {
  return Cookies.get(CookieNames.CSRF, { doNotParse: true });
};

/**
 * General Helpers
 */

export const isAccountTypeBuyer = () => {
  return getAccountType() === ACCOUNT_TYPE_BUYER;
};

export const getIsAuthenticated = () => {
  return getUserId() && getEmail();
};

export const destroyAuthCookies = () => {
  Cookies.remove(CookieNames.UserId, { path: DEFAULT_PATH });
  Cookies.remove(CookieNames.CompanyId, { path: DEFAULT_PATH });
  Cookies.remove(CookieNames.Email, { path: DEFAULT_PATH });
  Cookies.remove(CookieNames.AccountType, { path: DEFAULT_PATH });
};
