import { SettingsMain, SettingsPageHeader } from 'containers/SettingsPage/SettingsLayout';
import ApiKeySettings from './ApiKeySettings';
import ConnectBigCommerce from './ConnectBigCommerce';
import ConnectEasyPost from './ConnectEasyPost';
import ConnectMagento2 from './ConnectMagento2';
import EDISettings from './EDISettings';
import ExternalSFTPSettings from './ExternalSFTPSettings';
import InternalSFTPSettings from './InternalSFTPSettings';

import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store';
import {
  selectCompany,
  selectIsBuyer,
  selectIsProductPlatformBigCommerce,
  selectIsProductPlatformExternalSFTP,
  selectIsProductPlatformInternalSFTP,
  selectIsProductPlatformMagento2,
  selectIsProductPlatformShopify,
  selectIsProductPlatformWooCommerce,
} from 'store/selectors/me/company';
import { selectUser } from 'store/selectors/me/user';
import ConnectShopify from './Shopify/Connect';
import SyncingSettings from './SyncingSettings';
import ConnectWooCommerce from './WooCommerce/ConnectWooCommerce';

const IntegrationsSettings = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const user = useAppSelector(selectUser);

  // Helpers used for the conditional rendering below
  const isShopify = useSelector(selectIsProductPlatformShopify);
  const isWooCommerce = useSelector(selectIsProductPlatformWooCommerce);
  const isMagento2 = useSelector(selectIsProductPlatformMagento2);
  const isBigCommerce = useSelector(selectIsProductPlatformBigCommerce);
  const isSFTPInternal = useSelector(selectIsProductPlatformInternalSFTP);
  const isSFTPExternal = useSelector(selectIsProductPlatformExternalSFTP);
  const isBuyer = useSelector(selectIsBuyer);

  return (
    <SettingsMain>
      <SettingsPageHeader title="Integrations" />
      <ApiKeySettings apiKey={user?.apiKey} dispatch={dispatch} />
      {isShopify && <ConnectShopify />}
      {isWooCommerce && <ConnectWooCommerce company={company} dispatch={dispatch} />}
      {isMagento2 && <ConnectMagento2 company={company} dispatch={dispatch} />}
      {isBigCommerce && <ConnectBigCommerce company={company} dispatch={dispatch} />}
      {isSFTPInternal && <InternalSFTPSettings dispatch={dispatch} />}
      {isSFTPExternal && <ExternalSFTPSettings dispatch={dispatch} company={company} />}
      {isBuyer && <ConnectEasyPost dispatch={dispatch} company={company} />}
      <EDISettings company={company} />
      <SyncingSettings company={company} />
    </SettingsMain>
  );
};

export default IntegrationsSettings;
