import { PageSelectedState } from 'hooks/useBulkSelect';
import ContentPasteSearchSVG from 'images/content_paste_search.svg';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import NoResultsCard from 'storybook/stories/cells/Card/NoResultsCard';
import PageList from 'storybook/stories/cells/PageList';
import PageNavigation from 'storybook/stories/cells/PageNavigation';
import SearchForm, { SearchFormButton, SearchFormInput } from 'storybook/stories/cells/SearchForm';
import SellerOrderSearchResult from 'storybook/stories/cells/SearchResult/SellerOrderSearchResult';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Checkbox from 'storybook/stories/molecules/Checkbox';
import Link from 'storybook/stories/molecules/Link';
import SearchResults, {
  SearchResultsFooter,
  SearchResultsHeader,
} from 'storybook/stories/organisms/SearchResults';
import type { OrderSearchResponse } from 'utils/api/orders';

export type OrderSearchFormInputs = {
  query: string;
};

interface OrderSearchFormProps {
  onSearchFormSubmit: SubmitHandler<OrderSearchFormInputs>;
  defaultSearchFormInputValue?: string;
}

const OrderSearchForm = ({
  onSearchFormSubmit,
  defaultSearchFormInputValue,
}: OrderSearchFormProps) => {
  const { register, handleSubmit } = useForm<OrderSearchFormInputs>();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit(onSearchFormSubmit)();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSearchFormSubmit, handleSubmit]);

  return (
    <SearchForm onSubmit={handleSubmit(onSearchFormSubmit)}>
      <SearchFormInput
        placeholder="Search"
        {...register('query')}
        defaultValue={defaultSearchFormInputValue}
      />
      <SearchFormButton data-testid="submit-query" />
    </SearchForm>
  );
};

interface SellerOrderSearchResultsProps {
  results?: OrderSearchResponse;
  onSearchFormSubmit: SubmitHandler<OrderSearchFormInputs>;
  defaultSearchFormInputValue?: string;
  currentPage: number;
  onPreviousClick: React.MouseEventHandler<HTMLButtonElement>;
  onNextClick: React.MouseEventHandler<HTMLButtonElement>;
  onPageClick: (pageNumber: number) => void;
  onSearchResultClick: (orderId: string) => void;
  headerActionsUi: React.ReactNode;
  headerFiltersUi: React.ReactNode;
  onSelectPageChange: React.ChangeEventHandler<HTMLInputElement>;
  onSelectRowChange: (id: string) => void;
  isSelected: (id: string) => boolean;
  isSelectable: boolean;
  pageSelectedState: PageSelectedState;
}

/**
 * `SearchResults` implementation for a Buyer & Seller search results
 */
const SellerOrderSearchResults = ({
  results,
  onSearchFormSubmit,
  defaultSearchFormInputValue,
  currentPage,
  onPreviousClick,
  onNextClick,
  onPageClick,
  onSearchResultClick,
  headerActionsUi,
  headerFiltersUi,
  onSelectPageChange,
  onSelectRowChange,
  isSelected,
  isSelectable,
  pageSelectedState,
}: SellerOrderSearchResultsProps) => {
  if (!results) return null;

  const orders = results.data.orders ?? [];
  const pagination = results.data.pagination ?? {};

  const hasPrevious = currentPage > 1;

  const headerSearchFormUi = (
    <OrderSearchForm
      onSearchFormSubmit={onSearchFormSubmit}
      defaultSearchFormInputValue={defaultSearchFormInputValue}
    />
  );

  const headerCheckboxUi = (
    <Checkbox
      name="selectPage"
      onChange={onSelectPageChange}
      checked={pageSelectedState !== PageSelectedState.None}
      indeterminate={pageSelectedState === PageSelectedState.Some}
      disabled={!isSelectable}
      aria-label={
        pageSelectedState !== PageSelectedState.None
          ? 'Remove all orders on page from selection'
          : 'Add all orders on page to selection'
      }
    />
  );

  const headerUi = (
    <SearchResultsHeader
      searchFormUi={headerSearchFormUi}
      checkboxUi={headerCheckboxUi}
      actionsUi={headerActionsUi}
      filtersUi={headerFiltersUi}
    />
  );

  const footerPageListUi = (
    <PageList
      currentPage={currentPage}
      onPageClick={onPageClick}
      lastPage={pagination.totalPages}
    />
  );

  const footerNavigationUi = (
    <PageNavigation
      hasPrevious={hasPrevious}
      hasNext={results.hasMore}
      onPreviousClick={onPreviousClick}
      onNextClick={onNextClick}
    />
  );

  const footerUi = (
    <SearchResultsFooter
      pageListUi={footerPageListUi}
      navigationUi={footerNavigationUi}
      totalResults={pagination.total}
    />
  );

  if (orders.length === 0) {
    return (
      <SearchResults headerUi={headerUi} footerUi={footerUi}>
        <NoResultsCard
          imageSrc={ContentPasteSearchSVG}
          title="No orders match your search"
          description="You can reset your search by clicking the button below"
        >
          <PrimaryButton $iconName="restart_alt" as={Link} to="/orders">
            Reset Search
          </PrimaryButton>
        </NoResultsCard>
      </SearchResults>
    );
  }

  return (
    <SearchResults headerUi={headerUi} footerUi={footerUi}>
      {orders.map((order) => {
        const checkboxUi = (
          <Checkbox
            name="selectRow"
            onClick={(event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation()}
            onChange={() => onSelectRowChange(order._id)}
            checked={isSelected(order._id)}
            aria-label={
              isSelected(order._id) ? `Remove order from selection` : `Add order to selection`
            }
          />
        );

        return (
          <SellerOrderSearchResult
            key={order._id}
            order={order}
            onClick={() => onSearchResultClick(order._id)}
            checkboxUi={checkboxUi}
            hasWarning={order.flagged}
          />
        );
      })}
    </SearchResults>
  );
};

export default SellerOrderSearchResults;
