import { useLocation } from 'react-router';
import { useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';
import type { UserOnboardingAttributes } from 'types/models/user';

const useUserOnboardingAttributes = () => {
  const user = useAppSelector(selectUser);
  const location = useLocation<{ isCreatingAnotherCompany?: boolean }>();

  const onboardingAttributes = user?.onboardingAttributes || ({} as UserOnboardingAttributes);
  const isCreatingAnotherCompany = !!location.state?.isCreatingAnotherCompany;

  return Object.entries(onboardingAttributes).reduce((accumulator, [key, value]) => {
    if (!value) return accumulator;
    if (isCreatingAnotherCompany) return accumulator;

    accumulator[key as keyof UserOnboardingAttributes] = value as any;

    return accumulator;
  }, {} as Partial<UserOnboardingAttributes>);
};

export default useUserOnboardingAttributes;
