import Icon from 'storybook/stories/molecules/Icon';

import './SeverityBadge.css';
import { getBadgeBySeverity, getIconBySeverity } from './Shared';

const SeverityBadge = ({ severity }) => (
  <span className={`badge severity-badge ${getBadgeBySeverity(severity)}`}>
    <Icon name={getIconBySeverity(severity)}>{severity}</Icon>
  </span>
);

export default SeverityBadge;
