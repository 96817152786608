import Flex from '@react-css/flex';
import Dropdown from 'storybook/stories/cells/Dropdown';
import FileUpload, { type UploadedFile } from 'storybook/stories/cells/FileUpload';
import Body from 'storybook/stories/molecules/Body';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Divider from 'storybook/stories/molecules/Divider';
import Icon from 'storybook/stories/molecules/Icon';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

export interface ManageWithCsvDropdownProps {
  onProcessFile: (file: UploadedFile) => Promise<string | ArrayBuffer | null>;
  onGeneratePreview: (rawFile: string | ArrayBuffer | null) => void;
  onClickExportCsv: () => void;
}

const ManageWithCsvDropdown = ({
  onProcessFile,
  onGeneratePreview,
  onClickExportCsv,
}: ManageWithCsvDropdownProps) => {
  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        <SecondaryButton size="small" $iconName="csv">
          Manage with CSV
        </SecondaryButton>
      </Dropdown.Trigger>

      <Dropdown.Content>
        {({ close }) => (
          <Flex column gap="24px">
            <Flex alignContentSpaceBetween justifySpaceBetween>
              <Icon color="gray500" name="csv">
                Export Price List
              </Icon>

              <SecondaryButton $iconName="download" size="small" onClick={onClickExportCsv}>
                Export CSV
              </SecondaryButton>
            </Flex>

            <Flex alignItemsCenter gap="16px">
              <Divider />
              <Body variant="Body/Body Small" color="bodyTextSecondary">
                or
              </Body>
              <Divider />
            </Flex>

            <FileUpload
              onUpload={(data: UploadedFile) => {
                onProcessFile(data).then((file) => {
                  onGeneratePreview(file);
                  close();
                });
              }}
            >
              <Flex alignItemsCenter gap="16px" style={{ height: '100%' }}>
                <Icon name="upload_file" size="48px" color="iconDefault" />

                <Flex column alignItemsStart gap="8px">
                  <Body variant="Body/Regular" as="strong" align="center">
                    Upload CSV
                  </Body>

                  <Body variant="Inputs/Input Message" align="center" color="bodyTextSecondary">
                    Drag and drop or{' '}
                    <Body variant="Inputs/Input Message" color="blue500">
                      click here to select a CSV file
                    </Body>{' '}
                    to update pricing.
                  </Body>
                </Flex>
              </Flex>
            </FileUpload>

            <SupportLink article="am55txvvky#price_list_editing_using_a_csv_file">
              <Flex gap="8px">
                <Body variant="Body/Regular">Price List CSV Guide</Body>
                <Icon color="blue500" name="open_in_new" />
              </Flex>
            </SupportLink>
          </Flex>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default ManageWithCsvDropdown;
