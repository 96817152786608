import Flex from '@react-css/flex';
import PreviewStatusRow, { PreviewStatus } from 'containers/PriceListPage/CSV/PreviewStatusRow';
import Modal from 'storybook/stories/cells/Modal';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import type { PriceListCSVPreview } from 'types/models/price-list';

export interface PreviewPriceListCSVModalProps {
  preview: PriceListCSVPreview | undefined;
  shouldShowModal: boolean;
  onDismiss: () => void;
  onConfirmImport: () => void;
}

const PreviewPriceListCsvModal = ({
  preview,
  shouldShowModal,
  onDismiss,
  onConfirmImport,
}: PreviewPriceListCSVModalProps) => {
  if (!preview) {
    return (
      <Modal.Root centered show={shouldShowModal} onHide={onDismiss} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Importing CSV</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Body>Uploading your file...</Body>
        </Modal.Body>
      </Modal.Root>
    );
  }

  return (
    <Modal.Root centered show={shouldShowModal} onHide={onDismiss} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Importing CSV</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Flex column gap="24px">
          <Body>
            Your file has been uploaded. We found the following changes listed below. Would you like
            to process and update the price list?
          </Body>

          <Flex column gap="12px">
            {preview.createdEntryCount > 0 && (
              <PreviewStatusRow status={PreviewStatus.Created} count={preview.createdEntryCount} />
            )}

            {preview.updatedEntryCount > 0 && (
              <PreviewStatusRow status={PreviewStatus.Updated} count={preview.updatedEntryCount} />
            )}

            {preview.deletedEntryCount > 0 && (
              <PreviewStatusRow status={PreviewStatus.Removed} count={preview.deletedEntryCount} />
            )}
          </Flex>
        </Flex>
      </Modal.Body>

      <Modal.Footer>
        <TertiaryButton onClick={onDismiss}>Cancel</TertiaryButton>

        <PrimaryButton kind="action" onClick={onConfirmImport}>
          Confirm
        </PrimaryButton>
      </Modal.Footer>
    </Modal.Root>
  );
};

export default PreviewPriceListCsvModal;
