/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import Button from 'components/Common/Button';
import Message from 'components/Common/Message';
import { createInvoice } from 'store/slices/ordersV2';
import Icon from 'storybook/stories/molecules/Icon';
import { getSuggestedTaxes } from 'utils/api/orders';
import { HST_SUPPORTED_PROVINCES } from 'utils/platform';

import GenerateInvoiceSingleTaxItem from 'containers/OrderPage/GenerateInvoiceSingleTaxItem';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import './GenerateInvoiceForm.css';

export const ESTIMATED_TAXES_DELAY = 3000;

const calculateHstTaxAmount = (taxBreakdown) =>
  taxBreakdown.reduce((accumulator, tax) => {
    if (['GS', 'SP'].includes(tax.type)) return accumulator + parseFloat(tax.amount);
    return accumulator;
  }, 0);

const GenerateInvoiceForm = ({ order, showErrorMessage, dispatch }) => {
  const [loadingEstimated, setLoadingEstimated] = useState(true);
  const [submitting, setSubmitting] = useState(false); // Used to monitor submitting of invoice
  const [taxes, setTaxes] = useState([]);
  const [hstTaxAmount, setHstTaxAmount] = useState(0);
  const [suggestedTaxes, setSuggestedTaxes] = useState([]);
  const [suggestedTaxesError, setSuggestedTaxesError] = useState(null);

  const shouldUseHst = HST_SUPPORTED_PROVINCES.includes(order.billingAddress?.state);

  const loadInitialTaxes = () => {
    setLoadingEstimated(true);
    getSuggestedTaxes(order._id)
      .then((response) => {
        if (response.data.breakdown) {
          const results = [];
          // The ID is required for being able to add and remove line items.
          for (let i = 0; i < response.data.breakdown.length; i += 1) {
            results.push({
              ...response.data.breakdown[i],
              id: i + 1,
            });
          }
          setSuggestedTaxes(results);
          setTaxes(results);
          setHstTaxAmount(calculateHstTaxAmount(results));

          setTimeout(() => {
            // Decide to show us "doing work", and  highlight why its not
            // automated yet.
            setLoadingEstimated(false);
          }, ESTIMATED_TAXES_DELAY);
        }
      })
      .catch((err) => {
        if (err.toString().indexOf('No billing address set for company') !== -1) {
          setSuggestedTaxesError(
            <span>
              You must <Link to="/settings/company/locations">set a billing address</Link> to get
              tax estimations.
            </span>
          );
        }
        setLoadingEstimated(false);
      });
  };

  useEffect(() => {
    loadInitialTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddLineItem = (e) => {
    e.preventDefault();
    const newTaxes = [...taxes];
    newTaxes.push({
      type: 'GS',
      amount: '',
      id: uuid(),
    });
    setTaxes(newTaxes);
    setHstTaxAmount(calculateHstTaxAmount(newTaxes));
  };

  const onUpdate = (e, index, newValue) => {
    e.preventDefault();
    if (taxes.length < index) {
      taxes.push(newValue);
    } else {
      taxes[index] = newValue;
    }
    setTaxes(taxes);
    setHstTaxAmount(calculateHstTaxAmount(taxes));
  };

  const onRemoveTaxItem = (e, index) => {
    e.preventDefault();
    if (index > -1) {
      const newTaxes = [...taxes];
      newTaxes.splice(index, 1);
      setTaxes(newTaxes);
      setHstTaxAmount(calculateHstTaxAmount(newTaxes));
    }
  };

  const onCreateInvoice = (evt) => {
    evt.preventDefault();
    setSubmitting(true);
    for (let i = 0; i < taxes.length; i += 1) {
      const taxAmountNum = parseFloat(taxes[i].amount);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(taxAmountNum)) {
        showErrorMessage({
          title: 'Error generating invoice',
          message: 'Tax amount must be a valid number',
        });
        return;
      }

      // Remove ID
      delete taxes[i].id;
      taxes[i].amount = taxAmountNum;
    }
    const invoice = {
      taxes,
    };
    dispatch(createInvoice({ orderId: order._id, invoice })).then((action) => {
      if (action.error) {
        showErrorMessage({ title: 'Error generating invoice', message: action.error.message });
      }

      if (
        taxes.length !== suggestedTaxes.length ||
        taxes.every((value, index) => {
          return value === suggestedTaxes[index];
        })
      ) {
        // Suggested Taxes and submitted taxes do not line up, why? Feedback sent to our team
        console.warn(
          'Submitted taxes and suggested taxes did not line up.',
          order._id,
          taxes,
          suggestedTaxes
        );
      }
    });
  };

  if (!order.shipped) {
    return (
      <p className="text-muted">You cannot create an invoice until the order has been shipped.</p>
    );
  }
  if (loadingEstimated) {
    return (
      <div className="d-flex align-items-center">
        <div className="spinner-border estimated-spinner" role="status" />
        <div className="estimated-loading-text-wrapper">
          <label className="estimated-loading-text">Estimating Taxes... </label>
          <label className="estimated-loading-subtext text-muted">
            We use a third-party service to provide an estimated tax amount.
          </label>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={onCreateInvoice}>
      {suggestedTaxesError && (
        <div>
          <Message show kind="error">
            {suggestedTaxesError}
          </Message>
        </div>
      )}
      {shouldUseHst && (
        <div className="mb-4" data-testid="harmonized-sales-tax">
          <p className="mb-0">Harmonized Sales Tax (HST) Amount: C${hstTaxAmount.toFixed(2)}</p>
          <p className="text-muted small mb-0">HST is calculated by combining GST and PST</p>
        </div>
      )}
      {taxes.map((taxItem, index) => (
        <GenerateInvoiceSingleTaxItem
          initialAmount={taxItem.amount}
          initialType={taxItem.type}
          index={index}
          hideRemoveTaxItem={taxes.length <= 1}
          key={taxItem.id}
          id={taxItem.id}
          onUpdate={onUpdate}
          onRemoveTaxItem={onRemoveTaxItem}
          showProvince={
            order.shippingAddress.country.toLowerCase() === 'canada' ||
            order.shippingAddress.country.toLowerCase() === 'ca'
          }
        />
      ))}

      <div>
        <Button
          outline
          color="secondary"
          size="sm"
          className="add-tax-line-item-btn"
          onClick={onAddLineItem}
        >
          <Icon name="add">Add Tax Line Item</Icon>
        </Button>
      </div>
      <div className="d-flex justify-content-end">
        <Button outline size="sm" color="primary" disabled={submitting}>
          Generate Invoice
        </Button>
      </div>
      <div>
        <p className="text-muted">
          You can <SupportLink article="v3t009ajik">read more about manual invoices</SupportLink>.
          All submitted invoices are final and cannot be changed.
        </p>
      </div>
    </form>
  );
};

export default GenerateInvoiceForm;
