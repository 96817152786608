/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from 'react';
import Modal from 'storybook/stories/cells/Modal';

import Button from 'components/Common/Button';
import Icon from 'storybook/stories/molecules/Icon';
import getGoogleCategoryData from 'utils/googleCategories';

import GoogleCategorySelect from './GoogleCategorySelect';

const GoogleCategoryModal = ({ onDismiss, show, onAssignCategory }) => {
  const categoryTrieRef = useRef();
  const categoryListRef = useRef();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [fetchingCategories, setFetchingCategories] = useState(true);
  // categoryPath is a string representation of the hierarchy
  // ex: Sporting Goods > Indoor Games > Table Shuffleboard
  const [categoryPath, setCategoryPath] = useState('');

  useEffect(() => {
    getGoogleCategoryData().then(({ list, trie }) => {
      categoryTrieRef.current = trie;
      categoryListRef.current = list;
      setFetchingCategories(false);
    });
  }, [setFetchingCategories]);

  const onBack = () => setSelectedCategory(null);

  const onAssign = () => {
    const { code, name } = selectedCategory;
    onAssignCategory({
      code: parseInt(code, 10),
      name,
    });
    setSelectedCategory(null);
  };

  return (
    <Modal.Root
      show={show}
      size="md"
      aria-labelledby="assign-category-modal"
      centered
      onHide={onDismiss}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign a Google Product Category</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {selectedCategory ? (
          <div className="card-body">
            <p className="h4 mb-3 mt-3">Selected Category:</p>
            <div className="d-flex mb-5">
              <p className="mb-0">{selectedCategory.code}:</p>
              <p className="mb-0 ml-2">{selectedCategory.name}</p>
            </div>
            <div className="d-flex justify-content-end">
              <Button size="sm" color="white" onClick={onBack}>
                Back
              </Button>
              <Button size="sm" color="primary" className="ml-3" onClick={onAssign}>
                Assign Category
              </Button>
            </div>
          </div>
        ) : fetchingCategories ? (
          <div
            className="d-flex flex-column justify-content-center"
            style={{ minHeight: '14rem' }}
          />
        ) : !fetchingCategories && (!categoryListRef.current || !categoryTrieRef.current) ? (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: '14rem' }}
          >
            <Icon name="report" className="text-danger">
              Could not get categories, please reload the page and try again. Contact
              support@convictional.com if this problem persists.
            </Icon>
          </div>
        ) : show ? (
          <GoogleCategorySelect
            onSelectCategory={setSelectedCategory}
            categoryList={categoryListRef.current}
            categoryTrie={categoryTrieRef.current}
            categoryPath={categoryPath}
            setCategoryPath={setCategoryPath}
          />
        ) : null}
      </Modal.Body>
    </Modal.Root>
  );
};

export default GoogleCategoryModal;
