import type { Dispatch, SetStateAction } from 'react';
import type { CommerceType, Platform } from 'types/models/company';
import { useLocalStorage } from 'usehooks-ts';

export type OnboardingStorage = {
  currentStep: number;
  commerceType?: CommerceType;
  platform?: Platform;
  isCreatingAnotherCompany?: boolean;
};

type UseOnboardingStorage = {
  onboardingStorage: OnboardingStorage | undefined;
  setOnboardingStorage: Dispatch<SetStateAction<OnboardingStorage | undefined>>;
};

const KEY = 'onboardingStorage';

const useOnboardingStorage = (): UseOnboardingStorage => {
  const [onboardingStorage, setOnboardingStorage] = useLocalStorage<OnboardingStorage | undefined>(
    KEY,
    undefined
  );

  return { onboardingStorage, setOnboardingStorage };
};

export default useOnboardingStorage;
