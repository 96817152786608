/* eslint-disable jsx-a11y/label-has-associated-control */
import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import type { ChangeEvent } from 'react';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import useAlertQueue from 'hooks/useAlertQueue';
import type { Partner } from 'types/models/partner';
import { getPartners } from 'utils/api/partners';
import { CreatePriceListContext } from './context';

import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Checkbox from 'storybook/stories/molecules/Checkbox';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import Label from 'storybook/stories/molecules/Label';
import ProgressBar from 'storybook/stories/organisms/ProgressBar';

const FETCHING_PARTNERS_QUERY_KEY = 'fetchingPartners';

const ScrollableContainer = styled.div`
  max-height: 20vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

// sortPartners sorts the fetched partners list by placing those without a price list at the top.
const sortPartners = (partners: Partner[]) => {
  return partners.sort((a, b) => {
    if (a.priceListName && !b.priceListName) {
      return 1;
    }
    if (!a.priceListName && b.priceListName) {
      return -1;
    }
    return 0;
  });
};

interface FooterProps {
  isNextButtonDisabled: boolean;
}

const Footer = ({ isNextButtonDisabled }: FooterProps) => {
  const { updateState } = useContext(CreatePriceListContext);

  const navigateBack = () => {
    updateState({ currentStep: 'AddExistingProductsForm' });
  };

  const skipForward = () => {
    updateState({ assignedPartners: {}, currentStep: 'CurrencyForm' });
  };

  return (
    <Card>
      <Flex justifySpaceBetween>
        <TertiaryButton size="small" $iconName="navigate_before" onClick={navigateBack}>
          Back
        </TertiaryButton>

        <Flex gap="24px">
          <SecondaryButton size="small" onClick={skipForward}>
            Decide Later
          </SecondaryButton>

          <PrimaryButton
            type="submit"
            size="small"
            $iconName="navigate_next"
            $iconAfter
            disabled={isNextButtonDisabled}
          >
            Next
          </PrimaryButton>
        </Flex>
      </Flex>
    </Card>
  );
};

const PartnersForm = () => {
  const { addErrorAlert } = useAlertQueue();
  const { assignedPartners, updateState } = useContext(CreatePriceListContext);
  const [selectedPartners, setSelectedPartners] = useState(assignedPartners);

  const {
    handleSubmit,
    formState: { isValid },
  } = useForm({});

  const onSubmit = () => {
    updateState({ assignedPartners: selectedPartners, currentStep: 'CurrencyForm' });
  };

  const fetchingPartners = useQuery({
    queryKey: [FETCHING_PARTNERS_QUERY_KEY],
    queryFn: () => getPartners({ limit: 250 }),
    onError: (error) => {
      addErrorAlert('Something went wrong', 'Unable to fetch partners list');
      console.error('Failed to fetch partners for partner list', error);
    },
  });

  const partners = sortPartners(fetchingPartners.data?.data ?? []);

  const shouldDisableButton = !isValid || isEmpty(selectedPartners);

  // handleCheckboxClick acts as a toggle. If a partner is already selected, it will be removed
  // from the list and React context. If it is not selected, it will be added.
  const handleCheckboxClick = (partner: Partner) => {
    setSelectedPartners((prevPartners) => {
      const newPartners = { ...prevPartners };
      if (partner._id in newPartners) {
        delete newPartners[partner._id];
      } else {
        newPartners[partner._id] = {
          id: partner._id,
          name: partner.buyerName,
        };
      }
      return newPartners;
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex column gap="16px">
        <Card>
          <Flex column gap="28px">
            <ProgressBar totalSteps={5} completedSteps={3} />

            <Flex column gap="32px">
              <Flex column gap="8px">
                <Heading variant="Headings/H3">
                  What partners do you want to assign this price list to?
                </Heading>
                <Body variant="Body/Regular" color="bodyTextSecondary">
                  Selecting a partner with an existing price list will replace it with this price
                  list. You can always add a partner later but you won’t be able to trade with this
                  pricing until you do.
                </Body>
              </Flex>

              <ScrollableContainer>
                {partners?.map((partner: Partner) => (
                  <Flex
                    key={partner._id}
                    gap="8px"
                    alignItemsStart
                    style={{ margin: '12px 0px', padding: '12px 8px' }}
                    onClick={() => handleCheckboxClick(partner)}
                  >
                    <Checkbox
                      id={`assign-partner-${partner._id}`}
                      name="assign-partner"
                      checked={partner._id in selectedPartners}
                      key={`assign-partner-${partner._id}`}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        event.stopPropagation();
                      }}
                    />

                    <Label htmlFor={partner._id}>
                      <Flex column gap="8px">
                        <Body variant="Body/Body Small">{partner.buyerName}</Body>

                        <Flex gap="4px">
                          {partner.priceListName ? (
                            <>
                              <Icon name="request_quote" />
                              <Body variant="Body/Header">{partner.priceListName}</Body>
                            </>
                          ) : (
                            <>
                              <Icon name="scan_delete" color="gray400" />
                              <Body variant="Body/Header" color="gray400">
                                No Price List
                              </Body>
                            </>
                          )}
                        </Flex>
                      </Flex>
                    </Label>
                  </Flex>
                ))}
              </ScrollableContainer>
            </Flex>
          </Flex>
        </Card>

        <Footer isNextButtonDisabled={shouldDisableButton} />
      </Flex>
    </form>
  );
};

export default PartnersForm;
