import Flex from '@react-css/flex';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { getShopifyAuthUrl } from 'utils/platform';

const ConnectShopifyCard = ({ done, store }) => {
  return (
    <GetStartedCard
      title="Connect your Shopify store"
      isComplete={done}
      completedUi={
        <Body>
          Manage your integration from your{' '}
          <Link to="/settings/dropshipping/integrations">Integration settings</Link>.
        </Body>
      }
    >
      {store ? (
        <Flex column gap="16px">
          <Body as="p">
            Please connect the Modern Dropship Shopify app to your store.{' '}
            <SupportLink article="eqxyqt0mtj">More Info</SupportLink>
          </Body>

          <Body>
            Store URL: <strong>{`https://${store}.myshopify.com`}</strong>
          </Body>

          <Flex.Item alignSelfEnd>
            <PrimaryButton
              size="small"
              as="a"
              rel="noopener noreferrer"
              href={getShopifyAuthUrl(store)}
            >
              Connect
            </PrimaryButton>
          </Flex.Item>
        </Flex>
      ) : (
        <Body as="strong" color="error500">
          Your Shopify Store URL is missing! Please contact support.
        </Body>
      )}
    </GetStartedCard>
  );
};

export default ConnectShopifyCard;
