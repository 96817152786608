import Avatar from 'storybook/stories/cells/Avatar';
import { CommerceType } from 'types/models/company';

export const COMMERCE_TYPE_AVATAR_SIZE = '40px';

interface CommerceTypeAvatarProps {
  commerceType: CommerceType;
}

const CommerceTypeAvatar = ({ commerceType }: CommerceTypeAvatarProps) => (
  <Avatar
    iconName={commerceType === CommerceType.Buyer ? 'domain' : 'store'}
    text={commerceType}
    backgroundColor={commerceType === CommerceType.Buyer ? 'blue100' : 'green100'}
    color={commerceType === CommerceType.Buyer ? 'blue700' : 'green700'}
    borderColor={commerceType === CommerceType.Buyer ? 'blue200' : 'green200'}
    size={COMMERCE_TYPE_AVATAR_SIZE}
  />
);

export default CommerceTypeAvatar;
