import Flex from '@react-css/flex';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { selectIsSeller } from 'store/selectors/me/company';
import Card from 'storybook/stories/cells/Card';
import ChipList from 'storybook/stories/cells/ChipList';
import Table from 'storybook/stories/cells/Table';
import Heading from 'storybook/stories/molecules/Heading';
import Link from 'storybook/stories/molecules/Link';
import type { ShippingMethod } from 'types/models/shipping';

import { formatShippingLevel } from 'utils/shipping';

type PartnerShippingMethodsProps = {
  shippingMethods?: ShippingMethod[];
};

const PartnerShippingMethods = ({ shippingMethods = [] }: PartnerShippingMethodsProps) => {
  const isSeller = useAppSelector(selectIsSeller);

  const sortedShippingMethods = useMemo(
    () => [...shippingMethods].sort((a, b) => a.maxDays - b.maxDays),
    [shippingMethods]
  );

  return (
    <Card>
      <Card.Head>
        <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
          {isSeller ? 'Supported' : 'Required'} Shipping Methods
        </Heading>
      </Card.Head>

      <Card.Body>
        <Flex column gap="24px">
          {sortedShippingMethods.length > 0 && (
            <Table>
              <Table.TBody>
                {sortedShippingMethods.map((method) => (
                  <Table.TR key={method.id}>
                    <Table.TD>
                      <strong>{formatShippingLevel(method.maxDays)}</strong>
                    </Table.TD>
                    <Table.TD>{method.description}</Table.TD>
                    <Table.TD>
                      <ChipList items={method.carrierNames} />
                    </Table.TD>
                  </Table.TR>
                ))}
              </Table.TBody>
            </Table>
          )}

          <span>
            Configure your {isSeller ? 'supported' : 'required'}{' '}
            <Link to="/settings/dropshipping/shipping">shipping methods</Link> in your company
            settings.
          </span>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default PartnerShippingMethods;
