import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { updateMagento2Auth } from 'store/slices/company';
import { useSafeState } from 'utils/hooks';

import Flex from '@react-css/flex';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

const ConnectMagento2Card = ({ done, storeUrl }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [error, setError] = useSafeState('');
  const pending = useSelector(({ company }) => company.isUpdatingPlatformAuth);

  const onSubmit = ({ consumerKey, consumerSecret, accessToken, accessTokenSecret, storeView }) => {
    setError('');
    dispatch(
      updateMagento2Auth({
        magento2Auth: {
          platform: 'magento2',
          platformUrl: storeUrl,
          consumerKey,
          consumerSecret,
          accessToken,
          accessTokenSecret,
          storeView,
        },
      })
    ).then((action) => {
      if (action.error) {
        setError(action.error.message);
      }
    });
  };

  return (
    <GetStartedCard
      isComplete={done}
      title="Connect your Magento 2 store"
      completedUi={
        <Body>
          Manage your integration from your{' '}
          <Link to="/settings/dropshipping/integrations">Integration settings</Link>.
        </Body>
      }
    >
      <Body as="p">
        Please input your Magento 2 credentials so Modern Dropship can connect to your store.
        Credentials can be created in Magento at{' '}
        <strong>System &gt; Integrations &gt; Add New Integration.</strong> Make sure to set
        Resource Access to <code>All</code>. View the full{' '}
        <SupportLink article="uow9hojkbq">Magento 2 Connection Guide</SupportLink>.
      </Body>

      <Body>
        Store URL: <strong>{storeUrl}</strong>
      </Body>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex column gap="16px">
          <Flex column gap="8px">
            <Label htmlFor="magento2-consumer-key-input">Consumer Key</Label>
            <Input
              placeholder="Magento 2 Consumer Key"
              {...register('consumerKey', {
                required: 'Magento 2 Consumer Key is required',
              })}
            />
            {errors.consumerKey && (
              <Body color="error500" variant="Body/Body Small">
                {errors.consumerKey.message}
              </Body>
            )}
          </Flex>

          <Flex column gap="8px">
            <Label htmlFor="magento2-consumer-secret-input">Consumer Secret</Label>
            <Input
              placeholder="Magento 2 Consumer Secret"
              {...register('consumerSecret', {
                required: 'Magento 2 Consumer Secret is required',
              })}
            />
            {errors.consumerSecret && (
              <Body color="error500" variant="Body/Body Small">
                {errors.consumerSecret.message}
              </Body>
            )}
          </Flex>

          <Flex column gap="8px">
            <Label htmlFor="magento2-access-token-input">Access Token</Label>
            <Input
              placeholder="Magento 2 Access Token"
              {...register('accessToken', {
                required: 'Magento 2 Access Token is required',
              })}
            />
            {errors.accessToken && (
              <Body color="error500" variant="Body/Body Small">
                {errors.accessToken.message}
              </Body>
            )}
          </Flex>

          <Flex column gap="8px">
            <Label htmlFor="magento2-access-token-secret-input">Access Token Secret</Label>
            <Input
              placeholder="Magento 2 Access Token Secret"
              {...register('accessTokenSecret', {
                required: 'Magento 2 Access Token Secret is required',
              })}
            />
            {errors.accessTokenSecret && (
              <Body color="error500" variant="Body/Body Small">
                {errors.accessTokenSecret.message}
              </Body>
            )}
          </Flex>

          <Flex column gap="8px">
            <Label htmlFor="magento2-store-view-input">Store View</Label>
            <Input placeholder="Optional - Magento 2 Store View" {...register('storeView')} />
            {errors.storeView && (
              <Body color="error500" variant="Body/Body Small">
                {errors.storeView.message}
              </Body>
            )}
          </Flex>

          <Flex.Item alignSelfEnd>
            <PrimaryButton size="small" type="submit" disabled={pending}>
              Connect
            </PrimaryButton>
          </Flex.Item>
        </Flex>
      </form>

      {error && <Body color="error500">{error}</Body>}
    </GetStartedCard>
  );
};

export default ConnectMagento2Card;
