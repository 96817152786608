import theme from 'storybook/stories/theme';

export const REACT_SELECT_CUSTOM_STYLES = {
  control: (provided: any) => ({
    ...provided,
    background: theme.color.gray100,
    border: `1px solid ${theme.color.gray200}`,
    borderRadius: '8px',
    boxShadow: 'none',
    ':hover': {
      borderColor: theme.color.gray500,
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontFamily: theme.font.primary,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: theme.color.gray900,
    cursor: 'grab',
    backgroundColor: state.isFocused ? theme.color.gray100 : theme.color.white,
    ':active': {
      borderColor: theme.color.gray100,
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: theme.font.primary,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: theme.color.gray600,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: theme.font.primary,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: theme.color.gray900,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: theme.color.gray600,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
};

export const PRODUCT_FIELD_OPTIONS = [
  {
    label: 'Seller Reference',
    value: 'sellerReference',
  },
  {
    label: 'Description',
    value: 'description',
  },
  {
    label: 'Images',
    value: 'images',
  },
  {
    label: 'Tags',
    value: 'tags',
  },
  {
    label: 'Product Title',
    value: 'title',
  },
  {
    label: 'Brand',
    value: 'brand',
  },
  {
    label: 'Google Product Category',
    value: 'googleProductCategory',
  },
  {
    label: 'Variant Title',
    value: 'variant.title',
  },
  {
    label: 'Retail Price',
    value: 'variant.retailPrice',
  },
  {
    label: 'Inventory',
    value: 'variant.inventoryAmount',
  },
  {
    label: 'Skip Count',
    value: 'variant.skipCount',
  },
  {
    label: 'Weight',
    value: 'variant.weight',
  },
  {
    label: 'Weight Units',
    value: 'variant.weightUnits',
  },
  {
    label: 'Dimension Length',
    value: 'variant.dimensions.length',
  },
  {
    label: 'Dimension Width',
    value: 'variant.dimensions.width',
  },
  {
    label: 'Dimension Height',
    value: 'variant.dimensions.height',
  },
  {
    label: 'Dimension Units',
    value: 'variant.dimensions.units',
  },
  {
    label: 'SKU',
    value: 'variant.sku',
  },
  {
    label: 'Barcode',
    value: 'variant.barcode',
  },
  {
    label: 'Barcode Type',
    value: 'variant.barcodeType',
  },
];

export enum ValidationTypes {
  Type = 'type',
  Required = 'required',
  Min = 'min',
  Max = 'max',
  Equals = 'equals',
  NotEquals = 'notEquals',
  OneOf = 'oneOf',
  Unique = 'unique',
  CodeEquals = 'codeEquals',
  NameEquals = 'nameEquals',
  CodeOneOf = 'codeOneOf',
  NameOneOf = 'nameOneOf',
}

export type Option = { label: string; value: string };
