import Grid from '@react-css/grid';
import PrivateRoute from 'containers/App/PrivateRoute';
import ProtectedRoute from 'containers/App/ProtectedRoute';
import CompanyFlagsSettings from 'containers/SettingsPage/CompanyTab/CompanyFlagsSettings';
import CompanyLocationSettings from 'containers/SettingsPage/CompanyTab/CompanyLocationsSettings';
import CompanyProfileSettings from 'containers/SettingsPage/CompanyTab/CompanyProfileSettings';
import TeamMembersSettings from 'containers/SettingsPage/CompanyTab/TeamMembersSettings';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { useAppSelector } from 'store';
import { selectIsSuperUser } from 'store/selectors/me/user';
import Card from 'storybook/stories/cells/Card';
import Menu from 'storybook/stories/cells/Menu';
import styled from 'styled-components';

const MenuWrapper = styled(Card)`
  padding: 24px;
  position: sticky;
  top: 24px;
`;

enum SectionSegments {
  Profile = 'profile',
  Locations = 'locations',
  TeamMembers = 'team-members',
  Flags = 'flags',
}

const CompanySettings = () => {
  const { section } = useParams<{ section?: SectionSegments }>();
  const isSuperUser = useAppSelector(selectIsSuperUser);

  const selectedSection =
    section && Object.values(SectionSegments).includes(section) ? section : SectionSegments.Profile;

  return (
    <Grid columns="240px 1fr" gap="24px">
      <Grid.Item>
        <MenuWrapper>
          <Menu>
            <Menu.Link
              $iconName="apartment"
              $isActive={selectedSection === SectionSegments.Profile}
              to="/settings/company/profile"
            >
              Profile
            </Menu.Link>

            <Menu.Link
              $iconName="pin_drop"
              $isActive={selectedSection === SectionSegments.Locations}
              to="/settings/company/locations"
            >
              Locations
            </Menu.Link>

            <Menu.Link
              $iconName="group"
              $isActive={selectedSection === SectionSegments.TeamMembers}
              to="/settings/company/team-members"
            >
              Team Members
            </Menu.Link>

            {isSuperUser && (
              <Menu.Link
                $iconName="admin_panel_settings"
                $isActive={selectedSection === SectionSegments.Flags}
                to="/settings/company/flags"
              >
                Flags
              </Menu.Link>
            )}
          </Menu>
        </MenuWrapper>
      </Grid.Item>

      <Card>
        <Switch>
          <PrivateRoute path="/settings/company/profile" component={CompanyProfileSettings} />
          <PrivateRoute path="/settings/company/locations" component={CompanyLocationSettings} />
          <PrivateRoute path="/settings/company/team-members" component={TeamMembersSettings} />

          <ProtectedRoute
            isAllowed={isSuperUser}
            redirectTo="/settings/company/profile"
            path="/settings/company/flags"
            component={CompanyFlagsSettings}
          />

          <Route path="*">
            <Redirect to="/settings/company/profile" />
          </Route>
        </Switch>
      </Card>
    </Grid>
  );
};

export default CompanySettings;
