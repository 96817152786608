import { useDispatch } from 'react-redux';
import { addUserToCompany } from 'store/slices/me';
import { inviteUser, type InviteUserParams } from 'utils/api/signup';
import { useSafeState } from 'utils/hooks';

export const CONVICTIONAL_TEST_BUYER_EMAIL = 'testing+buyer@convictional.com';

const useInviteUser = () => {
  const [pending, setPending] = useSafeState<boolean>(false);
  const [error, setError] = useSafeState<string | Error>('');
  const [alert, setAlert] = useSafeState<null | { type: string; message: string }>(null);
  const dispatch = useDispatch();

  const inviteUserAction = async (userData: InviteUserParams['user']) => {
    const userDataCopy = { ...userData };
    userDataCopy.email = userDataCopy.email.toLowerCase().trim();
    setError('');
    setPending(true);
    try {
      await inviteUser({ user: userDataCopy });
      dispatch(addUserToCompany(userDataCopy));
      setAlert({ type: 'success', message: `An invite has been sent to ${userDataCopy.email}` });
    } catch (err) {
      const errorMessage = error instanceof Error ? error.message : error;
      console.error('Unable to invite user', errorMessage);
      setError(errorMessage);
      setAlert({ type: 'danger', message: errorMessage });
    }
    setPending(false);
  };

  return {
    inviteUserPending: pending,
    inviteUserError: error,
    inviteUser: inviteUserAction,
    inviteUserAlert: alert,
    closeInviteUserAlert: () => setAlert(null),
  };
};

export default useInviteUser;
