import { isEmpty, omitBy } from 'lodash';
import qs from 'qs';
import type { BaseSearchParams } from 'types/general';

const getUpdatedUrl = <Type extends BaseSearchParams>(
  pathname: string,
  currentParams?: Type,
  newParams?: Type
) => {
  const params = omitBy({ ...currentParams, ...newParams }, isEmpty);

  const isFirstPage = newParams?.page === '1';
  const hasFiltered = newParams?.query || newParams?.filters || newParams?.createdAt;

  // We want to remove the page param from the resultant query if the user
  // has paginated back to the first page of results or if the user is
  // performing an action that should always return the first page of
  // results, such as submitting the query or filters forms.
  if (isFirstPage || hasFiltered) {
    delete params.page;
  }

  // Downcase any new queries coming in to normalize cache
  if (newParams?.query) {
    params.query = newParams.query.toLowerCase();
  }

  return isEmpty(params) ? pathname : [pathname, qs.stringify(params)].join('?');
};

export default getUpdatedUrl;
