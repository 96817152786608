import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty, omitBy } from 'lodash';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import Spinner from 'components/Common/Spinner';
import DashboardPage from 'components/Dashboard/DashboardPage';
import ActionsDropdown from 'containers/Products/BuyerProductsPage/ActionsDropdown';
import EmptyBuyerProductsPage from 'containers/Products/BuyerProductsPage/EmptyBuyerProductsPage';
import type { ProductFiltersFormInputs } from 'containers/Products/BuyerProductsPage/FiltersDropdown';
import FiltersDropdown from 'containers/Products/BuyerProductsPage/FiltersDropdown';
import BuyerProductValidation from 'containers/Products/BuyerProductValidation';
import useAlertQueue from 'hooks/useAlertQueue';
import { PageSelectedState, useBulkSelect } from 'hooks/useBulkSelect';
import {
  selectIsProductPlatformSelectEnabled,
  selectIsProductPlatformSyncEnabled,
} from 'store/selectors/me/company';
import Page from 'storybook/stories/cells/Page';
import type { ProductSearchFormInputs } from 'storybook/stories/organisms/SearchResults/BuyerProductSearchResults';
import BuyerProductSearchResults from 'storybook/stories/organisms/SearchResults/BuyerProductSearchResults';
import type { ErrorMessage } from 'types/api';
import type { LegacyBuyerProduct } from 'types/models/legacy-buyer-product';
import type { BuyerProductSearchAggregationData } from 'types/models/search';
import {
  deselectProduct,
  getProductSearch,
  getProductSearchAggregations,
  selectProduct,
  type ProductSearchParams,
} from 'utils/api/products';
import getUpdatedUrl from 'utils/searchResults';

interface PageWrapperProps {
  children: React.ReactNode;
  isProductsEmpty: boolean;
}

const PageWrapper = ({ children, isProductsEmpty }: PageWrapperProps) => (
  <DashboardPage>
    <Page>
      <Page.Head title="Products">{!isProductsEmpty && <BuyerProductValidation />}</Page.Head>
      <Page.Body>{children}</Page.Body>
    </Page>
  </DashboardPage>
);

const BuyerProductsPage = () => {
  // State

  const { search, pathname } = useLocation();
  const history = useHistory();
  const isPlatformSyncEnabled = useSelector(selectIsProductPlatformSyncEnabled);
  const isPlatformSelectEnabled = useSelector(selectIsProductPlatformSelectEnabled);
  const [isAggregationQueryEnabled, setIsAggregationQueryEnabled] = useState(false);

  const searchParams = qs.parse(search, { ignoreQueryPrefix: true }) as ProductSearchParams;

  // Alert Handling

  const { addErrorAlert } = useAlertQueue();
  const { addSuccessAlert } = useAlertQueue();

  // Queries

  const fetchingSearchResults = useQuery({
    queryKey: ['getProductSearch', 'buyer', searchParams] as const,
    queryFn: ({ queryKey }) => getProductSearch(queryKey[2]),
    onError: (error) => {
      addErrorAlert('Something went wrong', 'Unable to fetch product search results.');
      console.error('Unable to fetch buyer product search results', error);
    },
  });

  const fetchingSearchResultAggregations = useQuery({
    queryKey: ['getProductSearchAggregations', 'buyer', searchParams] as const,
    queryFn: ({ queryKey }) => getProductSearchAggregations(queryKey[2]),
    enabled: isAggregationQueryEnabled,
    onError: (error: ErrorMessage) => {
      addErrorAlert('Something went wrong', 'Unable to fetch product search filters');
      console.error('Unable to fetch buyer product search filters', error);
    },
  });

  // Mutations

  type SelectingProductMutationArgs = {
    id: string;
  };

  const selectingProduct = useMutation({
    mutationFn: ({ id }: SelectingProductMutationArgs) => selectProduct(id),
    onError: (error, { id }) => {
      addErrorAlert('Something went wrong', 'Unable to select product');
      console.error(`Unable to select product ${id}`, error);
    },
  });

  type DeselectingProductMutationArgs = {
    id: string;
  };

  const deselectingProduct = useMutation({
    mutationFn: ({ id }: DeselectingProductMutationArgs) => deselectProduct(id),
    onError: (error, { id }) => {
      addErrorAlert('Something went wrong', 'Unable to deselect product');
      console.error(`Unable to deselect product ${id}`, error);
    },
  });

  // Helpers

  const results = fetchingSearchResults.data;
  const currentPage = parseInt(searchParams.page || '1', 10);

  const aggregations = fetchingSearchResultAggregations.data?.data?.aggregations;

  const products = useMemo(
    () => results?.data?.products || [],
    [results?.data?.products]
  ) as LegacyBuyerProduct[];

  const productIdsBySelectedState = useMemo(
    () =>
      products.reduce(
        (accumulator, product) => {
          accumulator[product.id] = product.selected;
          return accumulator;
        },
        {} as Record<string, boolean>
      ),
    [products]
  );

  const selectableProducts = useMemo(
    () => products.filter((product) => product.selectedAvailable),
    [products]
  );

  const isProductsEmpty = products.length === 0 && isEmpty(searchParams);

  const {
    pageSelectedState,
    selectedIds,
    select,
    deselect,
    selectAll,
    deselectAll,
    isSelected,
    isAnySelected,
    isSelectable,
  } = useBulkSelect(selectableProducts.length);

  // Side Effects

  useEffect(() => {
    deselectAll();
  }, [fetchingSearchResults.isFetching, deselectAll]);

  // Event Handlers

  const onSearchFormSubmit: SubmitHandler<ProductSearchFormInputs> = (values) => {
    history.push(getUpdatedUrl(pathname, searchParams, values));
  };

  const onFiltersFormSubmit: SubmitHandler<ProductFiltersFormInputs> = (values) => {
    history.push(getUpdatedUrl(pathname, searchParams, { filters: omitBy(values, isEmpty) }));
  };

  const onFiltersFormReset = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { filters: undefined }));
  };

  const onPreviousClick = () => {
    // When navigating back to the first page, we want to omit the page param to help
    // with React Query caching.
    const page = currentPage === 2 ? '' : `${currentPage - 1}`;
    history.push(getUpdatedUrl(pathname, searchParams, { page }));
  };

  const onNextClick = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: `${currentPage + 1}` }));
  };

  const onPageClick = (pageNumber: number) => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: pageNumber.toString() }));
  };

  const onSearchResultClick = (productId: string) => {
    history.push(`/products/${productId}`, { backUrlQueryParams: qs.stringify(searchParams) });
  };

  const onSelectPageChange = () => {
    if (pageSelectedState === PageSelectedState.All) {
      deselectAll();
    } else {
      selectAll(selectableProducts.map(({ id }) => id));
    }
  };

  const onSelectRowChange = (id: string) => {
    if (isSelected(id)) {
      deselect(id);
    } else {
      select(id);
    }
  };

  const onSelectProductsClick = async () => {
    const selectableProductIds = selectedIds.filter((id) => !productIdsBySelectedState[id]);
    await Promise.all(selectableProductIds.map((id) => selectingProduct.mutateAsync({ id })));
    fetchingSearchResults.refetch();
    addSuccessAlert('Products selected', 'Products should sync shortly');
  };

  const onDeselectProductsClick = async () => {
    const deselectableProductIds = selectedIds.filter((id) => productIdsBySelectedState[id]);
    await Promise.all(deselectableProductIds.map((id) => deselectingProduct.mutateAsync({ id })));
    fetchingSearchResults.refetch();
    addSuccessAlert('Products deselected', 'Products will no longer be available for sale');
  };

  const onSelectProductChange = async (id: string) => {
    await selectingProduct.mutateAsync({ id });
    fetchingSearchResults.refetch();
    addSuccessAlert('Product selected', 'Product should sync shortly');
  };

  const onDeselectProductChange = async (id: string) => {
    await deselectingProduct.mutateAsync({ id });
    fetchingSearchResults.refetch();
    addSuccessAlert('Product deselected', 'Product will no longer be available for sale');
  };

  // Render

  if (!fetchingSearchResults.isSuccess)
    return (
      <PageWrapper isProductsEmpty={isProductsEmpty}>
        <Spinner />
      </PageWrapper>
    );

  if (isProductsEmpty)
    return (
      <PageWrapper isProductsEmpty={isProductsEmpty}>
        <EmptyBuyerProductsPage />
      </PageWrapper>
    );

  const headerActionsUi = isAnySelected && (
    <ActionsDropdown
      onSelectProductsClick={onSelectProductsClick}
      onDeselectProductsClick={onDeselectProductsClick}
    />
  );

  const headerFiltersUi = (
    <FiltersDropdown
      aggregations={aggregations as BuyerProductSearchAggregationData['aggregations']}
      onFiltersFormSubmit={onFiltersFormSubmit}
      onFiltersFormReset={onFiltersFormReset}
      searchParams={searchParams}
      onOpen={() => setIsAggregationQueryEnabled(true)}
      isLoading={fetchingSearchResultAggregations.isLoading}
    />
  );

  return (
    <PageWrapper isProductsEmpty={isProductsEmpty}>
      <BuyerProductSearchResults
        results={results}
        onSearchFormSubmit={onSearchFormSubmit}
        defaultSearchFormInputValue={searchParams.query}
        currentPage={currentPage}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
        onPageClick={onPageClick}
        onSearchResultClick={onSearchResultClick}
        headerFiltersUi={headerFiltersUi}
        headerActionsUi={headerActionsUi}
        onSelectPageChange={onSelectPageChange}
        onSelectRowChange={onSelectRowChange}
        isSelected={isSelected}
        isSelectable={isSelectable}
        pageSelectedState={pageSelectedState}
        shouldDisplaySyncedStatus={isPlatformSyncEnabled}
        shouldDisplayBulkSelect={isPlatformSelectEnabled}
        shouldDisplaySyncingStatus
        onSelectProductChange={onSelectProductChange}
        onDeselectProductChange={onDeselectProductChange}
      />
    </PageWrapper>
  );
};

export default BuyerProductsPage;
