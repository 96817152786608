import type { UserOnboardingAttributes } from 'types/models/user';
import config from 'utils/config';
import { postJSONOptions } from 'utils/options';
import { postRequest, request } from 'utils/request';

export interface SignupParams {
  email: string;
  onboardingAttributes?: Partial<UserOnboardingAttributes>;
  redirect?: string;
}

export const signup = (params: SignupParams): Promise<{}> => {
  const url = `${config.apiURL}/signup`;
  const options = postJSONOptions(params);
  return request(url, options);
};

export interface InviteUserParams {
  user: {
    firstName: string;
    lastName: string | null;
    email: string;
  };
}

export const inviteUser = (params: InviteUserParams): Promise<{}> =>
  postRequest('/signup/invite', params);
