import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import UpdatedIndicator from 'components/Common/UpdatedIndicator';
import RichTextEditor from 'storybook/stories/organisms/RichTextEditor';

import CardProductValidationAlert from './CardProductValidationAlert';

const Wrapper = styled.div`
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${({ theme, hasErrors }) =>
    hasErrors &&
    css`
      border-color: ${theme.color.warning500};
    `}
`;

const ProductTitleDescription = ({
  product,
  edits,
  onEditProductField,
  titleValidationErrors,
  descriptionValidationErrors,
}) => {
  const productTitle = Object.prototype.hasOwnProperty.call(edits, 'title')
    ? edits.title
    : product.title || '';
  const htmlDescription = Object.prototype.hasOwnProperty.call(edits, 'bodyHtml')
    ? edits.bodyHtml
    : product.bodyHtml || '';

  const [bodyHtml, setBodyHtml] = useState(htmlDescription);

  // Update product.bodyHtml field when user updates bodyHTML state
  // useMemo helps avoid unnecessary re-renders
  const updateBodyHtml = useMemo(() => {
    return (html, onEdit) => {
      if (!html || onEdit === undefined) return;
      onEdit('bodyHtml')(html);
    };
  }, []);

  useEffect(() => {
    if (htmlDescription !== bodyHtml) {
      updateBodyHtml(bodyHtml, onEditProductField);
    }
  }, [bodyHtml, updateBodyHtml, htmlDescription, onEditProductField]);

  const validationErrors = useMemo(() => {
    const titleErrors = titleValidationErrors?.map((err) => ({ ...err, field: 'Title' })) || [];
    const descErrors =
      descriptionValidationErrors?.map((err) => ({ ...err, field: 'Description' })) || [];
    return titleErrors.concat(descErrors);
  }, [titleValidationErrors, descriptionValidationErrors]);

  const onChange =
    (key) =>
    ({ target: { value } }) => {
      onEditProductField(key)(value);
    };

  return (
    <Wrapper
      className="card p-4 overflow-auto"
      id="product-title-description-card"
      hasErrors={validationErrors?.length > 0}
    >
      <div className="form-group">
        <h4 className="mb-3">
          Product Title{' '}
          {Object.prototype.hasOwnProperty.call(edits, 'title') && (
            <UpdatedIndicator className="ml-1" />
          )}
        </h4>
        <input
          className="form-control form-control-sm"
          aria-label="Product Title"
          value={productTitle}
          onChange={onChange('title')}
          name="title"
        />
      </div>
      <div className="form-group mb-0">
        <h4 className="mb-3">
          HTML Description{' '}
          {Object.prototype.hasOwnProperty.call(edits, 'bodyHtml') ? (
            <UpdatedIndicator className="ml-2" />
          ) : null}
        </h4>

        <RichTextEditor value={bodyHtml} setValue={setBodyHtml} />
      </div>
      {validationErrors.length > 0 && <CardProductValidationAlert errors={validationErrors} />}
    </Wrapper>
  );
};

export default ProductTitleDescription;
