import { useMutation, useQuery } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import MetafieldsEditor from 'storybook/stories/organisms/MetafieldsEditor';
import styled from 'styled-components';
import type { Metafields } from 'types/general';
import {
  getBuyerVariantByProductAndVariantId,
  updateVariantMetafields,
} from 'utils/api/buyer/products';

const Wrapper = styled.div`
  margin-top: 24px;
`;

export interface VariantMetafieldsProps {
  productId: string;
  variantId: string;
}

const VariantMetafields = ({ productId, variantId }: VariantMetafieldsProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const fetchingVariant = useQuery({
    queryKey: ['getBuyerVariantByProductAndVariantId', productId, variantId],
    queryFn: () => getBuyerVariantByProductAndVariantId(productId, variantId),
    onError: () => {
      addErrorAlert('Error!', 'Something went wrong. Please try again.');
    },
  });

  const updatingMetafields = useMutation({
    mutationFn: (body: Metafields) => updateVariantMetafields(productId, variantId, body),
    onSuccess: () => {
      addSuccessAlert('Success', 'Your variant has been updated.');
      fetchingVariant.refetch();
    },
    onError: () => {
      addErrorAlert('Error!', 'Something went wrong. Please try again.');
    },
  });

  return (
    <Wrapper>
      <MetafieldsEditor
        metafields={fetchingVariant.data?.data.metafields}
        updateMetafields={updatingMetafields.mutate}
      />
    </Wrapper>
  );
};

export default VariantMetafields;
