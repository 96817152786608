import Flex from '@react-css/flex';
import BuyerBillingSettings from 'containers/PartnerPage/PartnerBillingSettings/BuyerBillingSettings';
import { useState } from 'react';
import { useAppSelector } from 'store';
import { selectIsBuyer, selectIsSeller } from 'store/selectors/me/company';
import Card from 'storybook/stories/cells/Card';
import Heading from 'storybook/stories/molecules/Heading';
import StatusIcon from 'storybook/stories/molecules/Icon/StatusIcon';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import Toggle from 'storybook/stories/molecules/Toggle';
import { InvoicePosterReason, type Partner } from 'types/models/partner';
import type { UpdatePartnerParams } from 'utils/api/partners';
import { friendlyInvoicePosterReason } from 'utils/partners';
import SellerBillingSettings from './SellerBillingSettings';

interface BillingDescriptionProps {
  partner: Partner;
  billing: boolean;
  shouldShowWarning: boolean;
}

const BillingDescription = ({ partner, billing, shouldShowWarning }: BillingDescriptionProps) => {
  if (shouldShowWarning) {
    return (
      <>
        This setting is enabled but cannot be used yet because{' '}
        <strong>{friendlyInvoicePosterReason(partner.billingSettings.invoicePosterReason)}</strong>
      </>
    );
  }

  return (
    <span>
      {billing
        ? 'Stripe invoices will be generated and automatically paid for fulfilled orders.'
        : 'Stripe invoices will not be generated for fulfilled orders. Modern Dropship invoices will still be generated for your records.'}
    </span>
  );
};

export interface PartnerBillingSettingsProps {
  partner: Partner;
  updatePartner: (params: UpdatePartnerParams) => void;
}

const PartnerBillingSettings = ({ partner, updatePartner }: PartnerBillingSettingsProps) => {
  const [billing, setBilling] = useState(partner.billing);
  const isBuyer = useAppSelector(selectIsBuyer);
  const isSeller = useAppSelector(selectIsSeller);

  const isBillingSettingModified = partner.billing !== billing;
  const isBillingSettingAvailable =
    partner.billingSettings.invoicePosterReason === InvoicePosterReason.None;

  const shouldShowWarning = !isBillingSettingModified && billing && !isBillingSettingAvailable;

  const onAutomatedStripeInvoicingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setBilling(checked);
    updatePartner({ billing: checked });
  };

  return (
    <Card>
      <Card.Head>
        <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
          Billing
        </Heading>
      </Card.Head>

      <Card.Body>
        <Flex column gap="24px">
          <Toggle
            name="billing"
            onChange={onAutomatedStripeInvoicingChange}
            checked={billing}
            description={
              <>
                <BillingDescription
                  partner={partner}
                  billing={billing}
                  shouldShowWarning={shouldShowWarning}
                />{' '}
                <SupportLink article="8lwln18tin">Learn more</SupportLink>
              </>
            }
          >
            {shouldShowWarning ? (
              <StatusIcon variant="warning" position="after">
                Automated Stripe Invoicing
              </StatusIcon>
            ) : (
              'Automated Stripe Invoicing'
            )}
          </Toggle>
          {isSeller && <SellerBillingSettings partner={partner} updatePartner={updatePartner} />}
          {isBuyer && <BuyerBillingSettings partner={partner} updatePartner={updatePartner} />}
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default PartnerBillingSettings;
