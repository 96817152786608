import _isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

import Message from 'components/Common/Message';
import pluralize from 'pluralize';

const SelectedVariantValidationAlert = ({ validationErrorMap }) => {
  const validationErrors = useMemo(() => {
    const errors = [];
    if (_isEmpty(validationErrorMap)) return errors;
    Object.keys(validationErrorMap).forEach((field) => {
      validationErrorMap[field].forEach((err) => {
        errors.push(err);
      });
    });
    return errors;
  }, [validationErrorMap]);

  if (validationErrors.length === 0) return null;

  return (
    <Message show kind="warning" className="mt-0">
      <p className="m-0 mb-2">
        <strong>
          This variant has {pluralize('validation error', validationErrors.length, true)}
        </strong>
      </p>
      {validationErrors.map((err) => {
        return (
          <p className="m-0 small" key={err.fieldPath + err.message}>
            <strong>{err.variantFieldPath}</strong> - {err.message}
          </p>
        );
      })}
    </Message>
  );
};

export default SelectedVariantValidationAlert;
