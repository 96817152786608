import { forwardRef } from 'react';
import styled from 'styled-components';

import Icon from 'storybook/stories/molecules/Icon';
import Input, { INPUT_HEIGHT } from 'storybook/stories/molecules/Input';

const ICON_CONTAINER_SIZE = INPUT_HEIGHT;

const StyledInput = styled(Input).attrs(() => ({ type: 'datetime-local' }))`
  padding-right: ${ICON_CONTAINER_SIZE - 6}px;

  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    right: 14px;
  }
`;

type WrapperProps = Pick<DateInputProps, 'disabled' | 'isFullWidth'>;

const Wrapper = styled.div<WrapperProps>`
  display: ${({ isFullWidth }) => (isFullWidth ? 'block' : 'inline-block')};
  position: relative;
  overflow: hidden;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
`;

type DateIconProps = Pick<DateInputProps, 'disabled'>;

const StyledIcon = styled(Icon).attrs<DateIconProps>(({ disabled }) => ({
  color: disabled ? 'bodyTextDisabled' : 'iconDefault',
}))<DateIconProps>`
  pointer-events: none;
  position: absolute;
  inset: 0 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ICON_CONTAINER_SIZE}px;
  height: ${ICON_CONTAINER_SIZE}px;
`;

interface DateInputProps extends React.ComponentProps<typeof Input> {}

/**
 * Extends an `Input` component's styling and wraps it to provide iconography. Props
 * passed to `DateInput` will be curried to the underlying `Input` component.
 */

const DateInput = forwardRef<HTMLInputElement, React.ComponentProps<typeof StyledInput>>(
  ({ disabled, isFullWidth, ...inputProps }: DateInputProps, ref) => (
    <Wrapper isFullWidth={isFullWidth} disabled={disabled}>
      <StyledInput ref={ref} disabled={disabled} isFullWidth={isFullWidth} {...inputProps} />
      <StyledIcon name="calendar_month" disabled={disabled} />
    </Wrapper>
  )
);

export default DateInput;
