import SimpleBar from 'simplebar-react';
import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & #settings-header-dropdown {
    font-size: 1rem;
    font-weight: 500;
  }
`;

export const SettingsFooter = styled.footer`
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0rem 1.5rem;
  border-top: 1px solid #e3ebf6;
`;

const ScrollWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

// Header

interface SettingsPageHeaderProps {
  title: string;
  children?: React.ReactNode;
}

export const SettingsPageHeader = ({ title, children }: SettingsPageHeaderProps) => {
  return (
    <>
      <Header className="d-flex justify-space-between align-items-center">
        <div className="d-none d-md-flex align-items-center">
          <h3 className="mb-0 mr-2">{title}</h3>
        </div>

        {children && children}
      </Header>
      <hr className="mb-4" />
    </>
  );
};

// Main

interface SettingsMainProps {
  children: React.ReactNode;
}

export const SettingsMain = ({ children }: SettingsMainProps) => (
  <ScrollWrapper>
    <SimpleBar style={{ height: '100%' }}>
      <div className="p-4">{children}</div>
    </SimpleBar>
  </ScrollWrapper>
);
