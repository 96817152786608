import { useState } from 'react';
import { Card } from 'react-bootstrap';

import Button from 'components/Common/Button';
import Icon from 'storybook/stories/molecules/Icon';
import { deleteShippingMethod, updateShippingMethod } from 'utils/api/shipping';
import { formatShippingLevel } from 'utils/shipping';

import useAlertQueue from 'hooks/useAlertQueue';
import ShippingMethodForm from './ShippingMethodForm';

const ShippingMethod = ({ shippingMethod, onDeleteSuccess, onUpdateSuccess, isSeller }) => {
  const alertQueue = useAlertQueue();
  const [inEditMode, setInEditMode] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDeleteShippingMethod = () => {
    setIsDeleting(true);
    deleteShippingMethod(shippingMethod.id)
      .then(() => {
        onDeleteSuccess(shippingMethod.id);
      })
      .catch((err) => {
        setIsDeleting(false);
        alertQueue.addErrorAlert('Something went wrong', err.message);
      });
  };

  const onUpdateShippingMethod = (data) => updateShippingMethod(shippingMethod.id, data);

  if (!shippingMethod) {
    return null;
  }

  if (inEditMode) {
    return (
      <Card className="mb-2 p-3">
        <ShippingMethodForm
          shippingMethod={shippingMethod}
          onCancel={() => setInEditMode(false)}
          isSeller={isSeller}
          onSave={onUpdateShippingMethod}
          onSaveSuccess={(response) => {
            onUpdateSuccess(response);
            setInEditMode(false);
          }}
        />
      </Card>
    );
  }

  return (
    <Card className="mb-2 p-3">
      <div className="d-flex justify-content-between">
        <div>
          <p className="font-weight-bold mb-2">{formatShippingLevel(shippingMethod.maxDays)}</p>
          {shippingMethod.description && (
            <p className="text-muted small mb-2">{shippingMethod.description}</p>
          )}
          <div className="d-flex" style={{ flexWrap: 'wrap' }}>
            {shippingMethod.carrierNames?.length === 0 ? (
              <p className="text-muted small mb-0">No selected carriers</p>
            ) : (
              shippingMethod.carrierNames.map((carrier) => (
                <span
                  className="badge badge-light mr-2 mb-2"
                  style={{ fontSize: '0.8rem' }}
                  key={carrier}
                >
                  {carrier}
                </span>
              ))
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            size="sm"
            className="ml-2"
            aria-label="edit shipping method"
            onClick={() => setInEditMode(true)}
          >
            <Icon name="edit" />
          </Button>
          <Button
            size="sm"
            className="ml-2"
            aria-label="delete shipping method"
            disabled={isDeleting}
            onClick={onDeleteShippingMethod}
          >
            <Icon name="delete" className="text-danger" />
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ShippingMethod;
