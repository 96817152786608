import Flex from '@react-css/flex';
import { useMutation, useQuery } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import { useState } from 'react';
import Body from 'storybook/stories/molecules/Body';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Chip from 'storybook/stories/molecules/Chip';
import StatusChip from 'storybook/stories/molecules/Chip/StatusChip';
import Spinner from 'storybook/stories/molecules/Spinner';
import Toggle from 'storybook/stories/molecules/Toggle';
import { WebhookHealth, type Webhook as WebhookType } from 'types/models/webhook';
import { getWebhooks, patchWebhook } from 'utils/api/webhooks';
import type ApiError from 'utils/ApiError';

interface WebhookProps {
  webhook: WebhookType;
  onEditButtonClick: (webhook: WebhookType) => void;
}

const Webhook = ({ webhook, onEditButtonClick }: WebhookProps) => {
  const alertQueue = useAlertQueue();
  const [isActivated, setIsActivated] = useState(webhook.active);

  const patchingWebhook = useMutation({
    mutationKey: ['patchWebhook', webhook.id],
    mutationFn: (active: boolean) => patchWebhook(webhook.id, { active }),
    onSuccess: () => {
      alertQueue.addSuccessAlert(
        'Webhook updated successfully',
        `Webhook for ${webhook.targetUrl} has been updated`
      );
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Something went wrong', error.message);
    },
  });

  return (
    <Flex column gap="8px">
      <Flex justifySpaceBetween alignItemsCenter>
        <Body variant="Body/Regular Bold">{webhook.targetUrl}</Body>

        <TertiaryButton
          $iconName="edit"
          size="small"
          style={{ paddingRight: 0 }}
          onClick={() => onEditButtonClick(webhook)}
        >
          Edit
        </TertiaryButton>
      </Flex>

      <Flex alignItemsCenter gap="8px">
        <Flex.Item grow={1}>
          <Toggle
            checked={isActivated}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIsActivated(event.target.checked);
              patchingWebhook.mutate(event.target.checked);
            }}
          >
            {isActivated ? 'Active' : 'Inactive'}
          </Toggle>
        </Flex.Item>

        {webhook.topics.map((topic) => (
          <Chip key={topic} size="small">
            {topic}
          </Chip>
        ))}

        <StatusChip
          size="small"
          kind={webhook.health === WebhookHealth.Healthy ? 'success' : 'warning'}
        >
          {webhook.health === WebhookHealth.Healthy ? 'Healthy' : 'Failed'}
        </StatusChip>
      </Flex>
    </Flex>
  );
};

interface ListWebhooksProps {
  setWebhookToEdit: (webhook: WebhookType) => void;
}

const ListWebhooks = ({ setWebhookToEdit }: ListWebhooksProps) => {
  const alertQueue = useAlertQueue();

  const fetchingWebhooks = useQuery({
    queryKey: ['getWebhooks'],
    queryFn: () => getWebhooks(),
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Something went wrong', error.message);
    },
  });

  const webhooks = fetchingWebhooks.data?.data ?? [];

  if (fetchingWebhooks.isLoading) {
    return (
      <Flex justifyCenter>
        <Spinner />
      </Flex>
    );
  }

  if (webhooks.length === 0) {
    <Body>No webhooks found.</Body>;
  }

  return (
    <Flex column gap="24px">
      {webhooks.map((webhook) => (
        <Webhook key={webhook.id} webhook={webhook} onEditButtonClick={setWebhookToEdit} />
      ))}
    </Flex>
  );
};

export default ListWebhooks;
