import Flex from '@react-css/flex';
import Dropdown from 'storybook/stories/cells/Dropdown';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import { EditEntryOptions } from './context';

interface EditPricingHeaderDropDownMenuProps {
  onClickEditProperty: (property: EditEntryOptions) => void;
}

/**
 * Composes a button for use in the header of the PriceListEntries component.
 */
const EditPricingHeaderDropDownMenu = ({
  onClickEditProperty,
}: EditPricingHeaderDropDownMenuProps) => {
  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        <TertiaryButton $iconName="edit_note" kind="neutral">
          Edit Pricing
        </TertiaryButton>
      </Dropdown.Trigger>
      <Dropdown.Content>
        {({ close }) => {
          return (
            <Flex column gap="32px">
              <Dropdown.Header iconName="edit_note" heading="Edit Pricing" />

              <Flex column gap="16px">
                <Icon
                  as="button"
                  name="sell"
                  onClick={() => {
                    onClickEditProperty(EditEntryOptions.YOUR_RETAIL_PRICE);
                    close();
                  }}
                >
                  Edit &quot;Your Retail Price&quot;
                </Icon>

                <Icon
                  as="button"
                  name="percent"
                  onClick={() => {
                    onClickEditProperty(EditEntryOptions.MARGIN);
                    close();
                  }}
                >
                  Edit Margin
                </Icon>

                <Icon
                  as="button"
                  name="percent"
                  onClick={() => {
                    onClickEditProperty(EditEntryOptions.MARGIN_BULK);
                    close();
                  }}
                >
                  Edit Margins in Bulk
                </Icon>

                <Icon
                  as="button"
                  name="price_change"
                  onClick={() => {
                    onClickEditProperty(EditEntryOptions.YOU_EARN);
                    close();
                  }}
                >
                  Edit &quot;You Earn&quot;
                </Icon>
              </Flex>
            </Flex>
          );
        }}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default EditPricingHeaderDropDownMenu;
