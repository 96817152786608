import { formatHoursToTimeString } from 'utils/time';

const OrderShippingInfo = ({ order }) => {
  if (!order) return null;

  const { shippingAddress, shipped, shipTime } = order;

  return (
    <div className="card p-4 overflow-auto">
      <h4 className="mb-3">Shipping Address</h4>

      {shippingAddress.name && <p className="mb-0">{shippingAddress.name}</p>}
      {shippingAddress.company && <p className="mb-0">{shippingAddress.company}</p>}
      {shippingAddress.addressOne && <p className="mb-0">{shippingAddress.addressOne}</p>}
      {shippingAddress.addressTwo && <p className="mb-0">{shippingAddress.addressTwo}</p>}
      <p className="mb-0">
        {shippingAddress.city}, {shippingAddress.state}{' '}
      </p>
      <p className="mb-0">{shippingAddress.country}</p>
      <p className="mb-0">{shippingAddress.zip}</p>

      {order?.customerEmail && (
        <>
          <hr />

          <h4 className="mb-3">Email Address</h4>

          <p className="mb-0">{order.customerEmail}</p>
        </>
      )}

      {shippingAddress?.phoneNumber && (
        <>
          <hr />

          <h4 className="mb-3">Phone Number</h4>

          <p className="mb-0">{shippingAddress.phoneNumber}</p>
        </>
      )}

      <hr />

      <h4 className="mb-3">Ship Time</h4>
      <p className="mb-2">{shipped ? formatHoursToTimeString(shipTime) || '-' : 'Not Shipped'}</p>
    </div>
  );
};

export default OrderShippingInfo;
