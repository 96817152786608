import useAlertQueue from 'hooks/useAlertQueue';
import useOnboardingAttributeParams from 'hooks/useOnboardingAttributeParams';
import { useEffect, useRef } from 'react';
import { useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';

const useShopifyAppInstallAlert = () => {
  const user = useAppSelector(selectUser);
  const { addSuccessAlert } = useAlertQueue();
  const { source, temporaryTokenId } = useOnboardingAttributeParams();
  const alerted = useRef<boolean>(false);

  useEffect(() => {
    if (alerted.current) {
      return;
    }
    // If the user doesn't exist, just confirm successful installation
    if (!user && source === 'shopify' && temporaryTokenId) {
      addSuccessAlert(
        'Installation Complete!',
        'Create your Modern Dropship account to get started'
      );
    }
    alerted.current = true;
  }, [addSuccessAlert, source, temporaryTokenId, user]);
};

export default useShopifyAppInstallAlert;
