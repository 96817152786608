import styled from 'styled-components';

const PlatformBadge = styled.span`
  color: #fff;
  background-color: #6e84a3;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  background-color: #39afd1;
  & i {
    font-size: 1.2rem;
    margin-left: 0.4rem;
  }
`;

export default PlatformBadge;
