import { useMutation } from '@tanstack/react-query';
import download from 'downloadjs';
import useAlertQueue from 'hooks/useAlertQueue';
import Modal from 'storybook/stories/cells/Modal';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import type ApiError from 'utils/ApiError';
import { getDocument } from 'utils/api/documents';

const DefaultNextSteps = () => {
  return (
    <ul className="mb-4">
      <li>
        <strong>Step 1:</strong> Make any necessary corrections identified above
      </li>
      <li>
        <strong>Step 2:</strong> Upload the CSV file again
      </li>
    </ul>
  );
};

const DownloadNextSteps = () => {
  return (
    <ul className="mb-4">
      <li>
        <strong>Step 1:</strong> Download the updated CSV file
      </li>
      <li>
        <strong>Step 2:</strong> Make any necessary corrections identified in the &quot;Error&quot;
        column
      </li>
      <li>
        <strong>Step 3:</strong> Upload the CSV file again
      </li>
    </ul>
  );
};

interface UploadProductCSVModalProps {
  showModal: boolean;
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  error: Record<string, string[]>;
}

const UploadProductCSVModal = ({
  showModal,
  setShowCreateModal,
  error,
}: UploadProductCSVModalProps) => {
  const { addErrorAlert } = useAlertQueue();

  const downloadErrorFile = useMutation({
    mutationFn: () => getDocument(error.errorDocumentId[0]),
    onSuccess: (blob) => {
      download(blob);
    },
    onError: (err: ApiError) => {
      addErrorAlert('Something went wrong', err.message);
      console.error('Unable to download CSV file.', err);
    },
  });

  const showDownloadButton = error.errorDocumentId?.length > 0;

  return (
    <Modal.Root
      show={showModal}
      aria-labelledby="upload-products-error-modal-title"
      centered
      onHide={() => {
        setShowCreateModal(false);
      }}
      animation={false}
      id="upload-products-error-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Please Update the CSV File</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="mb-3">Your file has been uploaded but we found some errors.</p>
        <p className="mb-3">
          {error.general?.map((message) => (
            <Icon name="error" color="error500" key={message}>
              {message}
            </Icon>
          ))}
        </p>
        <p className="mb-3">Next steps:</p>
        <div className="mb-3">
          {showDownloadButton ? <DownloadNextSteps /> : <DefaultNextSteps />}
        </div>
      </Modal.Body>

      {showDownloadButton ? (
        <Modal.Footer>
          <SecondaryButton
            size="small"
            type="submit"
            onClick={() => downloadErrorFile.mutate()}
            id="download-csv-btn"
          >
            Download Updated CSV File
          </SecondaryButton>
        </Modal.Footer>
      ) : null}
    </Modal.Root>
  );
};

export default UploadProductCSVModal;
