import Flex from '@react-css/flex';
import styled, { keyframes } from 'styled-components';

import PageHeader from 'components/Common/PageHeader';
import breakpoints from 'utils/breakpoints';

const loadingTextKeyframe = keyframes`
  0% {
    background: var(--light);
  }
  50% {
    background: #e2ebf6;
  }
  100% {
    background: var(--light);
  }
`;

const LoadingText = styled.div`
  height: 0.9375rem;
  margin-bottom: 0.5rem;
  background: var(--light);
  border-radius: 4px;
  animation: ${loadingTextKeyframe} 2s linear infinite;
`;

const LoadHeading = styled(LoadingText)`
  height: 1.6rem;
  width: 24rem;
`;

const LoadImage = styled(LoadingText)`
  height: 135px;
  width: 135px;
  border-radius: 5px;
`;

const LoadCardTitle = styled(LoadingText)`
  height: 1rem;
  width: 10rem;
`;

const LoadCardText = styled(LoadingText)`
  height: 0.8rem;
`;

const FlexContainer = styled(Flex)`
  gap: 32px;
  ${breakpoints({
    values: {
      xs: {
        'flex-direction': 'column',
      },
      sm: {
        'flex-direction': 'column',
      },
      md: {
        'flex-direction': 'column',
      },
      lg: {
        'flex-direction': 'row',
      },
      xl: {
        'flex-direction': 'row',
      },
      xxl: {
        'flex-direction': 'row',
      },
    },
  })}
`;

const LoadingProductPage = () => {
  return (
    <>
      <PageHeader>
        <LoadHeading className="mb-3" />
      </PageHeader>
      <FlexContainer>
        <Flex.Item grow={3} shrink={2}>
          <div className="card p-4">
            <LoadCardTitle className="mb-4" />
            <div className="mb-3">
              <LoadCardText />
              <LoadCardText />
            </div>

            <div className="mb-3">
              <LoadCardText />
              <LoadCardText />
            </div>

            <div>
              <LoadCardText />
              <LoadCardText />
            </div>
          </div>

          <div className="card p-4">
            <LoadCardTitle className="mb-4" />
            <div>
              <LoadCardText />
              <LoadCardText />
              <LoadCardText />
              <LoadCardText />
            </div>
          </div>

          <div className="card p-4">
            <LoadCardTitle className="mb-4" />
            <Flex gap="36px">
              <Flex.Item>
                <LoadImage />
              </Flex.Item>
              <Flex.Item>
                <LoadImage />
              </Flex.Item>
              <Flex.Item>
                <LoadImage />
              </Flex.Item>
            </Flex>
          </div>

          <div className="card p-4">
            <LoadCardTitle className="mb-4" />
            <div className="mb-3">
              <LoadCardText />
              <LoadCardText />
            </div>

            <div className="mb-3">
              <LoadCardText />
              <LoadCardText />
            </div>
          </div>
        </Flex.Item>
        <Flex.Item grow={2} shrink={1}>
          <div className="card p-4">
            <LoadCardText className="mb-0" />
            <hr className="mb-4 mt-4" />
            <LoadCardText className="mb-3" />
            <LoadCardText className="mb-0" />
            <hr className="mb-4 mt-4" />
            <LoadCardText className="mb-0" />
          </div>

          <div className="card p-4">
            <LoadCardTitle className="mb-4" />
            <LoadCardText />
          </div>
          <div className="card p-4">
            <LoadCardTitle className="mb-4" />
            <LoadCardText />
          </div>
          <div className="card p-4">
            <LoadCardTitle className="mb-4" />
            <LoadCardText />
          </div>
        </Flex.Item>
      </FlexContainer>
    </>
  );
};

export default LoadingProductPage;
