import Flex from '@react-css/flex';
import RowEditPricingButton from 'storybook/stories/cells/SearchResult/PriceListEntrySearchResult/RowEditPricingButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Icon from 'storybook/stories/molecules/Icon';

interface RowFooterProps {
  isEditing: boolean;
  isValid: boolean;
  onCancelEdit: () => void;
  setEditingYourRetailPrice: () => void;
  setEditingMargin: () => void;
  setEditingYouEarn: () => void;
  onRemoveFromPriceListClick: () => void;
}

const RowEnd = ({
  isEditing,
  isValid,
  onCancelEdit,
  setEditingYourRetailPrice,
  setEditingMargin,
  setEditingYouEarn,
  onRemoveFromPriceListClick,
}: RowFooterProps) => {
  return (
    <Flex justifyEnd alignContentCenter>
      {isEditing ? (
        <Flex gap="8px" alignItemsCenter onClick={(e) => e.stopPropagation()}>
          <SecondaryButton size="small" type="submit" data-testid="save-button" disabled={!isValid}>
            Save
          </SecondaryButton>
          <Icon as="button" size="24px" name="close" color="gray500" onClick={onCancelEdit} />
        </Flex>
      ) : (
        <RowEditPricingButton
          onEditYourRetailPriceClick={setEditingYourRetailPrice}
          onEditMarginClick={setEditingMargin}
          onEditYouEarnClick={setEditingYouEarn}
          onRemoveFromPriceListClick={onRemoveFromPriceListClick}
        />
      )}
    </Flex>
  );
};

export default RowEnd;
