import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchGetStarted } from 'utils/api/auth';
import { useSafeState } from 'utils/hooks';
import {
  updateBigCommerceAuth,
  updateMagento2Auth,
  updateOnboardingStatus,
  updateSFTPCreds,
  updateWooCommerceAuth,
} from './company';
import { updateUserCompany } from './settings/company';
import { createSFTPCreds } from './sftpUser';
import { skipStripeSetup } from './stripe';

export const COMPLETED = 'completed';
export const SKIPPED = 'skipped';

export const isDone = (state) => state === COMPLETED || state === SKIPPED;

export const fetchGettingStarted = createAsyncThunk('getStarted/fetch', () => fetchGetStarted());

const initialStepState = {
  required: false,
  status: '',
};

// matches API response (mostly for documentation/initial render, API response overwrites entire slice of state)
const initialState = {
  connectShopify: { ...initialStepState },
  connectWooCommerce: { ...initialStepState },
  connectBigCommerce: { ...initialStepState },
  connectMagento2: { ...initialStepState },
  connectStripe: { ...initialStepState },
  connectEasyPost: { ...initialStepState },
  addPaymentMethod: { ...initialStepState },
  addBillingAddress: { ...initialStepState },
  addShippingAddress: { ...initialStepState },
  addReturnsAddress: { ...initialStepState },
  shippingMethods: { ...initialStepState },
  activeProducts: { ...initialStepState },
  createdPriceList: { ...initialStepState },
  createSFTPCreds: { ...initialStepState },
  createExternalSFTPCredentials: { ...initialStepState },
  uploadSFTPFile: { ...initialStepState },
  createCSVMappings: { ...initialStepState },
  invitePartners: { ...initialStepState },
  inviteTeam: { ...initialStepState },
  ediSchemaSetup: { ...initialStepState },
  testOrder: { ...initialStepState },
  actionsAssigned: { ...initialStepState },
  configureEDISettings: { ...initialStepState },
  viewEDISpecifications: { ...initialStepState },
  requiredCount: 0,
  doneCount: 0,
};

const getStartedSlice = createSlice({
  name: 'getStarted',
  initialState,
  reducers: {
    billingAddressAdded: (draft) => {
      draft.addBillingAddress.status = COMPLETED;
    },
    returnsAddressAdded: (draft) => {
      draft.addReturnsAddress.status = COMPLETED;
    },
    shippingAddressAdded: (draft) => {
      draft.addShippingAddress.status = COMPLETED;
    },
    shippingMethodAdded: (draft) => {
      draft.shippingMethods.status = COMPLETED;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(skipStripeSetup.fulfilled, (draft) => {
        draft.connectStripe.status = SKIPPED;
        draft.doneCount += 1;
      })
      .addCase(updateWooCommerceAuth.fulfilled, (draft) => {
        draft.connectWooCommerce.status = COMPLETED;
        draft.doneCount += 1;
      })
      .addCase(updateMagento2Auth.fulfilled, (draft) => {
        draft.connectMagento2.status = COMPLETED;
        draft.doneCount += 1;
      })
      .addCase(updateBigCommerceAuth.fulfilled, (draft) => {
        draft.connectBigCommerce.status = COMPLETED;
        draft.doneCount += 1;
      })
      .addCase(updateSFTPCreds.fulfilled, (draft) => {
        draft.createExternalSFTPCredentials.status = COMPLETED;
        draft.doneCount += 1;
      })
      .addCase(createSFTPCreds.fulfilled, (draft) => {
        draft.createSFTPCreds.status = COMPLETED;
        draft.doneCount += 1;
      })
      .addCase(updateOnboardingStatus.fulfilled, (draft, action) => {
        const invitePartnerState = _get(action, 'payload.gettingStarted.invitePartnerCompleted');
        const inviteTeamState = _get(action, 'payload.gettingStarted.inviteTeamCompleted');
        const connectEasyPostSkipped = _get(
          action,
          'payload.gettingStarted.connectEasyPostSkipped'
        );
        const sftpFileUploadCompleted = _get(
          action,
          'payload.gettingStarted.sftpFileUploadCompleted'
        );
        const ediSpecsReviewCompleted = _get(
          action,
          'payload.gettingStarted.ediSpecsReviewCompleted'
        );
        const testOrderCompleted = _get(action, 'payload.gettingStarted.testOrderCompleted');
        draft.inviteTeam.status = inviteTeamState;
        draft.invitePartners.status = invitePartnerState;
        draft.connectEasyPost.status = connectEasyPostSkipped
          ? SKIPPED
          : draft.connectEasyPost.status;
        draft.uploadSFTPFile.status = sftpFileUploadCompleted
          ? COMPLETED
          : draft.uploadSFTPFile.status; // only completed state is saved here
        draft.viewEDISpecifications.status = ediSpecsReviewCompleted
          ? COMPLETED
          : draft.viewEDISpecifications.status; // only completed state is saved here
        draft.testOrder.status = testOrderCompleted ? COMPLETED : draft.testOrder.status; // only completed state is saved here
        draft.doneCount += 1;
      })
      .addCase(fetchGettingStarted.fulfilled, (draft, action) => {
        return { ...draft, ...action.payload.data };
      })
      .addCase(updateUserCompany.fulfilled, (draft, action) => {
        const isEDIUpdate = action.meta.arg.ediData;
        if (isEDIUpdate && draft.configureEDISettings.status !== COMPLETED) {
          draft.configureEDISettings.status = COMPLETED;
          draft.doneCount += 1;
        }
      });
  },
});

export const {
  billingAddressAdded,
  returnsAddressAdded,
  shippingAddressAdded,
  shippingMethodAdded,
} = getStartedSlice.actions;

export default getStartedSlice.reducer;

// Hooks

// called on get started page on mount to reload the onboarding status of user/company
export const useFetchGetStarted = () => {
  const [loading, setLoading] = useSafeState(true);
  const [error, setError] = useSafeState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGettingStarted()).then((action) => {
      if (action.error) {
        console.error('Unable to fetch getting started data', action.error.message);
        setError(action.error.message);
      }
      setLoading(false);
    });
  }, [dispatch, setError, setLoading]);

  return {
    loading,
    error,
  };
};
