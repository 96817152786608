/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';

import Button from 'components/Common/Button';
import FormField from 'components/Common/FormField';
import Icon from 'storybook/stories/molecules/Icon';

import './GenerateInvoiceSingleTaxItem.css';

const GenerateInvoiceSingleTaxItem = ({
  index,
  initialType,
  initialAmount,
  hideRemoveTaxItem,
  onUpdate,
  onRemoveTaxItem,
  id,
  showProvince,
}) => {
  const [taxAmount, setTaxAmount] = useState(initialAmount);
  const [taxType, setTaxType] = useState(initialType);

  const onChangeTaxAmount = (evt) => {
    const { value } = evt.target;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setTaxAmount(value);
      const newTax = {
        amount: value,
        type: taxType,
        id,
      };
      onUpdate(evt, index, newTax);
    }
  };

  const onChangeTaxType = (evt) => {
    const { value } = evt.target;
    setTaxType(value);

    const newTax = {
      amount: taxAmount,
      type: value,
      id,
    };
    onUpdate(evt, index, newTax);
  };

  return (
    <div className="row g-3">
      <div className="col-12">
        <label className="text-muted tax-item-title">Tax Item #{index + 1}</label>
        {!hideRemoveTaxItem && (
          <Button
            outline
            color="danger"
            size="sm"
            className="tax-item-remove-btn"
            onClick={(e) => onRemoveTaxItem(e, index)}
          >
            <Icon name="delete">Remove Tax Item</Icon>
          </Button>
        )}
      </div>
      <div className="col-12 col-md-6">
        <FormField
          type="text"
          id="tax-amount-input"
          label="Tax Amount"
          size="sm"
          value={taxAmount}
          onChange={onChangeTaxAmount}
          name="taxAmount"
          placeholder="00.00"
        />
      </div>
      <div className="col-12 col-md-6">
        <div className="form-group">
          <label htmlFor="tax-type-select">Tax Type</label>
          <select
            id="tax-type-select"
            className="form-control form-control-sm"
            value={taxType}
            onChange={onChangeTaxType}
          >
            <option value="GS">Goods and services tax (GST)</option>
            <option value="SP">{showProvince ? 'Provincial tax (PST)' : 'State tax'}</option>
            <option value="CP">County Sales tax</option>
            <option value="CS">City Sales tax</option>
            <option value="FT">Federal Excise tax</option>
            <option value="LT">Local Sales tax</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default GenerateInvoiceSingleTaxItem;
