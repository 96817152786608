import type { CommerceType, Company, Platform } from 'types/models/company';

export type UserEmail = {
  address: string;
  verified: boolean;
};

export type UserFlags = {
  recordUI: boolean;
  recordNetworkData: boolean;
  captureFrontendData: boolean;
  stayAnonymous: boolean;
};

export type UserOnboardingAttributes = {
  referralCompanyId: string;
  companyName: string;
  commerceType: CommerceType;
  platform: Platform;
  storeUrl: string;
  source: string;
  firstName: string;
  lastName: string;
  companyUrl: string;
  temporaryTokenId?: string;
};

export type UserMembership = {
  companyObjectId: string;
  apiKey: string;
  role: string;
  companyName: string;
  commerceType: CommerceType;
  url: string;
};

// type checker for user memebership
export const isUserMembership = (
  obj: Partial<Company> | UserMembership | null
): obj is UserMembership => {
  return (obj as UserMembership)?.companyObjectId !== undefined;
};

export interface UserNotifications {
  skipWeeklyOverviewEmail: boolean;
  skipActionCreated: boolean;
  skipCriticalCompanyErrorCreated: boolean;
  skipFulfillmentPostedToBuyer: boolean;
  skipOrderPostedToSeller: boolean;
  skipOrderItemsCancelled: boolean;
  skipProductAvailableToBuyer: boolean;
}

export interface User {
  _id: string;
  emails: UserEmail[];
  firstName: string;
  lastName: string;
  apiKey: string;
  created: string;
  updated: string;
  companyObjectId: string;
  flags: UserFlags;
  onboardingAttributes: UserOnboardingAttributes;
  superUser: boolean;
  notifications: UserNotifications;
  companyId: string;
  memberships: UserMembership[];
}

export interface UserUpdate extends Partial<UserNotifications> {
  firstName?: string;
  lastName?: string;
  onboardingAttributes?: UserOnboardingAttributes;
}
