/* eslint-disable no-nested-ternary */
import { useState } from 'react';

import { getFulfillmentPackingSlip } from 'store/slices/ordersV2';

import Flex from '@react-css/flex';
import CreateFulfillmentModal from 'containers/OrderPage/CreateFulfillmentModal';
import useAlertQueue from 'hooks/useAlertQueue';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import StatusChip from 'storybook/stories/molecules/Chip/StatusChip';
import CopyIcon from 'storybook/stories/molecules/Icon/CopyIcon';

const Fulfillment = ({ fulfillment = {}, dispatch, orderId }) => {
  const { addErrorAlert } = useAlertQueue();
  const onDownloadSlip = (e) => {
    e.preventDefault();
    dispatch(getFulfillmentPackingSlip({ orderId, fulfillmentId: fulfillment._id })).then(
      (action) => {
        if (action.error) {
          addErrorAlert('Error', action.error.message);
        }
      }
    );
  };

  return (
    <Flex column gap="12px">
      <Flex alignItemsCenter wrap gap="16px">
        <Body variant="Body/Regular Bold">{fulfillment.carrier || 'Unknown Carrier'}</Body>
        <StatusChip kind="info">{fulfillment.posted ? 'Posted' : 'Not Posted'}</StatusChip>
      </Flex>
      <Flex alignItemsCenter wrap gap="16px">
        <Body variant="Body/Body Small Bold">Tracking Code</Body>
        {fulfillment?.trackingUrls?.length === 1 ? (
          <Flex gap="8px">
            <Body variant="Body/Body Small">
              <a href={fulfillment.trackingUrls[0]} target="_blank" rel="noreferrer">
                {fulfillment.trackingCode}
              </a>
            </Body>
            <CopyIcon textToCopy={fulfillment.trackingCode} />
          </Flex>
        ) : (
          <Flex gap="8px">
            <Body variant="Body/Body Small">{fulfillment.trackingCode}</Body>
            <CopyIcon textToCopy={fulfillment.trackingCode} />
            {fulfillment.trackingUrls.map((url, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <a href={url} key={idx + url} className="mb-0 mr-2">
                [{idx}]
              </a>
            ))}
          </Flex>
        )}
      </Flex>
      <Flex gap="8px" alignItemsCenter>
        <Body variant="Body/Body Small Bold">Documents</Body>
        <TertiaryButton size="small" $iconName="receipt_long" onClick={onDownloadSlip}>
          Packing Slip
        </TertiaryButton>
      </Flex>
    </Flex>
  );
};

const OrderFulfillments = ({ order, isSeller, orderPlatform, dispatch, orderCancelled }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const canCreateFulfillment =
    isSeller &&
    (orderPlatform === 'other' ||
      (orderPlatform === 'bigcommerce' &&
        order.items?.some((item) => item.sellerItemCode === '0')));
  const fulfillments = order.fulfillments || [];

  const numberOfOrderItems = () => {
    let count = 0;
    order.items?.forEach((item) => {
      if (!item.cancelled) {
        count += item.quantity;
      }
    });
    return count;
  };

  const numberOfFulfillmentItems = () => {
    let count = 0;
    fulfillments?.forEach((fulfillment) => {
      fulfillment.items?.forEach((item) => {
        count += item.quantity;
      });
    });
    return count;
  };

  return (
    <div className="card p-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4>Fulfillments</h4>
        {order.shipped ? (
          <span className="badge badge-success">Shipped</span>
        ) : canCreateFulfillment && !orderCancelled ? (
          <PrimaryButton size="small" onClick={() => setShowCreateModal(true)}>
            Create Fulfillment
          </PrimaryButton>
        ) : (
          <span className="badge badge-soft-dark">{`${numberOfFulfillmentItems()} out of ${numberOfOrderItems()} items shipped`}</span>
        )}
      </div>

      <Flex column gap="16px">
        {fulfillments.length > 0 ? (
          <>
            {fulfillments.map((fulfillment) => (
              <Fulfillment
                fulfillment={fulfillment}
                key={fulfillment._id}
                orderId={order._id}
                dispatch={dispatch}
              />
            ))}
          </>
        ) : (
          <p>There are no fulfillments for this order</p>
        )}
      </Flex>

      <CreateFulfillmentModal
        showModal={showCreateModal}
        order={order}
        dispatch={dispatch}
        onDismiss={() => setShowCreateModal(false)}
      />
    </div>
  );
};

export default OrderFulfillments;
