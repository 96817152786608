import Button from 'components/Common/Button';
import Message from 'components/Common/Message';
import { getFriendlyResourceName, getSupportButtons } from 'containers/TradeOpsIssues/Shared';
import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { listTradeOpsIssues } from 'utils/api/issues';

const SupportButtonWrapper = styled.span`
  display: inline-block;
`;
const SupportLink = styled(Button)`
  margin-right: 5px;
`;

const getErrorMessage = (numberOfErrorsPerResource) => {
  let message = '';

  Object.entries(numberOfErrorsPerResource).forEach(([resource, total]) => {
    if (total === 0) return;
    if (message !== '') {
      message += ', ';
    }

    message += `${pluralize('issue', total, true)} with ${getFriendlyResourceName(resource)}`;
  });

  return `${message}.`;
};

const TradeOpsIssuesAlert = () => {
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [numberOfErrorsPerResource, setNumberOfErrorsPerResource] = useState({});

  useEffect(() => {
    const isRestrictedPath = ['/issues'].some((path) => window.location.pathname.startsWith(path));

    if (isRestrictedPath) {
      setLoading(false);
      return;
    }
    listTradeOpsIssues({
      page: 0,
      limit: 3,
      archived: false,
      severityLevels: ['error', 'critical'],
    })
      .then(({ data }) => {
        setErrors(data.errors);
        setNumberOfErrorsPerResource(data.numberOfErrorsPerResource);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  if (loading) {
    return null;
  }
  if (!errors || errors.length === 0) {
    return null;
  }
  return (
    <Message show animate={false} kind="error">
      <div className="align-items-center" style={{ height: '100%', width: '100%' }}>
        {errors.length > 2 ? (
          <p className="small m-0 p-0" style={{ width: '100%' }}>
            {getErrorMessage(numberOfErrorsPerResource)}{' '}
            <SupportButtonWrapper>
              <SupportLink color="dark" size="sm" tag="a" href="/issues">
                View Issues
              </SupportLink>
            </SupportButtonWrapper>
          </p>
        ) : (
          <>
            {errors.map((error) => (
              <p className="small" style={{ margin: 0, marginBottom: 5 }}>
                {error.message} {getSupportButtons(error)}
              </p>
            ))}
          </>
        )}
      </div>
    </Message>
  );
};

export default TradeOpsIssuesAlert;
