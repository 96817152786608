import { useInfiniteQuery, type UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { getPartners, type GetPartnersParams, type PartnersResponse } from 'utils/api/partners';

const LIMIT = 250;
const QUERY_KEY = 'GET_ALL_PARTNERS';

type UseAllPartnersQueryOptions = Omit<
  UseInfiniteQueryOptions<
    PartnersResponse,
    unknown,
    PartnersResponse,
    PartnersResponse,
    [typeof QUERY_KEY]
  >,
  'queryKey' | 'queryFn' | 'getNextPageParam'
>;

interface UseAllPartnersOptions {
  options?: UseAllPartnersQueryOptions;
  params?: Omit<GetPartnersParams, 'page' | 'limit'>;
}

const useAllPartners = ({ options, params }: UseAllPartnersOptions = {}) => {
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading, refetch } =
    useInfiniteQuery(
      [QUERY_KEY],
      ({ pageParam = 0 }) => getPartners({ page: pageParam, limit: LIMIT, ...params }),
      {
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage?.data.length < LIMIT) return undefined;
          return allPages.length;
        },
        ...options,
      }
    );

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  const partners = useMemo(() => (data?.pages ?? []).flatMap((page) => page?.data), [data?.pages]);

  return {
    partners,
    isLoading,
    refetch,
  };
};

export default useAllPartners;
