import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
  type Placement,
} from '@floating-ui/react';
import Flex from '@react-css/flex';
import { merge } from 'lodash';
import {
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import elevate from 'storybook/mixins/elevate';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import Spinner from 'storybook/stories/molecules/Spinner';
import styled, { css } from 'styled-components';

export interface DropdownOptions {
  initialOpen?: boolean;
  placement?: Placement;
  modal?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onOpen?: () => void;
  onClose?: () => void;
  isLoading?: boolean;
}

/**
 * Hooks
 */

export function useDropdown({
  initialOpen = false,
  placement = 'bottom-end',
  modal,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  onOpen,
  onClose,
  isLoading = false,
}: DropdownOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'end',
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  });

  const { context } = data;

  const click = useClick(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  useEffect(() => {
    if (open) onOpen?.();
    else onClose?.();
  }, [open, onOpen, onClose]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
      isLoading,
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId, isLoading]
  );
}

type ContextType =
  | (ReturnType<typeof useDropdown> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
      setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>;
    })
  | null;

const DropdownContext = createContext<ContextType>(null);

const useDropdownContext = () => {
  const context = useContext(DropdownContext);

  if (context == null) {
    throw new Error('Dropdown components must be wrapped in <Dropdown />');
  }

  return context;
};

/**
 * Base
 */

const Dropdown = ({
  children,
  modal = false,
  ...restOptions
}: {
  children: React.ReactNode;
} & DropdownOptions) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const dropdown = useDropdown({ modal, ...restOptions });
  return <DropdownContext.Provider value={dropdown}>{children}</DropdownContext.Provider>;
};

/**
 * Trigger
 */

const TriggerWrapper = styled.button`
  all: unset;
`;

interface DropdownTriggerProps {
  children: React.ReactNode;
  asChild?: boolean;
}

const DropdownTrigger = forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & DropdownTriggerProps
>(({ children, asChild = false, ...props }, propRef) => {
  const context = useDropdownContext();
  const childrenRef = (children as any).ref;

  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    );
  }

  return (
    <TriggerWrapper
      ref={ref}
      type="button"
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </TriggerWrapper>
  );
});

/**
 * Header
 */

const CloseIcon = styled(Icon)`
  all: unset;

  &:hover::before {
    ${({ theme }) => css`
      color: ${theme.color.blue600};
    `}
  }
`;

export interface DropdownHeaderProps {
  heading: string;
  iconName?: string;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const DropdownHeader = forwardRef<HTMLDivElement, DropdownHeaderProps>(
  ({ heading, iconName, onClose }, ref) => {
    const { setLabelId, setOpen } = useDropdownContext();
    const id = useId();

    // Only sets `aria-labelledby` on the Dropdown root element
    // if this component is mounted inside it.
    useLayoutEffect(() => {
      setLabelId(id);
      return () => setLabelId(undefined);
    }, [id, setLabelId]);

    return (
      <Flex alignItemsCenter gap="8px">
        <Flex.Item grow={1}>
          <Flex alignItemsCenter gap="8px">
            {iconName && <Icon name={iconName} size="20px" color="gray600" position="before" />}

            <Heading variant="Headings/H3" as="strong" ref={ref} id={id}>
              {heading}
            </Heading>
          </Flex>
        </Flex.Item>

        <CloseIcon
          as="button"
          type="button"
          name="close"
          position="before"
          onClick={(event) => {
            onClose?.(event);
            setOpen(false);
          }}
        />
      </Flex>
    );
  }
);

/**
 * Content
 */

interface ContentWrapperProps extends React.HTMLProps<HTMLDivElement> {
  padding?: string;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  ${elevate('4')};
  width: max-content;
  min-width: 250px;
  max-width: calc(100vw - 10px);
  border-radius: 16px;
  z-index: 1031; // Left menu is 1030
  border-radius: 16px;

  ${({ padding = '32px 24px' }) => css`
    padding: ${padding};
  `}

  ${({ theme }) => css`
    background: ${theme.color.white};
  `}
  
  ${Spinner} {
    display: block;
    margin: 0 auto;
  }
`;

interface DropdownContentProps extends Omit<ContentWrapperProps, 'children'> {
  /**
   * Render prop
   */
  children: (props: { close: () => void }) => React.ReactElement | React.ReactElement[] | null;
}

const DropdownContent = forwardRef<HTMLDivElement, DropdownContentProps>(
  ({ style, padding, children, ...props }, propRef) => {
    const { context: floatingContext, setOpen, isLoading, ...context } = useDropdownContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!floatingContext.open) return null;

    return (
      <FloatingPortal>
        <FloatingFocusManager context={floatingContext} modal={context.modal}>
          <ContentWrapper
            ref={ref}
            padding={padding}
            style={{ ...context.floatingStyles, ...style }}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps(props)}
          >
            {isLoading ? <Spinner /> : children({ close: () => setOpen(false) })}
          </ContentWrapper>
        </FloatingFocusManager>
      </FloatingPortal>
    );
  }
);

/**
 * This is a Floating UI Popover, styled to look like our Dropdowns, taken from here:
 *
 * - Base documentation: https://floating-ui.com/docs/popover
 * - Copied Example: https://codesandbox.io/s/distracted-swirles-jo1pvu?file=/src/App.tsx
 *
 * Eventually it may make sense to revert to the original Floating UI Popover and extend it
 * here to add Dropdown-specific style and features.
 *
 * Most of the time you will pass a `asChild` prop to `Tooltip.Trigger` in order to use any element as the anchor.
 * Doing so may create a warning in your console around passing a `ref` to a function component. The tooltip will
 * likely not work as expected. To fix this, wrap your anchor component in a `forwardRef` call.
 *
 * If you are still running into issues even after wrapping your anchor component in a `forwardRef` call, you may
 * control the visibility of the tooltip externally by using the `open` and `onOpenChange` props. This will allow
 * you to control the visibility of the tooltip from outside of the component.
 *
 * The `children` prop is a render prop that will be called with an object containing a `close` function. You may use
 * this function to close the dropdown if a user takes an action, such as after a user clicks a button or submits a form.
 */
export default merge(Dropdown, {
  Trigger: DropdownTrigger,
  Content: DropdownContent,
  Header: DropdownHeader,
});
