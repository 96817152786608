import type { Response } from 'types/api';
import { postRequest } from 'utils/request';

export type InstallEasyPostParams = {
  apiKey: string;
};

export const installEasyPost = (data: InstallEasyPostParams): Promise<Response<void>> =>
  postRequest('/easypost/install', data);

export const uninstallEasyPost = (): Promise<Response<void>> => postRequest('/easypost/uninstall');
