import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useBoolean, useCopyToClipboard } from 'usehooks-ts';

import type { IconProps } from 'storybook/stories/molecules/Icon';
import Icon from 'storybook/stories/molecules/Icon';

const IS_COPIED_DURATION = 2500;

const StyledIcon = styled(Icon).attrs(({ color = 'iconDefault' }) => ({
  color,
}))`
  cursor: pointer;
`;

interface CopyIconProps extends Omit<IconProps, 'name'> {
  textToCopy: string;
}

/**
 * A `CopyIcon` instruments `Icon` to create a clickable button which copies
 * the supplied text to a user's clipboard on click. It also provides visual
 * feedback to the user that the operation completed successfully.
 */
const CopyIcon = ({ children, textToCopy, color, position = 'after' }: CopyIconProps) => {
  const isCopiedTimeout = useRef<number>();

  const successIconVisibility = useBoolean(false);
  const [copiedText, copyText] = useCopyToClipboard();

  const shouldDisplaySuccessIcon = copiedText && successIconVisibility.value;

  useEffect(() => {
    return () => {
      if (isCopiedTimeout.current) clearTimeout(isCopiedTimeout.current);
    };
  }, []);

  const handleClick = () => {
    copyText(textToCopy);
    successIconVisibility.toggle();
    isCopiedTimeout.current = window.setTimeout(successIconVisibility.toggle, IS_COPIED_DURATION);
  };

  return shouldDisplaySuccessIcon ? (
    <StyledIcon name="check_circle" color="green500" position={position}>
      {children}
    </StyledIcon>
  ) : (
    <StyledIcon
      as="button"
      name="content_copy"
      color={color}
      position={position}
      onClick={handleClick}
      aria-label={`Copy ${textToCopy} to your clipboard`}
    >
      {children}
    </StyledIcon>
  );
};

export default CopyIcon;
