import type { Order20210101 } from 'types/models/order';

export const calculateHstTaxAmount = (taxBreakdown: Order20210101['taxes']) => {
  if (taxBreakdown === null) return 0;

  return taxBreakdown.reduce((accumulator, tax) => {
    if (['GS', 'SP'].includes(tax.type)) return accumulator + parseFloat(String(tax.amount));
    return accumulator;
  }, 0);
};

export const friendlyTaxType = (taxType: string): string => {
  switch (taxType) {
    case 'SP':
      return 'State/Provincial Tax';
    case 'GS':
      return 'Goods and Services Tax';
    case 'CP':
      return 'County Sales Tax';
    case 'CS':
      return 'City Sales Tax';
    case 'FT':
      return 'Federal Excise Tax';
    case 'LT':
      return 'Local Sales Tax';
    default:
      return '';
  }
};
