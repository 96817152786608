import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';

import useAlertQueue from 'hooks/useAlertQueue';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import type { Return } from 'types/models/return';
import { rejectReturn } from 'utils/api/orders';

interface RejectReturnFormData {
  rejectionReason: string;
}

interface RejectReturnModalProps {
  orderReturn: Return;
  shouldShowModal: boolean;
  onDismiss: () => void;
  refetchReturns: () => void;
}

const RejectReturnModal = ({
  orderReturn,
  shouldShowModal,
  onDismiss,
  refetchReturns,
}: RejectReturnModalProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm<RejectReturnFormData>({
    mode: 'onChange',
    defaultValues: {
      rejectionReason: '',
    },
  });

  const rejectingReturn = useMutation({
    mutationFn: (data: RejectReturnFormData) =>
      rejectReturn(orderReturn.orderId, orderReturn.id, data),
    onSuccess: () => {
      refetchReturns();
      reset();
      onDismiss();
      addSuccessAlert('Success', 'You have rejected the return request.');
    },
    onError: (error) => {
      addErrorAlert('Something went wrong', 'The return request was not rejected.');
      console.error('Error rejecting return', error);
    },
  });

  return (
    <Modal.Root centered show={shouldShowModal} onHide={onDismiss} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Reject Return</Modal.Title>
      </Modal.Header>

      <form
        onSubmit={handleSubmit((data) => {
          rejectingReturn.mutate(data);
        })}
      >
        <Modal.Body>
          <Flex column gap="4px">
            <Label isRequired htmlFor="rejectionReason">
              Reason for Rejection
            </Label>
            <Input
              {...register('rejectionReason', { required: true })}
              placeholder="This item is past the return policy window."
              required
            />
          </Flex>
        </Modal.Body>

        <Modal.Footer>
          <TertiaryButton onClick={onDismiss}>Cancel</TertiaryButton>

          <PrimaryButton disabled={!isValid || isSubmitting} kind="action" type="submit">
            Reject Return
          </PrimaryButton>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default RejectReturnModal;
