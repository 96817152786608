import Modal from 'storybook/stories/cells/Modal';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

import RadioButton from 'storybook/stories/molecules/RadioButton';
import type { PartnerAction } from 'types/models/partner-action';

interface SelectTemplateModalProps {
  showModal: boolean;
  onHide: () => void;
  actionTemplates: any;
  isLoading: boolean;
  selectedTemplate: PartnerAction;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<PartnerAction>>;
  onNextClick: () => void;
}

const SelectTemplateModal = ({
  showModal,
  onHide,
  actionTemplates,
  isLoading,
  selectedTemplate,
  setSelectedTemplate,
  onNextClick,
}: SelectTemplateModalProps) => {
  if (isLoading) return <div>loading...</div>;

  return (
    <Modal.Root
      show={showModal}
      onHide={onHide}
      animation={false}
      aria-labelledby="action-terms-and-conditions-general-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Acknowledgement of Terms and Conditions Action</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="mb-3">Select Template</p>

        {actionTemplates.data.map((template: PartnerAction) => (
          <RadioButton
            key={template.id}
            name="selectTemplate"
            className="mb-3"
            id="select-terms-and-conditions-template"
            data-testid={`select-template-${template.cardTitle}`}
            value={selectedTemplate}
            onChange={() => {
              setSelectedTemplate(template);
            }}
          >
            <div>
              <b>{template.cardTitle}</b>
              <br />
              <span className="small text-muted create-action-kind-description-text">
                {template.cardText}
              </span>
            </div>
          </RadioButton>
        ))}
      </Modal.Body>

      <Modal.Footer>
        <SecondaryButton onClick={onNextClick}>Next</SecondaryButton>
      </Modal.Footer>
    </Modal.Root>
  );
};

export default SelectTemplateModal;
