import Flex from '@react-css/flex';
import styled, { css } from 'styled-components';
import { useBoolean } from 'usehooks-ts';

import ChipList from 'storybook/stories/cells/ChipList';
import SearchResult from 'storybook/stories/cells/SearchResult';
import {
  MainTableWrapper,
  ProductVariantTableWrapper,
  TableMeta,
  ViewVariantsButton,
} from 'storybook/stories/cells/SearchResult/shared/Styles';
import Table from 'storybook/stories/cells/Table';
import Tooltip from 'storybook/stories/cells/Tooltip';
import CopyIcon from 'storybook/stories/molecules/Icon/CopyIcon';
import Toggle from 'storybook/stories/molecules/Toggle';
import type {
  SellerProduct,
  SellerProductImage,
  SellerProductVariant,
} from 'types/models/seller-product';
import { ProductImageThumbnail } from './shared/ProductImage';

// VARIANT TABLE

interface ProductVariantTableProps {
  variants: SellerProductVariant[];
}

const ProductVariantTable = ({ variants }: ProductVariantTableProps) => (
  <ProductVariantTableWrapper>
    <Table>
      <Table.THead>
        <Table.TR>
          <Table.TH width="280px">Title/Sku</Table.TH>
          <Table.TH>Barcode</Table.TH>
          <Table.TH align="right" width="100px">
            Quantity
          </Table.TH>
          <Table.TH align="right" width="180px">
            Your Retail Price
          </Table.TH>
        </Table.TR>
      </Table.THead>
      <Table.TBody>
        {variants.map((variant) => (
          <Table.TR key={variant._id}>
            <Table.TD>
              {variant.title || 'N/A'}
              <TableMeta>SKU: {variant.sku}</TableMeta>
            </Table.TD>
            <Table.TD onClick={(event) => event.stopPropagation()}>
              {variant.barcode ? (
                <CopyIcon textToCopy={variant.barcode}>{variant.barcode}</CopyIcon>
              ) : (
                'N/A'
              )}
              <TableMeta>{variant.barcodeType}</TableMeta>
            </Table.TD>
            <Table.TD align="right">{variant.inventoryQuantity}</Table.TD>
            <Table.TD align="right">{variant.retailPrice}</Table.TD>
          </Table.TR>
        ))}
      </Table.TBody>
    </Table>
  </ProductVariantTableWrapper>
);

// STATUS AREA

const ProductStatus = () => null;

// TAGS AREA

const ProductTagsLabel = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  ${({ theme }) => css`
    font-family: ${theme.font.secondary};
    color: ${theme.color.gray600};
  `}
`;

// PRODUCT TABLE

interface ProductTableProps {
  product: SellerProduct;
  onProductActiveChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    product: SellerProduct
  ) => void;
}

const ProductTable = ({ product, onProductActiveChange }: ProductTableProps) => {
  // This is required in order to disallow the contained instance of React Toggle to
  // bubble click events out to the parent `SearchResult` component. Unfortunately
  // instances of React Toggle do not come equipped with their own `onClick` props.
  // More info: https://github.com/aaronshaf/react-toggle/issues/77
  const handleCellClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
    event.stopPropagation();
  };

  const googleProductCategory = product.googleProductCategory?.code
    ? Object.values(product.googleProductCategory).join('-')
    : 'N/A';

  return (
    <MainTableWrapper>
      <Table>
        <Table.THead>
          <Table.TR>
            <Table.TH nowrap>Product Title</Table.TH>
            <Table.TH nowrap width="475px">
              Google Product Category
            </Table.TH>
            <Table.TH width="80px" align="right">
              Active
            </Table.TH>
          </Table.TR>
        </Table.THead>
        <Table.TBody>
          <Table.TR>
            <Table.TD truncate title={product.title}>
              {product.title}
            </Table.TD>
            <Table.TD>{googleProductCategory}</Table.TD>
            <Table.TD align="right" onClick={handleCellClick}>
              <Toggle
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onProductActiveChange(event, product)
                }
                checked={product.active}
                aria-label={`Activate ${product.title}`}
                name={`activate-${product.id}`}
              />
            </Table.TD>
          </Table.TR>
        </Table.TBody>
      </Table>
    </MainTableWrapper>
  );
};

// PRODUCT IMAGE

interface ProductImageProps {
  productTitle: string;
  image?: SellerProductImage;
}

const ProductImage = ({ image, productTitle }: ProductImageProps) => {
  return image ? (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <ProductImageThumbnail imageSrc={image.src} />
      </Tooltip.Trigger>
      <Tooltip.Content>
        <img width="272" src={image.src} alt={productTitle} referrerPolicy="no-referrer" />
      </Tooltip.Content>
    </Tooltip>
  ) : (
    <ProductImageThumbnail />
  );
};

// MAIN

interface SellerProductSearchResultProps {
  product: SellerProduct;
  checkboxUi: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onProductActiveChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    product: SellerProduct
  ) => void;
}

/**
 * `SearchResult` implementation for a Seller Product
 */
const SellerProductSearchResult = ({
  product,
  checkboxUi,
  onClick,
  onProductActiveChange,
}: SellerProductSearchResultProps) => {
  const { value: isVariantTableVisible, toggle: toggleVariantTableVisibility } = useBoolean(false);

  const images = product.images || [];

  const [primaryImage] = images.sort((a, b) => a.position - b.position);

  const handleVariantButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleVariantTableVisibility();
  };

  return (
    <SearchResult
      checkboxUi={checkboxUi}
      footerUi={isVariantTableVisible && <ProductVariantTable variants={product.variants} />}
      onClick={onClick}
    >
      <Flex column gap="24px">
        <Flex alignItemsCenter gap="32px">
          <ProductImage image={primaryImage} productTitle={product.title} />

          <Flex.Item grow={1}>
            <ProductTable product={product} onProductActiveChange={onProductActiveChange} />
          </Flex.Item>
        </Flex>

        <Flex alignItemsCenter>
          <Flex.Item grow={1}>
            <Flex alignItemsCenter gap="40px">
              <ProductStatus />

              <Flex alignItemsCenter gap="8px">
                <ProductTagsLabel>Tags:</ProductTagsLabel>
                <ChipList items={product.tags} variant="light" />
              </Flex>
            </Flex>
          </Flex.Item>

          <ViewVariantsButton
            isVariantTableVisible={isVariantTableVisible}
            onClick={handleVariantButtonClick}
          >
            View Variants
          </ViewVariantsButton>
        </Flex>
      </Flex>
    </SearchResult>
  );
};

export default SellerProductSearchResult;
