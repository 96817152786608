import type { Address } from 'types/general';

interface StreetAddressProps {
  address: Address;
}

const StreetAddress = ({ address }: StreetAddressProps) => (
  <address>
    {address.name}
    <br />
    {address.company && (
      <>
        {address.company}
        <br />
      </>
    )}
    {address.addressOne}
    <br />
    {address.addressTwo && (
      <>
        {address.addressTwo}
        <br />
      </>
    )}
    {address.city}, {address.state} {address.zip}
    <br />
    {address.country}
  </address>
);

export default StreetAddress;
