import Grid from '@react-css/grid';
import { useQuery } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { useHistory, useParams } from 'react-router';

import Spinner from 'components/Common/Spinner';
import DashboardPage from 'components/Dashboard/DashboardPage';
import useAlertQueue from 'hooks/useAlertQueue';
import { useAppSelector } from 'store';
import { getInvoice } from 'utils/api/invoices';
import { getPartnerByCompanyId } from 'utils/api/partners';
import { ACCOUNT_TYPE_SELLER } from 'utils/constants';

import InvoiceDashboardButton from './Invoice/InvoiceDashboardButton';
import InvoiceDetailsCard from './Invoice/InvoiceDetailsCard';
import InvoiceItemsCard from './Invoice/InvoiceSummaryCard';

import Page from 'storybook/stories/cells/Page';
import type { Company } from 'types/models/company';
import type { Invoice } from 'types/models/invoice';
import { canResetInvoice } from 'utils/invoices';
import InvoiceResetBanner from './Invoice/InvoiceResetBanner';

const backURL = '/invoices';

interface InvoicePageWrapperProps {
  children: ReactNode;
  title: string;
  invoice?: Invoice;
  company?: Company | null;
}

type InvoicePageParams = {
  id: string;
};

const InvoicePageWrapper = ({ children, title, invoice, company }: InvoicePageWrapperProps) => {
  const history = useHistory();

  return (
    <DashboardPage>
      <Page>
        <Page.Head
          title={title}
          backButton={{
            onClick: () => history.push(backURL),
            text: 'Back to Invoices',
          }}
        >
          <InvoiceDashboardButton invoice={invoice} company={company} />
        </Page.Head>

        <Page.Body>{children}</Page.Body>
      </Page>
    </DashboardPage>
  );
};

const InvoicePage = () => {
  const { id } = useParams<InvoicePageParams>();
  const me = useAppSelector((state) => state.me);
  const alertQueue = useAlertQueue();

  const fetchingInvoice = useQuery({
    queryKey: ['getInvoice', id],
    queryFn: () => getInvoice(id),
    staleTime: 60000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
    onError: (err) => {
      console.error(`Failed to fetch invoice ${id} ${err}`);
      alertQueue.addErrorAlert('Error', 'Error retrieving invoice');
    },
  });

  const invoice = fetchingInvoice.data?.data;
  const isSeller = me.company?.commerceType === ACCOUNT_TYPE_SELLER;
  const verb = isSeller ? 'to' : 'from';
  const partnerCompanyId = invoice && (isSeller ? invoice.buyerCompanyId : invoice.sellerCompanyId);

  const fetchingPartner = useQuery({
    queryKey: ['getPartnerByCompanyId', partnerCompanyId],
    queryFn: () => getPartnerByCompanyId(partnerCompanyId!),
    staleTime: 60000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!partnerCompanyId && !!me,
    onError: (err) => {
      console.error(`Failed to fetch invoice partner ${err}`);
      alertQueue.addErrorAlert('Error', 'Error retrieving partner for invoice');
    },
  });

  const partner = fetchingPartner.data;

  if (fetchingInvoice.isLoading || fetchingPartner.isLoading) {
    return (
      <InvoicePageWrapper title="Loading...">
        <Spinner />
      </InvoicePageWrapper>
    );
  }

  const partnerName = partner && (isSeller ? partner.buyerName : partner.sellerName);

  return (
    <InvoicePageWrapper
      title={`Invoice ${verb} ${partnerName}`}
      invoice={invoice}
      company={me.company}
    >
      {invoice && (
        <>
          {canResetInvoice(invoice, partner) && (
            <InvoiceResetBanner invoice={invoice} partner={partner!} />
          )}
          <Grid columns="auto 400px" gap="12px">
            <Grid.Item>
              <InvoiceItemsCard invoice={invoice} />
            </Grid.Item>
            <Grid.Item>
              <InvoiceDetailsCard invoice={invoice} />
            </Grid.Item>
          </Grid>
        </>
      )}
    </InvoicePageWrapper>
  );
};

export default InvoicePage;
