import type { RouteProps } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';

interface ProtectedRouteProps extends RouteProps {
  isAllowed: boolean;
  redirectTo: string;
  component: React.ComponentType<any>;
}

const ProtectedRoute = ({
  component: Component,
  isAllowed,
  redirectTo,
  ...routeProps
}: ProtectedRouteProps) => {
  return (
    <Route
      {...routeProps}
      render={(props) => (isAllowed ? <Component {...props} /> : <Redirect to={redirectTo} />)}
    />
  );
};

export default ProtectedRoute;
