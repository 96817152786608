import { useEffect, useState } from 'react';

import Icon from 'storybook/stories/molecules/Icon';

import './HelpWidget.css';

const getRandomNum = (min, max) => {
  return Math.round(Math.random() * (max - min) + min);
};

const HelpWidget = ({ options }) => {
  const [optionNum, setOptionNum] = useState(-1);
  useEffect(() => {
    const num = getRandomNum(0, options.length * 2);
    if (num >= options.length) {
      // Hides the widget sometimes
      setOptionNum(-1);
    } else {
      setOptionNum(num);
    }
  }, [options.length]);
  if (optionNum === -1) {
    return null;
  }
  return (
    <span className="badge bg-primary-soft help-widget-wrapper">
      <Icon name="info">
        <span className="help-widget-text">{options[optionNum]}</span>
      </Icon>
    </span>
  );
};

export default HelpWidget;
