/**
 * Company module contains actions and reducer for current user's company
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  makeUserOwnerOfCompany as apiMakeUserOwnerOfCompany,
  removeUserFromCompany as apiRemoveUserFromCompany,
  updateSFTPCreds as apiUpdateSFTPCreds,
  updateCompany,
} from 'utils/api/companies';

import type { RootState } from 'store';

export const updatePlatformAuth = (platform: string): any =>
  createAsyncThunk<
    any,
    any,
    {
      state: RootState;
    }
  >(`company/update${platform}Auth`, (auth, thunkAPI) => {
    const { me } = thunkAPI.getState();
    const { company } = me;
    if (!company) return company;
    return updateCompany(company._id, { auth });
  });

export const updateBigCommerceAuth = updatePlatformAuth('BigCommerce');
export const updateWooCommerceAuth = updatePlatformAuth('WooCommerce');
export const updateMagento2Auth = updatePlatformAuth('Magento2');
export const updateShopifyAuth = updatePlatformAuth('Shopify');

export const updateOnboardingStatus: any = createAsyncThunk<
  any,
  any,
  {
    state: RootState;
  }
>('company/updateOnboardingStatus', (gettingStarted, thunkAPI) => {
  const { me } = thunkAPI.getState();
  const { company } = me;
  if (!company) return company;
  return updateCompany(company._id, { gettingStarted });
});

export const updateSFTPCreds = createAsyncThunk<
  any,
  any,
  {
    state: RootState;
  }
>('company/updateSFTPCreds', (body, thunkAPI) => {
  const { me } = thunkAPI.getState();
  const { company } = me;
  if (!company) return company;
  return apiUpdateSFTPCreds(company._id, body);
});

export const removeUserFromCompany: any = createAsyncThunk<
  any,
  string,
  {
    state: RootState;
  }
>('company/removeUserFromCompany', (userId, thunkAPI) => {
  const { me } = thunkAPI.getState();
  const { company } = me;
  if (!company) return company;
  return apiRemoveUserFromCompany(company._id, userId);
});

export const makeUserOwnerOfCompany: any = createAsyncThunk<
  any,
  string,
  {
    state: RootState;
  }
>('company/makeUserOwnerOfCompany', (userId, thunkAPI) => {
  const { me } = thunkAPI.getState();
  const { company } = me;
  if (!company) return company;
  return apiMakeUserOwnerOfCompany(company._id, userId);
});

const companySlice = createSlice({
  name: 'company',
  initialState: {
    isUpdatingPlatformAuth: false,
    isUpdatingOnboardingStatus: false,
    isInvitingUser: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateBigCommerceAuth.pending, (draft) => {
        draft.isUpdatingPlatformAuth = true;
      })
      .addCase(updateBigCommerceAuth.fulfilled, (draft) => {
        draft.isUpdatingPlatformAuth = false;
      })
      .addCase(updateBigCommerceAuth.rejected, (draft) => {
        draft.isUpdatingPlatformAuth = false;
      })
      .addCase(updateWooCommerceAuth.pending, (draft) => {
        draft.isUpdatingPlatformAuth = true;
      })
      .addCase(updateWooCommerceAuth.fulfilled, (draft) => {
        draft.isUpdatingPlatformAuth = false;
      })
      .addCase(updateWooCommerceAuth.rejected, (draft) => {
        draft.isUpdatingPlatformAuth = false;
      })
      .addCase(updateShopifyAuth.pending, (draft) => {
        draft.isUpdatingPlatformAuth = true;
      })
      .addCase(updateShopifyAuth.fulfilled, (draft) => {
        draft.isUpdatingPlatformAuth = false;
      })
      .addCase(updateShopifyAuth.rejected, (draft) => {
        draft.isUpdatingPlatformAuth = false;
      })
      .addCase(updateOnboardingStatus.pending, (draft) => {
        draft.isUpdatingOnboardingStatus = true;
      })
      .addCase(updateOnboardingStatus.fulfilled, (draft) => {
        draft.isUpdatingOnboardingStatus = false;
      })
      .addCase(updateOnboardingStatus.rejected, (draft) => {
        draft.isUpdatingOnboardingStatus = false;
      });
  },
});

export default companySlice.reducer;
