import Flex from '@react-css/flex';
import GlobalSearchGraphicSVG from 'images/global-search-graphic.svg';
import type React from 'react';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import Heading from 'storybook/stories/molecules/Heading';
import styled from 'styled-components';

interface NoResultsCardProps extends React.ComponentProps<typeof Card> {
  /**
   * An optional image import
   */
  imageSrc?: string;
  /**
   * The title
   */
  title: string;
  /**
   * An optional, simple description
   */
  description?: React.ReactNode;
  /**
   * Typically reserved for buttons, but can be any React node
   */
  children?: React.ReactNode;
}

const Image = styled.img`
  width: 100px;
  margin: 40px auto 0;
  display: block;
`;

const StyledBody = styled(Body)`
  max-width: 422px;
  text-align: center;
`;

const NoResultsCard = ({
  imageSrc,
  title,
  description,
  children,
  ...cardProps
}: NoResultsCardProps) => (
  <Card {...cardProps}>
    <Card.Body>
      <Flex column gap="64px" alignItemsCenter>
        <Image src={imageSrc || GlobalSearchGraphicSVG} alt={`Image for ${title}`} />
        <Flex column gap="32px" alignItemsCenter>
          <Flex column gap="16px" alignItemsCenter>
            <Heading variant="Headings/H2">{title}</Heading>
            {description && (
              <StyledBody variant="Body/Regular" color="bodyTextSecondary">
                {description}
              </StyledBody>
            )}
          </Flex>
          {children}
        </Flex>
      </Flex>
    </Card.Body>
  </Card>
);

export default NoResultsCard;
