import styled from 'styled-components';

import Button from 'components/Common/Button';

const SupportButtonWrapper = styled.span`
  display: inline-block;
`;

const SupportLink = styled(Button)`
  margin-right: 5px;
`;

export const getSupportButtons = (companyError) => {
  if (!companyError.nextSteps) {
    return null;
  }
  return (
    <SupportButtonWrapper>
      {companyError.nextSteps.map((nextStep) => {
        if (!nextStep.name || !nextStep.link) {
          return null;
        }
        if (nextStep.link.indexOf('https://') !== -1 || nextStep.link.indexOf('http://') !== -1) {
          // External links should open in a new tab
          return (
            <SupportLink
              target="_blank"
              tag="a"
              outline
              color="dark"
              size="sm"
              href={nextStep.link}
            >
              {nextStep.name}
            </SupportLink>
          );
        }
        return (
          <SupportLink tag="a" size="sm" outline color="dark" href={nextStep.link}>
            {nextStep.name}
          </SupportLink>
        );
      })}
    </SupportButtonWrapper>
  );
};

export const getFriendlyErrorCode = (errCode) => {
  switch (errCode) {
    case 'err_order_internal':
      return 'Order Issue';
    case 'err_ssh_unable_to_auth':
      return 'SFTP Connection Issue';
    default:
      return errCode;
  }
};

export const getBadgeBySeverity = (severity) => {
  if (severity === 'critical') {
    return 'bg-danger-soft';
  }
  if (severity === 'error') {
    return 'bg-danger-soft';
  }
  if (severity === 'warning') {
    return 'bg-warning-soft';
  }
  if (severity === 'info') {
    return 'bg-info-soft';
  }
  return 'bg-primary-soft';
};

export const getIconBySeverity = (severity) => {
  if (severity === 'critical') {
    return 'monitor_heart';
  }
  if (severity === 'error') {
    return 'report';
  }
  if (severity === 'warning') {
    return 'warning';
  }
  if (severity === 'info') {
    return 'info';
  }

  // Why x? B/c this shouldn't be reachable
  return 'x';
};

export const getFriendlyResourceName = (resourceName) => {
  if (resourceName === 'ediRecords') {
    return 'EDI Records';
  }
  return resourceName;
};
