import { useState } from 'react';
import { useDispatch } from 'react-redux';

import CreateAcknowledgementActionModal from 'containers/PartnerPage/Actions/Acknowledgement/CreateAcknowledgementActionModal';
import type { CreateAgreementActionData } from 'containers/PartnerPage/Actions/Agreement/CreateAgreementActionModal';
import CreateAgreementActionModal from 'containers/PartnerPage/Actions/Agreement/CreateAgreementActionModal';
import type { SelectActionKindFormParams } from 'containers/PartnerPage/Actions/Common/CreateActionKindModal';
import CreateActionKindModal from 'containers/PartnerPage/Actions/Common/CreateActionKindModal';
import type { CreateFileUploadActionData } from 'containers/PartnerPage/Actions/FileUpload/CreateFileUploadActionModal';
import CreateFileUploadActionModal from 'containers/PartnerPage/Actions/FileUpload/CreateFileUploadActionModal';
import {
  ACKNOWLEDGEMENT,
  FILE_UPLOAD,
  HELLO_SIGN,
  TERMS_AND_CONDITIONS,
} from 'containers/PartnerPage/Actions/Kinds';
import CreateTermsAndConditions from 'containers/PartnerPage/Actions/TermsAndConditions';
import { createConsumableActionTemplate } from 'store/slices/partners';
import type { PartnerAction } from 'types/models/partner-action';
import { uploadFile } from 'utils/api/documents';

const enum ModalOptions {
  ShowFileUploadFlow,
  ShowAcknowledgementFlow,
  ShowAgreementFlow,
  ShowTermsAndConditionsFlow,
  HideAll,
}

interface CreateActionTemplateProps {
  showModal: boolean;
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  onRefresh: React.Dispatch<void>;
}

const CreateActionTemplate = ({
  showModal,
  setShowCreateModal,
  onRefresh,
}: CreateActionTemplateProps) => {
  const dispatch = useDispatch();
  const [kind, setKind] = useState(FILE_UPLOAD);
  const [currentModal, setCurrentModal] = useState(ModalOptions.HideAll);

  const onTemplatesRefresh = () => {
    onRefresh();
    setCurrentModal(ModalOptions.HideAll);
  };

  const onSelectActionKind = (data: SelectActionKindFormParams) => {
    if (data.actionKind === FILE_UPLOAD) {
      setCurrentModal(ModalOptions.ShowFileUploadFlow);
    } else if (data.actionKind === HELLO_SIGN) {
      setCurrentModal(ModalOptions.ShowAgreementFlow);
    } else if (data.actionKind === ACKNOWLEDGEMENT) {
      setCurrentModal(ModalOptions.ShowAcknowledgementFlow);
    } else if (data.actionKind === TERMS_AND_CONDITIONS) {
      setCurrentModal(ModalOptions.ShowTermsAndConditionsFlow);
    }

    setKind(data.actionKind);
    setShowCreateModal(false);
  };

  const onBackClick = () => {
    setCurrentModal(ModalOptions.HideAll);
    setKind('');
    setShowCreateModal(true);
  };

  let actionTemplateCreationFlow = null;
  if (kind === FILE_UPLOAD) {
    const onCreate = (body: CreateFileUploadActionData) => {
      if (!body.bytes) {
        // @ts-ignore
        return dispatch(createConsumableActionTemplate(body));
      }
      return uploadFile({
        fileName: body.fileName ?? '',
        fileType: body.fileType ?? '',
        bytes: body.bytes,
        partnersWithViewAccess: [],
        sharedWithCompany: true,
      }).then((response) => {
        // @ts-ignore
        if (response.error.length > 0) {
          // @ts-ignore
          throw response.error[0];
        } else {
          const bodyCopy = { ...body };
          const { data } = response;
          const { documentId } = data;
          bodyCopy.optionalDocId = documentId;
          // @ts-ignore
          return dispatch(createConsumableActionTemplate(bodyCopy));
        }
      });
    };
    actionTemplateCreationFlow = (
      <CreateFileUploadActionModal
        kind={kind}
        showModal={currentModal === ModalOptions.ShowFileUploadFlow}
        onHide={() => setCurrentModal(ModalOptions.HideAll)}
        // @ts-ignore
        onCreate={onCreate}
        onRefresh={onTemplatesRefresh}
        onBackClick={onBackClick}
        isActionTemplate
      />
    );
  } else if (kind === HELLO_SIGN) {
    const onCreate = (body: CreateAgreementActionData) => {
      const {
        cardTitle,
        cardText,
        required,
        fileName,
        fileType,
        file,
        emailSubject,
        emailMessage,
      } = body;

      return uploadFile({
        fileName,
        fileType,
        bytes: file,
        partnersWithViewAccess: [],
        sharedWithCompany: true,
      }).then((response) => {
        // @ts-ignore
        if (response.error.length > 0) {
          // @ts-ignore
          throw response.error[0];
        } else {
          const bodyCopy = { ...body };
          const { data } = response;
          const { documentId } = data;
          bodyCopy.documentId = documentId;
          return dispatch(
            // @ts-ignore
            createConsumableActionTemplate({
              cardTitle,
              cardText,
              kind,
              required,
              documentId,
              emailSubject,
              emailMessage,
            })
          );
        }
      });
    };
    actionTemplateCreationFlow = (
      <CreateAgreementActionModal
        kind={HELLO_SIGN}
        showModal={currentModal === ModalOptions.ShowAgreementFlow}
        onHide={() => setCurrentModal(ModalOptions.HideAll)}
        onCreate={onCreate}
        onRefresh={onTemplatesRefresh}
        onBackClick={onBackClick}
        isActionTemplate
      />
    );
  } else if (kind === ACKNOWLEDGEMENT) {
    const submitConsumableAction = (body: PartnerAction) => {
      const { cardTitle, cardText, required, documentId } = body;
      return dispatch(
        // @ts-ignore
        createConsumableActionTemplate({
          cardTitle,
          cardText,
          required,
          documentId,
          kind,
        })
      );
    };
    const onCreate = (body: PartnerAction) => {
      const { fileName = '', fileType = '', bytes = [], sharedWithCompany } = body;
      return uploadFile({
        fileName,
        fileType,
        bytes,
        partnersWithViewAccess: [],
        sharedWithCompany,
      }).then((response) => {
        // @ts-ignore
        if (response.error.length > 0) {
          // @ts-ignore
          throw response.error[0];
        } else {
          const bodyCopy = { ...body };
          const { data } = response;
          const { documentId } = data;
          bodyCopy.documentId = documentId;
          return submitConsumableAction(bodyCopy);
        }
      });
    };
    actionTemplateCreationFlow = (
      <CreateAcknowledgementActionModal
        kind={ACKNOWLEDGEMENT}
        showModal={currentModal === ModalOptions.ShowAcknowledgementFlow}
        onHide={() => setCurrentModal(ModalOptions.HideAll)}
        // @ts-ignore
        onCreate={onCreate}
        onRefresh={onTemplatesRefresh}
        onBackClick={onBackClick}
        isActionTemplate
      />
    );
  } else if (kind === TERMS_AND_CONDITIONS) {
    // Creation process very similar to HelloSign
    const onCreate = (body: PartnerAction) => {
      const {
        cardTitle,
        cardText,
        required,
        fileName = '',
        fileType = '',
        file = [],
        emailSubject,
        emailMessage,
        terms,
      } = body;

      return uploadFile({
        fileName,
        fileType,
        bytes: file,
        partnersWithViewAccess: [],
        sharedWithCompany: true,
      }).then((response) => {
        // @ts-ignore
        if (response.error.length > 0) {
          // @ts-ignore
          throw response.error[0];
        } else {
          const { data } = response;
          const { documentId } = data;

          return dispatch(
            // @ts-ignore
            createConsumableActionTemplate({
              cardTitle,
              cardText,
              kind,
              required,
              documentId,
              emailSubject,
              emailMessage,
              terms,
            })
          );
        }
      });
    };
    actionTemplateCreationFlow = (
      <CreateTermsAndConditions
        showModal={currentModal === ModalOptions.ShowTermsAndConditionsFlow}
        onHide={() => setCurrentModal(ModalOptions.HideAll)}
        onCreate={onCreate}
        onRefresh={onTemplatesRefresh}
        onGeneralBackClick={onBackClick}
        isActionTemplate
      />
    );
  }

  return (
    <>
      <CreateActionKindModal
        showModal={showModal}
        setShowCreateActionKindModal={setShowCreateModal}
        onSelectKind={onSelectActionKind}
        isActionTemplate
      />
      {actionTemplateCreationFlow}
    </>
  );
};

export default CreateActionTemplate;
