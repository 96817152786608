import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

// Helper hook to make sure component hasn't unmounted yet before setting data
export function useSafeDispatch<T>(dispatch: Dispatch<SetStateAction<T>>) {
  const mounted = useRef(false);
  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  // @ts-ignore
  return useCallback((...args) => (mounted.current ? dispatch(...args) : undefined), [dispatch]);
}

// Only set state if component is still mounted
export function useSafeState<T>(initialState: T): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState<T>(initialState);
  const safeSetState = useSafeDispatch<T>(setState);
  return [state, safeSetState];
}
