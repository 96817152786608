import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import download from 'downloadjs';
import useAlertQueue from 'hooks/useAlertQueue';
import { useParams } from 'react-router';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Link from 'storybook/stories/molecules/Link';
import Layout from 'storybook/stories/species/Layout';
import { getPackingSlipForOrder } from 'utils/api/orders';
import type ApiError from 'utils/ApiError';

const OrderPackingSlipPage = () => {
  const alertQueue = useAlertQueue();
  const { orderId } = useParams<{ orderId: string }>();

  const generatingPackingSlipForOrder = useQuery({
    queryKey: [getPackingSlipForOrder, orderId] as const,
    queryFn: ({ queryKey }) => getPackingSlipForOrder(queryKey[1]),
    enabled: !!orderId,
    onSuccess: (data) => {
      download(new Blob([data]), 'packingslip.pdf', 'application/pdf');
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Error download packing slip', error.message);
    },
  });

  return (
    <Layout.Root>
      <Layout.Content>
        <Card>
          <Flex column gap="24px">
            <Body variant="Body/Regular">
              {generatingPackingSlipForOrder.isLoading
                ? 'Loading...'
                : 'Your packing slip should download momentarily'}
            </Body>

            <PrimaryButton as={Link} to="/orders">
              Back to Orders
            </PrimaryButton>
          </Flex>
        </Card>
      </Layout.Content>
    </Layout.Root>
  );
};

export default OrderPackingSlipPage;
