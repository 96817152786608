import { merge } from 'lodash';
import { useEffect, useRef } from 'react';
import { useBoolean } from 'usehooks-ts';

const useScrollTo = <T extends Element>(
  options?: ScrollIntoViewOptions
): [React.RefObject<T>, () => void] => {
  const ref = useRef<T>(null);
  const shouldScrollToRef = useBoolean(false);

  useEffect(() => {
    if (ref.current?.scrollIntoView && shouldScrollToRef.value) {
      ref.current.scrollIntoView(merge({ behavior: 'smooth' }, options));
    }

    shouldScrollToRef.setFalse();
  }, [shouldScrollToRef, options]);

  return [ref, shouldScrollToRef.setTrue];
};

export default useScrollTo;
