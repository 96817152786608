/* eslint-disable no-nested-ternary */
import { get, isEmpty } from 'lodash';
import type { ReactNode } from 'react';
import { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import Modal from 'storybook/stories/cells/Modal';

import Message, { useMessage } from 'components/Common/Message';
import Spinner from 'components/Common/Spinner';
import { BuyerListItem, PartnerNameHeading } from 'containers/PriceListPage/Settings/Shared.styles';
import useDebounceValue from 'hooks/useDebounceValue';
import type { Partner } from 'types/models/partner';
import type { PriceList } from 'types/models/price-list';
import type { UpdatePartnerParams } from 'utils/api/partners';
import { updatePartner } from 'utils/api/partners';

import useAllPartners from 'hooks/useAllPartners';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import StatusIcon from 'storybook/stories/molecules/Icon/StatusIcon';

export interface AssignPriceListModalProps {
  show: boolean;
  priceList: PriceList;
  onPartnerUpdated: (id: string, body: UpdatePartnerParams) => void;
  onDismiss: () => void;
}

interface SimpleScrollProps {
  children: ReactNode;
}

export const SimpleScroll = forwardRef<HTMLDivElement, SimpleScrollProps>(
  ({ children, ...rest }, ref) => {
    return (
      <SimpleBar
        {...rest}
        scrollableNodeProps={{ ref }}
        style={{
          minHeight: '12rem',
          maxHeight: '20rem',
          paddingBottom: '1rem',
          paddingTop: '1rem',
        }}
      >
        {children}
      </SimpleBar>
    );
  }
);

const AssignPriceListModal = ({
  onDismiss,
  show,
  priceList,
  onPartnerUpdated,
}: AssignPriceListModalProps) => {
  const [search, setSearch] = useState('');
  const query = useDebounceValue(search, 400);
  const [filteredList, setFilteredList] = useState<Partner[]>([]);
  const { showErrorMessage, messageState, hideMessage } = useMessage();
  const [confirmReassignPartner, setConfirmReassignPartner] = useState<Partner | null>();

  const { partners, isLoading, refetch } = useAllPartners();

  useEffect(() => {
    setFilteredList((list) => {
      if (!query) return partners;
      const regex = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
      return list.filter((item) =>
        ['buyerName', 'buyerCompanyId'].some((keyPath) => regex.test(get(item, keyPath)))
      );
    });
  }, [partners, query]);

  const assignList = (partner: Partner) => {
    const body = {
      pricingId: priceList.id,
    };
    updatePartner(partner._id, body)
      .then(() => {
        onPartnerUpdated(partner._id, body);
        setConfirmReassignPartner(null);
        refetch();
      })
      .catch((err) => {
        showErrorMessage({ title: 'Error assigning price list', message: err.message });
      });
  };

  const onAssign = (partner: Partner) => () => {
    if (partner.pricingId) {
      setConfirmReassignPartner(partner);
      return;
    }
    assignList(partner);
  };

  const onUnassign = (partner: Partner) => () => {
    const body = { pricingId: '' };
    updatePartner(partner._id, body)
      .then(() => {
        onPartnerUpdated(partner._id, body);
        refetch();
      })
      .catch((err) => {
        showErrorMessage({ title: 'Error unassigning price list', message: err.message });
      });
  };

  const onCloseModal = () => {
    onDismiss();
    setConfirmReassignPartner(null);
  };

  return (
    <Modal.Root
      show={show}
      aria-labelledby="assign-price-list-modal"
      centered
      onHide={onCloseModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign Price List to Buyer</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Message
          show={messageState.show}
          onClose={hideMessage}
          kind={messageState.kind}
          className="m3"
        >
          <p className="m-0 mb-1">
            <strong>{messageState.title}</strong>
          </p>
          <p className="m-0">{messageState.message}</p>
        </Message>

        {isLoading ? (
          <div className="card-body">
            <div className="d-flex align-items-center mt-4 mb-4">
              <Spinner color="secondary" small />
              <p className="small text-muted mb-0 ml-3">Loading Partners</p>
            </div>
          </div>
        ) : partners?.length === 0 ? (
          <>
            <h4 className="mb-3">You don&apos;t have any partners yet</h4>
            <p className="mb-4">
              You have to invite a partner before you can assign this price list
            </p>
            <div className="d-flex justify-content-center mt-2">
              <Link to="/partners" className="btn btn-sm btn-primary">
                Go To Partners
              </Link>
            </div>
          </>
        ) : !isEmpty(confirmReassignPartner) ? (
          <div className="card-body">
            <h1 className="mb-4">Are you sure?</h1>
            <p className="mb-4">
              {confirmReassignPartner.buyerName || confirmReassignPartner.buyerCompanyId} already
              has another price list assigned to them. Are you sure you want to assign{' '}
              <strong>{priceList.name ? priceList.name : 'this price list'}</strong> to them
              instead?
            </p>
            <div className="d-flex justify-content-end mt-2">
              <SecondaryButton onClick={() => setConfirmReassignPartner(null)}>
                Cancel
              </SecondaryButton>

              <PrimaryButton onClick={() => assignList(confirmReassignPartner)}>
                Yes, Assign List
              </PrimaryButton>
            </div>
          </div>
        ) : (
          <>
            <div
              className="input-group input-group-flush py-0 px-4"
              style={{
                borderBottom: '1px solid #edf2f9',
                borderTop: messageState.show ? '1px solid #edf2f9' : 'none',
              }}
            >
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <Icon name="search" />
                </span>
              </div>
              <input
                className="form-control list-search'"
                placeholder="Search Buyers"
                onChange={({ target: { value } }) => {
                  setSearch(value);
                }}
              />
            </div>
            <SimpleScroll>
              {filteredList.map((partner: Partner) => {
                const partnerName = partner.buyerName || partner.buyerCompanyId;
                return (
                  <BuyerListItem key={partner._id}>
                    <div>
                      <PartnerNameHeading variant="Headings/H3" as="h1">
                        {partnerName}
                      </PartnerNameHeading>
                      {partner.pricingId ? (
                        <StatusIcon variant="checked">{priceList.name}</StatusIcon>
                      ) : (
                        <StatusIcon variant="unchecked">No Assigned List</StatusIcon>
                      )}
                    </div>

                    <div className="d-flex justify-content-end">
                      {partner.pricingId === priceList.id ? (
                        <SecondaryButton
                          onClick={onUnassign(partner)}
                          data-testid={`unassign-button-${partner._id}`}
                        >
                          Unassign
                        </SecondaryButton>
                      ) : (
                        <SecondaryButton
                          onClick={onAssign(partner)}
                          data-testid={`assign-button-${partner._id}`}
                        >
                          Assign
                        </SecondaryButton>
                      )}
                    </div>
                  </BuyerListItem>
                );
              })}
            </SimpleScroll>
          </>
        )}
      </Modal.Body>
    </Modal.Root>
  );
};

export default AssignPriceListModal;
