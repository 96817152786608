import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import Alert from 'storybook/stories/cells/Alert';
import Modal from 'storybook/stories/cells/Modal';
import styled from 'styled-components';

import { useForm } from 'react-hook-form';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import type { SellerProduct } from 'types/models/seller-product';
import type { CreatePriceListEntryParams } from 'utils/api/priceLists';
import { createPriceListEntry } from 'utils/api/priceLists';
import { toMoney } from 'utils/currencies';

interface SelectVariantModalProps {
  selectedProduct: SellerProduct;
  onHide: () => void;
  onSuccessCallback: (variantId: string) => void;

  priceListId: string;
  sellerCurrency: string;
}

type VariantPriceFormValues = {
  sellerRetailPrice: number;
  dropshipPrice: number;
};

const PriceEntryHeading = styled.h2`
  margin: 24px 0;
`;

const SelectVariantModal = ({
  selectedProduct,
  onHide,
  onSuccessCallback,
  priceListId,
  sellerCurrency,
}: SelectVariantModalProps) => {
  const [selectedVariantId, setSelectedVariantId] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Helpers

  const selectedVariant = useMemo(
    () => selectedProduct?.variants?.find((variant) => selectedVariantId === variant._id),
    [selectedVariantId, selectedProduct.variants]
  );

  const variantSelectOptions = useMemo(() => {
    if (isEmpty(selectedProduct)) return [];

    return selectedProduct.variants.map((variant) => {
      const variantOptions = [variant.option1, variant.option2, variant.option3].filter(
        (option) => !isEmpty(option)
      );

      const label = `[${variant.sku}] ${variantOptions.join(', ')}`;
      return { value: variant._id, label };
    });
  }, [selectedProduct]);

  const initialPricingFormValues: VariantPriceFormValues = {
    sellerRetailPrice: selectedVariant?.retailPrice ?? 0,
    dropshipPrice: 0,
  };

  // Event Handlers

  const handlePricingFormSubmit = async (values: VariantPriceFormValues) => {
    const params: CreatePriceListEntryParams = {
      variantId: selectedVariantId,
      sellerRetailPrice: toMoney(values.sellerRetailPrice, sellerCurrency),
      dropshipPrice: toMoney(values.dropshipPrice, sellerCurrency),
    };

    setSuccessMessage('');
    setErrorMessage('');

    createPriceListEntry(priceListId, params)
      .then((response) => {
        onSuccessCallback(response.data.variantId);
        setSuccessMessage('Variant has been priced successfully');
      })
      .catch((error) => {
        setErrorMessage(
          'Error saving price entry: This variant has already been assigned a price.'
        );
        console.error('Unable to save price list entry', error.message);
      });
  };

  const { handleSubmit, register, formState } = useForm<VariantPriceFormValues>({
    mode: 'onChange',
    defaultValues: initialPricingFormValues,
  });

  // Render

  return (
    <Modal.Root show onHide={onHide} data-testid="select-variant-modal">
      <form onSubmit={handleSubmit(handlePricingFormSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Price List Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label htmlFor="variant-select">Select a Variant</Form.Label>
            <Select
              inputId="variant-select"
              options={variantSelectOptions}
              onChange={(option) => {
                if (option?.value) setSelectedVariantId(option.value);
                setSuccessMessage('');
                setErrorMessage('');
              }}
            />
          </Form.Group>

          {successMessage && <Alert type="success">{successMessage}</Alert>}
          {errorMessage && <Alert type="error">{errorMessage}</Alert>}

          {selectedVariantId && (
            <>
              <PriceEntryHeading>Add Price Entry for {selectedVariant?.sku}</PriceEntryHeading>

              <Form.Group controlId="sellerRetailPrice">
                <Form.Label>Your Retail Price ({sellerCurrency})</Form.Label>
                <Form.Control
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('sellerRetailPrice')}
                  type="number"
                  step="0.01"
                  data-testid="seller-retail-price-input"
                  min="0.01"
                  placeholder={initialPricingFormValues.sellerRetailPrice.toString()}
                />
              </Form.Group>

              <PriceEntryHeading>Dropship Pricing</PriceEntryHeading>

              <Form.Group controlId="dropshipPrice">
                <Form.Label>Dropship Price ({sellerCurrency})</Form.Label>
                <Form.Control
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('dropshipPrice')}
                  type="number"
                  step="0.01"
                  data-testid="dropship-price-input"
                  min="0.01"
                  placeholder={initialPricingFormValues.dropshipPrice.toString()}
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton type="submit" disabled={formState.isSubmitting || !selectedVariantId}>
            Save Price Entry
          </PrimaryButton>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default SelectVariantModal;
