const fetchGoogleTaxonomy = () =>
  window
    .fetch('/json/googleTaxonomy.en-US.json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.json());

export default fetchGoogleTaxonomy;
