import Flex from '@react-css/flex';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { cloneDeep, isEqual, sortBy, values } from 'lodash';
import { useEffect, useState } from 'react';
import type { Control } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import type { MultiValue } from 'react-select';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import useAlertQueue from 'hooks/useAlertQueue';
import Alert from 'storybook/stories/cells/Alert';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Checkbox from 'storybook/stories/molecules/Checkbox';
import Divider from 'storybook/stories/molecules/Divider';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import type {
  FieldValidation,
  FieldValidationRules,
  FieldValidationRulesUpdate,
  ProductValidationRules,
} from 'types/models/buyer-product-validation';
import {
  createProductValidationRules,
  getProductValidationRules,
  updateProductValidationRules,
} from 'utils/api/buyer/products';

import Modal from 'storybook/stories/cells/Modal';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import AdvancedRules from './AdvancedRules';
import type { Option } from './constants';
import { REACT_SELECT_CUSTOM_STYLES, ValidationTypes } from './constants';
import WrapperButton from './index.shared';

const OpenModalText = styled.p`
  display: contents;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;

  ${({ theme }) => css`
    color: ${theme.color.white};
    font-family: ${theme.font.secondary};
  `};
`;

interface RightAlignedWrapperProps {
  hasDottedLine?: boolean;
}
const RightAlignedWrapper = styled.div<RightAlignedWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 8px;
  gap: 4px;

  // dotted line
  ${({ theme, hasDottedLine }) =>
    hasDottedLine &&
    css`
      border-top: 1px dashed ${theme.color.gray200};
    `}
`;

const ClickableText = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  display: inline;

  ${({ theme }) => css`
    color: ${theme.color.blue600};
    font-family: ${theme.font.secondary};
  `};
`;

const InlineText = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: inline;
  margin-right: 4px;
  gap: 8px;

  ${({ theme }) => css`
    color: ${theme.color.gray700};
  `};
`;

const IconWrap = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-table;
  text-align: center;
  margin-right: 12px;
  ${({ theme }) => css`
    background: ${theme.color.blue500};
    color: ${theme.color.white};
  `};
`;

const AddIcon = styled(Icon).attrs({ name: 'add', size: '25px', weight: 400 })`
  vertical-align: middle;
`;

const BARCODE_TYPE_OPTIONS = [
  {
    label: 'UPC',
    value: 'upc',
  },
  {
    label: 'GTIN-13',
    value: 'gtin-13',
  },
  {
    label: 'GTIN-14',
    value: 'gtin-14',
  },
  {
    label: 'EAN-8',
    value: 'ean-8',
  },
];

const DIMENSION_UNITS_OPTIONS = [
  {
    label: 'cm',
    value: 'cm',
  },
  {
    label: 'in',
    value: 'in',
  },
];

const WEIGHT_UNITS_OPTIONS = [
  {
    label: 'g',
    value: 'g',
  },
  {
    label: 'kg',
    value: 'kg',
  },
  {
    label: 'lb',
    value: 'lb',
  },
  {
    label: 'oz',
    value: 'oz',
  },
  {
    label: 't',
    value: 't',
  },
];

const GENERAL_STRINGS_REQUIRED = [
  'title',
  'variantSku',
  'description',
  'variantBarcode',
  'brand',
  'variantBarcodeType',
  'tags',
];
const GENERAL_NUMBERS_REQUIRED = ['variantInventoryAmount', 'variantRetailPrice'];

const getSelectOneOfOptionData = (options: Option[], selectedOneOfValues: string[]): Option[] => {
  if (!selectedOneOfValues) {
    return [];
  }

  const oneOfOptionData: Option[] = [];
  options.forEach((option: Option) => {
    selectedOneOfValues.forEach((oneOfValue: string) => {
      if (option.value === oneOfValue) {
        oneOfOptionData.push(option);
      }
    });
  });

  return oneOfOptionData;
};

const getOneOfCondition = (
  productValidations: ProductValidationRules | undefined,
  field: string
): string[] => {
  let oneOfCondition: string[] = [];
  if (productValidations) {
    productValidations?.productValidationRules.forEach((fieldValidations: FieldValidationRules) => {
      if (field === fieldValidations.field) {
        fieldValidations.rules.forEach((rule: FieldValidation) => {
          if (rule.validation === ValidationTypes.OneOf) {
            oneOfCondition = rule.condition;
          }
        });
      }
    });
  }
  return oneOfCondition;
};

export const convertToAPIName = (field: string): string => {
  if (field.startsWith('variantDimension')) {
    return `variant.dimensions.${field[16].toLowerCase()}${field.substring(17)}`;
  }
  if (field.startsWith('variant')) {
    return `variant.${field[7].toLowerCase()}${field.substring(8)}`;
  }
  return field;
};

const generateDropdownOneOfRequirements = (
  field: string,
  selectedOptions: MultiValue<Option>
): FieldValidationRules | null => {
  if (selectedOptions.length === 0) {
    return null;
  }
  return {
    field: convertToAPIName(field),
    rules: [
      {
        validation: ValidationTypes.OneOf,
        condition: selectedOptions.map((inputData) => inputData.value),
      },
    ],
  };
};

const generateGeneralRequirements = (
  field: string,
  isChecked: boolean
): FieldValidationRules | null => {
  if (!isChecked) {
    return null;
  }
  const apiFieldName = convertToAPIName(field);
  if (GENERAL_STRINGS_REQUIRED.includes(field)) {
    return {
      field: apiFieldName,
      rules: [
        {
          validation: ValidationTypes.Required,
          condition: true,
        },
      ],
    };
  }
  if (GENERAL_NUMBERS_REQUIRED.includes(field)) {
    return {
      field: apiFieldName,
      rules: [
        {
          validation: ValidationTypes.Required,
          condition: true,
        },
      ],
    };
  }
  if (field === 'images') {
    return {
      field: apiFieldName,
      rules: [
        {
          validation: ValidationTypes.Min,
          condition: 1,
        },
      ],
    };
  }
  if (field === 'googleProductCategory') {
    return {
      field: apiFieldName,
      rules: [
        {
          validation: ValidationTypes.Required,
          condition: true,
        },
      ],
    };
  }
  if (field === 'units') {
    return {
      field: 'variant.units',
      rules: [
        {
          validation: ValidationTypes.Required,
          condition: true,
        },
      ],
    };
  }

  // unsupported field
  return null;
};

interface GeneralRequirementsProps {
  field: string;
  displayName: string;
  control: Control<Record<string, any>>;
  wrapperSize?: string;
  className?: string;
  defaultChecked?: boolean;
}
const GeneralRequirement = ({
  field: fieldName,
  displayName,
  control,
  wrapperSize,
  className,
  defaultChecked,
}: GeneralRequirementsProps) => {
  return (
    <div className={wrapperSize || 'col'}>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={null}
        render={({ field }) => (
          <Checkbox
            name={field.name}
            onChange={(e: { target: { checked: boolean } }) => {
              field.onChange(generateGeneralRequirements(field.name, e.target.checked));
            }}
            className={className}
            defaultChecked={defaultChecked || undefined}
          >
            {displayName}
          </Checkbox>
        )}
      />
    </div>
  );
};

export const convertToFormName = (field: string): string => {
  if (field.startsWith('variant.')) {
    return `variant${field[8].toUpperCase()}${field.substring(9)}`;
  }
  return field;
};

const ONE_OF_GENERAL_REQUIREMENTS = [
  'variant.dimensions.units',
  'variant.weightUnits',
  'variant.barcodeType',
];

const UNITS_GENERAL_REQUIREMENTS = [
  'variant.dimensions.units',
  'variant.dimensions.length',
  'variant.dimensions.width',
  'variant.dimensions.height',
  'variant.weightUnits',
  'variant.weight',
];

export type GeneralRules = {
  title?: FieldValidationRules;
  brand?: FieldValidationRules;
  description?: FieldValidationRules;
  googleProductCategory?: FieldValidationRules;
  images?: FieldValidationRules;
  tags?: FieldValidationRules;
  units?: FieldValidationRules;
  variantBarcode?: FieldValidationRules;
  variantBarcodeType?: FieldValidationRules;
  variantBarcodeTypeOneOf?: FieldValidationRules;
  variantDimensionsUnitsOneOf?: FieldValidationRules;
  variantInventoryAmount?: FieldValidationRules;
  variantRetailPrice?: FieldValidationRules;
  variantSku?: FieldValidationRules;
  variantWeightUnitsOneOf?: FieldValidationRules;
};

export const splitGeneralRules = (validationRules: ProductValidationRules): GeneralRules => {
  const buildDefaultGeneralRules: GeneralRules = {};

  validationRules.productValidationRules.forEach(
    (validationRule: { field: string; rules: FieldValidation[] }) => {
      validationRule.rules.forEach((fieldValidation: FieldValidation) => {
        // special condition units requiredness
        if (
          fieldValidation.validation === ValidationTypes.Required &&
          UNITS_GENERAL_REQUIREMENTS.includes(validationRule.field)
        ) {
          buildDefaultGeneralRules.units = {
            field: 'variant.units',
            rules: [
              {
                validation: ValidationTypes.Required,
                condition: true,
              },
            ],
          };
          return;
        }

        // general requiredness conditions
        if (fieldValidation.validation === ValidationTypes.Required) {
          buildDefaultGeneralRules[convertToFormName(validationRule.field) as keyof GeneralRules] =
            {
              field: validationRule.field,
              rules: [
                {
                  validation: ValidationTypes.Required,
                  condition: true,
                },
              ],
            };
          return;
        }

        // images validation
        if (
          fieldValidation.validation === ValidationTypes.Min &&
          validationRule.field === 'images'
        ) {
          buildDefaultGeneralRules.images = {
            field: validationRule.field,
            rules: [
              {
                validation: ValidationTypes.Min,
                condition: 1,
              },
            ],
          };
          return;
        }

        // oneOf for barcodeType, weighUnits or dimensionUnits
        if (
          fieldValidation.validation === ValidationTypes.OneOf &&
          ONE_OF_GENERAL_REQUIREMENTS.includes(validationRule.field)
        ) {
          const oneOfRule = {
            field: validationRule.field,
            rules: [
              {
                validation: ValidationTypes.OneOf,
                condition: fieldValidation.condition,
              },
            ],
          };
          switch (validationRule.field) {
            case 'variant.dimensions.units':
              buildDefaultGeneralRules.variantDimensionsUnitsOneOf = oneOfRule;
              break;
            case 'variant.weightUnits':
              buildDefaultGeneralRules.variantWeightUnitsOneOf = oneOfRule;
              break;
            case 'variant.barcodeType':
              buildDefaultGeneralRules.variantBarcodeTypeOneOf = oneOfRule;
              break;
            default: // do nothing, not supported...
          }
        }
      });
    }
  );
  return buildDefaultGeneralRules;
};

const isGeneralRequirement = (field: string, validation: string, condition: any): boolean => {
  // if general requiredness
  if (validation === ValidationTypes.Required) {
    return true;
  }

  // if general image requiredness
  if (field === 'images' && validation === ValidationTypes.Min && condition === 1) {
    return true;
  }

  // if general oneOf condition
  if (validation === ValidationTypes.OneOf && ONE_OF_GENERAL_REQUIREMENTS.includes(field)) {
    return true;
  }

  return false;
};

export const splitAdvancedRules = (
  validationRules: ProductValidationRules
): [string[], Record<string, FieldValidationRules>] => {
  const advancedRuleKeys: string[] = [];
  const advancedRules: Record<string, FieldValidationRules> = {};

  validationRules.productValidationRules.forEach(
    (validationRule: { field: string; rules: FieldValidation[] }) => {
      validationRule.rules.forEach((fieldValidation: FieldValidation) => {
        // skip if rule already included as part of general requirements to avoid duplication
        if (
          isGeneralRequirement(
            validationRule.field,
            fieldValidation.validation,
            fieldValidation.condition
          )
        ) {
          return;
        }
        // set key for reference to this particular rule
        const advancedRuleKey = uuidv4();
        advancedRuleKeys.push(advancedRuleKey);

        // set advanced rules to be the individual field validation since each AdvancedRules component can only hold one validaiton rule
        advancedRules[advancedRuleKey] = {
          field: validationRule.field,
          rules: [fieldValidation],
        };
      });
    }
  );

  return [advancedRuleKeys, advancedRules];
};

const BuyerProductValidation = () => {
  // React Hook Form
  const { handleSubmit, control, reset } = useForm();

  // Alerts
  interface ErrorAlertProps {
    title: string;
    body: string;
  }
  const [submitRulesError, setSubmitRulesError] = useState<ErrorAlertProps>();
  const [fetchRulesError, setFetchRulesError] = useState<ErrorAlertProps>();

  const { addSuccessAlert } = useAlertQueue();

  const closeSubmitAlert = () => {
    setSubmitRulesError(undefined);
  };

  const closeFetchAlert = () => {
    setFetchRulesError(undefined);
  };

  const QUERY_KEY = 'getBuyerProductValidationRules';
  const queryClient = useQueryClient();

  // Fetch product validations if they exists whenever the self serve UI is made visible
  const [existingValidationRules, setExistingValidationRules] = useState<ProductValidationRules>();
  const [shouldUpdate, setShouldUpdate] = useState(false);

  type ErrorProp = {
    name: string;
    code: number;
    authErr: string;
    errors: { [message: string]: string }[];
  };
  const { data: dataResponse } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => getProductValidationRules(),
    onSuccess: (response) => {
      if (response.data) {
        setExistingValidationRules(response.data[0]);
        setShouldUpdate(true);
      }
    },
    onError: (error: ErrorProp) => {
      if (error.code !== 404) {
        setFetchRulesError({
          title: 'Error: Failed checking for existing rules',
          body: 'Please refresh the page and try again. If the issue persists, please contact support@convictional.com',
        });
      }
    },
  });

  // Product Validation default states, to be populated if rules already exists
  const [defaultRules, setDefaultRules] = useState<GeneralRules>({});

  // advancedRuleKeys is used as keys for AdvancedRules components
  const [advancedRuleKeys, setAdvancedRuleKeys] = useState<string[]>([]);
  const addRule = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setAdvancedRuleKeys((prevArray) => [...prevArray, uuidv4()]);
  };

  // advancedRules stores all the user inputted advanced rules
  const [advancedRules, setAdvancedRules] = useState<{ [key: string]: FieldValidationRules }>({});

  // set existing validation rules as default values (if rules already exists)
  useEffect(() => {
    if (existingValidationRules) {
      // set the React Hook Form data (general rules)
      setDefaultRules(splitGeneralRules(existingValidationRules));
      reset(splitGeneralRules(existingValidationRules));

      // set the advanced rules data
      const [existingAdvancedRuleKeys, existingAdvancedRules] =
        splitAdvancedRules(existingValidationRules);
      setAdvancedRuleKeys(existingAdvancedRuleKeys);
      setAdvancedRules(existingAdvancedRules);
    }
  }, [existingValidationRules, reset]);

  const defaultBarcodes = getSelectOneOfOptionData(
    BARCODE_TYPE_OPTIONS,
    getOneOfCondition(dataResponse?.data[0], 'variant.barcodeType')
  );

  const defaultWeightUnits = getSelectOneOfOptionData(
    WEIGHT_UNITS_OPTIONS,
    getOneOfCondition(dataResponse?.data[0], 'variant.weightUnits')
  );

  const defaultDimensionUnits = getSelectOneOfOptionData(
    DIMENSION_UNITS_OPTIONS,
    getOneOfCondition(dataResponse?.data[0], 'variant.dimensions.units')
  );

  // Modal states
  const [shouldShowProductValidationModal, setShouldShowProductValidationModal] = useState(false);

  const showModal = () => {
    setShouldShowProductValidationModal(true);
    queryClient.invalidateQueries([QUERY_KEY]);
  };
  const hideModal = () => {
    setShouldShowProductValidationModal(false);
  };

  type UpdatingRulesMutationArgs = {
    ruleId: string;
    productValidationRules: FieldValidationRulesUpdate[];
  };

  const updatingRules = useMutation({
    mutationFn: ({ ruleId, productValidationRules }: UpdatingRulesMutationArgs) =>
      updateProductValidationRules(ruleId, {
        productValidationRules,
      }),
    onSuccess: () => {
      addSuccessAlert(
        'Update Successful',
        'Your Product Validation Rules have been successfully updated'
      );
      hideModal();
      queryClient.invalidateQueries([QUERY_KEY]);
      updatingRules.reset();
    },
    onError: (error: any) => {
      setSubmitRulesError({
        title: 'Unable to update Product Validation Rules',
        body: error.message,
      });
    },
  });

  type CreatingRulesMutationArgs = {
    productValidationRules: FieldValidationRules[];
  };

  const creatingRules = useMutation({
    mutationFn: ({ productValidationRules }: CreatingRulesMutationArgs) =>
      createProductValidationRules({
        productValidationRules,
      }),
    onSuccess: () => {
      addSuccessAlert(
        'Creation Successful',
        'Your Product Validation Rules have been successfully created'
      );

      hideModal();
      queryClient.invalidateQueries([QUERY_KEY]);
      creatingRules.reset();
    },
    onError: (error: any) => {
      setSubmitRulesError({
        title: 'Unable to create Product Validation Rules',
        body: error.message,
      });
    },
  });

  // Helpers
  const isLoading = creatingRules.isLoading || updatingRules.isLoading;
  const isSuccessful = creatingRules.isSuccess || updatingRules.isSuccess;

  // onCreateRule creates new advanced validation rules
  const onCreateRule = (
    advancedRuleKey: string,
    field: string,
    validation: string,
    condition: any
  ) => {
    const newRule = {
      field,
      rules: [
        {
          validation,
          condition,
        },
      ],
    };

    setAdvancedRules((current) => {
      return {
        ...current,
        [advancedRuleKey]: newRule,
      };
    });
  };

  // onResetRule removes existing advanced rules when they've been updated
  const onResetRule = (key: string) => {
    setAdvancedRules((current) => {
      const copy = { ...current };
      delete copy[key];
      return copy;
    });
  };

  const onDeleteRule = (key: string, e: { preventDefault: () => void }) => {
    e.preventDefault();

    // delete the key from advancedRuleKeys to remove the associated component
    setAdvancedRuleKeys((current: string[]) => {
      const temp = [...current];
      const idx = current.indexOf(key);
      temp.splice(idx, 1);
      return temp;
    });

    // delete the rule from advancedRules
    setAdvancedRules((current) => {
      const copy = { ...current };
      delete copy[key];
      return copy;
    });
  };

  const validateAdvancedRules = (): boolean => {
    if (advancedRuleKeys.length !== Object.keys(advancedRules).length) {
      setSubmitRulesError({
        title: 'Incomplete Advanced Rules',
        body: 'Complete or delete incomplete advanced rules before submitting',
      });
      return false;
    }
    return true;
  };

  // convertUnitRules converts the units general requirement into the set of associated dimension and weight unit rules.
  // The set of dimension rules include requiredness for:
  // 1. variant.dimensions.units, 2. variant.dimensions.length, 3. variant.dimensions.width, 4. variant.dimensions.height
  // The set of weight rules includes requiredness for:
  // 1. variant.weightUnits, 2. variant.weight
  const convertUnitRules = (generalRules: any): Record<string, FieldValidationRules> => {
    const updatedGeneralRules = { ...generalRules };
    if (generalRules.units) {
      delete updatedGeneralRules.units;

      // Dimensions
      updatedGeneralRules.variantDimensionsUnits = {
        field: 'variant.dimensions.units',
        rules: [
          {
            validation: ValidationTypes.Required,
            condition: true,
          },
        ],
      };
      updatedGeneralRules.variantDimensionsLength = {
        field: 'variant.dimensions.length',
        rules: [
          {
            validation: ValidationTypes.Required,
            condition: true,
          },
        ],
      };
      updatedGeneralRules.variantDimensionsWidth = {
        field: 'variant.dimensions.width',
        rules: [
          {
            validation: ValidationTypes.Required,
            condition: true,
          },
        ],
      };
      updatedGeneralRules.variantDimensionsHeight = {
        field: 'variant.dimensions.height',
        rules: [
          {
            validation: ValidationTypes.Required,
            condition: true,
          },
        ],
      };

      // Weight
      updatedGeneralRules.variantWeightUnits = {
        field: 'variant.weightUnits',
        rules: [
          {
            validation: ValidationTypes.Required,
            condition: true,
          },
        ],
      };
      updatedGeneralRules.variantWeight = {
        field: 'variant.weight',
        rules: [
          {
            validation: ValidationTypes.Required,
            condition: true,
          },
        ],
      };
    }
    return updatedGeneralRules;
  };

  // transformGeneralRules performs additional rule transformations to better format the request data
  // currently used to create multiple rules for general rules units requirements (dimensions and weight)
  const transformGeneralRules = (generalRules: any): Record<string, FieldValidationRules> => {
    return convertUnitRules(generalRules);
  };

  const computeUpdates = (
    existingRules: FieldValidationRules[],
    submittedRules: FieldValidationRules[]
  ): FieldValidationRulesUpdate[] => {
    const updatedRules: FieldValidationRulesUpdate[] = [];

    // compute rules to be deleted
    existingRules.forEach((existingFieldValidationRule: FieldValidationRules) => {
      let foundRule = false;
      submittedRules.forEach((submittedFieldValidationRule: FieldValidationRules) => {
        if (existingFieldValidationRule.field === submittedFieldValidationRule.field) {
          foundRule = true;
        }
      });
      if (!foundRule) {
        const toDelete: FieldValidationRulesUpdate = {
          field: existingFieldValidationRule.field,
          rules: null,
        };
        updatedRules.push(toDelete);
      }
    });

    // compute rules to be updated or created
    submittedRules.forEach((submittedFieldValidationRule: FieldValidationRules) => {
      let foundRule = false;
      existingRules.forEach((existingFieldValidationRule: FieldValidationRules) => {
        if (existingFieldValidationRule.field === submittedFieldValidationRule.field) {
          foundRule = true;
          // compute updates that have occured to existing rules
          if (
            !isEqual(
              sortBy(existingFieldValidationRule.rules, ['condition', 'validation']),
              sortBy(submittedFieldValidationRule.rules, ['condition', 'validation'])
            )
          ) {
            updatedRules.push(submittedFieldValidationRule);
          }
        }
      });
      // if rule was not found in existing rules, it is net new and needs to be created
      if (!foundRule) {
        updatedRules.push(submittedFieldValidationRule);
      }
    });
    return updatedRules;
  };

  const submitRules = (generalRules: any) => {
    if (!validateAdvancedRules()) {
      return;
    }
    const generalRequirements = values(transformGeneralRules(cloneDeep(generalRules)));
    const advancedRulesValues = values(cloneDeep(advancedRules));

    const groupedFieldRules = generalRequirements.concat(advancedRulesValues).reduce(
      (accumulator: { [name: string]: FieldValidationRules }, rule: FieldValidationRules) => {
        if (!rule) {
          return accumulator;
        }
        if (rule.field in accumulator) {
          accumulator[rule.field].rules.push(...rule.rules);
        } else {
          accumulator[rule.field] = rule;
        }
        return accumulator;
      },
      {} as { [name: string]: FieldValidationRules }
    );

    const submittedProductValidationRules = values(groupedFieldRules).reduce(
      (accumulator: FieldValidationRules[], rule: FieldValidationRules) => {
        if (rule) accumulator.push(rule);
        return accumulator;
      },
      [] as FieldValidationRules[]
    );

    if (shouldUpdate && existingValidationRules) {
      const updateRules = computeUpdates(
        existingValidationRules.productValidationRules,
        submittedProductValidationRules
      );

      const ruleId = existingValidationRules.id;
      updatingRules.mutate({
        ruleId,
        productValidationRules: updateRules,
      });
    } else {
      creatingRules.mutate({
        productValidationRules: submittedProductValidationRules,
      });
    }
  };

  return (
    <>
      <PrimaryButton
        size="small"
        id="editProductValidationRulesTrigger"
        className="btn-import ml-2"
        onClick={showModal}
        aria-label="open product validation modal"
      >
        <Icon name="rule" style={{ verticalAlign: 'middle' }}>
          <OpenModalText>
            {shouldUpdate ? 'Update Product Validation Rules' : 'Set Product Validation Rules'}
          </OpenModalText>
        </Icon>
      </PrimaryButton>

      <Modal.Root
        show={shouldShowProductValidationModal}
        size="xl"
        aria-label="product validation modal"
        centered
        onHide={hideModal}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Validation Rules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="container-fluid mt-3">
              {fetchRulesError && (
                <Alert
                  type="error"
                  className="mb-3"
                  title={fetchRulesError.title}
                  onClose={closeFetchAlert}
                >
                  {fetchRulesError.body}
                </Alert>
              )}

              <h4>General Product Requirements</h4>
              <p>
                Select all the required data. You can find our guide{' '}
                <SupportLink article="1ji4dm8o8h">here</SupportLink>.
              </p>
              <div className="row mt-5">
                <GeneralRequirement
                  field="title"
                  displayName="Product Title"
                  control={control}
                  defaultChecked={defaultRules?.title !== undefined}
                />
                <GeneralRequirement
                  field="variantSku"
                  displayName="SKU"
                  control={control}
                  defaultChecked={defaultRules?.variantSku !== undefined}
                />
              </div>
              <div className="row mt-3">
                <GeneralRequirement
                  field="description"
                  displayName="Product Description"
                  control={control}
                  defaultChecked={defaultRules?.description !== undefined}
                />
                <GeneralRequirement
                  field="variantInventoryAmount"
                  displayName="Inventory"
                  control={control}
                  defaultChecked={defaultRules?.variantInventoryAmount !== undefined}
                />
              </div>
              <div className="collapse show" id="allGeneralAttributes" aria-expanded="false">
                <div className="row mt-3">
                  <GeneralRequirement
                    field="images"
                    displayName="Product Images"
                    control={control}
                    defaultChecked={defaultRules?.images !== undefined}
                  />
                  <GeneralRequirement
                    field="variantRetailPrice"
                    displayName="Suggested Retail Price"
                    control={control}
                    defaultChecked={defaultRules?.variantRetailPrice !== undefined}
                  />
                </div>
                <div className="row mt-3">
                  <GeneralRequirement
                    field="googleProductCategory"
                    displayName="Google Product Category"
                    control={control}
                    defaultChecked={defaultRules?.googleProductCategory !== undefined}
                  />
                  <GeneralRequirement
                    field="variantBarcode"
                    displayName="Barcode"
                    control={control}
                    defaultChecked={defaultRules?.variantBarcode !== undefined}
                  />
                </div>
                <div className="row mt-3">
                  <GeneralRequirement
                    field="brand"
                    displayName="Brand"
                    control={control}
                    defaultChecked={defaultRules?.brand !== undefined}
                  />
                  <GeneralRequirement
                    field="tags"
                    displayName="Tags"
                    control={control}
                    defaultChecked={defaultRules?.tags !== undefined}
                  />
                </div>
              </div>
            </div>

            <Divider className="mt-5 mb-5" />

            <div className="container-fluid">
              <div className="row" style={{ alignItems: 'end' }}>
                <GeneralRequirement
                  field="variantBarcodeType"
                  displayName="Barcode Type:"
                  control={control}
                  wrapperSize="col-sm-2"
                  className="font-weight-bold"
                  defaultChecked={defaultRules?.variantBarcodeType !== undefined}
                />
                <div className="col-sm-6">
                  <div style={{ display: 'inline-block' }}>
                    <Controller
                      control={control}
                      name="variantBarcodeTypeOneOf"
                      defaultValue={null}
                      render={({ field }) => (
                        <Select
                          ref={field.ref}
                          options={BARCODE_TYPE_OPTIONS}
                          defaultValue={defaultBarcodes}
                          isClearable
                          isMulti
                          placeholder="Barcode Types"
                          aria-label="select barcode types"
                          onChange={(multivalues) => {
                            field.onChange(
                              generateDropdownOneOfRequirements('variantBarcodeType', multivalues)
                            );
                          }}
                          styles={REACT_SELECT_CUSTOM_STYLES}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-4" style={{ alignItems: 'end' }}>
                <GeneralRequirement
                  field="units"
                  displayName="Units:"
                  control={control}
                  wrapperSize="col-sm-2"
                  className="font-weight-bold"
                  defaultChecked={defaultRules?.units !== undefined}
                />
                <div className="col-sm-4">
                  <InlineText>Dimension</InlineText>{' '}
                  <div style={{ display: 'inline-block' }}>
                    <Controller
                      control={control}
                      name="variantDimensionsUnitsOneOf"
                      defaultValue={null}
                      render={({ field }) => (
                        <Select
                          ref={field.ref}
                          options={DIMENSION_UNITS_OPTIONS}
                          defaultValue={defaultDimensionUnits}
                          isClearable
                          isMulti
                          onChange={(multivalues) =>
                            field.onChange(
                              generateDropdownOneOfRequirements(
                                'variantDimensionUnits',
                                multivalues
                              )
                            )
                          }
                          styles={REACT_SELECT_CUSTOM_STYLES}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col">
                  <InlineText>Weight</InlineText>{' '}
                  <div style={{ display: 'inline-block' }}>
                    <Controller
                      control={control}
                      name="variantWeightUnitsOneOf"
                      defaultValue={null}
                      render={({ field }) => (
                        <Select
                          ref={field.ref}
                          options={WEIGHT_UNITS_OPTIONS}
                          defaultValue={defaultWeightUnits}
                          isClearable
                          isMulti
                          onChange={(multivalues) =>
                            field.onChange(
                              generateDropdownOneOfRequirements('variantWeightUnits', multivalues)
                            )
                          }
                          styles={REACT_SELECT_CUSTOM_STYLES}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Divider className="mt-5 mb-5" />

            <div className="container-fluid mt-3">
              <div className="collapse show" id="allAdvancedRules" aria-expanded="true">
                <Flex column gap="16px">
                  <Heading variant="Headings/H3" as="h2">
                    Advanced Rules
                  </Heading>
                  <span>Create customized validation rules</span>
                  {advancedRuleKeys.map((advancedRuleKey) => (
                    <AdvancedRules
                      key={advancedRuleKey}
                      onDelete={(e) => onDeleteRule(advancedRuleKey, e)}
                      onCreate={(field, validation, condition) =>
                        onCreateRule(advancedRuleKey, field, validation, condition)
                      }
                      onReset={() => onResetRule(advancedRuleKey)}
                      existingRule={
                        advancedRules[advancedRuleKey] ? advancedRules[advancedRuleKey] : null
                      }
                    />
                  ))}
                  <WrapperButton onClick={addRule}>
                    <IconWrap>
                      <AddIcon />
                    </IconWrap>
                    <ClickableText>ADD ANOTHER RULE</ClickableText>
                  </WrapperButton>
                </Flex>
              </div>
            </div>

            {submitRulesError && (
              <Alert type="error" title={submitRulesError.title} onClose={closeSubmitAlert}>
                {submitRulesError.body}
              </Alert>
            )}

            <RightAlignedWrapper className="mt-3">
              <PrimaryButton
                type="submit"
                onClick={handleSubmit(submitRules)}
                disabled={isLoading || isSuccessful}
              >
                Save
              </PrimaryButton>
            </RightAlignedWrapper>
          </form>
        </Modal.Body>
      </Modal.Root>
    </>
  );
};

export default BuyerProductValidation;
