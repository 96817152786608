import { useMutation } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import type React from 'react';
import Dropdown from 'storybook/stories/cells/Dropdown';
import Menu from 'storybook/stories/cells/Menu';
import Button from 'storybook/stories/molecules/Button';
import IconButton from 'storybook/stories/molecules/Button/IconButton';
import type { Question } from 'types/models/question';
import { deleteReportingQuestion, sendReportingQuestionEmail } from 'utils/api/reporting';
import type ApiError from 'utils/ApiError';

interface ReportCardActionsDropdownProps {
  refetchQuestions: () => void;
  question?: Question;
  onExpandQuestionClick: (question?: Question) => void;
}

const ReportCardActionsDropdown: React.FC<ReportCardActionsDropdownProps> = ({
  refetchQuestions,
  question,
  onExpandQuestionClick,
}) => {
  const alertQueue = useAlertQueue();

  /**
   * Mutations
   */

  const emailingQuestion = useMutation({
    mutationFn: (id: string) => sendReportingQuestionEmail(id),
    onSuccess: () => {
      alertQueue.addSuccessAlert('Success', 'Question emailed');
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Error', error.message);
    },
  });

  const deletingQuestion = useMutation({
    mutationFn: (id: string) => deleteReportingQuestion(id),
    onSuccess: () => {
      alertQueue.addSuccessAlert('Success', 'Question deleted');
      refetchQuestions();
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Error', error.message);
    },
  });

  /**
   * Render
   */

  if (!question) return null;

  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        <IconButton name="more_vert" aria-label="Open Actions Menu" />
      </Dropdown.Trigger>

      <Dropdown.Content padding="16px 8px">
        {({ close }) => (
          <Menu>
            <Menu.Link
              as={Button}
              $iconName="open_in_full"
              onClick={() => {
                onExpandQuestionClick(question);
                close();
              }}
            >
              Expand Report
            </Menu.Link>

            <Menu.Link
              as={Button}
              $iconName="forward_to_inbox"
              onClick={() => {
                emailingQuestion.mutate(question.id);
                close();
              }}
            >
              Email Me This Report
            </Menu.Link>

            <Menu.Link
              as={Button}
              $iconName="delete_forever"
              kind="destructive"
              onClick={() => {
                deletingQuestion.mutate(question.id);
                close();
              }}
            >
              Delete Report
            </Menu.Link>
          </Menu>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default ReportCardActionsDropdown;
