// Import dependencies
import { MarginType } from 'containers/PriceListsPage/CreatePriceList/Shared.types';
import type { Partner, PartnerPerOrderFeeSettings } from 'types/models/partner';
import { PerOrderFeeType } from 'types/models/partner';
import type { PartnerOrderFees } from 'types/models/per-order-fee';
import { CURRENCIES, humanizeMoney } from './currencies';

// Constants
export const MARGIN_TYPE_FIXED = 'fixed';
export const MARGIN_TYPE_PERCENT = 'percent';

/**
 * Formats a number to two decimal places.
 */
export function formatToTwoDecimalPlaces(num: number | string): number {
  if (!num || num === '') {
    return 0;
  }
  const numString = num.toString();
  if (numString.indexOf('.') === -1) {
    // Return the value if it doesn't have a decimal value
    return parseFloat(numString);
  }
  if (numString.split('.')[1].length <= 2) {
    // If two or less decimal places, don't change
    return parseFloat(numString);
  }

  return parseFloat((Math.round(Number(numString) * 100) / 100).toFixed(2));
}

/**
 * Returns a price with the proper currency symbol, rounded to 2 decimal places.
 */
export const formatPriceWithCurrency = (price: number | string, currency: string = ''): string => {
  let currencySymbol = '';
  if (currency && CURRENCIES[currency.toUpperCase()]) {
    currencySymbol = CURRENCIES[currency.toUpperCase()].symbol;
  }
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(price)) || price === '') return '';
  if (price === 0) return `${currencySymbol}0.00`;
  return currencySymbol + formatToTwoDecimalPlaces(price).toFixed(2);
};

interface ToMarginStringInput {
  margin: number;
  marginType: string;
  currency: string;
  convertFromDecimal?: boolean;
}

/**
 * Formats a margin into a string with the proper currency symbol.
 */
export const toMarginString = ({
  margin,
  marginType,
  currency,
  convertFromDecimal = false,
}: ToMarginStringInput): string => {
  const marginValue = convertFromDecimal ? Math.round(margin * 100) : margin;

  const marginTypeAsFoo = marginType === 'percent' ? MarginType.Percent : MarginType.Fixed;

  return marginTypeAsFoo === MarginType.Percent
    ? `${marginValue}%`
    : formatPriceWithCurrency(marginValue, currency);
};

/**
 * Determines if a number is a rounding error by checking if it has more than 6 decimal places.
 */
export const isRoundingError = (num: number): boolean => {
  const decimalPart = num.toString().split('.')[1];
  return Boolean(decimalPart) && decimalPart.length > 6;
};

/**
 * Calculates the margin based on the margin type, retail price, and dropship price.
 */
export const calculateMargin = (
  marginType: MarginType,
  retailPrice: number,
  dropshipPrice: number
): number => {
  if (retailPrice === 0 && marginType === MarginType.Percent) {
    return 0;
  }

  let margin = 0;

  if (marginType === MarginType.Percent) {
    margin = ((retailPrice - dropshipPrice) / retailPrice) * 100;
  } else if (marginType === MarginType.Fixed) {
    margin = (retailPrice - dropshipPrice) / 100;
  }

  if (isRoundingError(margin)) {
    margin = Math.floor(margin);
  }

  return parseFloat(margin.toFixed());
};

/**
 * Given a partner's per order fee settings, it returns a human-readable string representing the fee amount.
 */
export const generateOrderFeeString = (settings: PartnerPerOrderFeeSettings): string | null => {
  if (settings.type === PerOrderFeeType.Fixed) {
    const { amount, currency } = settings.flatFee;
    if (amount === 0) {
      return null;
    }
    return humanizeMoney({ amount, currency });
  }

  if (settings.type === PerOrderFeeType.Percentage) {
    return `${settings.percentage}%`;
  }

  return null;
};

/**
 * Given a list of partners, it finds which ones have per order fees (both flat and percentage)
 * and returns a map of each partner id to their order fee data.
 */
export const mapPartnerIdsToOrderFees = (partners: Partner[]): PartnerOrderFees => {
  return partners.reduce((accumulator, partner) => {
    // Check that order fee settings exist
    const settings = partner.perOrderFeeSettings;
    if (!settings) return accumulator;
    if (!settings.flatFee && settings.percentage === undefined) return accumulator;

    // Generate the readable order fee string
    const feeString = generateOrderFeeString(settings);
    if (feeString === null) {
      return accumulator;
    }

    // Add the partner id and order fee data to the map
    return {
      ...accumulator,
      [partner._id]: { buyerName: partner.buyerName, orderFeeString: feeString },
    };
  }, {} as PartnerOrderFees);
};
