import { InvoicePoster, InvoiceStatus, type Invoice, type InvoiceItem } from 'types/models/invoice';
import type { Partner } from 'types/models/partner';
import { capitalizeFirstChar } from 'utils/strings';

export const friendlyInvoiceSource = (source: string): string => {
  switch (source) {
    case '':
      return 'None';
    case 'stripecharge':
      return 'Stripe Charge (Legacy)';
    default:
      return capitalizeFirstChar(source);
  }
};

export const invoiceItemDescription = (item: InvoiceItem): string => {
  return item.referenceId;
};

export const invoiceDescription = (invoice: Invoice): string => {
  if (invoice.items.length > 1) {
    return 'Multiple Items'; // Should not happen with current invoicing system
  }
  if (invoice.items.length === 0) {
    return 'Empty Invoice'; // Should not happen with current invoicing system
  }
  return invoiceItemDescription(invoice.items[0]);
};

export const canManuallyPayInvoice = (invoice: Invoice): boolean => {
  if (invoice.posted) {
    return false;
  }

  if (invoice.source !== InvoicePoster.None) {
    return false;
  }

  if (invoice.status !== InvoiceStatus.Open) {
    return false;
  }

  return true;
};

export const canResetInvoice = (invoice: Invoice, partner?: Partner): boolean => {
  if (!partner) {
    return false;
  }

  if (invoice.posted) {
    return false;
  }

  if (invoice.status !== InvoiceStatus.Open) {
    return false;
  }

  if (invoice.source === partner.billingSettings.invoicePoster) {
    return false;
  }

  return true;
};
