import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { PageSelectedState } from 'hooks/useBulkSelect';
import { useEffect } from 'react';
import NoResultsCard from 'storybook/stories/cells/Card/NoResultsCard';
import PageList from 'storybook/stories/cells/PageList';
import PageNavigation from 'storybook/stories/cells/PageNavigation';
import SearchForm, { SearchFormButton, SearchFormInput } from 'storybook/stories/cells/SearchForm';
import BuyerProductSearchResult from 'storybook/stories/cells/SearchResult/BuyerProductSearchResult';
import Tooltip from 'storybook/stories/cells/Tooltip';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Checkbox from 'storybook/stories/molecules/Checkbox';
import Link from 'storybook/stories/molecules/Link';
import SearchResults, {
  SearchResultsFooter,
  SearchResultsHeader,
} from 'storybook/stories/organisms/SearchResults';
import type { LegacyBuyerProduct } from 'types/models/legacy-buyer-product';
import type { ProductSearchResponse } from 'utils/api/products';

export type ProductSearchFormInputs = {
  query: string;
};

interface ProductSearchFormProps {
  onSearchFormSubmit: SubmitHandler<ProductSearchFormInputs>;
  defaultSearchFormInputValue?: string;
}

const ProductSearchForm = ({
  onSearchFormSubmit,
  defaultSearchFormInputValue,
}: ProductSearchFormProps) => {
  const { register, handleSubmit } = useForm<ProductSearchFormInputs>();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit(onSearchFormSubmit)();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSearchFormSubmit, handleSubmit]);

  return (
    <SearchForm onSubmit={handleSubmit(onSearchFormSubmit)}>
      <SearchFormInput
        placeholder="Search by Product Title, Code, or Brand"
        {...register('query')}
        defaultValue={defaultSearchFormInputValue}
      />
      <SearchFormButton data-testid="submit-query" />
    </SearchForm>
  );
};

interface ProductSearchResultCheckboxProps
  extends Pick<BuyerProductSearchResultsProps, 'onSelectRowChange' | 'isSelected'> {
  product: LegacyBuyerProduct;
}

const ProductSearchResultCheckbox = ({
  product,
  onSelectRowChange,
  isSelected,
}: ProductSearchResultCheckboxProps) => {
  const checkboxUi = (
    <Checkbox
      name="selectRow"
      onChange={() => onSelectRowChange(product.id)}
      checked={isSelected(product.id)}
      disabled={!product.selectedAvailable}
      aria-label={
        isSelected(product.id)
          ? `Remove ${product.title} from selection`
          : `Add ${product.title} to selection`
      }
    />
  );

  if (product.selectedAvailable) return checkboxUi;

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>{checkboxUi}</Tooltip.Trigger>
      <Tooltip.Content>
        {product.selectedAvailableReason || 'Selection is unavailable for this product'}
      </Tooltip.Content>
    </Tooltip>
  );
};

interface BuyerProductSearchResultsProps {
  results?: ProductSearchResponse;
  onSearchFormSubmit: SubmitHandler<ProductSearchFormInputs>;
  defaultSearchFormInputValue?: string;
  currentPage: number;
  onPreviousClick: React.MouseEventHandler<HTMLButtonElement>;
  onNextClick: React.MouseEventHandler<HTMLButtonElement>;
  onPageClick: (pageNumber: number) => void;
  onSearchResultClick: (productId: string) => void;
  headerFiltersUi: React.ReactNode;
  headerActionsUi: React.ReactNode;
  onSelectPageChange: React.ChangeEventHandler<HTMLInputElement>;
  onSelectRowChange: (id: string) => void;
  isSelected: (id: string) => boolean;
  isSelectable: boolean;
  pageSelectedState: PageSelectedState;
  shouldDisplaySyncedStatus: boolean;
  shouldDisplayBulkSelect: boolean;
  shouldDisplaySyncingStatus: boolean;
  onSelectProductChange: (productId: string) => void;
  onDeselectProductChange: (productId: string) => void;
}

/**
 * `SearchResults` implementation for a Buyer & Seller search results
 */
const BuyerProductSearchResults = ({
  results,
  onSearchFormSubmit,
  defaultSearchFormInputValue,
  currentPage,
  onPreviousClick,
  onNextClick,
  onPageClick,
  onSearchResultClick,
  headerFiltersUi,
  headerActionsUi,
  onSelectPageChange,
  onSelectRowChange,
  isSelected,
  isSelectable,
  pageSelectedState,
  shouldDisplaySyncedStatus,
  shouldDisplayBulkSelect,
  shouldDisplaySyncingStatus,
  onSelectProductChange,
  onDeselectProductChange,
}: BuyerProductSearchResultsProps) => {
  if (!results) return null;

  const products = (results.data.products ?? []) as LegacyBuyerProduct[];
  const pagination = results.data.pagination ?? {};

  const hasPrevious = currentPage > 1;

  const headerSearchFormUi = (
    <ProductSearchForm
      onSearchFormSubmit={onSearchFormSubmit}
      defaultSearchFormInputValue={defaultSearchFormInputValue}
    />
  );

  const headerCheckboxUi = shouldDisplayBulkSelect && (
    <Checkbox
      name="selectPage"
      onChange={onSelectPageChange}
      checked={pageSelectedState !== PageSelectedState.None}
      indeterminate={pageSelectedState === PageSelectedState.Some}
      disabled={!isSelectable}
      aria-label={
        pageSelectedState !== PageSelectedState.None
          ? 'Remove all products on page from selection'
          : 'Add all products on page to selection'
      }
    />
  );

  const headerUi = (
    <SearchResultsHeader
      searchFormUi={headerSearchFormUi}
      filtersUi={headerFiltersUi}
      actionsUi={headerActionsUi}
      checkboxUi={headerCheckboxUi}
    />
  );

  const footerPageListUi = (
    <PageList
      currentPage={currentPage}
      onPageClick={onPageClick}
      lastPage={pagination.totalPages}
    />
  );

  const footerNavigationUi = (
    <PageNavigation
      hasPrevious={hasPrevious}
      hasNext={results.hasMore}
      onPreviousClick={onPreviousClick}
      onNextClick={onNextClick}
    />
  );

  const footerUi = (
    <SearchResultsFooter
      pageListUi={footerPageListUi}
      navigationUi={footerNavigationUi}
      totalResults={pagination.total}
    />
  );

  if (products.length === 0) {
    return (
      <SearchResults headerUi={headerUi} footerUi={footerUi}>
        <NoResultsCard
          title="No products match your search"
          description="You can reset your search by clicking the button below"
        >
          <PrimaryButton $iconName="restart_alt" as={Link} to="/products">
            Reset Search
          </PrimaryButton>
        </NoResultsCard>
      </SearchResults>
    );
  }

  return (
    <SearchResults headerUi={headerUi} footerUi={footerUi}>
      {products.map((product) => {
        const checkboxUi = shouldDisplayBulkSelect && (
          <ProductSearchResultCheckbox
            product={product}
            onSelectRowChange={onSelectRowChange}
            isSelected={isSelected}
          />
        );

        return (
          <BuyerProductSearchResult
            key={product.id}
            product={product as LegacyBuyerProduct}
            onClick={() => onSearchResultClick(product.id)}
            checkboxUi={checkboxUi}
            shouldDisplaySyncedStatus={shouldDisplaySyncedStatus}
            shouldDisplaySyncingStatus={shouldDisplaySyncingStatus}
            onSelectProductChange={onSelectProductChange}
            onDeselectProductChange={onDeselectProductChange}
          />
        );
      })}
    </SearchResults>
  );
};

export default BuyerProductSearchResults;
