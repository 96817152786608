import SimpleBar from 'simplebar-react';

import CardProductValidationAlert from './CardProductValidationAlert';

const ProductDescription = ({ product, validationErrors }) => {
  const description = product ? product.bodyHtml : '';
  const cardStyle = validationErrors?.length > 0 ? { border: '1px solid #ffe4a0' } : {};

  return (
    <div className="card" style={cardStyle}>
      <div className="d-flex justify-content-between align-items-center p-4">
        <h4 className="mb-0">Description</h4>
      </div>
      <div className="card-body pt-0">
        <SimpleBar style={{ height: '100%', maxHeight: '18rem' }}>
          <p className="m-0 text-muted" aria-label="product description">
            {description || 'This product does not have a description'}
          </p>
        </SimpleBar>

        {validationErrors?.length > 0 && <CardProductValidationAlert errors={validationErrors} />}
      </div>
    </div>
  );
};

export default ProductDescription;
