import styled from 'styled-components';

import Button from 'components/Common/Button';
import PageHeader from 'components/Common/PageHeader';
import Icon from 'storybook/stories/molecules/Icon';

const EmptyText = styled.div`
  height: 0.9375rem;
  margin-bottom: 0.5rem;
  background: var(--light);
  border-radius: 4px;
`;

const EmptyHeading = styled(EmptyText)`
  height: 1.6rem;
  width: 24rem;
`;

const LargeIcon = styled(Icon)`
  font-size: 5rem;
`;

const NoProductPage = ({ error, backUrl }) => {
  return (
    <>
      <PageHeader>
        <EmptyHeading className="mb-3" />
      </PageHeader>
      <div className="p-5 d-flex justify-content-center align-items-center flex-column">
        <LargeIcon name="warning" className={error ? 'text-danger' : 'text-warning'} />
        <h2 className="mb-4">
          {error
            ? 'An error occurred while fetching this product'
            : 'This product could not be found'}
        </h2>
        <Button color="secondary" outline tag="link" to={backUrl}>
          Go To Products
        </Button>
      </div>
    </>
  );
};

export default NoProductPage;
