import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useMutation } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import { omit } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompany } from 'store/selectors/me/company';
import {
  billingAddressAdded,
  returnsAddressAdded,
  shippingAddressAdded,
} from 'store/slices/getStarted';
import { setLocations } from 'store/slices/me';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import type { Address } from 'types/general';
import { updateCompany } from 'utils/api/companies';

interface AddressFormProps {
  addresses?: Address[];
  addressType: string; // note: backend uses a string type, and doesn't enforce a specific set of values
}

const AddressForm = ({ addresses, addressType }: AddressFormProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();
  const company = useSelector(selectCompany);
  const dispatch = useDispatch();
  const [addressHandled, setAddressHandled] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      phoneNumber: '',
      name: '',
      company: '',
      addressOne: '',
      addressTwo: '',
      city: '',
      state: '',
      country: '',
      zip: '',
    },
  });

  if (addresses?.length && !addressHandled && !isDirty) {
    reset(addresses[0]);
    setAddressHandled(true);
  }

  const updatingCompany = useMutation({
    mutationFn: (data: Omit<Address, '_id'>) => {
      const payload = omit(data, '_id');
      return updateCompany(company?._id ?? '', { locations: [payload] });
    },
    onSuccess: (data) => {
      addSuccessAlert('Added Address', 'Your address has been saved.');
      reset();

      if (addressType === 'billing') {
        dispatch(billingAddressAdded());
        dispatch(setLocations(data.locations));
      }
      if (addressType === 'shipping') {
        dispatch(shippingAddressAdded());
      }
      if (addressType === 'returns') {
        dispatch(returnsAddressAdded());
      }
    },
    onError: (error: { message: string }) => {
      addErrorAlert('Something went wrong', error.message);
      console.error('Unable to update address', error);
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        updatingCompany.mutate({ ...data, type: addressType });
      })}
    >
      <Flex column gap="20px">
        <Flex column gap="8px">
          <Label isRequired htmlFor="name">
            Name
          </Label>
          <Input {...register('name', { required: true })} placeholder="John Smith" />{' '}
        </Flex>

        <Flex column gap="8px">
          <Label isRequired htmlFor="company">
            Company
          </Label>
          <Input {...register('company', { required: true })} placeholder="Example, Inc." />
        </Flex>

        <Flex column gap="8px">
          <Label isRequired htmlFor="addressOne">
            Address One
          </Label>
          <Input {...register('addressOne', { required: true })} placeholder="1524 Wilshire Dr" />
        </Flex>

        <Flex column gap="8px">
          <Label htmlFor="addressTwo">Address Two</Label>
          <Input {...register('addressTwo', { required: false })} placeholder="Unit 903" />
        </Flex>

        <Flex column gap="8px">
          <Label isRequired htmlFor="city">
            City, State/Provence, Zip
          </Label>

          <Grid columns="1fr 0.5fr 0.5fr" gap="8px">
            <Input {...register('city', { required: true })} isFullWidth placeholder="Vancouver" />
            <Input {...register('state', { required: true })} isFullWidth placeholder="BC" />
            <Input {...register('zip', { required: true })} isFullWidth placeholder="V6B 6A7" />
          </Grid>
        </Flex>

        <Flex column gap="8px">
          <Label isRequired htmlFor="country">
            Country
          </Label>
          <Input {...register('country', { required: true })} placeholder="CA" />
        </Flex>

        <Flex column gap="8px">
          <Label isRequired htmlFor="phoneNumber">
            Phone Number
          </Label>
          <Input {...register('phoneNumber', { required: true })} placeholder="111-222-3333" />
        </Flex>

        <Flex justifyEnd>
          <PrimaryButton size="small" disabled={!isValid || isSubmitting} type="submit">
            Save {addressType} Address
          </PrimaryButton>
        </Flex>
      </Flex>
    </form>
  );
};

export default AddressForm;
