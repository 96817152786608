/* eslint-disable jsx-a11y/label-has-associated-control */
import { isEmpty, isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDocumentTitle } from 'usehooks-ts';

import DashboardPage from 'components/Dashboard/DashboardPage';
import useDebounceValue from 'hooks/useDebounceValue';
import usePrevious from 'hooks/usePrevious';
import { listTradeOpsIssues } from 'utils/api/issues';

import { useQuery } from '@tanstack/react-query';
import Page from 'storybook/stories/cells/Page';
import HelpWidget from './HelpWidget';
import './Results.css';
import './RightMenu.css';
import './SearchRow.css';
import TradeOpsIssuesListContainer, { SEVERITIES } from './TradeOpsIssuesListContainer';
import TradeOpsIssuesPageNav from './TradeOpsIssuesPageNav';

// "All" is the default
export const DEFAULT_SEVERITY = SEVERITIES[0][0];

// Number of issues to return per query
export const MAX_PER_PAGE = 20;

// Provides data for resource select menu
export const RESOURCE_OPTIONS = [
  {
    value: 'companies',
    label: 'General',
  },
  {
    value: 'products',
    label: 'Products',
  },
  {
    value: 'variants',
    label: 'Variants',
  },
  {
    value: 'orders',
    label: 'Orders',
  },
  {
    value: 'prices',
    label: 'Prices',
  },
  {
    value: 'partners',
    label: 'Partners',
  },
  {
    value: 'edi_records',
    label: 'EDI Records',
  },
];

// Styles to apply to the resource select menu
const RESOURCE_SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    color: '#bac4d1',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '2px 5px',
  }),
};

// Query builder helper
export const buildQuery = (
  currentPage,
  searchQuery,
  selectedResources,
  severityLevel,
  isArchiveShown = false
) => {
  const query = {
    page: currentPage,
    limit: MAX_PER_PAGE,
    archived: isArchiveShown,
  };

  if (!isEmpty(searchQuery)) {
    query.search = searchQuery;
  }

  if (!isEmpty(selectedResources)) {
    query.resources = selectedResources.map(({ value }) => value);
  }

  if (!isEmpty(severityLevel) && severityLevel !== DEFAULT_SEVERITY) {
    query.severityLevels = severityLevel;
  }

  return query;
};

const TradeOpsIssuesPage = () => {
  useDocumentTitle('Issues');

  const [currentPage, setCurrentPage] = useState(0);
  const [errors, setErrors] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [selectedSeverity, setSelectedSeverity] = useState(DEFAULT_SEVERITY);
  const [resources, setResources] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [searchQueryDisplay, setSearchQueryDisplay] = useState('');

  const searchQuery = useDebounceValue(searchQueryDisplay, 500);

  const prevSearchQuery = usePrevious(searchQuery);
  const prevResources = usePrevious(resources);
  const prevShowArchived = usePrevious(showArchived);

  // Helpers

  const isFormUpdated =
    !isEqual(searchQuery, prevSearchQuery) ||
    !isEqual(resources, prevResources) ||
    !isEqual(showArchived, prevShowArchived);

  const resetResults = () => {
    setCurrentPage(0);
    setHasMore(false);
    setErrors([]);
  };

  // Side Effects

  useEffect(() => {
    if (isFormUpdated) {
      resetResults();
    }
  }, [isFormUpdated]);

  const queryKey = buildQuery(currentPage, searchQuery, resources, selectedSeverity, showArchived);

  const { isLoading } = useQuery({
    queryKey,
    queryFn: () => listTradeOpsIssues(queryKey),
    onSuccess: ({ data }) => {
      setErrors((errs) => errs.concat(data.errors));
      setHasMore(data.hasMore);
    },
    onError: (err) => {
      console.error('Fetch Trade Ops Error', err);
    },
  });

  // Event Handlers

  const handleLoadMoreClick = (event) => {
    event.preventDefault();

    setCurrentPage((page) => page + 1);
  };

  const handleTabSelect = (key) => {
    setSelectedSeverity(key);
    resetResults();
  };

  const handleStateChange = async (errorId) => {
    setErrors(errors.filter((error) => error.id !== errorId));
  };

  // Render

  return (
    <DashboardPage>
      <Page>
        <Page.Head title="Issues">
          <TradeOpsIssuesPageNav
            searchQuery={searchQuery}
            resources={resources}
            showArchived={showArchived}
            errors={errors}
            isLoading={isLoading}
            isSeveritySelected={selectedSeverity !== DEFAULT_SEVERITY}
          />
        </Page.Head>

        <Page.Body>
          <div className="row search-row">
            <div className="col-md-12">
              <div className="form-group search-filter-wrapper search-filter-input-wrapper">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control search-filter-input"
                  value={searchQueryDisplay}
                  placeholder="Search for message, or error code"
                  onChange={(e) => setSearchQueryDisplay(e.target.value)}
                />

                <label className="search-filter-show-archived-label">
                  <input
                    type="checkbox"
                    onChange={() => setShowArchived(!showArchived)}
                    className="search-filter-show-archived-input"
                  />
                  Show Archived
                </label>
              </div>

              <div className="form-group search-filter-wrapper search-filter-select-wrapper">
                <Select
                  options={RESOURCE_OPTIONS}
                  isMulti
                  onChange={(values) => setResources([...values])}
                  values={resources}
                  placeholder="Select resource"
                  styles={RESOURCE_SELECT_STYLES}
                />
              </div>
            </div>

            <div className="col-md-12">
              <HelpWidget
                options={[
                  'The pipe (|) in your search represents an "or". Ex. "err_no_barcode | err_ssh_unable_to_auth"',
                  'The ampersand (&) in your search represents an "and". Ex. "access & sftp"',
                  'If you need support, use the Contact Support button in the bottom left corner',
                  'Archive old errors to avoid getting future alerts about them',
                ]}
              />
            </div>
          </div>

          <hr />

          <TradeOpsIssuesListContainer
            isLoading={isLoading}
            errors={errors}
            onTabSelect={handleTabSelect}
            selectedSeverity={selectedSeverity}
            onStateChange={handleStateChange}
          />

          {hasMore && (
            <button
              type="button"
              onClick={handleLoadMoreClick}
              className="btn btn-primary mb-2"
              id="load-more-button"
              disabled={isLoading}
            >
              Load More
            </button>
          )}
        </Page.Body>
      </Page>
    </DashboardPage>
  );
};

export default TradeOpsIssuesPage;
