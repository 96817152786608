import Flex from '@react-css/flex';
import { merge } from 'lodash';
import typography from 'storybook/mixins/typography';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1; // because .app-container is flexbox
  padding: 24px 12px;
  background: ${({ theme }) => theme.color.bodyBackground};
  gap: 32px;
`;

const HeadWrapper = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray200};
`;

const HeadTitle = styled.h1`
  ${typography('Headings/H1')};
  color: ${({ theme }) => theme.color.bodyTextSecondary};
  margin: 0;
`;

const HeadBackButton = styled(TertiaryButton).attrs(() => ({
  $iconName: 'navigate_before',
  size: 'small',
}))`
  padding-left: 0;
`;

const HeadDescription = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.color.bodyTextSecondary};
`;

interface BackButtonProp {
  onClick: () => void;
  text: string;
}

interface PageHeadProps {
  /**
   * Title of the page
   */
  title: string;
  /**
   * Description of the page
   */
  description?: string;
  /**
   * UI for the back button
   */
  backButton?: BackButtonProp;
  /**
   * UI for the actions
   */
  children?: React.ReactNode;
  /**
   * Custom class name
   */
  className?: string;
}

const Head = ({ title, description, backButton, children, className }: PageHeadProps) => (
  <HeadWrapper className={className}>
    <Flex column>
      {backButton && (
        <Flex.Item alignSelfStart>
          <HeadBackButton onClick={backButton.onClick}>{backButton.text}</HeadBackButton>
        </Flex.Item>
      )}

      <Flex gap="8px">
        <Flex.Item grow={1}>
          <Flex column>
            <HeadTitle>{title}</HeadTitle>
            {description && <HeadDescription>{description}</HeadDescription>}
          </Flex>
        </Flex.Item>

        <Flex gap="16px">{children}</Flex>
      </Flex>
    </Flex>
  </HeadWrapper>
);

const Body = styled.div``;

export default merge(Base, {
  Head,
  Body,
});
