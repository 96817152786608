import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragStartEvent,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Grid from '@react-css/grid';
import { useState } from 'react';
import materialSymbol from 'storybook/mixins/materialSymbol';
import styled, { css } from 'styled-components';
import type { SellerProductImage } from 'types/models/seller-product';

const ThumbnailImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  position: absolute;
`;

const SortHandle = styled.div<Pick<ThumbnailProps, 'isDragging'>>`
  ${materialSymbol({ name: 'open_with', size: '1.5rem' })};
  background: ${({ theme }) => theme.color.white};
  position: absolute;
  bottom: 0.25rem;
  display: none;
  justify-content: center;
  align-items: center;
  left: 0.25rem;
  color: var(--dark);
  border: 1px solid #e3ebf6;
  border-radius: 4px;
  cursor: pointer;
  padding: 1px 6px;

  &:hover,
  &:active,
  &:focus {
    cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
  }
`;

interface ThumbnailProps extends React.HTMLAttributes<HTMLDivElement> {
  withOpacity?: boolean;
  isDragging?: boolean;
}

const Thumbnail = styled.div<ThumbnailProps>`
  opacity: ${({ withOpacity }) => (withOpacity ? '0.5' : '1')};
  transform-origin: 50% 50%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.gray100};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ isDragging }) =>
    isDragging
      ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
      : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px'};
  transform: ${({ isDragging }) => (isDragging ? 'scale(1.05)' : 'scale(1)')};
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &:hover ${SortHandle} {
    display: flex;
  }

  ${({ isDragging }) =>
    isDragging &&
    css`
      cursor: move;
    `}
`;

interface SortableThumbnailProps extends ThumbnailProps {
  id: string;
}

const SortableThumbnail = ({ id, children, ...itemProps }: SortableThumbnailProps) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Thumbnail ref={setNodeRef} style={style} withOpacity={isDragging} {...itemProps}>
      {children}
      <SortHandle {...attributes} {...listeners} />
    </Thumbnail>
    /* eslint-enable react/jsx-props-no-spreading */
  );
};

interface ProductImagesGridProps {
  imageIds: string[];
  editMap: Record<string, SellerProductImage>;
  productTitle: string;
  canEdit: boolean;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  onPositionChange: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
}

const ProductImagesGrid = ({
  imageIds,
  editMap,
  productTitle,
  canEdit,
  setSelectedIndex,
  onPositionChange,
}: ProductImagesGridProps) => {
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const sensors = useSensors(useSensor(MouseSensor));

  /**
   * Event Handlers
   */

  const onDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id === over?.id) return;

    if (active.id !== over?.id) {
      const oldIndex = imageIds.indexOf(active.id.toString());
      const newIndex = imageIds.indexOf(over?.id?.toString() ?? '');

      onPositionChange({ oldIndex, newIndex });
    }

    setActiveId(null);
  };

  const onDragCancel = () => {
    setActiveId(null);
  };

  /**
   * Helpers
   */

  const GridItem = canEdit ? SortableThumbnail : Thumbnail;

  /**
   * Render
   */

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <SortableContext items={imageIds} strategy={rectSortingStrategy}>
        <Grid columns="repeat(auto-fill, minmax(7rem, 1fr)" gap="0.8rem">
          {imageIds.map((id, index) => (
            <GridItem key={id} id={id} onClick={() => setSelectedIndex(index)}>
              <ThumbnailImage src={editMap[id]?.src} alt={`${productTitle} ${index}`} />
            </GridItem>
          ))}
        </Grid>
      </SortableContext>

      <DragOverlay adjustScale style={{ transformOrigin: '0 0' }}>
        {activeId && (
          <Thumbnail isDragging>
            <ThumbnailImage src={editMap[activeId]?.src} alt={productTitle} />
          </Thumbnail>
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default ProductImagesGrid;
