import { strip } from '@luxass/strip-json-comments';
import { Chart } from 'chart.js/auto';
import useScopedSentryMessage from 'hooks/useScopedSentryMessage';
import { useEffect, useRef } from 'react';
import { useAppSelector } from 'store';
import { selectCompanyId } from 'store/selectors/me/company';
import type { ChatMessage } from 'types/models/conversation';

export const renderCharts = (element: HTMLDivElement) => {
  const codeBlocks = element.querySelectorAll<HTMLElement>('pre code.language-json');

  codeBlocks.forEach((block, index) => {
    const chartConfig = JSON.parse(strip(block.innerHTML));

    const canvas = document.createElement('canvas');
    canvas.id = `chart-canvas-${index}`;
    canvas.dataset.testid = `chart-canvas-${index}`;

    // `block.parentElement` must be defined as per selector 'pre code.language-json'
    block.parentElement!.replaceWith(canvas);

    // Render the chart
    // eslint-disable-next-line no-new
    new Chart(canvas, chartConfig);
  });
};

const useAiChartRef = (content: ChatMessage['content']) => {
  const sendSentryError = useScopedSentryMessage('error');
  const companyId = useAppSelector(selectCompanyId);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!content) return;
    if (!contentRef.current) return;

    try {
      renderCharts(contentRef.current);
    } catch (error) {
      sendSentryError('Error parsing JSON or rendering chart', {
        extra: {
          error,
          content,
          companyId,
        },
      });
    }
  }, [content, companyId, sendSentryError]);

  return contentRef;
};

export default useAiChartRef;
