import { useCallback } from 'react';

import { useAppDispatch } from 'store';
import { addAlert } from 'store/slices/alerts';

const useAlertQueue = () => {
  const dispatch = useAppDispatch();

  const addSuccessAlert = useCallback(
    (title: string, body: string) => dispatch(addAlert({ type: 'success', title, body })),
    [dispatch]
  );

  const addErrorAlert = useCallback(
    (title: string, body: string) => dispatch(addAlert({ type: 'error', title, body })),
    [dispatch]
  );

  return { addSuccessAlert, addErrorAlert };
};

export default useAlertQueue;
