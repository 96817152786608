import Flex from '@react-css/flex';
import SearchResult, { type SearchResultProps } from 'storybook/stories/cells/SearchResult';
import OrderCode from 'storybook/stories/cells/SearchResult/shared/OrderCode';
import OrderStatus from 'storybook/stories/cells/SearchResult/shared/OrderStatus';
import {
  LinkIcon,
  MainTableWrapper,
  PartnerName,
  TableMeta,
} from 'storybook/stories/cells/SearchResult/shared/Styles';
import Table from 'storybook/stories/cells/Table';
import Body from 'storybook/stories/molecules/Body';
import StatusIcon from 'storybook/stories/molecules/Icon/StatusIcon';
import Link from 'storybook/stories/molecules/Link';
import { CommerceType } from 'types/models/company';
import type { Order } from 'types/models/order';
import { humanizeMoney, toMoney } from 'utils/currencies';
import { prettyDateTime } from 'utils/date';
import { formatHoursToTimeString } from 'utils/time';

interface BuyerOrderSearchResultProps extends Omit<SearchResultProps, 'children'> {
  order: Order;
  checkboxUi: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const BuyerOrderSearchResult = ({
  order,
  checkboxUi,
  onClick,
  hasWarning,
}: BuyerOrderSearchResultProps) => (
  <SearchResult checkboxUi={checkboxUi} onClick={onClick} hasWarning={hasWarning}>
    <Flex column gap="16px">
      <Flex alignItemsCenter gap="16px">
        <Flex.Item grow={1}>
          <MainTableWrapper>
            <Table>
              <Table.THead>
                <Table.TR>
                  <Table.TH>{prettyDateTime(order.created)}</Table.TH>
                  <Table.TH>Total</Table.TH>
                  <Table.TH>Buyer Order #</Table.TH>
                  <Table.TH>Seller Order #</Table.TH>
                  <Table.TH />
                </Table.TR>
              </Table.THead>
              <Table.TBody>
                <Table.TR>
                  <Table.TD>
                    <PartnerName>{order.sellerName}</PartnerName>
                  </Table.TD>
                  <Table.TD width="136px">
                    <Body variant="Body/Regular Bold">
                      {humanizeMoney(toMoney(order.totalPrice, order.currency))}
                    </Body>
                  </Table.TD>
                  <Table.TD width="224px" onClick={(event) => event.stopPropagation()}>
                    <OrderCode order={order} type={CommerceType.Buyer} />
                  </Table.TD>
                  <Table.TD width="224px" onClick={(event) => event.stopPropagation()}>
                    <OrderCode order={order} type={CommerceType.Seller} />
                  </Table.TD>
                  <Table.TD align="right" onClick={(event) => event.stopPropagation()}>
                    <LinkIcon as={Link} to={`/orders/${order._id}`} target="_blank" />
                  </Table.TD>
                </Table.TR>
              </Table.TBody>
            </Table>
          </MainTableWrapper>
        </Flex.Item>
      </Flex>

      <Flex gap="16px">
        <Flex.Item grow={1}>
          <OrderStatus order={order} />
        </Flex.Item>

        <Flex.Item>
          <Flex alignItemsCenter gap="16px">
            <TableMeta>
              Ship Time: {order.shipped ? formatHoursToTimeString(order.shipTime) : 'N/A'}
            </TableMeta>

            {order.isSlaCompliant !== null && (
              <StatusIcon variant={order.isSlaCompliant ? 'checked' : 'error'}>
                <TableMeta>SLA {!order.isSlaCompliant && 'Not'} Compliant</TableMeta>
              </StatusIcon>
            )}
          </Flex>
        </Flex.Item>
      </Flex>
    </Flex>
  </SearchResult>
);

export default BuyerOrderSearchResult;
