import { Route, type RouteProps } from 'react-router-dom';

interface PublicRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PublicRoute = ({ component: Component, ...routeProps }: PublicRouteProps) => (
  <Route {...routeProps} render={(props) => <Component {...props} />} />
);

export default PublicRoute;
