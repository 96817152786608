type EventListener = (event: CustomEvent<any>) => void;

const subscribe = (eventName: string, listener: EventListener): void => {
  document.addEventListener(
    eventName as keyof DocumentEventMap,
    listener as EventListenerOrEventListenerObject
  );
};

const unsubscribe = (eventName: string, listener: EventListener): void => {
  document.removeEventListener(
    eventName as keyof DocumentEventMap,
    listener as EventListenerOrEventListenerObject
  );
};

const publish = <T>(eventName: string, data: T): void => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};

export { publish, subscribe, unsubscribe };
