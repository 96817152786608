import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import SingleAction from 'containers/PartnerPage/Actions/SingleAction';
import { getActionsReceived } from 'store/slices/partners';

import { useAppDispatch, useAppSelector } from 'store';
import { selectMe, selectPartners } from 'store/selectors/me';
import ActionsPagination from './ActionsPagination';

const LIMIT = 5;

const HorizontalSpacer = styled.div`
  width: 87%;
  margin-left: auto;
  margin-right: auto;
  height: 2px;
  background-color: #e8e8e8;
  margin-bottom: 0.625rem;
`;

type ActionsToBeCompletedProps = {
  name: string;
  partnerId: string;
};

const ActionsToBeCompleted = ({ name, partnerId }: ActionsToBeCompletedProps) => {
  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();
  const { company } = useAppSelector(selectMe);

  // Get actions
  const refreshActions = useCallback(() => {
    if (company && company._id) {
      const assignedActionsParams = { assignedTo: company._id, partnerId, page, limit: LIMIT };
      dispatch(getActionsReceived(assignedActionsParams));
    }
  }, [company, dispatch, page, partnerId]);
  useEffect(() => {
    refreshActions();
  }, [refreshActions]);
  const { actionsReceived } = useAppSelector(selectPartners);

  if (!actionsReceived || actionsReceived.length === 0) {
    return null;
  }
  const displayName = name || 'Your partner';
  return (
    <div className="card">
      <div className="card-body">
        <h2 className="mb-2">Actions you need to complete</h2>
        <p className="small text-muted mb-3">
          {displayName} needs you to complete the following actions.
        </p>
        {actionsReceived.map((action, index) => (
          <>
            <SingleAction action={action} refreshPage={refreshActions} />
            {index !== actionsReceived.length - 1 && index !== LIMIT - 1 && <HorizontalSpacer />}
          </>
        ))}
        <ActionsPagination
          page={page}
          setPage={setPage}
          resultsSize={actionsReceived.length}
          limit={LIMIT}
        />
      </div>
    </div>
  );
};

export default ActionsToBeCompleted;
