import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useBoolean } from 'usehooks-ts';

import useAlertQueue from 'hooks/useAlertQueue';
import { getCurrencySymbol, humanizeMoney } from 'utils/currencies';
import { subscribe, unsubscribe } from 'utils/events';
import { calculateMargin } from 'utils/prices';

import { BulkEditPriceListContext } from 'containers/PriceListPage/BulkEditing/context';
import DeletePriceListEntryConfirmationModal from 'containers/PriceListPage/BulkEditing/DeletePriceListEntryConfirmationModal';
import { MarginType } from 'containers/PriceListsPage/CreatePriceList/Shared.types';

import SearchResult from 'storybook/stories/cells/SearchResult/index';
import RowEnd from 'storybook/stories/cells/SearchResult/PriceListEntrySearchResult/RowEnd';
import RowStart from 'storybook/stories/cells/SearchResult/PriceListEntrySearchResult/RowStart';
import {
  EqualSymbolContainer,
  EqualSymbolLine,
  InnerInputSymbol,
  PricingInput,
  PricingInputWrapper,
  SubtractSymbol,
  VerticalDivider,
} from 'storybook/stories/cells/SearchResult/shared/Styles';
import Tooltip from 'storybook/stories/cells/Tooltip';
import Body from 'storybook/stories/molecules/Body';
import Icon from 'storybook/stories/molecules/Icon';

export const maxMarginValue = (marginType: MarginType, marginAmount: number) => {
  return marginType === MarginType.Percent ? 100 : marginAmount / 100 - 1;
};

export const validateMarginInput = (
  marginType: MarginType,
  marginAmount: number,
  currency: string,
  value: number
) => {
  const maxValue = maxMarginValue(marginType, marginAmount);

  if (value <= maxValue) return true;

  let errorMessage = '';

  if (marginType === MarginType.Percent) {
    errorMessage = `Cannot be greater than ${maxValue}%.`;
  }

  if (marginType === MarginType.Fixed) {
    errorMessage = `Cannot be greater than ${humanizeMoney({
      amount: maxValue * 100,
      currency,
    })}.`;
  }

  return errorMessage;
};

interface PriceListSearchResultProps {
  priceListEntryId: string;
  marginType: MarginType;
  checkboxUi: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const PriceListEntrySearchResult = ({
  priceListEntryId,
  marginType,
  checkboxUi,
  onClick,
}: PriceListSearchResultProps) => {
  const { addSuccessAlert } = useAlertQueue();
  const { getRowById, updateRow, submitRowById, viewMode, deleteRowById } =
    useContext(BulkEditPriceListContext);

  const row = getRowById(priceListEntryId);
  const isEditing = useBoolean(
    row.isEditingYourRetailPrice || row.isEditingMargin || row.isEditingYouEarn
  );
  // If the sellerRetailPrice doesn't match the buyerRetailPrice, a conversion rate is being applied.
  const shouldShowSuggestedRetailPrice = useBoolean(
    row.entry.sellerRetailPrice.amount !== row.entry.buyerRetailPrice.amount
  );

  const shouldShowDeleteConfirmationModal = useBoolean(false);

  const onSubmit = useCallback(
    (data: any) => {
      if (row.isEditingYourRetailPrice) {
        row.entry.sellerRetailPrice.amount = data.yourRetailPrice * 100;
      }

      // Margins are stored only on the parent price list, not the entries.
      // To update the margin for an entry, we can just calculate the new
      // dropship price based on the margin that was entered.
      if (row.isEditingMargin) {
        if (marginType === MarginType.Percent) {
          row.entry.dropshipPrice.amount =
            row.entry.sellerRetailPrice.amount * (1 - data.margin / 100);
        } else {
          row.entry.dropshipPrice.amount = row.entry.sellerRetailPrice.amount - data.margin * 100;
        }
      }

      if (row.isEditingYouEarn) {
        row.entry.dropshipPrice.amount = data.youEarn * 100;
      }

      submitRowById(priceListEntryId);
      isEditing.setFalse();
      addSuccessAlert('Success', 'Price updated successfully');
    },
    [addSuccessAlert, isEditing, marginType, priceListEntryId, row, submitRowById]
  );

  const {
    handleSubmit,
    register,
    reset,
    setFocus,
    trigger,
    getValues,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    shouldFocusError: false, // Disable auto-focus on error fields
    defaultValues: {
      yourRetailPrice: row.entry.sellerRetailPrice.amount / 100,
      margin: calculateMargin(
        marginType,
        row.entry.sellerRetailPrice.amount,
        row.entry.dropshipPrice.amount
      ),
      youEarn: row.entry.dropshipPrice.amount / 100,
    },
  });

  // Form Validation Functions

  const validateYourRetailPriceInput = (value: number) => {
    const dropshipPrice = row.entry.dropshipPrice.amount / 100;
    if (value >= dropshipPrice) return true;

    return `Cannot be less than ${humanizeMoney({
      amount: dropshipPrice * 100,
      currency: row.entry.sellerRetailPrice.currency,
    })}.`;
  };

  const validateYouEarnInput = (value: number) => {
    const maxYouEarn = row.entry.sellerRetailPrice.amount / 100;
    if (value > maxYouEarn) {
      return `Cannot be greater than ${humanizeMoney(row.entry.sellerRetailPrice)}.`;
    }
    return true;
  };

  // Edit Button Helpers

  const setEditingYourRetailPrice = () => {
    updateRow(priceListEntryId, {
      isEditingYourRetailPrice: true,
      isEditingMargin: false,
      isEditingYouEarn: false,
    });
    isEditing.setTrue();
  };

  const setEditingMargin = () => {
    updateRow(priceListEntryId, {
      isEditingYourRetailPrice: false,
      isEditingMargin: true,
      isEditingYouEarn: false,
    });
    isEditing.setTrue();
  };

  const setEditingYouEarn = () => {
    updateRow(priceListEntryId, {
      isEditingYourRetailPrice: false,
      isEditingMargin: false,
      isEditingYouEarn: true,
    });
    isEditing.setTrue();
  };

  const onCancelEdit = () => {
    isEditing.setFalse();
    updateRow(priceListEntryId, {
      isEditingYourRetailPrice: false,
      isEditingMargin: false,
      isEditingYouEarn: false,
    });
    reset();
  };

  const onRemoveFromPriceListClick = () => {
    shouldShowDeleteConfirmationModal.setTrue();
  };

  const onConfirmDeletePriceListEntry = () => {
    shouldShowDeleteConfirmationModal.setFalse();
    deleteRowById(priceListEntryId);
  };

  // Side Effects

  // These useEffects cause the user's cursor to focus on the input they selected to edit.
  // The error checks are used to prevent re-focus and re-selection of fields that failed validation.
  useEffect(() => {
    if (row.isEditingYourRetailPrice && !errors.yourRetailPrice) {
      isEditing.setTrue();
      setFocus('yourRetailPrice', { shouldSelect: true });
    }
  }, [isEditing, row.isEditingYourRetailPrice, errors.yourRetailPrice, setFocus]);
  useEffect(() => {
    if (row.isEditingMargin && !errors.margin) {
      isEditing.setTrue();
      setFocus('margin', { shouldSelect: true });
    }
  }, [isEditing, row.isEditingMargin, errors.margin, setFocus]);
  useEffect(() => {
    if (row.isEditingYouEarn && !errors.youEarn) {
      isEditing.setTrue();
      setFocus('youEarn', { shouldSelect: true });
    }
  }, [isEditing, row.isEditingYouEarn, errors.youEarn, setFocus]);

  // Listen to the bulk save event
  useEffect(() => {
    const saveListener = (event: CustomEvent) => {
      const rowHasEdits =
        row.isEditingYourRetailPrice || row.isEditingMargin || row.isEditingYouEarn;
      if (!rowHasEdits) return;

      if (event.type === 'bulkSubmitEditPriceListEntries') {
        trigger().then((isFormValid) => {
          if (isFormValid) onSubmit(getValues());
        });
      }
    };

    subscribe('bulkSubmitEditPriceListEntries', saveListener);
    return () => {
      unsubscribe('bulkSubmitEditPriceListEntries', saveListener);
    };
  }, [
    getValues,
    onSubmit,
    row.isEditingYourRetailPrice,
    row.isEditingMargin,
    row.isEditingYouEarn,
    trigger,
  ]);

  // Listen to the bulk cancel event
  useEffect(() => {
    const cancelListener = (event: CustomEvent) => {
      const rowHasEdits =
        row.isEditingYourRetailPrice || row.isEditingMargin || row.isEditingYouEarn;
      if (!rowHasEdits) return;

      if (event.type === 'bulkCancelEditPriceListEntries') {
        isEditing.setFalse();
        updateRow(priceListEntryId, {
          isEditingYourRetailPrice: false,
          isEditingMargin: false,
          isEditingYouEarn: false,
        });
        reset();
      }
    };

    subscribe('bulkCancelEditPriceListEntries', cancelListener);
    return () => {
      unsubscribe('bulkCancelEditPriceListEntries', cancelListener);
    };
  }, [isEditing, priceListEntryId, reset, row, updateRow]);

  // Render
  return (
    <SearchResult checkboxUi={checkboxUi} onClick={onClick}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid columns="2fr minmax(300px, 5fr) 1fr" alignItemsCenter justifyContentSpaceAround>
          <RowStart row={row} viewMode={viewMode} />

          <Flex alignItemsCenter justifySpaceAround alignContentEnd>
            {/* Your Retail Price */}
            <Flex column data-testid="your-retail-price-display">
              <Body variant="Body/Body Small" color="bodyTextSecondary">
                Your Retail Price
              </Body>

              {row.isEditingYourRetailPrice ? (
                <Flex column gap="4px">
                  <PricingInputWrapper>
                    <InnerInputSymbol>
                      {getCurrencySymbol(row.entry.sellerRetailPrice.currency)}
                    </InnerInputSymbol>
                    <PricingInput
                      type="number"
                      step="0.01"
                      data-testid="your-retail-price-input"
                      min={row.entry.dropshipPrice.amount / 100}
                      {...register('yourRetailPrice', {
                        validate: validateYourRetailPriceInput,
                      })}
                      // Prevents row's selected state from being toggled.
                      onClick={(e) => e.stopPropagation()}
                    />
                  </PricingInputWrapper>
                  {errors.yourRetailPrice && (
                    <Body variant="Body/Body Small" color="error500">
                      {errors.yourRetailPrice.message}
                    </Body>
                  )}
                </Flex>
              ) : (
                <Flex gap="4px">
                  <Body variant="Body/Body Small">
                    {humanizeMoney(row.entry.sellerRetailPrice)}
                  </Body>
                  <Tooltip>
                    <Tooltip.Trigger asChild>
                      <Icon name="info" color="gray300" />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      The price synced from your platform. Any updates made to the price in Modern
                      Dropship will not affect the price on your platform.
                    </Tooltip.Content>
                  </Tooltip>
                </Flex>
              )}
            </Flex>

            <Flex column justifyCenter>
              <SubtractSymbol />
            </Flex>

            {/* Margin */}
            <Flex column data-testid="margin-display">
              <Body variant="Body/Body Small" color="bodyTextSecondary">
                Margin
              </Body>

              {row.isEditingMargin ? (
                <Flex column gap="4px">
                  <PricingInputWrapper onClick={(e) => e.stopPropagation()}>
                    {marginType === MarginType.Fixed && <InnerInputSymbol>$</InnerInputSymbol>}
                    {marginType === MarginType.Percent && <InnerInputSymbol>%</InnerInputSymbol>}

                    <PricingInput
                      type="number"
                      min="0"
                      step={marginType === MarginType.Fixed ? '0.01' : '1'}
                      max={maxMarginValue(marginType, row.entry.sellerRetailPrice.amount)}
                      data-testid="margin-input"
                      {...register('margin', {
                        validate: (value) =>
                          validateMarginInput(
                            marginType,
                            row.entry.sellerRetailPrice.amount,
                            row.entry.sellerRetailPrice.currency,
                            value
                          ),
                      })}
                    />
                  </PricingInputWrapper>
                  {errors.margin && (
                    <Body variant="Body/Body Small" color="error500">
                      {errors.margin.message}
                    </Body>
                  )}
                </Flex>
              ) : (
                <Flex gap="4px">
                  {(row.isEditingYourRetailPrice || row.isEditingYouEarn) && (
                    <Body variant="Body/Body Small">-- %</Body>
                  )}

                  {!row.isEditingYourRetailPrice && !row.isEditingYouEarn && (
                    <Body variant="Body/Body Small">
                      {marginType === MarginType.Fixed &&
                        getCurrencySymbol(row.entry.sellerRetailPrice.currency)}
                      {calculateMargin(
                        marginType,
                        row.entry.sellerRetailPrice.amount,
                        row.entry.dropshipPrice.amount
                      )}
                      {marginType === MarginType.Percent && '%'}
                    </Body>
                  )}
                  <Tooltip>
                    <Tooltip.Trigger asChild>
                      <Icon name="info" color="gray300" />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      This margin applies only to this SKU and overwrites the default margin.
                    </Tooltip.Content>
                  </Tooltip>
                </Flex>
              )}
            </Flex>

            <EqualSymbolContainer>
              <EqualSymbolLine />
              <EqualSymbolLine />
            </EqualSymbolContainer>

            {/* You Earn */}
            <Flex column data-testid="you-earn-display">
              <Body variant="Body/Body Small" color="bodyTextSecondary">
                You Earn
              </Body>

              {row.isEditingYouEarn ? (
                <Flex column gap="4px">
                  <PricingInputWrapper>
                    <InnerInputSymbol>
                      {getCurrencySymbol(row.entry.sellerRetailPrice.currency)}
                    </InnerInputSymbol>
                    <PricingInput
                      type="number"
                      step="0.01"
                      data-testid="you-earn-input"
                      max={row.entry.sellerRetailPrice.amount / 100}
                      min={0}
                      {...register('youEarn', {
                        validate: validateYouEarnInput,
                      })}
                      // Prevents row's selected state from being toggled.
                      onClick={(e) => e.stopPropagation()}
                    />
                  </PricingInputWrapper>
                  {errors.youEarn && (
                    <Body variant="Body/Body Small" color="error500">
                      {errors.youEarn.message}
                    </Body>
                  )}
                </Flex>
              ) : (
                <Flex gap="4px">
                  {row.isEditingMargin ? (
                    <Body variant="Body/Body Small">
                      {getCurrencySymbol(row.entry.dropshipPrice.currency)} --
                    </Body>
                  ) : (
                    <Body variant="Body/Body Small">{humanizeMoney(row.entry.dropshipPrice)}</Body>
                  )}

                  <Tooltip>
                    <Tooltip.Trigger asChild>
                      <Icon name="info" color="gray300" />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      The amount that your partner will pay you for fulfilling an order.
                    </Tooltip.Content>
                  </Tooltip>
                </Flex>
              )}
            </Flex>

            {/* Sug. Retail Price */}
            {shouldShowSuggestedRetailPrice.value && (
              <>
                <VerticalDivider />

                <Flex column>
                  <Body variant="Body/Body Small" color="bodyTextSecondary">
                    Sug. Retail Price
                  </Body>

                  <Flex gap="4px">
                    <Body variant="Body/Body Small">
                      {humanizeMoney(row.entry.buyerRetailPrice)}
                    </Body>
                    <Tooltip>
                      <Tooltip.Trigger asChild>
                        <Icon name="info" color="gray300" />
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        The price with your conversion rate applied. This is the price that will
                        sync to your partner&apos;s platform.
                      </Tooltip.Content>
                    </Tooltip>
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>

          <RowEnd
            isEditing={isEditing.value}
            onCancelEdit={onCancelEdit}
            isValid={isValid}
            setEditingYourRetailPrice={setEditingYourRetailPrice}
            setEditingMargin={setEditingMargin}
            setEditingYouEarn={setEditingYouEarn}
            onRemoveFromPriceListClick={onRemoveFromPriceListClick}
          />
        </Grid>
      </form>

      <DeletePriceListEntryConfirmationModal
        onCancel={shouldShowDeleteConfirmationModal.setFalse}
        onConfirm={onConfirmDeletePriceListEntry}
        shouldShow={shouldShowDeleteConfirmationModal.value}
        priceListEntry={row}
      />
    </SearchResult>
  );
};

export default PriceListEntrySearchResult;
