import { useQuery } from '@tanstack/react-query';

import { getAddresses } from 'utils/api/integrations';

const useFetchAddresses = (platformConnected: boolean) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ['platformAddresses'],
    queryFn: () => getAddresses(),
    onSuccess: () => {
      console.info('Fetched Addresses');
    },
    onError: (err) => {
      console.error('Fetch Addresses Error', err);
    },
    enabled: platformConnected,
  });

  return {
    addresses: data?.data,
    isLoading,
    error,
  };
};

export default useFetchAddresses;
