/**
 * NOTE: Don't use this way of showing alerts. It was added as an example of how to generalize alert control through redux,
 * but we should keep alert control local to pages / components that are using the alerts. This should only be extended upon
 * for global alerts. For an example of local alert control, look at Message. A more reusable alert component will eventually
 * be created from this style/design. We should aim to remove this and keep alerts local while still dispatching actions through redux
 * like it is done in the new settings pages.
 */
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid4 } from 'uuid';

const errorMap = {
  general: 'Something went wrong, please contact support@convictional.com if the problem persists',
  timedOut: 'The request timed out, please check your network connection',
  unauthorized: 'Unauthorized, please login again',
  companyExists:
    'This company has already been setup, please have the company administrator invite you to join.',
};

const getHumanErrorMessage = (errorMessage) => {
  if (/unauthorized/i.test(errorMessage)) return errorMap.unauthorized;
  if (/request timed out/i.test(errorMessage)) return errorMap.timedOut;
  if (/company already exists/i.test(errorMessage)) return errorMap.companyExists;
  return errorMap.general;
};

const errorsSlice = createSlice({
  name: 'errors',
  initialState: [],
  reducers: {
    addError: (state, action) => {
      const { alertId, code, message } = action.payload;
      state.push({
        alertId,
        id: uuid4(),
        message: getHumanErrorMessage(message, code),
      });
    },
    removeErrorsByAlertId: (state, action) => {
      return state.filter((err) => err.alertId !== action.payload);
    },
    removeErrorById: (state, action) => {
      return state.filter((err) => err.id !== action.payload);
    },
    clearAllErrors: () => {
      return [];
    },
  },
});

export const { addError, removeErrorById, removeErrorsByAlertId, clearAllErrors } =
  errorsSlice.actions;

export default errorsSlice.reducer;
