import type { Response } from 'types/api';
import { getBlobRequest, postRequest } from 'utils/request';

interface UploadParams {
  bytes: number[];
  fileType: string;
  fileName: string;
  tags?: string[];
  sharedWithCompany?: boolean;
  partnersWithViewAccess?: {
    partnerId: string;
    name: string;
  }[];
}

export interface DocumentResponse {
  documentId: string;
}

type UploadImageParams = UploadParams;
type UploadImageResponse = Response<DocumentResponse>;

export const uploadImage = (params: UploadImageParams): Promise<UploadImageResponse> => {
  const { bytes, fileType, fileName, tags, sharedWithCompany, partnersWithViewAccess } = params;
  const formData = new FormData();
  const blob = new Blob([new Uint8Array(bytes)], { type: fileType });

  formData.append('fileType', params.fileType);
  formData.append('fileName', fileName);
  if (tags) {
    formData.append('tags', JSON.stringify(tags));
  }
  if (sharedWithCompany) {
    formData.append('sharedWithCompany', JSON.stringify(sharedWithCompany));
  }
  if (partnersWithViewAccess) {
    formData.append('partnersWithViewAccess', JSON.stringify(partnersWithViewAccess));
  }
  formData.append('file', blob, fileName);

  return postRequest('/documents/images', formData);
};

export type UploadFileParams = UploadParams;
export type UploadFileResponse = Response<DocumentResponse>;

export const uploadFile = (params: UploadFileParams): Promise<UploadFileResponse> => {
  const { bytes, fileName, tags, sharedWithCompany, partnersWithViewAccess } = params;
  const formData = new FormData();
  const blob = new Blob([new Uint8Array(bytes)]);

  formData.append('fileType', params.fileType);
  formData.append('fileName', fileName);
  if (tags) {
    formData.append('tags', JSON.stringify(tags));
  }
  if (sharedWithCompany) {
    formData.append('sharedWithCompany', JSON.stringify(sharedWithCompany));
  }
  if (partnersWithViewAccess) {
    formData.append('partnersWithViewAccess', JSON.stringify(partnersWithViewAccess));
  }
  formData.append('file', blob, fileName);

  return postRequest('/documents/files', formData);
};

export const getDocument = (documentId: string): Promise<Blob> =>
  getBlobRequest(`/documents/${documentId}`);
