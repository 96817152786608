import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import useFloatingChatPrompt from 'hooks/useFloatingChatPrompt';
import GroupAddSVG from 'images/group_add.svg';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsSeller } from 'store/selectors/me/company';
import NoResultsCard from 'storybook/stories/cells/Card/NoResultsCard';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { getPriceLists } from 'utils/api/priceLists';

interface GuideButtonProps {
  isSeller: boolean;
}

const GuideButton = ({ isSeller }: GuideButtonProps) => (
  <SecondaryButton
    as={SupportLink}
    article={isSeller ? 'xhpwmqqltq' : 'myjn4aiax9'}
    $iconName="help"
  >
    Guide
  </SecondaryButton>
);

const EmptyPartnersPage = () => {
  const isSeller = useSelector(selectIsSeller);

  const fetchingPriceList = useQuery({
    queryKey: ['getPriceLists', { limit: 1 }],
    queryFn: () => getPriceLists({ limit: 1 }),
  });

  const hasPriceList = (fetchingPriceList.data?.data ?? []).length > 0;
  const isPriceListNeeded = isSeller && !hasPriceList;

  const { tryPrompting } = useFloatingChatPrompt();

  useEffect(() => {
    tryPrompting();
  }, [tryPrompting]);

  if (fetchingPriceList.isLoading) return null;

  return (
    <NoResultsCard
      data-testid="empty-page"
      imageSrc={GroupAddSVG}
      title={
        isPriceListNeeded
          ? "You'll need a price list first"
          : "You don't have any active partnerships"
      }
      description={
        isPriceListNeeded
          ? 'Click the button below to visit the Price Lists page to complete this step.'
          : 'Use the section to the right to complete this step.'
      }
    >
      {isPriceListNeeded && (
        <Flex gap="16px">
          <GuideButton isSeller={isSeller} />
          <PrimaryButton as={Link} to="/prices" $iconName="request_quote">
            Go to Price Lists
          </PrimaryButton>
        </Flex>
      )}
    </NoResultsCard>
  );
};

export default EmptyPartnersPage;
