import type { Order, OrderItem } from 'types/models/order';
import type { Return } from 'types/models/return';

// Order Return Summary Status
export enum OrderReturnSummary {
  Unreturned = 'unreturned',
  HasPendingReturns = 'has_pending_returns',
  PartiallyReturned = 'partially_returned',
  FullyReturned = 'fully_returned',
}

const returnIsPending = (r: Return) => r.status === 'pending';
const returnIsAccepted = (r: Return) => {
  const acceptedDate = new Date(r.acceptedAt);
  return acceptedDate.getTime() > 0;
};

export const allOrderItemsReturned = (returns: Return[], orderItems: OrderItem[]) => {
  const quantitiesReturnedByItemId = returns.reduce(
    (acc, r) => {
      const quantity = acc[r.orderItemId] || 0;
      return { ...acc, [r.orderItemId]: quantity + r.quantity };
    },
    {} as Record<string, number>
  );

  return orderItems.every((item) => {
    const quantityReturned = quantitiesReturnedByItemId[item._id] || 0;
    return quantityReturned === item.quantity;
  });
};

export const getOrderReturnStatus = (order: Order): OrderReturnSummary => {
  // If there are any pending returns, the order is in a "pending returns" state
  const pendingReturns = order.returns?.filter(returnIsPending) || [];
  if (pendingReturns.length > 0) {
    return OrderReturnSummary.HasPendingReturns;
  }

  // Determine if the order is fully or partially returned
  const acceptedReturns = order.returns?.filter(returnIsAccepted) || [];
  if (acceptedReturns.length === 0) {
    return OrderReturnSummary.Unreturned;
  }

  if (allOrderItemsReturned(acceptedReturns, order.items || [])) {
    return OrderReturnSummary.FullyReturned;
  }

  return OrderReturnSummary.PartiallyReturned;
};
