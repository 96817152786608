import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import download from 'downloadjs';
import useAlertQueue from 'hooks/useAlertQueue';
import { useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Divider from 'storybook/stories/molecules/Divider';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import RadioButton from 'storybook/stories/molecules/RadioButton';
import styled from 'styled-components';
import { exportPriceListCSV, type PriceListCsvExportOptions } from 'utils/api/priceLists';

const Wrapper = styled(Flex)`
  padding: 24px 12px 0 12px;
`;

export enum PriceListCsvExportOption {
  UnpricedOnly = 'unpricedOnly',
  PricedOnly = 'pricedOnly',
}

interface FormFields {
  exportOption?: PriceListCsvExportOption;
}

export interface ExportPriceListCsvModalProps {
  shouldShowModal: boolean;
  onDismiss: () => void;
  priceListId: string;
  priceListName: string;
}

const ExportPriceListCsvModal = ({
  shouldShowModal,
  onDismiss,
  priceListId,
  priceListName,
}: ExportPriceListCsvModalProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const { handleSubmit, register } = useForm<FormFields>({
    defaultValues: {
      exportOption: undefined,
    },
  });

  const exportToCSV = useMutation({
    mutationFn: (params: PriceListCsvExportOptions) => exportPriceListCSV(priceListId, params),
    onSuccess: (data) => {
      onDismiss();
      download(data, `${priceListName}.csv`, 'text/csv');
      addSuccessAlert('Success', 'Your price list entries have been exported to a CSV.');
    },
    onError: (err: Error) => {
      addErrorAlert('Something went wrong', err.message);
    },
  });

  const onSubmit = (data: FormFields) => {
    const params: PriceListCsvExportOptions = {};

    if (data.exportOption) {
      params.exportOption = data.exportOption;
    }

    exportToCSV.mutate(params);
  };

  return (
    <Modal.Root centered show={shouldShowModal} onHide={onDismiss} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
            What products would you like to price in your CSV?
          </Heading>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Wrapper column gap="32px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex column gap="32px">
              <Flex column gap="24px">
                <RadioButton {...register('exportOption')} value={undefined} defaultChecked>
                  <Flex gap="8px" alignContentCenter>
                    All active products
                    <SupportLink article="9vzrzqkyqh">
                      <Icon color="blue500" name="help" />
                    </SupportLink>
                  </Flex>
                </RadioButton>
                <RadioButton
                  {...register('exportOption')}
                  value={PriceListCsvExportOption.UnpricedOnly}
                >
                  Un-priced products only
                </RadioButton>
                <RadioButton
                  {...register('exportOption')}
                  value={PriceListCsvExportOption.PricedOnly}
                >
                  Priced products only
                </RadioButton>
              </Flex>

              <Flex justifyEnd>
                <TertiaryButton kind="neutral" onClick={onDismiss}>
                  Cancel
                </TertiaryButton>
                <PrimaryButton $iconName="download" type="submit">
                  Export CSV
                </PrimaryButton>
              </Flex>
            </Flex>
          </form>

          <Divider />

          <Flex column gap="12px">
            <Body variant="Body/Regular" color="bodyTextSecondary">
              CSV Tips:
            </Body>

            <ul>
              <li>
                <Body variant="Body/Body Small">
                  To remove a product from your price list, type &quot;y&quot; in the
                  &quot;Remove?&quot; column
                </Body>
              </li>
              <li>
                <Body variant="Body/Body Small">
                  Only &quot;Your Retail Price&quot; and &quot;You Earn&quot; can be edited
                </Body>
              </li>
              <li>
                <Body variant="Body/Body Small">For more tips, please visit our</Body>{' '}
                <SupportLink article="am55txvvky#price_list_editing_using_a_csv_file">
                  <Body variant="Body/Body Small">Price List CSV Guide</Body>
                </SupportLink>
              </li>
            </ul>
          </Flex>
        </Wrapper>
      </Modal.Body>
    </Modal.Root>
  );
};

export default ExportPriceListCsvModal;
