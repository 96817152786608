import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'components/Common/Spinner';
import DashboardPage from 'components/Dashboard/DashboardPage';
import ActionTemplateSection from 'containers/PartnersPage/ActionTemplates/ActionTemplateSection';
import EmptyPartnersPage from 'containers/PartnersPage/EmptyPartnersPage';
import InvitePartner from 'containers/PartnersPage/InvitePartner';
import PartnerActions from 'containers/PartnersPage/PartnerActions';
import type { PartnerFilters } from 'containers/PartnersPage/PartnerFilters';
import PartnerOutboundInvites from 'containers/PartnersPage/PartnerOutboundInvites';
import PartnerSearch from 'containers/PartnersPage/PartnerSearch';
import PendingInviteCard from 'containers/PartnersPage/PendingInviteCard';
import useAlertQueue from 'hooks/useAlertQueue';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store';
import { selectMe } from 'store/selectors/me';
import { selectIsBuyer } from 'store/selectors/me/company';
import { getAllActionsReceived } from 'store/slices/partners';
import Alert from 'storybook/stories/cells/Alert';
import Page from 'storybook/stories/cells/Page';
import Tabs from 'storybook/stories/cells/Tabs';
import Badge from 'storybook/stories/molecules/Badge';
import { useDocumentTitle } from 'usehooks-ts';
import type ApiError from 'utils/ApiError';
import { getPartners, type GetPartnersParams } from 'utils/api/partners';
import { isAccountTypeBuyer } from 'utils/cookies';

const LIMIT = 25;

const usePartnerSearch = (inputValue: string, filters: PartnerFilters, page: number) => {
  const isBuyer = useSelector(selectIsBuyer);
  const alertQueue = useAlertQueue();

  return useQuery({
    queryKey: [
      'getPartners',
      { page, limit: LIMIT, showOnboardingStatus: 'true', ...filters } as GetPartnersParams,
      inputValue,
      isBuyer,
    ] as const,
    queryFn: ({ queryKey }) => {
      const [, params, partnerName] = queryKey;

      if (isBuyer) {
        params.sellerName = partnerName;
      } else {
        params.buyerName = partnerName;
      }

      return getPartners(params);
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Unable to find partners', error.message);
    },
  });
};

enum TabView {
  Partners,
  Actions,
}

const PageWrapper = ({ children }: React.PropsWithChildren<{}>) => (
  <DashboardPage>
    <Page>
      <Page.Head title="Partners" />
      <Page.Body>
        <Grid columns="66% auto" gap="24px">
          <Grid.Item>{children}</Grid.Item>
          <Flex column gap="24px">
            <PendingInviteCard />
            <InvitePartner />
            <PartnerOutboundInvites />
          </Flex>
        </Grid>
      </Page.Body>
    </Page>
  </DashboardPage>
);

const PartnersPage = () => {
  useDocumentTitle('Partners');

  const dispatch = useAppDispatch();

  const [page, setPage] = useState(0);
  const [partnerName, setPartnerName] = useState('');
  const [selectedTab, setSelectedTab] = useState<TabView>(TabView.Partners);
  const [filters, setFilters] = useState<PartnerFilters>({
    signedUp: 'true',
  });

  const { allActionsReceived } = useAppSelector(({ partners: p }) => p);
  const { company, user } = useAppSelector(selectMe);

  const fetchingPartnerSearch = usePartnerSearch(partnerName, filters, page);
  const partners = fetchingPartnerSearch.data?.data ?? [];

  const isSearching = partnerName !== '';

  // Get actions
  const refreshActions = useCallback(() => {
    if (company && company._id) {
      const assignedActionsParams = {
        assignedTo: company._id,
        page,
        hasPartner: true,
        completed: false,
      };
      dispatch(getAllActionsReceived(assignedActionsParams));
    }
  }, [company, page, dispatch]);

  useEffect(() => {
    refreshActions();
  }, [company, refreshActions]);

  if (!company || !user) {
    return null;
  }

  if (fetchingPartnerSearch.isLoading && !isSearching) {
    return (
      <PageWrapper>
        <Spinner />
      </PageWrapper>
    );
  }

  if (partners.length === 0 && page === 0 && !isSearching) {
    return (
      <PageWrapper>
        <EmptyPartnersPage />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Flex column gap="24px">
        <Tabs>
          <Tabs.Item
            $iconName="group"
            selected={selectedTab === TabView.Partners}
            onClick={() => setSelectedTab(TabView.Partners)}
          >
            Partners
          </Tabs.Item>

          <Tabs.Item
            $iconName="event_list"
            selected={selectedTab === TabView.Actions}
            onClick={() => setSelectedTab(TabView.Actions)}
          >
            <Flex gap="4px" alignItemsCenter>
              Actions
              <Badge variant="info" count={allActionsReceived?.length} />
            </Flex>
          </Tabs.Item>
        </Tabs>

        {selectedTab === TabView.Partners && (
          <>
            {allActionsReceived?.length > 0 && (
              <Alert type="warning" title="You have actions to complete.">
                Please visit the Actions tab to review.
              </Alert>
            )}

            <PartnerSearch
              isBuyer={isAccountTypeBuyer()}
              partners={partners}
              setPage={setPage}
              page={page}
              limit={LIMIT}
              setPartnerName={setPartnerName}
              filters={filters}
              setFilters={setFilters}
            />
          </>
        )}

        {selectedTab === TabView.Actions && (
          <>
            {partners && <PartnerActions actions={allActionsReceived} partners={partners} />}
            <ActionTemplateSection />
          </>
        )}
      </Flex>
    </PageWrapper>
  );
};

export default PartnersPage;
