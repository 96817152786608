import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import styled from 'styled-components';

import Icon from 'storybook/stories/molecules/Icon';
import { getDocument } from 'utils/api/documents';
import { getEDIRecords } from 'utils/api/edi';

import CreateEDIRecordModal from 'containers/OrderPage/CreateEDIRecordModal';
import CreateEDIRetryModal from 'containers/OrderPage/CreateEDIRetryModal';
import download from 'downloadjs';
import useAlertQueue from 'hooks/useAlertQueue';
import useScopedSentryMessage from 'hooks/useScopedSentryMessage';

const StyledTableHeader = styled.th`
  color: #5c6fad;
`;

const BadgeText = styled.p`
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 0;
`;

const ProcessedBadge = () => {
  return (
    <BadgeText className="success-badge">
      <Icon name="check_circle">Processed</Icon>
    </BadgeText>
  );
};
const AcknowledgedBadge = () => {
  return (
    <BadgeText className="success-badge">
      <Icon name="check_circle">Acknowledged</Icon>
    </BadgeText>
  );
};
const AcceptedBadge = () => {
  return (
    <BadgeText className="success-badge">
      <Icon name="check_circle">Accepted</Icon>
    </BadgeText>
  );
};
const PartialAcknowledgedBadge = () => {
  return (
    <BadgeText className="sent-badge">
      <Icon name="send">Partially Acknowledged</Icon>
    </BadgeText>
  );
};
const AcceptedWithErrorsBadge = () => {
  return (
    <BadgeText className="sent-badge">
      <Icon name="error">Accepted With Errors</Icon>
    </BadgeText>
  );
};
const PartiallyAcceptedBadge = () => {
  return (
    <BadgeText className="sent-badge">
      <Icon name="error">Partially Accepted</Icon>
    </BadgeText>
  );
};
const SentBadge = () => {
  return (
    <BadgeText className="sent-badge">
      <Icon name="send">Sent</Icon>
    </BadgeText>
  );
};
const ErrBadge = () => {
  return (
    <BadgeText className="error-badge">
      <Icon name="hide_source">Error</Icon>
    </BadgeText>
  );
};
const RejectedBadge = () => {
  return (
    <BadgeText className="error-badge">
      <Icon name="hide_source">Rejected</Icon>
    </BadgeText>
  );
};

const OrderEDIRecords = ({ order }) => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modalRecord, setModalRecord] = useState(null);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [retryRecord, setRetryRecord] = useState(null);
  const [error, setError] = useState('');
  const alertQueue = useAlertQueue();
  const sendSentryError = useScopedSentryMessage('error');

  const fetchingEdiRecords = useQuery({
    queryKey: ['getEDIRecords', order._id],
    queryFn: () => getEDIRecords(order._id),
    enabled: order?._id !== null,
    onError: (err) => {
      console.warn('error', err.message);
    },
  });
  const ediRecords = fetchingEdiRecords?.data?.data;

  const downloadingDocument = useMutation({
    mutationFn: (documentId) => getDocument(documentId),
    onSuccess: async (blob) => {
      download(blob);
    },
    onError: (apiError) => {
      setError('Unable to download file');
      alertQueue.addErrorAlert('Something went wrong', 'Please contact support@convictional.com');
      sendSentryError('Unable to download file', { extra: { apiError } });
    },
  });

  const DisplayEDIRecords = ({ records }) => {
    return records.map((record) => {
      let hasErrors;
      let badge;
      if (record.errors.length > 0) {
        badge = <ErrBadge />;
        hasErrors = true;
      } else if (record.routing === 'inbound') {
        badge = <ProcessedBadge />;
      } else {
        const acks = record.outboundAcknowledgement.segments;
        if (acks.length !== 1) {
          const allAcked = acks.every((ack) => ack.acknowledged);
          const noneAcked = acks.every((ack) => !ack.acknowledged);
          if (allAcked) {
            badge = <AcknowledgedBadge />;
          } else if (noneAcked) {
            badge = <SentBadge />;
          } else {
            badge = <PartialAcknowledgedBadge />;
          }
        } else {
          const ack = acks[0];
          if (ack.status === 'accepted') {
            badge = <AcceptedBadge />;
          } else if (ack.status === 'accepted_with_errors') {
            badge = <AcceptedWithErrorsBadge />;
          } else if (ack.status === 'partially_accepted') {
            badge = <PartiallyAcceptedBadge />;
          } else if (ack.status === 'rejected') {
            badge = <RejectedBadge />;
          } else if (ack.acknowledged) {
            badge = <AcknowledgedBadge />;
          } else {
            badge = <SentBadge />;
          }
        }
      }
      let infoClassName = 'modify-card-details-btn';
      if (hasErrors) {
        infoClassName += ' error-badge';
      }
      return (
        <tr key={record.id}>
          <td>{record.interchangeControlNumber}</td>
          <td>{badge}</td>
          <td className="text-right nowrap">
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              className="modify-card-details-btn"
              onClick={() => downloadingDocument.mutate(record.documentId)}
              title="Download this EDI document"
              aria-label="Download this EDI document"
            >
              <Icon name="download" />
            </button>
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              className="modify-card-details-btn"
              onClick={() => {
                setRetryRecord(record);
                setShowRetryModal(true);
              }}
              title="Retry this EDI record"
              aria-label="Retry this EDI record"
            >
              <Icon name="repeat" />
            </button>
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              className={infoClassName}
              onClick={() => {
                setModalRecord(record);
                setShowDetailModal(true);
              }}
              title="Additional details"
              aria-label="Additional details"
            >
              <Icon name="info" />
            </button>
          </td>
        </tr>
      );
    });
  };

  if (!ediRecords) return null;
  let ediRecordsExist = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const recordType in ediRecords) {
    if (ediRecords[recordType] !== null) {
      ediRecordsExist = true;
    }
  }
  if (!ediRecordsExist) return null;

  if (fetchingEdiRecords.isLoading)
    return (
      <div className="card p-4">
        <h4 className="mb-4">EDI Records</h4>
        <div>Loading...</div>
      </div>
    );

  return (
    <div className="card p-4">
      <h4 className="mb-4">EDI Records</h4>
      {error && <div className="alert alert-danger">{error}</div>}
      <table className="table table-sm">
        <thead>
          <tr key="header-row">
            <StyledTableHeader scope="col">Interchange Control Number</StyledTableHeader>
            <StyledTableHeader scope="col">Status</StyledTableHeader>
            <StyledTableHeader scope="col" />
          </tr>
        </thead>
        <tbody>
          {ediRecords.purchaseOrders && [
            <tr className="rowHeader" key="850-head">
              <td colSpan="4">
                <h4 className="m-0">Purchase Orders (850)</h4>
              </td>
            </tr>,
            <DisplayEDIRecords records={ediRecords.purchaseOrders} />,
          ]}
          {ediRecords.purchaseOrderAcknowledgements && [
            <tr className="rowHeader" key="855-head">
              <td colSpan="4">
                <h4 className="m-0">Purchase Order Acknowledgements (855)</h4>
              </td>
            </tr>,
            <DisplayEDIRecords records={ediRecords.purchaseOrderAcknowledgements} />,
          ]}
          {ediRecords.shipNotices && [
            <tr className="rowHeader" key="856-head">
              <td colSpan="4">
                <h4 className="m-0">Ship Notices (856)</h4>
              </td>
            </tr>,
            <DisplayEDIRecords records={ediRecords.shipNotices} />,
          ]}
          {ediRecords.invoices && [
            <tr className="rowHeader" key="810-head">
              <td colSpan="4">
                <h4 className="m-0">Invoices (810)</h4>
              </td>
            </tr>,
            <DisplayEDIRecords records={ediRecords.invoices} />,
          ]}
        </tbody>
      </table>
      <CreateEDIRecordModal
        record={modalRecord}
        showModal={showDetailModal}
        onDismiss={() => {
          setShowDetailModal(false);
        }}
      />
      <CreateEDIRetryModal
        record={retryRecord}
        showModal={showRetryModal}
        onDismiss={() => {
          setShowRetryModal(false);
        }}
      />
    </div>
  );
};
export default OrderEDIRecords;
