// Values obtainable here:
// https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
const ENTER_KEY_VALUE = 'Enter';
const SPACE_KEY_VALUES = [' ', 'Spacebar'];

export const isEnterKey = <Type extends Element>(event: React.KeyboardEvent<Type>) =>
  event.key === ENTER_KEY_VALUE;

export const isSpacebarKey = <Type extends Element>(event: React.KeyboardEvent<Type>) =>
  SPACE_KEY_VALUES.includes(event.key);
