import Flex from '@react-css/flex';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import { selectSchemaMapping, setSchemaMapping } from 'store/slices/edi/schema';
import Icon from 'storybook/stories/molecules/Icon';
import {
  RELATION_TYPE_ATTRIBUTE,
  RELATION_TYPE_CONSTANT,
  RELATION_TYPE_METAFIELD,
  RELATION_TYPE_VARIABLE,
  formatFriendlyType,
  formatKey,
} from 'utils/edi';

const FlexContainer = styled.div`
  display: flex;
`;

const Chip = styled.div`
  border-radius: 4px;
  color: #12263f;
  font-size: 10px;
  font-weight: 600;
  margin-right: 5px;
  padding: 1px 4px;
  background: #d2ddec;
`;

const ButtonWithIcon = styled.button`
  background: none;
  border: none;
  color: #2c7be5;
  font-size: 14px;
  > i {
    margin-right: 2px;
  }
`;

const MappingOptionName = styled.p`
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: 600;
`;
const MappingOptionDescription = styled.p`
  font-size: 12px;
  margin-bottom: -5px;
  color: #6e84a3;
`;

const AdvancedHeader = styled.h4`
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: grey;
`;

const InputLabel = styled.label`
  font-size: 12px;
  margin-bottom: 0px;
  color: grey;
`;

const MappingTranslation = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const componentOptions = {
  DropdownIndicator: null,
};

const formatMappingOptionLabel = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <MappingOptionName>{props.data.label}</MappingOptionName>
      <MappingOptionDescription>{props.data.description}</MappingOptionDescription>
    </components.Option>
  );
};

const MappingForm = (props) => {
  const {
    scopeName = '',
    info = {},
    elementSelected = '',
    mappings = {},
    sortedScopes = [],
    segment = {},
  } = props;

  const mapping = selectSchemaMapping(mappings, sortedScopes, scopeName, elementSelected);
  const { constant, attributeKey, metafieldKey, metafieldNamespace } = mapping.relation;
  const { required, min, max, allowed } = mapping.validation;
  const element = info.elements[mapping.elementIndex];

  const getRelationTypeValue = () => {
    if (mapping.relation.type === '') {
      return 'none';
    }
    if (mapping.relation.type === RELATION_TYPE_VARIABLE) {
      return mapping.relation.variable;
    }
    return mapping.relation.type;
  };

  const relationTypeValue = getRelationTypeValue();

  const dispatch = useDispatch();

  const [isEditingAdvanced, setIsEditingAdvanced] = useState(false);
  const toggleAdvanced = () => {
    setIsEditingAdvanced(!isEditingAdvanced);
  };
  useEffect(() => {
    // exit advanced view if element being configured changes
    setIsEditingAdvanced(false);
  }, [elementSelected, setIsEditingAdvanced]);

  const inferRelationType = (value) => {
    if (
      value !== RELATION_TYPE_CONSTANT &&
      value !== RELATION_TYPE_METAFIELD &&
      value !== RELATION_TYPE_ATTRIBUTE
    ) {
      return RELATION_TYPE_VARIABLE;
    }
    return value;
  };

  const handleMappingDropdownChange = (e) => {
    const { segmentId, segmentIndex, elementIndex } = mapping;
    const { value } = e;
    const type = inferRelationType(value);
    const updatedMapping = {
      ...mapping,
      relation: {
        type,
      },
    };
    if (type === RELATION_TYPE_VARIABLE) {
      updatedMapping.relation.variable = value;
    }
    if (type === RELATION_TYPE_CONSTANT) {
      updatedMapping.relation.constant = segment.elements[elementIndex];
    }
    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: formatKey(segmentId, segmentIndex, elementIndex),
        mapping: updatedMapping,
      })
    );
  };

  const setConstant = (e) => {
    const { value } = e.target;
    const updatedMapping = {
      ...mapping,
      relation: {
        ...mapping.relation,
        constant: value,
      },
    };
    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const setAttributeKey = (e) => {
    const { value } = e.target;
    const updatedMapping = {
      ...mapping,
      relation: {
        ...mapping.relation,
        attributeKey: value,
      },
    };
    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const setMetafieldNamespace = (e) => {
    const { value } = e.target;
    const updatedMapping = {
      ...mapping,
      relation: {
        ...mapping.relation,
        metafieldNamespace: value,
      },
    };
    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const setMetafieldKey = (e) => {
    const { value } = e.target;
    const updatedMapping = {
      ...mapping,
      relation: {
        ...mapping.relation,
        metafieldKey: value,
      },
    };
    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const setValidationRequired = () => {
    const updatedMapping = {
      ...mapping,
      validation: {
        ...mapping.validation,
        required: !mapping.validation.required,
      },
    };
    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const setValidationMin = (e) => {
    const { value } = e.target;
    const updatedMapping = {
      ...mapping,
      validation: {
        ...mapping.validation,
        min: Number(value),
      },
    };
    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const setValidationMax = (e) => {
    const { value } = e.target;
    const updatedMapping = {
      ...mapping,
      validation: {
        ...mapping.validation,
        max: Number(value),
      },
    };
    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const setValidationAllowed = (value) => {
    let safeAllowed = [];
    if (!mapping.validation.allowed) {
      safeAllowed = [value];
    } else {
      safeAllowed = [...mapping.validation.allowed, value];
    }
    const updatedMapping = {
      ...mapping,
      validation: {
        ...mapping.validation,
        allowed: safeAllowed,
      },
    };
    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const [allowedInputValue, setAllowedInputValue] = useState('');
  const handleAllowedInputChange = (value) => {
    setAllowedInputValue(value);
  };
  const handleAllowedChange = (values) => {
    const updatedMapping = {
      ...mapping,
      validation: {
        ...mapping.validation,
        allowed: values.map((val) => val.value),
      },
    };
    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const handleAllowedKeyDown = (e) => {
    const { key } = e;
    if (!allowedInputValue) return;
    switch (key) {
      case 'Enter':
      case 'Tab':
        setValidationAllowed(allowedInputValue);
        setAllowedInputValue('');
        e.preventDefault();
        break;
      default:
    }
  };

  const addNewMappingTranslation = () => {
    const translationMapping = {
      convictionalValue: '',
      externalValue: '',
    };
    const translation = {
      type: 'mapping',
      mapping: [translationMapping],
    };
    // initialize if none exist
    if (!mapping.translation) {
      const updatedMapping = {
        ...mapping,
        translation,
      };
      dispatch(
        setSchemaMapping({
          scopeName,
          lookupKey: elementSelected,
          mapping: updatedMapping,
        })
      );
      return;
    }

    const updatedMapping = {
      ...mapping,
      translation: {
        ...mapping.translation,
        mapping: [...mapping.translation.mapping, translationMapping],
      },
    };
    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const buildNewArray = (oldArray, newItemIndex, direction, value) => {
    if (oldArray.length <= newItemIndex) {
      return [
        {
          convictionalValue: '',
          externalValue: '',
          [direction]: value,
        },
      ];
    }
    // item already exists in the array, build new array and assign value to direction at index
    return oldArray.map((item, idx) =>
      idx === newItemIndex ? { ...item, [direction]: value } : item
    );
  };

  const setTranslationMapping = (index, direction, value) => {
    let prevMapping = [];

    if (mapping.translation) {
      prevMapping = mapping.translation.mapping;
    }

    const updatedMapping = {
      ...mapping,
      translation: {
        type: 'mapping',
        mapping: buildNewArray(prevMapping, index, direction, value),
      },
    };

    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const deleteMappingTranslation = (indexToRemove) => {
    const updatedMapping = {
      ...mapping,
      translation: {
        ...mapping.translation,
        mapping: mapping.translation.mapping.filter((_, index) => index !== indexToRemove),
      },
    };

    dispatch(
      setSchemaMapping({
        scopeName,
        lookupKey: elementSelected,
        mapping: updatedMapping,
      })
    );
  };

  const groupedOptions = [
    {
      label: 'Modern Dropship Values',
      options: info.variables
        ? info.variables
            .filter((variable) => variable.scope === scopeName)
            .map((variable) => {
              return {
                value: variable.variable,
                label: variable.name,
                description: variable.description,
              };
            })
        : [],
    },
    {
      label: 'Custom Values',
      options: [
        {
          value: RELATION_TYPE_CONSTANT,
          label: 'Constant',
          description: 'A value that will always be the same, often used for identifiers',
        },
        {
          value: RELATION_TYPE_METAFIELD,
          label: 'Metafield',
          description: 'A custom value you have assigned to your trading partner',
        },
        {
          value: RELATION_TYPE_ATTRIBUTE,
          label: 'Attribute',
          description:
            'A custom value assigned to a transactional resource, like custom product fields',
        },
      ],
    },
  ];

  const findSelectedOptionFromValue = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const group of groupedOptions) {
      const selected = group.options.find((option) => option.value === relationTypeValue);
      if (selected) {
        return selected;
      }
    }
    return null;
  };

  const selectedOption = findSelectedOptionFromValue();

  return (
    <>
      <Flex alignItemsStart>
        <Flex.Item grow={1}>
          <h4>{element.name}</h4>
        </Flex.Item>

        <ButtonWithIcon onClick={toggleAdvanced}>
          <Icon name={isEditingAdvanced ? 'west' : 'edit_note'}>
            {isEditingAdvanced ? 'Back' : 'Advanced'}
          </Icon>
        </ButtonWithIcon>
      </Flex>
      <FlexContainer className="mb-3">
        <Chip>{formatFriendlyType(element.type)}</Chip>
        <Chip>{required ? 'Mandatory' : 'Optional'}</Chip>
        <Chip>
          Min {min} / Max {max}
        </Chip>
      </FlexContainer>
      {!isEditingAdvanced ? (
        <form style={{ maxWidth: '350px' }}>
          <div className="mb-3">
            <InputLabel>Mapping</InputLabel>
            <Select
              placeholder="Select mapping"
              components={{ Option: formatMappingOptionLabel }}
              options={groupedOptions}
              onChange={handleMappingDropdownChange}
              value={selectedOption}
              menuPlacement="auto"
            />
          </div>
          {relationTypeValue === RELATION_TYPE_CONSTANT && (
            <>
              <InputLabel>Constant</InputLabel>
              <input
                onChange={setConstant}
                value={constant}
                type="text"
                placeholder="key"
                className="form-control"
              />
            </>
          )}

          {relationTypeValue === RELATION_TYPE_METAFIELD && (
            <>
              <InputLabel>Namespace</InputLabel>
              <input
                onChange={setMetafieldNamespace}
                value={metafieldNamespace}
                type="text"
                placeholder="example_namespace"
                className="form-control mb-3"
              />
              <InputLabel>Key</InputLabel>
              <input
                onChange={setMetafieldKey}
                value={metafieldKey}
                type="text"
                placeholder="example_key"
                className="form-control"
              />
            </>
          )}

          {relationTypeValue === RELATION_TYPE_ATTRIBUTE && (
            <>
              <InputLabel>Key</InputLabel>
              <input
                onChange={setAttributeKey}
                value={attributeKey}
                type="text"
                placeholder="example_key"
                className="form-control"
              />
            </>
          )}
        </form>
      ) : (
        <>
          <AdvancedHeader>Validation</AdvancedHeader>
          <div className="custom-control custom-switch mb-3">
            <input
              type="checkbox"
              id="required-toggle"
              className="custom-control-input"
              checked={required}
              onChange={setValidationRequired}
            />
            <label className="custom-control-label" htmlFor="required-toggle">
              {required ? 'Mandatory' : 'Optional'}
            </label>
          </div>
          <InputLabel>Min length</InputLabel>
          <input
            type="number"
            placeholder={1}
            className="form-control mb-3"
            value={min}
            onChange={setValidationMin}
          />
          <InputLabel>Max length</InputLabel>
          <input
            type="number"
            placeholder={5}
            className="form-control mb-3"
            value={max}
            onChange={setValidationMax}
          />
          <InputLabel>Allowed values</InputLabel>
          <Select
            components={componentOptions}
            menuIsOpen={false}
            inputValue={allowedInputValue}
            onInputChange={handleAllowedInputChange}
            onKeyDown={handleAllowedKeyDown}
            onChange={handleAllowedChange}
            className="mb-3"
            isClearable
            isMulti
            placeholder="Type a value and press enter"
            value={
              allowed &&
              allowed.map((value) => {
                return { value, label: value };
              })
            }
          />
          {mapping.translation?.mapping.length > 0 ? (
            <>
              <AdvancedHeader>Translations</AdvancedHeader>
              {mapping.translation.mapping.map((translation, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <MappingTranslation key={`translation-${index}`} className="mb-3">
                    <div className="mr-3">
                      <InputLabel>EDI Value</InputLabel>
                      <input
                        onChange={(e) =>
                          setTranslationMapping(index, 'externalValue', e.target.value)
                        }
                        value={translation.externalValue}
                        type="text"
                        placeholder="UP"
                        className="form-control"
                      />
                    </div>
                    <div>
                      <InputLabel>Modern Dropship Value</InputLabel>
                      <input
                        onChange={(e) =>
                          setTranslationMapping(index, 'convictionalValue', e.target.value)
                        }
                        value={translation.convictionalValue}
                        type="text"
                        placeholder="UPC"
                        className="form-control"
                      />
                    </div>
                    <ButtonWithIcon
                      style={{
                        height: '20px',
                        marginTop: '30px',
                        color: 'grey',
                      }}
                      onClick={() => deleteMappingTranslation(index)}
                    >
                      <Icon name="delete" />
                    </ButtonWithIcon>
                  </MappingTranslation>
                );
              })}
              <ButtonWithIcon onClick={addNewMappingTranslation}>
                <Icon name="add">Add translation</Icon>
              </ButtonWithIcon>
            </>
          ) : (
            <ButtonWithIcon onClick={addNewMappingTranslation}>
              <Icon name="add">Add translation</Icon>
            </ButtonWithIcon>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(reduxState) {
  const { ediSchemas } = reduxState;
  const { mappings } = ediSchemas.schema;
  const { sortedScopes } = ediSchemas;
  return {
    mappings,
    sortedScopes,
  };
}

export default connect(mapStateToProps)(MappingForm);

MappingForm.propTypes = {
  scopeName: PropTypes.string,
  info: PropTypes.object,
  elementSelected: PropTypes.string,
  mappings: PropTypes.object,
  sortedScopes: PropTypes.arrayOf(PropTypes.string),
  segment: PropTypes.object,
};
