import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useMutation } from '@tanstack/react-query';
import Button from 'components/Common/Button';
import UpdatedIndicator from 'components/Common/UpdatedIndicator';
import useAlertQueue from 'hooks/useAlertQueue';
import useFlagEnabled from 'hooks/useFlagEnabled';
import ProductPlaceholderSVG from 'images/product_placeholder.svg';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import Modal from 'storybook/stories/cells/Modal';
import Body from 'storybook/stories/molecules/Body';
import Divider from 'storybook/stories/molecules/Divider';
import Heading from 'storybook/stories/molecules/Heading';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import Select, { EMPTY_OPTION } from 'storybook/stories/molecules/Select';
import styled from 'styled-components';
import { updateBuyerProductVariant } from 'utils/api/buyer/products';
import { formatPriceWithCurrency } from 'utils/prices';
import SelectedVariantAttributes from './SelectedVariantAttributes';
import SelectedVariantOptions from './SelectedVariantOptions';
import SelectedVariantValidationAlert from './SelectedVariantValidationAlert';
import VariantMetafields from './VariantMetafields';

const VariantThumbnailImage = styled.img`
  max-width: 150px;
`;

const VariantThumbnail = ({ variant }) => {
  const hasImages = variant?.images?.length > 0;
  const src = hasImages ? variant.images[0].src : ProductPlaceholderSVG;
  const altText = hasImages ? variant.images[0].alt : 'No image available';

  return (
    <Flex.Item>
      <VariantThumbnailImage src={src} alt={altText} />
    </Flex.Item>
  );
};

const VariantFieldRead = ({ label, value, isBold }) => {
  return (
    <Flex column>
      <Body variant="Body/Header" color="bodyTextSecondary">
        {label}
      </Body>
      <Body
        variant={isBold && value ? 'Body/Regular Bold' : 'Body/Regular'}
        color={value ? 'bodyTextPrimary' : 'bodyTextDisabled'}
      >
        {value || 'None'}
      </Body>
    </Flex>
  );
};

const VariantField = ({
  canEdit,
  value,
  label,
  onChange = () => {},
  name,
  hasEdits,
  type = 'text',
  isBold = false,
}) => {
  return canEdit ? (
    <>
      <Label htmlFor={name}>
        {label} {hasEdits && <UpdatedIndicator className="ml-1" />}
      </Label>
      <Input
        className="form-control form-control-sm"
        aria-label={label}
        value={value}
        onChange={onChange}
        name={name}
        type={type}
      />
    </>
  ) : (
    <VariantFieldRead label={label} value={value} isBold={isBold} />
  );
};

const SelectedVariantModal = ({
  show,
  onDismiss,
  variant = {},
  originalVariant = {},
  canEdit,
  options = [],
  variantId,
  onChangeVariantField,
  isSeller,
  validationErrorMap,
  productId,
}) => {
  const {
    _id: id,
    barcode,
    barcodeType,
    code,
    dimensions,
    inventoryQuantity,
    retailPrice,
    basePrice,
    skipCount,
    sku,
    title,
    weight,
    weightUnits,
    attributes,
  } = variant;

  const { length, width, height, units } = dimensions || {
    length: '',
    width: '',
    height: '',
    units: '',
  };

  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const isProductMetafieldsEnabled = useFlagEnabled('showProductMetafields');

  const onCloseModal = () => {
    onDismiss();
  };

  const onChangeField =
    (field) =>
    ({ target: { value } }) => {
      const validPriceRegex = /^\d*\.?\d{0,2}$/;
      if (field === 'retailPrice' && !validPriceRegex.test(value)) return; // ignore a bad entry for a price
      if (field === 'barcodeType') {
        onChangeVariantField(variantId, field, value.toLowerCase());
        return;
      }
      onChangeVariantField(variantId, field, value);
    };

  const onSelectedOptionChange = (field) => (option) => {
    if (field === 'dimensions.units') {
      onChangeVariantField(variantId, 'dimensions', { ...dimensions, units: option.value });
      return;
    }
    onChangeVariantField(variantId, field, option.value);
  };

  const onChangeDimension =
    (field) =>
    ({ target: { value } }) => {
      const newDimensions = { ...dimensions, [field]: value };
      onChangeVariantField(variantId, 'dimensions', newDimensions);
    };

  const onChangeAttributes = (attrs) => {
    onChangeVariantField(variantId, 'attributes', attrs);
  };

  const updatingBuyerVariant = useMutation({
    mutationFn: (data) =>
      updateBuyerProductVariant(productId, variantId, {
        ...data,
        attributes: { ...variant.attributes },
        id: null,
      }),
    onSuccess: () => {
      addSuccessAlert('Success', 'Your variant has been successfully updated.');
      onCloseModal();
    },
    onError: (error) => {
      addErrorAlert('Something went wrong', error.message);
      console.error('Unable to update variant', error);
    },
  });

  return (
    <Modal.Root
      show={show}
      size="lg"
      aria-labelledby="variant-details-modal"
      centered
      onHide={onCloseModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Heading variant="Headings/H3" color="bodyTextSecondary" id="variant-details-modal">
          Variant Details
        </Heading>
      </Modal.Header>

      <Modal.Body>
        <SelectedVariantValidationAlert validationErrorMap={validationErrorMap} />
        <Flex gap="24px" column>
          <Grid columns="1fr 5fr" gap="24px">
            <VariantThumbnail variant={originalVariant} />
            <Flex column gap="16px">
              <Grid columns="1fr 1fr" gap="24px">
                <Grid.Item>
                  <VariantField
                    canEdit={canEdit}
                    value={title}
                    label="Title"
                    name="title"
                    hasEdits={originalVariant.title !== title}
                    onChange={onChangeField('title')}
                    isBold
                  />
                </Grid.Item>
                <Grid.Item>
                  <VariantField
                    canEdit={canEdit}
                    value={sku}
                    label="SKU"
                    name="sku"
                    hasEdits={originalVariant.sku !== sku}
                    onChange={onChangeField('sku')}
                    isBold
                  />
                </Grid.Item>
              </Grid>
              <Flex gap="24px" alignItemsBaseline>
                <Flex.Item>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {skipCount ? (
                    <Body variant="Body/Regular Bold">Inventory Not tracked</Body>
                  ) : canEdit ? (
                    <>
                      <Label htmlFor="inventoryQuantity">
                        Inventory{' '}
                        {originalVariant.inventoryQuantity !== inventoryQuantity && (
                          <UpdatedIndicator className="ml-1" />
                        )}
                      </Label>
                      <Input
                        className="form-control form-control-sm"
                        aria-label="Inventory Quantity"
                        type="number"
                        value={inventoryQuantity}
                        onChange={onChangeField('inventoryQuantity')}
                        name="inventoryQuantity"
                      />
                    </>
                  ) : (
                    <VariantFieldRead label="Inventory" value={inventoryQuantity || 0} isBold />
                  )}
                </Flex.Item>
                {!isSeller && (
                  <Flex column>
                    <VariantFieldRead
                      label="What You Pay"
                      value={
                        basePrice !== 0 && basePrice !== undefined
                          ? formatPriceWithCurrency(basePrice)
                          : 'Not priced'
                      }
                      isBold
                    />
                  </Flex>
                )}
                <Flex.Item>
                  <VariantField
                    canEdit={canEdit}
                    value={formatPriceWithCurrency(retailPrice)}
                    label={isSeller ? 'Your Retail Price' : 'Suggested Retail Price'}
                    name="retailPrice"
                    type="number"
                    onChange={onChangeField('retailPrice')}
                    hasEdits={originalVariant.retailPrice !== retailPrice}
                    isBold
                  />
                </Flex.Item>
              </Flex>
            </Flex>
          </Grid>
          <Flex gap="16px" justifySpaceBetween alignItemsBaseline>
            <Flex.Item>
              <VariantField
                canEdit={canEdit}
                value={barcode}
                label={barcodeType ? barcodeType.toUpperCase() : 'No Barcode Type'}
                name="barcode"
                hasEdits={originalVariant.barcode !== barcode}
                onChange={onChangeField('barcode')}
              />
            </Flex.Item>
            <Flex.Item>
              <VariantField
                canEdit={canEdit}
                value={barcodeType}
                label="Barcode Type"
                name="barcodeType"
                hasEdits={originalVariant.barcodeType !== barcodeType}
                onChange={onChangeField('barcodeType')}
              />
            </Flex.Item>
            <Flex.Item>
              <VariantField
                canEdit={canEdit}
                value={code}
                label="Code"
                name="code"
                hasEdits={originalVariant.code !== code}
                onChange={onChangeField('code')}
              />
            </Flex.Item>
            <Flex.Item>
              {/* eslint-disable-next-line no-nested-ternary */}
              {canEdit ? (
                <Flex column gap="12px">
                  <Label htmlFor="convictionalId">Modern Dropship ID</Label>
                  <Body variant="Body/Regular" color="bodyTextSecondary">
                    {id}
                  </Body>
                </Flex>
              ) : (
                <VariantField value={id} label="Modern Dropship ID" name="convictionalId" />
              )}
            </Flex.Item>
          </Flex>

          <Divider />

          <Flex.Item>
            <Flex gap="16px" column>
              <Heading variant="Headings/H4">Options</Heading>
              <SelectedVariantOptions
                options={options}
                variant={variant}
                originalVariant={originalVariant}
                canEdit={canEdit}
                onChangeOption={onChangeField}
              />
            </Flex>
          </Flex.Item>

          <Divider />

          <SelectedVariantAttributes
            attributes={attributes || []}
            onChangeAttributes={onChangeAttributes}
            canEdit={isSeller}
            hasEdits={!_isEqual(attributes, originalVariant.attributes)}
            validationErrors={validationErrorMap?.attributes}
          />

          <Divider />

          <Flex column gap="16px">
            <Heading variant="Headings/H4">Dimensions</Heading>

            <Grid gap="16px" columns="repeat(4, 1fr)" alignItems="baseline">
              <Grid.Item>
                <VariantField
                  canEdit={canEdit}
                  value={length}
                  label="Length"
                  name="length"
                  type="number"
                  onChange={onChangeDimension('length')}
                  hasEdits={_get(originalVariant, 'dimensions.length') !== length}
                />
              </Grid.Item>
              <Grid.Item>
                <VariantField
                  canEdit={canEdit}
                  value={width}
                  label="Width"
                  name="width"
                  type="number"
                  onChange={onChangeDimension('width')}
                  hasEdits={_get(originalVariant, 'dimensions.width') !== width}
                />
              </Grid.Item>

              <Grid.Item>
                <VariantField
                  canEdit={canEdit}
                  value={height}
                  label="Height"
                  name="height"
                  type="number"
                  onChange={onChangeDimension('height')}
                  hasEdits={_get(originalVariant, 'dimensions.height') !== height}
                />
              </Grid.Item>

              <Grid.Item>
                {canEdit ? (
                  <Flex column>
                    <Label htmlFor="units">
                      Units{' '}
                      {_get(originalVariant, 'dimensions.units') !== units && (
                        <UpdatedIndicator className="ml-1" />
                      )}
                    </Label>

                    <Select
                      defaultValue={{ value: units, label: units }}
                      onChange={onSelectedOptionChange('dimensions.units')}
                      name="units"
                      isFullWidth
                      options={[
                        EMPTY_OPTION,
                        { value: 'cm', label: 'cm' },
                        { value: 'in', label: 'in' },
                      ]}
                    />
                  </Flex>
                ) : (
                  <VariantFieldRead label="Units" value={units} />
                )}
              </Grid.Item>
            </Grid>

            <Grid gap="16px" columns="repeat(4, 1fr)" alignItems="baseline">
              <Grid.Item>
                <VariantField
                  canEdit={canEdit}
                  value={weight}
                  label="Weight"
                  name="weight"
                  type="number"
                  onChange={onChangeField('weight')}
                  hasEdits={originalVariant.weight !== weight}
                />
              </Grid.Item>
              <Grid.Item />
              <Grid.Item>
                {canEdit ? (
                  <Flex column>
                    <Label htmlFor="weightUnits">
                      Weight Units{' '}
                      {originalVariant.weightUnits !== weightUnits && (
                        <UpdatedIndicator className="ml-1" />
                      )}
                    </Label>
                    <Select
                      defaultValue={{ value: weightUnits, label: weightUnits }}
                      onChange={onSelectedOptionChange('weightUnits')}
                      name="weightUnits"
                      isFullWidth
                      options={[
                        EMPTY_OPTION,
                        { value: 'g', label: 'g' },
                        { value: 'kg', label: 'kg' },
                        { value: 'lb', label: 'lb' },
                        { value: 'oz', label: 'oz' },
                        { value: 't', label: 't' },
                      ]}
                    />
                  </Flex>
                ) : (
                  <VariantFieldRead label="Weight Units" value={weightUnits} />
                )}
              </Grid.Item>
            </Grid>
          </Flex>

          {isProductMetafieldsEnabled && (
            <>
              <Divider />
              <VariantMetafields productId={productId} variantId={variantId} />
            </>
          )}
        </Flex>
      </Modal.Body>

      <Modal.Footer>
        {isSeller && (
          <Button color="primary" size="sm" onClick={onCloseModal}>
            Done
          </Button>
        )}

        {canEdit && !isSeller && (
          <Button color="primary" size="sm" onClick={() => updatingBuyerVariant.mutate(variant)}>
            Save
          </Button>
        )}
      </Modal.Footer>
    </Modal.Root>
  );
};

export default SelectedVariantModal;
