import Flex from '@react-css/flex';
import BigCommerceSvg from 'images/bigcommerce.svg';
import MagentoSvg from 'images/magento.svg';
import ShopifySvg from 'images/shopify.svg';
import WooCommerceSvg from 'images/woocommerce.svg';
import type React from 'react';
import Body from 'storybook/stories/molecules/Body';
import DecisionButton from 'storybook/stories/molecules/Button/DecisionButton';
import RadioButton from 'storybook/stories/molecules/RadioButton';
import styled from 'styled-components';
import { Platform } from 'types/models/company';

type BaseProps = React.ComponentProps<typeof DecisionButton>;

interface ContentProps {
  isActive: boolean;
  children?: string | React.ReactNode;
}

const Content: React.FC<ContentProps> = ({ isActive, children }) => {
  return (
    <Flex alignItemsCenter gap="16px">
      <RadioButton checked={isActive} readOnly />
      {children}
    </Flex>
  );
};

const PlatformText = styled(Body).attrs(() => ({
  variant: 'Navigation/Nav Link',
  color: 'bodyTextPrimary',
}))``;

const PlatformImage = styled.img`
  height: 32px;
`;

const ShopifyDecisionButton: React.FC<BaseProps> = (props) => {
  return (
    <DecisionButton {...props} aria-label="Shopify">
      <Content isActive={props.isActive}>
        <PlatformImage src={ShopifySvg} alt="Shopify logo" />
      </Content>
    </DecisionButton>
  );
};

const MagentoDecisionButton: React.FC<BaseProps> = (props) => {
  return (
    <DecisionButton {...props} aria-label="Magento 2">
      <Content isActive={props.isActive}>
        <PlatformImage src={MagentoSvg} alt="Magento logo" />
      </Content>
    </DecisionButton>
  );
};

const WooCommerceDecisionButton: React.FC<BaseProps> = (props) => {
  return (
    <DecisionButton {...props} aria-label="WooCommerce">
      <Content isActive={props.isActive}>
        <PlatformImage src={WooCommerceSvg} alt="WooCommerce logo" />
      </Content>
    </DecisionButton>
  );
};

const BigCommerceDecisionButton: React.FC<BaseProps> = (props) => {
  return (
    <DecisionButton {...props} aria-label="BigCommerce">
      <Content isActive={props.isActive}>
        <PlatformImage src={BigCommerceSvg} alt="BigCommerce logo" />
      </Content>
    </DecisionButton>
  );
};

const CsvSftpDecisionButton: React.FC<BaseProps> = (props) => {
  return (
    <DecisionButton {...props}>
      <Content isActive={props.isActive}>
        <PlatformText>Upload CSV files to our secure FTP server</PlatformText>
      </Content>
    </DecisionButton>
  );
};

const ExternalSftpDecisionButton: React.FC<BaseProps> = (props) => {
  return (
    <DecisionButton {...props}>
      <Content isActive={props.isActive}>
        <PlatformText>Have us download CSV files from your secure FTP server</PlatformText>
      </Content>
    </DecisionButton>
  );
};

const EdiSftpDecisionButton: React.FC<BaseProps> = (props) => {
  return (
    <DecisionButton {...props}>
      <Content isActive={props.isActive}>
        <PlatformText>Upload EDI X12 files to our secure FTP server</PlatformText>
      </Content>
    </DecisionButton>
  );
};

const EdiExternalSftpDecisionButton: React.FC<BaseProps> = (props) => {
  return (
    <DecisionButton {...props}>
      <Content isActive={props.isActive}>
        <PlatformText>Have us download EDI X12 files from your secure FTP server</PlatformText>
      </Content>
    </DecisionButton>
  );
};

const OtherDecisionButton: React.FC<BaseProps> = (props) => {
  return (
    <DecisionButton {...props}>
      <Content isActive={props.isActive}>
        <PlatformText>{props.children}</PlatformText>
      </Content>
    </DecisionButton>
  );
};

interface PlatformDecisionButtonProps extends BaseProps {
  variant: Platform; // Required because 'other' means API or in-app CSVs :facepalm:
}

const PlatformDecisionButton: React.FC<PlatformDecisionButtonProps> = ({
  variant,
  children,
  ...rest
}) => {
  switch (variant) {
    case Platform.Shopify:
      return <ShopifyDecisionButton {...rest} />;
    case Platform.Magento2:
      return <MagentoDecisionButton {...rest} />;
    case Platform.WooCommerce:
      return <WooCommerceDecisionButton {...rest} />;
    case Platform.BigCommerce:
      return <BigCommerceDecisionButton {...rest} />;
    case Platform.CsvSftp:
      return <CsvSftpDecisionButton {...rest} />;
    case Platform.ExternalSftp:
      return <ExternalSftpDecisionButton {...rest} />;
    case Platform.EdiSftp:
      return <EdiSftpDecisionButton {...rest} />;
    case Platform.EdiExternalSftp:
      return <EdiExternalSftpDecisionButton {...rest} />;
    default:
      return <OtherDecisionButton {...rest}>{children}</OtherDecisionButton>;
  }
};

export default PlatformDecisionButton;
