import React from 'react';
import Modal from 'storybook/stories/cells/Modal';

import type { Dispatch } from '@reduxjs/toolkit';
import useAlertQueue from 'hooks/useAlertQueue';
import { cancelSellerOrder } from 'store/slices/ordersV2';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import type { Order20210101, OrderItem } from 'types/models/order';

import {
  BodyHeaderText,
  BoldText,
  CancellationReasonLabel,
  ItemRemovedText,
  RedAsterisk,
} from 'containers/OrderPage/Shared.styles';
import { useForm } from 'react-hook-form';
import TextAreaInput from 'storybook/stories/molecules/Input/TextAreaInput';

export interface CancelOrderModalProps {
  order: Order20210101;
  dispatch: Dispatch;
  onDismiss: () => void;
  showModal: boolean;
}

interface CancelledReasonValues {
  cancelledReason: string;
}

const CancelOrderModal = ({ order, dispatch, onDismiss, showModal }: CancelOrderModalProps) => {
  const { addSuccessAlert, addErrorAlert } = useAlertQueue();
  const fulfillments = order.fulfillments || [];
  const hasFulfillments = fulfillments.length > 0;
  const itemsToCancel = order.items?.filter((item: OrderItem) => !item.cancelled) ?? [];

  const cancelOrder = async (values: CancelledReasonValues) => {
    const data = { reason: values.cancelledReason, unfulfilledOnly: false };

    onDismiss();

    if (hasFulfillments) {
      data.unfulfilledOnly = true;
    }

    // @ts-ignore
    dispatch(cancelSellerOrder({ id: order._id, data })).then(
      (action: { error: { message: string } }) => {
        if (action.error) {
          addErrorAlert('Error cancelling order', action.error.message);
          return;
        }

        order.items?.filter((item: OrderItem) => item.cancelled);
        addSuccessAlert('Success', 'Order has been cancelled');
      }
    );
  };

  const initialValues = { cancelledReason: '' };

  const { handleSubmit, register, formState } = useForm<CancelledReasonValues>({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  return (
    <Modal.Root
      show={showModal}
      aria-labelledby="cancel-order-modal-title"
      centered
      onHide={onDismiss}
      animation={false}
    >
      <form onSubmit={handleSubmit(cancelOrder)}>
        <Modal.Header closeButton>
          <Modal.Title>Order Cancellation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <BodyHeaderText>Please provide a reason for cancelling this order:</BodyHeaderText>

          {itemsToCancel.map((item) => (
            <React.Fragment key={item._id}>
              <ItemRemovedText>
                <BoldText>Item Removed:</BoldText>
                {item.title}
              </ItemRemovedText>
            </React.Fragment>
          ))}

          <CancellationReasonLabel htmlFor="cancel-reason-input">
            Order Cancellation Reason<RedAsterisk>&#42;</RedAsterisk>
          </CancellationReasonLabel>

          <TextAreaInput
            {...register('cancelledReason', { required: 'A reason for cancellation is required' })}
            placeholder="Item is out of stock..."
            data-testid="cancel-order-reason"
          />

          {hasFulfillments && (
            <p className="small mb-4">
              Note: This order is already partially fulfilled. All unfulfilled items will be
              cancelled.
            </p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <SecondaryButton onClick={onDismiss}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" disabled={!(formState.isDirty && formState.isValid)}>
            Cancel Order
          </PrimaryButton>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default CancelOrderModal;
