import type { Options } from 'react-select';
import type { ReactSelectOption } from 'types/general';

export const DIMENSION_OPTIONS: Options<ReactSelectOption> = [
  {
    label: 'Inches',
    value: 'in',
  },
  {
    label: 'Millimeters',
    value: 'mm',
  },
  {
    label: 'Centimeters',
    value: 'cm',
  },
];

export const WEIGHT_OPTIONS: Options<ReactSelectOption> = [
  {
    label: 'Grams',
    value: 'g',
  },
  {
    label: 'Pounds',
    value: 'lb',
  },
  {
    label: 'Ounces',
    value: 'oz',
  },
];
