import Flex from '@react-css/flex';
import { useInfiniteQuery } from '@tanstack/react-query';
import Spinner from 'components/Common/Spinner';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import { HistoryTimeline } from 'storybook/stories/organisms/Timeline/HistoryTimeline';
import priceListEventComponents from 'storybook/stories/organisms/Timeline/HistoryTimeline/PriceListEvents';
import styled from 'styled-components';
import { getPriceListHistory } from 'utils/api/priceLists';

const TimelineWrapper = styled.div`
  padding: 16px;
`;

type PriceListHistoryProps = {
  priceListId: string;
};

const PriceListHistory = ({ priceListId }: PriceListHistoryProps) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['getPriceListHistory', priceListId],
    ({ pageParam = 0 }) => {
      return getPriceListHistory(priceListId, { limit: 5, page: pageParam });
    },
    {
      enabled: !!priceListId,
      getNextPageParam: (lastPage) => {
        // Parse next from last page
        if (!lastPage.next) return undefined;
        const url = new URL(lastPage.next);
        const page = url.searchParams.get('page');
        return lastPage.hasMore ? page : undefined;
      },
    }
  );

  // Combine events from every page
  const allEvents = data?.pages.flatMap((page) => page.data) ?? [];

  return (
    <div>
      <Heading variant="Headings/H2">History</Heading>
      <TimelineWrapper>
        <Flex column gap="8px">
          <HistoryTimeline
            events={allEvents}
            hasMore={hasNextPage || false}
            eventComponents={priceListEventComponents}
          />

          {isFetchingNextPage && <Spinner />}

          {hasNextPage && !isFetchingNextPage && (
            <Flex.Item>
              <TertiaryButton
                $iconName="expand_more"
                kind="neutral"
                size="small"
                onClick={() => {
                  fetchNextPage();
                }}
              >
                Load More
              </TertiaryButton>
            </Flex.Item>
          )}
        </Flex>
      </TimelineWrapper>
    </div>
  );
};

export default PriceListHistory;
