import type { HTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import type { Elevation } from 'storybook/mixins/elevate';
import elevate from 'storybook/mixins/elevate';
import resetButtonStyles from 'storybook/mixins/resetButtonStyles';
import typography from 'storybook/mixins/typography';

interface AlertProps {
  /* The type of alert */
  type: 'error' | 'success' | 'warning';
  /* The alert's title */
  title?: string;
  /* The body of the alert message */
  children: ReactNode;
  /*
   * Whether or not to display the UI with a drop shadow.
   * Helpful for integration with the AlertQueue component.
   */
  hasDropShadow?: boolean;
  /**
   * Elevation key
   */
  elevation?: Elevation;
  /* Adding this handler will add a Close button to the UI */
  onClose?: MouseEventHandler<HTMLButtonElement>;
}

interface WrapperProps {
  type: 'error' | 'success' | 'warning';
  hasDropShadow?: boolean;
  elevation?: Elevation;
}

const Wrapper = styled.div.attrs(({ role }) => ({
  role: role || 'alert',
}))<WrapperProps>`
  display: flex;
  align-items: stretch;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 4px;
  border-left: 4px solid;

  ${({ hasDropShadow }) =>
    hasDropShadow &&
    css`
      ${elevate('8')};
    `}

  ${({ elevation }) => elevation && elevate(elevation)}

  ${({ type, theme }) => {
    if (type === 'error')
      return css`
        border-color: ${theme.color.error500};
      `;

    if (type === 'success')
      return css`
        border-color: ${theme.color.success500};
      `;

    if (type === 'warning')
      return css`
        border-color: ${theme.color.warning500};
      `;

    return css``;
  }};
`;

const IconWrapper = styled.div`
  align-self: center;
  line-height: 1;
  width: 64px;
  min-width: 64px;
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  padding: 16px 16px 16px 0;
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1.5px solid transparent;
  margin: 0 auto;

  &::before {
    display: block;
    width: 24px;
    height: 24px;
    font-size: 24px;
    content: '';
    font-variation-settings: 'FILL' 1;
    font-family: ${({ theme }) => theme.font.icon};
  }
`;

const ErrorIcon = styled(Icon)`
  background: ${({ theme }) => theme.color.error100};
  border-color: ${({ theme }) => theme.color.error500};

  &::before {
    content: 'error';
    color: ${({ theme }) => theme.color.error500};
  }
`;

const SuccessIcon = styled(Icon)`
  background: ${({ theme }) => theme.color.success100};
  border-color: ${({ theme }) => theme.color.success500};

  &::before {
    content: 'check_circle';
    color: ${({ theme }) => theme.color.success500};
  }
`;

const WarningIcon = styled(Icon)`
  background: ${({ theme }) => theme.color.warning100};
  border-color: ${({ theme }) => theme.color.warning500};

  &::before {
    content: 'warning';
    color: ${({ theme }) => theme.color.warning500};
  }
`;

const Title = styled.strong`
  display: block;
  color: ${({ theme }) => theme.color.bodyTextPrimary};
  margin-bottom: 4px;
  ${typography('Headings/H4')};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Body = styled.div`
  color: ${({ theme }) => theme.color.bodyTextSecondary};
  ${typography('Body/Body Small')};

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const CloseButton = styled.button`
  ${resetButtonStyles};
  justify-self: stretch;
  cursor: pointer;
  width: 100px;
  color: ${({ theme }) => theme.color.bodyTextSecondary};
  border-left: 1px solid ${({ theme }) => theme.color.gray200};
  padding: 24px;
  ${typography('Buttons/Button Small')};
  width: 88px;
  &:hover {
    background: ${({ theme }) => theme.color.gray100};
  }
`;

const Alert = ({
  type,
  title,
  children,
  hasDropShadow,
  onClose,
  ...wrapperProps
}: AlertProps & HTMLAttributes<HTMLDivElement>) => (
  <Wrapper type={type} hasDropShadow={hasDropShadow} {...wrapperProps}>
    <IconWrapper>
      {type === 'error' && <ErrorIcon />}
      {type === 'success' && <SuccessIcon />}
      {type === 'warning' && <WarningIcon />}
    </IconWrapper>

    <ContentWrapper>
      {title && <Title>{title}</Title>}
      <Body>{children}</Body>
    </ContentWrapper>

    {onClose && <CloseButton onClick={onClose}>Close</CloseButton>}
  </Wrapper>
);

export default Alert;
