import * as Sentry from '@sentry/react';

// Generates a random password
// ref: https://stackoverflow.com/a/26528271
const passwordLength = 16;
const lowercase = 'abcdefghijklmnopqrstuvwxyz';
const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const numbers = '0123456789';
const symbols = '!"#$%&*@()';
const passwordPool = lowercase + uppercase + numbers + symbols;
const passwordPoolSize = passwordPool.length;

export const generatePassword = () => {
  const randomValues = new Uint8Array(passwordLength);
  if (window.crypto && window.crypto.getRandomValues) {
    window.crypto.getRandomValues(randomValues);
  } else {
    Sentry.captureMessage("Cannot get random values given user's browser", 'error');
    return '';
  }

  let password = '';
  for (let i = 0; i < randomValues.length; i += 1) {
    const charIndex = randomValues[i] % passwordPoolSize;
    password += passwordPool[charIndex];
  }
  return password;
};

function isValidUsername(username: string) {
  if (username.length >= 5) {
    return true;
  }
  return false;
}

// Generates a username given available company details
export const generateUsername = (companyName: string, legacyCompanyId: string) => {
  if (companyName !== '') {
    const username = companyName.replace(/\s/g, '-');
    if (isValidUsername(username)) {
      return username;
    }
  }
  if (legacyCompanyId !== '') {
    const username = legacyCompanyId.substring(0, legacyCompanyId.lastIndexOf('-'));
    if (isValidUsername(username)) {
      return username;
    }
  }
  return '';
};

const MIN_PASSWORD_LENGTH = 12;
const MAX_PASSWORD_LENGTH = 100;
const MIN_USERNAME_LENGTH = 2;
const MAX_USERNAME_LENGTH = 100;
const MAX_DIRECTORY_LENGTH = 100;
const MIN_PORT_LENGTH = 1;
const MAX_PORT_LENGTH = 10;

export const validators = {
  password: {
    required:
      'Please enter a password. Make use of lowercase and uppercase characters, numbers and symbols',
    minLength: {
      value: MIN_PASSWORD_LENGTH,
      message: `Password must be at least ${MIN_PASSWORD_LENGTH} characters`,
    },
    maxLength: {
      value: MAX_PASSWORD_LENGTH,
      message: `Password must be at most ${MAX_PASSWORD_LENGTH} characters`,
    },
  },
  username: {
    required: 'Please enter a username',
    minLength: {
      value: MIN_USERNAME_LENGTH,
      message: `Username must be at least ${MIN_USERNAME_LENGTH} characters`,
    },
    maxLength: {
      value: MAX_USERNAME_LENGTH,
      message: `Username must be at most ${MAX_USERNAME_LENGTH} characters`,
    },
  },
  directory: {
    maxLength: {
      value: MAX_DIRECTORY_LENGTH,
      message: `Directory name must be at most ${MAX_DIRECTORY_LENGTH} characters`,
    },
  },
  port: {
    required: 'Please enter a port',
    minLength: {
      value: MIN_PORT_LENGTH,
      message: `Port must be at least ${MIN_PORT_LENGTH} character`,
    },
    maxLength: {
      value: MAX_PORT_LENGTH,
      message: `Port must be at most  ${MAX_PORT_LENGTH} characters`,
    },
  },
  host: {
    required: 'Please enter your host address',
  },
  hostKey: {
    required: 'Please enter your host key',
  },
  archiveMethod: {
    required: 'Please select an archive method',
  },
};
