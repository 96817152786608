import { Fragment } from 'react';

const CustomField = ({ field }) => (
  <div className="d-flex justify-content-between flex-wrap align-items-center">
    <div className="mr-1">
      <p className="mb-0">{field.key}</p>
      <p className="mb-0 small text-muted">{field.type}</p>
    </div>
    <p className="mb-0 small">{field.value}</p>
  </div>
);

const OrderCustomFields = ({ customFields }) => {
  return (
    <div className="card p-4">
      <h4 className="mb-4">Custom Fields</h4>
      {customFields.map((field, index) => (
        <Fragment key={field.key}>
          <CustomField field={field} />
          {index !== customFields.length - 1 ? <hr /> : null}
        </Fragment>
      ))}

      {customFields.length === 0 && <p>No custom fields</p>}
    </div>
  );
};

export default OrderCustomFields;
