import type { BaseQueryParams, PaginatedResponse, Response } from 'types/api';
import type { Metafield, Metafields } from 'types/general';
import type { BuyerProduct } from 'types/models/buyer-product';
import type {
  ProductValidationRules,
  ProductValidationRulesCreate,
  ProductValidationRulesUpdate,
} from 'types/models/buyer-product-validation';
import type {
  LegacyBuyerProduct,
  LegacyBuyerProductVariant,
} from 'types/models/legacy-buyer-product';
import type { SellerProduct } from 'types/models/seller-product';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'utils/request';

interface GetBuyerProductsParams extends BaseQueryParams {
  updatedMin?: string;
  companyId?: string;
  title?: string;
  productCode?: string;
  vendor?: string;
}

export type BuyerProductResponse = Response<BuyerProduct>;
export type BuyerProductsResponse = PaginatedResponse<BuyerProduct[]>;

export type BuyerProductValidationResponse = Response<ProductValidationRules>;
export type BuyerProductValidationsResponse = Response<ProductValidationRules[]>;

export const getBuyerProducts = async (
  params: GetBuyerProductsParams
): Promise<BuyerProductsResponse> => getRequest('/buyer/products', params);

export const getBuyerProduct = async (productId: string): Promise<BuyerProductResponse> =>
  getRequest(`/buyer/products/${productId}`);

export const getBuyerVariantByProductAndVariantId = async (
  productId: string,
  variantId: string
): Promise<BuyerProductResponse> =>
  getRequest(`/buyer/products/${productId}/variants/${variantId}`);

// TODO: Migrated from ./products.js
// - Update to be async
// - Add return type
export const getProductValidationErrorsForBuyer = (productId: string) =>
  getRequest(`/buyer/products/${productId}/validation-errors`);

export const getProductValidationRules = async (): Promise<BuyerProductValidationsResponse> =>
  getRequest('/buyer/products/validation-rules');

export const createProductValidationRules = async (
  params: ProductValidationRulesCreate
): Promise<BuyerProductValidationResponse> =>
  postRequest('/buyer/products/validation-rules', params);

export const updateProductValidationRules = async (
  ruleId: string,
  params: ProductValidationRulesUpdate
): Promise<BuyerProductValidationResponse> =>
  patchRequest(`/buyer/products/validation-rules/${ruleId}`, params);

type PatchProductResponse = LegacyBuyerProduct | SellerProduct;

export const patchBuyerProduct = (
  productId: string,
  data: Partial<LegacyBuyerProduct | SellerProduct>
): Promise<PatchProductResponse> => patchRequest(`/buyer/products/${productId}`, data);

type DeleteImageResponse = {};

export const deleteBuyerProductImage = (
  productId: string,
  imageId: string
): Promise<DeleteImageResponse> => deleteRequest(`/buyer/products/${productId}/images/${imageId}`);

export const updateBuyerProductVariant = (
  productId: string,
  variantId: string,
  data = {}
): Promise<LegacyBuyerProductVariant> =>
  patchRequest(`/buyer/products/${productId}/variants/${variantId}`, data);

export type UpdateMetafields = Record<string, Record<string, Omit<Metafield, 'updated'>>>;

export const updateProductMetafields = (productId: string, data: UpdateMetafields) => {
  return patchRequest(`/buyer/products/${productId}`, data);
};

export const updateVariantMetafields = (productId: string, variantId: string, data: Metafields) => {
  return patchRequest(`/buyer/products/${productId}/variants/${variantId}`, data);
};
