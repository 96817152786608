import styled, { css } from 'styled-components';

import materialSymbol from 'storybook/mixins/materialSymbol';
import type { ChipProps } from 'storybook/stories/molecules/Chip';
import Chip, { ChipSizes } from 'storybook/stories/molecules/Chip';

export enum StatusChipKinds {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Pending = 'pending',
}

interface StatusChipProps extends Omit<ChipProps, 'variant'> {
  /**
   * The kind of status to display
   */
  kind: `${StatusChipKinds}`;
  /**
   * Whether or not to display an icon.
   */
  hasIcon?: boolean;
}

/**
 * A `StatusChip` is a special type of `Chip` used to indicate statuses.
 * Icons applied are standardized and should not be changed.
 * Supports `small` size but will remove the icon.
 * Will default to `info` if no `kind` is provided.
 */
const StatusChip = styled(Chip)<StatusChipProps>`
  padding: ${({ hasIcon }) => (hasIcon ? '0 8px 0 4px;' : '0 8px;')};
  ${({ theme, kind, hasIcon, size }) => {
    const shouldDisplayIcon = hasIcon && size !== ChipSizes.Small;

    switch (kind) {
      case StatusChipKinds.Success:
        return css`
          background: ${theme.color.success100};
          border-color: rgba(46, 132, 113, 0.3);
          color: ${theme.color.success700};

          ${shouldDisplayIcon &&
          materialSymbol({ name: 'check_circle', color: 'success700', size: '20px' })};
        `;
      case StatusChipKinds.Warning:
        return css`
          background: ${theme.color.warning100};
          border-color: rgba(140, 107, 0, 0.3);
          color: ${theme.color.warning700};

          ${shouldDisplayIcon &&
          materialSymbol({ name: 'warning', color: 'warning700', size: '20px' })};
        `;
      case StatusChipKinds.Error:
        return css`
          background: ${theme.color.error100};
          border-color: rgba(142, 24, 16, 0.3);
          color: ${theme.color.error700};

          ${shouldDisplayIcon &&
          materialSymbol({ name: 'report', color: 'error700', size: '20px' })};
        `;
      case StatusChipKinds.Pending:
        return css`
          background: ${theme.color.gray100};
          border: 1px dashed rgba(143, 167, 184, 0.6);
          color: ${theme.color.bodyTextSecondary};

          ${shouldDisplayIcon &&
          materialSymbol({ name: 'schedule', color: 'iconDefault', size: '20px' })};
        `;
      case StatusChipKinds.Info:
      default:
        return css`
          background: ${theme.color.info100};
          border-color: rgba(27, 110, 204, 0.3);
          color: ${theme.color.info700};

          ${shouldDisplayIcon && materialSymbol({ name: 'info', color: 'info700', size: '20px' })};
        `;
    }
  }}
`;

export default StatusChip;
