import Input from 'storybook/stories/molecules/Input';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const DollarSign = styled.span`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

export const DollarInput = styled(Input)`
  padding-left: 36px;
  min-width: 100px;
`;
