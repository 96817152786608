import { useForm } from 'react-hook-form';

import Button from 'components/Common/Button';
import CardFormField from 'components/Common/CardFormField';
import Spinner from 'components/Common/Spinner';
import { useSafeState } from 'utils/hooks';
import { generatePassword, validators } from 'utils/sftpUser';

const CONVICTIONAL_SFTP_HOST = 'sftp.convictional.com';
const CONVICTIONAL_SFTP_PORT = 2122;

const clipboardCopy = (name, clip) => ({
  name: `${name}-clip`,
  icon: 'content_copy',
  event: (clipText) => window.navigator.clipboard.writeText(clipText),
  eventData: clip,
});

const InternalSFTPSettingsForm = ({
  sftpUsername,
  sftpPassword,
  sftpSettings,
  onSubmit,
  showPasswordField,
  showSubmitButton,
  disableSubmitButton,
  submitButtonText,
  isPasswordDefaultVisible = false,
}) => {
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: sftpPassword || '',
    },
  });
  const [showPassword, setShowPassword] = useSafeState(isPasswordDefaultVisible);

  const refreshPassword = () => {
    setValue('password', generatePassword());
  };

  const passwordDisplay = {
    name: 'internal-sftp-password-display',
    icon: showPassword ? 'visibility_off' : 'visibility',
    event: setShowPassword,
    eventData: !showPassword,
  };

  const passwordRefresh = {
    name: 'internal-sftp-password-refresh',
    icon: 'cached',
    event: refreshPassword,
    eventData: null,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardFormField
        disabled
        id="internal-sftp-host-form-field"
        name="host"
        label="Host"
        placeholder={CONVICTIONAL_SFTP_HOST}
        appendInput={[clipboardCopy('internal-sftp-host-form-field', CONVICTIONAL_SFTP_HOST)]}
      />
      <CardFormField
        disabled
        id="internal-sftp-port-form-field"
        name="port"
        label="Port"
        placeholder={CONVICTIONAL_SFTP_PORT}
        appendInput={[clipboardCopy('internal-sftp-port-form-field', CONVICTIONAL_SFTP_PORT)]}
      />
      <CardFormField
        disabled
        id="internal-sftp-upload-directory-form-field"
        label="Upload Location"
        placeholder={sftpSettings.uploadDirectory}
        tooltipText="This is the SFTP server directory to which Modern Dropship will upload EDI files."
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register('uploadDirectory', validators.directory)}
        error={errors.uploadDirectory}
        appendInput={[
          clipboardCopy('internal-sftp-upload-directory-form-field', sftpSettings.uploadDirectory),
        ]}
      />
      <CardFormField
        disabled
        id="internal-sftp-download-directory-form-field"
        label="Download Location"
        placeholder={sftpSettings.downloadDirectory}
        tooltipText="This is the SFTP server directory from which Modern Dropship will retrieve your product and order files."
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register('downloadDirectory', validators.directory)}
        error={errors.downloadDirectory}
        appendInput={[
          clipboardCopy(
            'internal-sftp-download-directory-form-field',
            sftpSettings.downloadDirectory
          ),
        ]}
      />
      <CardFormField
        disabled
        id="internal-sftp-username-form-field"
        name="username"
        label="Username"
        placeholder={sftpUsername}
        error={errors.username}
        appendInput={[clipboardCopy('internal-sftp-username-form-field', sftpUsername)]}
      />
      {showPasswordField && (
        <CardFormField
          data-private
          type={showPassword ? 'text' : 'password'}
          id="internal-sftp-password-form-field"
          label="Password"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('password', validators.password)}
          placeholder="Password needs to be at least 12 characters"
          appendInput={[
            passwordRefresh,
            passwordDisplay,
            clipboardCopy('internal-sftp-password-form-field', getValues('password')),
          ]}
          error={errors.password}
        />
      )}
      {showSubmitButton && (
        <div className="d-flex justify-content-end">
          <Button size="sm" color="primary" type="submit" disabled={disableSubmitButton}>
            {disableSubmitButton ? <Spinner color="white" small /> : submitButtonText}
          </Button>
        </div>
      )}
    </form>
  );
};

export default InternalSFTPSettingsForm;
