import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/Common/Button';
import UpdatedIndicator from 'components/Common/UpdatedIndicator';
import Icon from 'storybook/stories/molecules/Icon';

import GoogleCategoryModal from '../Products/SellerProductsPage/GoogleCategoryModal';
import CardProductValidationAlert from './CardProductValidationAlert';

const Wrapper = styled.div`
  border: 1px solid transparent;

  ${({ theme, hasErrors }) =>
    hasErrors &&
    css`
      border-color: ${theme.color.warning500};
    `}
`;

const ProductCategory = ({ product, edits, canEdit, onEditCategory, validationErrors }) => {
  const [showModal, setShowModal] = useState(false);

  const code = Object.prototype.hasOwnProperty.call(edits, 'googleProductCategory')
    ? _get(edits, 'googleProductCategory.code')
    : _get(product, 'googleProductCategory.code');
  const name = Object.prototype.hasOwnProperty.call(edits, 'googleProductCategory')
    ? _get(edits, 'googleProductCategory.name')
    : _get(product, 'googleProductCategory.name');

  const noCategory = !code || !name;

  const onAssignCategory = (category) => {
    onEditCategory(category);
    setShowModal(false);
  };

  const onRemoveCategory = () => {
    if (_isEmpty(product.googleProductCategory)) {
      onEditCategory({});
    } else {
      onEditCategory({ code: 0, name: '' });
    }
    setShowModal(false);
  };

  return (
    <Wrapper className="card p-4 overflow-auto" hasErrors={validationErrors?.length > 0}>
      <h4 className="mb-3">
        Google Product Category{' '}
        {Object.prototype.hasOwnProperty.call(edits, 'googleProductCategory') && (
          <UpdatedIndicator aria-label="category updated" className="ml-1" />
        )}
      </h4>

      {noCategory ? (
        <p className="mb-3 text-muted">This product has not been assigned a category</p>
      ) : (
        <div className="d-flex mb-3">
          <p className="mb-0 text-muted">{code}:</p>
          <p className="mb-0 ml-2 text-muted">{name}</p>
        </div>
      )}

      {canEdit &&
        (noCategory ? (
          <div className="d-flex">
            <Button
              size="sm"
              color="white"
              aria-label="assign category"
              onClick={() => setShowModal(true)}
            >
              Assign Category
            </Button>
          </div>
        ) : (
          <div className="d-flex justify-content-end">
            <Button size="sm" aria-label="edit category" onClick={() => setShowModal(true)}>
              <Icon name="edit" />
            </Button>
            <Button
              size="sm"
              className="ml-2"
              aria-label="remove category"
              onClick={onRemoveCategory}
            >
              <Icon name="delete" className="text-danger" />
            </Button>
          </div>
        ))}

      {validationErrors?.length > 0 && <CardProductValidationAlert errors={validationErrors} />}

      <GoogleCategoryModal
        show={showModal}
        onDismiss={() => setShowModal(false)}
        onAssignCategory={onAssignCategory}
      />
    </Wrapper>
  );
};

export default ProductCategory;
