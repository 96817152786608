import Flex from '@react-css/flex';
import { useState } from 'react';
import { ButtonSizes } from 'storybook/stories/molecules/Button';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Input from 'storybook/stories/molecules/Input';
import styled from 'styled-components';
import type { Metafield } from 'types/general';

const Wrapper = styled.div`
  margin-top: 24px;
`;

interface AddNewRowFooterProps {
  namespace: string;
  onAddNewKey: (namespace: string, key: string) => void;
}

const AddKeyRow = ({ namespace, onAddNewKey }: AddNewRowFooterProps) => {
  const [newKeys, setNewKeys] = useState<Map<string, Metafield>>(new Map());

  const newKeyValue = newKeys.get(namespace)?.value || '';
  const setValue = (newValue: string) => {
    setNewKeys((prev) => {
      const newMap = new Map(prev);
      newMap.set(namespace, {
        value: newValue,
        description: '',
        updated: '',
      });
      return newMap;
    });
  };

  const onClickAdd = () => {
    setNewKeys((prev) => {
      const newMap = new Map(prev);
      newMap.set(namespace, {
        value: newKeyValue,
        description: '',
        updated: '',
      });
      return newMap;
    });
    onAddNewKey(namespace, newKeyValue);
    setValue('');
  };

  return (
    <Wrapper>
      <Flex alignItemsCenter gap="14px">
        <Input
          value={newKeyValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
          placeholder="Enter a key"
        />
        <SecondaryButton
          disabled={!newKeyValue}
          size={ButtonSizes.Small}
          $iconName="add"
          onClick={onClickAdd}
        >
          Add Key
        </SecondaryButton>
      </Flex>
    </Wrapper>
  );
};

export default AddKeyRow;
