import Flex from '@react-css/flex';
import { useMutation, useQuery } from '@tanstack/react-query';
import { format, formatISO } from 'date-fns';
import useAlertQueue from 'hooks/useAlertQueue';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import IconButton from 'storybook/stories/molecules/Button/IconButton';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Input from 'storybook/stories/molecules/Input';
import DateInput from 'storybook/stories/molecules/Input/DateInput';
import Label from 'storybook/stories/molecules/Label';
import Select from 'storybook/stories/molecules/Select';
import type { DeepPartial, ReactSelectOption } from 'types/general';
import type { Webhook } from 'types/models/webhook';
import { getWebhookTopics, patchWebhook, postWebhook } from 'utils/api/webhooks';
import type ApiError from 'utils/ApiError';

interface WebhookFormProps {
  webhook?: Webhook;
  reset: () => void;
}

const WebhookForm = ({ webhook, reset }: WebhookFormProps) => {
  const alertQueue = useAlertQueue();

  /**
   * Form
   */

  interface FormFields {
    targetUrl: string;
    topics: ReactSelectOption[];
    secrets: { secret: string; expiresDate: string }[];
    limiterRate: number;
    limiterBurst: number;
  }

  const { handleSubmit, register, control, formState } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      targetUrl: webhook?.targetUrl ?? '',
      topics: webhook?.topics.map((topic) => ({ label: topic, value: topic })) ?? [],
      secrets: webhook?.secrets.map((secret) => ({
        secret: secret.secret,
        expiresDate: format(new Date(secret.expiresDate), "yyyy-MM-dd'T'HH:mm:ss"),
      })) ?? [{ secret: '' }],
      limiterRate: webhook?.limiterRate ?? 0,
      limiterBurst: webhook?.limiterBurst ?? 0,
    },
  });

  const secrets = useFieldArray({
    control,
    name: 'secrets',
  });

  /**
   * Queries
   */

  const fetchingWebhookTopics = useQuery({
    queryKey: ['getWebhookTopics'],
    queryFn: () => getWebhookTopics(),
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert("Couldn't fetch Webhook topics", error.message);
    },
  });

  const topics = fetchingWebhookTopics.data?.data ?? [];

  /**
   * Mutations
   */

  const creatingWebhook = useMutation({
    mutationKey: ['postWebhook'],
    mutationFn: (params: DeepPartial<Webhook>) => postWebhook(params),
    onSuccess: () => {
      alertQueue.addSuccessAlert('Success', 'Webhook has been created');
      reset();
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Error', error.message);
    },
  });

  const patchingWebhook = useMutation({
    mutationKey: ['patchWebhook', webhook?.id],
    mutationFn: (params: DeepPartial<Webhook>) => patchWebhook(webhook!.id, params),
    onSuccess: () => {
      alertQueue.addSuccessAlert('Success', 'Webhook has been updated');
      reset();
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Error', error.message);
    },
  });

  /**
   * Helpers
   */

  const isEditing = !!webhook;
  const mutation = isEditing ? patchingWebhook : creatingWebhook;

  /**
   * Handlers
   */

  const onSubmit = handleSubmit((data) => {
    const topicsParam = data.topics.map((topic) => topic.value);

    const secretsParam = data.secrets
      .filter(
        (secret) =>
          !formState.defaultValues?.secrets?.some(
            (defaultSecret) => defaultSecret?.secret === secret.secret
          )
      )
      .map((secret) => ({
        secret: secret.secret,
        expiresDate: formatISO(new Date(secret.expiresDate), { representation: 'complete' }),
      }));

    const params: DeepPartial<Webhook> = {
      targetUrl: data.targetUrl,
      limiterRate: data.limiterRate,
      limiterBurst: data.limiterBurst,
      topics: topicsParam,
      secrets: secretsParam,
    };

    mutation.mutate(params);
  });

  return (
    <form onSubmit={onSubmit}>
      <Flex column gap="16px">
        <Flex column gap="8px">
          <Label htmlFor="targetUrl">Target URL</Label>
          <Input
            {...register('targetUrl', { required: 'Target URL is required' })}
            placeholder="Target URL"
          />
        </Flex>

        <Flex column gap="8px">
          <Label htmlFor="topics">Topics</Label>
          <Controller
            control={control}
            name="topics"
            render={({ field: { ref, ...rest } }) => (
              <Select
                {...rest}
                innerRef={ref}
                isMulti
                options={topics.map((topic) => ({ label: topic, value: topic }))}
              />
            )}
          />
        </Flex>

        <Flex column gap="8px">
          <Label htmlFor="limiterRate">Rate</Label>
          <Input
            {...register('limiterRate', { required: 'Rate is required', valueAsNumber: true })}
            placeholder="Rate"
          />
        </Flex>

        <Flex column gap="8px">
          <Label htmlFor="limiterBurst">Burst</Label>
          <Input
            {...register('limiterBurst', { required: 'Burst is required', valueAsNumber: true })}
            placeholder="Burst"
          />
        </Flex>

        <Flex alignItemsEnd>
          <Flex.Item grow={1}>
            <Flex column gap="16px">
              {secrets.fields.map((secret, index) => (
                <Flex gap="16px" key={secret.id}>
                  <Flex column gap="8px">
                    <Label htmlFor={`secrets.${index}.secret`}>Secret</Label>
                    <Input
                      {...register(`secrets.${index}.secret`, {
                        required: 'Secret is required',
                      })}
                      placeholder="Secret"
                    />
                  </Flex>

                  <Flex column gap="8px">
                    <Label htmlFor={`secrets.${index}.expiresDate`}>Expires date</Label>
                    <DateInput
                      {...register(`secrets.${index}.expiresDate`, {
                        required: 'Expires date is required',
                      })}
                      placeholder="Expires date"
                    />
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex.Item>

          {isEditing && (
            <Flex gap="8px" style={{ paddingBottom: '16px' }}>
              <IconButton
                name="remove_circle"
                color="error500"
                onClick={() => secrets.remove(secrets.fields.length - 1)}
              />

              <IconButton
                name="add_circle"
                color="success500"
                onClick={() => secrets.append({ secret: '', expiresDate: '' })}
              />
            </Flex>
          )}
        </Flex>

        <Flex alignItemsCenter gap="16px">
          <PrimaryButton type="submit">Submit</PrimaryButton>
          <TertiaryButton onClick={reset}>Cancel</TertiaryButton>
        </Flex>
      </Flex>
    </form>
  );
};

export default WebhookForm;
