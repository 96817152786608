import styled, { css } from 'styled-components';

import materialSymbol from 'storybook/mixins/materialSymbol';
import typography from 'storybook/mixins/typography';

export enum ButtonSizes {
  Large = 'large',
  Small = 'small',
}

export enum ButtonKinds {
  Action = 'action',
  Neutral = 'neutral',
  Destructive = 'destructive',
}

type GetButtonTypographyArg = Pick<React.ComponentProps<typeof Button>, 'disabled' | 'size'>;

export const getButtonTypography = ({
  disabled,
  size = ButtonSizes.Large,
}: GetButtonTypographyArg) => {
  if (size === ButtonSizes.Large && disabled) {
    return typography('Buttons/Button Disabled');
  }

  if (size === ButtonSizes.Large) {
    return typography('Buttons/Button');
  }

  if (size === ButtonSizes.Small && disabled) {
    return typography('Buttons/Button Small Disabled');
  }

  if (size === ButtonSizes.Small) {
    return typography('Buttons/Button Small');
  }

  return css``;
};

type GetButtonPaddingArg = Pick<
  React.ComponentProps<typeof Button>,
  '$iconName' | '$iconAfter' | 'size'
>;

export const getButtonPadding = ({
  $iconName,
  $iconAfter,
  size = ButtonSizes.Large,
}: GetButtonPaddingArg) => {
  const isIconOnLeft = $iconName && !$iconAfter;
  const isIconOnRight = $iconName && $iconAfter;

  if (size === ButtonSizes.Large && isIconOnLeft) {
    return css`
      padding-right: 32px;
      padding-left: 20px;
    `;
  }

  if (size === ButtonSizes.Large && isIconOnRight) {
    return css`
      padding-right: 20px;
      padding-left: 32px;
    `;
  }

  if (size === ButtonSizes.Large) {
    return css`
      padding-right: 32px;
      padding-left: 32px;
    `;
  }

  if (size === ButtonSizes.Small && isIconOnLeft) {
    return css`
      padding-right: 20px;
      padding-left: 12px;
    `;
  }

  if (size === ButtonSizes.Small && isIconOnRight) {
    return css`
      padding-right: 12px;
      padding-left: 20px;
    `;
  }

  if (size === ButtonSizes.Small) {
    return css`
      padding-right: 24px;
      padding-left: 24px;
    `;
  }

  return css``;
};

export interface ButtonProps {
  /**
   * A name of an icon to display before the button text, by default
   */
  $iconName?: string;
  /**
   * Display the icon after the button text
   */
  $iconAfter?: boolean;
  /**
   * The size of the button
   */
  size?: `${ButtonSizes}`;
}

/**
 * This is a basic button offering. It's goal is to provide a consistent, mostly unstyled
 * button component that can be used in a variety of contexts. It is composed by the
 * `PrimaryButton`, `SecondaryButton`, and `TertiaryButton` components, but can be used
 * as a means to get around React's a11y complaints of certain UIs that shouldn't have
 * `onClick`, ala:
 *
 * ```
 * <Link as={Button} onClick={doSomething}>...</Link>
 * ```
 */
const Button = styled.button.attrs(({ type = 'button' }) => ({ type }))<ButtonProps>`
  all: unset;
  display: inline-flex;
  gap: 8px;
  justify-content: space-around;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: inherit;
    text-decoration: none !important; // Important when casting a 'Link' as a Button-type component
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ size }) => {
    switch (size) {
      case ButtonSizes.Large:
        return css`
          height: 44px;
        `;
      case ButtonSizes.Small:
        return css`
          height: 32px;
        `;
      default:
        return css``;
    }
  }}

  ${({ $iconName, $iconAfter }) =>
    $iconName &&
    materialSymbol({
      name: $iconName,
      position: $iconAfter ? 'after' : 'before',
      size: '24px',
      grade: 100,
    })}
`;

export default Button;
