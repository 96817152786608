import { useQuery } from '@tanstack/react-query';
import download from 'downloadjs';
import useScopedSentryMessage from 'hooks/useScopedSentryMessage';
import { useParams } from 'react-router-dom';
import { getDocument } from 'utils/api/documents';

type DocumentPageParams = {
  documentId: string;
};

const DownloadPage = () => {
  const { documentId } = useParams<DocumentPageParams>();
  const sendSentryError = useScopedSentryMessage('error');

  const downloadingDocument = useQuery({
    queryKey: ['getDocument', documentId],
    queryFn: () => getDocument(documentId),
    onSuccess: async (blob: Blob) => {
      download(blob);
    },
    onError: (apiError: Error) => {
      sendSentryError('Unable to download file', { extra: { apiError } });
    },
  });

  if (downloadingDocument.isError) {
    return <p>error</p>;
  }

  if (downloadingDocument.isLoading) {
    return <p>Downloading... The file will download within your browser.</p>;
  }

  return (
    <p>
      The file has downloaded. If the file has not successfully downloaded, please review
      permissions. <br />
      <br />
      <a href="/">Navigate back to Modern Dropship</a>
    </p>
  );
};

export default DownloadPage;
