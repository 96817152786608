import type { Options } from 'react-select';
import type { ReactSelectOption } from 'types/general';

export type DateSelectOption = ReactSelectOption<[string, string]>;

const getDaysAgoDate = (now = new Date(), daysAgo = 0) => {
  const date = new Date(now);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() - daysAgo);
  return date;
};

const getStartOfThisWeekDate = (now = new Date()) => getDaysAgoDate(now, now.getDay());

const getStartOfPreviousWeekDate = (now = new Date()) => getDaysAgoDate(now, now.getDay() + 7);

const getEndOfPreviousWeekDate = (now = new Date()) => {
  const date = getStartOfPreviousWeekDate(now);
  date.setDate(date.getDate() + 6);
  date.setHours(23, 59, 59, 999);
  return date;
};

const useDateSelectOptions = (now = new Date()): Options<DateSelectOption> => {
  const nowIso = now.toISOString();

  const startOfThisWeekIso = getStartOfThisWeekDate(now).toISOString();
  const startOfLastWeekIso = getStartOfPreviousWeekDate(now).toISOString();
  const endOfLastWeekIso = getEndOfPreviousWeekDate(now).toISOString();

  const last14DaysIso = getDaysAgoDate(now, 14).toISOString();
  const last30DaysIso = getDaysAgoDate(now, 30).toISOString();
  const last60DaysIso = getDaysAgoDate(now, 60).toISOString();
  const last90DaysIso = getDaysAgoDate(now, 90).toISOString();
  const last120DaysIso = getDaysAgoDate(now, 120).toISOString();

  return [
    { value: ['', ''], label: 'All' },
    { value: [startOfThisWeekIso, nowIso], label: 'This week' },
    { value: [startOfLastWeekIso, endOfLastWeekIso], label: 'Last week' },
    { value: [last14DaysIso, nowIso], label: 'Last 14 days' },
    { value: [last30DaysIso, nowIso], label: 'Last 30 days' },
    { value: [last60DaysIso, nowIso], label: 'Last 60 days' },
    { value: [last90DaysIso, nowIso], label: 'Last 90 days' },
    { value: [last120DaysIso, nowIso], label: 'Last 120 days' },
  ];
};

export default useDateSelectOptions;
