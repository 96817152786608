import Modal from 'storybook/stories/cells/Modal';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

import type { SellerProduct } from 'types/models/seller-product';

interface DeleteConfirmationModalProps {
  onDismiss: () => void;
  onAccept: () => void;
  show: boolean;
  selectedProducts: SellerProduct[];
}

const DeleteConfirmationModal = ({
  onDismiss,
  onAccept,
  show,
  selectedProducts,
}: DeleteConfirmationModalProps) => (
  <Modal.Root
    show={show}
    size="lg"
    aria-labelledby="delete-products-modal-title"
    centered
    animation={false}
    onHide={onDismiss}
    id="delete-products-modal"
  >
    <Modal.Header closeButton>
      <Modal.Title>Delete {selectedProducts.length} Products?</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p className="mb-3">
        By clicking on the delete button you will lose the following products PERMANENTLY. You will
        have to add your products again if you will want to use them.
      </p>

      <ul className="mb-4">
        {selectedProducts.map((product) => (
          <li key={product.id}>
            {product.code} | {product.title}
          </li>
        ))}
      </ul>
    </Modal.Body>

    <Modal.Footer>
      <SecondaryButton size="small" type="button" onClick={onDismiss}>
        Cancel
      </SecondaryButton>

      <SecondaryButton
        size="small"
        type="submit"
        kind="destructive"
        onClick={onAccept}
        id="delete-products-btn"
      >
        Delete
      </SecondaryButton>
    </Modal.Footer>
  </Modal.Root>
);

export default DeleteConfirmationModal;
