import Flex from '@react-css/flex';
import { useBoolean } from 'usehooks-ts';

import SearchResult from 'storybook/stories/cells/SearchResult';
import {
  MainTableWrapper,
  ProductVariantTableWrapper,
  TableMeta,
  VerticalDivider,
  ViewVariantsButton,
} from 'storybook/stories/cells/SearchResult/shared/Styles';
import Table from 'storybook/stories/cells/Table';
import Tooltip from 'storybook/stories/cells/Tooltip';
import CopyIcon from 'storybook/stories/molecules/Icon/CopyIcon';
import StatusIcon from 'storybook/stories/molecules/Icon/StatusIcon';
import Toggle from 'storybook/stories/molecules/Toggle';
import type {
  LegacyBuyerProduct,
  LegacyBuyerProductImage,
  LegacyBuyerProductVariant,
} from 'types/models/legacy-buyer-product';
import { humanizeMoney, toMoney } from 'utils/currencies';
import { ProductImageThumbnail } from './shared/ProductImage';

// VARIANT TABLE

interface ProductVariantTableProps {
  variants: LegacyBuyerProductVariant[];
}

const ProductVariantTable = ({ variants }: ProductVariantTableProps) => (
  <ProductVariantTableWrapper>
    <Table>
      <Table.THead>
        <Table.TR>
          <Table.TH width="280px">Title/Sku</Table.TH>
          <Table.TH>Barcode</Table.TH>
          <Table.TH align="right" width="100px">
            Quantity
          </Table.TH>
          <Table.TH align="right" width="140px">
            What You Pay
          </Table.TH>
          <Table.TH align="right" width="100px">
            Margin
          </Table.TH>
          <Table.TH align="right" width="100px">
            Margin %
          </Table.TH>
          <Table.TH align="right" width="140px">
            Suggested Retail Price
          </Table.TH>
        </Table.TR>
      </Table.THead>
      <Table.TBody>
        {variants.map((variant) => (
          <Table.TR key={variant._id}>
            <Table.TD>
              {variant.title || 'N/A'}
              <TableMeta>SKU: {variant.sku}</TableMeta>
            </Table.TD>
            <Table.TD onClick={(event) => event.stopPropagation()}>
              {variant.barcode ? (
                <CopyIcon textToCopy={variant.barcode}>{variant.barcode}</CopyIcon>
              ) : (
                'N/A'
              )}
              <TableMeta>{variant.barcodeType}</TableMeta>
            </Table.TD>
            <Table.TD align="right">{variant.inventoryQuantity}</Table.TD>
            <Table.TD align="right">
              {humanizeMoney(toMoney(variant.basePrice, variant.baseCurrency))}
            </Table.TD>
            <Table.TD align="right">
              {humanizeMoney(toMoney(variant.marginAmount, variant.baseCurrency))}
            </Table.TD>
            <Table.TD align="right">{variant.marginPercent}%</Table.TD>
            <Table.TD align="right">
              {humanizeMoney(toMoney(variant.retailPrice, variant.retailCurrency))}
            </Table.TD>
          </Table.TR>
        ))}
      </Table.TBody>
    </Table>
  </ProductVariantTableWrapper>
);

// STATUS AREA

interface ProductSyncStatusProps {
  isSynced: boolean;
  isSelected: boolean;
  shouldDisplaySyncingStatus: boolean;
}

const ProductSyncStatus = ({
  isSynced,
  isSelected,
  shouldDisplaySyncingStatus,
}: ProductSyncStatusProps) => {
  const isCurrentlySyncing = shouldDisplaySyncingStatus && isSelected && !isSynced;

  if (isCurrentlySyncing)
    return (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <StatusIcon variant="syncing">Syncing</StatusIcon>
        </Tooltip.Trigger>
        <Tooltip.Content>
          You have selected this product. We are in the process of syncing it to your store.
        </Tooltip.Content>
      </Tooltip>
    );

  if (!isSynced)
    return (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <StatusIcon variant="warning">Not Synced</StatusIcon>
        </Tooltip.Trigger>
        <Tooltip.Content>
          You must select this product in order for it to sync to your store.
        </Tooltip.Content>
      </Tooltip>
    );

  if (isSynced)
    return (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <StatusIcon variant="checked">Synced</StatusIcon>
        </Tooltip.Trigger>
        <Tooltip.Content>
          This product has been selected and is synced to your store.
        </Tooltip.Content>
      </Tooltip>
    );

  return null;
};

// PRODUCT SELECT TOGGLE

interface ProductSelectToggleProps {
  product: LegacyBuyerProduct;
  onChange: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const ProductSelectToggle = ({ product, onChange }: ProductSelectToggleProps) => {
  if (!product.selectedAvailable) {
    return (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <Toggle disabled />
        </Tooltip.Trigger>

        <Tooltip.Content>
          {product.selectedAvailableReason || 'Selection is unavailable for this product'}
        </Tooltip.Content>
      </Tooltip>
    );
  }

  return (
    <Toggle
      checked={product.selected}
      onChange={onChange}
      aria-label={`${product.selected ? 'Deselect' : 'Select'} ${product.title}`}
    />
  );
};

// PRODUCT TABLE

interface ProductTableProps {
  product: LegacyBuyerProduct;
  onProductSelectionChange: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const ProductTable = ({ product, onProductSelectionChange }: ProductTableProps) => (
  <MainTableWrapper>
    <Table>
      <Table.THead>
        <Table.TR>
          <Table.TH nowrap width="160px">
            Product Code
          </Table.TH>
          <Table.TH width="160px">Brand</Table.TH>
          <Table.TH>Product Title</Table.TH>
          <Table.TH width="80px" align="right">
            Selected
          </Table.TH>
        </Table.TR>
      </Table.THead>
      <Table.TBody>
        <Table.TR>
          <Table.TD>
            <strong>{product.code || 'N/A'}</strong>
          </Table.TD>
          <Table.TD>{product.vendor}</Table.TD>
          <Table.TD truncate title={product.title}>
            {product.title}
          </Table.TD>
          <Table.TD align="center" onClick={(event) => event.stopPropagation()}>
            <ProductSelectToggle product={product} onChange={onProductSelectionChange} />
          </Table.TD>
        </Table.TR>
      </Table.TBody>
    </Table>
  </MainTableWrapper>
);

// PRODUCT IMAGE

interface ProductImageProps {
  productTitle: string;
  image?: LegacyBuyerProductImage;
}

const ProductImage = ({ image, productTitle }: ProductImageProps) => {
  return image ? (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <ProductImageThumbnail imageSrc={image.src} />
      </Tooltip.Trigger>
      <Tooltip.Content>
        <img width="272" src={image.src} alt={productTitle} referrerPolicy="no-referrer" />
      </Tooltip.Content>
    </Tooltip>
  ) : (
    <ProductImageThumbnail />
  );
};

// MAIN

interface BuyerProductSearchResultProps {
  product: LegacyBuyerProduct;
  checkboxUi?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  shouldDisplaySyncedStatus: boolean;
  shouldDisplaySyncingStatus: boolean;
  onSelectProductChange: (productId: string) => void;
  onDeselectProductChange: (productId: string) => void;
}

/**
 * `SearchResult` implementation for a Buyer Product
 */
const BuyerProductSearchResult = ({
  product,
  checkboxUi,
  onClick,
  shouldDisplaySyncedStatus,
  shouldDisplaySyncingStatus,
  onSelectProductChange,
  onDeselectProductChange,
}: BuyerProductSearchResultProps) => {
  const { value: isVariantTableVisible, toggle: toggleVariantTableVisibility } = useBoolean(false);

  const images = product.images || [];

  const [primaryImage] = images.sort((a, b) => a.position - b.position);
  const marginRange = new Set<number>([product.marginRangeMin, product.marginRangeMax]);

  const handleVariantButtonClick = () => {
    toggleVariantTableVisibility();
  };

  const handleProductSelectionChange = () => {
    if (product.selected) onDeselectProductChange(product.id);
    else onSelectProductChange(product.id);
  };

  return (
    <SearchResult
      checkboxUi={checkboxUi}
      footerUi={isVariantTableVisible && <ProductVariantTable variants={product.variants} />}
      onClick={onClick}
    >
      <Flex column gap="24px">
        <Flex alignItemsCenter gap="32px">
          <ProductImage image={primaryImage} productTitle={product.title} />

          <Flex.Item grow={1}>
            <ProductTable
              product={product}
              onProductSelectionChange={handleProductSelectionChange}
            />
          </Flex.Item>
        </Flex>

        <Flex alignItemsCenter>
          <Flex.Item grow={2} data-testid={`product-status-${product.id}`}>
            <Flex gap="16px">
              {shouldDisplaySyncedStatus && (
                <>
                  <ProductSyncStatus
                    isSelected={product.selected}
                    isSynced={product.synced}
                    shouldDisplaySyncingStatus={shouldDisplaySyncingStatus}
                  />

                  <VerticalDivider />
                </>
              )}

              <TableMeta>Margin: {Array.from(marginRange).join('-')}%</TableMeta>
            </Flex>
          </Flex.Item>

          <ViewVariantsButton
            isVariantTableVisible={isVariantTableVisible}
            onClick={(event) => {
              event.stopPropagation();
              handleVariantButtonClick();
            }}
          >
            View Variants
          </ViewVariantsButton>
        </Flex>
      </Flex>
    </SearchResult>
  );
};

export default BuyerProductSearchResult;
