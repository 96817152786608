/* eslint-disable max-classes-per-file */
import fetchGoogleTaxonomy from 'utils/fetchGoogleTaxonomy';

// keep fetched taxonomy data in memory so we don't have to fetch again
let googleCategoryData = null;

/**
 * each node in the Trie represents a single google product category at a particular level
 * a node can have subcategories (children), and also a code
 * if a node has a code, it represents a final subcategory in the google taxonomy
 */
class CategoryTrieNode {
  constructor(category, level, code = '') {
    this.category = category; // the text representing the name of the category at this level
    this.level = level; // how far down the tree is this node
    this.code = code; // the actual category ID, should only be available if this node represents a terminal node
    this.children = {}; // hash to represent children
  }

  addChild(category, level, code = '') {
    if (!this.children[category]) {
      this.children[category] = new CategoryTrieNode(category, level, code);
    }
    const childNode = this.children[category];
    // node can have children and also be a leaf (final subcategory)
    // if the children are added before
    childNode.code = childNode.code || code;
    return childNode;
  }

  hasChild(category) {
    return !!this.children[category];
  }

  getChild(category) {
    return this.children[category];
  }

  hasChildren() {
    return Object.keys(this.children).length === 0;
  }

  /**
   * gets a list of the child categories, useful to display next level of options
   * @returns array of categories that are nested one level under this node
   */
  getChildCategories() {
    return Object.keys(this.children);
  }
}

/**
 * data structure used to give hierarchy and easy access to all the google product categories
 * should only need to be built once when category JSON loads
 */
class CategoryTrie {
  constructor() {
    this.head = new CategoryTrieNode('ROOT', 0);
  }

  /**
   * called for each category returned in the google taxonomy JSON in order to build up the trie
   * @param {string} categoryPath the category hierarchy in the form of "a > b > c"
   * @param {string|int} code the Google Product Category code that matches this category hierarchy
   */
  addGoogleCategory(categoryPath, code) {
    const categories = categoryPath.split('>').map((c) => c.trim());
    let currentNode = this.head;

    categories.forEach((category, idx) => {
      const isLast = idx === categories.length - 1;
      currentNode = currentNode.addChild(category, idx + 1, isLast ? code : '');
    });
  }

  /**
   * takes a category hierarchy and returns the appropriate code
   * @param {string|array} categoryPath can be string "a > b > c" or array ["a", "b", "c"]
   * @returns {string|int} the code or "" if the category path doesn't match
   */
  getCodeByPath(categoryPath = []) {
    const categories =
      typeof categoryPath === 'string'
        ? categoryPath.split('>').map((c) => c.trim())
        : categoryPath;
    let currentNode = this.head;
    for (let i = 0; i < categories.length; i += 1) {
      // if path isn't valid, return empty
      if (!currentNode.hasChild(categories[i])) {
        return '';
      }
      currentNode = currentNode.getChild(categories[i]);
    }
    // found last node
    return currentNode.code;
  }

  /**
   * takes a partial category hierarchy and returns the subcategories
   * @param {string|array} categoryPath can be string "a > b > c" or array ["a", "b", "c"]
   * @returns {array|null} a list of subcategories, null if there are no more subcategories, or path is invalid
   */
  getSubcategoriesByPath(categoryPath = []) {
    const categories =
      typeof categoryPath === 'string'
        ? categoryPath
            .split('>')
            .map((c) => c.trim())
            .filter((c) => c)
        : categoryPath;
    let currentNode = this.head;
    for (let i = 0; i < categories.length; i += 1) {
      // if path isn't valid, return empty
      if (!currentNode.hasChild(categories[i])) {
        return null;
      }
      currentNode = currentNode.getChild(categories[i]);
    }
    // found last node
    const subcategories = currentNode.getChildCategories();
    return subcategories.length === 0 ? null : subcategories;
  }
}

const buildTrieAndList = (data) => {
  if (!data) return null;
  const trie = new CategoryTrie();
  const list = [];
  Object.keys(data).forEach((key) => {
    trie.addGoogleCategory(data[key], key);
    list.push({ code: key, name: data[key] });
  });
  return { trie, list };
};

/**
 * Fetches the Google Product Taxonomy JSON and returns parsed data structures used when assigning a category
 * @returns {object} object containing the trie and list data structures used in the picker
 */
const getGoogleCategoryData = async () => {
  try {
    // if data loaded into memory, return it, don't fetch
    if (googleCategoryData) {
      return buildTrieAndList(googleCategoryData);
    }
    // if not in memory, pull from API and parse
    googleCategoryData = await fetchGoogleTaxonomy();
    return buildTrieAndList(googleCategoryData);
  } catch (err) {
    console.error(err);
  }
  return { list: null, trie: null };
};

export default getGoogleCategoryData;
