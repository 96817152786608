/* eslint-disable jsx-a11y/label-has-associated-control */
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from 'components/Common/Button';
import FormField from 'components/Common/FormField';
import Spinner from 'components/Common/Spinner';
import { updateUser } from 'store/slices/settings/account';

import useAlertQueue from 'hooks/useAlertQueue';
import { useAppDispatch, useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';
import { SettingsFooter, SettingsMain, SettingsPageHeader } from '../SettingsLayout';

const AccountSettings = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const alertQueue = useAlertQueue();
  const formId = 'account-settings-form';
  const { isUpdatingUser } = useSelector(({ settings }) => settings.account);
  const { handleSubmit, formState, reset, register } = useForm({
    defaultValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
    },
  });
  const { isDirty } = formState;

  const onFormSubmit = (data) => {
    dispatch(updateUser(data)).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong.', action.error.message);
        return;
      }
      alertQueue.addSuccessAlert('Success', 'Account info updated');
    });
    reset({ ...data }, { keepDefaultValues: false, keepValues: false, keepDirty: false });
  };

  return (
    <>
      <SettingsMain>
        <SettingsPageHeader title="My Account" />
        <form id={formId} onSubmit={handleSubmit(onFormSubmit)}>
          <FormField
            label="First Name"
            id="user-first-name"
            type="text"
            placeholder="John"
            size="sm"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('firstName')}
          />
          <FormField
            label="Last Name"
            id="user-last-name"
            type="text"
            placeholder="Doe"
            size="sm"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('lastName')}
          />
        </form>
        <hr className="my-4" />
        <label className="mr-2">Email:</label>
        <p className="d-inline font-weight-bold">{user.emails[0].address}</p>
        <p className="text-muted small">
          Please contact support if you would like to change your login email.
        </p>
      </SettingsMain>
      <SettingsFooter>
        <Button
          type="submit"
          form={formId}
          disabled={!isDirty || isUpdatingUser}
          size="sm"
          color="primary"
          className="mr-2"
        >
          {isUpdatingUser ? <Spinner color="white" className="mx-2" small /> : 'Save'}
        </Button>
      </SettingsFooter>
    </>
  );
};

export default AccountSettings;
