import Flex from '@react-css/flex';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Card from 'storybook/stories/cells/Card';
import Table from 'storybook/stories/cells/Table';
import Body from 'storybook/stories/molecules/Body';
import type { Invoice } from 'types/models/invoice';
import { humanizeMoney, toMoney } from 'utils/currencies';
import { invoiceItemDescription } from 'utils/invoices';
import { friendlyTaxType } from 'utils/taxes';

const InvoiceItemTableWrapper = styled.div`
  ${({ theme }) => `
    ${Table} {
      border-collapse: collapse;
    }

    /* Override vertical padding on all data, head cells */
    ${Table.TD}, ${Table.TH} {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    /* Override bottom padding on last body row */
    ${Table.TBody} ${Table.TR}:last-child ${Table.TD} {
      padding-bottom: 16px;
    }

    /* Draw a solid top border on the last row of the table body */
    ${Table.TFoot} {
      border-top: 1px solid ${theme.color.gray200};
      ${Table.TR}:first-child ${Table.TD} {
        padding-top: 16px;
      }
    }
  `}
`;

// Styled header wrapper to align with table spacing
const HeaderContainer = styled.div`
  padding: 0 8px;
`;

export interface InvoiceSummaryCardProps {
  invoice: Invoice;
}

const InvoiceSummaryCard = ({ invoice }: InvoiceSummaryCardProps) => {
  const history = useHistory();

  const handleTableRowClick = (reference: string, referenceId: string) => {
    if (reference === 'order') history.push(`/orders/${referenceId}`);
  };

  return (
    <Card>
      <Flex column gap="16px">
        <HeaderContainer>
          <h3>Summary</h3>
        </HeaderContainer>

        <InvoiceItemTableWrapper>
          <Table variant="data" data-testid="invoice-items-table">
            <Table.THead>
              <Table.TR>
                <Table.TH colSpan={2}>Invoice Item</Table.TH>
                <Table.TH align="right">Amount</Table.TH>
              </Table.TR>
            </Table.THead>

            <Table.TBody>
              {invoice.items.map((item) => (
                <Table.TR
                  key={item._id}
                  data-testid="invoiceitemrow"
                  onClick={() => handleTableRowClick(item.reference, item.referenceId)}
                >
                  <Table.TD colSpan={2}>
                    <Body color="bodyTextLinks">{invoiceItemDescription(item)}</Body>
                  </Table.TD>
                  <Table.TD align="right">
                    {humanizeMoney(toMoney(item.amountCents / 100, invoice.currency))}
                  </Table.TD>
                </Table.TR>
              ))}
              {invoice.taxes &&
                invoice.taxes.map((item, idx) => (
                  // Note: using index is acceptable here as tax items are static and cannot be edited here
                  // If/when this view is updated to allow editing tax items, this will have to change
                  // eslint-disable-next-line react/no-array-index-key
                  <Table.TR key={`tax${idx}`} data-testid="taxrow">
                    <Table.TD>{friendlyTaxType(item.type)}</Table.TD>
                    <Table.TD align="right">{`${(100.0 * item.amountPercent).toFixed(
                      2
                    )}%`}</Table.TD>
                    <Table.TD align="right">
                      {humanizeMoney(toMoney(item.amountCents / 100.0, invoice.currency))}
                    </Table.TD>
                  </Table.TR>
                ))}
              {invoice.orderFee && (
                <Table.TR key="orderFee" data-testid="taxrow">
                  <Table.TD colSpan={2}>Order Fee</Table.TD>
                  <Table.TD align="right">{humanizeMoney(invoice.orderFee.appliedFee)}</Table.TD>
                </Table.TR>
              )}
            </Table.TBody>
            <Table.TFoot key="total">
              <Table.TR data-testid="totalrow">
                <Table.TD align="right" width="99%" colSpan={2}>
                  Total
                </Table.TD>
                <Table.TD align="right" bold>
                  {humanizeMoney(toMoney(invoice.amountTotalCents / 100.0, invoice.currency))}
                </Table.TD>
              </Table.TR>
            </Table.TFoot>
          </Table>
        </InvoiceItemTableWrapper>
      </Flex>
    </Card>
  );
};

export default InvoiceSummaryCard;
