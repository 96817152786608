/* eslint-disable jsx-a11y/label-has-associated-control, react/jsx-props-no-spreading */
import { useCombobox, type UseComboboxStateChange } from 'downshift';
import { forwardRef, useRef, useState } from 'react';

import { getCompanies } from 'utils/api/companies';

import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import SimpleBar from 'simplebar-react';
import typography from 'storybook/mixins/typography';
import Body from 'storybook/stories/molecules/Body';
import Chip from 'storybook/stories/molecules/Chip';
import styled, { css } from 'styled-components';
import type { CompanyResponse } from 'types/models/company';
import { useDebounce, useEffectOnce } from 'usehooks-ts';
import { prettyDateTime } from 'utils/date';

const Item = styled.div`
  padding: 0.5rem 1rem;

  ${({ theme }) => css`
    &:nth-child(even) {
      background-color: ${theme.color.gray100};
    }
    &:hover {
      background-color: ${theme.color.gray200};
    }
    &.highlighted {
      background-color: ${theme.color.gray200};
    }
  `}
`;

const Title = styled.span`
  min-width: 200px;
  ${typography('Body/Regular Bold')}
`;

const Detail = styled.span`
  min-width: 100px;
  ${typography('Inputs/Input Message')}
`;

interface CompanyListItemProps {
  company: CompanyResponse;
  highlighted?: boolean;
}

const CompanyListItem = forwardRef<HTMLDivElement, CompanyListItemProps>(
  ({ company, highlighted, ...rest }, ref) => {
    const primaryName = company.name || company.legacyCompanyId;

    return (
      <Item {...rest} ref={ref} className={highlighted ? 'highlighted' : ''}>
        <Flex alignItemsCenter gap="16px">
          <Title>{primaryName}</Title>
          <Chip size="small">{company.commerceType}</Chip>
          <Body variant="Body/Header">{company._id}</Body>
        </Flex>
        <Flex alignItemsCenter gap="16px">
          <Detail>Orders: {company.orderPlatform}</Detail>
          <Detail>Products: {company.productPlatform}</Detail>
          <Detail>Inventory: {company.inventoryPlatform}</Detail>
          <Detail>Created: {prettyDateTime(company.created)}</Detail>
        </Flex>
      </Item>
    );
  }
);

interface CompanySelectorProps {
  onSelect: (item: any) => void;
  defaultSearch?: string;
}

const CompanySelector = ({ onSelect, defaultSearch }: CompanySelectorProps) => {
  const [search, setSearch] = useState(defaultSearch || '');
  const debouncedSearch = useDebounce(search, 600);
  const [searchResults, setSearchResults] = useState<CompanyResponse[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  // a string representation of the list item that is selected (used internally in downshiftjs)
  const itemToString = (item: CompanyResponse | null) => {
    if (!item) return '';
    return item._id;
  };

  const onSelectedItemChange = ({ selectedItem }: UseComboboxStateChange<CompanyResponse>) => {
    if (!selectedItem) return;
    setSearch(selectedItem.name || selectedItem.legacyCompanyId);

    onSelect(selectedItem);
  };

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    isOpen,
    highlightedIndex,
  } = useCombobox({
    items: searchResults,
    itemToString,
    inputValue: search,
    onSelectedItemChange,
  });

  const { isLoading } = useQuery({
    queryKey: ['sudoCompanies', debouncedSearch],
    queryFn: () => getCompanies({ search: debouncedSearch }),
    enabled: isOpen,
    onSuccess: (data) => {
      setSearchResults(data);
    },
  });

  useEffectOnce(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  // render
  return (
    <div className="input-group input-group-flush py-2">
      <label htmlFor="search" {...getLabelProps({ className: 'visuallyhidden' })}>
        Search Companies
      </label>
      <div {...getComboboxProps()} style={{ width: '100%', zIndex: 1 }}>
        <input
          {...getInputProps({
            ref: inputRef,
            className: 'form-control list-search',
            placeholder: 'Search by Name, Object ID, Legacy ID, Code, or User Email',
            onChange: (e) => {
              const query = e.currentTarget.value;
              setSearch(query);
            },
          })}
          id="search"
          style={{ borderRadius: isOpen ? '0.375rem 0.375rem 0rem 0rem' : '0.375rem' }}
          spellCheck={false}
          onBlur={() => {}}
        />
      </div>
      <div {...getMenuProps()} style={{ width: '100%' }}>
        {isOpen ? (
          <SimpleBar
            style={{
              maxHeight: '20rem',
              border: '1px solid #d2ddec',
              borderRadius: '0rem 0rem 0.375rem 0.375rem',
              width: '100%',
              marginTop: '-1px',
            }}
          >
            {isLoading && <Item>Loading...</Item>}
            {searchResults.map((item, index) => (
              <CompanyListItem
                key={item._id}
                company={item}
                highlighted={index === highlightedIndex}
                {...getItemProps({ item, index })}
              />
            ))}
          </SimpleBar>
        ) : null}
      </div>
    </div>
  );
};

export default CompanySelector;
