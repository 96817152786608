import Heading from 'storybook/stories/molecules/Heading';
import Input from 'storybook/stories/molecules/Input';
import styled from 'styled-components';

export const ShortInput = styled(Input)`
  max-width: 250px;
`;

export const BuyerListItem = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  grid-template-columns: 1.5fr 1fr;

  &.highlighted {
    color: #12263f;
    background-color: #edf2f9;
  }
`;

export const PartnerNameHeading = styled(Heading)`
  margin-bottom: 4px;
`;
