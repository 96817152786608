import Flex from '@react-css/flex';
import styled, { css } from 'styled-components';

import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import { numberWithCommas } from 'utils/numbers';

// HEADER

const SearchResultsHeaderWrapper = styled(Card)`
  padding: 16px;
`;

const SearchResultsHeaderDivider = styled.div`
  width: 1px;
  align-self: stretch;

  ${({ theme }) => css`
    background-color: ${theme.color.gray200};
  `}
`;

const SearchResultsHeaderFormWrapper = styled.div`
  max-width: 400px;
`;

interface SearchResultsHeaderProps {
  /**
   * Custom Checkbox UI
   */
  checkboxUi?: React.ReactNode;
  /**
   * An implementation of `SearchForm`
   */
  searchFormUi: React.ReactNode;
  /**
   * Custom Actions UI
   */
  actionsUi?: React.ReactNode;
  /**
   * Custom Filters UI
   */
  filtersUi?: React.ReactNode;
  /**
   * Custom DeleteButton UI
   */
  deleteButton?: React.ReactNode;
  /**
   * Custom AddNewButton UI
   */
  addNewButton?: React.ReactNode;
}

/**
 * Provides layout for Search Result searching and filtering
 */
export const SearchResultsHeader = ({
  searchFormUi,
  checkboxUi,
  actionsUi,
  filtersUi,
  deleteButton,
  addNewButton,
}: SearchResultsHeaderProps) => {
  const shouldAddMenuDivider = actionsUi && filtersUi;

  return (
    <SearchResultsHeaderWrapper>
      <Flex alignItemsCenter gap="16px">
        {checkboxUi && (
          <>
            {checkboxUi}
            <SearchResultsHeaderDivider />
          </>
        )}

        <Flex.Item grow={1}>
          <SearchResultsHeaderFormWrapper>{searchFormUi}</SearchResultsHeaderFormWrapper>
        </Flex.Item>

        <Flex alignItemsCenter gap="24px">
          {actionsUi}
          {shouldAddMenuDivider && <SearchResultsHeaderDivider />}
          {filtersUi}
          {deleteButton}
          {addNewButton}
        </Flex>
      </Flex>
    </SearchResultsHeaderWrapper>
  );
};

// FOOTER

const SearchResultsFooterWrapper = styled(Card)`
  padding: 16px;
`;

interface SearchResultsFooterProps {
  /**
   * A number of total results
   */
  totalResults?: number;
  /**
   * Implementation of a `PageList` component
   */
  pageListUi?: React.ReactNode;
  /**
   * Implementation of a `PageNavigation` component
   */
  navigationUi: React.ReactNode;
}

/**
 * Provides layout for Search Result pagination
 */
export const SearchResultsFooter = ({
  totalResults,
  pageListUi,
  navigationUi,
}: SearchResultsFooterProps) => {
  return (
    <SearchResultsFooterWrapper>
      <Flex gap="16px" alignItemsCenter>
        {totalResults !== undefined && (
          <Body variant="Body/Body Small" color="bodyTextSecondary">
            {totalResults === 1
              ? `${numberWithCommas(totalResults)} Result`
              : `${numberWithCommas(totalResults)} Results`}
          </Body>
        )}

        <Flex.Item grow={1}>
          <Flex justifyEnd alignItemsCenter gap="16px">
            {pageListUi}
            {navigationUi}
          </Flex>
        </Flex.Item>
      </Flex>
    </SearchResultsFooterWrapper>
  );
};

// MAIN

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const BodyWrapper = styled.div`
  position: static;
  z-index: 0;
`;

const FooterWrapper = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1;
`;

interface SearchResultsProps {
  /**
   * An implementation of `SearchResultsHeader`
   */
  headerUi?: React.ReactNode;
  /**
   * An implementation of `SearchResultsFooter`
   */
  footerUi?: React.ReactNode;
  /**
   * Implementations of `SearchResult`
   */
  children: React.ReactNode;
}

/**
 * Provides layout for a Search Results. Designed to be implemented for a specific
 * resource, e.g. ProductSearchResults, OrderSearchResults, etc
 */
const SearchResults = ({ headerUi, footerUi, children }: SearchResultsProps) => {
  return (
    <Flex column gap="8px">
      {headerUi && <HeaderWrapper>{headerUi}</HeaderWrapper>}
      <BodyWrapper>
        <Flex column gap="8px">
          {children}
        </Flex>
      </BodyWrapper>
      {footerUi && <FooterWrapper>{footerUi}</FooterWrapper>}
    </Flex>
  );
};

export default SearchResults;
