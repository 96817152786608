import Flex from '@react-css/flex';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import styled, { keyframes } from 'styled-components';

import PageHeader from 'components/Common/PageHeader';
import { Link } from 'react-router-dom';
import Icon from 'storybook/stories/molecules/Icon';
import breakpoints from 'utils/breakpoints';

const loadingTextKeyframe = keyframes`
  0% {
    background: var(--light);
  }
  50% {
    background: #e2ebf6;
  }
  100% {
    background: var(--light);
  }
`;

const LoadingText = styled.div`
  height: 0.9375rem;
  margin-bottom: 0.5rem;
  background: var(--light);
  border-radius: 4px;
  animation: ${loadingTextKeyframe} 2s linear infinite;
`;

const LoadHeading = styled(LoadingText)`
  height: 1.6rem;
  width: 24rem;
`;

const LoadSubheading = styled(LoadingText)`
  height: 1rem;
  width: 16rem;
`;

const LoadCardTitle = styled(LoadingText)`
  height: 1rem;
  width: 10rem;
`;

const LoadCardText = styled(LoadingText)`
  height: 0.8rem;
`;

const FlexContainer = styled(Flex)`
  gap: 32px;
  ${breakpoints({
    values: {
      xs: {
        'flex-direction': 'column',
      },
      sm: {
        'flex-direction': 'column',
      },
      md: {
        'flex-direction': 'column',
      },
      lg: {
        'flex-direction': 'row',
      },
      xl: {
        'flex-direction': 'row',
      },
      xxl: {
        'flex-direction': 'row',
      },
    },
  })}
`;

const LoadingOrderPage = () => {
  return (
    <>
      <PageHeader>
        <Flex row alignItemsCenter gap="32px">
          <Flex.Item>
            <TertiaryButton as={Link} to="/orders">
              <Icon name="west" />
            </TertiaryButton>
          </Flex.Item>
          <Flex.Item>
            <LoadHeading className="mb-3" />
            <LoadSubheading />
          </Flex.Item>
        </Flex>
      </PageHeader>
      <FlexContainer>
        <Flex.Item grow={5}>
          <div className="card p-4">
            <LoadCardTitle className="mb-4" />
            <div className="mb-3">
              <LoadCardText />
              <LoadCardText />
            </div>

            <div>
              <LoadCardText />
              <LoadCardText />
            </div>
          </div>

          <div className="card p-4">
            <LoadCardTitle className="mb-4" />
            <div className="mb-3">
              <LoadCardText />
              <LoadCardText />
            </div>

            <div>
              <LoadCardText />
              <LoadCardText />
            </div>
          </div>

          <div className="card p-4">
            <LoadCardTitle className="mb-4" />
            <LoadCardText style={{ width: '75%' }} />
            <hr />
            <LoadCardTitle className="mb-4" />
            <div style={{ width: '75%' }}>
              <LoadCardText />
              <LoadCardText />
              <LoadCardText />
              <LoadCardText />
            </div>
          </div>
        </Flex.Item>
        <Flex.Item grow={3}>
          <div className="card p-4" style={{ height: '14rem' }}>
            <LoadCardTitle />
          </div>
          <div className="card p-4">
            <LoadCardTitle className="mb-4" />

            <div style={{ width: '75%' }}>
              <LoadCardText />
              <LoadCardText />
              <LoadCardText />
              <LoadCardText />
            </div>
          </div>

          <div className="card p-4">
            <LoadCardTitle className="mb-4" />
            <LoadCardText />
            <hr />
            <LoadCardText />
            <LoadCardText />
            <hr />
            <LoadCardText />
            <LoadCardText />
          </div>
        </Flex.Item>
      </FlexContainer>
    </>
  );
};

export default LoadingOrderPage;
