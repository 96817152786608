import Flex from '@react-css/flex';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

const Container = styled.div`
  max-width: 600px;
`;

const OrderAttributes = ({ order, isSeller }) => {
  if (!isSeller) return null;
  if (!order) return null;
  if (!order.buyerAttributes) return null;
  if (isEmpty(order.buyerAttributes)) return null;

  return (
    <Container className="card p-4">
      <h4 className="mb-4">Attributes</h4>

      <Flex column gap="24px">
        {Object.keys(order.buyerAttributes).map((name) => {
          return (
            <Flex column gap="8px" key={uuid()}>
              <strong>{name}</strong>
              <code>{order.buyerAttributes[name].value}</code>
            </Flex>
          );
        })}
      </Flex>
    </Container>
  );
};
export default OrderAttributes;
