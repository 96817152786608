import { useState } from 'react';
import Modal from 'storybook/stories/cells/Modal';

import Button from 'components/Common/Button';
import Message, { useMessage } from 'components/Common/Message';
import { deleteProduct } from 'utils/api/products';

const DeleteProductModal = ({ product = {}, onDismiss, show, onProductDeleted }) => {
  const [deleting, setDeleting] = useState(false);
  const { showErrorMessage, messageState, hideMessage } = useMessage();

  const onCloseModal = () => {
    hideMessage();
    setDeleting(false);
    onDismiss();
  };

  const onDelete = async () => {
    const productId = product.id;
    setDeleting(true);
    try {
      await deleteProduct(productId);
      onCloseModal();
      onProductDeleted();
    } catch (err) {
      showErrorMessage({ title: 'Error deleting product', message: err.message });
      setDeleting(false);
    }
  };

  return (
    <Modal.Root
      show={show}
      aria-labelledby="delete-product-modal-title"
      centered
      onHide={onCloseModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Product Delete</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Message
          show={messageState.show}
          onClose={hideMessage}
          kind={messageState.kind}
          className="mt-0"
        >
          <p className="m-0 mb-1">
            <strong>{messageState.title}</strong>
          </p>
          <p className="m-0">{messageState.message}</p>
        </Message>

        <h4>Are you sure?</h4>

        <p>
          By deleting this product you will lose your product PERMANENTLY. You will have to add your
          product again if you want to use it.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" color="white" type="button" onClick={onCloseModal}>
          Dismiss
        </Button>

        <Button size="sm" type="submit" color="danger" disabled={deleting} onClick={onDelete}>
          Delete Product
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
};

export default DeleteProductModal;
