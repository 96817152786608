import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';

import useAlertQueue from 'hooks/useAlertQueue';
import type { UpdatePriceListParams } from 'utils/api/priceLists';
import { updatePriceList } from 'utils/api/priceLists';

import type { Company } from 'types/models/company';
import type { Partner } from 'types/models/partner';
import type { PriceList } from 'types/models/price-list';

import AutomationCard from 'containers/PriceListPage/Settings/AutomationCard';
import BasicInfoCard from 'containers/PriceListPage/Settings/BasicInfoCard';
import PricingCalculationCard from 'containers/PriceListPage/Settings/PricingCalculationCard';

export interface PriceListSettingsProps {
  company?: Company | null;
  priceList: PriceList;
  partners: Partner[];
  onPartnerUpdated: () => void;
  refreshPriceList: () => void;
}

const PriceListSettings = ({
  company,
  priceList,
  partners,
  onPartnerUpdated,
  refreshPriceList,
}: PriceListSettingsProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const updatingPriceList = useMutation({
    mutationFn: (data: UpdatePriceListParams) => updatePriceList(priceList.id, data),
    onSuccess: () => {
      addSuccessAlert('Success', 'Price list updated.');
      refreshPriceList();
    },
    onError: (error) => {
      addErrorAlert('Something went wrong', 'We were unable to update the price list.');
      console.error('Error updating price list', error);
    },
  });

  return (
    <Flex column gap="24px" style={{ marginBottom: '58px' }}>
      <BasicInfoCard
        priceList={priceList}
        partners={partners}
        onPartnerUpdated={onPartnerUpdated}
        onFormSubmit={updatingPriceList.mutate}
        isPriceListUpdated={updatingPriceList.isSuccess}
      />

      <PricingCalculationCard
        priceList={priceList}
        partners={partners}
        onFormSubmit={updatingPriceList.mutate}
        isPriceListUpdated={updatingPriceList.isSuccess}
      />

      <AutomationCard
        company={company}
        priceList={priceList}
        onFormSubmit={updatingPriceList.mutate}
        isPriceListUpdated={updatingPriceList.isSuccess}
      />
    </Flex>
  );
};

export default PriceListSettings;
