import Flex from '@react-css/flex';
import { useState } from 'react';
import Dropdown from 'storybook/stories/cells/Dropdown';
import SearchForm, { SearchFormButton, SearchFormInput } from 'storybook/stories/cells/SearchForm';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import Link from 'storybook/stories/molecules/Link';
import CompanyProfile from 'storybook/stories/organisms/CompanyProfile';
import styled from 'styled-components';
import type { Company } from 'types/models/company';
import type { UserMembership } from 'types/models/user';

export const MAX_LENGTH_FOR_DEFAULT_VIEW = 4;

const MenuListContainer = styled.div`
  max-height: 224px;
  overflow-y: auto;
`;

const DropdownContentWrapper = styled.div`
  max-width: 450px;
`;

interface SwitchCompanyContentProps {
  memberships: UserMembership[];
  onSelect: (params: { companyObjectId: string }) => void;
  close: () => void;
}

const SwitchCompanyContent = ({ memberships, onSelect, close }: SwitchCompanyContentProps) => {
  const [filter, setFilter] = useState('');

  return (
    <Flex column gap="24px">
      {memberships.length > MAX_LENGTH_FOR_DEFAULT_VIEW && (
        <SearchForm>
          <SearchFormInput
            aria-label="Search for a company"
            id="title"
            name="title"
            placeholder="Search for a company"
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            value={filter}
          />
          <SearchFormButton />
        </SearchForm>
      )}

      <MenuListContainer>
        <Flex column gap="16px">
          {memberships
            .filter((membership) => {
              return membership.companyName.toLowerCase().includes(filter.toLowerCase());
            })
            .map((membership) => (
              <CompanyProfile
                company={membership}
                onClick={() => {
                  onSelect({ companyObjectId: membership.companyObjectId });
                  close();
                }}
                key={membership.companyObjectId}
              />
            ))}
        </Flex>
      </MenuListContainer>

      <Flex justifyEnd gap="8px">
        <TertiaryButton
          as={Link}
          to={{
            pathname: '/onboarding',
            state: { isCreatingAnotherCompany: true },
          }}
          size="small"
          $iconName="add"
        >
          Add New Account
        </TertiaryButton>
      </Flex>
    </Flex>
  );
};

const CreateCompanyContent = () => {
  return (
    <Flex column gap="32px">
      <Dropdown.Header heading="Create a New Company" iconName="info" />

      <Flex column gap="16px">
        <Body as="p">
          You can now manage multiple Modern Dropship accounts through a single email address.
        </Body>

        <Body as="p">
          Company accounts can be added to operate as both Buyer and Seller, connect multiple
          ecommerce platforms, and manage dropship orders across your partners.
        </Body>

        <Flex justifyEnd>
          <PrimaryButton
            as={Link}
            to={{
              pathname: '/onboarding',
              state: { isCreatingAnotherCompany: true },
            }}
            size="small"
            $iconName="add"
          >
            Add New Account
          </PrimaryButton>
        </Flex>

        <Icon color="warning500" name="warning" position="before">
          Note: An e-commerce account can only be integrated with one company profile.
        </Icon>
      </Flex>
    </Flex>
  );
};

interface CompanyPickerProps {
  selectedMembership: Company | UserMembership | null;
  selectableMemberships: UserMembership[];
  hasMultipleMemberships: boolean;
  onSelect: (params: { companyObjectId: string }) => void;
}

const CompanyPicker = ({
  selectedMembership,
  selectableMemberships,
  hasMultipleMemberships,
  onSelect,
}: CompanyPickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown placement="bottom-start" open={isOpen} onOpenChange={setIsOpen}>
      <Dropdown.Trigger asChild>
        <CompanyProfile
          company={selectedMembership}
          hasDropdownArrow
          onClick={() => setIsOpen(!isOpen)}
        />
      </Dropdown.Trigger>
      <Dropdown.Content>
        {({ close }) => (
          <DropdownContentWrapper>
            {hasMultipleMemberships ? (
              <SwitchCompanyContent
                memberships={selectableMemberships}
                onSelect={onSelect}
                close={close}
              />
            ) : (
              <CreateCompanyContent />
            )}
          </DropdownContentWrapper>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default CompanyPicker;
