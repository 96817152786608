import { useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';

import Button from 'components/Common/Button';
import FormField from 'components/Common/FormField';

const AddImageModal = ({ onAddImage, show, onDismiss }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();
  const imageUrlValue = watch('imageUrl');

  const onSubmit = ({ imageUrl }) => {
    onAddImage(imageUrl);
    onDismiss();
  };

  return (
    <Modal.Root
      show={show}
      size="md"
      aria-labelledby="add-product-image-modal"
      centered
      onHide={onDismiss}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Image from URL</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <FormField
            label="Paste Image URL"
            id="add-product-image-url"
            type="text"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('imageUrl', {
              required: 'Please enter a URL',
            })}
            error={errors.imageUrl}
            placeholder="https://"
            size="md"
          />
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" color="white" type="button" onClick={onDismiss}>
            Cancel
          </Button>

          <Button
            size="sm"
            type="submit"
            color="primary"
            disabled={!imageUrlValue}
            aria-label="Add Image"
          >
            Add Image
          </Button>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default AddImageModal;
