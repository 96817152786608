import { useState } from 'react';

import { useAppDispatch } from 'store';
import { updateOnboardingStatus } from 'store/slices/company';

import Flex from '@react-css/flex';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

interface ReviewEDISpecsCardProps {
  done: boolean;
}

const ViewEDISpecsCard = ({ done }: ReviewEDISpecsCardProps) => {
  const dispatch = useAppDispatch();
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const handleCopySpecLinkButtonClick = () => {
    window.navigator.clipboard.writeText(
      'https://support.moderndropship.com/article/gmys86kwlo#step_5_configure_edi'
    );
    setIsLinkCopied(true);
    setTimeout(() => setIsLinkCopied(false), 3000);
  };

  const onMarkComplete = () => {
    dispatch(updateOnboardingStatus({ ediSpecsReviewCompleted: true }));
  };

  return (
    <GetStartedCard
      isComplete={done}
      title="Review EDI Specs"
      completedUi={
        <Body as="p">
          You have reviewed our{' '}
          <SupportLink article="gmys86kwlo#step_5_configure_edi">EDI specifications</SupportLink>.
        </Body>
      }
    >
      <Flex column gap="16px">
        <Body as="p">
          View our{' '}
          <SupportLink article="gmys86kwlo#step_5_configure_edi">
            EDI specifications and documentation
          </SupportLink>
          . You can share them with your EDI specialist or third party integrator. Once your EDI
          connection has been setup, you can mark this as completed.
        </Body>

        <Flex.Item alignSelfEnd>
          <Flex gap="8px">
            <SecondaryButton
              size="small"
              onClick={handleCopySpecLinkButtonClick}
              disabled={isLinkCopied}
            >
              {isLinkCopied ? `Link Copied` : `Copy EDI Spec Link`}
            </SecondaryButton>

            <PrimaryButton size="small" onClick={onMarkComplete}>
              Mark as Completed
            </PrimaryButton>
          </Flex>
        </Flex.Item>
      </Flex>
    </GetStartedCard>
  );
};

export default ViewEDISpecsCard;
