import { createSelector } from '@reduxjs/toolkit';

import { selectMe } from 'store/selectors/me';

/**
 * User
 */

export const selectUser = createSelector(selectMe, (me) => me.user);

export const selectUserMemberships = createSelector(selectUser, (user) => user?.memberships ?? []);

export const selectIsSuperUser = createSelector(selectUser, (user) => !!user?.superUser);

/**
 * Helper Selectors
 */

export const selectIsSudoing = createSelector(
  selectUser,
  selectUserMemberships,
  (user, memberships) =>
    memberships.every((membership) => membership.companyObjectId !== user?.companyObjectId)
);

export const selectUserMembershipNames = createSelector(selectUserMemberships, (memberships) =>
  memberships.map((membership) => membership.companyName)
);
