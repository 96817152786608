import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import Modal from 'storybook/stories/cells/Modal';
import styled from 'styled-components';
import { useBoolean } from 'usehooks-ts';

import useAlertQueue from 'hooks/useAlertQueue';
import { useAppSelector } from 'store';
import { selectIsProductPlatformSelectEnabled, selectIsSeller } from 'store/selectors/me/company';
import Tooltip from 'storybook/stories/cells/Tooltip';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import type { LegacyBuyerProduct } from 'types/models/legacy-buyer-product';
import { deselectProduct, selectProduct } from 'utils/api/products';

interface ResyncProductModalProps {
  onClose: () => void;
  isOpen: boolean;
  onReselect: () => void;
}

const ResyncButtonsRow = styled(Flex)`
  margin-bottom: 16px;
`;

const ResyncProductModal = ({ onClose, isOpen, onReselect }: ResyncProductModalProps) => {
  return (
    <Modal.Root show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        This product is already in your e-commerce platform. Syncing will overwrite existing product
        information in your e-commerce platform with the changes here.
      </Modal.Body>

      <ResyncButtonsRow justifySpaceAround>
        <PrimaryButton kind="destructive" onClick={onClose}>
          Cancel
        </PrimaryButton>

        <PrimaryButton
          onClick={() => {
            onReselect();
            onClose();
          }}
        >
          Sync
        </PrimaryButton>
      </ResyncButtonsRow>
    </Modal.Root>
  );
};

export interface SelectProductButtonProps {
  product: LegacyBuyerProduct;
  onSelectToggled: (shouldSelect: boolean) => void;
}

const SelectProductButton = ({ product, onSelectToggled }: SelectProductButtonProps) => {
  const isPlatformSelectEnabled = useAppSelector(selectIsProductPlatformSelectEnabled);
  const isSeller = useAppSelector(selectIsSeller);
  const showResyncModal = useBoolean(false);

  const handleModalClose = () => {
    showResyncModal.setFalse();
  };

  // Alert Handling

  const { addErrorAlert } = useAlertQueue();

  // Mutations

  type SelectingProductMutationArgs = {
    id: string;
  };

  const selectingProduct = useMutation({
    mutationFn: ({ id }: SelectingProductMutationArgs) => selectProduct(id),
    onError: (error, { id }) => {
      addErrorAlert('Something went wrong', 'Unable to select product');
      console.error(`Unable to select product ${id}`, error);
    },
  });

  type DeselectingProductMutationArgs = {
    id: string;
  };

  const deselectingProduct = useMutation({
    mutationFn: ({ id }: DeselectingProductMutationArgs) => deselectProduct(id),
    onError: (error, { id }) => {
      addErrorAlert('Something went wrong', 'Unable to deselect product');
      console.error(`Unable to deselect product ${id}`, error);
    },
  });

  const isLoading = selectingProduct.isLoading || deselectingProduct.isLoading;

  // Event Handlers

  const handleClick = async (shouldSelect: boolean, productId: string) => {
    await (shouldSelect ? selectingProduct : deselectingProduct).mutateAsync({
      id: productId,
    });
    onSelectToggled(shouldSelect);
  };

  // Render

  if (isSeller) return null;
  if (!product) return null;

  const { selected, selectedAvailable, selectedAvailableReason } = product;
  const isSelectable = isPlatformSelectEnabled || selectedAvailable;

  if (!isSelectable) return null;

  if (!selectedAvailable)
    return (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <PrimaryButton
            disabled
            kind="destructive"
            aria-label="select unavailable"
            $iconName="error"
            $iconAfter
          >
            Select Unavailable
          </PrimaryButton>
        </Tooltip.Trigger>
        <Tooltip.Content>
          {selectedAvailableReason || 'Selection is unavailable for this product'}
        </Tooltip.Content>
      </Tooltip>
    );

  return (
    <>
      <PrimaryButton
        onClick={() => {
          if (!selected && product.synced) {
            showResyncModal.setTrue();
          } else {
            handleClick(!selected, product.id);
          }
        }}
        disabled={!selectedAvailable || isLoading}
        aria-label="toggle select"
      >
        {/* Sync is essentially re-sync, but Sync is a more user-friendly label */}
        {!selected && (product.synced ? 'Sync' : 'Select')}

        {selected && 'Deselect'}
      </PrimaryButton>

      <ResyncProductModal
        isOpen={showResyncModal.value}
        onClose={handleModalClose}
        onReselect={() => handleClick(true, product.id)}
      />
    </>
  );
};

export default SelectProductButton;
