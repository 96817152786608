import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import styled from 'styled-components';

import Card from 'storybook/stories/cells/Card';
import Table from 'storybook/stories/cells/Table';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import type { Invoice } from 'types/models/invoice';
import { isZeroDate, prettyDate } from 'utils/date';

import { useAppSelector } from 'store';
import { selectMe } from 'store/selectors/me';
import { CommerceType } from 'types/models/company';
import { canManuallyPayInvoice, friendlyInvoiceSource } from 'utils/invoices';
import InvoiceStatusPill from '../InvoiceStatusPill';
import { InvoicePayButton } from './InvoicePayButton';

export interface InvoiceDetailsCardProps {
  invoice: Invoice;
}

// TableIndentContainer provides horizontal flex layout with padding
// to match the table element on either side
const TableIndentContainer = styled(Flex)`
  flex-direction: row;
  width: 100%;
  padding: 0 8px;
  align-items: stretch;
`;

// StyledGrid modifies grid with some layout changes
const StyledGrid = styled(Grid).attrs(() => ({ gap: '16px' }))`
  height: auto;
`;

// InvoiceDetailsCard shows detailed information about an invoice
const InvoiceDetailsCard = ({ invoice }: InvoiceDetailsCardProps) => {
  const { company } = useAppSelector(selectMe);
  const isSeller = company?.commerceType === CommerceType.Seller;

  return (
    <Card>
      <Flex column gap="16px">
        <TableIndentContainer>
          <Flex.Item grow={1}>
            <h3>Details</h3>
          </Flex.Item>
          <Flex.Item>
            <InvoiceStatusPill status={invoice.status} />
          </Flex.Item>
        </TableIndentContainer>

        <StyledGrid rows="auto">
          <StyledGrid.Item>
            <Table>
              <Table.TBody>
                <Table.TR>
                  <Table.TH>ID</Table.TH>
                  <Table.TD>{invoice.id}</Table.TD>
                </Table.TR>
                <Table.TR>
                  <Table.TH>Created</Table.TH>
                  <Table.TD>{prettyDate(invoice.created)}</Table.TD>
                </Table.TR>
                <Table.TR>
                  <Table.TH>Updated</Table.TH>
                  <Table.TD>{prettyDate(invoice.updated)}</Table.TD>
                </Table.TR>
                {!isZeroDate(invoice?.toBePostedOnDate) ? (
                  <Table.TR>
                    <Table.TH>Date to be Posted</Table.TH>
                    <Table.TD>{prettyDate(invoice?.toBePostedOnDate)}</Table.TD>
                  </Table.TR>
                ) : null}
                {invoice?.voidReason ? (
                  <Table.TR>
                    <Table.TH>Void Reason</Table.TH>
                    <Table.TD>{invoice?.voidReason}</Table.TD>
                  </Table.TR>
                ) : null}
                {invoice.source ? (
                  <Table.TR>
                    <Table.TH>{invoice.posted ? 'Posted To' : 'Will Post To'}</Table.TH>
                    <Table.TD>{friendlyInvoiceSource(invoice.source)}</Table.TD>
                  </Table.TR>
                ) : null}
                {invoice.buyerCode ? (
                  <Table.TR>
                    <Table.TH>Buyer Code</Table.TH>
                    <Table.TD>{invoice.buyerCode}</Table.TD>
                  </Table.TR>
                ) : null}
                {invoice.sellerCode ? (
                  <Table.TR>
                    <Table.TH>Seller Code</Table.TH>
                    <Table.TD>{invoice.sellerCode}</Table.TD>
                  </Table.TR>
                ) : null}
              </Table.TBody>
            </Table>
          </StyledGrid.Item>
          {invoice.download && (
            <StyledGrid.Item>
              <TableIndentContainer>
                <PrimaryButton
                  onClick={() => {
                    window.open(invoice.download, '_blank');
                  }}
                >
                  Download Posted Invoice
                </PrimaryButton>
              </TableIndentContainer>
            </StyledGrid.Item>
          )}
          {invoice.hostedURL && (
            <StyledGrid.Item>
              <TableIndentContainer>
                <SecondaryButton
                  onClick={() => {
                    window.open(invoice.hostedURL, '_blank');
                  }}
                >
                  View Posted Invoice
                </SecondaryButton>
              </TableIndentContainer>
            </StyledGrid.Item>
          )}
        </StyledGrid>
        {isSeller && canManuallyPayInvoice(invoice) && <InvoicePayButton invoice={invoice} />}
      </Flex>
    </Card>
  );
};

export default InvoiceDetailsCard;
