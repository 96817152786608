import type { PaginatedResponse, Response } from 'types/api';
import type { Me } from 'types/models/auth';
import type {
  ConsentChallenge,
  ConsentRequestSession,
  LoginChallenge,
  OAuth2Connection,
  OAuth2Integration,
  ScopeMap,
} from 'types/models/hydra';
import type { PartnerGettingStartedSteps } from 'types/models/partner';
import ApiError from 'utils/ApiError';
import config from 'utils/config';
import { getJSONOptions } from 'utils/options';
import { deleteRequest, getRequest, postRequest, refreshToken, request } from 'utils/request';

// Does not use standard request-refresh lifecycle because we should never redirect back to login after if this request returns 401.
// By just throwing an error, the user and company will remain null and the user will redirect to login from the React routing.
// Note: even though the email and userId cookies are still short circuiting this API call on app load (see /redux/slices/me), they could be removed and we could
// rely solely on the HTTP only cookies and backend session status to dictate if the user is logged in.
export const fetchMe = async (): Promise<Response<Me>> => {
  const url = `${config.apiURL}/auth/me`;
  const options = getJSONOptions();

  try {
    return await request(url, options);
  } catch (error) {
    if (!(error instanceof ApiError)) throw error;

    // can't refresh, no cookies
    if (error.isUnauthorized && error.isCookieInvalid) throw new ApiError('Require authorization');
    // other error
    if (!(error.isUnauthorized && error.shouldRefresh)) throw new ApiError('Error fetching me');
    // try to refresh
    await refreshToken().catch((e) => e);
    // retry fetch
    return request(url, options);
  }
};

export const fetchGetStarted = (): Promise<Response<PartnerGettingStartedSteps>> =>
  getRequest('/auth/me/getting-started');

interface LogMeOutResponse {
  success: boolean;
}

export const logMeOut = (): Promise<LogMeOutResponse> => postRequest('/auth/logout');

type ConnectStripeResponse = {
  authorizeURL: string;
};

export const connectStripe = (): Promise<ConnectStripeResponse> =>
  postRequest('/auth/stripe/connect');

/* Shared */

export type ChallengeRedirectResponse = Response<RedirectResponseData>;

export type RedirectResponseData = {
  redirect_to: string;
};

/* Login */
export type LoginChallengeResponse = Response<LoginChallenge>;

export type AcceptLoginChallengeParams = {
  subject: string; // email?
  remember: boolean; // keep the integration enabled
  rememberFor: number; // milliseconds
  acr?: number; // access control level (higher = more access)
};

export type RejectLoginChallengeParams = {
  error: string;
  errorDescription: string;
};

export const getLoginChallenge = async (
  loginChallengeId: string
): Promise<LoginChallengeResponse> => getRequest(`/auth/oauth2/login/${loginChallengeId}`);

export const acceptLoginChallenge = async (
  loginChallengeId: string,
  params: AcceptLoginChallengeParams
): Promise<ChallengeRedirectResponse> =>
  postRequest(`/auth/oauth2/login/${loginChallengeId}/accept`, params);

export const rejectLoginChallenge = async (
  loginChallengeId: string,
  params: RejectLoginChallengeParams
): Promise<ChallengeRedirectResponse> =>
  postRequest(`/auth/oauth2/login/${loginChallengeId}/reject`, params);

/* Consent */
export type ConsentChallengeResponse = Response<ConsentChallenge>;

export type AcceptConsentChallengeParams = {
  remember: boolean; // keep the integration enabled
  rememberFor: number; // milliseconds
  grantAccessTokenAudience: string[]; // used by hydra to check if requested audience is allowed by client
  grantScope: string[]; // these are the scopes to be granted
  consentRequestSession: ConsentRequestSession; // session data
};

export type RejectConsentChallengeParams = {
  error: string;
  errorDescription: string;
};

export const getConsentChallenge = async (
  consentChallengeId: string
): Promise<ConsentChallengeResponse> => getRequest(`/auth/oauth2/consent/${consentChallengeId}`);

export const acceptConsentChallenge = async (
  consentChallengeId: string,
  params: AcceptConsentChallengeParams
): Promise<ChallengeRedirectResponse> =>
  postRequest(`/auth/oauth2/consent/${consentChallengeId}/accept`, params);

export const rejectConsentChallenge = async (
  consentChallengeId: string,
  params: RejectConsentChallengeParams
): Promise<ChallengeRedirectResponse> =>
  postRequest(`/auth/oauth2/consent/${consentChallengeId}/reject`, params);

/* Integrations */
export type IntegrationsResponse = PaginatedResponse<OAuth2Integration[]>;

export const getAllIntegrations = async (): Promise<IntegrationsResponse> =>
  getRequest(`/auth/oauth2/integration`);

export const getIntegrationDetails = async (integrationId: string): Promise<IntegrationsResponse> =>
  getRequest(`/auth/oauth2/integration/${integrationId}`);

/* Connections (company <> integration) */
export type GetConnectionsResponse = PaginatedResponse<OAuth2Connection[]>;
export type CreateConnectionResponse = Response<OAuth2Connection>;

export const getAllConnections = async (companyId: string): Promise<GetConnectionsResponse> =>
  getRequest(`/auth/oauth2/connection/company/${companyId}`);

export const createConnection = async (
  integrationId: string,
  companyId: string
): Promise<CreateConnectionResponse> =>
  postRequest(`/auth/oauth2/connection/integration/${integrationId}/company/${companyId}`);

export const removeConnection = async (
  integrationId: string,
  companyId: string
): Promise<Response<unknown>> =>
  deleteRequest(`/auth/oauth2/connection/integration/${integrationId}/company/${companyId}`);

/* Scopes */
export type ScopesResponse = Response<ScopeMap>;

export const getScopes = async (): Promise<ScopesResponse> => getRequest(`/auth/oauth2/scope`);
