import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import useAlertQueue from 'hooks/useAlertQueue';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import type { Company } from 'types/models/company';
import { CommerceType } from 'types/models/company';
import { updateCompany } from 'utils/api/companies';

interface UpdateBillingSettingsFormData {
  numBillingDelayDays: number;
}

export interface BillingSettingsFormProps {
  company?: Company | null;
}

const BillingSettingsForm = ({ company }: BillingSettingsFormProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty },
  } = useForm<UpdateBillingSettingsFormData>({
    defaultValues: {
      numBillingDelayDays: company?.defaultBillingSettings.paymentTerms.numBillingDelayDays,
    },
  });

  const updatingBillingSettings = useMutation({
    mutationFn: (data: UpdateBillingSettingsFormData) =>
      updateCompany(company?._id ?? '', {
        paymentTerms: { numBillingDelayDays: data.numBillingDelayDays },
      }),
    onSuccess: (data: Company) => {
      // keep the form's default value in sync with the server
      reset({
        numBillingDelayDays: data.defaultBillingSettings.paymentTerms.numBillingDelayDays,
      });
      addSuccessAlert('Success', 'Your payment terms have been updated.');
    },
    onError: (error) => {
      addErrorAlert('Something went wrong', 'We were unable to update your payment terms.');
      console.error('Error updating payment terms', error);
    },
  });

  if (company?.commerceType === CommerceType.Seller) return null;

  return (
    <Flex column gap="12px">
      <Heading variant="Headings/H3" as="h4">
        Payment Terms
      </Heading>

      <form onSubmit={handleSubmit((data) => updatingBillingSettings.mutate(data))}>
        <Flex column gap="6px">
          <Label isRequired htmlFor="numBillingDelayDays">
            Number of days between shipment and invoicing
          </Label>

          <Flex.Item>
            <Input
              data-testid="numBillingDelayDays"
              type="number"
              min="0"
              {...register('numBillingDelayDays', { required: true, valueAsNumber: true })}
            />
          </Flex.Item>

          {isDirty && (
            <Flex justifyEnd gap="6px">
              <PrimaryButton type="submit" size="small">
                Save
              </PrimaryButton>
            </Flex>
          )}
        </Flex>
      </form>
    </Flex>
  );
};

export default BillingSettingsForm;
