import Tooltip from 'storybook/stories/cells/Tooltip';
import Body from 'storybook/stories/molecules/Body';
import StatusIcon from 'storybook/stories/molecules/Icon/StatusIcon';
import { InvoicePosterReason, type Partner } from 'types/models/partner';
import { friendlyInvoicePosterReason } from 'utils/partners';

type InvoicingStatusProps = {
  partner: Partner;
};

const InvoicingStatus = ({ partner }: InvoicingStatusProps) => {
  if (partner.billing && partner.billingSettings.invoicePosterReason !== InvoicePosterReason.None) {
    return (
      <StatusIcon variant="warning">
        <Tooltip>
          <Tooltip.Trigger asChild>
            <Body variant="Body/Caption" color="bodyTextSecondary">
              Automated Invoicing
            </Body>
          </Tooltip.Trigger>
          <Tooltip.Content>
            Automated Stripe Invoicing is enabled, but cannot be used yet because:{' '}
            <b>{friendlyInvoicePosterReason(partner.billingSettings.invoicePosterReason)}</b>{' '}
          </Tooltip.Content>
        </Tooltip>
      </StatusIcon>
    );
  }

  return (
    <StatusIcon variant={partner.billing ? 'checked' : 'error'}>
      <Body variant="Body/Caption" color="bodyTextSecondary">
        Automated Invoicing
      </Body>
    </StatusIcon>
  );
};

export default InvoicingStatus;
