import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import Modal from 'storybook/stories/cells/Modal';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

import Label from 'storybook/stories/molecules/Label';

type ReactSelectMultiOption = {
  label: string;
  value: string;
  __isNew__: boolean;
};

export type TagsModalFormInputs = {
  tags: ReactSelectMultiOption[];
};

interface TagsModalProps {
  onDismiss: () => void;
  onSubmit: SubmitHandler<TagsModalFormInputs>;
  show: boolean;
  type: 'add' | 'remove';
}

const TagsModal = ({ onDismiss, onSubmit, show, type }: TagsModalProps) => {
  const { control, handleSubmit } = useForm<TagsModalFormInputs>();

  return (
    <Modal.Root
      show={show}
      size="lg"
      aria-labelledby="product-tags-modal-title"
      centered
      onHide={onDismiss}
      animation={false}
      id="product-tags-modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {type === 'add' && 'Add Tags'}
            {type === 'remove' && 'Remove Tags'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Label htmlFor="tags">Tags</Label>
          <Controller
            control={control}
            name="tags"
            defaultValue={[]}
            render={({ field }) => <CreatableSelect isMulti inputId="tags" {...field} />}
          />
        </Modal.Body>

        <Modal.Footer>
          <SecondaryButton size="small" type="button" onClick={onDismiss}>
            Dismiss
          </SecondaryButton>

          <SecondaryButton size="small" type="submit" id="product-tags-btn">
            Save
          </SecondaryButton>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default TagsModal;
