/**
 * Me module is used to load current user and user's company
 * This slice of state is the single source of truth
 * for the current user and the company they belong to
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  removeUserFromCompany,
  updateBigCommerceAuth,
  updateShopifyAuth,
  updateWooCommerceAuth,
} from 'store/slices/company';
import { updateUser } from 'store/slices/settings/account';
import { updateUserCompany, uploadCompanyLogo } from 'store/slices/settings/company';
import { rollApiKey } from 'store/slices/settings/integrations';
import { skipStripeSetup } from 'store/slices/stripe';
import type { Me } from 'types/redux';
import { fetchMe } from 'utils/api/auth';
import { sudoToken, type SudoTokenParams } from 'utils/api/login';
import { getIsAuthenticated, setAccountType } from 'utils/cookies';

export const refreshMe = createAsyncThunk('me/refresh', async () => fetchMe());

export const getCurrentUserAndCompany = createAsyncThunk(
  'me/getCurrentUserAndCompany',
  async () => {
    if (!getIsAuthenticated()) {
      return { user: null, company: null, subscriptions: null } as Me;
    }

    const response = await fetchMe();

    const { user, company, subscriptions } = response.data;

    if (company) {
      setAccountType(company.commerceType);
    }

    return { user, company, subscriptions } as Me;
  }
);

export const sudoUser = createAsyncThunk('me/sudo', (data: SudoTokenParams) => {
  return sudoToken(data);
});

export const initialState: Me = {
  user: null,
  company: null,
  subscriptions: null,
  error: null,
};

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    addUserToCompany: (draft, action) => {
      if (draft.company) draft.company.users.push(action.payload);
    },
    setCompany: (draft, action) => {
      draft.company = action.payload;
    },
    setLocations: (draft, action) => {
      if (draft.company) draft.company.locations = action.payload;
    },
    resetMe: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUserAndCompany.fulfilled, (draft, action) => {
      draft.user = action.payload.user;
      draft.company = action.payload.company;
      draft.subscriptions = action.payload.subscriptions;
    });

    builder.addCase(uploadCompanyLogo.fulfilled, (draft, action) => {
      draft.company = action.payload;
    });

    builder.addCase(updateUserCompany.fulfilled, (draft, action) => {
      draft.company = action.payload;
    });

    builder.addCase(updateWooCommerceAuth.fulfilled, (draft, action) => {
      draft.company = {
        ...draft.company,
        ...action.payload,
        productPlatformActive: true,
        orderPlatformActive: true,
      };
    });

    builder.addCase(updateBigCommerceAuth.fulfilled, (draft, action) => {
      draft.company = {
        ...draft.company,
        ...action.payload,
        productPlatformActive: true,
        orderPlatformActive: true,
      };
    });

    builder.addCase(updateShopifyAuth.fulfilled, (draft, action) => {
      draft.company = {
        ...draft.company,
        ...action.payload,
      };
    });

    builder.addCase(skipStripeSetup.fulfilled, (draft, action) => {
      draft.company = action.payload;
    });

    builder.addCase(rollApiKey.fulfilled, (draft, action) => {
      if (draft.user) draft.user.apiKey = action.payload.apiKey;
    });

    builder.addCase(updateUser.fulfilled, (draft, action) => {
      draft.user = action.payload;
    });

    builder.addCase(removeUserFromCompany.fulfilled, (draft, action) => {
      draft.company = action.payload.data;
    });

    builder.addCase(refreshMe.fulfilled, (draft, action) => {
      draft.user = action.payload.data.user ?? null;
      draft.company = action.payload.data.company ?? null;
      draft.subscriptions = action.payload.data.subscriptions ?? null;
    });
  },
});

export const { setCompany, addUserToCompany, setLocations, resetMe } = meSlice.actions;

export default meSlice.reducer;
