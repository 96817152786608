import PropTypes from 'prop-types';

const DocumentElement = (props) => {
  const { element = '', active = false } = props;
  const activeStyle = { borderBottom: '2px solid', color: '#2c7be5' };
  return (
    <>
      <span>*</span>
      <span style={active ? activeStyle : {}}>{element}</span>
    </>
  );
};

export default DocumentElement;

DocumentElement.propTypes = {
  element: PropTypes.string,
  active: PropTypes.bool,
};
