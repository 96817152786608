import { useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from 'components/Common/Button';
import FormField from 'components/Common/FormField';
import Spinner from 'components/Common/Spinner';
import useAlertQueue from 'hooks/useAlertQueue';
import { updateMagento2Auth } from 'store/slices/company';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { getPlatformUrl } from 'utils/platform';

const ConnectMagento2 = ({ dispatch, company }) => {
  const alertQueue = useAlertQueue();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const pending = useSelector(({ company: c }) => c.isUpdatingPlatformAuth);
  const connected = company.productPlatformActive;
  const [showForm, setShowForm] = useState(!connected);
  const storeUrl = getPlatformUrl(company, 'magento2');

  const onSubmit = ({ consumerKey, consumerSecret, accessToken, accessTokenSecret, storeView }) => {
    dispatch(
      updateMagento2Auth({
        magento2Auth: {
          platform: 'magento2',
          platformUrl: storeUrl,
          consumerKey,
          consumerSecret,
          accessToken,
          accessTokenSecret,
          storeView,
        },
      })
    ).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
        return;
      }
      setShowForm(false);
      alertQueue.addSuccessAlert('Success', 'Magento 2 credentials updated');
    });
  };

  return (
    <>
      <hr className="my-4" />
      <h4>
        Connect your Magento 2 Store
        {connected && (
          <Badge variant="success" pill className="ml-3 mt-n1">
            Connected
          </Badge>
        )}
      </h4>
      {!connected && (
        <p className="small text-muted mb-1">
          Please input your Magento 2 credentials so Modern Dropship can connect to your store.
          Credentials can be created in Magento at System &gt; Integrations &gt; Add New
          Integration. Make sure to set Resource Access to &quot;All&quot;. View the full{' '}
          <SupportLink article="uow9hojkbq">Magento 2 Connection Guide</SupportLink>.
        </p>
      )}
      <p className="small text-muted">
        Store: <strong>{storeUrl}</strong>
      </p>
      {showForm ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField
            type="text"
            id="magento2-consumer-key-input"
            label="Consumer Key"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('consumerKey', {
              required: 'Magento 2 Consumer Key is required',
            })}
            placeholder="Magento 2 Consumer Key"
            error={errors.magento2ConsumerKey}
          />
          <FormField
            type="text"
            id="magento2-consumer-secret-input"
            label="Consumer Secret"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('consumerSecret', {
              required: 'Magento 2 Consumer Secret is required',
            })}
            placeholder="Magento 2 Consumer Secret"
            error={errors.magento2ConsumerSecret}
          />
          <FormField
            type="text"
            id="magento2-access-token-input"
            label="AccessToken"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('accessToken', {
              required: 'Magento 2 Access Token is required',
            })}
            placeholder="Magento 2 Access Token"
            error={errors.magento2AccessToken}
          />
          <FormField
            type="text"
            id="magento2-access-token-secret-input"
            label="Access Token Secret"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('accessTokenSecret', {
              required: 'Magento 2 Access Token Secret is required',
            })}
            placeholder="Magento 2 Access Token Secret"
            error={errors.magento2AccessTokenSecret}
          />

          <FormField
            type="text"
            id="magento2-store-view-input"
            label="Store View"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('storeView')}
            placeholder="Optional - Magento 2 Store View"
            error={errors.magento2StoreView}
          />

          <div className="d-flex">
            <Button size="sm" type="submit" color="primary" outline>
              {pending ? <Spinner color="primary" small className="mx-3" /> : 'Save Credentials'}
            </Button>
            {connected && showForm && (
              <Button
                size="sm"
                color="white"
                type="button"
                className="ml-3"
                onClick={() => setShowForm(false)}
              >
                Cancel
              </Button>
            )}
          </div>
        </form>
      ) : (
        <Button size="sm" type="submit" color="white" onClick={() => setShowForm(true)}>
          Change Credentials
        </Button>
      )}
    </>
  );
};

export default ConnectMagento2;
