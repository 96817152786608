import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import SelectedErrorMenu from './SelectedErrorMenu';
import TradeOpsIssuesList from './TradeOpsIssuesList';

// Tuples of key/title pairs used to construct the tabbed sections
// Pairing a severity key with a displayed title
export const SEVERITIES = [
  ['all', 'All'],
  ['critical', 'Critical'],
  ['error', 'Error'],
  ['warning', 'Warning'],
  ['info', 'Info'],
];

const TradeOpsIssuesListContainer = ({
  isLoading,
  errors,
  onTabSelect,
  selectedSeverity,
  onStateChange,
}) => {
  const [selectedError, setSelectedError] = useState({});

  const handleErrorClick = (event, error) => {
    event.preventDefault();
    setSelectedError(error);
  };

  const handleStateChange = () => {
    setSelectedError({});
    onStateChange(selectedError.id);
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <Tabs
          activeKey={selectedSeverity}
          onSelect={onTabSelect}
          id="categorized-trade-ops-errors"
          className="mb-3"
        >
          {SEVERITIES.map(([key, title]) => (
            <Tab key={key} eventKey={key} title={title}>
              <TradeOpsIssuesList
                isLoading={isLoading}
                errors={errors}
                onErrorClick={handleErrorClick}
                selectedError={selectedError}
              />
            </Tab>
          ))}
        </Tabs>
      </div>

      <div className="col-md-4">
        {isEmpty(selectedError) ? (
          <p className="empty-right-menu-text text-muted">Select an error to view more details</p>
        ) : (
          <SelectedErrorMenu
            selectedError={selectedError}
            onAfterReopen={handleStateChange}
            onAfterArchive={handleStateChange}
          />
        )}
      </div>
    </div>
  );
};

TradeOpsIssuesListContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  selectedSeverity: PropTypes.string.isRequired,
  onStateChange: PropTypes.func.isRequired,
};

export default TradeOpsIssuesListContainer;
