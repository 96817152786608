import { Link } from 'react-router-dom';

import { isZeroDate, prettyDate } from 'utils/date';

import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import { InvoicePayButton } from 'containers/Invoices/Invoice/InvoicePayButton';
import { useAppDispatch } from 'store';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import StatusChip from 'storybook/stories/molecules/Chip/StatusChip';
import Heading from 'storybook/stories/molecules/Heading';
import { InvoiceStatus } from 'types/models/invoice';
import type { Order } from 'types/models/order';
import type { Partner } from 'types/models/partner';
import type ApiError from 'utils/ApiError';
import { getInvoice } from 'utils/api/invoices';
import { humanizeMoney } from 'utils/currencies';
import { canManuallyPayInvoice } from 'utils/invoices';
import { capitalizeFirstChar } from 'utils/strings';
import GenerateInvoiceForm from './GenerateInvoiceForm';

export interface OrderInvoiceProps {
  order: Order;
  partner?: Partner;
  isSeller: boolean;
  showErrorMessage: ({ message, title }: { message: string; title: string }) => void;
}

export const OrderInvoice = ({ order, partner, isSeller, showErrorMessage }: OrderInvoiceProps) => {
  const { invoiceId } = order;
  const dispatch = useAppDispatch();

  const fetchingInvoice = useQuery({
    queryKey: ['getInvoice', invoiceId],
    queryFn: () => getInvoice(invoiceId),
    enabled: !!invoiceId,
    onError: (err: ApiError) => {
      showErrorMessage({ message: err.message, title: 'Error fetching invoice' });
    },
  });

  const invoice = fetchingInvoice.data?.data;

  const isAutomaticInvoicingPartnership = partner?.billingSettings.generateOrderInvoices;
  const shouldShowGenerateInvoiceForm = isSeller && !isAutomaticInvoicingPartnership;
  return (
    <Card className="card p-4">
      <Heading variant="Headings/H3">Invoicing</Heading>
      {!invoice && (
        <>
          <Body variant="Body/Body Small" color="bodyTextSecondary">
            This order has not been invoiced.
            {isAutomaticInvoicingPartnership && (
              <> One will be automatically generated when the order is fulfilled.</>
            )}
          </Body>

          {shouldShowGenerateInvoiceForm && (
            <GenerateInvoiceForm
              dispatch={dispatch}
              showErrorMessage={showErrorMessage}
              order={order}
            />
          )}
        </>
      )}
      {invoice && (
        <Flex column gap="8px">
          {!isZeroDate(invoice.created) && (
            <Flex wrap gap="8px" alignItemsCenter>
              <Flex.Item grow={1}>
                <Body variant="Body/Body Small" color="bodyTextSecondary">
                  Billed on {prettyDate(invoice.created)}
                </Body>
              </Flex.Item>
            </Flex>
          )}
          <Flex wrap gap="8px" alignItemsCenter>
            <Body variant="Body/Header">Invoice ID:</Body>

            <Flex.Item grow={1}>
              <Link to={`/invoices/${invoice.id}`}>{invoice.id}</Link>
            </Flex.Item>
            <StatusChip kind="info">{capitalizeFirstChar(invoice.status)}</StatusChip>
          </Flex>

          <Flex wrap gap="8px" alignItemsCenter>
            <Body variant="Body/Header">Total Amount:</Body>
            <Body variant="Body/Body Small" color="bodyTextSecondary">
              {humanizeMoney({ amount: invoice.amountTotalCents, currency: invoice.currency })}
            </Body>
          </Flex>

          {invoice.status === InvoiceStatus.Paid && (
            <>
              <Flex wrap gap="8px" alignItemsCenter>
                <Body variant="Body/Header">Amount Paid:</Body>
                <Body variant="Body/Body Small" color="bodyTextSecondary">
                  {humanizeMoney({ amount: invoice.amountPaidCents, currency: invoice.currency })}
                </Body>
              </Flex>

              <Flex wrap gap="8px" alignItemsCenter>
                <Body variant="Body/Header">Amount Remaining:</Body>
                <Body variant="Body/Body Small" color="bodyTextSecondary">
                  {humanizeMoney({
                    amount: invoice.amountRemainingCents,
                    currency: invoice.currency,
                  })}
                </Body>
              </Flex>
            </>
          )}

          {invoice.hostedURL && (
            <Flex wrap gap="8px" alignItemsCenter>
              <Body variant="Body/Header">Invoice URL:</Body>
              <a href={invoice.hostedURL} style={{ wordBreak: 'break-word' }}>
                {invoice.hostedURL}
              </a>
            </Flex>
          )}

          {invoice.buyerCode && (
            <Flex wrap gap="8px" alignItemsCenter>
              <Body variant="Body/Header">Buyer Code:</Body>
              <Body variant="Body/Body Small" color="bodyTextSecondary">
                {invoice.buyerCode}
              </Body>
            </Flex>
          )}

          {invoice.sellerCode && (
            <Flex wrap gap="8px" alignItemsCenter>
              <Body variant="Body/Header">Seller Code:</Body>
              <Body variant="Body/Body Small" color="bodyTextSecondary">
                {invoice.sellerCode}
              </Body>
            </Flex>
          )}
          {invoice?.download && (
            <PrimaryButton as="a" href={invoice.download}>
              Download Invoice
            </PrimaryButton>
          )}
          {isSeller && canManuallyPayInvoice(invoice) && <InvoicePayButton invoice={invoice} />}
        </Flex>
      )}
    </Card>
  );
};
