import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import AddressForm from 'containers/GetStarted/Locations/AddressForm';
import useAlertQueue from 'hooks/useAlertQueue';
import { omit } from 'lodash';
import { startCase } from 'lodash/fp';
import { useDispatch } from 'react-redux';
import { returnsAddressAdded, shippingAddressAdded } from 'store/slices/getStarted';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import type { Address } from 'types/general';
import type { Company } from 'types/models/company';
import { useBoolean } from 'usehooks-ts';
import { updateCompany } from 'utils/api/companies';

export interface AddAddressCardProps {
  isDone: boolean;
  company: Company | null;
  addressType: string;
}

const AddAddressCard = ({ isDone, company, addressType }: AddAddressCardProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();
  const dispatch = useDispatch();
  const billingAddress = company?.locations?.find((location) => location.type === 'billing');
  const isEditing = useBoolean(false);
  const addressName = startCase(addressType);

  const updatingCompany = useMutation({
    mutationFn: (data: Address) => {
      if (!company?._id) {
        throw new Error('Company not found');
      }
      // Need to Remove _id from address, assume there is a nicer way to do this?
      const payload = omit(data, '_id');
      payload.type = addressType;
      return updateCompany(company?._id, { locations: [payload] });
    },
    onSuccess: () => {
      addSuccessAlert('Added Address', 'Your address has been saved.');
      if (addressType === 'shipping') {
        dispatch(shippingAddressAdded());
      }
      if (addressType === 'returns') {
        dispatch(returnsAddressAdded());
      }
    },
    onError: (error: { message: string }) => {
      addErrorAlert('Something went wrong', error.message);
      console.error('Unable to update address', error);
    },
  });

  return (
    <GetStartedCard
      isComplete={isDone}
      title={`Add a ${addressName} Address`}
      completedUi={
        <Body as="p">
          Manage your locations from your{' '}
          <Link to="/settings/company/locations">Location settings</Link>.
        </Body>
      }
    >
      {isEditing.value ? (
        <AddressForm addressType={addressType} />
      ) : (
        <Flex justifyEnd gap="16px">
          {billingAddress && (
            <SecondaryButton size="small" onClick={() => updatingCompany.mutate(billingAddress)}>
              Use billing address
            </SecondaryButton>
          )}

          <PrimaryButton size="small" onClick={isEditing.setTrue}>
            Add new address
          </PrimaryButton>
        </Flex>
      )}
    </GetStartedCard>
  );
};

export default AddAddressCard;
