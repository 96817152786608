import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  createSchema as createSchemaFromAPI,
  generateSchema as generateSchemaFromAPI,
  listSchemas as listSchemasFromAPI,
  setSchemaAsLive as setSchemaAsLiveFromAPI,
  useDefaultSchema as useDefaultSchemaFromAPI,
} from 'utils/api/edi';
import { sortSegmentsAndScopes } from 'utils/edi';

export const generateSchema = createAsyncThunk('ediSchemas/generateSchema', (params) => {
  const { documentNumber, body } = params;
  return generateSchemaFromAPI(documentNumber, body);
});

export const defaultSchema = createAsyncThunk('ediSchemas/defaultSchema', (params) => {
  const { documentNumber } = params;
  return useDefaultSchemaFromAPI(documentNumber);
});

export const createSchema = createAsyncThunk('ediSchemas/createSchema', (params) => {
  const { documentNumber, schema } = params;
  return createSchemaFromAPI(documentNumber, schema);
});

export const listSchemas = createAsyncThunk('ediSchemas/listSchemas', (payload) => {
  const { documentNumber, params } = payload;
  return listSchemasFromAPI(documentNumber, params);
});

export const setLiveSchema = createAsyncThunk('ediSchemas/setSchemaAsLive', (params) => {
  const { documentNumber, schemaID } = params;
  return setSchemaAsLiveFromAPI(documentNumber, schemaID);
});

// setScopedSchemaMapping is a recursive reducer for efficiently setting an
// arbitrarily nested mapping object within the mappings of a document schema
const setScopedSchemaMapping = (innerMappings, targetScope, scopesInDoc, key, mapping) => {
  if (innerMappings[targetScope]) {
    // innerMappings is a part of the draft state. It's safe to reassign.
    // eslint-disable-next-line no-param-reassign
    innerMappings[targetScope].mappings[key] = mapping;
    return;
  }
  const nextScopeName = scopesInDoc.find((scope) => innerMappings[scope]);
  setScopedSchemaMapping(
    innerMappings[nextScopeName].mappings,
    targetScope,
    scopesInDoc,
    key,
    mapping
  );
};

// selectSchemaMapping is a recursive selector for efficiently getting
// an arbitrarily nested mapping object within the mappings of a document shema
export const selectSchemaMapping = (mappings, scopesInDoc, targetScope, key) => {
  if (targetScope === '') {
    return mappings[key];
  }
  if (mappings[targetScope]) {
    return mappings[targetScope].mappings[key];
  }
  // Instead of determining the next key by iterating through
  // mappings (the number of which we can not control for) and checking
  // for mapping.relation.type=scope, we can determine it by checking for matches
  // in the list of possible scopes (which we do control, and is always less than 4)
  const nextScope = scopesInDoc.find((scope) => mappings[scope]);
  return selectSchemaMapping(mappings[nextScope].mappings, scopesInDoc, targetScope, key);
};

// initialState describes the core structure of state for this slice
const initialState = {
  schemaLoading: false,
  schemaLoaded: false,
  schema: {
    id: '',
    documentNumber: 0,
    name: '',
    live: false,
    type: '',
    mappings: {},
    sequence: [],
  },
  tree: {},
  sortedSegments: [],
  sortedScopes: [],
  schemaSaving: false,
  schemaSaved: false,
  schemaList: [],
  schemaListLoading: false,
  schemaListLoaded: false,
};

const schemaSlice = createSlice({
  name: 'ediSchemas',
  initialState: {
    ...initialState,
  },
  reducers: {
    setSchemaName: (draft, action) => {
      draft.schema.name = action.payload;
    },
    setSchemaMapping: (draft, action) => {
      const { scopeName, lookupKey, mapping } = action.payload;
      if (scopeName === '') {
        draft.schema.mappings[lookupKey] = mapping;
      } else {
        setScopedSchemaMapping(
          draft.schema.mappings,
          scopeName,
          draft.sortedScopes,
          lookupKey,
          mapping
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateSchema.pending, (draft) => {
        draft.schemaLoading = true;
      })
      .addCase(generateSchema.rejected, (draft) => {
        draft.schemaLoading = false;
      })
      .addCase(generateSchema.fulfilled, (draft, action) => {
        const { data } = action.payload;
        const { schema, tree } = data;
        const { sortedSegments, sortedScopes } = sortSegmentsAndScopes(schema.sequence, tree);
        draft.schema = schema;
        draft.tree = tree;
        draft.sortedSegments = sortedSegments;
        draft.sortedScopes = sortedScopes;
        draft.schemaLoading = false;
        draft.schemaLoaded = true;
      })
      .addCase(createSchema.pending, (draft) => {
        draft.schemaSaving = true;
      })
      .addCase(createSchema.rejected, (draft) => {
        draft.schemaSaving = false;
      })
      .addCase(createSchema.fulfilled, (draft) => {
        draft.schemaSaving = false;
        draft.schemaSaved = true;
      })
      .addCase(listSchemas.pending, (draft) => {
        draft.schemaListLoading = true;
      })
      .addCase(listSchemas.rejected, (draft) => {
        draft.schemaListLoading = false;
      })
      .addCase(listSchemas.fulfilled, (draft, action) => {
        const { data } = action.payload;
        draft.schemaList = data;
        draft.schemaListLoading = false;
        draft.schemaListLoaded = true;
      })
      .addCase(setLiveSchema.pending, (draft) => {
        draft.setLiveSchemaSaving = true;
      })
      .addCase(setLiveSchema.rejected, (draft) => {
        draft.setLiveSchemaSaving = false;
      })
      .addCase(setLiveSchema.fulfilled, (draft) => {
        draft.setLiveSchemaSaving = false;
        draft.setLiveSchemaSaved = true;
      });
  },
});

export const { setSchemaName, setSchemaMapping } = schemaSlice.actions;

export default schemaSlice.reducer;
