import Flex from '@react-css/flex';
import Underline from '@tiptap/extension-underline';
import type { Editor } from '@tiptap/react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { merge } from 'lodash';
import type { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import { useBoolean } from 'usehooks-ts';

import elevate from 'storybook/mixins/elevate';
import typography from 'storybook/mixins/typography';
import Icon from 'storybook/stories/molecules/Icon';
import TextAreaInput from 'storybook/stories/molecules/Input/TextAreaInput';
import Toggle from 'storybook/stories/molecules/Toggle';

const MenuButton = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  all: unset;

  margin: 4px;
  font-size: 18px;
`;

const Wrapper = styled.div`
  max-width: 100%;
`;

const ContentWrapper = styled(EditorContent)`
  .ProseMirror {
    border-radius: 16px;
    min-height: 135px;

    outline: 0;
    max-width: 100%;
    height: auto;
    padding: 10px 16px;
    border: 1px solid transparent;

    ${({ theme }) => css`
      background: ${theme.color.gray100};
    `}
    // When the input is active or focused
    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.color.gray300};
    }

    // When the input is not empty
    &:not(:placeholder-shown) {
      border-color: ${({ theme }) => theme.color.blue400};
    }
  }
`;

const TopRowWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 8px;
  padding: 8px 16px 0 8px;
  margin-bottom: 16px;
  ${elevate('1')};
`;

interface FormattingControlsRowProps {
  editor: Editor | null;
  formattingControls: FormattingControlsOptions;
}

const FormattingControlsRow = ({ editor, formattingControls }: FormattingControlsRowProps) => {
  if (!editor) return null;

  return (
    <TopRowWrapper>
      <Flex justifySpaceBetween>
        {/* Text Formatting */}
        <Flex gap="6px" alignItemsStart>
          {formattingControls.bold && (
            <MenuButton
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run()}
            >
              <Icon
                name="format_bold"
                size="24px"
                color={editor.isActive('bold') ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}

          {formattingControls.italic && (
            <MenuButton
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
            >
              <Icon
                name="format_italic"
                size="24px"
                color={editor.isActive('italic') ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}

          {formattingControls.underline && (
            <MenuButton
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              disabled={!editor.can().chain().focus().toggleUnderline().run()}
            >
              <Icon
                name="format_underlined"
                size="24px"
                color={editor.isActive('underline') ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}

          {formattingControls.strike && (
            <MenuButton
              onClick={() => editor.chain().focus().toggleStrike().run()}
              disabled={!editor.can().chain().focus().toggleStrike().run()}
            >
              <Icon
                name="strikethrough_s"
                size="24px"
                color={editor.isActive('strike') ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}
        </Flex>

        {/* Lists */}
        <Flex gap="6px">
          {formattingControls.bulletList && (
            <MenuButton onClick={() => editor.chain().focus().toggleBulletList().run()}>
              <Icon
                name="format_list_bulleted"
                size="24px"
                color={editor.isActive('bulletList') ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}

          {formattingControls.orderedList && (
            <MenuButton onClick={() => editor.chain().focus().toggleOrderedList().run()}>
              <Icon
                name="format_list_numbered"
                size="24px"
                color={editor.isActive('orderedList') ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}
        </Flex>

        {/* Headers */}
        <Flex gap="6px">
          {formattingControls.heading1 && (
            <MenuButton onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}>
              <Icon
                name="format_h1"
                size="24px"
                color={editor.isActive('heading', { level: 1 }) ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}

          {formattingControls.heading2 && (
            <MenuButton onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}>
              <Icon
                name="format_h2"
                size="24px"
                color={editor.isActive('heading', { level: 2 }) ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}

          {formattingControls.heading3 && (
            <MenuButton onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}>
              <Icon
                name="format_h3"
                size="24px"
                color={editor.isActive('heading', { level: 3 }) ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}

          {formattingControls.heading4 && (
            <MenuButton onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}>
              <Icon
                name="format_h4"
                size="24px"
                color={editor.isActive('heading', { level: 4 }) ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}

          {formattingControls.heading5 && (
            <MenuButton onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}>
              <Icon
                name="format_h5"
                size="24px"
                color={editor.isActive('heading', { level: 5 }) ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}

          {formattingControls.heading6 && (
            <MenuButton onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}>
              <Icon
                name="format_h6"
                size="24px"
                color={editor.isActive('heading', { level: 6 }) ? 'blue500' : 'gray500'}
              />
            </MenuButton>
          )}
        </Flex>
      </Flex>
    </TopRowWrapper>
  );
};

const ButtonText = styled.span<{
  isDisabled: boolean;
}>`
  ${typography('Buttons/Button Small')};
  color: ${({ theme, isDisabled }) => (isDisabled ? theme.color.gray400 : theme.color.gray600)};
`;

interface EditRawHtmlToggleButtonProps {
  onClick: () => void;
  isChecked: boolean;
}

const EditRawHtmlToggleButton = ({ onClick, isChecked }: EditRawHtmlToggleButtonProps) => {
  return (
    <Toggle name="editing-raw-html" onChange={onClick} checked={isChecked}>
      <ButtonText isDisabled={false}>Edit Raw HTML?</ButtonText>
    </Toggle>
  );
};

interface BottomRowProps {
  editor: Editor | null;
  onToggleClick: () => void;
}

const BottomRow = ({ editor, onToggleClick }: BottomRowProps) => {
  if (!editor) return null;

  return (
    <Flex justifySpaceBetween alignItemsCenter>
      <EditRawHtmlToggleButton onClick={onToggleClick} isChecked={false} />

      <Flex.Item>
        <MenuButton
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <Icon
            name="undo"
            size="24px"
            color={editor.can().chain().focus().undo().run() ? 'gray600' : 'gray400'}
          >
            <ButtonText isDisabled={!editor.can().chain().focus().undo().run()}>Undo</ButtonText>
          </Icon>
        </MenuButton>
        <MenuButton
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <Icon
            name="redo"
            size="24px"
            color={editor.can().chain().focus().redo().run() ? 'gray600' : 'gray400'}
          >
            <ButtonText isDisabled={!editor.can().chain().focus().redo().run()}>Redo</ButtonText>
          </Icon>
        </MenuButton>
        <MenuButton
          onClick={() => {
            editor.chain().focus().clearNodes().unsetAllMarks().run();
          }}
        >
          <Icon name="format_clear" size="24px" color="gray600">
            <ButtonText isDisabled={false}>Clear Formatting</ButtonText>
          </Icon>
        </MenuButton>
      </Flex.Item>
    </Flex>
  );
};

const RawHtmlEditor = styled(TextAreaInput)`
  width: 100%;
  height: 100%;
`;

export interface FormattingControlsOptions {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strike?: boolean;
  bulletList?: boolean;
  orderedList?: boolean;
  heading1?: boolean;
  heading2?: boolean;
  heading3?: boolean;
  heading4?: boolean;
  heading5?: boolean;
  heading6?: boolean;
}

const DEFAULT_FORMATTING_CONTROLS = {
  bold: true,
  italic: true,
  underline: true,
  strike: true,
  bulletList: true,
  orderedList: true,
  heading1: false,
  heading2: false,
  heading3: false,
  heading4: false,
  heading5: false,
  heading6: false,
};

interface RichTextEditorProps {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  formattingControlsPosition?: 'top' | 'bottom'; // Defines where the formatting controls should be rendered
  formattingControlsOptions?: Partial<FormattingControlsOptions>; // Defines which formatting controls should be rendered, such as Bolding, Italics, etc.
  shouldShowFooter?: boolean; // Defines whether the footer should be rendered
}

const RichTextEditor = ({
  value,
  setValue,
  shouldShowFooter = true,
  formattingControlsPosition = 'top',
  formattingControlsOptions,
}: RichTextEditorProps) => {
  const formattingControls = merge({}, DEFAULT_FORMATTING_CONTROLS, formattingControlsOptions);

  const isEditingRawHtml = useBoolean(false);

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: value,
    onUpdate: (e) => {
      setValue(e.editor.getHTML() ?? '');
    },
  });

  // Handles toggling between the plain text and the rich text editors.
  // Only attempts HTML conversions if the editor has changes. This keeps TipTap
  // from "helping", and cleaning up invalid HTML.
  const handleToggleClick = () => {
    // Return early if editor doesn't have changes, and toggle between plain text and rich text editors
    if (!editor?.can().undo()) {
      isEditingRawHtml.toggle();
      return;
    }

    if (isEditingRawHtml.value) {
      editor?.commands.setContent(value);
    } else {
      setValue(editor?.getHTML() ?? '');
    }
    isEditingRawHtml.toggle();
  };

  // Plain Text Editor
  if (isEditingRawHtml.value) {
    return (
      <Wrapper>
        <RawHtmlEditor
          data-testid="raw-html-editor"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
        />
        <EditRawHtmlToggleButton onClick={handleToggleClick} isChecked />
      </Wrapper>
    );
  }

  // Rich Text Editor
  return (
    <Wrapper>
      {formattingControlsPosition === 'top' && (
        <FormattingControlsRow editor={editor} formattingControls={formattingControls} />
      )}
      <ContentWrapper data-testid="rich-text-input" editor={editor} />

      {formattingControlsPosition === 'bottom' && (
        <FormattingControlsRow editor={editor} formattingControls={formattingControls} />
      )}
      {shouldShowFooter && <BottomRow editor={editor} onToggleClick={handleToggleClick} />}
    </Wrapper>
  );
};

export default RichTextEditor;
