import { basicEventComponent, type EventComponent } from '..';
import PriceListUpdatedPayload from './PriceListUpdatedPayload';

// priceListEventComponents is a map of event names to components capable of rendering them
const priceListEventComponents: Record<string, EventComponent> = {
  PriceListCreatedPayload: basicEventComponent('add_circle', 'Price List Created'),
  PriceListUpdatedPayload,
};

export default priceListEventComponents;
