import classNames from 'classnames';
import { forwardRef } from 'react';
import type { FieldError } from 'react-hook-form';
import styled, { css } from 'styled-components';

const DescriptionText = styled.p<{ size: FormFieldProps['size'] }>`
  margin-bottom: 0.313rem;

  ${({ size }) =>
    size === 'sm' &&
    css`
      font-size: 0.688rem;
    `}
`;

export const FormFieldLabel = styled.label<{
  size: FormFieldProps['size'];
  labelSize?: FormFieldProps['labelSize'];
  description?: FormFieldProps['description'];
}>`
  ${({ size, labelSize }) =>
    (size === 'sm' || labelSize === 'sm') &&
    css`
      font-size: 0.875rem;
    `}

  ${({ description }) =>
    description &&
    css`
      margin-bottom: 0.125rem;
    `}
`;

const SubLabel = styled.p`
  white-space: pre-wrap;
`;

interface FormFieldProps {
  error?: { message: string } | FieldError;
  errorAlignRight?: boolean;
  label?: string;
  subLabel?: string;
  id?: string;
  type?: string;
  size?: string;
  className?: string;
  isTextarea?: boolean;
  description?: string;
  ungrouped?: boolean;
  isHorizontal?: boolean;
  labelSize?: string;
  // The rest of the props should be React.HTMLProps<HTMLInputElement>
  // But there's a conflict with the `size` prop
  [key: string]: any;
}

const FormField = forwardRef<HTMLInputElement | HTMLTextAreaElement, FormFieldProps>(
  (props, ref) => {
    const {
      error,
      errorAlignRight,
      label,
      subLabel,
      id,
      type,
      size,
      className,
      isTextarea,
      description,
      ungrouped,
      isHorizontal,
      labelSize,
      ...inputProps
    } = props;
    const cn = classNames('form-group', className);
    const inputCN = classNames('form-control', {
      [`form-control-${size}`]: size,
      'is-invalid': error,
    });

    const StyledField = (
      <>
        {label && id && (
          <FormFieldLabel
            size={size}
            labelSize={labelSize}
            description={description}
            className={isHorizontal ? 'd-block mb-3 col-sm-4 pt-2 px-0' : ''}
            htmlFor={id}
          >
            {label}
          </FormFieldLabel>
        )}
        <div className={`form-input-wrapper ${isHorizontal ? 'mb-3 col-sm-8 px-0' : ''}`}>
          {description && (
            <DescriptionText size={size} className="text-muted">
              {description}
            </DescriptionText>
          )}
          {isTextarea ? (
            <textarea
              id={id}
              className={inputCN}
              ref={ref as React.LegacyRef<HTMLTextAreaElement>}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
            />
          ) : (
            <input
              id={id}
              type={type}
              className={inputCN}
              ref={ref as React.LegacyRef<HTMLInputElement>}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
            />
          )}
          {error && (
            <div className={`invalid-feedback mt-2 ${errorAlignRight ? 'text-right' : ''}`}>
              {error.message}
            </div>
          )}
          {subLabel && !error && (
            <SubLabel className={`text-muted small mt-2 ${ungrouped ? 'mb-0' : ''}`}>
              {subLabel}
            </SubLabel>
          )}
        </div>
      </>
    );

    return ungrouped ? (
      StyledField
    ) : (
      <div role="group" className={cn}>
        {StyledField}
      </div>
    );
  }
);

export default FormField;
