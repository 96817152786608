import Flex from '@react-css/flex';
import styled, { css } from 'styled-components';

import elevate from 'storybook/mixins/elevate';
import Card, { type CardProps } from 'storybook/stories/cells/Card';
import { isEnterKey, isSpacebarKey } from 'utils/keyboard';

interface StyledCardProps extends CardProps {
  $hasWarning: boolean;
}

const StyledCard = styled(Card).attrs(({ onClick }) => ({
  role: onClick && 'button',
  tabIndex: onClick && 0,
  onKeyDown:
    onClick &&
    ((event: React.KeyboardEvent<HTMLDivElement>) => {
      if (isSpacebarKey(event) || isEnterKey(event)) {
        event.preventDefault();
        onClick(event as unknown as React.MouseEvent<HTMLDivElement>);
      }
    }),
}))<StyledCardProps>`
  padding: 0;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ $hasWarning }) =>
    $hasWarning &&
    css`
      position: relative;
      background-color: rgba(255, 249, 204, 0.5); // warning100 @ 50%
      border: 1px solid ${({ theme }) => theme.color.warning500};

      &::before {
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        ${elevate('2')};
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-20px, -50%);
        background: ${({ theme }) => theme.color.white};
        cursor: initial;
      }

      &::after {
        content: 'exclamation';
        font-family: ${({ theme }) => theme.font.icon};
        font-size: 20px;
        color: ${({ theme }) => theme.color.warning700};
        text-align: center;
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-16px, -50%);
        background: ${({ theme }) => theme.color.warning500};
        cursor: initial;
      }
    `}
`;

const CheckboxWrapper = styled.div.attrs(({ onClick }) => ({
  onClick: (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (onClick) onClick(event);
  },
}))`
  display: grid;
  place-content: center;

  ${({ theme }) => css`
    background-color: ${theme.color.gray100};
    padding: 16px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  `};
`;

const BodyWrapper = styled.div`
  padding: 24px;
`;

export interface SearchResultProps {
  /**
   * The contents of the Search Result body
   */
  children: React.ReactNode;
  /**
   * A Checkbox UI used for selecting the row
   */
  checkboxUi?: React.ReactNode;
  /**
   * Any UI to display in a row below the main Search Result
   */
  footerUi?: React.ReactNode;
  /**
   * On click handler
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /**
   * Render the search result in a warning state
   */
  hasWarning?: boolean;
}

/**
 * Provides layout for a Search Result. Designed to be implemented for a specific
 * resource, e.g. ProductSearchResult, OrderSearchResult, etc
 */
const SearchResult = ({
  checkboxUi,
  footerUi,
  children,
  onClick,
  hasWarning,
}: SearchResultProps) => (
  <StyledCard onClick={onClick} $hasWarning={hasWarning}>
    <Flex column>
      <Flex>
        {checkboxUi && <CheckboxWrapper>{checkboxUi}</CheckboxWrapper>}

        <Flex.Item grow={1}>
          <BodyWrapper data-analytics-id="search-result">{children}</BodyWrapper>
        </Flex.Item>
      </Flex>

      {footerUi}
    </Flex>
  </StyledCard>
);

export default SearchResult;
