import type { Response } from 'types/api';
import type { Conversation, ConversationMessageFeedback } from 'types/models/conversation';
import { postRequest, putRequest } from 'utils/request';

type StartConversationResponse = Response<Conversation>;

export const startConversation = async (): Promise<StartConversationResponse> =>
  postRequest('/conversations');

type StartAnonymousConversationResponse = Response<Conversation>;

export const startAnonymousConversation = async (
  buyerCompanyObjectId: string
): Promise<StartAnonymousConversationResponse> =>
  postRequest(`/conversations/buyer/${buyerCompanyObjectId}`);

export interface SendConversationFeedbackParams {
  feedback: ConversationMessageFeedback;
}

type SendConversationFeedbackResponse = Response<{
  feedback: ConversationMessageFeedback;
}>;

export const sendConversationFeedback = async (
  conversationId: string,
  messageId: string,
  params: SendConversationFeedbackParams
): Promise<SendConversationFeedbackResponse> =>
  putRequest(`/conversations/${conversationId}/messages/${messageId}/feedback`, params);
