import Flex from '@react-css/flex';

import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';

interface SaveAndCancelHeaderButtonProps {
  onCancel: () => void;
  onSave: () => void;
}

const SaveAndCancelHeaderButton = ({ onCancel, onSave }: SaveAndCancelHeaderButtonProps) => {
  return (
    <Flex alignItemsCenter>
      <TertiaryButton size="small" onClick={onCancel}>
        Cancel
      </TertiaryButton>
      <PrimaryButton size="small" onClick={onSave}>
        Save All
      </PrimaryButton>
    </Flex>
  );
};

export default SaveAndCancelHeaderButton;
