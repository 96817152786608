import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { FloatingChatContext } from 'containers/App/Contexts/FloatingChatContext';
import useAlertQueue from 'hooks/useAlertQueue';
import useChat from 'hooks/useChat';
import useScrollTo from 'hooks/useScrollTo';
import { useContext, useEffect } from 'react';
import Chat from 'storybook/stories/species/Chat';
import type { ChatFormValues } from 'storybook/stories/species/Chat/ChatForm';
import FloatingChat from 'storybook/stories/species/Chat/FloatingChat';
import type { ConversationMessageFeedback } from 'types/models/conversation';
import {
  sendConversationFeedback,
  startConversation,
  type SendConversationFeedbackParams,
} from 'utils/api/conversations';

interface ChatBodyContentProps {
  onInterruptButtonClick: () => void;
  onFeedbackFormSubmit: (messageId: string, feedback: ConversationMessageFeedback) => void;
  messages: ReturnType<typeof useChat>['messages'];
  isAwaitingWebsocketResponse: ReturnType<typeof useChat>['isAwaitingWebsocketResponse'];
}

const ChatBodyContent = ({
  onInterruptButtonClick,
  onFeedbackFormSubmit,
  messages,
  isAwaitingWebsocketResponse,
}: ChatBodyContentProps) => {
  return (
    <Chat.Messages
      messages={messages}
      isAwaitingWebsocketResponse={isAwaitingWebsocketResponse}
      onInterruptButtonClick={onInterruptButtonClick}
      onFeedbackFormSubmit={onFeedbackFormSubmit}
    />
  );
};

const FloatingChatUI = () => {
  const alertQueue = useAlertQueue();
  const [bottomOfMessagesRef, scrollToBottomOfMessages] = useScrollTo<HTMLDivElement>();

  const { isPromptVisible, hidePrompt, isWindowOpen, closeWindow, openWindow } =
    useContext(FloatingChatContext);

  const {
    messages,
    isAwaitingWebsocketResponse,
    sendHumanMessage,
    sendInterruptMessage,
    resetChat,
    setIsAwaitingWebsocketResponse,
    conversation,
    updateConversationData,
  } = useChat({ isPersisted: true });

  /**
   * Helpers
   */

  const isFormDisabled = isAwaitingWebsocketResponse;

  /**
   * Mutations
   */

  const startingConversation = useMutation({
    mutationFn: () => startConversation(),
    onSuccess: (response) => {
      updateConversationData({ conversation: response.data });
      setIsAwaitingWebsocketResponse(true);
    },
    onError: (error) => {
      alertQueue.addErrorAlert('Failed to start a conversation', 'Please try again');
      console.error('Unable to start conversation', error);
      setIsAwaitingWebsocketResponse(false);

      Sentry.withScope((scope) => {
        scope.setExtra('error', error);
        Sentry.captureMessage('Unable to start conversation', 'error');
      });
    },
  });

  interface SendingConversationFeedbackMutationData {
    conversationId: string;
    messageId: string;
    params: SendConversationFeedbackParams;
  }

  const sendingConversationFeedback = useMutation({
    mutationKey: ['sendConversationFeedback'],
    mutationFn: ({ conversationId, messageId, params }: SendingConversationFeedbackMutationData) =>
      sendConversationFeedback(conversationId, messageId, params),
    onSuccess: () => {
      alertQueue.addSuccessAlert('Success!', 'Your feedback has been submitted.');
    },
    onError: (error) => {
      alertQueue.addErrorAlert('Error!', 'Something went wrong. Please try again.');

      Sentry.withScope((scope) => {
        scope.setExtra('error', error);
        Sentry.captureMessage('Unable to send chat feedback', 'error');
      });
    },
  });

  /**
   * Event Handlers
   */

  const onFloatingChatButtonClick = () => {
    if (isWindowOpen) {
      resetChat();
      closeWindow();
      return;
    }

    openWindow();
    startingConversation.mutate();
  };

  const onFormSubmit = (data: ChatFormValues) => {
    sendHumanMessage(data.question);
  };

  const onInterruptButtonClick = () => {
    sendInterruptMessage();
  };

  const onFeedbackFormSubmit = (messageId: string, feedback: ConversationMessageFeedback) => {
    sendingConversationFeedback.mutate({
      messageId,
      conversationId: conversation?.id || '',
      params: { feedback },
    });
  };

  /**
   * Side Effects
   */

  // Each time a new message is added, scroll to the bottom of the chat window
  useEffect(() => {
    scrollToBottomOfMessages();
  }, [messages, scrollToBottomOfMessages]);

  /**
   * Render
   */

  return (
    <FloatingChat>
      <FloatingChat.Button onClick={onFloatingChatButtonClick} />
      {isPromptVisible && <FloatingChat.Prompt onDismissClick={hidePrompt} />}
      <FloatingChat.Window>
        <Chat data-testid="FloatingChatUI">
          <Chat.Header actionsUi={<FloatingChat.HeaderActions />} />
          <Chat.Body>
            {startingConversation.isLoading ? (
              <Chat.Loading />
            ) : (
              <ChatBodyContent
                messages={messages}
                isAwaitingWebsocketResponse={isAwaitingWebsocketResponse}
                onInterruptButtonClick={onInterruptButtonClick}
                onFeedbackFormSubmit={onFeedbackFormSubmit}
              />
            )}

            <div ref={bottomOfMessagesRef} />
          </Chat.Body>
          <Chat.Form onSubmit={onFormSubmit} isDisabled={isFormDisabled} />
        </Chat>
      </FloatingChat.Window>
    </FloatingChat>
  );
};

export default FloatingChatUI;
