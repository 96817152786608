export enum BlueColors {
  blue900 = '#071C33',
  blue800 = '#0E3766',
  blue700 = '#145399',
  blue600 = '#1B6ECC',
  blue500 = '#228AFF',
  blue400 = '#4EA1FF',
  blue300 = '#7AB9FF',
  blue200 = '#A7D0FF',
  blue100 = '#D3E8FF',
  blue050 = '#F0F7FF',
}

export enum GreenColors {
  green900 = '#102D27',
  green800 = '#205B4E',
  green700 = '#2E8471',
  green600 = '#40B69B',
  green500 = '#50E3C2',
  green400 = '#73E9CE',
  green300 = '#96EEDA',
  green200 = '#B9F4E7',
  green100 = '#DCF9F3',
  green050 = '#EDFCF9',
}

export enum NeutralColors {
  gray900 = '#1A262D',
  gray700 = '#2A4252',
  gray600 = '#3E596C',
  gray500 = '#5D7A8E',
  gray400 = '#8FA7B8',
  gray300 = '#C1D0DB',
  gray200 = '#E3EBF2',
  gray100 = '#F5F8FA',
  white = '#ffffff',
}

export enum WarningColors {
  warning700 = '#8C6B00',
  warning500 = '#FFD900',
  warning100 = '#FFF9CC',
}

export enum ErrorColors {
  error700 = '#8E1810',
  error500 = '#D52315',
  error100 = '#FFEEE6',
}

export const SuccessColors = {
  success700: GreenColors.green700,
  success500: GreenColors.green600,
  success100: GreenColors.green050,
};

export const InfoColors = {
  info700: BlueColors.blue600,
  info500: BlueColors.blue500,
  info100: BlueColors.blue050,
};

export const TokenColors = {
  bodyBackground: NeutralColors.gray100,
  bodyTextPrimary: NeutralColors.gray900,
  bodyTextSecondary: NeutralColors.gray500,
  bodyTextDisabled: NeutralColors.gray300,
  bodyTextLinks: BlueColors.blue600,
  bodyTextLinksHover: BlueColors.blue300,
  iconDefault: NeutralColors.gray400,
};

export const ThemeColors = {
  ...BlueColors,
  ...GreenColors,
  ...NeutralColors,
  ...WarningColors,
  ...ErrorColors,
  ...SuccessColors,
  ...InfoColors,
  ...TokenColors,
};

export type ThemeColor = keyof typeof ThemeColors;

enum ThemeFonts {
  primary = "'Inter', sans-serif",
  secondary = "'Montserrat', sans-serif",
  monospace = "'Source Code Pro', monospace",
  icon = 'Material Symbols Rounded Variable',
}

export enum ThemeBreakpoints {
  xs = 'max-width: 575.98px',
  sm = 'min-width: 576px',
  md = 'min-width: 768px',
  lg = 'min-width: 992px',
  xl = 'min-width: 1200px',
  xxl = 'min-width: 1400px',
}

const theme = {
  color: ThemeColors,
  font: ThemeFonts,
  breakpoints: ThemeBreakpoints,
};

export default theme;
