/* eslint-disable jsx-a11y/label-has-associated-control */
import { flow, identity, lowerCase, map, sortBy, startCase } from 'lodash/fp';
import { useMemo, useState } from 'react';
import Select from 'react-select';

import Button from 'components/Common/Button';
import useAlertQueue from 'hooks/useAlertQueue';
import { CARRIERS } from 'utils/shipping';

export const shippingLevelOptions = [
  { value: 0, label: 'Overnight' },
  { value: 1, label: 'Next Day Delivery' },
  { value: 2, label: 'Deliver in 2 days' },
  { value: 3, label: 'Deliver in 3 days' },
  { value: 4, label: 'Deliver in 4 days' },
  { value: 5, label: 'Deliver in 5 days' },
  { value: 7, label: 'Deliver in 7 days' },
  { value: 10, label: 'Deliver in 10 days' },
  { value: 14, label: 'Deliver in 14 days' },
  { value: -1, label: 'Specify number of days' },
];

export const carrierOptions = flow(
  sortBy(identity),
  map((carrier) => ({ value: carrier, label: flow(lowerCase, startCase)(carrier) }))
)(CARRIERS);

const ShippingMethodForm = ({ onCancel, onSave, onSaveSuccess, isSeller, shippingMethod }) => {
  const alertQueue = useAlertQueue();

  // calculate the default form state based on whether or not it is edit/create
  const defaultValues = useMemo(() => {
    let description = '';
    let selectedLevelOption = shippingLevelOptions[0];
    let maxDays = 0;
    let numDaysValue = '';
    let selectedCarrierOptions = [];

    if (shippingMethod) {
      // override defaults with the current values (editing an entry)
      // default to the value from maxDays, or -1 if it is custom/not in options list
      selectedLevelOption = shippingLevelOptions.find(
        (option) => option.value === shippingMethod.maxDays || option.value === -1
      );
      maxDays = shippingMethod.maxDays;
      description = shippingMethod.description;

      if (selectedLevelOption.value === -1) {
        numDaysValue = `${maxDays}`;
      }

      const carrierPresenceMap =
        shippingMethod.carrierNames?.reduce((accumulator, carrier) => {
          accumulator[carrier] = true;
          return accumulator;
        }, {}) || {};

      selectedCarrierOptions = carrierOptions.filter((option) => carrierPresenceMap[option.value]);
    }

    return {
      selectedLevelOption,
      maxDays,
      description,
      selectedCarrierOptions,
      numDaysValue,
    };
  }, [shippingMethod]);

  const [description, setDescription] = useState(defaultValues.description);
  const [selectedLevelOption, setSelectedLevelOption] = useState(defaultValues.selectedLevelOption);
  const [maxDays, setMaxDays] = useState(defaultValues.maxDays);
  const [selectedCarrierOptions, setSelectedCarrierOptions] = useState(
    defaultValues.selectedCarrierOptions
  );
  const [numDaysValue, setNumDaysValue] = useState(defaultValues.numDaysValue);
  const [isSaving, setIsSaving] = useState(false);
  const [validationError, setValidationError] = useState('');

  const onChangeLevel = (option) => {
    setSelectedLevelOption(option);
    setMaxDays(option.value);
    if (option.value === -1) {
      setNumDaysValue('');
    }
  };

  const onChangeCarriers = (options) => {
    setSelectedCarrierOptions(options);
  };

  const onChangeDescription = ({ target: { value } }) => {
    setDescription(value);
  };

  const onChangeNumDays = ({ target: { value } }) => {
    if (/^\d*$/.test(value)) {
      setNumDaysValue(value);
      const valueInt = parseInt(value, 10);
      // eslint-disable-next-line no-restricted-globals
      setMaxDays(isNaN(valueInt) ? -1 : valueInt);
    }
  };

  const onSubmit = (evt) => {
    setValidationError('');
    evt.preventDefault();
    if (isSaving) return;

    // eslint-disable-next-line no-restricted-globals
    if (maxDays < 0 || isNaN(maxDays)) {
      setValidationError('Please enter max days until delivery');
      return;
    }

    if (description.length > 500) {
      setValidationError('Description can only be 500 characters or less');
      return;
    }

    const data = {
      description,
      maxDays,
      carrierNames: selectedCarrierOptions.map((option) => option.value),
    };

    setIsSaving(true);
    onSave(data)
      .then((response) => {
        setIsSaving(false);
        onSaveSuccess(response);
      })
      .catch((err) => {
        setIsSaving(false);
        alertQueue.addErrorAlert('Something went wrong', err.message);
      });
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="mb-2" style={{ fontSize: '0.875rem' }}>
          Shipping Level
        </label>
        <Select
          options={shippingLevelOptions}
          aria-label="Select Shipping Level"
          value={selectedLevelOption}
          onChange={onChangeLevel}
          menuPlacement="auto"
        />

        {selectedLevelOption.value === -1 && (
          <div className="d-flex align-items-center mt-2">
            <p className="mb-0 small mr-2">Deliver within</p>
            <input
              type="number"
              onChange={onChangeNumDays}
              value={numDaysValue}
              className="form-control form-control-sm"
              style={{ width: '4rem' }}
            />
            <p className="mb-0 small ml-2">days</p>
          </div>
        )}
        <p className="mb-0 mt-2 small text-muted">
          Shipping Levels are measured in business days. Holidays are included.
        </p>
      </div>
      <div className="form-group">
        <label className="mb-2" style={{ fontSize: '0.875rem' }}>
          Description
        </label>
        <textarea
          placeholder="Optional"
          rows={1}
          className="form-control"
          value={description}
          onChange={onChangeDescription}
        />
      </div>
      <label className="mb-2" style={{ fontSize: '0.875rem' }}>
        {isSeller ? 'Select Carriers' : 'Select Preferred Carriers'}
      </label>
      <Select
        options={carrierOptions}
        aria-labelledby="Select Carriers"
        onChange={onChangeCarriers}
        menuPlacement="auto"
        defaultValue={selectedCarrierOptions}
        isMulti
      />
      <p className="mb-3 mt-2 small text-muted">
        Don&apos;t see your carrier? Please contact support@convictional.com
      </p>
      <div className="d-flex justify-content-end">
        <Button size="sm" color="white" type="button" onClick={onCancel} className="mr-2">
          Cancel
        </Button>

        <Button size="sm" color="primary" disabled={isSaving} type="submit">
          Save
        </Button>
      </div>
      {validationError && <p className="text-danger text-right mt-2">{validationError}</p>}
    </form>
  );
};

export default ShippingMethodForm;
