import { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from 'components/Common/Button';
import FormField from 'components/Common/FormField';
import Spinner from 'components/Common/Spinner';
import useAlertQueue from 'hooks/useAlertQueue';
import { updateBigCommerceAuth } from 'store/slices/company';
import { getBigCommerceHash, getPlatformUrl } from 'utils/platform';

const ConnectBigCommerce = ({ dispatch, company }) => {
  const alertQueue = useAlertQueue();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();
  const pending = useSelector(({ company: c }) => c.isUpdatingPlatformAuth);
  const connected = company.productPlatformActive;
  const [showForm, setShowForm] = useState(!connected);
  const storeUrl = getPlatformUrl(company, 'bigcommerce');

  useEffect(() => {
    setValue('bigCommerceHash', getBigCommerceHash(storeUrl));
  }, [setValue, storeUrl, showForm]);

  const onSubmit = ({ bigCommerceClientId, bigCommerceAccessToken, bigCommerceHash }) => {
    dispatch(
      updateBigCommerceAuth({
        bigCommerceAuth: {
          platform: 'bigcommerce',
          platformUrl: storeUrl,
          bigCommerceClientId,
          bigCommerceAccessToken,
          bigCommerceHash,
        },
      })
    ).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
        return;
      }
      setShowForm(false);
      alertQueue.addSuccessAlert('Success', 'BigCommerce credentials updated');
    });
  };

  return (
    <>
      <hr className="my-4" />
      <h4>
        Connect your BigCommerce Store
        {connected && (
          <Badge variant="success" pill className="ml-3 mt-n1">
            Connected
          </Badge>
        )}
      </h4>
      {!connected && (
        <p className="small text-muted mb-1">
          Please input your BigCommerce credentials so Modern Dropship can connect to your store.{' '}
        </p>
      )}
      <p className="small text-muted">
        Store: <strong>{storeUrl}</strong>
      </p>
      {showForm ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField
            type="text"
            size="sm"
            id="settings-big-hash-input"
            label="Store Hash"
            placeholder="BigCommerce Store Hash"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('bigCommerceHash', {
              required: 'BigCommerce Store Hash is required',
            })}
            error={errors.bigCommerceHash}
          />
          <FormField
            type="text"
            size="sm"
            id="settings-big-client-id-input"
            label="Client ID"
            placeholder="BigCommerce API Client ID"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('bigCommerceClientId', {
              required: 'BigCommerce Client ID is required',
            })}
            error={errors.bigCommerceClientId}
          />
          <FormField
            type="text"
            size="sm"
            id="settings-big-access-token-input"
            label="Access Token"
            placeholder="BigCommerce Access Token"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('bigCommerceAccessToken', {
              required: 'BigCommerce Access Token is required',
            })}
            error={errors.bigCommerceAccessToken}
          />

          <div className="d-flex">
            <Button size="sm" type="submit" color="primary" outline>
              {pending ? <Spinner color="primary" small className="mx-3" /> : 'Save Credentials'}
            </Button>
            {connected && showForm && (
              <Button
                size="sm"
                color="white"
                type="button"
                className="ml-3"
                onClick={() => setShowForm(false)}
              >
                Cancel
              </Button>
            )}
          </div>
        </form>
      ) : (
        <Button size="sm" type="submit" color="white" onClick={() => setShowForm(true)}>
          Change Credentials
        </Button>
      )}
    </>
  );
};

export default ConnectBigCommerce;
