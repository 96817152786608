import { useQuery } from '@tanstack/react-query';
import DashboardPage from 'components/Dashboard/DashboardPage';
import EmptyPriceListsPage from 'containers/PriceListsPage/EmptyPriceListsPage';
import useAlertQueue from 'hooks/useAlertQueue';
import qs from 'qs';
import { Redirect, useHistory, useLocation } from 'react-router';
import { useAppSelector } from 'store';
import { selectIsSeller } from 'store/selectors/me/company';
import Page from 'storybook/stories/cells/Page';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import Spinner from 'storybook/stories/molecules/Spinner';
import PriceListSearchResults from 'storybook/stories/organisms/SearchResults/PriceListSearchResults';
import type { BaseSearchParams } from 'types/general';
import { useDocumentTitle } from 'usehooks-ts';
import { getPriceLists } from 'utils/api/priceLists';
import { getProducts } from 'utils/api/products';
import getUpdatedUrl from 'utils/searchResults';

interface PriceListPageProps {
  hasProducts: boolean;
  hasPriceLists?: boolean;
  children: React.ReactNode;
}

const PageWrapper = ({ hasProducts, hasPriceLists, children }: PriceListPageProps) => (
  <DashboardPage>
    <Page>
      <Page.Head title="Price Lists">
        {hasPriceLists && (
          <>
            <SecondaryButton size="small" $iconName="help" as={SupportLink} article="ia2nwgi8be">
              Guide
            </SecondaryButton>

            {hasProducts && (
              <PrimaryButton size="small" $iconName="attach_money" as={Link} to="/prices/create">
                New Price List
              </PrimaryButton>
            )}
          </>
        )}
      </Page.Head>
      <Page.Body>{children}</Page.Body>
    </Page>
  </DashboardPage>
);

const PriceLists = () => {
  useDocumentTitle('Price Lists');

  const history = useHistory();
  const { search, pathname } = useLocation();
  const alertQueue = useAlertQueue();
  const isSeller = useAppSelector(selectIsSeller);

  /**
   * Queries
   */

  const searchParams = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as BaseSearchParams;

  const currentPage = parseInt(searchParams.page || '1', 10);

  const fetchingProducts = useQuery({
    queryKey: ['getProducts', { limit: 1 }],
    queryFn: ({ queryKey }) => {
      const params = queryKey[1] as { limit: number };
      return getProducts(params);
    },
  });

  const hasProducts = (fetchingProducts.data?.data || []).length > 0;

  const fetchingPriceLists = useQuery({
    queryKey: ['getPriceLists', currentPage, searchParams],
    queryFn: () => getPriceLists({ page: currentPage - 1 }),
    onError: () => {
      alertQueue.addErrorAlert(
        'Something went wrong',
        'We were unable to load your price lists. Please refresh.'
      );
    },
  });

  const { hasMore = false, data: priceLists = [] } = fetchingPriceLists.data || {};
  const isLoading = fetchingPriceLists.isLoading || fetchingProducts.isLoading;

  /**
   * Event Handlers
   */

  const onPreviousClick = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: `${currentPage - 1}` }));
  };

  const onNextClick = () => {
    history.push(getUpdatedUrl(pathname, searchParams, { page: `${currentPage + 1}` }));
  };

  /**
   * Render
   */

  // If user is not a seller, redirect buyer to Orders
  if (!isSeller) return <Redirect to="/" />;

  if (isLoading) {
    return (
      <PageWrapper hasProducts={hasProducts}>
        <Spinner />
      </PageWrapper>
    );
  }

  if (priceLists.length === 0) {
    return (
      <PageWrapper hasProducts={hasProducts}>
        <EmptyPriceListsPage hasProducts={hasProducts} />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper hasProducts={hasProducts} hasPriceLists={priceLists.length > 0}>
      <PriceListSearchResults
        priceLists={priceLists}
        currentPage={currentPage}
        hasMore={hasMore}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
        onSearchResultClick={(priceList) => history.push(`/prices/${priceList.id}`)}
      />
    </PageWrapper>
  );
};

export default PriceLists;
