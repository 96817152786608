import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export type AlertData = {
  type: 'success' | 'error';
  title: string;
  body: string;
};

export type AlertQueueData = {
  id: string;
  data: AlertData;
};

export type AlertState = {
  queue: AlertQueueData[];
};

export const initialState: AlertState = {
  queue: [],
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    resetAlertQueue: (draft) => {
      draft.queue = initialState.queue;
    },

    addAlert: (draft, action: PayloadAction<AlertData>) => {
      draft.queue.push({ id: uuidv4(), data: action.payload });
    },

    removeAlert: (draft, action: PayloadAction<string>) => {
      draft.queue = draft.queue.filter((alert) => alert.id !== action.payload);
    },
  },
});

export const { addAlert, removeAlert, resetAlertQueue } = alertsSlice.actions;

export default alertsSlice.reducer;
