const CreateActionTextbox = ({ id, label, value, placeholder, setValue }) => (
  <div className="form-group">
    <label htmlFor={`create-card-${id}`}>{label}</label>
    <input
      type="text"
      className="form-control"
      placeholder={placeholder}
      id={`create-card-${id}`}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      autoComplete="off"
    />
  </div>
);

export default CreateActionTextbox;
