import type { Event } from 'types/models/history';
import Timeline, { type TimelineProps } from '..';

// Useful types for event rendering components

export type EventComponentProps = {
  event: Event;
};

export type EventComponent = ({ event }: EventComponentProps) => JSX.Element | null;

export const DefaultEventComponent = ({ event }: EventComponentProps) => (
  <Timeline.Event iconName="edit" timestamp={event.createdAt} title="Event" />
);

export const basicEventComponent = (iconName: string, title: string) => {
  const BasicEventComponent = ({ event }: EventComponentProps) => (
    <Timeline.Event iconName={iconName} timestamp={event.createdAt} title={title} />
  );
  return BasicEventComponent;
};

type HistoryTimelineProps = TimelineProps & {
  events: Event[];
  eventComponents: Record<string, EventComponent>;
  defaultEventComponent?: EventComponent;
};

// HistoryTimeline is a wrapper around Timeline that takes in a list of history events
// and renders them using the provided event components.
export const HistoryTimeline = ({
  events,
  hasMore,
  eventComponents,
  defaultEventComponent = DefaultEventComponent,
  bgColor,
}: HistoryTimelineProps) => (
  <Timeline hasMore={hasMore} bgColor={bgColor}>
    {events.map((event) => {
      let EventComponent = eventComponents[event.name];
      if (!EventComponent) {
        EventComponent = defaultEventComponent;
      }
      return <EventComponent key={event.eventId} event={event} />;
    })}
  </Timeline>
);
