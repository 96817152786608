import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import type { CompanyState } from 'types/redux';
import {
  deleteLocationFromCompany,
  updateCompany,
  type CreateBillingPortalResponse,
  type UpdateCompanyParams,
  type UpdateCompanyResponse,
} from 'utils/api/companies';
import { uploadImage } from 'utils/api/documents';

const initialState: CompanyState = {
  isUpdatingCompany: false,
  isUploadingLogo: false,
};

export const updateUserCompany: any = createAsyncThunk<
  Promise<UpdateCompanyResponse> | null,
  UpdateCompanyParams,
  {
    state: RootState;
  }
>('settings/company/update', (updates, thunkAPI) => {
  const { me } = thunkAPI.getState();
  const { company } = me;
  if (!company) return company;
  return updateCompany(company._id, updates);
});

export const deleteLocationFromUserCompany = createAsyncThunk<
  Promise<CreateBillingPortalResponse> | null,
  string,
  {
    state: RootState;
  }
  // @ts-ignore
>('settings/company/deleteLocation', (locationId, thunkAPI) => {
  const {
    me: { company },
  } = thunkAPI.getState();
  if (!company) {
    return company;
  }
  return deleteLocationFromCompany(company._id, locationId);
});

export const uploadCompanyLogo = createAsyncThunk<
  any,
  { bytes: number[]; fileType: string; fileName: string },
  {
    state: RootState;
  }
>('settings/company/uploadLogo', async ({ bytes, fileType, fileName }, thunkAPI) => {
  const { me } = thunkAPI.getState();
  const { company } = me;
  if (!company) {
    throw new Error('Your login session has expired');
  }
  try {
    const body = await uploadImage({ bytes, fileName, fileType, sharedWithCompany: true });
    const logoId = body.data.documentId;
    return await updateCompany(company._id, { logoId });
  } catch (error) {
    console.log('Error uploading logo', error);
    throw new Error('Error uploading logo - check file type and try again.');
  }
});

const slice = createSlice({
  name: 'settings/company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUserCompany.pending, (draft) => {
        draft.isUpdatingCompany = true;
      })
      .addCase(deleteLocationFromUserCompany.pending, (draft) => {
        draft.isUpdatingCompany = true;
      })
      .addMatcher(isAnyOf(updateUserCompany.fulfilled, updateUserCompany.rejected), (draft) => {
        draft.isUpdatingCompany = false;
      })
      .addMatcher(
        isAnyOf(deleteLocationFromUserCompany.fulfilled, deleteLocationFromUserCompany.rejected),
        (draft) => {
          draft.isUpdatingCompany = false;
        }
      );
  },
});

export default slice.reducer;
