import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

import { useCanEditStoreName } from 'hooks/useCanEditStoreName';
import Tooltip from 'storybook/stories/cells/Tooltip';

interface EditStoreNameButtonProps {
  onClick: () => void;
}

const EditStoreNameButton = ({ onClick }: EditStoreNameButtonProps) => {
  const [isStoreNameEditable, storeNameNotEditableReason] = useCanEditStoreName();

  if (storeNameNotEditableReason) {
    return (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <SecondaryButton size="small" onClick={onClick} disabled={!isStoreNameEditable}>
            Edit Store Name
          </SecondaryButton>
        </Tooltip.Trigger>
        <Tooltip.Content>{storeNameNotEditableReason}</Tooltip.Content>
      </Tooltip>
    );
  }

  return (
    <SecondaryButton size="small" onClick={onClick} disabled={!isStoreNameEditable}>
      Edit Store Name
    </SecondaryButton>
  );
};

export default EditStoreNameButton;
