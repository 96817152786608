import Flex from '@react-css/flex';
import Modal from 'storybook/stories/cells/Modal';

import type { EditablePriceListEntry } from 'containers/PriceListPage/BulkEditing/context';

import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';

interface DeletePriceListEntriesConfirmationModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  shouldShow: boolean;
  priceListEntry: EditablePriceListEntry;
}

const DeletePriceListEntryConfirmationModal = ({
  onCancel,
  onConfirm,
  shouldShow,
  priceListEntry,
}: DeletePriceListEntriesConfirmationModalProps) => {
  const friendlyProductName = `${priceListEntry.entry.productTitle} - ${priceListEntry.entry.variant.title}`;
  return (
    <Modal.Root
      show={shouldShow}
      size="lg"
      aria-labelledby="remove-from-price-list-modal"
      centered
      onHide={onCancel}
      animation={false}
      id="remove-from-price-list-modal"
    >
      <Modal.Header closeButton>
        <Heading variant="Headings/H2" color="bodyTextSecondary">
          Remove from Price List
        </Heading>
      </Modal.Header>

      <Modal.Body>
        <Flex column gap="16px">
          <Body variant="Body/Regular">
            Are you sure you want to remove &quot;{friendlyProductName}&quot; from this price list?
          </Body>

          <Body variant="Body/Regular">
            By removing, your product will no longer be priced on this list and your partners will
            not be able to sell it on their store. Your product will still be available in Modern
            Dropship, and can be re-added to this price list in the future.
          </Body>
        </Flex>
      </Modal.Body>

      <Modal.Footer>
        <TertiaryButton size="small" onClick={onCancel}>
          Cancel
        </TertiaryButton>
        <PrimaryButton
          size="small"
          kind="destructive"
          data-testid="confirm-remove-button"
          onClick={onConfirm}
        >
          Remove
        </PrimaryButton>
      </Modal.Footer>
    </Modal.Root>
  );
};

export default DeletePriceListEntryConfirmationModal;
