import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import ConfirmationModal from 'components/Common/ConfirmationModal';
import FormField from 'components/Common/FormField';
import Spinner from 'components/Common/Spinner';
import useAlertQueue from 'hooks/useAlertQueue';
import type { AppDispatch } from 'store';
import { fetchGettingStarted } from 'store/slices/getStarted';
import { getCurrentUserAndCompany } from 'store/slices/me';
import Body from 'storybook/stories/molecules/Body';
import { ButtonKinds } from 'storybook/stories/molecules/Button';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import type { Company } from 'types/models/company';
import { installEasyPost, uninstallEasyPost, type InstallEasyPostParams } from 'utils/api/easypost';

interface EasyPostSettingsFormProps {
  connected: boolean;
  onSuccess: () => void;
}

type FormValues = {
  apiKey: string;
};

const EasyPostSettingsForm = ({ connected, onSuccess }: EasyPostSettingsFormProps) => {
  const alertQueue = useAlertQueue();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>();
  const [showUninstallModal, setShowUninstallModal] = useState(false);

  const install = useMutation({
    mutationFn: (params: InstallEasyPostParams) => installEasyPost(params),
    onSuccess: () => {
      alertQueue.addSuccessAlert('Success', 'EasyPost API Key updated successfully');
      onSuccess();
    },
    onError: (error: any) => {
      console.error('Unable to install EasyPost', error);
      alertQueue.addErrorAlert('Something went wrong', error.message);
    },
  });

  const uninstall = useMutation({
    mutationFn: () => uninstallEasyPost(),
    onSuccess: () => {
      alertQueue.addSuccessAlert('Success', 'EasyPost uninstalled successfully');
      onSuccess();
    },
    onError: (error: any) => {
      console.error('Unable to uninstall EasyPost', error);
      alertQueue.addErrorAlert('Something went wrong', error.message);
    },
  });

  return (
    <>
      <ConfirmationModal
        show={showUninstallModal}
        title="Are you sure?"
        confirmText="Uninstall"
        cancelText="Back"
        onConfirm={() => {
          uninstall.mutate();
        }}
        onCancel={() => {
          setShowUninstallModal(false);
        }}
        confirmButtonKind={ButtonKinds.Destructive}
      >
        <Body>
          Are you sure you want to uninstall EasyPost? This will:
          <ul>
            <li>Remove your EasyPost API Key</li>
            <li>Reset all shipping label responsibility settings</li>
          </ul>
        </Body>
      </ConfirmationModal>

      <form
        onSubmit={handleSubmit((data) => {
          install.mutate(data);
        })}
        data-testid="easypost-settings-form"
      >
        <FormField
          autoComplete="off"
          type="text"
          id="easypost-apikey-input"
          label="EasyPost API Key"
          placeholder="EZAK..."
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('apiKey', {
            required: 'API Key is required',
          })}
          error={errors.apiKey}
        />
        <Flex>
          {install.isLoading ? (
            <Flex alignItemsCenter gap="8px">
              <Spinner size="sm" />
              <Body>Connecting EasyPost...</Body>
            </Flex>
          ) : (
            <Flex column gap="16px">
              <Flex gap="12px">
                {connected && (
                  <SecondaryButton
                    size="small"
                    kind="destructive"
                    onClick={() => {
                      setShowUninstallModal(true);
                    }}
                  >
                    Uninstall EasyPost
                  </SecondaryButton>
                )}
                <PrimaryButton size="small" type="submit">
                  Save API Key
                </PrimaryButton>
              </Flex>
              <Body variant="Body/Body Small" color="bodyTextSecondary">
                Update shipping responsibility in{' '}
                <a
                  href="https://app.convictional.com/settings/shipping"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  shipping settings
                </a>{' '}
                to share your EasyPost account.
              </Body>
            </Flex>
          )}
        </Flex>
      </form>
    </>
  );
};

interface ConnectEasyPostProps {
  dispatch: AppDispatch;
  company: Company | null;
}

const ConnectEasyPost = ({ dispatch, company }: ConnectEasyPostProps) => {
  const connected = company?.easypost?.connected ?? false;
  const [showForm, setShowForm] = useState(!connected);
  return (
    <>
      <hr className="my-4" />
      <Flex column data-testid="connect-easypost" gap="8px">
        <h4>
          EasyPost API Key
          {connected && (
            <Badge variant="success" pill className="ml-3 mt-n1">
              Connected
            </Badge>
          )}
        </h4>
        {!connected && (
          <Body variant="Body/Body Small" color="bodyTextSecondary">
            We support integration with EasyPost for streamlined shipping label creation using your
            approved carriers. You can create an{' '}
            <a href="https://www.easypost.com/">EasyPost account</a> and enter your API key below.{' '}
            <SupportLink article="anymr8mmb3">More Info</SupportLink>
          </Body>
        )}
        {showForm ? (
          <EasyPostSettingsForm
            connected={connected}
            onSuccess={() => {
              setShowForm(false);

              // Tell redux to update company and getting started state now that we're connected
              dispatch(fetchGettingStarted());
              dispatch(getCurrentUserAndCompany());
            }}
          />
        ) : (
          <Flex>
            <SecondaryButton
              size="small"
              type="button"
              onClick={() => {
                setShowForm(true);
              }}
            >
              Update API Key
            </SecondaryButton>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default ConnectEasyPost;
