import PageList from 'storybook/stories/cells/PageList';
import PageNavigation from 'storybook/stories/cells/PageNavigation';
import SellerPriceListSearchResult from 'storybook/stories/cells/SearchResult/SellerPriceListSearchResult';
import SearchResults, { SearchResultsFooter } from 'storybook/stories/organisms/SearchResults';
import type { PriceList } from 'types/models/price-list';

interface PriceListSearchResultsProps {
  priceLists: PriceList[];
  currentPage: number;
  onPreviousClick: React.MouseEventHandler<HTMLButtonElement>;
  onNextClick: React.MouseEventHandler<HTMLButtonElement>;
  onSearchResultClick: (priceList: PriceList) => void;
  hasMore: boolean;
}

const PriceListSearchResults = ({
  priceLists,
  currentPage,
  onPreviousClick,
  onNextClick,
  onSearchResultClick,
  hasMore,
}: PriceListSearchResultsProps) => {
  const hasPrevious = currentPage > 1;

  const footerPageListUi = <PageList currentPage={currentPage} />;

  const footerNavigationUi = (
    <PageNavigation
      hasPrevious={hasPrevious}
      hasNext={hasMore}
      onPreviousClick={onPreviousClick}
      onNextClick={onNextClick}
    />
  );

  const footerUi = (
    <SearchResultsFooter pageListUi={footerPageListUi} navigationUi={footerNavigationUi} />
  );

  return (
    <SearchResults footerUi={footerUi}>
      {priceLists.map((priceList) => (
        <SellerPriceListSearchResult
          key={priceList.id}
          priceList={priceList}
          onClick={() => onSearchResultClick(priceList)}
        />
      ))}
    </SearchResults>
  );
};

export default PriceListSearchResults;
