import Flex from '@react-css/flex';
import Dropdown from 'storybook/stories/cells/Dropdown';
import Body from 'storybook/stories/molecules/Body';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Icon from 'storybook/stories/molecules/Icon';

interface EditButtonDropdownMenuProps {
  onEditYourRetailPriceClick: () => void;
  onEditMarginClick: () => void;
  onEditYouEarnClick: () => void;
  onRemoveFromPriceListClick: () => void;
}

const RowEditingPricingButton = ({
  onEditYourRetailPriceClick,
  onEditMarginClick,
  onEditYouEarnClick,
  onRemoveFromPriceListClick,
}: EditButtonDropdownMenuProps) => {
  return (
    // This prevents the row's selected state from being toggled when clicking the dropdown menu.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown>
        <Dropdown.Trigger asChild>
          <TertiaryButton $iconName="edit" aria-label="Edit this entry" />
        </Dropdown.Trigger>
        <Dropdown.Content>
          {({ close }) => (
            <Flex column gap="32px">
              <Dropdown.Header iconName="edit_note" heading="Edit Pricing" />

              <Flex column gap="16px">
                <Icon
                  as="button"
                  name="sell"
                  onClick={() => {
                    onEditYourRetailPriceClick();
                    close();
                  }}
                >
                  Edit &quot;Your Retail Price&quot;
                </Icon>

                <Icon
                  as="button"
                  name="percent"
                  onClick={() => {
                    onEditMarginClick();
                    close();
                  }}
                >
                  Edit Margin
                </Icon>

                <Icon
                  as="button"
                  name="price_change"
                  onClick={() => {
                    onEditYouEarnClick();
                    close();
                  }}
                >
                  Edit &quot;You Earn&quot;
                </Icon>

                <Icon
                  as="button"
                  name="delete"
                  color="error500"
                  onClick={() => {
                    onRemoveFromPriceListClick();
                    close();
                  }}
                >
                  <Body color="error500">Remove from Price List</Body>
                </Icon>
              </Flex>
            </Flex>
          )}
        </Dropdown.Content>
      </Dropdown>
    </div>
  );
};

export default RowEditingPricingButton;
