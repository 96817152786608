import { useDispatch } from 'react-redux';
import Modal from 'storybook/stories/cells/Modal';

import useAlertQueue from 'hooks/useAlertQueue';
import { getOrder } from 'store/slices/ordersV2';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Label from 'storybook/stories/molecules/Label';
import type { Order20210101, OrderItem } from 'types/models/order';
import { cancelSellerOrderItem } from 'utils/api/orders';

import {
  BodyHeaderText,
  BoldText,
  ItemRemovedRow,
  ItemRemovedText,
  RedAsterisk,
} from 'containers/OrderPage/Shared.styles';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import TextAreaInput from 'storybook/stories/molecules/Input/TextAreaInput';

export interface CancelOrderItemModalProps {
  order: Order20210101;
  items: Order20210101['items'];
  isSeller: boolean;
  showModal: boolean;
  onDismiss: () => void;
  resetItemsToUpdate: () => void;
}

interface CancelledReason {
  itemId: string;
  reason: string;
}

interface CancelledReasonsValues {
  cancelledReasons: CancelledReason[];
}

const CancelOrderItemsModal = ({
  order,
  items,
  resetItemsToUpdate,
  isSeller,
  showModal,
  onDismiss,
}: CancelOrderItemModalProps) => {
  const { addSuccessAlert, addErrorAlert } = useAlertQueue();
  const dispatch = useDispatch();
  const safeItems = items ?? [];

  const cancelOrderItem = async (values: CancelledReasonsValues) => {
    resetItemsToUpdate();
    onDismiss();

    const defaultCancellationReason = isSeller ? 'Seller cancelled order' : 'Buyer cancelled order';

    safeItems.forEach((item: OrderItem) => {
      const cancelledReason = values.cancelledReasons.find((reason) => reason.itemId === item._id);

      const data = {
        ...item,
        reason: cancelledReason?.reason ?? defaultCancellationReason,
      };

      cancelSellerOrderItem(order._id, item._id, data)
        .then(() => {
          addSuccessAlert('Success', 'Order item has been cancelled');

          // @ts-ignore
          dispatch(getOrder(order._id));
        })
        .catch((error) => {
          addErrorAlert('Could not cancel order item', error.message);
          console.error('Unable to cancel order item', error.message);
        });
    });
  };

  const cancelledReasons = safeItems.map((item) => ({ itemId: item._id, reason: '' }));
  const initialValues = { cancelledReasons };

  const { handleSubmit, control, formState } = useForm<CancelledReasonsValues>({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const { fields } = useFieldArray({
    control,
    name: 'cancelledReasons',
  });

  return (
    <Modal.Root centered show={showModal} onHide={onDismiss} animation={false}>
      <form onSubmit={handleSubmit(cancelOrderItem)}>
        <Modal.Header closeButton>
          <Modal.Title>Order Update</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <BodyHeaderText>Please provide a reason for updating this order:</BodyHeaderText>

          {fields.map((cancelledReason, idx) => {
            const item = safeItems.find((i) => i._id === cancelledReason.itemId);

            return (
              <ItemRemovedRow key={item?._id || idx}>
                <ItemRemovedText>
                  <BoldText>Item Removed:</BoldText>
                  {item?.title}
                </ItemRemovedText>
                <Label htmlFor="cancelled-reason-input">
                  Order Update Reason<RedAsterisk>&#42;</RedAsterisk>
                </Label>
                <Controller
                  name={`cancelledReasons.${idx}.reason`}
                  control={control}
                  rules={{ required: 'A reason for cancellation is required' }}
                  render={({ field }) => (
                    <TextAreaInput {...field} placeholder="Item was out of stock..." />
                  )}
                />
              </ItemRemovedRow>
            );
          })}
        </Modal.Body>

        <Modal.Footer>
          <SecondaryButton onClick={onDismiss}>Cancel</SecondaryButton>

          <PrimaryButton
            type="submit"
            data-testid="modal-update-button"
            disabled={!(formState.isDirty && formState.isValid)}
          >
            Update Order
          </PrimaryButton>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default CancelOrderItemsModal;
