import Flex from '@react-css/flex';
import Dropdown from 'storybook/stories/cells/Dropdown';

import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Icon from 'storybook/stories/molecules/Icon';

interface ActionsDropdownProps {
  onSelectProductsClick: React.MouseEventHandler<HTMLButtonElement>;
  onDeselectProductsClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ActionsDropdown = ({
  onSelectProductsClick,
  onDeselectProductsClick,
}: ActionsDropdownProps) => {
  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        <SecondaryButton $iconName="more_vert">Actions</SecondaryButton>
      </Dropdown.Trigger>
      <Dropdown.Content>
        {() => {
          return (
            <Flex column gap="32px">
              <Dropdown.Header iconName="more_vert" heading="More Actions" />

              <Flex column gap="16px">
                <Icon as="button" name="check_circle" onClick={onSelectProductsClick}>
                  Select Products
                </Icon>

                <Icon as="button" name="cancel" onClick={onDeselectProductsClick}>
                  Deselect Products
                </Icon>
              </Flex>
            </Flex>
          );
        }}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default ActionsDropdown;
