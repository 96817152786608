import Flex from '@react-css/flex';
import { isEmpty, merge } from 'lodash';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import styled from 'styled-components';

import Grid from '@react-css/grid';
import useAllPartners from 'hooks/useAllPartners';
import type { DropdownOptions } from 'storybook/stories/cells/Dropdown';
import Dropdown from 'storybook/stories/cells/Dropdown';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Label from 'storybook/stories/molecules/Label';
import RadioButton from 'storybook/stories/molecules/RadioButton';
import type { Partner } from 'types/models/partner';
import type {
  BuyerProductSearchAggregationData,
  BuyerProductSearchFacets,
} from 'types/models/search';
import type { ProductSearchParams } from 'utils/api/products';

const DEFAULT_FACETS: BuyerProductSearchFacets = {
  sellerId: {},
  brand: {},
  selected: {
    false: 0,
    true: 0,
  },
  selectedAvailable: {
    false: 0,
    true: 0,
  },
};

const getPartnerSelectOptionLabel = (partner: Partner) => {
  if (partner.sellerName && partner.sellerCompanyId)
    return `${partner.sellerName} (${partner.sellerCompanyId})`;

  if (!partner.sellerName && partner.sellerCompanyId) return partner.sellerCompanyId;

  return partner.sellerName;
};

const Form = styled.form.attrs(() => ({
  // Prevents Dropdown from closing when a React Select is cleared
  onMouseDown: (event: React.MouseEvent) => event.stopPropagation(),
}))``;

const SelectWrapper = styled.div`
  .filter-select-container {
    width: 480px;
  }
`;

export type ProductFiltersFormInputs = {
  sellerId: string;
  brand: string;
  selected: string;
  selectedAvailable: string;
};

interface FiltersDropdownProps extends DropdownOptions {
  aggregations?: BuyerProductSearchAggregationData['aggregations'];
  onFiltersFormSubmit: SubmitHandler<ProductFiltersFormInputs>;
  onFiltersFormReset: () => void;
  searchParams: ProductSearchParams;
}

const FiltersDropdown = ({
  aggregations,
  onFiltersFormSubmit,
  onFiltersFormReset,
  searchParams,
  ...dropdownOptions
}: FiltersDropdownProps) => {
  const { register, control, handleSubmit, reset, formState } = useForm<ProductFiltersFormInputs>({
    mode: 'onChange',
    defaultValues: {
      sellerId: undefined,
      brand: undefined,
    },
  });

  const { partners } = useAllPartners();

  const facets = merge({}, DEFAULT_FACETS, aggregations?.facets);
  const filters = searchParams?.filters ?? {};

  const partnerSelectOptions = partners
    .filter((partner) => Object.keys(facets.sellerId).includes(partner.sellerCompanyObjectId))
    .map((partner) => ({
      value: partner.sellerCompanyObjectId,
      label: getPartnerSelectOptionLabel(partner),
    }));

  const brandSelectOptions = Object.entries(facets.brand).map(([brandName, count]) => ({
    value: brandName,
    label: `${brandName} (${count})`,
  }));

  return (
    <Dropdown {...dropdownOptions}>
      <Dropdown.Trigger asChild>
        {isEmpty(filters) ? (
          <SecondaryButton $iconName="filter_alt">Filters</SecondaryButton>
        ) : (
          <PrimaryButton $iconName="filter_alt">Filters</PrimaryButton>
        )}
      </Dropdown.Trigger>
      <Dropdown.Content>
        {({ close }) => (
          <Form
            onSubmit={handleSubmit((values) => {
              onFiltersFormSubmit(values);
              close();
            })}
          >
            <Flex column gap="32px">
              <Dropdown.Header iconName="filter_alt" heading="Filters" />

              <Flex column gap="16px">
                <Label htmlFor="sellerId">Partners</Label>

                <Controller
                  control={control}
                  name="sellerId"
                  defaultValue={filters.sellerId || ''}
                  render={({ field }) => (
                    <SelectWrapper>
                      <Select
                        className="filter-select-container"
                        options={partnerSelectOptions}
                        onChange={(newValue) => field.onChange(newValue?.value)}
                        value={partnerSelectOptions.find((option) => option.value === field.value)}
                        ref={field.ref}
                        aria-label="Select a Partner"
                        isClearable
                        inputId="sellerId"
                      />
                    </SelectWrapper>
                  )}
                />
              </Flex>

              <Flex column gap="16px">
                <Label htmlFor="brand">Brand</Label>

                <Controller
                  control={control}
                  name="brand"
                  defaultValue={filters.brand || ''}
                  render={({ field }) => (
                    <SelectWrapper>
                      <Select
                        className="filter-select-container"
                        options={brandSelectOptions}
                        onChange={(newValue) => field.onChange(newValue?.value)}
                        value={brandSelectOptions.find((option) => option.value === field.value)}
                        ref={field.ref}
                        aria-label="Select a Brand"
                        isClearable
                        inputId="brand"
                      />
                    </SelectWrapper>
                  )}
                />
              </Flex>

              <Grid columns="1fr 1fr" gap="32px">
                <Flex column gap="16px">
                  <Label htmlFor="selected">Selected</Label>

                  <RadioButton
                    {...register('selected')}
                    value="true"
                    defaultChecked={filters?.selected === 'true'}
                  >
                    Selected ({facets.selected.true})
                  </RadioButton>

                  <RadioButton
                    {...register('selected')}
                    value="false"
                    defaultChecked={filters?.selected === 'false'}
                  >
                    Not Selected ({facets.selected.false})
                  </RadioButton>
                </Flex>

                <Flex column gap="16px">
                  <Label htmlFor="selectedAvailable">Available to Select</Label>

                  <RadioButton
                    {...register('selectedAvailable')}
                    value="true"
                    defaultChecked={filters?.selectedAvailable === 'true'}
                  >
                    Available ({facets.selectedAvailable.true})
                  </RadioButton>

                  <RadioButton
                    {...register('selectedAvailable')}
                    value="false"
                    defaultChecked={filters?.selectedAvailable === 'false'}
                  >
                    Unavailable ({facets.selectedAvailable.false})
                  </RadioButton>
                </Flex>
              </Grid>

              <Flex justifyEnd gap="8px">
                <TertiaryButton
                  type="reset"
                  onClick={() => {
                    reset(formState.defaultValues);
                    onFiltersFormReset();
                    close();
                  }}
                >
                  Clear All
                </TertiaryButton>

                <PrimaryButton type="submit">Apply</PrimaryButton>
              </Flex>
            </Flex>
          </Form>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default FiltersDropdown;
