import type { PaginatedResponse, Response } from 'types/api';
import type { DeepPartial } from 'types/general';
import type { Webhook } from 'types/models/webhook';

import { getRequest, patchRequest, postRequest } from 'utils/request';

export const getWebhooks = (): Promise<PaginatedResponse<Webhook[]>> => getRequest('/webhooks');

export const getWebhookTopics = (): Promise<Response<string[]>> => getRequest('/webhooks/topics');

export const postWebhook = (params: DeepPartial<Webhook>) => postRequest('/webhooks', params);

export const patchWebhook = (id: string, params: DeepPartial<Webhook>) =>
  patchRequest(`/webhooks/${id}`, params);
