import Flex from '@react-css/flex';
import { isEmpty, merge } from 'lodash';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { DropdownOptions } from 'storybook/stories/cells/Dropdown';
import Dropdown from 'storybook/stories/cells/Dropdown';

import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Label from 'storybook/stories/molecules/Label';
import RadioButton from 'storybook/stories/molecules/RadioButton';
import type {
  SellerProductSearchAggregationData,
  SellerProductSearchFacets,
} from 'types/models/search';
import type { ProductSearchParams } from 'utils/api/products';

const DEFAULT_FACETS: SellerProductSearchFacets = {
  active: {
    true: 0,
    false: 0,
  },
};

export type ProductFiltersFormInputs = {
  active: string;
};

interface FiltersDropdownProps extends DropdownOptions {
  aggregations?: SellerProductSearchAggregationData['aggregations'];
  onFiltersFormSubmit: SubmitHandler<ProductFiltersFormInputs>;
  onFiltersFormReset: () => void;
  searchParams: ProductSearchParams;
}

const FiltersDropdown = ({
  aggregations,
  onFiltersFormSubmit,
  onFiltersFormReset,
  searchParams,
  ...dropdownOptions
}: FiltersDropdownProps) => {
  const { register, handleSubmit, reset, formState } = useForm<ProductFiltersFormInputs>({
    mode: 'onChange',
    defaultValues: {
      active: undefined,
    },
  });

  const facets = merge({}, DEFAULT_FACETS, aggregations?.facets);
  const filters = searchParams?.filters;

  return (
    <Dropdown {...dropdownOptions}>
      <Dropdown.Trigger asChild>
        {isEmpty(filters) ? (
          <SecondaryButton $iconName="filter_alt">Filters</SecondaryButton>
        ) : (
          <PrimaryButton $iconName="filter_alt">Filters</PrimaryButton>
        )}
      </Dropdown.Trigger>
      <Dropdown.Content>
        {({ close }) => (
          <form
            onSubmit={handleSubmit((values) => {
              onFiltersFormSubmit(values);
              close();
            })}
          >
            <Flex column gap="32px">
              <Dropdown.Header iconName="filter_alt" heading="Filters" />

              <Flex column gap="16px">
                <Label htmlFor="active">Active</Label>

                <RadioButton
                  {...register('active')}
                  value="true"
                  defaultChecked={filters?.active === 'true'}
                >
                  Activated ({facets.active.true})
                </RadioButton>

                <RadioButton
                  {...register('active')}
                  value="false"
                  defaultChecked={filters?.active === 'false'}
                >
                  Not Activated ({facets.active.false})
                </RadioButton>
              </Flex>

              <Flex justifyEnd gap="8px">
                <TertiaryButton
                  type="reset"
                  onClick={() => {
                    reset(formState.defaultValues);
                    onFiltersFormReset();
                    close();
                  }}
                >
                  Clear All
                </TertiaryButton>

                <PrimaryButton type="submit">Apply</PrimaryButton>
              </Flex>
            </Flex>
          </form>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default FiltersDropdown;
