import { components, type OptionProps } from 'react-select';
import Button from 'storybook/stories/molecules/Button';
import Input from 'storybook/stories/molecules/Input';
import Select from 'storybook/stories/molecules/Select';
import styled, { css } from 'styled-components';
import type { ReactSelectOption } from 'types/general';

/**
 * <ComboInput.Input />
 */

const StyledInput = styled(Input)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
`;

/**
 * <ComboInput.Select />
 */

const Option = ({ children, ...props }: OptionProps<ReactSelectOption>) => {
  return (
    <components.Option {...props}>
      {children}
      {props.data.description && ` - ${props.data.description}`}
    </components.Option>
  );
};

const StyledSelect = styled(Select).attrs(({ placeholder = '', isSearchable = false }) => ({
  placeholder,
  isSearchable,
  components: { Option },
}))`
  .select {
    width: auto;
    min-width: 100px;
    position: static;
    border: 0;
  }

  .select__control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .select__menu {
    left: 0;
    right: 0;
  }
`;

/**
 * <ComboInput.Button />
 */

const StyledButton = styled(Input).attrs(() => ({ as: Button }))`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  // For some strange reason, border box inheritance isn't working here.
  // It works in storybook but not in the app. This is a workaround
  // until we figure out why.
  box-sizing: border-box;
`;

/**
 * <ComboInput />
 */

interface ComboInputProps {
  isFullWidth?: boolean;
}

const Base = styled.div<ComboInputProps>`
  display: inline-flex;
  align-items: center;
  position: relative;

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      display: flex;

      ${StyledInput} {
        width: 100%;
        align-self: stretch;
      }
    `}
`;

/**
 * A compound component responsible for placing two inputs next to one another.
 * By default, the Select renders with no placeholder and isn't searchable.
 * It will render a description next to each Select option, if supplied in the option data.
 */
const ComboInput = Object.assign(Base, {
  Input: StyledInput,
  Select: StyledSelect,
  Button: StyledButton,
});

export default ComboInput;
