import { useEffect, useReducer, useRef } from 'react';
import styled, { css } from 'styled-components';

import materialSymbol from 'storybook/mixins/materialSymbol';
import resetButtonStyles from 'storybook/mixins/resetButtonStyles';
import Icon from 'storybook/stories/molecules/Icon';
import { useSafeDispatch } from 'utils/hooks';

import './Message.css';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  gap: 24px;
  border-radius: 4px;
  margin: 16px 0;

  ${({ theme, kind }) => {
    if (kind === 'error')
      return css`
        background: ${theme.color.error100};
        border-color: ${theme.color.error500};
      `;

    if (kind === 'success')
      return css`
        background: ${theme.color.success100};
        border-color: ${theme.color.success500};
      `;

    if (kind === 'warning')
      return css`
        background: ${theme.color.warning100};
        border-color: ${theme.color.warning500};
      `;

    // Info is the default message state
    return css`
      background: ${theme.color.info100};
      border-color: ${theme.color.info500};
    `;
  }}
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  min-height: 55px;
  align-self: stretch;

  ${({ theme, kind }) => {
    if (kind === 'error')
      return css`
        background: ${theme.color.error500};
      `;

    if (kind === 'success')
      return css`
        background: ${theme.color.success500};
      `;

    if (kind === 'warning')
      return css`
        background: ${theme.color.warning500};
      `;

    // Info is the default message state
    return css`
      background: ${theme.color.info500};
    `;
  }}
`;

const MessageIcon = styled(Icon).attrs(({ kind }) => {
  switch (kind) {
    case 'error':
      return { name: 'error' };
    case 'success':
      return { name: 'check_circle' };
    case 'warning':
      return { name: 'warning' };
    default:
      return { name: 'info' };
  }
})`
  min-width: 32px;
  justify-content: center;

  ${({ theme, kind }) => css`
    color: ${kind === 'warning' ? theme.color.gray900 : theme.color.white};
  `}
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.color.gray900};
    gap: 4px;
    line-height: 1.4285;
    padding: 16px 24px 16px 0;
  `}
`;

const CloseButton = styled.button.attrs(() => ({
  'aria-label': 'Close',
  type: 'button',
}))`
  ${resetButtonStyles};

  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  min-height: 55px;

  ${({ theme }) => css`
    ${materialSymbol({ name: 'cancel', color: theme.color.gray900 })}
  `}
`;

const MessageComponent = ({ nodeRef, kind, className, onClose, children }) => (
  <Wrapper ref={nodeRef} kind={kind} className={className}>
    <IconWrapper kind={kind}>
      <MessageIcon kind={kind} />
    </IconWrapper>

    <BodyWrapper>{children}</BodyWrapper>

    {onClose && <CloseButton onClick={onClose} />}
  </Wrapper>
);

const Message = ({ show, onClose, children, kind, animate = false, className = '' }) => {
  const nodeRef = useRef(null);

  if (!animate && !show) return null;

  if (!animate && show) {
    return (
      <MessageComponent nodeRef={nodeRef} kind={kind} className={className} onClose={onClose}>
        {children}
      </MessageComponent>
    );
  }

  return (
    <MessageComponent kind={kind} className={className} onClose={onClose}>
      {children}
    </MessageComponent>
  );
};

const initialState = {
  show: false,
  title: '',
  message: '',
  kind: '',
};

function messageReducer(state, { type, message, title, kind }) {
  switch (type) {
    case 'show': {
      return { show: true, message, title, kind };
    }
    case 'hide': {
      return { ...state, show: false };
    }
    default: {
      throw new Error(`Unsupported alert action type: ${type}`);
    }
  }
}

export const useMessage = (autoCloseTime) => {
  const [state, dispatch] = useReducer(messageReducer, initialState);
  const safeDispatch = useSafeDispatch(dispatch);
  const hideTimer = useRef(null);

  useEffect(() => {
    clearTimeout(hideTimer.current);
    if (state.show && autoCloseTime) {
      hideTimer.current = setTimeout(() => safeDispatch({ type: 'hide' }), autoCloseTime);
    }
  }, [state, safeDispatch, autoCloseTime]);

  const showMessage =
    (kind) =>
    ({ message, title }) =>
      safeDispatch({ type: 'show', message, title, kind });

  const showErrorMessage = showMessage('error');
  const showSuccessMessage = showMessage('success');
  const showWarningMessage = showMessage('warning');

  const hideMessage = () => safeDispatch({ type: 'hide' });

  return {
    showErrorMessage,
    showSuccessMessage,
    showWarningMessage,
    hideMessage,
    messageState: state,
  };
};

export default Message;
