import { useSelector } from 'react-redux';

import ConfirmationModal from 'components/Common/ConfirmationModal';
import Message from 'components/Common/Message';
import InternalSFTPSettingsForm from 'containers/SettingsPage/DropshippingTab/IntegrationsSettings/InternalSFTPSettingsForm';
import useAlertQueue from 'hooks/useAlertQueue';
import { updateSFTPCreds } from 'store/slices/company';
import { useGetSFTPCreds } from 'store/slices/sftpUser';
import Divider from 'storybook/stories/molecules/Divider';
import Heading from 'storybook/stories/molecules/Heading';
import { useSafeState } from 'utils/hooks';
import { generateUsername } from 'utils/sftpUser';

const InternalSFTPSettings = ({ dispatch }) => {
  const alertQueue = useAlertQueue();
  const { loading, error } = useGetSFTPCreds();
  const [isUpdatingSFTPCreds, setIsUpdatingSFTPCreds] = useSafeState(false);
  const sftpUser = useSelector(({ sftpUser: user }) => user);
  const company = useSelector(({ me }) => me.company);
  const username = sftpUser.sftpUsername || generateUsername(company.name, company.legacyCompanyId);

  const handleUpdateSFTPCreds = ({ password }) => {
    setIsUpdatingSFTPCreds(true);

    dispatch(updateSFTPCreds({ username, password })).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
      } else {
        alertQueue.addSuccessAlert('Success', 'SFTP credentials updated');
      }
      setIsUpdatingSFTPCreds(false);
    });
  };

  const [isConfirming, setIsConfirming] = useSafeState(false);
  const [heldPassword, setHeldPassword] = useSafeState('');
  const triggerConfirmation = ({ password }) => {
    setIsConfirming(true);
    setHeldPassword(password);
  };

  const handleConfirm = () => {
    setIsConfirming(false);
    handleUpdateSFTPCreds({ password: heldPassword });
  };

  if (loading) {
    return (
      <div className="form-group mt-4">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <Message kind="error" show animate={false} className="mt-4">
        Failed to load SFTP Credentials settings. Please refresh the page to try again. If the error
        persists, please contact support@convictional.com.
      </Message>
    );
  }

  return (
    <>
      <Divider />
      <Heading variant="Headings/H3">SFTP Connection Details</Heading>
      <InternalSFTPSettingsForm
        sftpSettings={company.sftp}
        sftpUsername={username}
        onSubmit={triggerConfirmation}
        disableSubmitButton={isUpdatingSFTPCreds}
        showSubmitButton
        showPasswordField
        submitButtonText="Update password"
      />
      <ConfirmationModal
        show={isConfirming}
        onConfirm={handleConfirm}
        onCancel={() => {
          setIsConfirming(false);
        }}
        title="Are you sure you want to change the SFTP server password?"
        message="Changing the password for the SFTP server will break your integration if your SFTP client does not use the new password. This action can not be reverted."
        cancelText="Cancel"
      />
    </>
  );
};

export default InternalSFTPSettings;
