import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { CURRENCY_OPTIONS } from 'utils/currencies';
import type { CreatePriceListContextValues } from '../context';
import { CreatePriceListContext } from '../context';

import type { CommonFooterProps } from '../Shared.types';

import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Label from 'storybook/stories/molecules/Label';
import Select from 'storybook/stories/molecules/Select';
import ProgressBar from 'storybook/stories/organisms/ProgressBar';

const Footer = ({ isNextButtonDisabled }: CommonFooterProps) => {
  const { updateState } = useContext(CreatePriceListContext);

  const onClickBack = () => updateState({ currentStep: 'PartnersForm' });

  return (
    <Card>
      <Flex justifySpaceBetween>
        <TertiaryButton size="small" $iconName="navigate_before" onClick={onClickBack}>
          Back
        </TertiaryButton>

        <PrimaryButton type="submit" size="small" $iconName="check" disabled={isNextButtonDisabled}>
          Looks Correct
        </PrimaryButton>
      </Flex>
    </Card>
  );
};

const CurrencyForm = () => {
  const { updateState } = useContext(CreatePriceListContext);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      yourCurrency: CURRENCY_OPTIONS[0].value,
      partnerCurrency: CURRENCY_OPTIONS[0].value,
    },
  });

  const onCurrencySubmit = (data: Partial<CreatePriceListContextValues>) => {
    const currenciesAreSame = data.yourCurrency === data.partnerCurrency;
    const nextStep = currenciesAreSame ? 'MarginForm' : 'ConversionRateForm';

    updateState({
      yourCurrency: data.yourCurrency,
      partnerCurrency: data.partnerCurrency,
      currentStep: nextStep,
    });

    // clear stored values from state that aren't applicable when currencies are the same
    if (currenciesAreSame) {
      updateState({ conversionRate: 1, retailPriceFormat: null });
    }
  };

  return (
    <form onSubmit={handleSubmit(onCurrencySubmit)}>
      <Flex column gap="16px">
        <Card style={{ maxWidth: '100%' }}>
          <Flex column gap="28px">
            <ProgressBar totalSteps={5} completedSteps={4} />

            <Flex column gap="32px">
              <Heading variant="Headings/H3">Do these currencies look correct?</Heading>

              <Grid columns="1fr 1fr">
                <Flex column gap="8px">
                  <Label htmlFor="yourCurrency" isRequired>
                    Your Currency
                  </Label>

                  <Controller
                    name="yourCurrency"
                    control={control}
                    render={({ field }) => (
                      <Select
                        defaultValue={CURRENCY_OPTIONS[0]}
                        options={CURRENCY_OPTIONS}
                        value={CURRENCY_OPTIONS.find((option) => option.value === field.value)}
                        onChange={(option) =>
                          field.onChange(option?.value ?? CURRENCY_OPTIONS[0].value)
                        }
                      />
                    )}
                  />

                  <Body variant="Body/Body Small">Currency you want to be paid in</Body>
                </Flex>

                <Flex column gap="8px">
                  <Label htmlFor="partnerCurrency" isRequired>
                    Partner&apos;s Currency
                  </Label>
                  <Controller
                    name="partnerCurrency"
                    control={control}
                    render={({ field }) => (
                      <Select
                        defaultValue={CURRENCY_OPTIONS[0]} // todo: infer default from context state
                        options={CURRENCY_OPTIONS}
                        value={CURRENCY_OPTIONS.find((option) => option.value === field.value)}
                        onChange={(option) =>
                          field.onChange(option?.value ?? CURRENCY_OPTIONS[0].value)
                        }
                      />
                    )}
                  />

                  <Body variant="Body/Body Small">Currency your partner uses</Body>
                </Flex>
              </Grid>
            </Flex>
          </Flex>
        </Card>

        <Footer isNextButtonDisabled={!isValid} />
      </Flex>
    </form>
  );
};

export default CurrencyForm;
