import { set } from 'lodash';
import { useBoolean } from 'usehooks-ts';

import Flex from '@react-css/flex';
import { useSelector } from 'react-redux';
import { selectIsApiBuyer } from 'store/selectors/me/company';
import Body from 'storybook/stories/molecules/Body';
import Heading from 'storybook/stories/molecules/Heading';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import Select from 'storybook/stories/molecules/Select';
import Toggle from 'storybook/stories/molecules/Toggle';
import type { DeepPartial, MerchandisingSettings } from 'types/general';

interface SettingRowProps {
  label: string;
  description: string;
  name: string;
  children: React.ReactNode;
  shouldDisplayHeading?: boolean;
}

interface ToggleSettingProps extends Omit<SettingRowProps, 'children'> {
  value: boolean;
  name: string;
  onSettingsChange: AutonomousMerchandisingSettingsProps['onSettingsChange'];
  isDisabled?: boolean;
}

const ToggleSetting = ({
  label,
  description,
  value,
  name,
  onSettingsChange,
  isDisabled,
}: ToggleSettingProps) => {
  const checked = useBoolean(value);

  return (
    <Toggle
      name={name}
      description={description}
      checked={checked.value}
      isDisabled={isDisabled}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        checked.setValue(event.target.checked);
        onSettingsChange(set({}, name, event.target.checked));
      }}
    >
      {label}
    </Toggle>
  );
};

interface CreatableSelectSettingProps extends Omit<SettingRowProps, 'children'> {
  value: string[];
  name: string;
  onSettingsChange: AutonomousMerchandisingSettingsProps['onSettingsChange'];
  isDisabled?: boolean;
}

const CreatableSelectSetting = ({
  label,
  description,
  value,
  name,
  onSettingsChange,
  isDisabled,
}: CreatableSelectSettingProps) => {
  const options = value.map((item) => ({ label: item, value: item }));

  return (
    <Flex column gap="4px">
      <Label htmlFor={name}>{label}</Label>
      <Select
        isMulti
        isCreatable
        isClearable
        name={name}
        inputId={name}
        isDisabled={isDisabled}
        defaultValue={options}
        onChange={(data) =>
          onSettingsChange(
            set(
              {},
              name,
              data.map((option) => option.value)
            )
          )
        }
      />
      {description && <Body variant="Inputs/Input Message">{description}</Body>}
    </Flex>
  );
};

interface TextInputSettingProps extends Omit<SettingRowProps, 'children'> {
  value: string;
  name: string;
  placeholder?: string;
  onSettingsChange: AutonomousMerchandisingSettingsProps['onSettingsChange'];
  isDisabled?: boolean;
}

const TextInputSetting = ({
  label,
  description,
  value,
  name,
  placeholder,
  onSettingsChange,
  isDisabled,
}: TextInputSettingProps) => {
  return (
    <Flex column gap="4px">
      <Label htmlFor={name}>{label}</Label>
      <Input
        id={name}
        defaultValue={value}
        disabled={isDisabled}
        placeholder={placeholder}
        onChange={(e) => onSettingsChange(set({}, name, e.target.value))}
      />

      {description && <Body variant="Inputs/Input Message">{description}</Body>}
    </Flex>
  );
};

interface ImageSettingsProps {
  settings?: MerchandisingSettings;
  onSettingsChange: AutonomousMerchandisingSettingsProps['onSettingsChange'];
}

const ImageSettings = ({ settings, onSettingsChange }: ImageSettingsProps) => {
  return (
    <Flex column gap="16px">
      <Heading variant="Headings/H4">Images</Heading>

      <Flex column gap="24px">
        <Flex column gap="4px">
          <Label htmlFor="imageSettings.fitWidth">Constrain image width</Label>
          <Flex.Item>
            <Input
              type="number"
              id="imageSettings.fitWidth"
              name="imageSettings.fitWidth"
              aria-label="Choose an image width"
              defaultValue={settings?.imageSettings?.fitWidth ?? 0}
              onChange={(e) =>
                onSettingsChange(set({}, 'imageSettings.fitWidth', Number(e.target.value)))
              }
            />
          </Flex.Item>
        </Flex>

        <Flex column gap="4px">
          <Label htmlFor="imageSettings.fitHeight">Constrain image height</Label>
          <Flex.Item>
            <Input
              type="number"
              id="imageSettings.fitHeight"
              name="imageSettings.fitHeight"
              aria-label="Choose an image height"
              defaultValue={settings?.imageSettings?.fitHeight ?? 0}
              onChange={(e) =>
                onSettingsChange(set({}, 'imageSettings.fitHeight', Number(e.target.value)))
              }
            />
          </Flex.Item>
        </Flex>
      </Flex>
    </Flex>
  );
};

interface TranslationSettingsProps {
  settings?: MerchandisingSettings;
  onSettingsChange: AutonomousMerchandisingSettingsProps['onSettingsChange'];
}

const TranslationSettings = ({ settings, onSettingsChange }: TranslationSettingsProps) => {
  const titleChecked = useBoolean(settings?.translationSettings?.translateTitle ?? false);
  const descriptionChecked = useBoolean(settings?.translationSettings?.translateBody ?? false);

  const options = (settings?.translationSettings?.locales ?? []).map((item) => ({
    label: item,
    value: item,
  }));

  return (
    <Flex column gap="16px">
      <Heading variant="Headings/H4">Translations</Heading>

      <Flex column gap="24px">
        <Flex column gap="4px">
          <Label htmlFor="translationSettings.locales">Locales to support</Label>

          <Select
            isMulti
            isCreatable
            isClearable
            defaultValue={options}
            aria-label="Choose locales to support"
            onChange={(data) =>
              onSettingsChange(
                set(
                  {},
                  'translationSettings.locales',
                  data.map((option) => option.value)
                )
              )
            }
          />
        </Flex>

        <Toggle
          id="translationSettings.translateTitle"
          name="translationSettings.translateTitle"
          aria-label="Translate your partner's titles"
          checked={titleChecked.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            titleChecked.setValue(event.target.checked);
            onSettingsChange(set({}, 'translationSettings.translateTitle', event.target.checked));
          }}
        >
          Translate your partner&apos;s titles
        </Toggle>

        <Toggle
          id="translationSettings.translateBody"
          name="translationSettings.translateBody"
          aria-label="Translate your partner's descriptions"
          checked={descriptionChecked.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            descriptionChecked.setValue(event.target.checked);
            onSettingsChange(set({}, 'translationSettings.translateBody', event.target.checked));
          }}
        >
          Translate your partner&apos;s descriptions
        </Toggle>
      </Flex>
    </Flex>
  );
};

export interface AutonomousMerchandisingSettingsProps {
  settings?: MerchandisingSettings;
  onSettingsChange: (settings: DeepPartial<MerchandisingSettings>) => void;
}

const AutonomousMerchandisingSettings = ({
  settings,
  onSettingsChange,
}: AutonomousMerchandisingSettingsProps) => {
  const isApiBuyer = useSelector(selectIsApiBuyer);

  return (
    <Flex column gap="24px" data-testid="merchandising-settings">
      <ToggleSetting
        label="Receive product updates"
        description="Sync your partner's changes to their products to your store"
        value={settings?.pushSellerProductUpdates ?? false}
        name="pushSellerProductUpdates"
        onSettingsChange={onSettingsChange}
      />

      <ToggleSetting
        label="Predict Google categorization"
        description="Have Modern Dropship automatically predict your partner's Google Product Category"
        value={settings?.predictCategory ?? false}
        name="predictCategory"
        onSettingsChange={onSettingsChange}
      />

      <Flex column gap="16px">
        <Heading variant="Headings/H4">Tags</Heading>

        <Flex column gap="24px">
          <ToggleSetting
            label="Add partner name as product tag"
            description="Add your partner's name as a tag to your products"
            value={settings?.addSellerNameProductTag ?? false}
            name="addSellerNameProductTag"
            onSettingsChange={onSettingsChange}
          />

          <ToggleSetting
            label="Add category as product tag"
            description="Add your partner's Google Product Category to your products"
            value={settings?.addSellerGpcProductTag ?? false}
            name="addSellerGpcProductTag"
            onSettingsChange={onSettingsChange}
          />

          <ToggleSetting
            label="Remove partner tags"
            description="Remove your partner's tags from your products"
            value={settings?.removeSellerProductTags ?? false}
            name="removeSellerProductTags"
            onSettingsChange={onSettingsChange}
          />

          <CreatableSelectSetting
            label="Add default product tags"
            description="Add these tags to all products"
            value={settings?.defaultProductTags ?? []}
            name="defaultProductTags"
            onSettingsChange={onSettingsChange}
          />
        </Flex>
      </Flex>

      <Flex column gap="16px">
        <Heading variant="Headings/H4">Formatting</Heading>

        <Flex column gap="24px">
          <TextInputSetting
            label="Modify product title format"
            description="Modify your partner's product title format"
            value={settings?.titleTemplate ?? ''}
            name="titleTemplate"
            placeholder="{{ titleize .product.Title }}"
            onSettingsChange={onSettingsChange}
          />

          <TextInputSetting
            label="Modify product description format"
            description="Modify your partner's product description format"
            value={settings?.bodyHtmlTemplate ?? ''}
            name="bodyHtmlTemplate"
            placeholder="{{ .product.BodyHTML }} - Suffix"
            onSettingsChange={onSettingsChange}
          />

          <TextInputSetting
            label="Modify product SEO title format"
            description="Modify your partner's product SEO title format"
            value={settings?.seoTitleTemplate ?? ''}
            name="seoTitleTemplate"
            placeholder="{{ .seller.Name }} {{ .product.Title }}"
            onSettingsChange={onSettingsChange}
          />
        </Flex>
      </Flex>

      <ImageSettings settings={settings} onSettingsChange={onSettingsChange} />

      {isApiBuyer && (
        <TranslationSettings settings={settings} onSettingsChange={onSettingsChange} />
      )}
    </Flex>
  );
};

export default AutonomousMerchandisingSettings;
