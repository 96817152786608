import { getRequest, postRequest } from 'utils/request';

export const generateSchema = (documentNumber, body) => {
  return postRequest(`/edi/schemas/${documentNumber}/generate`, body);
};

export const useDefaultSchema = (documentNumber) => {
  return postRequest(`/edi/schemas/${documentNumber}/default/live`, {});
};

export const getSegmentInfo = (params) => {
  return getRequest(`/edi/info`, params);
};

export const createSchema = (documentNumber, body) => {
  return postRequest(`/edi/schemas/${documentNumber}`, body);
};

export const listSchemas = (documentNumber, params) => {
  return getRequest(`/edi/schemas/${documentNumber}`, params);
};

export const setSchemaAsLive = (documentNumber, schemaID) => {
  return postRequest(`/edi/schemas/${documentNumber}/id/${schemaID}/live`);
};

export const getEDIRecords = (orderId) => getRequest(`/edi/orders/${orderId}/records`);

export const retryEDIRecord = (ediRecordId) => postRequest(`/edi/records/${ediRecordId}/retry`);
