import Timeline from 'storybook/stories/organisms/Timeline';
import type { EventComponentProps } from 'storybook/stories/organisms/Timeline/HistoryTimeline';
import type { Order20210101 } from 'types/models/order';
import { orderIsFulfilled } from 'utils/order';

const OrderFulfilledPayload = ({ event }: EventComponentProps) => {
  const snapshot = event.snapshot as Order20210101 | null;

  const fullyFulfilled = orderIsFulfilled(snapshot);

  return (
    <Timeline.Event
      iconName="inventory_2"
      timestamp={event.createdAt}
      title={fullyFulfilled ? 'Order Fulfilled' : 'Order Partially Fulfilled'}
    />
  );
};

export default OrderFulfilledPayload;
