import Flex from '@react-css/flex';
import type React from 'react';
import { useState } from 'react';
import type { Options, SingleValue } from 'react-select';
import { useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import FormField from 'storybook/stories/cells/FormField';
import Body from 'storybook/stories/molecules/Body';
import UnitInput from 'storybook/stories/molecules/Input/UnitInput';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import Select from 'storybook/stories/molecules/Select';
import type { ReactSelectOption } from 'types/general';
import type { Partner } from 'types/models/partner';
import type { UpdatePartnerParams } from 'utils/api/partners';

interface BuyerDetailsProps {
  partner: Partner;
  updatePartner: (body: UpdatePartnerParams) => void;
}

const BuyerDetails = ({ partner, updatePartner }: BuyerDetailsProps) => {
  const [shippingLabelOwner, setShippingLabelOwner] = useState(
    partner.shippingSettings?.shippingLabelOwner ?? ''
  );
  const [maxShipTime, setMaxShipTime] = useState(partner.maxShipTime ?? 0);
  const [maxReturnRate, setMaxReturnRate] = useState(partner.maxReturnRate ?? 0);

  const company = useAppSelector(selectCompany);
  const companyMaxShipTime = company?.maxShipTime ?? 0;
  const companyMaxReturnRate = company?.maxReturnRate ?? 0;
  // Helpers

  const shippingOwnerBuyerOptions: Options<ReactSelectOption> = [
    {
      value: 'seller',
      label: `Provided by ${partner.sellerName}`,
    },
    {
      value: 'buyer',
      label: `Provided by ${partner.buyerName}`,
    },
    {
      value: '',
      label: 'Inherit from company settings',
    },
  ];

  const selectedShippingOwnerBuyerOption = shippingOwnerBuyerOptions.find(
    (option) => option.value === shippingLabelOwner
  );

  // Event Handlers

  const onShippingLabelSourceChange = (option: SingleValue<ReactSelectOption>) => {
    const newShippingLabelOwner = option?.value ?? '';
    setShippingLabelOwner(newShippingLabelOwner);
    updatePartner({ shippingSettings: { shippingLabelOwner: newShippingLabelOwner } });
  };

  const onShipTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let maxShipTimeValue = e.target.valueAsNumber;
    // Clear the max ship time if the input is empty
    if (e.target.value === '') {
      maxShipTimeValue = 0;
    }
    updatePartner({ maxShipTime: maxShipTimeValue });
    setMaxShipTime(maxShipTimeValue);
  };

  const onReturnRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let maxReturnRateValue = e.target.valueAsNumber;
    // Clear the max return rate if the input is empty
    if (e.target.value === '') {
      maxReturnRateValue = 0;
    }
    updatePartner({ maxReturnRate: maxReturnRateValue });
    setMaxReturnRate(maxReturnRateValue);
  };

  // Render

  return (
    <>
      <Flex column gap="4px">
        <Body variant="Body/Header" color="bodyTextSecondary">
          Assigned Price List
        </Body>

        {partner.priceListName || (
          <span>
            None. Your partner can create a price list by following{' '}
            <SupportLink article="ia2nwgi8be">this support guide</SupportLink>.
          </span>
        )}
      </Flex>

      {partner.priceListName && (
        <Flex column gap="4px">
          <Body variant="Body/Header" color="bodyTextSecondary">
            Price List Margin
          </Body>

          <span>
            {partner.pricingMarginMin === partner.pricingMarginMax
              ? `${partner.pricingMarginMin}%`
              : `${partner.pricingMarginMin}-${partner.pricingMarginMax}%`}
          </span>
        </Flex>
      )}

      <FormField id="partnerShippingLabelOwner" labelText="Shipping Label Source">
        <Select
          inputId="partnerShippingLabelOwner"
          name="partnerShippingLabelOwner"
          options={shippingOwnerBuyerOptions}
          value={selectedShippingOwnerBuyerOption}
          onChange={onShippingLabelSourceChange}
        />
      </FormField>

      <FormField id="maxShipTime" labelText="Expected Maximum Ship Time">
        <UnitInput
          id="maxShipTime"
          type="number"
          defaultValue={partner.maxShipTime > 0 ? partner.maxShipTime : undefined}
          onChange={onShipTimeChange}
          unit="hours"
        />

        {maxShipTime > 0 && (
          <Body variant="Inputs/Input Message">
            This overrides the default max ship time SLA
            {companyMaxShipTime > 0 && ` (${companyMaxShipTime} hours)`} specified in your{' '}
            <Link to="/settings/dropshipping/service-levels">settings</Link>.
          </Body>
        )}
        {maxShipTime === 0 && companyMaxShipTime > 0 && (
          <Body variant="Inputs/Input Message">
            This partner is using the default max ship time SLA of {companyMaxShipTime} hours
            specified in your <Link to="/settings/dropshipping/service-levels">settings</Link>.
          </Body>
        )}
      </FormField>

      <FormField id="maxReturnRate" labelText="Expected Maximum Return Rate">
        <UnitInput
          id="maxReturnRate"
          type="number"
          defaultValue={partner.maxReturnRate > 0 ? partner.maxReturnRate : undefined}
          onChange={onReturnRateChange}
          unit="%"
        />

        {maxReturnRate > 0 && (
          <Body variant="Inputs/Input Message">
            This overrides the default max return rate SLA
            {companyMaxReturnRate > 0 && ` (${companyMaxReturnRate}%)`} specified in your{' '}
            <Link to="/settings/dropshipping/service-levels">settings</Link>.
          </Body>
        )}
        {maxReturnRate === 0 && companyMaxReturnRate > 0 && (
          <Body variant="Inputs/Input Message">
            This partner is using the default max return rate SLA of {companyMaxReturnRate}%
            specified in your <Link to="/settings/dropshipping/service-levels">settings</Link>.
          </Body>
        )}
      </FormField>
    </>
  );
};

export default BuyerDetails;
