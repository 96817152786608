import Flex from '@react-css/flex';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import Card from 'storybook/stories/cells/Card';
import Table from 'storybook/stories/cells/Table';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import Input from 'storybook/stories/molecules/Input';
import type { Partner } from 'types/models/partner';
import type { UpdatePartnerParams } from 'utils/api/partners';
import {
  FIELD_LABELS,
  indexFields,
  mapDataToFields,
  mapFieldsToData,
  type PartnerMetafieldData,
} from 'utils/partners';

type PartnerCustomInformationParams = {
  partner: Partner;
  updatePartner: (params: UpdatePartnerParams) => void;
};

const PartnerCustomInformation = ({ partner, updatePartner }: PartnerCustomInformationParams) => {
  const company = useAppSelector(selectCompany);

  const [formState, setFormState] = useState<PartnerMetafieldData[][]>([]);

  useEffect(() => {
    const metafieldsSchema =
      company &&
      company.ediData &&
      company.ediData.warehouseMetafieldNamespace &&
      company.ediData.warehouseDefaultMetafieldKey
        ? [
            {
              namespace: company.ediData.warehouseMetafieldNamespace || '',
              key: company.ediData.warehouseDefaultMetafieldKey || '',
            },
          ]
        : [];

    const mappedFields = mapDataToFields(metafieldsSchema, partner.metafields);
    setFormState(mappedFields);
  }, [partner, company, partner.metafields]);

  const removeField = ({ currentTarget: { id } }: React.MouseEvent<HTMLButtonElement>) => {
    const removedRowIdx = parseInt(id.split('-')[2], 10);
    const updatedFields = formState.filter((i, idx) => idx !== removedRowIdx);
    const indexedFields = indexFields(updatedFields);

    setFormState(indexedFields);
    updatePartner(mapFieldsToData(indexedFields));
  };

  const addField = () => {
    const rowIdx = formState.length;
    const newFieldRow = FIELD_LABELS.map((label, colIdx) => ({
      isNew: true,
      id: `${label.toLowerCase()}-${rowIdx},${colIdx}`,
      value: '',
      label,
    }));
    const updatedFields = [...formState, newFieldRow];

    setFormState(updatedFields);
    updatePartner(mapFieldsToData(updatedFields));
  };

  const updateField = ({ target: { id, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const [rowUpdateIdx, colUpdateIdx] = id
      .split('-')[1]
      .split(',')
      .map((idx) => parseInt(idx, 10));
    const updatedFields = formState.map((row, rowIdx) =>
      row.map((i, colIdx) =>
        rowIdx === rowUpdateIdx && colIdx === colUpdateIdx
          ? { ...formState[rowIdx][colIdx], value }
          : formState[rowIdx][colIdx]
      )
    );

    setFormState(updatedFields);
    updatePartner(mapFieldsToData(updatedFields));
  };

  return (
    <Card>
      <Card.Head>
        <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
          Custom Information
        </Heading>
      </Card.Head>

      <Card.Body>
        <Flex column gap="16px">
          <Table>
            {formState.length > 0 && (
              <Table.THead>
                <Table.TR>
                  {FIELD_LABELS.map((label) => (
                    <Table.TH key={label}>{label}</Table.TH>
                  ))}
                  <Table.TH />
                </Table.TR>
              </Table.THead>
            )}

            <Table.TBody>
              {formState.map((row, idx) => (
                <Table.TR key={`row-${row[0].id}`}>
                  {row.map(({ id, label, value, readOnly }) => (
                    <Table.TD key={id} width={label === 'Value' ? '100%' : ''}>
                      <Input
                        id={id}
                        name={id}
                        value={value}
                        placeholder={`${label}...`}
                        onChange={updateField}
                        disabled={readOnly}
                        isFullWidth={label === 'Value'}
                      />
                    </Table.TD>
                  ))}

                  <Table.TD>
                    {row[0].isNew && (
                      <Icon
                        as="button"
                        name="delete"
                        onClick={removeField}
                        id={`delete-field-${idx}`}
                        aria-label="Remove row"
                        color="error500"
                      />
                    )}
                  </Table.TD>
                </Table.TR>
              ))}
            </Table.TBody>
          </Table>

          <Flex justifyEnd>
            <SecondaryButton size="small" color="primary" onClick={addField}>
              Add field
            </SecondaryButton>
          </Flex>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default PartnerCustomInformation;
