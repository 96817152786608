import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useQuery } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from 'usehooks-ts';

import Spinner from 'components/Common/Spinner';
import DashboardPage from 'components/Dashboard/DashboardPage';
import useAlertQueue from 'hooks/useAlertQueue';
import { useAppSelector } from 'store';
import Card from 'storybook/stories/cells/Card';
import PageList from 'storybook/stories/cells/PageList';
import PageNavigation from 'storybook/stories/cells/PageNavigation';
import Table from 'storybook/stories/cells/Table';
import type { Partner } from 'types/models/partner';
import { getInvoices } from 'utils/api/invoices';
import { getPartners } from 'utils/api/partners';
import { humanizeMoney, toMoney } from 'utils/currencies';
import { prettyDate } from 'utils/date';
import { friendlyInvoiceSource, invoiceDescription } from 'utils/invoices';

import Page from 'storybook/stories/cells/Page';
import DownloadCsvButton from './DownloadCsvButton';
import EmptyInvoicesPage from './EmptyInvoicesPage';
import InvoiceStatusPill from './InvoiceStatusPill';

interface InvoicesPageWrapperProps {
  children: ReactNode;
  shouldShowDownloadCSVButton?: boolean;
}

const InvoicesPageWrapper = ({
  children,
  shouldShowDownloadCSVButton,
}: InvoicesPageWrapperProps) => (
  <DashboardPage>
    <Page>
      <Page.Head title="Invoices">{shouldShowDownloadCSVButton && <DownloadCsvButton />}</Page.Head>
      <Page.Body>{children}</Page.Body>
    </Page>
  </DashboardPage>
);

const sellerIdToName = (partners: Partner[], sellerId: string): string => {
  const partner = partners.find((p) => p.sellerCompanyObjectId === sellerId);
  if (!partner) {
    return sellerId;
  }
  return partner.sellerName;
};

const buyerIdToName = (partners: Partner[], buyerId: string): string => {
  const partner = partners.find((p) => p.buyerCompanyObjectId === buyerId);
  if (!partner) {
    return buyerId;
  }
  return partner.buyerName;
};

const InvoicesPage = () => {
  useDocumentTitle('Invoices');

  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const me = useAppSelector((state) => state.me);
  const alertQueue = useAlertQueue();

  const isBuyer = me.company?.commerceType === 'buyer';

  // Grab invoices to display in our table
  const { data: invoicesResponse } = useQuery({
    queryKey: ['invoice', currentPage],
    queryFn: () => getInvoices({ page: currentPage, limit: 20 }),
    staleTime: 60000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: (err) => {
      console.error(`Failed to fetch invoices ${err}`);
      alertQueue.addErrorAlert('Error', 'Error retrieving invoices');
    },
  });

  // Grab partners to use for better display names
  const { data: partnersResponse } = useQuery({
    queryKey: ['getPartners', { page: 0, limit: 250 }],
    queryFn: () => getPartners({ page: 0, limit: 250 }),
    staleTime: 60000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: (err) => {
      console.error(`Failed to fetch partners ${err}`);
      alertQueue.addErrorAlert('Error', 'Error retrieving partners');
    },
  });

  const handleTableRowClick = (invoiceId: string) => {
    history.push(`/invoices/${invoiceId}`);
  };

  // Early return with a spinner for when either response has not returned yet
  if (!invoicesResponse || !partnersResponse)
    return (
      <InvoicesPageWrapper>
        <Spinner />
      </InvoicesPageWrapper>
    );

  const invoices = invoicesResponse.data;
  const partners = partnersResponse.data as Partner[];
  const hasInvoices = invoices.length > 0;

  const showBuyerCodes = invoices.some((invoice) => invoice.buyerCode !== '');

  if (!hasInvoices)
    return (
      <InvoicesPageWrapper>
        <EmptyInvoicesPage />
      </InvoicesPageWrapper>
    );

  return (
    <InvoicesPageWrapper shouldShowDownloadCSVButton={invoices.length > 0}>
      <Card>
        <Grid rows="auto" gap="16px">
          <Grid.Item>
            <Table variant="data" data-testid="quotes-table">
              <Table.THead>
                <Table.TR>
                  <Table.TH>Date</Table.TH>
                  <Table.TH>Partner</Table.TH>
                  {showBuyerCodes && <Table.TH>Buyer Code</Table.TH>}
                  <Table.TH>Order Number</Table.TH>
                  <Table.TH align="right">Total</Table.TH>
                  <Table.TH>Source</Table.TH>
                  <Table.TH>Posted</Table.TH>
                  <Table.TH>Status</Table.TH>
                </Table.TR>
              </Table.THead>

              <Table.TBody>
                {invoices.map((invoice) => (
                  <Table.TR
                    key={invoice.id}
                    onClick={() => handleTableRowClick(invoice.id)}
                    data-testid="invoicerow"
                  >
                    <Table.TD>{prettyDate(invoice.created)}</Table.TD>
                    <Table.TD>
                      {isBuyer
                        ? sellerIdToName(partners, invoice.sellerCompanyId)
                        : buyerIdToName(partners, invoice.buyerCompanyId)}
                    </Table.TD>
                    {showBuyerCodes && <Table.TD>{invoice.buyerCode}</Table.TD>}
                    <Table.TD>{invoiceDescription(invoice)}</Table.TD>
                    <Table.TD align="right">
                      {humanizeMoney(toMoney(invoice.amountTotalCents / 100, invoice.currency))}
                    </Table.TD>
                    <Table.TD>{friendlyInvoiceSource(invoice.source)}</Table.TD>
                    <Table.TD>{invoice.posted ? 'Yes' : 'No'}</Table.TD>
                    <Table.TD>
                      <InvoiceStatusPill status={invoice.status} />
                    </Table.TD>
                  </Table.TR>
                ))}
              </Table.TBody>
            </Table>
          </Grid.Item>

          <Grid.Item>
            <Flex justifyEnd alignItemsCenter gap="16px">
              <PageList currentPage={currentPage + 1} />

              <PageNavigation
                hasPrevious={currentPage !== 0}
                hasNext={invoicesResponse.hasMore}
                onPreviousClick={() => setCurrentPage((page) => page - 1)}
                onNextClick={() => setCurrentPage((page) => page + 1)}
              />
            </Flex>
          </Grid.Item>
        </Grid>
      </Card>
    </InvoicesPageWrapper>
  );
};

export default InvoicesPage;
