import Flex from '@react-css/flex';
import Card from 'storybook/stories/cells/Card';
import Icon from 'storybook/stories/molecules/Icon';
import styled from 'styled-components';

export const InnerCard = styled(Card)`
  padding: 16px;
  position: relative;
  z-index: 0;
`;

export const GrowingIcon = styled(Icon)`
  flex-grow: 1;
`;

export const WordBreakFlex = styled(Flex)`
  word-break: break-all;
`;

export const OuterCard = styled(Card)`
  padding: 0; // Remove outside padding so that the border is flush with the table card
  overflow: hidden; // Hide the extra shadows that an inner card will cause
`;

export const CardFooter = styled.div`
  // Inset the footer so that the table overlaps it
  margin-top: -8px;
`;
