import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import CreateShippingLabelModal from 'containers/OrderPage/ShippingLabels/CreateShippingLabelModal';
import { getOrderItemsAvailableToShip } from 'containers/OrderPage/ShippingLabels/helpers';
import { useMemo } from 'react';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import type { Order20210101 } from 'types/models/order';
import type { Partner } from 'types/models/partner';
import { useBoolean } from 'usehooks-ts';
import { getShippingLabelsForOrder } from 'utils/api/orders';
import { prettyDateTime } from 'utils/date';

export interface ShippingLabelProps {
  order: Order20210101;
  partner?: Partner;
}
const ShippingLabels = ({ order, partner }: ShippingLabelProps) => {
  const shouldShowCreateLabelModal = useBoolean(false);

  const fetchingShippingLabels = useQuery({
    queryKey: ['getShippingLabelsForOrder', order._id],
    queryFn: () => getShippingLabelsForOrder(order._id),
    enabled: order._id !== '' && order._id !== undefined,
  });

  const shippingLabels = fetchingShippingLabels.data?.data;

  const availableToShip = useMemo(
    () => getOrderItemsAvailableToShip(shippingLabels, order.items || []),
    [shippingLabels, order.items]
  );

  const showCreateLabelButton = partner?.effectiveShippingSettings?.shippingLabelOwner === 'buyer';

  // If there are no shipping labels and the user is not allowed to create them, don't show anything
  if (!showCreateLabelButton && shippingLabels?.length === 0) {
    return null;
  }

  return (
    <>
      <Card className="mb-4">
        <Flex column gap="12px">
          <Flex gap="8px" justifySpaceBetween>
            <Heading variant="Headings/H3">Shipping Labels</Heading>
            {availableToShip.length > 0 && showCreateLabelButton && (
              <PrimaryButton size="small" onClick={shouldShowCreateLabelModal.setTrue}>
                Create Label
              </PrimaryButton>
            )}
          </Flex>

          {shippingLabels?.length === 0 ? (
            <Body>You haven&apos;t created any shipping labels yet</Body>
          ) : (
            shippingLabels?.map((s) => (
              <Flex column key={s.id}>
                <a href={s.labelURL} rel="noopener noreferrer" target="_blank">
                  Label - {s.trackingCode}
                </a>
                <Body variant="Body/Body Small" color="gray500">
                  Created: {prettyDateTime(s.createdAt)}
                </Body>
              </Flex>
            ))
          )}
        </Flex>
      </Card>

      {order.items && (
        <CreateShippingLabelModal
          shouldShow={shouldShowCreateLabelModal.value}
          onDismiss={shouldShowCreateLabelModal.setFalse}
          orderId={order._id}
          items={availableToShip}
          refetchShippingLabels={fetchingShippingLabels.refetch}
        />
      )}
    </>
  );
};

export default ShippingLabels;
