import styled, { css } from 'styled-components';

import Label from 'storybook/stories/molecules/Label';

// Text

export const BodyHeaderText = styled.p`
  padding-bottom: 18px;

  ${({ theme }) => css`
    color: ${theme.color.gray600};
    font-size: 20px;
  `}
`;

export const ItemRemovedRow = styled.span`
  padding-bottom: 64px;
`;

export const ItemRemovedText = styled.span`
  display: flex;
  font-size: 16px;

  &:last-child {
    margin-bottom: 24px;
  }
`;

export const BoldText = styled.p`
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 4px;
`;

export const RedAsterisk = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.error500};
  `};
`;

export const CancellationReasonLabel = styled(Label)`
  margin-top: 24px;
`;
