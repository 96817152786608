import type { MaterialSymbolOptions } from 'storybook/mixins/materialSymbol';
import materialSymbol from 'storybook/mixins/materialSymbol';
import Button from 'storybook/stories/molecules/Button';
import styled from 'styled-components';

/**
 * Composes an icon as a button.
 */
const IconButton = styled(Button)<MaterialSymbolOptions>`
  ${(options) => materialSymbol(options)};
`;

export default IconButton;
