/* eslint-disable jsx-a11y/label-has-associated-control */
import Modal from 'storybook/stories/cells/Modal';

import Message from 'components/Common/Message';

const CreateEDIRecordModal = ({ record, showModal, onDismiss }) => {
  const onCloseModal = () => {
    onDismiss();
  };
  if (!record) return null;
  return (
    <Modal.Root
      show={showModal}
      size="md"
      aria-labelledby="edi-detail-modal-title"
      centered
      onHide={onCloseModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>EDI Record details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Message show={record.errors.length > 0} kind="error" className="mt-0">
          <p className="m-0 mb-1">
            <strong>This EDI record contains errors</strong>
          </p>

          <div className="m-0">
            <ul className="list-group list-group-flush">
              {record.errors.map((err, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className="list-group-item" key={`errorkey${i}`}>
                    {err.message}{' '}
                  </li>
                );
              })}
            </ul>
          </div>
        </Message>

        <div className="p-4">
          <label className="mr-2 font-weight-bold">Interchange Control Number:</label>
          <p className="d-inline">{record.interchangeControlNumber}</p>
          <p className="text-muted small">A number uniquely identifying every EDI record.</p>
          <hr className="my-4" />
          <label className="mr-2 font-weight-bold">EDI Record ID:</label>
          <p className="d-inline">{record.id}</p>
          <p className="text-muted small">Our unique identifier for every EDI record.</p>
          <hr className="my-4" />
          <label className="mr-2 font-weight-bold">Source Path:</label>
          <p className="d-inline text-break">{record.sourcePath}</p>
          <hr className="my-4" />
          <label className="mr-2 font-weight-bold">Created:</label>
          <p className="d-inline">{record.created}</p>
        </div>
      </Modal.Body>
    </Modal.Root>
  );
};

export default CreateEDIRecordModal;
