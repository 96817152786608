import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { useState } from 'react';

/**
 * Helper function to turn an array into an object with array elements indexed by _id
 * @param {array} list
 * @returns {object} idMap
 */
export const arrayToIdMap = (list) =>
  list
    ? list.reduce((accumulator, item) => {
        accumulator[item._id] = { ...item };
        return accumulator;
      }, {})
    : {};

/**
 * Helper function that takes a resource list of objects and returns the ids sorted by position
 * @param {array} list - array of objects
 * @returns {array} array of ids
 */
export const getSortedIdList = (list) =>
  list ? [...list].sort((a, b) => a.position - b.position).map((item) => item._id) : [];

/**
 * Helper function that takes a list of ids and an idMap and updates the position
 * of each obj in the id map according to the order of idList
 * @param {array} idList
 * @param {object} idMap
 * @returns {object} updatedIdMap
 */
export const updateIdMapPositions = (idList = [], idMap = {}) =>
  idList
    ? idList.reduce((accumulator, id, idx) => {
        if (!idMap[id]) return accumulator;
        accumulator[id] = { ...idMap[id], position: idx + 1 };
        return accumulator;
      }, {})
    : {};

/**
 * get a difference between two objects
 * @param {*} original - original object
 * @param {*} wip - work in progress object
 * @returns an object containing the difference between the two compared on root level keys
 */
export const shallowDiff = (original = {}, wip = {}) => {
  return Object.keys(wip).reduce((accumulator, key) => {
    const value = wip[key];
    if (!_isEqual(value, original[key])) {
      accumulator[key] = value;
    }

    return accumulator;
  }, {});
};

/**
 * Get array for patch request
 * @param {array|object} original - array from original product (ie. images, or options), or previously made idMap
 * @param {object} editMap - an idMap of edited product data { [id]: data }
 * @returns {array} patch
 */
export const getArrayPatch = (original = [], editMap = {}) => {
  const idMap = _isArray(original) ? arrayToIdMap(original) : original;
  return Object.keys(editMap).reduce((result, id) => {
    const editItem = editMap[id];
    // add the whole item to result because it is new (has no _id so has not been saved)
    if (!editItem._id) {
      result.push(editItem);
      return result;
    }
    const originalItem = idMap[editItem._id];
    // if it is not in the original product, skip it
    if (!originalItem) return result;
    const diff = shallowDiff(originalItem, editItem);
    // if there is a difference, add it to patch
    if (!_isEmpty(diff)) {
      result.push({ ...diff, _id: editItem._id });
    }
    return result;
  }, []);
};

/**
 * Get attributes patch object
 * @param {object} original - original attributes from product or variant
 * @param {array} edits - an array of edited attributes data
 * @returns {object} patch
 */
export const getAttributesPatch = (original = {}, edits = []) => {
  const editsNameMap = {}; // easy lookup map of current attribute names (prevent nested looping)
  const patch = {};
  // go through edits, remove empty names from consideration and create easy lookup for finding names that have been removed
  edits.forEach((attr) => {
    const name = attr ? attr.name : '';
    if (!name) return;
    editsNameMap[name] = true;
    if (!original[name] || original[name].value !== attr.value) {
      patch[name] = { value: attr.value };
    }
  });
  // fill patch request with delete requests for names that are no longer in list but were initially
  Object.keys(original).forEach((name) => {
    if (!editsNameMap[name]) {
      patch[name] = null;
    }
  });
  return patch;
};

/**
 * Hook used to manage the live editing and display of product information
 * TODO: Refactor live product editing to make it less complicated. Memo more components and lift up work in progress state.
 *       Reconcile hasEdits outside of render cycle and calculate PATCH payload on save.
 */
const useEditProduct = ({ product }) => {
  // object representing edited product data that will be sent in PATCH
  const [edits, setEdits] = useState({});
  // used to notify children when product data has been updated/cleared to reset their local "work in progress" state
  const [clearEditsAt, setClearEditsAt] = useState(null);
  // used to show the save/discard buttons if the product has edits that are different from original
  const hasEdits = !_isEmpty(edits);

  // editing root level product fields
  const onEditProductField = (key) => (value) => {
    // remove from edits if work in progress value is the same as product value
    if (_isEqual(product[key], value)) {
      if (!Object.prototype.hasOwnProperty.call(edits, key)) return;
      const editsCopy = { ...edits };
      delete editsCopy[key];
      setEdits(editsCopy);
      return;
    }
    setEdits({ ...edits, [key]: value });
  };

  // array fields (options, images, variants, attributes) manage their edits and reconcile the PATCH locally in the individual child components
  // this callback is used to report the necessary PATCH back to this parent component
  const onEditArrayField = (key) => (patch) => {
    if (patch === null || _isEmpty(patch)) {
      const editsCopy = { ...edits };
      delete editsCopy[key];
      setEdits(editsCopy);
      return;
    }
    setEdits({ ...edits, [key]: patch });
  };

  // responds the same way as onEditArrayField
  // checks is PATCH is null or empty, adds it if it isn't empty, removes it if it is
  const onEditObjectField = onEditArrayField;

  // reset any edits that have happened and change the timestamp so children can reset their local state
  const onDiscardEdits = () => {
    setEdits({});
    setClearEditsAt(new Date().toISOString());
  };

  return {
    edits,
    hasEdits,
    onEditProductField,
    onEditArrayField,
    onEditObjectField,
    onDiscardEdits,
    clearEditsAt,
  };
};

export default useEditProduct;
