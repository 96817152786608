import Flex from '@react-css/flex';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { isEmpty } from 'lodash';
import Dropdown from 'storybook/stories/cells/Dropdown';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Input from 'storybook/stories/molecules/Input';
import type { PriceListEntriesSearchParams } from 'utils/api/priceLists';

export type PriceListEntryFiltersFormInputs = {
  tags: string[];
};

interface FiltersDropdownProps {
  onFiltersFormSubmit: SubmitHandler<PriceListEntryFiltersFormInputs>;
  onFiltersFormReset: () => void;
  searchParams: PriceListEntriesSearchParams;
}

const FiltersDropdown = ({
  onFiltersFormSubmit,
  onFiltersFormReset,
  searchParams,
}: FiltersDropdownProps) => {
  const { register, handleSubmit } = useForm<PriceListEntryFiltersFormInputs>({
    defaultValues: {
      tags: searchParams?.filters?.tags,
    },
  });

  const filters = searchParams?.filters;

  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        {isEmpty(filters) ? (
          <TertiaryButton $iconName="filter_alt" kind="neutral">
            Filters
          </TertiaryButton>
        ) : (
          <PrimaryButton $iconName="filter_alt">Filters</PrimaryButton>
        )}
      </Dropdown.Trigger>
      <Dropdown.Content>
        {({ close }) => (
          <form
            onSubmit={handleSubmit((values) => {
              onFiltersFormSubmit(values);
              close();
            })}
          >
            <Flex column gap="32px">
              <Dropdown.Header iconName="filter_alt" heading="Filters" />

              <Input
                type="text"
                id="tags"
                placeholder="Search for Product Tags"
                {...register('tags')}
              />

              <Flex justifyEnd gap="8px">
                <TertiaryButton
                  type="reset"
                  size="small"
                  onClick={() => {
                    onFiltersFormReset();
                    close();
                  }}
                >
                  Clear All
                </TertiaryButton>

                <PrimaryButton type="submit" size="small">
                  Apply
                </PrimaryButton>
              </Flex>
            </Flex>
          </form>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default FiltersDropdown;
