import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';

import useAlertQueue from 'hooks/useAlertQueue';
import { useSelector } from 'react-redux';
import { selectIsProductPlatformShopify } from 'store/selectors/me/company';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import type { Return } from 'types/models/return';
import { useBoolean } from 'usehooks-ts';
import { acceptReturn } from 'utils/api/orders';

interface AcceptReturnFormData {
  sellerCode: string;
}

interface AcceptReturnModalProps {
  orderReturn: Return;
  shouldShowModal: boolean;
  onDismiss: () => void;
  refetchReturns: () => void;
}

const AcceptReturnModal = ({
  orderReturn,
  shouldShowModal,
  onDismiss,
  refetchReturns,
}: AcceptReturnModalProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();
  const isUsingShopify = useSelector(selectIsProductPlatformShopify);

  const shouldShowCodeInput = useBoolean(!isUsingShopify);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm<AcceptReturnFormData>({
    mode: 'onChange',
    defaultValues: {
      sellerCode: '',
    },
  });

  const acceptingReturn = useMutation({
    mutationFn: (data: AcceptReturnFormData) =>
      acceptReturn(orderReturn.orderId, orderReturn.id, data),
    onSuccess: () => {
      refetchReturns();
      reset();
      onDismiss();
      addSuccessAlert('Success', 'You have accepted the return request.');
    },
    onError: (error) => {
      addErrorAlert('Something went wrong', 'The return request was not accepted.');
      console.error('Error accepting return', error);
    },
  });

  return (
    <Modal.Root centered show={shouldShowModal} onHide={onDismiss} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Accept Return</Modal.Title>
      </Modal.Header>

      <form
        onSubmit={handleSubmit((data) => {
          acceptingReturn.mutate(data);
        })}
      >
        <Modal.Body>
          {shouldShowCodeInput.value ? (
            <Flex column gap="4px">
              <Label isRequired htmlFor="seller-code">
                RMA Code
              </Label>
              <Input
                {...register('sellerCode', { required: true })}
                placeholder="RMA#1234"
                required
              />
            </Flex>
          ) : (
            <Body variant="Body/Regular" align="center">
              Are you sure you want to accept this return?
            </Body>
          )}
        </Modal.Body>

        <Modal.Footer>
          <TertiaryButton onClick={onDismiss}>Cancel</TertiaryButton>

          <PrimaryButton disabled={!isValid || isSubmitting} kind="action" type="submit">
            Accept Return
          </PrimaryButton>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default AcceptReturnModal;
