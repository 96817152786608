import Flex from '@react-css/flex';
import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { useBoolean, useDocumentTitle } from 'usehooks-ts';

import useAlertQueue from 'hooks/useAlertQueue';
import useAllPartners from 'hooks/useAllPartners';
import useSellerOnlyPage from 'hooks/useSellerOnlyPage';
import { selectCompany, selectIsSeller } from 'store/selectors/me/company';
import { getPriceList, importPriceListCSV, previewPriceListCSV } from 'utils/api/priceLists';

import DashboardPage from 'components/Dashboard/DashboardPage';
import { BulkEditPriceListProvider } from 'containers/PriceListPage/BulkEditing/context';
import ExportPriceListCsvModal from 'containers/PriceListPage/CSV/ExportPriceListCsvModal';
import PreviewPriceListCsvModal from 'containers/PriceListPage/CSV/PreviewPriceListCsvModal';
import DeletePriceListModal from 'containers/PriceListPage/DeletePriceListModal';
import LoadingPriceListPage from 'containers/PriceListPage/LoadingPriceListPage';
import NoPriceListPage from 'containers/PriceListPage/NoPriceListPage';
import PriceListEntries from 'containers/PriceListPage/PriceListEntries';
import PriceListHistory from 'containers/PriceListPage/PriceListHistory';
import PriceListSettings from 'containers/PriceListPage/Settings';
import { MarginType } from 'containers/PriceListsPage/CreatePriceList/Shared.types';

import ManageWithCsvDropdown from 'containers/PriceListPage/CSV/ManageWithCsvDropdown';
import type { UploadedFile } from 'storybook/stories/cells/FileUpload';
import Page from 'storybook/stories/cells/Page';
import Tabs from 'storybook/stories/cells/Tabs';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';

interface LocationState {
  backUrlQueryParams?: string;
}

interface LocationWithState extends Location {
  state: LocationState;
}

enum TabView {
  Products,
  Settings,
  History,
}

const PriceListPage = () => {
  const { priceId: priceListId } = useParams<{ priceId: string }>();
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();
  const location = useLocation() as LocationWithState;
  const history = useHistory();
  const queryClient = useQueryClient();
  const isSeller = useSelector(selectIsSeller);
  const company = useSelector(selectCompany);

  const [selectedTab, setSelectedTab] = useState<TabView>(TabView.Products);
  const [errorMessage, setErrorMessage] = useState('');
  const { partners = [] } = useAllPartners({});
  const [csvFile, setCsvFile] = useState<string | ArrayBuffer | null>(null);

  // Helpers

  const backUrl =
    location.state && location.state.backUrlQueryParams
      ? `/prices${location.state.backUrlQueryParams}`
      : '/prices';

  const shouldShowDeleteModal = useBoolean(false);
  const shouldShowPreviewModal = useBoolean(false);
  const shouldShowExportModal = useBoolean(false);

  // Side Effects

  // redirect if a buyer tries to access this page
  useSellerOnlyPage({ isSeller, history });

  // Fetch all price list data
  const fetchingPriceList = useQuery({
    queryKey: ['getPriceList', priceListId] as const,
    queryFn: ({ queryKey }) => getPriceList(queryKey[1]),
    enabled: !!priceListId,
    onError: (error: { message: string }) => {
      setErrorMessage(error.message);
      Sentry.captureException(error);
    },
  });

  const priceList = fetchingPriceList.data?.data;

  const uploadingCsv = useMutation({
    mutationFn: (result: FileReader['result']) => importPriceListCSV(priceList!.id, result),
    onSuccess: () => {
      shouldShowPreviewModal.setFalse();
      addSuccessAlert('CSV imported successfully', "We're updating your price list now!");
      queryClient.invalidateQueries(['getPriceList']);
      queryClient.invalidateQueries(['getPriceListEntries', priceList!.id]);
      queryClient.invalidateQueries(['getPriceListEntrySearch']);
    },
    onError: (error: { message: string }) => {
      addErrorAlert('Something went wrong', error.message);
      console.error('Unable to import price list csv', error);
    },
  });

  const previewingCsvImport = useMutation({
    mutationFn: (result: FileReader['result']) => previewPriceListCSV(priceList!!.id, result),
    onSuccess: shouldShowPreviewModal.setTrue,
    onError: (error: { message: string }) => {
      addErrorAlert('Something went wrong', error.message);
      console.error('Unable to preview price list csv', error);
    },
  });

  const csvPreview = previewingCsvImport.data?.data;

  const onProcessFile = (data: UploadedFile): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const blob = new Blob([data.file], { type: 'application/octet-stream' });
      const reader = new FileReader();

      reader.onload = () => {
        setCsvFile(reader.result);
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        addErrorAlert('Something went wrong', 'The file you tried to import was unreadable');
        console.error('Unreadable price list csv imported', error);
        reject(error);
      };

      reader.readAsText(blob, 'UTF-8');
    });
  };

  // update document title, use listName if present
  const listName = priceList?.name || '';
  useDocumentTitle(`Price List${listName ? ` | ${listName}` : ''}`);

  // when we delete the price list, let's replace the page with the prices list
  const onPriceListDeleted = () => {
    history.replace(backUrl);
  };

  // Event Handlers

  const onPartnerUpdated = () => {
    queryClient.invalidateQueries(['price-list-partners']);
  };

  const onProductsTabClick = () => setSelectedTab(TabView.Products);
  const onSettingsTabClick = () => setSelectedTab(TabView.Settings);
  const onHistoryTabClick = () => setSelectedTab(TabView.History);

  // If the user is not a seller, redirect buyer to Orders
  if (!isSeller) return <Redirect to="/" />;

  // Render loading price list
  if (!priceList && fetchingPriceList.isFetching) {
    return (
      <DashboardPage>
        <LoadingPriceListPage />
      </DashboardPage>
    );
  }

  // Render empty price list
  if (!priceList) {
    return (
      <DashboardPage>
        <NoPriceListPage error={errorMessage} backUrl={backUrl} />
      </DashboardPage>
    );
  }

  // Render
  return (
    <DashboardPage>
      <Page>
        <Page.Head
          title={priceList ? `Price List: ${priceList.name}` : 'Price List Details'}
          backButton={{
            onClick: () => history.push(backUrl),
            text: 'Back to Price Lists',
          }}
        >
          <ManageWithCsvDropdown
            onProcessFile={onProcessFile}
            onGeneratePreview={(rawFile) => previewingCsvImport.mutate(rawFile)}
            onClickExportCsv={shouldShowExportModal.setTrue}
          />

          <PrimaryButton
            size="small"
            $iconName="delete_forever"
            kind="destructive"
            onClick={shouldShowDeleteModal.setTrue}
          >
            Delete Price List
          </PrimaryButton>
        </Page.Head>

        <Page.Body>
          <Flex column gap="24px">
            <Tabs>
              <Tabs.Item
                $iconName="widgets"
                selected={selectedTab === TabView.Products}
                onClick={onProductsTabClick}
              >
                Products
              </Tabs.Item>
              <Tabs.Item
                $iconName="edit_note"
                selected={selectedTab === TabView.Settings}
                onClick={onSettingsTabClick}
              >
                Settings
              </Tabs.Item>
              <Tabs.Item
                $iconName="history"
                selected={selectedTab === TabView.History}
                onClick={onHistoryTabClick}
              >
                History
              </Tabs.Item>
            </Tabs>

            <Flex.Item>
              {/* Products View */}
              {selectedTab === TabView.Products && (
                <BulkEditPriceListProvider>
                  <Flex column gap="16px">
                    <PriceListEntries
                      priceListId={priceListId}
                      defaultMargin={priceList.dropshipMargin}
                      marginType={
                        priceList.dropshipMarginType === 'percent'
                          ? MarginType.Percent
                          : MarginType.Fixed
                      }
                      sellerCurrency={priceList.sellerCurrency}
                      onProcessFile={onProcessFile}
                      onGeneratePreview={(rawFile) => previewingCsvImport.mutate(rawFile)}
                      onClickExportCsv={shouldShowExportModal.setTrue}
                    />
                  </Flex>
                </BulkEditPriceListProvider>
              )}

              {/* Settings View */}
              {selectedTab === TabView.Settings && (
                <PriceListSettings
                  company={company}
                  priceList={priceList}
                  partners={partners}
                  onPartnerUpdated={onPartnerUpdated}
                  refreshPriceList={fetchingPriceList.refetch}
                />
              )}

              {/* History View */}
              {selectedTab === TabView.History && <PriceListHistory priceListId={priceList.id} />}
            </Flex.Item>
          </Flex>

          {/* Modals */}
          <DeletePriceListModal
            priceList={priceList}
            show={shouldShowDeleteModal.value}
            onDismiss={shouldShowDeleteModal.setFalse}
            onPriceListDeleted={onPriceListDeleted}
            partnerList={partners}
          />

          <PreviewPriceListCsvModal
            shouldShowModal={shouldShowPreviewModal.value}
            onDismiss={shouldShowPreviewModal.setFalse}
            onConfirmImport={() => uploadingCsv.mutate(csvFile)}
            preview={csvPreview}
          />

          <ExportPriceListCsvModal
            shouldShowModal={shouldShowExportModal.value}
            onDismiss={shouldShowExportModal.setFalse}
            priceListId={priceList.id}
            priceListName={priceList.name}
          />
        </Page.Body>
      </Page>
    </DashboardPage>
  );
};

export default PriceListPage;
