import { useMutation, useQueryClient } from '@tanstack/react-query';
import ConfirmationModal from 'components/Common/ConfirmationModal';
import useAlertQueue from 'hooks/useAlertQueue';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import type { Invoice } from 'types/models/invoice';
import { useBoolean } from 'usehooks-ts';
import { payInvoice } from 'utils/api/invoices';

export interface InvoicePayButtonProps {
  invoice: Invoice;
}

export const InvoicePayButton = ({ invoice }: InvoicePayButtonProps) => {
  const { addSuccessAlert, addErrorAlert } = useAlertQueue();
  const queryClient = useQueryClient();

  const shouldShowConfirmationModal = useBoolean(false);

  const payingInvoice = useMutation({
    mutationFn: () => payInvoice(invoice.id),
    onSuccess: () => {
      addSuccessAlert('Success!', 'Invoice marked as paid');
      queryClient.invalidateQueries(['getInvoice']);
    },
    onError: (error: any) => {
      addErrorAlert('Error', error.message);
    },
  });

  const pay = () => {
    payingInvoice.mutate();
    shouldShowConfirmationModal.setFalse();
  };

  return (
    <>
      <PrimaryButton
        size="small"
        onClick={() => {
          shouldShowConfirmationModal.setTrue();
        }}
      >
        Mark Paid
      </PrimaryButton>
      <ConfirmationModal
        show={shouldShowConfirmationModal.value}
        title="Are you sure?"
        confirmText="Mark Paid"
        cancelText="Cancel"
        onConfirm={pay}
        onCancel={() => {
          shouldShowConfirmationModal.setFalse();
        }}
      >
        <Body>
          Once an invoice has been manually marked as paid, it cannot be undone. Are you sure you
          want to proceed?
        </Body>
      </ConfirmationModal>
    </>
  );
};
