import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import Body from 'storybook/stories/molecules/Body';
import CommerceTypeDecisionButton from 'storybook/stories/molecules/Button/DecisionButton/CommerceTypeDecisionButton';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import ProgressBar from 'storybook/stories/organisms/ProgressBar';
import { CommerceType } from 'types/models/company';

interface CommerceTypeSelectionProps {
  currentStep: number;
  totalSteps: number;
  onNextButtonClick: () => void;
}

const CommerceTypeSelection = ({
  currentStep,
  totalSteps,
  onNextButtonClick,
}: CommerceTypeSelectionProps) => {
  const location = useLocation<{ isCreatingAnotherCompany?: boolean }>();
  const { setValue, watch } = useFormContext();

  const isCreatingAnotherCompany = !!location.state?.isCreatingAnotherCompany;
  const commerceType = watch('commerceType');

  return (
    <Flex column gap="48px">
      <ProgressBar totalSteps={totalSteps} completedSteps={currentStep} />

      <Flex column gap="32px">
        <Flex column gap="8px">
          <Heading variant="Headings/H3" as="h1">
            How do you want to use Modern Dropship?
          </Heading>

          <Body as="p">
            Please choose a commerce type. Your commerce type can only be changed later under
            certain conditions. Please choose the one that aligns best with your company&apos;s
            objective.{' '}
            <SupportLink article="/account-management/how-to-change-your-commerce-type">
              Learn more
            </SupportLink>
          </Body>
        </Flex>

        <Grid columns="1fr 1fr" gap="24px">
          <CommerceTypeDecisionButton
            variant={CommerceType.Seller}
            isActive={commerceType === CommerceType.Seller}
            onClick={() => setValue('commerceType', CommerceType.Seller)}
          />

          <CommerceTypeDecisionButton
            variant={CommerceType.Buyer}
            isActive={commerceType === CommerceType.Buyer}
            onClick={() => setValue('commerceType', CommerceType.Buyer)}
          />
        </Grid>

        <Flex justifySpaceBetween>
          <TertiaryButton
            $iconName={isCreatingAnotherCompany && 'navigate_before'}
            $removePadding="left"
            as={Link}
            to={isCreatingAnotherCompany ? '/' : '/logout'}
          >
            {isCreatingAnotherCompany ? 'Back' : 'Log out'}
          </TertiaryButton>

          <PrimaryButton $iconName="navigate_next" $iconAfter onClick={onNextButtonClick}>
            Next
          </PrimaryButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CommerceTypeSelection;
