import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useMutation } from '@tanstack/react-query';
import { endOfDay, format, parseISO, startOfDay } from 'date-fns';
import download from 'downloadjs';
import useAlertQueue from 'hooks/useAlertQueue';
import { useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import type { ErrorMessage } from 'types/api';
import { useBoolean } from 'usehooks-ts';
import { downloadOrders, type DownloadOrdersParams } from 'utils/api/orders';

const DownloadCsvButton = () => {
  const modalVisibility = useBoolean(false);
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  /**
   * Form
   */

  interface FormFields {
    createdMin?: string;
    createdMax?: string;
  }

  const { register, handleSubmit, formState, reset } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      createdMin: '',
      createdMax: '',
    },
  });

  /**
   * Helpers
   */

  const closeModal = () => {
    reset();
    modalVisibility.setFalse();
  };

  /**
   * Mutations
   */

  const exportingCSV = useMutation({
    mutationFn: (params: DownloadOrdersParams) => downloadOrders(params),
    onSuccess: (data) => {
      download(data, 'orders.csv', 'text/csv');
      addSuccessAlert('Success', 'Your orders have been exported to a CSV.');
    },
    onError: (error: ErrorMessage) => {
      addErrorAlert('Something went wrong', error.message);
      console.error('Unable to export orders to CSV.', error);
    },
    onSettled: () => {
      closeModal();
    },
  });

  /**
   * Event Handlers
   */

  const onDownloadFormSubmit = (data: FormFields) => {
    const params: DownloadOrdersParams = {};

    if (data.createdMin)
      params.createdMin = format(startOfDay(parseISO(data.createdMin)), "yyyy-MM-dd'T'HH:mm:ssxxx");

    if (data.createdMax)
      params.createdMax = format(endOfDay(parseISO(data.createdMax)), "yyyy-MM-dd'T'HH:mm:ssxxx");

    exportingCSV.mutate(params);
  };

  /**
   * Render
   */

  return (
    <>
      <PrimaryButton size="small" $iconName="download" onClick={modalVisibility.setTrue}>
        Download CSV
      </PrimaryButton>

      <Modal.Root show={modalVisibility.value} onHide={closeModal} centered>
        <form onSubmit={handleSubmit(onDownloadFormSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Download Orders</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Flex column gap="24px">
              <Body variant="Body/Regular">
                Use these fields to reduce your export to a specific date rage. If you&apos;d like
                to try to export your entire order history, leave these fields blank.
              </Body>

              <Grid columns="1fr 1fr" gap="16px">
                <Flex column gap="8px">
                  <Label htmlFor="createdMin">From</Label>
                  <Input type="date" id="createdMin" {...register('createdMin')} />
                </Flex>

                <Flex column gap="8px">
                  <Label htmlFor="createdMax">To</Label>
                  <Input type="date" id="createdMax" {...register('createdMax')} />
                </Flex>
              </Grid>
            </Flex>
          </Modal.Body>

          <Modal.Footer>
            <Flex justifyEnd gap="16px">
              <TertiaryButton type="reset" onClick={closeModal}>
                Cancel
              </TertiaryButton>

              <PrimaryButton type="submit" disabled={exportingCSV.isLoading}>
                {formState.isDirty ? 'Download Range' : 'Download All'}
              </PrimaryButton>
            </Flex>
          </Modal.Footer>
        </form>
      </Modal.Root>
    </>
  );
};

export default DownloadCsvButton;
