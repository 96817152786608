import type { BaseQueryParams, PaginatedResponse, Response } from 'types/api';
import type { Attribute, BaseSearchParams } from 'types/general';
import type { Event } from 'types/models/history';
import type { Order20210101 } from 'types/models/order';
import type { OrderSearchAggregationData, OrderSearchData } from 'types/models/search';
import type { ShippingLabel, ShippingLabelQuantities } from 'types/models/shipping';
import type { TaxItem } from 'types/models/tax';
import config from 'utils/config';
import { getOptions } from 'utils/options';
import {
  getBlobRequest,
  getCsvRequest,
  getRequest,
  patchRequest,
  postRequest,
  request,
} from 'utils/request';

export interface GetOrdersParams extends BaseQueryParams {
  sellerOrderCode?: string;
  buyerOrderCode?: string;
  sellerCompanyId?: string;
  buyerCompanyId?: string;
  posted?: string;
  shipped?: string;
  billed?: string;
  flagged?: string;
  buyerOrderCodeLike?: string;
  isTest?: string;
  createdMin?: string;
  createdMax?: string;
}

type GetOrdersResponse = Order20210101[];

export const getOrders = (params: GetOrdersParams): Promise<GetOrdersResponse> =>
  getRequest('/orders', params);

export const getOrderById = (orderId: string) => getRequest(`/orders/${orderId}`);

interface UpdateOrderParams {
  billed?: boolean;
  note?: string;
  attributes: Record<string, Attribute>;
}

export const updateOrder = (orderId: string, params: UpdateOrderParams) =>
  patchRequest(`/orders/${orderId}`, params);

interface CreateFulfillmentParams {
  id: string;
  carrier: string;
  trackingNumbers: string[];
  trackingCode: string;
  trackingUrls: string[];
  items: { id: string; quantity: number }[];
}

export const createFulfillment = (orderId: string, params: CreateFulfillmentParams) =>
  postRequest(`/orders/${orderId}/fulfillments`, params);

export const getPackingSlipForOrder = (orderId: string) =>
  getBlobRequest(`/orders/${orderId}/packingslip`, {}, { Accept: 'application/pdf' });

export const getOTAPackingSlipForOrder = (orderId: string, token: string) =>
  request(
    `${config.apiURL}/ota/orders/${orderId}/packingslip?token=${token}`,
    getOptions({ Accept: 'application/pdf' }, {}),
    'blob'
  );

export const getPackingSlipForFulfillment = (orderId: string, fulfillmentId: string) =>
  getBlobRequest(
    `/orders/${orderId}/fulfillments/${fulfillmentId}/packingslip`,
    {},
    {
      Accept: 'application/pdf',
    }
  );

interface CancelSellerOrderParams {
  reason: string;
  unfulfilledOnly: boolean;
}

export const cancelSellerOrder = (orderId: string, params: CancelSellerOrderParams) =>
  postRequest(`/orders/${orderId}/cancel`, params);

interface CancelSellerOrderItemParams {
  reason?: string;
  newQuantity?: number;
}

export const cancelSellerOrderItem = (
  orderId: string,
  itemId: string,
  params: CancelSellerOrderItemParams
) => postRequest(`/orders/${orderId}/items/${itemId}/cancel`, params);

interface GenerateInvoiceParams {
  taxes: TaxItem[];
}

export const generateInvoice = (orderId: string, params: GenerateInvoiceParams) =>
  postRequest(`/orders/${orderId}/invoices`, params);

export const unflagOrder = (orderId: string) => postRequest(`/orders/${orderId}/unflag`);

export const getSuggestedTaxes = (orderId: string) => getRequest(`/orders/${orderId}/taxes`);

export interface DownloadOrdersParams extends BaseQueryParams {
  sellerOrderCode?: string;
  buyerOrderCode?: string;
  sellerCompanyId?: string;
  buyerCompanyId?: string;
  posted?: string;
  shipped?: string;
  billed?: string;
  flagged?: string;
  buyerOrderCodeLike?: string;
  isTest?: string;
  createdMin?: string;
  createdMax?: string;
}

export const downloadOrders = (params?: DownloadOrdersParams) =>
  getCsvRequest('/orders/export', params);

export const getOrderReturns = (orderId: string) => getRequest(`/orders/${orderId}/returns`);

interface AcceptReturnParams {
  sellerCode: string;
}

export const acceptReturn = (orderId: string, returnId: string, params: AcceptReturnParams) =>
  patchRequest(`/orders/${orderId}/returns/${returnId}/accept`, params);

interface RejectReturnParams {
  rejectionReason: string;
}

export const rejectReturn = (orderId: string, returnId: string, params: RejectReturnParams) =>
  patchRequest(`/orders/${orderId}/returns/${returnId}/reject`, params);

export const completeReturn = (orderId: string, returnId: string) =>
  patchRequest(`/orders/${orderId}/returns/${returnId}/complete`, null);

export const getShippingLabelsForOrder = (orderId: string): Promise<Response<ShippingLabel[]>> =>
  getRequest(`/orders/${orderId}/shipping-labels`);

export type CreateShippingLabelParams = {
  quantities: ShippingLabelQuantities;
  weight: { value: number; units: string };
  dimensions?: { length: number; width: number; height: number; units: string };
};

export const createShippingLabel = (
  orderId: string,
  data: CreateShippingLabelParams
): Promise<Response<ShippingLabel>> => postRequest(`/orders/${orderId}/shipping-labels`, data);

export type OrderHistoryResponse = PaginatedResponse<Event[]>;

export const getOrderHistory = async (
  orderId: string,
  params?: BaseQueryParams
): Promise<OrderHistoryResponse> => getRequest(`/orders/${orderId}/history`, params);

export interface OrderSearchFilters {
  sellerId?: string;
  buyerId?: string;
  posted?: string;
  shipped?: string;
  invoiced?: string;
  paid?: string;
  hasReturns?: string;
  hasPendingReturns?: string;
  flagged?: string;
  cancelled?: string;
}

export type OrderSearchParams = BaseSearchParams<OrderSearchFilters>;

export type OrderSearchResponse = PaginatedResponse<OrderSearchData>;

export const getOrderSearch = (params?: OrderSearchParams): Promise<OrderSearchResponse> =>
  getRequest('/orders/search', params);

export type OrderSearchAggregationResponse = Response<OrderSearchAggregationData>;

export const getOrderSearchAggregations = (
  params?: OrderSearchParams
): Promise<OrderSearchAggregationResponse> => getRequest('/orders/search/aggregations', params);
