import Flex from '@react-css/flex';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import DocumentSegment from 'containers/EDISchemas/DocumentSegment';
import ElementID from 'containers/EDISchemas/ElementID';
import MappingForm from 'containers/EDISchemas/MappingForm';
import SegmentCard from 'containers/EDISchemas/SegmentCard';
import { getSegmentInfo } from 'store/slices/edi/info';
import { setSchemaName } from 'store/slices/edi/schema';
import Tooltip from 'storybook/stories/cells/Tooltip';
import Icon from 'storybook/stories/molecules/Icon';
import { formatKey } from 'utils/edi';

const loadKeyframe = keyframes`
  from {
    left: -3rem;
  }
  to {
    left: 100%;
  }
`;

const loadKeyframeDouble = keyframes`
  from {
    left: -3rem;
  }
  to {
    left: 200%;
  }
`;

const LoadingText = styled.div`
  height: 0.9375rem;
  background: var(--light);
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  display: inline-block;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 3rem;
    top: 0;
    height: 100%;
    width: 3rem;
    background: linear-gradient(to right, transparent 0%, white 50%, transparent 100%);
    animation: ${loadKeyframe} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
`;
const EmptyTitle = styled(LoadingText)`
  width: 30%;
  height: 15px;
  margin-bottom: -2px;
  &::before {
    animation: ${loadKeyframeDouble} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
`;

const TransactionSet = styled.div`
  background: #edf2f994;
  font-weight: 600;
  font-family: monospace;
  padding: 16px 10px;
  position: sticky;
  top: 25px;
  border: 1px solid #edf2f9;
  border-radius: 6px;
`;

const InputLabel = styled.label`
  font-size: 12px;
  margin: 0px;
  color: grey;
`;

const SegmentCardID = styled.span`
  cursor: pointer;
  margin-right: 20px;
  line-height: 30px;
`;

const SegmentCardName = styled.span`
  font-size: 12px;
  color: grey;
`;

const MappingHeaderIcon = styled(Icon)`
  font-size: 20px;
  float: right;
`;

const MappingConfiguration = (props) => {
  const { documentNumber = '', errors } = props;
  const { schema, sortedSegments } = useSelector(({ ediSchemas }) => ediSchemas);
  const { info, infoLoading } = useSelector(({ ediInfo }) => ediInfo);

  const [segmentActiveIndex, setSegmentActiveIndex] = useState(-1);
  const [elementHovered, setElementHovered] = useState(null);
  const [elementSelected, setElementSelected] = useState(null);

  const dispatch = useDispatch();

  const updateName = ({ target }) => {
    dispatch(setSchemaName(target.value));
  };

  /* Select segment card to open */
  const [open, setOpen] = useState('');
  const handleOpen = (value, segment) => {
    // clear any previously selected element
    setElementSelected(null);
    if (open === value) {
      // if same card selected, close it
      setOpen(-1);
    } else {
      // get the info for the segment and open
      const params = {
        segmentID: segment.segmentID,
        documentNumber,
        scope: segment.scopeName,
      };
      dispatch(getSegmentInfo(params)).then((action) => {
        if (action.error) {
          console.warn('failed to get segment information');
        }
      });
      setOpen(value);
    }
  };

  /* Select element in segment card to configure */
  const [scopeName, setScopeName] = useState();
  const onConfigureMapping = (segment, elementIndex) => {
    const { scopeName: scope, segmentID, segmentIndex } = segment;
    const key = formatKey(segmentID, segmentIndex, elementIndex);
    setElementSelected(key);
    setScopeName(scope);
  };

  const hasErrors = (segment) => {
    return errors[segment.segmentID] ? errors[segment.segmentID][segment.segmentIndex] : false;
  };

  return (
    <div className="row">
      <div className="col-md-12 col-lg-6">
        <h2>Transaction Set</h2>
        <TransactionSet>
          {sortedSegments.map((segment, segmentIndex) => {
            return (
              <DocumentSegment
                // eslint-disable-next-line react/no-array-index-key
                key={segmentIndex}
                segment={segment}
                segmentActive={segmentIndex === segmentActiveIndex}
                elementHovered={elementHovered}
                elementSelected={elementSelected}
              />
            );
          })}
        </TransactionSet>
      </div>

      <div className="col-md-12 col-lg-6">
        <h2>Mapping</h2>
        <InputLabel>Name</InputLabel>
        <input
          type="text"
          placeholder="Give this mapping a name"
          className="form-control mb-3"
          onChange={updateName}
          value={schema.name}
        />
        <div
          className="accordion mb-5"
          onMouseLeave={() => {
            setSegmentActiveIndex(-1);
          }}
        >
          {sortedSegments.map((segment, cardIndex) => {
            return (
              <SegmentCard
                hasErrors={hasErrors(segment)}
                // eslint-disable-next-line react/no-array-index-key
                key={`${segment.segmentID}-${cardIndex}`}
                onHeaderClick={() => {
                  handleOpen(cardIndex, segment);
                }}
                onCardEnter={() => {
                  setSegmentActiveIndex(cardIndex);
                }}
                isOpen={open === cardIndex}
                header={
                  <div>
                    <SegmentCardID>{segment.segmentID}</SegmentCardID>
                    {info.name && open === cardIndex && !infoLoading ? (
                      <SegmentCardName>{info.name}</SegmentCardName>
                    ) : null}
                    {open === cardIndex && infoLoading ? <EmptyTitle /> : null}
                    {hasErrors(segment) ? (
                      <Tooltip>
                        <Tooltip.Trigger asChild>
                          <MappingHeaderIcon name="warning" color="#f6c343" />
                        </Tooltip.Trigger>
                        <Tooltip.Content>Missing mapping for one or more elements</Tooltip.Content>
                      </Tooltip>
                    ) : (
                      <MappingHeaderIcon name="done" color="#00d97e" />
                    )}
                  </div>
                }
                body={
                  <div id="left-tabs-example">
                    <Flex>
                      <Flex.Item>
                        <Flex column>
                          {segment.elements.map((element, elementIndex) => {
                            const formattedKey = formatKey(
                              segment.segmentID,
                              segment.segmentIndex,
                              elementIndex
                            );
                            return element !== '' ? (
                              <ElementID
                                segmentID={segment.segmentID}
                                elementIndex={elementIndex}
                                isSelected={elementSelected === formattedKey}
                                onClick={() => onConfigureMapping(segment, elementIndex, element)}
                                onMouseEnter={() => setElementHovered(formattedKey)}
                                onMouseLeave={() => setElementHovered(null)}
                                key={formattedKey}
                              />
                            ) : null;
                          })}
                        </Flex>
                      </Flex.Item>

                      <Flex.Item grow={1}>
                        {elementSelected && !infoLoading ? (
                          <MappingForm
                            scopeName={scopeName}
                            elementSelected={elementSelected}
                            segment={segment}
                            info={info}
                          />
                        ) : (
                          <div>
                            <EmptyTitle />
                          </div>
                        )}
                      </Flex.Item>
                    </Flex>
                  </div>
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MappingConfiguration;

MappingConfiguration.propTypes = {
  documentNumber: PropTypes.string,
};
