import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import download from 'downloadjs';
import { useEffect, useState } from 'react';
import { getEDIRecords as apiGetEDIRecords } from 'utils/api/edi';
import {
  cancelSellerOrder as apiCancelSellerOrder,
  createFulfillment as apiCreateFulfillment,
  unflagOrder as apiUnflagOrder,
  updateOrder as apiUpdateOrder,
  generateInvoice,
  getOrderById,
  getPackingSlipForFulfillment,
} from 'utils/api/orders';
import { createTestOrder as apiCreateTestOrder } from 'utils/api/testOrders';

const initialState = {
  byId: {}, // hash map of order objects indexed by ID
};

export const getOrder = createAsyncThunk('orders/getOrder', (id) => {
  return getOrderById(id);
});

export const updateOrder = createAsyncThunk('orders/updateOrder', ({ id, updates }) => {
  return apiUpdateOrder(id, updates);
});

export const cancelSellerOrder = createAsyncThunk('orders/cancelSellerOrder', ({ id, data }) => {
  return apiCancelSellerOrder(id, data);
});

export const getFulfillmentPackingSlip = createAsyncThunk(
  'orders/getFulfillmentPackingSlip',
  ({ orderId, fulfillmentId }) => {
    return getPackingSlipForFulfillment(orderId, fulfillmentId).then((data) => {
      download(new Blob([data]), 'packingslip.pdf', 'application/pdf');
      return { success: true };
    });
  }
);

export const createFulfillment = createAsyncThunk(
  'orders/createFulfillment',
  ({ orderId, fulfillment }) => {
    return apiCreateFulfillment(orderId, fulfillment);
  }
);

export const createInvoice = createAsyncThunk('orders/createInvoice', ({ orderId, invoice }) => {
  return generateInvoice(orderId, invoice);
});

export const getEDIRecords = createAsyncThunk('orders/getEDIRecords', ({ orderId }) => {
  return apiGetEDIRecords(orderId);
});

export const unflagOrder = createAsyncThunk('orders/unflag', (orderId) => {
  return apiUnflagOrder(orderId);
});

export const createTestOrder = createAsyncThunk('test-orders/create', (data) => {
  return apiCreateTestOrder(data);
});

const slice = createSlice({
  name: 'orders',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getOrder.fulfilled, (draft, action) => {
      draft.byId[action.payload._id] = action.payload;
    });
    builder.addCase(cancelSellerOrder.fulfilled, (draft, action) => {
      // response from cancel does not include invoice, so merge it with current order to keep invoice in state
      const order = action.payload.data;
      const currentOrder = draft.byId[order._id];
      draft.byId[order._id] = { ...order, invoice: currentOrder?.invoice };
    });
    builder.addCase(updateOrder.fulfilled, (draft, action) => {
      // response from update does not include invoice or shipTime, so merge it with current order to keep invoice in state
      const order = action.payload;
      draft.byId[order._id] = order;
    });
    builder.addCase(createInvoice.fulfilled, (draft, action) => {
      const { orderId } = action.meta.arg;
      const invoice = action.payload.data;
      if (invoice) {
        draft.byId[orderId].invoice = invoice;
        draft.byId[orderId].invoiceId = invoice._id;
      }
    });
    builder.addCase(unflagOrder.fulfilled, (draft, action) => {
      draft.byId[action.payload.data._id] = action.payload.data;
    });
    builder.addCase(createTestOrder.fulfilled, (draft, action) => {
      draft.byId[action.payload.data._id] = action.payload.data;
    });
  },
});

export default slice.reducer;

// Hooks

// called on order detail page
export const useFetchOrder = (dispatch, id) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  useEffect(() => {
    dispatch(getOrder(id)).then((action) => {
      if (action.error) {
        setError(action.error.message);
      }
      setLoading(false);
    });
  }, [dispatch, setError, setLoading, id]);

  return {
    orderLoading: loading,
    orderLoadingError: error,
  };
};
