import styled, { css } from 'styled-components';

import typography, { TypographyVariants } from 'storybook/mixins/typography';
import type { ThemeColor } from 'storybook/stories/theme';
import type { PickEnum } from 'types/general';

type HeadingVariant = `${PickEnum<
  TypographyVariants,
  TypographyVariants.H1 | TypographyVariants.H2 | TypographyVariants.H3 | TypographyVariants.H4
>}`;

export interface HeadingProps {
  /**
   * The heading variant to display. Passed directly to the `typography` helper.
   */
  variant?: HeadingVariant;
  /**
   * Horizontal alignment
   */
  align?: string;
  /**
   * Change the underlying HTML element, for a11y semantics
   */
  as?: string;
  /**
   * Text color
   */
  color?: ThemeColor;
}

/**
 * Renders a heading. This component will try to glean the semantics of the supplied
 * `variant` and render a corresponding H1-H4 tag with proper styling. Override this
 * behavior as needed by using the `as` prop.
 */
const Heading = styled.strong.attrs<HeadingProps>(({ variant = TypographyVariants.H1, as }) => {
  switch (variant) {
    case TypographyVariants.H1:
      return { as: as || 'h1' };
    case TypographyVariants.H2:
      return { as: as || 'h2' };
    case TypographyVariants.H3:
      return { as: as || 'h3' };
    case TypographyVariants.H4:
      return { as: as || 'h4' };
    default:
      return { as: as || 'strong' };
  }
})<HeadingProps>`
  ${({ variant = TypographyVariants.H1 }) => typography(variant)}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ theme, color }) =>
    color &&
    css`
      color: ${theme.color[color]};
    `}

  margin: 0;
`;

export default Heading;
