// https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
export function inIFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function iframeIsFromShopify() {
  if (!window.document.location.ancestorOrigins) {
    return false;
  }
  if (window.document.location.ancestorOrigins.length !== 1) {
    return false;
  }
  const url = window.document.location.ancestorOrigins[0];
  return url.indexOf('my.shopify.com') !== -1;
}
