import { noop } from 'lodash';
import { createContext, useCallback, useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';

export interface FloatingChatContextInitialValue {
  isWindowOpen?: boolean;
}

export interface FloatingChatContextValue extends FloatingChatContextInitialValue {
  isWindowExpanded: boolean;
  isPromptVisible: boolean;
  openWindow: () => void;
  closeWindow: () => void;
  expandWindow: () => void;
  collapseWindow: () => void;
  showPrompt: () => void;
  hidePrompt: () => void;
}

/**
 * This is specifically the context used for the floating chat UI.
 * It's purpose is to allow any component to control the visual presentation
 * from anywhere in the app.
 */
export const FloatingChatContext = createContext<FloatingChatContextValue>({
  isWindowOpen: false,
  isWindowExpanded: false,
  isPromptVisible: false,
  openWindow: noop,
  closeWindow: noop,
  expandWindow: noop,
  collapseWindow: noop,
  showPrompt: noop,
  hidePrompt: noop,
});

interface FloatingChatContextProviderProps {
  children: React.ReactNode;
  initialValue?: FloatingChatContextInitialValue;
}

const FloatingChatContextProvider = ({
  children,
  initialValue = {},
}: FloatingChatContextProviderProps) => {
  const windowVisibility = useBoolean(initialValue.isWindowOpen);
  const promptVisibility = useBoolean(false);
  const windowExpansion = useBoolean(false);

  const closeWindow = useCallback(() => {
    windowVisibility.setFalse();
    windowExpansion.setFalse();
  }, [windowVisibility, windowExpansion]);

  const openWindow = useCallback(() => {
    windowVisibility.setTrue();
    promptVisibility.setFalse();
  }, [windowVisibility, promptVisibility]);

  const value = useMemo(
    () => ({
      isWindowOpen: windowVisibility.value,
      isWindowExpanded: windowExpansion.value,
      isPromptVisible: promptVisibility.value,
      expandWindow: windowExpansion.setTrue,
      collapseWindow: windowExpansion.setFalse,
      showPrompt: promptVisibility.setTrue,
      hidePrompt: promptVisibility.setFalse,
      openWindow,
      closeWindow,
    }),
    [windowVisibility, promptVisibility, windowExpansion, openWindow, closeWindow]
  );

  return <FloatingChatContext.Provider value={value}>{children}</FloatingChatContext.Provider>;
};

export default FloatingChatContextProvider;
