/* eslint-disable no-nested-ternary */
import _isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import UpdatedIndicator from 'components/Common/UpdatedIndicator';
import { getAttributesPatch, shallowDiff } from 'hooks/products/useEditProduct';
import Icon from 'storybook/stories/molecules/Icon';
import { formatPriceWithCurrency } from 'utils/prices';

import Tooltip from 'storybook/stories/cells/Tooltip';
import CardProductValidationAlert from './CardProductValidationAlert';
import './ProductVariants.css';
import SelectedVariantModal from './SelectedVariant/SelectedVariantModal';

const cleanNumField = (parseFn) => (originalVal, editedVal) =>
  // eslint-disable-next-line no-restricted-globals
  isNaN(parseFn(editedVal)) ? originalVal : parseFn(editedVal);

const cleanFloat = cleanNumField(parseFloat);
const cleanInt = cleanNumField(parseInt);

const cleanDimensions = (original = {}, edits = {}) =>
  ['length', 'width', 'height'].reduce(
    (newDim, field) => ({ ...newDim, [field]: cleanFloat(original[field], edits[field]) }),
    {
      units: edits.units || '',
    }
  );

/**
 * Processes the editedVariant and converts the string values from editing back to numbers
 * if the user enters in something that cannot be converted, the values are set back to the originals
 * @returns {Variant} the edited variant with proper fields set to numbers
 */
export const cleanNumberFields = (originalVariant, editedVariant) =>
  Object.keys(editedVariant).reduce(
    (variant, field) =>
      field === 'dimensions'
        ? {
            ...variant,
            dimensions: cleanDimensions(originalVariant.dimensions, editedVariant.dimensions),
          }
        : ['retailPrice', 'weight'].includes(field)
          ? { ...variant, [field]: cleanFloat(originalVariant[field], editedVariant[field]) }
          : field === 'inventoryQuantity'
            ? { ...variant, [field]: cleanInt(originalVariant[field], editedVariant[field]) }
            : { ...variant, [field]: editedVariant[field] },
    {}
  );

export const getVariantPatch = (variantMap = {}, editMap = {}) => {
  return Object.keys(editMap).reduce((result, variantId) => {
    const editVariant = editMap[variantId];
    // todo: handle adding new variants
    //
    const originalVariant = variantMap[variantId];
    // if it is not in the original variants, skip it
    if (!originalVariant) return result;
    const diff = shallowDiff(originalVariant, editVariant);
    // if attributes has been changed, flatten into patch array
    if (diff.attributes) {
      const attrPatch = getAttributesPatch(
        // need to merge attributes back into original object for comparison
        originalVariant.attributes.reduce(
          (accumulator, attr) => ({ ...accumulator, [attr.name]: { value: attr.value } }),
          {}
        ),
        // stays as edits array
        editVariant.attributes
      );
      if (!_isEmpty(attrPatch)) {
        diff.attributes = attrPatch;
      } else {
        delete diff.attributes;
      }
    }
    // if diff is empty, return without pushing onto array
    if (_isEmpty(diff)) return result;
    result.push({ ...diff, _id: variantId });
    return result;
  }, []);
};

// creates a hashmap of variants and also turns attributes into a hashmap
export const createVariantMap = (variants = []) =>
  variants
    ? variants.reduce(
        (map, v) => ({
          ...map,
          [v._id]: {
            ...v,
            // convert attributes into a list for easy list editing (addition, removal, empty names, etc)
            // note this will happen for the variantMap and editMap, _isEqual() is used to determine if they have been edited
            attributes: Object.keys(v.attributes || {}).map((name) => ({
              name,
              value: v.attributes[name].value,
              id: uuidv4(),
            })),
          },
        }),
        {}
      )
    : {};

const VariantRow = ({ variant, onRowClick, isSeller, productPlatform, hasValidationErrors }) => {
  const rowStyle = hasValidationErrors ? { background: '#fff8e7' } : {};

  return (
    <tr onClick={onRowClick} style={rowStyle}>
      <td>
        <p className="mb-0" style={{ maxWidth: '12rem', whiteSpace: 'break-spaces' }}>
          {variant.title || 'No Title'}
        </p>
        <p className="mb-0 text-muted" style={{ maxWidth: '12rem', whiteSpace: 'break-spaces' }}>
          {variant.sku ? `SKU: ${variant.sku}` : 'No SKU'}
        </p>
      </td>
      <td>
        <p className="mb-0">{variant.barcode || 'No Barcode'}</p>
        {variant.barcodeType ? <p className="mb-0 text-muted">{variant.barcodeType}</p> : null}
      </td>
      <td>
        {variant.skipCount ? (
          productPlatform === 'shopify' ? (
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Icon name="help" position="after">
                  Not Tracked
                </Icon>
              </Tooltip.Trigger>
              <Tooltip.Content>
                Expecting to see an inventory number? If your Seller has inventory marked
                &apos;Continue selling when out of stock&apos;, Modern Dropship will not track their
                inventory, but continue allowing orders for the item.
              </Tooltip.Content>
            </Tooltip>
          ) : (
            'Not Tracked'
          )
        ) : (
          variant.inventoryQuantity
        )}
      </td>
      {!isSeller && <td>{formatPriceWithCurrency(variant.basePrice)}</td>}
      <td>{formatPriceWithCurrency(variant.retailPrice)}</td>
    </tr>
  );
};

const Wrapper = styled.div`
  border: 1px solid transparent;

  ${({ theme, hasErrors }) =>
    hasErrors &&
    css`
      border-color: ${theme.color.warning500};
    `}
`;

const ProductVariants = ({
  productId,
  variants = [],
  canEdit,
  onEditVariants,
  clearEditsAt,
  hasEdits,
  isSeller,
  productPlatform,
  options = [],
  validationErrorMapById = {},
}) => {
  // create a map of variant values holding original value (easy reference to compare)
  const [variantMap, setVariantMap] = useState(() => createVariantMap(variants));
  // create an editable map of variant values (work in progress)
  const [editMap, setEditMap] = useState(() => ({ ...variantMap }));

  // create list of IDs (work in progress id list)
  const [variantIds, setVariantIds] = useState(() => variants.map((variant) => variant._id));
  // hold copy of the last clearEditsAt to compare when it changes (ignores other dependencies)
  const clearEditsAtRef = useRef(clearEditsAt);
  // when selected, the variant modal will show
  const [selectedVariantId, setSelectedVariantId] = useState(null);

  const numVariantsWithErrors = Object.keys(validationErrorMapById).length;

  useEffect(() => {
    // used to reset local edits only when the parent has specifically said to do so
    // reset back to product.variants values
    if (clearEditsAtRef.current !== clearEditsAt) {
      setVariantIds(variants.map((variant) => variant._id));
      const newVariantMap = createVariantMap(variants);
      setVariantMap(newVariantMap);
      setEditMap({ ...newVariantMap });
      clearEditsAtRef.current = clearEditsAt;
    }
  }, [clearEditsAt, variants]);

  // will replace a root level field for a particular variant
  const onChangeVariantField = (variantId, field, value) => {
    const newEditMap = { ...editMap, [variantId]: { ...editMap[variantId], [field]: value } };
    setEditMap(newEditMap);
  };

  const onDoneEditing = () => {
    setSelectedVariantId(null);
    if (isSeller) {
      const editedVariant = editMap[selectedVariantId];
      const originalVariant = variantMap[selectedVariantId];
      const newEditMap = {
        ...editMap,
        [selectedVariantId]: cleanNumberFields(originalVariant, editedVariant),
      };
      setEditMap(newEditMap);
      const patch = getVariantPatch(variantMap, newEditMap);
      onEditVariants(patch);
    }
  };

  return (
    <Wrapper className="card overflow-auto" hasErrors={numVariantsWithErrors > 0}>
      <div className="d-flex justify-content-between align-items-center p-4">
        <h4 className="mb-0">Variants {hasEdits ? <UpdatedIndicator className="ml-1" /> : null}</h4>
      </div>
      {variantIds.length === 0 ? (
        <div className="card-body pt-0">
          <p className="mb-2 text-muted">This product does not have any variants</p>
        </div>
      ) : (
        <table className="table table-sm table-hover table-nowrap VariantsTable mb-0">
          {variantIds.length > 0 && (
            <thead>
              <tr>
                <th>Title / SKU</th>
                <th>Barcode</th>
                <th>Quantity</th>
                {!isSeller && <th>What You Pay</th>}
                {isSeller ? <th>Your Retail Price</th> : <th>Suggested Retail Price</th>}
              </tr>
            </thead>
          )}
          <tbody className="list">
            {variantIds.map((varId, idx) => (
              <VariantRow
                key={varId}
                variant={editMap[varId]}
                index={idx}
                onRowClick={() => setSelectedVariantId(varId)}
                isSeller={isSeller}
                productPlatform={productPlatform}
                hasValidationErrors={!!validationErrorMapById[varId]}
              />
            ))}
          </tbody>
        </table>
      )}

      {numVariantsWithErrors > 0 && (
        <CardProductValidationAlert
          numVariantsWithErrors={numVariantsWithErrors}
          className="mb-2 mx-3"
        />
      )}

      <SelectedVariantModal
        show={!!selectedVariantId}
        variant={selectedVariantId ? editMap[selectedVariantId] : {}}
        originalVariant={selectedVariantId ? variantMap[selectedVariantId] : {}}
        onDismiss={onDoneEditing}
        canEdit={canEdit}
        options={options}
        onChangeVariantField={onChangeVariantField}
        variantId={selectedVariantId}
        isSeller={isSeller}
        productId={productId}
        validationErrorMap={validationErrorMapById[selectedVariantId]}
      />
    </Wrapper>
  );
};

export default ProductVariants;
