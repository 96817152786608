import { createSelector } from '@reduxjs/toolkit';

import { selectMe } from 'store/selectors/me';
import { CommerceType, Platform } from 'types/models/company';

/**
 * Company
 */

export const selectCompany = createSelector(selectMe, (me) => me.company);

export const selectCompanyId = createSelector(selectCompany, (company) => company?._id);

export const selectIsGetStartedComplete = createSelector(
  selectCompany,
  (company) => !!company?.gettingStarted?.allStepsCompleted
);

export const selectShopifyPermissions = createSelector(
  selectCompany,
  (company) => company?.shopify?.permissions ?? ''
);

/**
 * Product Platform
 */

export const selectProductPlatform = createSelector(
  selectCompany,
  (company) => company?.productPlatform
);

export const selectHasProductPlatform = createSelector(
  selectProductPlatform,
  (platform) => !!platform
);

export const selectIsProductPlatformOther = createSelector(
  selectProductPlatform,
  (platform) => platform === Platform.Other
);

export const selectIsProductPlatformShopify = createSelector(
  selectProductPlatform,
  (platform) => platform === Platform.Shopify
);

export const selectIsProductPlatformMagento2 = createSelector(
  selectProductPlatform,
  (platform) => platform === Platform.Magento2
);

export const selectIsProductPlatformWooCommerce = createSelector(
  selectProductPlatform,
  (platform) => platform === Platform.WooCommerce
);

export const selectIsProductPlatformBigCommerce = createSelector(
  selectProductPlatform,
  (platform) => platform === Platform.BigCommerce
);

export const selectIsProductPlatformInternalSFTP = createSelector(
  selectProductPlatform,
  (platform) => platform === Platform.CsvSftp || platform === Platform.EdiSftp
);

export const selectIsProductPlatformExternalSFTP = createSelector(
  selectProductPlatform,
  (platform) => platform === Platform.ExternalSftp || platform === Platform.EdiExternalSftp
);

export const selectIsProductPlatformEdi = createSelector(
  selectProductPlatform,
  (platform) => platform === Platform.EdiSftp || platform === Platform.EdiExternalSftp
);

/**
 * Order Platform
 */

export const selectOrderPlatform = createSelector(
  selectCompany,
  (company) => company?.orderPlatform
);

export const selectIsOrderPlatformOther = createSelector(
  selectOrderPlatform,
  (platform) => platform === Platform.Other
);

export const selectIsOrderPlatformShopify = createSelector(
  selectOrderPlatform,
  (platform) => platform === Platform.Shopify
);

export const selectIsOrderPlatformEdi = createSelector(
  selectOrderPlatform,
  (platform) => platform === Platform.EdiSftp || platform === Platform.EdiExternalSftp
);

/**
 * Commerce Type
 */

export const selectCommerceType = createSelector(selectCompany, (company) => company?.commerceType);

export const selectIsBuyer = createSelector(
  selectCommerceType,
  (commerceType) => commerceType === CommerceType.Buyer
);

export const selectIsSeller = createSelector(
  selectCommerceType,
  (commerceType) => commerceType === CommerceType.Seller
);

export const selectHasCommerceType = createSelector(
  selectCommerceType,
  (commerceType) => !!commerceType
);

/**
 * Helper Selectors
 */

export const selectIsIntegratedWithShopify = createSelector(
  selectIsProductPlatformShopify,
  selectIsOrderPlatformShopify,
  (...platforms) => platforms.some(Boolean)
);

export const selectIsProductPlatformSFTP = createSelector(
  selectIsProductPlatformInternalSFTP,
  selectIsProductPlatformExternalSFTP,
  (...platforms) => platforms.some(Boolean)
);

export const selectIsProductPlatformSyncEnabled = createSelector(
  selectIsProductPlatformShopify,
  selectIsProductPlatformMagento2,
  (...platforms) => platforms.some(Boolean)
);

export const selectIsProductPlatformSelectEnabled = createSelector(
  selectIsProductPlatformOther,
  selectIsProductPlatformShopify,
  selectIsProductPlatformMagento2,
  (...platforms) => platforms.some(Boolean)
);

export const selectIsApiBuyer = createSelector(
  selectIsBuyer,
  selectIsProductPlatformOther,
  (isBuyer, isProductPlatformOther) => isBuyer && isProductPlatformOther
);

export const selectIsDropshipSetup = createSelector(
  selectHasCommerceType,
  selectHasProductPlatform,
  (hasCommerceType, hasProductPlatform) => hasCommerceType && hasProductPlatform
);

export const selectHasRequiredShopifyPermissions = createSelector(
  selectShopifyPermissions,
  (currentPermissions) => {
    const requiredPermissions = [
      'write_assigned_fulfillment_orders',
      'write_third_party_fulfillment_orders',
    ];

    return requiredPermissions.every((permission) => currentPermissions.includes(permission));
  }
);

export const selectIsAutonomousMerchandisingAvailable = createSelector(
  selectIsBuyer,
  selectProductPlatform,
  (isBuyer, productPlatform) =>
    isBuyer &&
    productPlatform &&
    [Platform.Shopify, Platform.Magento2, Platform.Other].includes(productPlatform)
);
