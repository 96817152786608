import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import type { Options, SingleValue } from 'react-select';
import Body from 'storybook/stories/molecules/Body';
import Label from 'storybook/stories/molecules/Label';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import Select from 'storybook/stories/molecules/Select';
import Toggle from 'storybook/stories/molecules/Toggle';
import type { ReactSelectOption } from 'types/general';
import type { Partner } from 'types/models/partner';
import type { UpdatePartnerParams } from 'utils/api/partners';
import { getPriceLists } from 'utils/api/priceLists';

interface SellerDetailsProps {
  partner: Partner;
  updatePartner: (body: UpdatePartnerParams) => void;
}

const SellerDetails = ({ partner, updatePartner }: SellerDetailsProps) => {
  const [autoAcceptReturns, setAutoAcceptReturns] = useState(partner.autoAcceptReturns);
  const [currentPriceList, setCurrentPriceList] = useState(partner.pricingId ?? '');

  const fetchingPriceLists = useQuery({
    queryKey: ['fetchingPriceLists'],
    queryFn: () => getPriceLists({ page: 0, limit: 250 }),
  });

  // Helpers

  const priceLists = fetchingPriceLists.data?.data ?? [];

  const priceListOptions: Options<ReactSelectOption> = priceLists.map((priceList) => ({
    value: priceList.id,
    label: priceList.name,
  }));

  const selectedPriceListOption = priceListOptions.find(
    (option) => option.value === currentPriceList
  );

  // Event Handlers

  const onAutoAcceptReturnsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setAutoAcceptReturns(checked);
    updatePartner({ autoAcceptReturns: checked });
  };

  const onPriceListChange = (option: SingleValue<ReactSelectOption>) => {
    const newPriceList = option?.value ?? '';
    setCurrentPriceList(newPriceList);
    updatePartner({ pricingId: newPriceList });
  };

  // Render

  return (
    <>
      <Flex gap="8px" alignItemsStart>
        <Toggle
          name="autoAcceptReturns"
          onChange={onAutoAcceptReturnsChange}
          checked={autoAcceptReturns}
          description={
            <span>
              Returns {autoAcceptReturns ? 'will' : 'will not'} be automatically accepted.{' '}
              <SupportLink article="vu6kftk7za">Learn more</SupportLink>
            </span>
          }
        >
          Accept Returns Automatically
        </Toggle>
      </Flex>

      <Flex column gap="4px">
        <Label htmlFor="priceList">Assigned Price List</Label>

        {selectedPriceListOption ? (
          <Select
            inputId="priceList"
            name="priceList"
            options={priceListOptions}
            value={selectedPriceListOption}
            onChange={onPriceListChange}
          />
        ) : (
          <span>
            Price lists are required to transact with a partner. Don&apos;t have a price list ready
            for this partner? <Link to="/prices/create">Create a new price list here.</Link>
          </span>
        )}
      </Flex>

      <Flex column gap="4px">
        <Body variant="Body/Header" color="bodyTextSecondary">
          Shipping Label Source
        </Body>

        {!partner.shippingSettings?.shippingLabelOwner && 'Unknown Provider'}

        {partner.shippingSettings?.shippingLabelOwner === 'buyer' &&
          `Provided by ${partner.buyerName}`}

        {partner.shippingSettings?.shippingLabelOwner === 'seller' &&
          `Provided by ${partner.sellerName}`}
      </Flex>

      <Flex column gap="4px">
        <Label htmlFor="maxShipTime">Expected Maximum Ship Time</Label>

        <Body variant="Body/Body Small">
          {partner.effectiveMaxShipTime > 0 ? `${partner.effectiveMaxShipTime} hours` : '--'}
        </Body>
      </Flex>

      <Flex column gap="4px">
        <Label htmlFor="maxReturnRate">Expected Maximum Return Rate</Label>

        <Body variant="Body/Body Small">
          {partner.effectiveMaxReturnRate > 0 ? `${partner.effectiveMaxReturnRate}%` : '--'}
        </Body>
      </Flex>
    </>
  );
};

export default SellerDetails;
