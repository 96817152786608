import Flex from '@react-css/flex';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

const CreatePriceListCard = ({ done, disabled }) => {
  return (
    <GetStartedCard
      title="Create and Assign Price List"
      isComplete={done}
      completedUi={
        <Body>
          Manage your price lists from the <Link to="/prices">Prices page</Link>.
        </Body>
      }
    >
      {disabled ? (
        <Body as="p">
          You need to activate products before you can create price lists.{' '}
          <SupportLink article="ia2nwgi8be">More Info</SupportLink>
        </Body>
      ) : (
        <Flex column gap="16px">
          <Body as="p">
            You will need to create a price list for your products and assign it to a buyer before
            they can be sold. <SupportLink article="ia2nwgi8be">More Info</SupportLink>
          </Body>

          <Flex.Item alignSelfEnd>
            <PrimaryButton size="small" as={Link} to="/prices">
              Go To Prices
            </PrimaryButton>
          </Flex.Item>
        </Flex>
      )}
    </GetStartedCard>
  );
};

export default CreatePriceListCard;
