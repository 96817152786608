import { CHAT_LOADING_SIZE } from 'storybook/stories/species/Chat/ChatLoading';
import styled from 'styled-components';

const ChatBody = styled.div`
  position: relative;
  padding: 16px 16px 8px;
  overflow-y: auto;
  min-height: ${CHAT_LOADING_SIZE}px;
`;

export default ChatBody;
