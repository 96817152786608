import { map } from 'lodash';
import type { Currencies, Currency, Money } from 'types/general';

// CURRENCIES holds supported currencies
const CURRENCIES: Currencies = {
  CAD: {
    currency: 'Canadian Dollar',
    code: 'CAD',
    symbol: 'CA$',
    flag: '\ud83c\udde8\ud83c\udde6',
  },
  USD: {
    currency: 'United States Dollar',
    code: 'USD',
    symbol: '$',
    flag: '\ud83c\uddfa\ud83c\uddf8',
  },
  EUR: {
    currency: 'Euro',
    code: 'EUR',
    symbol: '\u20ac',
    flag: '\ud83c\uddea\ud83c\uddfa',
  },
  GBP: {
    currency: 'British Pound',
    code: 'GBP',
    symbol: '\u00a3',
    flag: '\ud83c\uddec\ud83c\udde7',
  },
  AUD: {
    currency: 'Australian Dollar',
    code: 'AUD',
    symbol: 'AU$',
    flag: '\ud83c\udde6\ud83c\uddfa',
  },
  AED: {
    currency: 'United Arab Emirates Dirham',
    code: 'AED',
    symbol: 'AED',
    flag: '\ud83c\udde6\ud83c\uddea',
  },
  AFN: {
    currency: 'Afghan Afghani',
    code: 'AFN',
    symbol: 'Af',
    flag: '\ud83c\udde6\ud83c\uddeb',
  },
  ALL: {
    currency: 'Albanian Lek',
    code: 'ALL',
    symbol: 'ALL',
    flag: '\ud83c\udde6\ud83c\uddf1',
  },
  AMD: {
    currency: 'Armenian Dram',
    code: 'AMD',
    symbol: 'AMD',
    flag: '\ud83c\udde6\ud83c\uddf2',
  },
  ANG: {
    currency: 'Netherlands Antillean Gulden',
    code: 'ANG',
    symbol: 'NA\u0192',
    flag: '\ud83c\udde8\ud83c\uddfc',
  },
  AOA: {
    currency: 'Angolan Kwanza',
    code: 'AOA',
    symbol: 'Kz',
    flag: '\ud83c\udde6\ud83c\uddf4',
  },
  ARS: {
    currency: 'Argentine Peso',
    code: 'ARS',
    symbol: 'AR$',
    flag: '\ud83c\udde6\ud83c\uddf7',
  },
  AWG: {
    currency: 'Aruban Florin',
    code: 'AWG',
    symbol: 'Afl',
    flag: '\ud83c\udde6\ud83c\uddfc',
  },
  AZN: {
    currency: 'Azerbaijani Manat',
    code: 'AZN',
    symbol: 'man.',
    flag: '\ud83c\udde6\ud83c\uddff',
  },
  BAM: {
    currency: 'Bosnia & Herzegovina Convertible Mark',
    code: 'BAM',
    symbol: 'KM',
    flag: '\ud83c\udde7\ud83c\udde6',
  },
  BBD: {
    currency: 'Barbadian Dollar',
    code: 'BBD',
    symbol: 'BB$',
    flag: '\ud83c\udde7\ud83c\udde7',
  },
  BDT: {
    currency: 'Bangladeshi Taka',
    code: 'BDT',
    symbol: 'Tk',
    flag: '\ud83c\udde7\ud83c\udde9',
  },
  BGN: {
    currency: 'Bulgarian Lev',
    code: 'BGN',
    symbol: 'BGN',
    flag: '\ud83c\udde7\ud83c\uddec',
  },
  BMD: {
    currency: 'Bermudian Dollar',
    code: 'BMD',
    symbol: 'BM$',
    flag: '\ud83c\udde7\ud83c\uddf2',
  },
  BND: {
    currency: 'Brunei Dollar',
    code: 'BND',
    symbol: 'BN$',
    flag: '\ud83c\udde7\ud83c\uddf3',
  },
  BOB: {
    currency: 'Bolivian Boliviano',
    code: 'BOB',
    symbol: 'Bs',
    flag: '\ud83c\udde7\ud83c\uddf4',
  },
  BRL: {
    currency: 'Brazilian Real',
    code: 'BRL',
    symbol: 'R$',
    flag: '\ud83c\udde7\ud83c\uddf7',
  },
  BSD: {
    currency: 'Bahamian Dollar',
    code: 'BSD',
    symbol: 'B$',
    flag: '\ud83c\udde7\ud83c\uddf8',
  },
  BWP: {
    currency: 'Botswana Pula',
    code: 'BWP',
    symbol: 'BWP',
    flag: '\ud83c\udde7\ud83c\uddfc',
  },
  BZD: {
    currency: 'Belize Dollar',
    code: 'BZD',
    symbol: 'BZ$',
    flag: '\ud83c\udde7\ud83c\uddff',
  },
  CDF: {
    currency: 'Congolese Franc',
    code: 'CDF',
    symbol: 'CDF',
    flag: '\ud83c\udde8\ud83c\udde9',
  },
  CHF: {
    currency: 'Swiss Franc',
    code: 'CHF',
    symbol: 'CHF',
    flag: '\ud83c\udde8\ud83c\udded',
  },
  CNY: {
    currency: 'Chinese Renminbi Yuan',
    code: 'CNY',
    symbol: 'CN\u00a5',
    flag: '\ud83c\udde8\ud83c\uddf3',
  },
  COP: {
    currency: 'Colombian Peso',
    code: 'COP',
    symbol: 'CO$',
    flag: '\ud83c\udde8\ud83c\uddf4',
  },
  CRC: {
    currency: 'Costa Rican Col\u00f3n',
    code: 'CRC',
    symbol: '\u20a1',
    flag: '\ud83c\udde8\ud83c\uddf7',
  },
  CVE: {
    currency: 'Cape Verdean Escudo',
    code: 'CVE',
    symbol: 'CV$',
    flag: '\ud83c\udde8\ud83c\uddfb',
  },
  CZK: {
    currency: 'Czech Koruna',
    code: 'CZK',
    symbol: 'K\u010d',
    flag: '\ud83c\udde8\ud83c\uddff',
  },
  DKK: {
    currency: 'Danish Krone',
    code: 'DKK',
    symbol: 'Dkr',
    flag: '\ud83c\udde9\ud83c\uddf0',
  },
  DOP: {
    currency: 'Dominican Peso',
    code: 'DOP',
    symbol: 'RD$',
    flag: '\ud83c\udde9\ud83c\uddf4',
  },
  DZD: {
    currency: 'Algerian Dinar',
    code: 'DZD',
    symbol: 'DA',
    flag: '\ud83c\udde9\ud83c\uddff',
  },
  EGP: {
    currency: 'Egyptian Pound',
    code: 'EGP',
    symbol: 'EGP',
    flag: '\ud83c\uddea\ud83c\uddec',
  },
  ETB: {
    currency: 'Ethiopian Birr',
    code: 'ETB',
    symbol: 'Br',
    flag: '\ud83c\uddea\ud83c\uddf9',
  },
  FJD: {
    currency: 'Fijian Dollar',
    code: 'FJD',
    symbol: 'FJ$',
    flag: '\ud83c\uddeb\ud83c\uddef',
  },
  FKP: {
    currency: 'Falkland Islands Pound',
    code: 'FKP',
    symbol: 'FK\u00a3',
    flag: '\ud83c\uddeb\ud83c\uddf0',
  },
  GEL: {
    currency: 'Georgian Lari',
    code: 'GEL',
    symbol: 'GEL',
    flag: '\ud83c\uddec\ud83c\uddea',
  },
  GIP: {
    currency: 'Gibraltar Pound',
    code: 'GIP',
    symbol: 'GI\u00a3',
    flag: '\ud83c\uddec\ud83c\uddee',
  },
  GMD: {
    currency: 'Gambian Dalasi',
    code: 'GMD',
    symbol: 'D',
    flag: '\ud83c\uddec\ud83c\uddf2',
  },
  GTQ: {
    currency: 'Guatemalan Quetzal',
    code: 'GTQ',
    symbol: 'GTQ',
    flag: '\ud83c\uddec\ud83c\uddf9',
  },
  GYD: {
    currency: 'Guyanese Dollar',
    code: 'GYD',
    symbol: 'GY$',
    flag: '\ud83c\uddec\ud83c\uddfe',
  },
  HKD: {
    currency: 'Hong Kong Dollar',
    code: 'HKD',
    symbol: 'HK$',
    flag: '\ud83c\udded\ud83c\uddf0',
  },
  HNL: {
    currency: 'Honduran Lempira',
    code: 'HNL',
    symbol: 'HNL',
    flag: '\ud83c\udded\ud83c\uddf3',
  },
  HRK: {
    currency: 'Croatian Kuna',
    code: 'HRK',
    symbol: 'kn',
    flag: '\ud83c\udded\ud83c\uddf7',
  },
  HTG: {
    currency: 'Haitian Gourde',
    code: 'HTG',
    symbol: 'G',
    flag: '\ud83c\udded\ud83c\uddf9',
  },
  IDR: {
    currency: 'Indonesian Rupiah',
    code: 'IDR',
    symbol: 'Rp',
    flag: '\ud83c\uddee\ud83c\udde9',
  },
  ILS: {
    currency: 'Israeli New Sheqel',
    code: 'ILS',
    symbol: '\u20aa',
    flag: '\ud83c\uddee\ud83c\uddf1',
  },
  INR: {
    currency: 'Indian Rupee',
    code: 'INR',
    symbol: 'Rs',
    flag: '\ud83c\uddee\ud83c\uddf3',
  },
  JMD: {
    currency: 'Jamaican Dollar',
    code: 'JMD',
    symbol: 'J$',
    flag: '\ud83c\uddef\ud83c\uddf2',
  },
  KES: {
    currency: 'Kenyan Shilling',
    code: 'KES',
    symbol: 'Ksh',
    flag: '\ud83c\uddf0\ud83c\uddea',
  },
  KGS: {
    currency: 'Kyrgyzstani Som',
    code: 'KGS',
    symbol: '\u20c0',
    flag: '\ud83c\uddf0\ud83c\uddec',
  },
  KHR: {
    currency: 'Cambodian Riel',
    code: 'KHR',
    symbol: 'KHR',
    flag: '\ud83c\uddf0\ud83c\udded',
  },
  KYD: {
    currency: 'Cayman Islands Dollar',
    code: 'KYD',
    symbol: 'CI$',
    flag: '\ud83c\uddf0\ud83c\uddfe',
  },
  KZT: {
    currency: 'Kazakhstani Tenge',
    code: 'KZT',
    symbol: 'KZT',
    flag: '\ud83c\uddf0\ud83c\uddff',
  },
  LAK: {
    currency: 'Lao Kip',
    code: 'LAK',
    symbol: '\u20ad',
    flag: '\ud83c\uddf1\ud83c\udde6',
  },
  LBP: {
    currency: 'Lebanese Pound',
    code: 'LBP',
    symbol: 'L.L.',
    flag: '\ud83c\uddf1\ud83c\udde7',
  },
  LKR: {
    currency: 'Sri Lankan Rupee',
    code: 'LKR',
    symbol: 'SLRs',
    flag: '\ud83c\uddf1\ud83c\uddf0',
  },
  LRD: {
    currency: 'Liberian Dollar',
    code: 'LRD',
    symbol: 'L$',
    flag: '\ud83c\uddf1\ud83c\uddf7',
  },
  LSL: {
    currency: 'Lesotho Loti',
    code: 'LSL',
    symbol: 'L',
    flag: '\ud83c\uddf1\ud83c\uddf8',
  },
  MAD: {
    currency: 'Moroccan Dirham',
    code: 'MAD',
    symbol: 'MAD',
    flag: '\ud83c\uddf2\ud83c\udde6',
  },
  MDL: {
    currency: 'Moldovan Leu',
    code: 'MDL',
    symbol: 'MDL',
    flag: '\ud83c\uddf2\ud83c\udde9',
  },
  MKD: {
    currency: 'Macedonian Denar',
    code: 'MKD',
    symbol: 'MKD',
    flag: '\ud83c\uddf2\ud83c\uddf0',
  },
  MMK: {
    currency: 'Myanmar Kyat',
    code: 'MMK',
    symbol: 'MMK',
    flag: '\ud83c\uddf2\ud83c\uddf2',
  },
  MNT: {
    currency: 'Mongolian T\u00f6gr\u00f6g',
    code: 'MNT',
    symbol: '\u20ae',
    flag: '\ud83c\uddf2\ud83c\uddf3',
  },
  MOP: {
    currency: 'Macanese Pataca',
    code: 'MOP',
    symbol: 'MOP$',
    flag: '\ud83c\uddf2\ud83c\uddf4',
  },
  MRO: {
    currency: 'Mauritanian Ouguiya',
    code: 'MRO',
    symbol: 'UM',
    flag: '\ud83c\uddf2\ud83c\uddf7',
  },
  MUR: {
    currency: 'Mauritian Rupee',
    code: 'MUR',
    symbol: 'MURs',
    flag: '\ud83c\uddf2\ud83c\uddfa',
  },
  MVR: {
    currency: 'Maldivian Rufiyaa',
    code: 'MVR',
    symbol: 'Rf',
    flag: '\ud83c\uddf2\ud83c\uddfb',
  },
  MWK: {
    currency: 'Malawian Kwacha',
    code: 'MWK',
    symbol: 'K',
    flag: '\ud83c\uddf2\ud83c\uddfc',
  },
  MXN: {
    currency: 'Mexican Peso',
    code: 'MXN',
    symbol: 'MX$',
    flag: '\ud83c\uddf2\ud83c\uddfd',
  },
  MYR: {
    currency: 'Malaysian Ringgit',
    code: 'MYR',
    symbol: 'RM',
    flag: '\ud83c\uddf2\ud83c\uddfe',
  },
  MZN: {
    currency: 'Mozambican Metical',
    code: 'MZN',
    symbol: 'MTn',
    flag: '\ud83c\uddf2\ud83c\uddff',
  },
  NAD: {
    currency: 'Namibian Dollar',
    code: 'NAD',
    symbol: 'N$',
    flag: '\ud83c\uddf3\ud83c\udde6',
  },
  NGN: {
    currency: 'Nigerian Naira',
    code: 'NGN',
    symbol: '\u20a6',
    flag: '\ud83c\uddf3\ud83c\uddec',
  },
  NIO: {
    currency: 'Nicaraguan C\u00f3rdoba',
    code: 'NIO',
    symbol: 'C$',
    flag: '\ud83c\uddf3\ud83c\uddee',
  },
  NOK: {
    currency: 'Norwegian Krone',
    code: 'NOK',
    symbol: 'Nkr',
    flag: '\ud83c\uddf3\ud83c\uddf4',
  },
  NPR: {
    currency: 'Nepalese Rupee',
    code: 'NPR',
    symbol: 'NPRs',
    flag: '\ud83c\uddf3\ud83c\uddf5',
  },
  NZD: {
    currency: 'New Zealand Dollar',
    code: 'NZD',
    symbol: 'NZ$',
    flag: '\ud83c\uddf3\ud83c\uddff',
  },
  PAB: {
    currency: 'Panamanian Balboa',
    code: 'PAB',
    symbol: 'B/.',
    flag: '\ud83c\uddf5\ud83c\udde6',
  },
  PEN: {
    currency: 'Peruvian Nuevo Sol',
    code: 'PEN',
    symbol: 'S/.',
    flag: '\ud83c\uddf5\ud83c\uddea',
  },
  PGK: {
    currency: 'Papua New Guinean Kina',
    code: 'PGK',
    symbol: 'K',
    flag: '\ud83c\uddf5\ud83c\uddec',
  },
  PHP: {
    currency: 'Philippine Peso',
    code: 'PHP',
    symbol: '\u20b1',
    flag: '\ud83c\uddf5\ud83c\udded',
  },
  PKR: {
    currency: 'Pakistani Rupee',
    code: 'PKR',
    symbol: 'PKRs',
    flag: '\ud83c\uddf5\ud83c\uddf0',
  },
  PLN: {
    currency: 'Polish Z\u0142oty',
    code: 'PLN',
    symbol: 'z\u0142',
    flag: '\ud83c\uddf5\ud83c\uddf1',
  },
  QAR: {
    currency: 'Qatari Riyal',
    code: 'QAR',
    symbol: 'QR',
    flag: '\ud83c\uddf6\ud83c\udde6',
  },
  RON: {
    currency: 'Romanian Leu',
    code: 'RON',
    symbol: 'RON',
    flag: '\ud83c\uddf7\ud83c\uddf4',
  },
  RSD: {
    currency: 'Serbian Dinar',
    code: 'RSD',
    symbol: 'din.',
    flag: '\ud83c\uddf7\ud83c\uddf8',
  },
  RUB: {
    currency: 'Russian Ruble',
    code: 'RUB',
    symbol: 'RUB',
    flag: '\ud83c\uddf7\ud83c\uddfa',
  },
  SAR: {
    currency: 'Saudi Riyal',
    code: 'SAR',
    symbol: 'SR',
    flag: '\ud83c\uddf8\ud83c\udde6',
  },
  SBD: {
    currency: 'Solomon Islands Dollar',
    code: 'SBD',
    symbol: 'SI$',
    flag: '\ud83c\uddf8\ud83c\udde7',
  },
  SCR: {
    currency: 'Seychellois Rupee',
    code: 'SCR',
    symbol: 'Re',
    flag: '\ud83c\uddf8\ud83c\udde8',
  },
  SEK: {
    currency: 'Swedish Krona',
    code: 'SEK',
    symbol: 'Skr',
    flag: '\ud83c\uddf8\ud83c\uddea',
  },
  SGD: {
    currency: 'Singapore Dollar',
    code: 'SGD',
    symbol: 'S$',
    flag: '\ud83c\uddf8\ud83c\uddec',
  },
  SHP: {
    currency: 'Saint Helenian Pound',
    code: 'SHP',
    symbol: 'SH\u00a3',
    flag: '\ud83c\uddf8\ud83c\udded',
  },
  SLL: {
    currency: 'Sierra Leonean Leone',
    code: 'SLL',
    symbol: 'Le',
    flag: '\ud83c\uddf8\ud83c\uddf1',
  },
  SOS: {
    currency: 'Somali Shilling',
    code: 'SOS',
    symbol: 'Ssh',
    flag: '\ud83c\uddf8\ud83c\uddf4',
  },
  SRD: {
    currency: 'Surinamese Dollar',
    code: 'SRD',
    symbol: 'Sur$',
    flag: '\ud83c\uddf8\ud83c\uddf7',
  },
  STD: {
    currency: 'S\u00e3o Tom\u00e9 and Pr\u00edncipe Dobra',
    code: 'STD',
    symbol: 'Db',
    flag: '\ud83c\uddf8\ud83c\uddf9',
  },
  SVC: {
    currency: 'Salvadoran Col\u00f3n',
    code: 'SVC',
    symbol: '\u20a1',
    flag: '\ud83c\uddf8\ud83c\uddfb',
  },
  SZL: {
    currency: 'Swazi Lilangeni',
    code: 'SZL',
    symbol: 'L',
    flag: '\ud83c\uddf8\ud83c\uddff',
  },
  THB: {
    currency: 'Thai Baht',
    code: 'THB',
    symbol: '\u0e3f',
    flag: '\ud83c\uddf9\ud83c\udded',
  },
  TJS: {
    currency: 'Tajikistani Somoni',
    code: 'TJS',
    symbol: 'SM',
    flag: '\ud83c\uddf9\ud83c\uddef',
  },
  TOP: {
    currency: 'Tongan Pa\u02bbanga',
    code: 'TOP',
    symbol: 'T$',
    flag: '\ud83c\uddf9\ud83c\uddf4',
  },
  TRY: {
    currency: 'Turkish Lira',
    code: 'TRY',
    symbol: 'TL',
    flag: '\ud83c\uddf9\ud83c\uddf7',
  },
  TTD: {
    currency: 'Trinidad and Tobago Dollar',
    code: 'TTD',
    symbol: 'TT$',
    flag: '\ud83c\uddf9\ud83c\uddf9',
  },
  TZS: {
    currency: 'Tanzanian Shilling',
    code: 'TZS',
    symbol: 'TSh',
    flag: '\ud83c\uddf9\ud83c\uddff',
  },
  UAH: {
    currency: 'Ukrainian Hryvnia',
    code: 'UAH',
    symbol: '\u20b4',
    flag: '\ud83c\uddfa\ud83c\udde6',
  },
  UYU: {
    currency: 'Uruguayan Peso',
    code: 'UYU',
    symbol: '$U',
    flag: '\ud83c\uddfa\ud83c\uddfe',
  },
  UZS: {
    currency: 'Uzbekistani Som',
    code: 'UZS',
    symbol: 'UZS',
    flag: '\ud83c\uddfa\ud83c\uddff',
  },
  WST: {
    currency: 'Samoan Tala',
    code: 'WST',
    symbol: 'WS$',
    flag: '\ud83c\uddfc\ud83c\uddf8',
  },
  XCD: {
    currency: 'East Caribbean Dollar',
    code: 'XCD',
    symbol: 'EC$',
    flag: '\ud83c\udde6\ud83c\uddec',
  },
  YER: {
    currency: 'Yemeni Rial',
    code: 'YER',
    symbol: 'YR',
    flag: '\ud83c\uddfe\ud83c\uddea',
  },
  ZAR: {
    currency: 'South African Rand',
    code: 'ZAR',
    symbol: 'R',
    flag: '\ud83c\uddff\ud83c\udde6',
  },
  ZMW: {
    currency: 'Zambian Kwacha',
    code: 'ZMW',
    symbol: 'K',
    flag: '\ud83c\uddff\ud83c\uddf2',
  },
};
Object.freeze(CURRENCIES);

const CURRENCY_OPTIONS: { label: string; value: string }[] = map(
  CURRENCIES,
  (currency: Currency) => ({
    label: `${currency.flag} ${currency.code} - ${currency.currency}`,
    value: currency.code,
  })
);
Object.freeze(CURRENCY_OPTIONS);

const CURRENCY_OPTIONS_COMBO_INPUT: { label: string; value: string; description: string }[] = map(
  CURRENCIES,
  (currency: Currency) => ({
    label: currency.symbol,
    value: currency.code,
    description: `${currency.flag} ${currency.code} - ${currency.currency}`,
  })
);
Object.freeze(CURRENCY_OPTIONS_COMBO_INPUT);

export const getCurrencyLabel = (value: string) =>
  CURRENCY_OPTIONS.find((option: { value: string; label: string }) => option.value === value)
    ?.label ?? '';

export const getCurrencySymbol = (code?: string) =>
  code && CURRENCIES[code.toUpperCase()]?.symbol ? CURRENCIES[code.toUpperCase()].symbol : '';

// Formats params into a Money object by converting price, in dollars, to cents.
export const toMoney = (price: number, currency: string): Money => {
  return { amount: price * 100, currency };
};

// Formats a money object based on the user's current locale
export const humanizeMoney = (money: Money | null, locale = 'en-US'): string => {
  const amount = money?.amount || 0;
  const currency = money?.currency || 'USD';

  return (amount / 100).toLocaleString(locale, {
    style: 'currency',
    currency,
  });
};

export const roundMoney = (money: Money): Money => {
  const fixed = money.amount.toFixed(2);
  const amount = parseFloat(fixed);
  return { amount, currency: money.currency };
};

export { CURRENCIES, CURRENCY_OPTIONS, CURRENCY_OPTIONS_COMBO_INPUT };
