import Flex from '@react-css/flex';
import Spinner from 'components/Common/Spinner';
import useAlertQueue from 'hooks/useAlertQueue';
import { isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { updateUserCompany } from 'store/slices/settings/company';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import Select from 'storybook/stories/molecules/Select';
import type { ReactSelectOption } from 'types/general';
import type { Company, CompanyEDIData } from 'types/models/company';
import {
  CONVICTIONAL_ACCEPTED_EDI_FILE_EXTENSIONS,
  EDI_FILE_ENCODING_OPTIONS,
  ISA_QUALIFIERS,
  MIN_ISA_ID_LENGTH,
  isaIDSpec,
  mapEDIFileExtensionToOption,
} from 'utils/edi';

interface EdiSettingsFormFields {
  isaID?: string;
  isaIDQualifier?: ReactSelectOption;
  characterEncoding?: ReactSelectOption;
  acceptedFileExtensions?: ReactSelectOption[];
}

interface EDISettingsProps {
  company: Company | null;
  isHorizontal?: boolean;
  isTitleVisible?: boolean;
}

const MAPPED_QUALIFIERS = Object.entries(ISA_QUALIFIERS).map(([qualifier, desc]) => ({
  value: qualifier,
  label: `${qualifier} - ${desc}`,
}));

const EDISettings = ({
  company,
  isHorizontal = false, // When set, a horizontal form is used
  isTitleVisible = true, // When set, the title is visible
}: EDISettingsProps) => {
  const ediData = company?.ediData ?? ({} as CompanyEDIData);
  const isaID: string = isEmpty(ediData.isaID) ? '' : ediData.isaID.trim();
  const defaultISAIDQualifier: { value: string; label: string } = MAPPED_QUALIFIERS.find(
    (qualifier) => qualifier.value === ediData.isaIDQualifier
  ) ?? { value: '', label: '' };
  const defaultCharacterEncoding: { value: string; label: string } = isEmpty(
    ediData.characterEncoding
  )
    ? { value: '', label: '' }
    : { value: ediData.characterEncoding, label: ediData.characterEncoding };
  const acceptedFileExtensions: string[] = isEmpty(ediData.acceptedFileExtensions)
    ? []
    : ediData.acceptedFileExtensions;
  const defaultFileExtensions: { value: string; label: string }[] =
    mapEDIFileExtensionToOption(acceptedFileExtensions);
  const dispatch = useAppDispatch();
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  // @ts-ignore
  const { isUpdatingCompany } = useSelector(({ settings }) => settings.company);

  const {
    handleSubmit,
    register,
    formState: { isDirty, errors },
    control,
    reset,
  } = useForm<EdiSettingsFormFields>({
    defaultValues: {
      isaID,
      isaIDQualifier: defaultISAIDQualifier,
      characterEncoding: defaultCharacterEncoding,
      acceptedFileExtensions: defaultFileExtensions,
    },
  });

  const onFormSubmit = (data: EdiSettingsFormFields) => {
    const newFileExtensions = isHorizontal
      ? acceptedFileExtensions
      : (data.acceptedFileExtensions?.map((acceptedFileExtension: ReactSelectOption) => {
          return acceptedFileExtension.value ?? '';
        }) ?? []);

    const paddedData =
      (data.isaID?.length ?? 0) < MIN_ISA_ID_LENGTH
        ? {
            ediData: {
              ...data,
              isaID: data.isaID?.padEnd(MIN_ISA_ID_LENGTH),
              isaIDQualifier: data.isaIDQualifier?.value,
              characterEncoding: data.characterEncoding?.value,
              acceptedFileExtensions: newFileExtensions,
            },
          }
        : {
            ediData: {
              ...data,
              characterEncoding: data.characterEncoding?.value,
              acceptedFileExtensions: newFileExtensions,
            },
          };

    dispatch(updateUserCompany(paddedData)).then((action: { error: { message: any } }) => {
      if (action.error) {
        addErrorAlert('Error', action.error.message);
        return;
      }
      addSuccessAlert('Success', 'Company EDI details were updated');
    });

    reset(
      { ...data },
      {
        keepDefaultValues: false,
        keepValues: false,
        keepDirty: false,
      }
    );
  };

  return (
    <>
      {!isHorizontal && <hr className="my-4" />}
      {isTitleVisible && <h4 className="mb-4">EDI Settings</h4>}
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Flex column gap="24px">
          <Flex column gap="4px">
            <Label htmlFor="isaIDQualifier">ISA ID Qualifier</Label>

            <Controller
              name="isaIDQualifier"
              control={control}
              rules={{ required: true }}
              render={({ field: { onBlur, onChange, ref, value } }) => (
                <Select
                  isFullWidth
                  options={MAPPED_QUALIFIERS}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  innerRef={ref}
                />
              )}
            />
            <Body variant="Body/Body Small" color="bodyTextSecondary">
              Your qualifier indicates the type of ISA ID you are using. If you would like to use a
              qualifier that doesn&apos;t appear on this list, please contact support.
            </Body>
            {errors.isaIDQualifier && (
              <Body variant="Body/Body Small" color="error500">
                {errors.isaIDQualifier.type === 'required'
                  ? 'ISA ID Qualifier is required'
                  : errors.isaIDQualifier.message}
              </Body>
            )}
          </Flex>

          <Flex column gap="4px">
            <Label htmlFor="isaID">ISA ID</Label>
            <Input
              type="text"
              id="isaID"
              placeholder="YOURCOMPANYISA"
              {...register('isaID', {
                required: true,
                pattern: {
                  value: isaIDSpec,
                  message: 'Your ISA ID must be between 1 and 15 alphanumeric characters.',
                },
              })}
            />
            {errors.isaID && (
              <Body variant="Body/Body Small" color="error500">
                {errors.isaID.type === 'required' ? 'ISA ID is required' : errors.isaID.message}
              </Body>
            )}
          </Flex>

          <Flex column gap="4px">
            <Label htmlFor="acceptedFileExtensions">Encoding used</Label>
            <Controller
              control={control}
              name="characterEncoding"
              rules={{ required: true }}
              render={({ field: { onBlur, onChange, ref, value } }) => (
                <Select
                  isSearchable
                  isFullWidth
                  menuPlacement="auto"
                  options={EDI_FILE_ENCODING_OPTIONS}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  innerRef={ref}
                />
              )}
            />
            <Body variant="Body/Body Small" color="bodyTextSecondary">
              The encoding used indicates the encoding used by your EDI system. Our default is
              UTF-8.
            </Body>
            {errors.characterEncoding && (
              <Body variant="Body/Body Small" color="error500">
                {errors.characterEncoding.type === 'required'
                  ? 'Encoding is required'
                  : errors.characterEncoding.message}
              </Body>
            )}
          </Flex>

          {!isHorizontal && (
            <Flex column gap="4px">
              <Label htmlFor="acceptedFileExtensions">Accepted File Extensions</Label>

              <Controller
                control={control}
                name="acceptedFileExtensions"
                rules={{ required: true }}
                render={({ field: { onBlur, onChange, ref, value } }) => (
                  <Select
                    isMulti
                    isClearable
                    isCreatable
                    isFullWidth
                    options={CONVICTIONAL_ACCEPTED_EDI_FILE_EXTENSIONS}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    innerRef={ref}
                  />
                )}
              />
              <Body variant="Body/Body Small" color="bodyTextSecondary">
                Select from dropdown or type and create a new file extension used by your EDI
                system.
              </Body>
              {errors.acceptedFileExtensions && (
                <Body variant="Body/Body Small" color="error500">
                  {errors.acceptedFileExtensions.type === 'required'
                    ? 'Accepted File Extensions are required'
                    : errors.acceptedFileExtensions.message}
                </Body>
              )}
            </Flex>
          )}
          <div className={isHorizontal ? 'd-flex justify-content-end' : ''}>
            <PrimaryButton type="submit" size="small" disabled={!isDirty || isUpdatingCompany}>
              {isUpdatingCompany && <Spinner color="primary" className="mx-2" small />}

              {isHorizontal ? 'Save EDI Settings' : 'Update EDI Settings'}
            </PrimaryButton>
          </div>
        </Flex>
      </form>
    </>
  );
};

export default EDISettings;
