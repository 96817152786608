import type { JsonObject } from 'react-use-websocket/dist/lib/types';

export enum WebsocketMessageType {
  AI = 'ai_message',
  HUMAN = 'human_message',
  ERROR = 'error',
  JOINED = 'joined',
  LEAVE = 'leave',
  STATUS = 'status_change',
  INTERRUPT = 'interrupt',
  MODE = 'mode_changed',
}

export enum ConversationMessageFeedbackType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NONE = '',
}

export enum ConversationMode {
  Support = 'support',
}

export interface ChatMessage {
  key: string;
  content: string;
  type: WebsocketMessageType;
  id?: string;
}

export type HistoryMessage = Pick<
  WebsocketConversationMessageFromServer,
  'type' | 'content' | 'message_id'
>;

// Keys are in snake case because of the Python App proxy
export interface WebsocketConversationMessageFromServer extends JsonObject {
  type: WebsocketMessageType;
  content: string;
  conversation_id: string;
  message_id: string;
  api_token: string;
  commerce_type: string;
  product_platform: string;
  order_platform: string;
  first_name: string;
  last_name: string;
  company_name: string;
  company_object_id: string;
  partner_ids: string[];
  mode: string;
  history: HistoryMessage[];
}

export interface WebsocketConversationMessageFromClient extends JsonObject {
  type: WebsocketMessageType;
  content: string;
  conversation_id: string;
  mode: string;
  partner: string;
}

export interface ConversationMessageFeedback {
  type: ConversationMessageFeedbackType;
  reason: string;
}

export interface ConversationMessage {
  id: string;
  raw: string;
  type: string;
  content: string;
  createdAt: string;
  feedback: ConversationMessageFeedback;
}

export interface Conversation {
  id: string;
  authToken: string;
  companyId: string;
  userId: string;
  modes: ConversationMode[];
}
