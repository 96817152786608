const TeamMemberTable = ({ teamMembers = [] }) => {
  if (teamMembers.length === 0) {
    return null;
  }

  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th className="p-2">First Name</th>
          <th className="p-2">Email</th>
        </tr>
      </thead>
      <tbody className="list">
        {teamMembers.map((member) => (
          <tr key={member.email}>
            <td className="small p-2">{member.firstName || '-'}</td>
            <td className="small p-2">{member.email || '-'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TeamMemberTable;
