import ListWebhooks from 'containers/SettingsPage/DropshippingTab/WebhooksSettings/ListWebhooks';
import WebhookForm from 'containers/SettingsPage/DropshippingTab/WebhooksSettings/WebhookForm';
import { SettingsMain, SettingsPageHeader } from 'containers/SettingsPage/SettingsLayout';
import { useEffect, useState } from 'react';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import type { Webhook } from 'types/models/webhook';

enum Action {
  Create,
  Update,
  List,
}

const WebhookSettings = () => {
  const [currentAction, setCurrentAction] = useState<Action>(Action.List);
  const [webhookToEdit, setWebhookToEdit] = useState<Webhook | undefined>();

  const reset = () => {
    setCurrentAction(Action.List);
    setWebhookToEdit(undefined);
  };

  useEffect(() => {
    if (webhookToEdit) {
      setCurrentAction(Action.Update);
    }
  }, [webhookToEdit]);

  return (
    <SettingsMain>
      <SettingsPageHeader title="Webhooks">
        {currentAction === Action.List && (
          <SecondaryButton
            $iconName="add"
            size="small"
            onClick={() => setCurrentAction(Action.Create)}
          >
            Create New
          </SecondaryButton>
        )}
      </SettingsPageHeader>

      {currentAction === Action.List && <ListWebhooks setWebhookToEdit={setWebhookToEdit} />}
      {currentAction === Action.Create && <WebhookForm reset={reset} />}
      {currentAction === Action.Update && <WebhookForm reset={reset} webhook={webhookToEdit} />}
    </SettingsMain>
  );
};

export default WebhookSettings;
