import { v4 as uuidv4 } from 'uuid';

import {
  CommerceType,
  Platform,
  type CompanyShopifySettings,
  type CompanyWooCommerceAuth,
} from 'types/models/company';

import type { Me } from 'types/redux';
import config from './config';

export const getShopifyAuthUrl = (store?: string) => {
  if (!store) return '';

  return (
    `https://${store}.myshopify.com/admin/oauth/authorize` +
    `?client_id=${config.shopify.clientId}` +
    `&scope=${config.shopify.scope}` +
    `&redirect_uri=${config.shopify.redirect}` +
    `&state=${uuidv4()}`
  );
};

export const getShopifyStore = (company: Me['company']) => {
  return (company?.activeAuth?.shopify as CompanyShopifySettings)?.store; // if no store, do not fallback to companyId
};

export const getPlatformUrl = (company: Me['company'], platform: string) => {
  return (company?.activeAuth?.[platform] as CompanyWooCommerceAuth)?.platformUrl || '';
};

export const getBigCommerceHash = (platformUrl = '') =>
  platformUrl
    .replace('https://', '')
    .replace('store-', '')
    .replace('.bigcommerce.com', '')
    .replace('.mybigcommerce.com', '');

export const friendlyPlatformName = (platform?: Platform, commerceType?: CommerceType) => {
  if (platform === Platform.Other && commerceType === CommerceType.Buyer) {
    return 'API';
  }

  if (platform === Platform.Other && commerceType === CommerceType.Seller) {
    return 'App or API';
  }

  switch (platform) {
    case Platform.WooCommerce:
      return 'WooCommerce';
    case Platform.Shopify:
      return 'Shopify';
    case Platform.BigCommerce:
      return 'BigCommerce';
    case Platform.Magento2:
      return 'Magento 2';
    case Platform.CsvSftp:
    case Platform.ExternalSftp:
      return 'CSV over SFTP';
    case Platform.EdiSftp:
    case Platform.EdiExternalSftp:
      return 'EDI over SFTP';
    default:
      return 'Other';
  }
};

// Note: HST = Harmonized Sales Tax
export const HST_SUPPORTED_PROVINCES = ['NB', 'NL', 'NS', 'ON', 'PE'];

const MODERN_PLATFORMS = [
  Platform.Shopify,
  Platform.Magento2,
  Platform.WooCommerce,
  Platform.BigCommerce,
];

export const isPlatformModern = (platform: Platform) => {
  return MODERN_PLATFORMS.includes(platform);
};
