import Grid from '@react-css/grid';
import type { ElementType } from 'react';
import { useContext } from 'react';
import { useDocumentTitle } from 'usehooks-ts';

import DashboardPage from 'components/Dashboard/DashboardPage';

import { CreatePriceListContext, CreatePriceListProvider } from './context';

import { Redirect, useHistory } from 'react-router';
import { useAppSelector } from 'store';
import { selectIsSeller } from 'store/selectors/me/company';
import Page from 'storybook/stories/cells/Page';
import AddExistingProductsForm from './AddExistingProductsForm';
import ConversionRateForm from './CurrencyForms/ConversionRateForm';
import CurrencyForm from './CurrencyForms/CurrencyForm';
import RoundingRulesForm from './CurrencyForms/RoundingRulesForm';
import MarginForm from './MarginForm';
import PartnersForm from './PartnersForm';
import PriceListNameForm from './PriceListNameForm';
import SummaryCard from './SummaryCard';

type StepComponents = Record<string, ElementType>;

// Map each step to a component
export const CreatePriceListSteps: StepComponents = {
  PriceListNameForm,
  AddExistingProductsForm,
  PartnersForm,
  CurrencyForm,
  ConversionRateForm,
  RoundingRulesForm,
  MarginForm,
  default: PriceListNameForm,
};

const Index = () => {
  const history = useHistory();
  useDocumentTitle('Create a Price List');

  const { currentStep } = useContext(CreatePriceListContext);
  const CurrentStepComponent = CreatePriceListSteps[currentStep] || CreatePriceListSteps.default;

  return (
    <DashboardPage>
      <Page>
        <Page.Head
          title="Create A Price List"
          backButton={{
            onClick: () => history.push('/prices'),
            text: 'Back to Price Lists',
          }}
        />

        <Grid columns="2fr 1fr" gap="26px">
          <CurrentStepComponent />
          <SummaryCard />
        </Grid>
      </Page>
    </DashboardPage>
  );
};

const CreatePriceList = () => {
  const isSeller = useAppSelector(selectIsSeller);

  // If the user is not a seller, redirect buyer to Orders
  if (!isSeller) return <Redirect to="/" />;

  return (
    <CreatePriceListProvider>
      <Index />
    </CreatePriceListProvider>
  );
};

export default CreatePriceList;
