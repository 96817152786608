/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import styled, { css } from 'styled-components';

import Flex from '@react-css/flex';
import { useQueryClient } from '@tanstack/react-query';
import { formatDistanceToNow } from 'date-fns';
import { useAppDispatch } from 'store';
import { getPartners, getPendingPartners, updatePartner } from 'store/slices/partners';
import Button from 'storybook/stories/molecules/Button';
import CompanyProfile from 'storybook/stories/organisms/CompanyProfile';
import CompanyPicker from 'storybook/stories/species/CompanyPicker';
import { CommerceType, type Company } from 'types/models/company';
import type { Partner } from 'types/models/partner';
import { isUserMembership, type UserMembership } from 'types/models/user';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 32px;
  background: #f5f8fa;
  border: 1px solid #e3ebf2;
  border-radius: 8px;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 44px;
  width: 100%;
`;

const CompanyCard = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray200};
    background: ${theme.color.white};
  `}
`;

const RedAsterik = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.error500};
  `}
`;

const FromDate = styled.span`
  text-transform: uppercase;
  ${({ theme }) => css`
    font-size: 11px;
    font-family: ${theme.font.secondary};
  `}
`;

interface FromCompanyProps {
  commerceType: CommerceType;
  name: string;
  date: Date | undefined;
}

const FromCompany = ({ commerceType, name, date }: FromCompanyProps) => {
  const companyPartial: Partial<Company> = {
    commerceType,
    name,
    url: '',
  };

  return (
    <CompanyCard>
      <ProfileWrapper>
        <CompanyProfile company={companyPartial} />
      </ProfileWrapper>
      {date && <FromDate>{`${formatDistanceToNow(date)} ago`}</FromDate>}
    </CompanyCard>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const determineSenderCommerceType = (partner: Partner) => {
  return partner.buyerCompanyObjectId === '' ? CommerceType.Seller : CommerceType.Buyer;
};

interface PendingInviteProps {
  selectedMembership: Company | UserMembership | null;
  selectableMemberships: UserMembership[];
  pendingPartner: Partner;
}

const PendingInvite = ({
  selectedMembership: initialSelectedMembership,
  selectableMemberships,
  pendingPartner,
}: PendingInviteProps) => {
  const queryClient = useQueryClient();
  const senderCommerceType = determineSenderCommerceType(pendingPartner);
  const senderName =
    senderCommerceType === CommerceType.Buyer
      ? pendingPartner.buyerName
      : pendingPartner.sellerName;
  const sentDate = new Date(pendingPartner.created) || undefined;

  const [selectedMembership, setSelectedMembership] = useState<Company | UserMembership | null>(
    initialSelectedMembership
  );

  const assignableMemberships = selectableMemberships.filter(
    (membership) => membership.commerceType !== senderCommerceType
  );

  const dispatch = useAppDispatch();
  const doAssign = async (companyObjectId: string) => {
    const payload = {
      partnerId: pendingPartner._id,
      body: { assignToCompanyObjectId: companyObjectId },
    };
    dispatch(updatePartner(payload)).then(() => {
      queryClient.invalidateQueries(['getPartners']);
      dispatch(getPartners({ page: 0, limit: 250 }));
      dispatch(getPendingPartners());
    });
  };

  return (
    <Container>
      <Flex column gap="8px">
        <span>Invitation From</span>
        <FromCompany commerceType={senderCommerceType} name={senderName} date={sentDate} />
      </Flex>

      <Flex column gap="8px">
        <label>
          Company Assignment<RedAsterik>*</RedAsterik>
        </label>
        <CompanyCard>
          <ProfileWrapper>
            <CompanyPicker
              selectedMembership={selectedMembership}
              selectableMemberships={assignableMemberships}
              onSelect={(params) => {
                setSelectedMembership(
                  assignableMemberships.find(
                    (membership) => membership.companyObjectId === params.companyObjectId
                  ) ?? initialSelectedMembership
                );
              }}
              hasMultipleMemberships={assignableMemberships.length > 1}
            />
          </ProfileWrapper>
        </CompanyCard>
      </Flex>

      <ButtonWrapper>
        <Button
          onClick={() => {
            doAssign(
              isUserMembership(selectedMembership)
                ? selectedMembership.companyObjectId
                : (selectedMembership?._id ?? '')
            );
          }}
        >
          Accept partnership
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default PendingInvite;
