import Flex from '@react-css/flex';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/Common/Button';
import Message from 'components/Common/Message';
import { unflagOrder } from 'store/slices/ordersV2';
import resetButtonStyles from 'storybook/mixins/resetButtonStyles';
import { TEST_ORDER_FLAGGED_MESSAGE } from 'utils/constants';
import { prettyDateTime } from 'utils/date';

import CancelOrderModal from 'containers/OrderPage/CancelOrderModal';

const FLAGGED_MESSAGE_MAX_LENGTH = 500;

const SyncOrderButton = styled(Button)`
  background-color: white;
  margin-left: 24px;
`;

const LinkButton = styled.button`
  ${resetButtonStyles};

  ${({ theme }) => css`
    color: ${theme.color.blue500};
  `}
`;

const OrderFlaggedSellerNextSteps = ({ order = {}, onUnflag, onCancel }) => {
  const isCancelable = !order.shipped && !order.hasCancellations;
  const isFlagged = order.flaggedAt === 'post' && order.flagged;

  if (!isFlagged) return null;

  return (
    <>
      <strong>Next Steps:</strong>

      <ol className="m-0">
        <li>
          <LinkButton onClick={onUnflag}>Retry the order</LinkButton>, or
        </li>

        <li>
          Reach out to your partner to resolve this issue{' '}
          {order.buyerEmail && (
            <>
              (
              <LinkButton as="a" href={`mailto:${order.buyerEmail}`}>
                {order.buyerEmail}
              </LinkButton>
              )
            </>
          )}
          {isCancelable && ', or'}
        </li>

        {isCancelable && (
          <li>
            <LinkButton onClick={onCancel}>Cancel the order</LinkButton>
          </li>
        )}
      </ol>
    </>
  );
};

const OrderFlaggedAlert = ({ order, isSeller, dispatch, showSuccessMessage, showErrorMessage }) => {
  const [showCancel, setShowCancel] = useState(false);
  const flaggedAt = prettyDateTime(order.flaggedDate);
  const isUnacknowledgedTestOrder =
    order.isTest &&
    isSeller &&
    order.flagged &&
    order.flaggedMessage === TEST_ORDER_FLAGGED_MESSAGE;

  const flaggedMessage =
    order.flaggedMessage.length > FLAGGED_MESSAGE_MAX_LENGTH
      ? `${order.flaggedMessage.substring(0, FLAGGED_MESSAGE_MAX_LENGTH)}...`
      : order.flaggedMessage;

  const onUnflag = () => {
    dispatch(unflagOrder(order._id)).then((action) => {
      if (action.error) {
        showErrorMessage({ title: 'Error retrying order', message: action.error.message });
        return;
      }

      if (isUnacknowledgedTestOrder) {
        showSuccessMessage({
          title: 'Order unflagged',
          message: 'This order will be synced in the next order sync (usually within 15 minutes).',
        });
        return;
      }
      showSuccessMessage({
        title: 'Order retried',
        message: 'This order will be retried on the next order sync (usually within 15 minutes).',
      });
    });
  };

  const onCancel = (e) => {
    e.preventDefault();
    setShowCancel(true);
  };

  return order && order.flagged ? (
    <>
      <Message kind={order.isTest ? 'info' : 'warning'} show animate={false} className="mt-0 mb-4">
        <Flex alignItemsCenter>
          <div>
            <p className="m-0 mb-1">
              <strong>Order Flagged</strong>
              {flaggedAt ? ` on ${flaggedAt}` : ''}
            </p>
            <p className="m-0 mb-1">
              <strong>Reason:</strong> {flaggedMessage}
            </p>
            {isSeller && (
              <OrderFlaggedSellerNextSteps order={order} onUnflag={onUnflag} onCancel={onCancel} />
            )}
          </div>
          {isUnacknowledgedTestOrder && (
            <SyncOrderButton outline color="primary" size="sm" onClick={onUnflag}>
              Sync the Test Order to My Platform
            </SyncOrderButton>
          )}
        </Flex>
      </Message>
      <CancelOrderModal
        order={order}
        showModal={showCancel}
        onDismiss={() => setShowCancel(false)}
        dispatch={dispatch}
      />
    </>
  ) : null;
};

export default OrderFlaggedAlert;
