import Flex from '@react-css/flex';
import type { Elevation } from 'storybook/mixins/elevate';
import elevate from 'storybook/mixins/elevate';
import BodyMolecule from 'storybook/stories/molecules/Body';
import styled from 'styled-components';

export interface CardProps {
  /**
   * The elevation of the card.
   */
  elevation?: Elevation | 'none';
  /**
   * Padding
   */
  padding?: string;
  /**
   * isFullWidth
   */
  $isFullWidth?: boolean;
}

const Base = styled(Flex).attrs(({ column = true, gap = '24px' }) => ({ column, gap }))<CardProps>`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 8px;
  padding: ${({ padding = '24px 32px' }) => padding};

  ${({ elevation = '1' }) => elevation !== 'none' && elevate(elevation)};

  ${({ $isFullWidth }) => $isFullWidth && 'width: 100%'};
`;

const Title = styled(BodyMolecule).attrs({ variant: 'Headings/H3' })``;

const Head = styled(Flex.Item)`
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray200};

  ${Title} + button {
    max-height: 24px;
  }
`;

const Body = styled(Flex.Item)``;

/**
 * A simple, visually distinct Card UI. It supports optional `Card.Head` and `Card.Body` subcomponents,
 * which can provide layout for content. If no `Card.Head` is necessary you may omit `Card.Body`.
 */
const Card = Object.assign(Base, { Head, Title, Body });

export default Card;
