import Flex from '@react-css/flex';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  BulkEditPriceListContext,
  TableViewOptions,
  type EditablePriceListEntry,
} from 'containers/PriceListPage/BulkEditing/context';
import { MarginType } from 'containers/PriceListsPage/CreatePriceList/Shared.types';
import useAlertQueue from 'hooks/useAlertQueue';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';
import { ProductImage } from 'storybook/stories/cells/SearchResult/shared/ProductImage';
import {
  InnerInputSymbol,
  PricingInput,
  PricingInputWrapper,
} from 'storybook/stories/cells/SearchResult/shared/Styles';
import Body from 'storybook/stories/molecules/Body';
import Button from 'storybook/stories/molecules/Button';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import Label from 'storybook/stories/molecules/Label';
import styled, { css } from 'styled-components';
import type { ErrorMessage } from 'types/api';
import type { Money } from 'types/general';
import { updatePriceListEntry } from 'utils/api/priceLists';
import { roundMoney } from 'utils/currencies';

const RowListItem = styled.li<{ invalid: boolean }>`
  ${({ invalid, theme }) =>
    invalid &&
    css`
      color: ${theme.color.error500};
    `}

  & + & {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid ${({ theme }) => theme.color.gray200};
  }
`;

const RowList = styled.ul`
  margin: 0;
  padding: 16px;
  list-style: none;
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
  background: ${({ theme }) => theme.color.bodyBackground};
`;

interface BulkEditPriceListEntryMarginModalProps {
  show: boolean;
  onHide: () => void;
  marginType: MarginType;
  defaultMargin: number;
  initialRows: EditablePriceListEntry[];
}

const BulkEditPriceListEntryMarginModal = ({
  show,
  onHide,
  marginType,
  defaultMargin,
  initialRows,
}: BulkEditPriceListEntryMarginModalProps) => {
  const { viewMode } = useContext(BulkEditPriceListContext);
  const [rows, setRows] = useState(initialRows);
  const [invalidRowIds, setInvalidRowIds] = useState<string[]>([]);
  const alertQueue = useAlertQueue();
  const queryClient = useQueryClient();

  const formattedMargin = marginType === MarginType.Percent ? defaultMargin * 100 : defaultMargin;

  interface FormValues {
    margin: number;
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      margin: formattedMargin,
    },
  });

  /**
   * Mutations
   */

  const updatingPriceListEntry = useMutation({
    mutationFn: ({
      row,
      newSellerRetailPrice,
      newDropShipPrice,
    }: {
      row: EditablePriceListEntry;
      newSellerRetailPrice: Money;
      newDropShipPrice: Money;
    }) =>
      updatePriceListEntry(row.entry.priceListId, row.entry.id, {
        sellerRetailPrice: newSellerRetailPrice,
        dropshipPrice: newDropShipPrice,
      }),
    onError: (error: ErrorMessage) => {
      alertQueue.addErrorAlert('Unable to save price list entry', error.message);
    },
  });

  /**
   * Helpers
   */

  const removeRow = (id: string) => {
    setRows((prevRows) => prevRows.filter((row) => row.entry.id !== id));
  };

  const resetRows = () => {
    setRows(initialRows);
  };

  const validateMarginField = (value: number) => {
    const percentCheck = marginType === MarginType.Percent && (value < 0 || value > 100);

    const invalidIds =
      marginType === MarginType.Fixed
        ? rows
            .filter((row) => value * 100 > row.entry.sellerRetailPrice.amount)
            .map((row) => row.entry.id)
        : [];

    const fixedCheck = marginType === MarginType.Fixed && invalidIds.length > 0;

    setInvalidRowIds(invalidIds);

    if (percentCheck) {
      return 'Margin must be between 0 and 100';
    }

    if (fixedCheck) {
      return 'Margin cannot be greater than the dropship price';
    }

    return true;
  };

  /**
   * Event Handlers
   */

  const onClose = useCallback(() => {
    reset({ margin: formattedMargin });
    onHide();
  }, [onHide, reset, formattedMargin]);

  const onSubmit = async (values: FormValues) => {
    await Promise.all(
      rows.map((row) => {
        const newSellerRetailPrice = roundMoney(row.entry.sellerRetailPrice);

        const newDropshipPriceAmount =
          marginType === MarginType.Percent
            ? newSellerRetailPrice.amount * (1 - values.margin / 100)
            : newSellerRetailPrice.amount + values.margin * 100 * -1;
        const newDropShipPrice = roundMoney({
          ...row.entry.dropshipPrice,
          amount: newDropshipPriceAmount,
        });

        return updatingPriceListEntry.mutateAsync({ row, newSellerRetailPrice, newDropShipPrice });
      })
    );

    alertQueue.addSuccessAlert('Success', 'Successfully updated margins');

    queryClient.invalidateQueries(['getPriceListEntries']);
    queryClient.invalidateQueries(['getPriceListEntrySearch']);

    onHide();
  };

  /**
   * Side Effects
   */

  // Close the modal if all rows are removed from the list
  useEffect(() => {
    if (rows.length === 0) onClose();
  }, [rows.length, onClose]);

  /**
   * Render
   */

  return (
    <Modal.Root show={show} onShow={resetRows} onHide={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Margins in Bulk</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex column gap="32px">
            <Body variant="Body/Regular">
              Please enter your new margin and confirm the SKUs below
            </Body>
            <Flex column gap="8px">
              <Label htmlFor="margin" isRequired>
                New Margin
              </Label>

              <PricingInputWrapper>
                {marginType === MarginType.Fixed && <InnerInputSymbol>$</InnerInputSymbol>}
                {marginType === MarginType.Percent && <InnerInputSymbol>%</InnerInputSymbol>}

                <PricingInput
                  type="number"
                  min="0"
                  step={marginType === MarginType.Fixed ? '0.01' : '1'}
                  id="margin"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('margin', {
                    required: true,
                    valueAsNumber: true,
                    validate: validateMarginField,
                  })}
                />
              </PricingInputWrapper>

              {errors.margin && (
                <Body variant="Body/Body Small" color="error500">
                  {errors.margin.message}
                </Body>
              )}
            </Flex>

            <RowList>
              {rows.map((row) => (
                <RowListItem key={row.entry.id} invalid={invalidRowIds.includes(row.entry.id)}>
                  <Flex alignItemsCenter gap="16px">
                    <ProductImage alt={`Image for ${row.entry.variant.sku}`} src={row.imgSrc} />

                    <Flex.Item grow={1}>
                      {viewMode === TableViewOptions.TITLES && (
                        <Flex column>
                          <Body variant="Body/Regular">{row.entry.productTitle}</Body>
                          <Body variant="Body/Body Small" color="bodyTextSecondary">
                            {row.entry.variant.title}
                          </Body>
                        </Flex>
                      )}

                      {viewMode === TableViewOptions.SKU && (
                        <Body variant="Body/Regular">{row.entry.variant.sku}</Body>
                      )}
                    </Flex.Item>

                    <Icon
                      as={Button}
                      name="delete_forever"
                      size="24px"
                      color="iconDefault"
                      onClick={() => removeRow(row.entry.id)}
                    />
                  </Flex>
                </RowListItem>
              ))}
            </RowList>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Flex justifyEnd gap="16px">
            <TertiaryButton type="reset" onClick={onClose}>
              Cancel
            </TertiaryButton>

            <PrimaryButton type="submit">Update Margin</PrimaryButton>
          </Flex>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default BulkEditPriceListEntryMarginModal;
