import { useMutation } from '@tanstack/react-query';
import { createContext, useCallback, useMemo, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import useAlertQueue from 'hooks/useAlertQueue';
import type { PriceListEntry } from 'types/models/price-list-entry';
import { deletePriceListEntry, updatePriceListEntry } from 'utils/api/priceLists';
import { roundMoney } from 'utils/currencies';

export interface EditablePriceListEntry {
  entry: PriceListEntry;
  imgSrc: string;
  isEditingYourRetailPrice: boolean;
  isEditingMargin: boolean;
  isEditingYouEarn: boolean;
}

export enum EditEntryOptions {
  YOUR_RETAIL_PRICE = 'retailPrice',
  MARGIN = 'margin',
  MARGIN_BULK = 'marginBulk',
  YOU_EARN = 'youEarn',
}

export enum TableViewOptions {
  TITLES = 'titles',
  SKU = 'sku',
}

export interface BulkEditPriceListContextValues {
  rows: EditablePriceListEntry[];
  setRows: React.Dispatch<React.SetStateAction<EditablePriceListEntry[]>>;
  selectedRows: EditablePriceListEntry[];
  selectedRowIds: string[];
  setSelectedRowIds?: React.Dispatch<React.SetStateAction<string[]>>;
  viewMode: TableViewOptions;
  setViewMode: (newViewMode: TableViewOptions) => void;
  getRowById: (id: string) => EditablePriceListEntry;
  updateRow: (id: string, updates: Partial<EditablePriceListEntry>) => void;
  markRowsForEditing: (ids: string[], action: EditEntryOptions) => void;
  submitRowById: (id: string) => void;
  deleteRowById: (id: string) => void;
}

const defaultValues: BulkEditPriceListContextValues = {
  rows: [],
  setRows: () => {},
  selectedRows: [],
  selectedRowIds: [],
  setSelectedRowIds: () => {},
  viewMode: TableViewOptions.TITLES,
  setViewMode: () => {},
  getRowById: () => ({}) as EditablePriceListEntry,
  updateRow: () => {},
  markRowsForEditing: () => {},
  submitRowById: () => {},
  deleteRowById: () => {},
};

export const BulkEditPriceListContext =
  createContext<BulkEditPriceListContextValues>(defaultValues);

interface BulkEditPriceListProviderProps {
  children: React.ReactNode;
  initialContext?: Partial<BulkEditPriceListContextValues>;
}

export const BulkEditPriceListProvider = ({
  children,
  initialContext,
}: BulkEditPriceListProviderProps) => {
  const { addSuccessAlert, addErrorAlert } = useAlertQueue();

  const [rows, setRows] = useState<EditablePriceListEntry[]>(initialContext?.rows || []);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>(
    initialContext?.selectedRowIds || []
  );

  const selectedRows = useMemo(() => {
    return rows.filter((row) => selectedRowIds.includes(row.entry.id));
  }, [rows, selectedRowIds]);

  // Initialize viewMode from localStorage or default to TableViewOptions.TITLES
  const [viewMode, setViewMode] = useLocalStorage<TableViewOptions>(
    'priceListEntriesViewMode',
    TableViewOptions.TITLES
  );

  // handleSetViewMode toggles which view mode the user has set.
  const handleSetViewMode = useCallback(
    (newViewMode: TableViewOptions) => {
      setViewMode(newViewMode);
    },
    [setViewMode]
  );

  // Used for individual row updates
  const updateRow = useCallback((id: string, updates: Partial<EditablePriceListEntry>) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const index = updatedRows.findIndex((row) => row.entry.id === id);
      if (index !== -1) {
        updatedRows[index] = { ...updatedRows[index], ...updates };
      }
      return updatedRows;
    });
  }, []);

  // removeRowFromRows removes a price list entry row from the entries in memory.
  // Note - It doesn't make the DELETE call to the server.
  const removeRowFromRows = useCallback(
    (id: string) => {
      const updatedRows = [...rows];
      const index = updatedRows.findIndex((row) => row.entry.id === id);
      if (index !== -1) {
        updatedRows.splice(index, 1);
      }
      setRows(updatedRows);
    },
    [rows]
  );

  // markRowsForEditing toggles the selected "editing" state of the rows with the given ids.
  const markRowsForEditing = useCallback(
    (ids: string[], action: EditEntryOptions) => {
      const updatedRows = [...rows];
      ids.forEach((id) => {
        const index = updatedRows.findIndex((row) => row.entry.id === id);
        if (index !== -1) {
          updatedRows[index] = {
            ...updatedRows[index],
            isEditingYourRetailPrice: action === EditEntryOptions.YOUR_RETAIL_PRICE,
            isEditingMargin: action === EditEntryOptions.MARGIN,
            isEditingYouEarn: action === EditEntryOptions.YOU_EARN,
          };
        }
      });
      setRows(updatedRows);
    },
    [rows]
  );

  // getRowById returns a price list entry row by the given id.
  const getRowById = useCallback(
    (id: string) => {
      const row = rows.find((editableRow) => editableRow.entry.id === id);

      if (!row) {
        return {} as EditablePriceListEntry;
      }

      return row;
    },
    [rows]
  );

  // submittingSingleRow makes the PATCH call to the server to update a single price list entry.
  const submittingSingleRow = useMutation({
    mutationFn: (row: EditablePriceListEntry) =>
      updatePriceListEntry(row.entry.priceListId, row.entry.id, {
        sellerRetailPrice: roundMoney(row.entry.sellerRetailPrice),
        dropshipPrice: roundMoney(row.entry.dropshipPrice),
      }),
    onError: (error: { message: string }) => {
      console.error('Unable to save price list entry', error.message);
    },
  });

  // deletingPriceListEntry makes the DELETE call to the server to remove a single price list entry.
  const deletingPriceListEntry = useMutation({
    mutationFn: (row: EditablePriceListEntry) =>
      deletePriceListEntry(row.entry.priceListId, row.entry.id),
    onSuccess: (_data: any, row: EditablePriceListEntry) => {
      addSuccessAlert('Success', 'Successfully removed product from price list.');
      removeRowFromRows(row.entry.id);
    },
    onError: (error: { message: string }) => {
      addErrorAlert('Error', 'Failed to remove product from price list.');
      console.error('Failed to remove product from price list', error.message);
    },
  });

  // submitRowById triggers the useMutation call to update a single price list entry.
  const submitRowById = useCallback(
    (id: string) => {
      const row = getRowById(id);

      row.isEditingYourRetailPrice = false;
      row.isEditingMargin = false;
      row.isEditingYouEarn = false;

      submittingSingleRow.mutate(row);
    },
    [getRowById, submittingSingleRow]
  );

  // deleteRowById triggers the useMutation call to remove a single price list entry.
  const deleteRowById = useCallback(
    (id: string) => {
      const row = getRowById(id);
      deletingPriceListEntry.mutate(row);
    },
    [getRowById, deletingPriceListEntry]
  );

  const value = useMemo(
    () => ({
      rows,
      setRows,
      selectedRows,
      selectedRowIds,
      setSelectedRowIds,
      viewMode,
      setViewMode: handleSetViewMode,
      getRowById,
      updateRow,
      markRowsForEditing,
      submitRowById,
      deleteRowById,
    }),
    [
      rows,
      selectedRows,
      selectedRowIds,
      viewMode,
      handleSetViewMode,
      getRowById,
      updateRow,
      markRowsForEditing,
      submitRowById,
      deleteRowById,
    ]
  );

  return (
    <BulkEditPriceListContext.Provider value={value}>{children}</BulkEditPriceListContext.Provider>
  );
};
