import elevate from 'storybook/mixins/elevate';
import Button from 'storybook/stories/molecules/Button';
import styled from 'styled-components';

interface DecisionButtonProps {
  isActive: boolean;
  $padding?: string;
}

const DecisionButton = styled(Button)<DecisionButtonProps>`
  all: unset;
  padding: ${({ $padding = '24px' }) => $padding};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.info100 : theme.color.white};
  border: 1px solid
    ${({ theme, isActive }) => (isActive ? theme.color.blue300 : theme.color.gray200)};
  border-radius: 8px;

  ${({ isActive }) => isActive && elevate('1')};
`;

export default DecisionButton;
