import Flex from '@react-css/flex';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import DashboardPage from 'components/Dashboard/DashboardPage';
import Card from 'storybook/stories/cells/Card';
import Page from 'storybook/stories/cells/Page';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import StatusIcon from 'storybook/stories/molecules/Icon/StatusIcon';
import { ACCOUNT_TYPE_BUYER } from 'utils/constants';
import { getAccountType } from 'utils/cookies';
import { supportEDIDocumentNumbers } from 'utils/edi';

const DocumentListContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 4.5rem 0;
`;

const CardInfoContainer = styled.div`
  flex: 1;
`;

const EDISchemasPage = () => {
  const history = useHistory();
  return (
    <DashboardPage>
      <Page>
        <Page.Head title="EDI Documents" />
        <DocumentListContainer>
          {supportEDIDocumentNumbers(getAccountType() === ACCOUNT_TYPE_BUYER).map((doc) => {
            return (
              <Card className="mb-3" key={doc.number}>
                <Flex gap="16px">
                  <StatusIcon variant="unchecked" />
                  <CardInfoContainer>
                    <h4 className="text-body text-focus mb-1">
                      {doc.name} ({doc.number})
                    </h4>
                    <p className="mb-0">
                      {getAccountType() === ACCOUNT_TYPE_BUYER
                        ? doc.buyerDescription
                        : doc.sellerDescription}
                    </p>
                  </CardInfoContainer>
                  <SecondaryButton
                    onClick={() => history.push({ pathname: `/edi/documents/${doc.number}` })}
                  >
                    Configure
                  </SecondaryButton>
                </Flex>
              </Card>
            );
          })}
        </DocumentListContainer>
      </Page>
    </DashboardPage>
  );
};

export default EDISchemasPage;
