import DashboardPage from 'components/Dashboard/DashboardPage';
import Page from 'storybook/stories/cells/Page';

interface PageWrapperProps {
  children: React.ReactNode;
}

const PageWrapper = ({ children }: PageWrapperProps) => (
  <DashboardPage>
    <Page>
      <Page.Head title="Locked" />
      <Page.Body>{children}</Page.Body>
    </Page>
  </DashboardPage>
);

const LockedPage = () => {
  return (
    <PageWrapper>
      <strong>
        This account has been locked. Please reach out to{' '}
        <a href="mailto:support@moderndropship.com">support@moderndropship.com</a> to resolve this
        issue.
      </strong>
    </PageWrapper>
  );
};

export default LockedPage;
