import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { selectMe } from 'store/selectors/me';
import { getOrders } from 'utils/api/orders';
import { getPartners } from 'utils/api/partners';
import { getPriceLists } from 'utils/api/priceLists';
import { getProducts } from 'utils/api/products';

const useCommerceChangeDisabled = () => {
  const { company } = useSelector(selectMe);

  // TODO: Use app wide query keys
  const { data: orders } = useQuery({
    queryKey: ['GET_ORDERS'],
    queryFn: () => getOrders({ page: 0, limit: 1 }),
  });
  const { data: partners } = useQuery({
    queryKey: ['GET_PARTNERS'],
    queryFn: () => getPartners({ page: 0, limit: 1 }),
  });
  const { data: priceLists } = useQuery({
    queryKey: ['GET_PRICE_LISTS'],
    queryFn: () => getPriceLists({ page: 0, limit: 1 }),
  });
  const { data: products } = useQuery({
    queryKey: ['GET_PRODUCTS'],
    queryFn: () => getProducts({ page: 0, limit: 1 }),
  });

  if (orders && orders?.length > 0) {
    return {
      isDisabled: true,
      reason: 'You cannot change your commerce type because you have Orders.',
    };
  }

  if (partners && partners?.data.length > 0) {
    return {
      isDisabled: true,
      reason:
        'You cannot change your commerce type because you have Partners. Please remove your partnerships first if you want to change your commerce type.',
    };
  }

  if (priceLists && priceLists?.data.length > 0) {
    return {
      isDisabled: true,
      reason:
        'You cannot change your commerce type because you have Price Lists. Please remove your Price Lists first if you want to change your commerce type.',
    };
  }

  if (company?.commerceType === 'seller' && products && products?.data.length > 0) {
    return {
      isDisabled: true,
      reason:
        'You cannot change your commerce type because you have Products. Please remove your Products first if you want to change your commerce type.',
    };
  }

  return {
    isDisabled: false,
    reason: 'Click to change your commerce type.',
  };
};

export default useCommerceChangeDisabled;
