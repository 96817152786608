import Flex from '@react-css/flex';
import SearchResult from 'storybook/stories/cells/SearchResult';
import {
  DateText,
  MainTableWrapper,
  TableMeta,
} from 'storybook/stories/cells/SearchResult/shared/Styles';
import Table from 'storybook/stories/cells/Table';
import Body from 'storybook/stories/molecules/Body';
import StatusIcon from 'storybook/stories/molecules/Icon/StatusIcon';
import type { PriceList } from 'types/models/price-list';
import { prettyDate } from 'utils/date';
import { toMarginString } from 'utils/prices';

const SellerPriceListFooter = ({
  priceList,
}: Pick<SellerPriceListSearchResultProps, 'priceList'>) => (
  <Flex alignItemsCenter gap="8px">
    <Flex.Item grow={1}>
      <Flex gap="16px">
        <StatusIcon variant={priceList.shouldAddNewProducts ? 'checked' : 'unchecked'}>
          <TableMeta>Apply to New Products</TableMeta>
        </StatusIcon>

        <StatusIcon variant={priceList.shouldIgnoreAutomatedSyncs ? 'checked' : 'unchecked'}>
          <TableMeta>Ignore Product Updates</TableMeta>
        </StatusIcon>
      </Flex>
    </Flex.Item>

    <DateText>Last updated {prettyDate(priceList.updated)}</DateText>
  </Flex>
);

const SellerPriceListTable = ({
  priceList,
}: Pick<SellerPriceListSearchResultProps, 'priceList'>) => (
  <MainTableWrapper>
    <Table>
      <Table.THead>
        <Table.TR>
          <Table.TH>Name</Table.TH>
          <Table.TH>Default Margin</Table.TH>
          <Table.TH>Currency</Table.TH>
          <Table.TH>Conversion</Table.TH>
          <Table.TH>Rounding</Table.TH>
        </Table.TR>
      </Table.THead>
      <Table.TBody>
        <Table.TR key={priceList.id}>
          <Table.TD nowrap>
            <Body variant="Body/Regular Bold">{priceList.name}</Body>
          </Table.TD>
          <Table.TD>
            {toMarginString({
              margin: priceList.dropshipMargin,
              marginType: priceList.dropshipMarginType,
              currency: priceList.sellerCurrency,
              convertFromDecimal: true,
            })}
          </Table.TD>
          <Table.TD>{priceList.sellerCurrency}</Table.TD>
          <Table.TD>
            {priceList.retailCurrencyConversionRate === 1
              ? 'N/A'
              : priceList.retailCurrencyConversionRate}
          </Table.TD>
          <Table.TD>{priceList.retailPriceFormat || 'N/A'}</Table.TD>
        </Table.TR>
      </Table.TBody>
    </Table>
  </MainTableWrapper>
);

interface SellerPriceListSearchResultProps {
  priceList: PriceList;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const SellerPriceListSearchResult = ({ priceList, onClick }: SellerPriceListSearchResultProps) => (
  <SearchResult onClick={onClick}>
    <Flex column gap="16px">
      <Flex.Item grow={1}>
        <SellerPriceListTable priceList={priceList} />
      </Flex.Item>
      <SellerPriceListFooter priceList={priceList} />
    </Flex>
  </SearchResult>
);

export default SellerPriceListSearchResult;
