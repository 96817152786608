import Flex from '@react-css/flex';
import { useSelector } from 'react-redux';

import PlatformBadge from 'components/Common/PlatformBadge';
import UploadProductCSVButton from 'containers/Products/SellerProductsPage/UploadProductCSVButton';
import { selectProductPlatform } from 'store/selectors/me/company';
import Tooltip from 'storybook/stories/cells/Tooltip';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { Platform } from 'types/models/company';
import { friendlyPlatformName } from 'utils/platform';

interface HeaderRowButtonsProps {
  onFileUploaded?: () => void;
  hasProducts?: boolean;
}

const HeaderRowButtons = ({ onFileUploaded, hasProducts }: HeaderRowButtonsProps) => {
  const productPlatform = useSelector(selectProductPlatform);

  if (productPlatform === Platform.Other && !hasProducts) return null;

  if (productPlatform === Platform.Other)
    return (
      <Flex justifyCenter alignItemsCenter gap="8px">
        {onFileUploaded && <UploadProductCSVButton onFileUploaded={onFileUploaded} />}

        <SecondaryButton size="small" as={SupportLink} article="7og0t9go64">
          <Icon name="help">Guide</Icon>
        </SecondaryButton>
      </Flex>
    );

  if (productPlatform === Platform.CsvSftp)
    return (
      <Flex.Item>
        <PlatformBadge>
          <Tooltip>
            <Tooltip.Trigger asChild>
              <Icon name="help" position="after">
                Managed with SFTP
              </Icon>
            </Tooltip.Trigger>
            <Tooltip.Content>
              These products are synced from your product file(s) in the SFTP server. You can manage
              active status here. If you want to make other changes, please update your product
              file(s) and reupload them in the SFTP server.
            </Tooltip.Content>
          </Tooltip>
        </PlatformBadge>
      </Flex.Item>
    );

  const platformName = friendlyPlatformName(productPlatform);

  return (
    <Flex.Item>
      <PlatformBadge>
        <Tooltip>
          <Tooltip.Trigger asChild>
            <Icon name="help" position="after">
              Managed in {platformName}
            </Icon>
          </Tooltip.Trigger>
          <Tooltip.Content>
            You can manage active status and tags here. If you want to make other changes, please
            visit your {platformName} account.
          </Tooltip.Content>
        </Tooltip>
      </PlatformBadge>
    </Flex.Item>
  );
};

export default HeaderRowButtons;
