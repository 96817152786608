/* eslint-disable jsx-a11y/control-has-associated-label */
import { useCallback, useEffect, useState } from 'react';

import { FILE_UPLOAD, HELLO_SIGN } from 'containers/PartnerPage/Actions/Kinds';
import { ACTIONS_LIMIT, getFriendlyNameForActionKind } from 'containers/PartnerPage/Actions/Shared';
import { deleteConsumableAction, getActionsSent } from 'store/slices/partners';
import Icon from 'storybook/stories/molecules/Icon';
import { getDocument } from 'utils/api/documents';
import { convertBase64ToBlob } from 'utils/files';

import { useMutation } from '@tanstack/react-query';
import download from 'downloadjs';
import useAlertQueue from 'hooks/useAlertQueue';
import useScopedSentryMessage from 'hooks/useScopedSentryMessage';
import { useAppDispatch, useAppSelector } from 'store';
import { selectMe, selectPartners } from 'store/selectors/me';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import { getAgreementFile } from 'utils/api/agreements';
import ActionsPagination from './ActionsPagination';
import type { EditActionCardModalState } from './EditActionCardModal';
import './OwnedActions.css';

type OwnedActionsProps = {
  name: string;
  setCardEditDetails: (cardEditDetails: EditActionCardModalState) => void;
  partnerId: string;
  setShowCreateModal: (showCreateModal: boolean) => void;
  setShowEditModal: (showEditModal: boolean) => void;
};

const OwnedActions = ({
  name,
  setCardEditDetails,
  partnerId,
  setShowCreateModal,
  setShowEditModal,
}: OwnedActionsProps) => {
  const [page, setPage] = useState(0);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const { company } = useAppSelector(selectMe);
  const alertQueue = useAlertQueue();
  const sendSentryError = useScopedSentryMessage('error');

  const downloadingAgreement = useMutation({
    mutationFn: (agreementId: string) => getAgreementFile(agreementId),
    onSuccess: async ({ data: { bytes } }: { data: { bytes: string } }) => {
      download(convertBase64ToBlob(bytes, 'application/pdf'), 'agreement.pdf', 'application/pdf');
    },
    onError: (apiError: Error) => {
      setError('Unable to download file');
      alertQueue.addErrorAlert('Something went wrong', 'Please contact support@convictional.com');
      sendSentryError('Unable to download file', { extra: { apiError } });
    },
  });

  const downloadingDocument = useMutation({
    mutationFn: (documentId: string) => getDocument(documentId),
    onSuccess: async (blob: Blob) => {
      download(blob);
    },
    onError: (apiError: Error) => {
      setError('Unable to download file');
      alertQueue.addErrorAlert('Something went wrong', 'Please contact support@convictional.com');
      sendSentryError('Unable to download file', { extra: { apiError } });
    },
  });

  // Get actions that are owned
  const refreshActionsSent = useCallback(() => {
    if (company && company._id) {
      const assignedActionsParams = {
        assignedFrom: company._id,
        partnerId,
        page,
        limit: ACTIONS_LIMIT,
      };
      dispatch(getActionsSent(assignedActionsParams));
    }
  }, [company, dispatch, page, partnerId]);

  useEffect(() => {
    refreshActionsSent();
  }, [company, page, refreshActionsSent]);
  const { actionsSent } = useAppSelector(selectPartners);

  // Events that can happen on a single action
  const deleteActions = (e: React.MouseEvent<HTMLButtonElement>, actionId: string) => {
    e.preventDefault();

    dispatch(deleteConsumableAction(actionId)).then(() => {
      if (!company) {
        return;
      }
      dispatch(
        getActionsSent({ assignedFrom: company._id, partnerId, page: 0, limit: ACTIONS_LIMIT })
      );
    });
  };

  const displayName = name || 'your partner';
  if (!actionsSent || actionsSent.length === 0) {
    return (
      <div className="card">
        <div className="card-body text-center empty-owned-actions-card-body">
          <h2 className="mb-2">Get Started with Actions!</h2>
          <p className="text-muted mb-3">
            Modern Dropship can help you facilitate communications with {displayName}. You can
            request a task to be completed by {displayName}. You can request files, have agreements
            signed, or have documents acknowledged.
          </p>
          <div className="create-action-btn-wrapper">
            <PrimaryButton onClick={() => setShowCreateModal(true)} color="primary">
              Create your first action
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card" id="actions-owned-card">
      <div className="card-body">
        <h2 className="mb-2">Actions you have assigned</h2>
        <p className="small text-muted mb-3">
          You are requesting the following actions from {displayName}.
        </p>
        <div className="create-action-btn-wrapper">
          <PrimaryButton onClick={() => setShowCreateModal(true)} color="primary">
            Create New Action
          </PrimaryButton>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <table className="table table-sm">
          <thead>
            <tr key="header-row">
              <th scope="col">Card</th>
              <th scope="col">Type</th>
              <th scope="col" aria-label="Badge" />
            </tr>
          </thead>
          <tbody>
            {actionsSent.map((actionsSentItem) => {
              const {
                id,
                cardTitle,
                cardText,
                kind,
                required,
                completed,
                agreementId,
                documentId,
              } = actionsSentItem;
              let downloadContent = null;
              if (kind === HELLO_SIGN && agreementId && agreementId !== '') {
                downloadContent = (
                  // eslint-disable-next-line react/button-has-type
                  <button
                    className="modify-card-details-btn"
                    onClick={() => downloadingAgreement.mutate(agreementId)}
                  >
                    <Icon name="download" />
                  </button>
                );
              } else if (kind === FILE_UPLOAD && documentId && documentId !== '') {
                downloadContent = (
                  // eslint-disable-next-line react/button-has-type
                  <button
                    className="modify-card-details-btn"
                    onClick={() => downloadingDocument.mutate(documentId)}
                  >
                    <Icon name="download" />
                  </button>
                );
              }
              return (
                <tr key={Math.random()}>
                  <td>
                    <b>{cardTitle}</b>
                    <br />
                    {cardText}
                  </td>
                  <td>{getFriendlyNameForActionKind(kind)}</td>
                  <td className="text-right nowrap">
                    {required ? null : (
                      <span className="badge badge-soft-info owned-actions-optional-badge">
                        Optional
                      </span>
                    )}
                    {completed ? <span className="badge badge-success">Completed</span> : null}
                    {downloadContent}

                    {!completed ? (
                      <>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <button
                          className="modify-card-details-btn"
                          onClick={() => {
                            setCardEditDetails({
                              id,
                              cardTitle,
                              cardText,
                              kind,
                              required,
                            });
                            setShowEditModal(true);
                          }}
                        >
                          <Icon name="drive_file_rename_outline" />
                        </button>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <button
                          className="modify-card-details-btn"
                          onClick={(e) => deleteActions(e, id)}
                        >
                          <Icon name="delete" />
                        </button>
                      </>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ActionsPagination
          page={page}
          setPage={setPage}
          resultsSize={actionsSent.length}
          limit={ACTIONS_LIMIT}
        />
      </div>
    </div>
  );
};

export default OwnedActions;
