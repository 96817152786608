import styled, { css } from 'styled-components';

import type { ButtonProps } from 'storybook/stories/molecules/Button';
import Button, {
  ButtonKinds,
  ButtonSizes,
  getButtonPadding,
  getButtonTypography,
} from 'storybook/stories/molecules/Button';

type GetButtonColorSchemeArg = Pick<SecondaryButtonProps, '$iconAfter' | 'kind'>;

const getButtonColorScheme = ({
  $iconAfter,
  kind = ButtonKinds.Action,
}: GetButtonColorSchemeArg) => {
  if (kind === ButtonKinds.Action) {
    return css`
      background: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.bodyTextLinks};
      border-color: ${({ theme }) => theme.color.bodyTextLinks};

      &:hover,
      &:focus {
        background: ${({ theme }) => theme.color.gray200};
        color: ${({ theme }) => theme.color.gray700};
        border-color: ${({ theme }) => theme.color.gray700};
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${({ theme }) => theme.color.gray700};
      }
    `;
  }

  if (kind === ButtonKinds.Neutral) {
    return css`
      background: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.bodyTextSecondary};
      border-color: ${({ theme }) => theme.color.bodyTextSecondary};

      &:hover,
      &:focus {
        background: ${({ theme }) => theme.color.gray200};
        color: ${({ theme }) => theme.color.gray700};
        border-color: ${({ theme }) => theme.color.gray500};
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${({ theme }) => theme.color.gray500};
      }
    `;
  }

  if (kind === ButtonKinds.Destructive) {
    return css`
      background: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.error500};
      border-color: ${({ theme }) => theme.color.error500};

      &:hover,
      &:focus {
        background: ${({ theme }) => theme.color.error100};
        color: ${({ theme }) => theme.color.error700};
        border-color: ${({ theme }) => theme.color.error700};
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${({ theme }) => theme.color.error700};
      }
    `;
  }

  return css``;
};

interface SecondaryButtonProps extends ButtonProps {
  /**
   * The kind of action this button describes
   */
  kind?: `${ButtonKinds}`;
}

/**
 * A composed `Button` component that represents the secondary action a user can take on a page.
 * - `size` is defaulted to `ButtonSizes.Large`
 * - `kind` is defaulted to `ButtonKinds.Action`
 */
const SecondaryButton = styled(Button).attrs<SecondaryButtonProps>(
  ({ size = ButtonSizes.Large }) => ({ size })
)<SecondaryButtonProps>`
  position: relative;
  border-radius: 100px;
  border: ${({ size }) => (size === ButtonSizes.Large ? '2px' : '1px')} solid transparent;

  /* Set up inset ring for focus, using the pseudo selector not bound to an icon */
  ${({ $iconName, $iconAfter, size }) => css`
    &::${$iconName && $iconAfter ? 'before' : 'after'} {
      position: absolute;
      inset: 3px;
      display: block;
      content: '';
      border: ${size === ButtonSizes.Large ? '2px' : '1px'} solid transparent;
      border-radius: 100px;
    }
  `}

  /* Determine appropriate typography */
  ${({ size, disabled }) => getButtonTypography({ size, disabled })}

  /* Determine appropriate padding */
  ${({ size, $iconName, $iconAfter }) => getButtonPadding({ size, $iconName, $iconAfter })}

  /* Determine color scheme */
  ${({ kind, $iconAfter }) => getButtonColorScheme({ kind, $iconAfter })}

  /* Maintain a static disabled state */
  &:disabled {
    background: transparent;
    color: ${({ theme }) => theme.color.bodyTextDisabled};
    border-color: ${({ theme }) => theme.color.bodyTextDisabled};
  }
`;

export default SecondaryButton;
