import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import {
  updateCompany,
  type UpdateCompanyParams,
  type UpdateCompanyResponse,
} from 'utils/api/companies';

interface SLAState {
  isUpdatingSLA: boolean;
}

const initialState: SLAState = {
  isUpdatingSLA: false,
};

export const updateCompanySLA = createAsyncThunk<
  Promise<UpdateCompanyResponse> | null,
  UpdateCompanyParams,
  { state: RootState }
>('settings/sla/update', (updates, thunkAPI) => {
  const { me } = thunkAPI.getState();
  const { company } = me;
  if (!company) return company;
  return updateCompany(company._id, updates);
});

const slice = createSlice({
  name: 'settings/company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCompanySLA.pending, (draft) => {
        draft.isUpdatingSLA = true;
      })
      .addMatcher(isAnyOf(updateCompanySLA.fulfilled, updateCompanySLA.rejected), (draft) => {
        draft.isUpdatingSLA = false;
      });
  },
});

export default slice.reducer;
