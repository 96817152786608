import { useEffect, useState } from 'react';
import { getRequest } from 'utils/request';

type UseCSRFTokenArgs = {
  isAppLoading: boolean;
};

const useCSRFToken = ({ isAppLoading }: UseCSRFTokenArgs) => {
  const [isCSRFReady, setIsCSRFReady] = useState(false);

  useEffect(() => {
    if (isCSRFReady || isAppLoading) return;

    const makeRequest = async () => {
      await getRequest('/');
      setIsCSRFReady(true);
    };

    makeRequest();
  }, [isCSRFReady, isAppLoading]);

  return { isCSRFReady };
};

export default useCSRFToken;
