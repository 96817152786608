import styled from 'styled-components';

import Alert from 'storybook/stories/cells/Alert';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

interface DuplicateSkuAlertProps {
  duplicateVariantSkus: string[];
}

const DuplicateSkuAlert = ({ duplicateVariantSkus }: DuplicateSkuAlertProps) => {
  if (duplicateVariantSkus.length === 0) return null;

  return (
    <StyledAlert type="error" title="This page of Products contains duplicate SKUs">
      To avoid issues each product variant must have a unique SKU. For more information please read
      our guide on{' '}
      <SupportLink article="qmz6lfbfni#duplicate_sk_us">fixing duplicate SKUs</SupportLink>. The
      non-unique SKUs on this page are: <strong>{duplicateVariantSkus.join(', ')}</strong>
    </StyledAlert>
  );
};

export default DuplicateSkuAlert;
