import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useQuery } from '@tanstack/react-query';
import DashboardPage from 'components/Dashboard/DashboardPage';
import CreateReportCard from 'containers/ReportingPage/CreateReportCard';
import ReportCard from 'containers/ReportingPage/ReportCard';
import ReportModal from 'containers/ReportingPage/ReportModal';
import useAlertQueue from 'hooks/useAlertQueue';
import { useState } from 'react';
import Page from 'storybook/stories/cells/Page';
import type { Question } from 'types/models/question';
import { useDocumentTitle } from 'usehooks-ts';
import { fetchReportingQuestions } from 'utils/api/reporting';
import type ApiError from 'utils/ApiError';

const ReportingPage = () => {
  useDocumentTitle('Reporting');

  const alertQueue = useAlertQueue();
  const [expandedQuestion, setExpandedQuestion] = useState<Question | null>(null);

  /**
   * Queries
   */

  const fetchingQuestions = useQuery({
    queryKey: ['fetchReportingQuestions'],
    queryFn: () => fetchReportingQuestions(),
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Error', error.message);
    },
  });

  const questions = fetchingQuestions.data?.data;

  /**
   * Event Handlers
   */

  const onExpandQuestionClick = (question?: Question) => {
    if (question) setExpandedQuestion(question);
  };

  const onModalHide = () => {
    setExpandedQuestion(null);
  };

  /**
   * Render
   */

  return (
    <DashboardPage>
      <Page>
        <Page.Head
          title="Reporting"
          description="Reports are refreshed daily. Please check back tomorrow for the latest update."
        />

        <Flex column gap="24px">
          <CreateReportCard refetchQuestions={fetchingQuestions.refetch} />

          <Grid columns="repeat(2, 1fr)" gap="24px">
            {questions?.map((question) => (
              <ReportCard
                key={question.id}
                askedQuestion={question}
                refetchQuestions={fetchingQuestions.refetch}
                onExpandQuestionClick={onExpandQuestionClick}
              />
            ))}
          </Grid>
        </Flex>

        <ReportModal question={expandedQuestion} onHide={onModalHide} />
      </Page>
    </DashboardPage>
  );
};

export default ReportingPage;
