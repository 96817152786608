import { FloatingChatContext } from 'containers/App/Contexts/FloatingChatContext';
import { useCallback, useContext, useEffect, useState } from 'react';

const useFloatingChatPrompt = () => {
  const { showPrompt, hidePrompt, isWindowOpen } = useContext(FloatingChatContext);
  const [hasAlreadyPrompted, setHasAlreadyPrompted] = useState(false);

  const tryPrompting = useCallback(() => {
    if (hasAlreadyPrompted) return;
    if (isWindowOpen) {
      console.log('Unable to prompt user, chat window is already open');
      return;
    }

    showPrompt();
    setHasAlreadyPrompted(true);
  }, [isWindowOpen, hasAlreadyPrompted, showPrompt]);

  useEffect(() => {
    return () => {
      hidePrompt();
      setHasAlreadyPrompted(false);
    };
  }, [hidePrompt]);

  return { tryPrompting };
};

export default useFloatingChatPrompt;
