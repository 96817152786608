import Flex from '@react-css/flex';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Message from 'components/Common/Message';
import useAlertQueue from 'hooks/useAlertQueue';
import Body from 'storybook/stories/molecules/Body';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import type { Invoice } from 'types/models/invoice';
import { InvoicePoster, type Partner } from 'types/models/partner';
import { resetInvoice } from 'utils/api/invoices';
import { friendlyInvoiceSource } from 'utils/invoices';

const resetMessage = (newInvoicePoster: InvoicePoster) => {
  switch (newInvoicePoster) {
    case InvoicePoster.None:
      return "You've disabled automatic invoicing for this partner. Would you like to manage this invoice manually?";
    default:
      return `You've activated ${friendlyInvoiceSource(
        newInvoicePoster
      )} for this partner. Would you like to add this invoice to ${friendlyInvoiceSource(
        newInvoicePoster
      )} for payment?`;
  }
};

const resetButtonIcon = (newInvoicePoster: InvoicePoster) => {
  switch (newInvoicePoster) {
    case InvoicePoster.None:
      return 'back_hand';
    default:
      return 'post_add';
  }
};

const resetButtonMessage = (newInvoicePoster: InvoicePoster) => {
  switch (newInvoicePoster) {
    case InvoicePoster.None:
      return 'Manage Manually';
    default:
      return 'Add Invoice';
  }
};

interface InvoiceResetBannerProps {
  invoice: Invoice;
  partner: Partner;
}

const InvoiceResetBanner = ({ invoice, partner }: InvoiceResetBannerProps) => {
  const queryClient = useQueryClient();
  const { addSuccessAlert, addErrorAlert } = useAlertQueue();
  const resettingInvoice = useMutation({
    mutationFn: () => resetInvoice(invoice.id),
    onSuccess: () => {
      addSuccessAlert('Success', 'The invoice has been reset');
      queryClient.invalidateQueries(['getInvoice']);
    },
    onError: (error: any) => {
      addErrorAlert('Something went wrong', error.message);
      Sentry.captureException(error);
    },
  });

  return (
    <Message kind="info" show animate={false} onClose={undefined}>
      <Flex alignItemsCenter gap="16px">
        <Flex.Item grow={1}>
          <Body variant="Body/Regular">{resetMessage(partner.billingSettings.invoicePoster)}</Body>
        </Flex.Item>

        <SecondaryButton
          size="small"
          kind="neutral"
          $iconName={resetButtonIcon(partner.billingSettings.invoicePoster)}
          onClick={() => {
            resettingInvoice.mutate();
          }}
        >
          {resetButtonMessage(partner.billingSettings.invoicePoster)}
        </SecondaryButton>
      </Flex>
    </Message>
  );
};

export default InvoiceResetBanner;
