import Flex from '@react-css/flex';
import { isFinite } from 'lodash';
import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { getCurrencySymbol } from 'utils/currencies';
import type { CommonFooterProps, MarginOptionsType } from './Shared.types';
import { MarginOptions, MarginType } from './Shared.types';
import type { CreatePriceListContextValues } from './context';
import { CreatePriceListContext } from './context';

import Card from 'storybook/stories/cells/Card';
import ComboInput from 'storybook/stories/cells/ComboInput';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Label from 'storybook/stories/molecules/Label';
import ProgressBar from 'storybook/stories/organisms/ProgressBar';

export const calculatePartnerPaysYou = (
  yourPrice: number,
  margin: number,
  marginType: MarginType
) => {
  switch (marginType) {
    case MarginType.Fixed:
      return yourPrice - margin;
    default:
      return yourPrice * (1 - margin / 100);
  }
};

const Footer = ({ isNextButtonDisabled }: CommonFooterProps) => {
  const { yourCurrency, partnerCurrency, updateState } = useContext(CreatePriceListContext);

  const lastStep = yourCurrency === partnerCurrency ? 'CurrencyForm' : 'RoundingRulesForm';
  const onClickBack = () => updateState({ currentStep: lastStep });

  return (
    <Card>
      <Flex justifySpaceBetween>
        <TertiaryButton size="small" $iconName="navigate_before" onClick={onClickBack}>
          Back
        </TertiaryButton>

        <PrimaryButton type="submit" size="small" $iconName="check" disabled={isNextButtonDisabled}>
          Create Price List
        </PrimaryButton>
      </Flex>
    </Card>
  );
};

const MarginForm = () => {
  const { margin, marginType, yourCurrency, submitNewPriceList } =
    useContext(CreatePriceListContext);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      margin,
      marginType,
    },
  });

  const marginFormValue = watch('margin');
  const marginTypeFormValue = watch('marginType');

  const shouldShowMarginExample =
    isFinite(marginFormValue) && marginFormValue !== null && marginFormValue >= 0;

  const getMarginTypeSymbol = (currentMarginType: string) => {
    switch (currentMarginType) {
      case MarginType.Percent:
        return '%';
      case MarginType.Fixed:
        return getCurrencySymbol(yourCurrency);
      default:
        return '%';
    }
  };

  const onSubmit = (data: Partial<CreatePriceListContextValues>) => {
    // we can be sure that these values aren't undefined because we set defaults in the useForm hook
    submitNewPriceList(data.margin!!, data.marginType!!);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex column gap="16px">
        <Card>
          <Flex column gap="28px">
            <ProgressBar totalSteps={5} completedSteps={5} />

            <Flex column gap="32px">
              <Flex column gap="8px">
                <Heading variant="Headings/H3">What margin have you agreed on?</Heading>
                <Body variant="Body/Regular" color="bodyTextSecondary">
                  This is the default margin for the price list. You can configure the price for
                  individual products after the price list has been created.
                </Body>
              </Flex>

              <Flex justifySpaceBetween>
                <Flex column gap="8px">
                  <Label htmlFor="margin" isRequired>
                    Margin
                  </Label>

                  <ComboInput>
                    <ComboInput.Input
                      type="number"
                      min="0"
                      placeholder="0"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('margin', { required: true, valueAsNumber: true })}
                    />
                    <Controller
                      name="marginType"
                      control={control}
                      render={({ field }) => (
                        <ComboInput.Select
                          defaultValue={MarginOptions[0]}
                          options={MarginOptions}
                          value={MarginOptions.find((option) => option.value === field.value)}
                          onChange={(option: MarginOptionsType) =>
                            field.onChange(option?.value ?? MarginOptions[0].value)
                          }
                        />
                      )}
                    />
                  </ComboInput>

                  {shouldShowMarginExample && (
                    <Flex alignItemsCenter gap="8px">
                      <Body variant="Body/Body Small" color="blue800">
                        Margin Example:
                      </Body>
                      <Body variant="Body/Body Small" color="gray500">
                        {/*
                          Example of what the Flex below looks like:
                          C$200.00 retail price - 75% margin = partner pays you C$50.00
                      */}
                        <Flex alignItemsCenter gap="4px">
                          {getCurrencySymbol(yourCurrency)}200.00 retail price - {marginFormValue}
                          {getMarginTypeSymbol(marginTypeFormValue)} margin = partner pays you{' '}
                          {getCurrencySymbol(yourCurrency)}
                          {calculatePartnerPaysYou(
                            200,
                            marginFormValue,
                            marginTypeFormValue
                          ).toFixed(2)}
                        </Flex>
                      </Body>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Footer isNextButtonDisabled={!isValid || isSubmitting} />
      </Flex>
    </form>
  );
};

export default MarginForm;
