import styled, { css } from 'styled-components';

import typography from 'storybook/mixins/typography';

export enum ChipVariants {
  Default = 'default',
  Light = 'light',
}

export enum ChipSizes {
  Large = 'large',
  Small = 'small',
}

export interface ChipProps {
  /**
   * The visual presentation of the UI
   */
  variant?: `${ChipVariants}`;
  /**
   * The size of the UI
   */
  size?: `${ChipSizes}`;
}

/**
 * A chip UI used for creating a colorized bit of text.
 */

const Chip = styled.span<ChipProps>`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.color.bodyTextPrimary};

  ${({ size = ChipSizes.Large }) => {
    switch (size) {
      case ChipSizes.Large:
        return css`
          padding: 0 8px;
          height: 24px;
          ${typography('Chips/Chip Text Large')};
        `;
      case ChipSizes.Small:
        return css`
          padding: 0 6px;
          height: 20px;
          ${typography('Chips/Chip Text Small')};
        `;
      default:
        return css``;
    }
  }}

  ${({ theme, variant = ChipVariants.Default }) => {
    switch (variant) {
      case ChipVariants.Default:
        return css`
          background: ${theme.color.blue050};
          border-color: ${theme.color.gray200};
        `;
      case ChipVariants.Light:
        return css`
          background: ${theme.color.white};
          border-color: ${theme.color.gray200};
        `;
      default:
        return css``;
    }
  }};
`;

export default Chip;
