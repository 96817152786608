import styled, { css } from 'styled-components';

import elevate from 'storybook/mixins/elevate';
import materialSymbol from 'storybook/mixins/materialSymbol';
import resetButtonStyles from 'storybook/mixins/resetButtonStyles';

export const BUTTON_SIZE = 26;

const Wrapper = styled.div`
  display: inline-flex;
  ${elevate('1')};
  border-radius: ${BUTTON_SIZE / 2}px;
`;

const Divider = styled.div`
  width: 1px;

  ${({ theme }) => css`
    background: linear-gradient(
      0deg,
      ${theme.color.blue500} 20%,
      ${theme.color.blue100} 20%,
      ${theme.color.blue100} 80%,
      ${theme.color.blue500} 80%
    );
  `}
`;

interface PageButtonProps {
  variant: 'back' | 'next';
}

const PageButton = styled.button<PageButtonProps>`
  ${resetButtonStyles};

  display: grid;
  place-content: center;

  height: ${BUTTON_SIZE}px;
  width: ${BUTTON_SIZE}px;

  ${({ theme, variant, disabled }) => css`
    background-color: ${theme.color.blue500};

    ${variant === 'back' &&
    css`
      border-radius: ${BUTTON_SIZE / 2}px 0 0 ${BUTTON_SIZE / 2}px;
    `}

    ${variant === 'next' &&
    css`
      border-radius: 0 ${BUTTON_SIZE / 2}px ${BUTTON_SIZE / 2}px 0;
    `}

    ${materialSymbol({
      name: variant === 'back' ? 'navigate_before' : 'navigate_next',
      color: 'white',
      additionalStyling:
        disabled &&
        css`
          opacity: 0.2;
        `,
    })}
  `}
`;

interface PageNavigationProps {
  /**
   * Enables/disables previous button
   */
  hasPrevious: boolean;
  /**
   * Enables/disables next button
   */
  hasNext: boolean;
  /**
   * Callback for previous button clicks
   */
  onPreviousClick: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Callback for next button clicks
   */
  onNextClick: React.MouseEventHandler<HTMLButtonElement>;
}

const PageNavigation = ({
  hasPrevious,
  hasNext,
  onPreviousClick,
  onNextClick,
}: PageNavigationProps) => (
  <Wrapper>
    <PageButton
      data-testid="pagination-back"
      variant="back"
      onClick={onPreviousClick}
      disabled={!hasPrevious}
      aria-label="Load Previous Page of Results"
    />

    <Divider />

    <PageButton
      data-testid="pagination-next"
      variant="next"
      onClick={onNextClick}
      disabled={!hasNext}
      aria-label="Load Next Page of Results"
    />
  </Wrapper>
);

export default PageNavigation;
