import Grid from '@react-css/grid';
import typography from 'storybook/mixins/typography';
import Button from 'storybook/stories/molecules/Button';
import Link from 'storybook/stories/molecules/Link';
import { AiIconWrapper, MessageContent } from 'storybook/stories/species/Chat/Shared.styles';
import styled from 'styled-components';

const StyledMessageContent = styled(MessageContent)`
  background-color: ${({ theme }) => theme.color.gray100};
  ${typography('Body/Regular Italics')};
  color: ${({ theme }) => theme.color.bodyTextSecondary};
`;

interface ChatAwaitingAiMessageProps {
  onInterruptButtonClick: () => void;
}

const ChatAwaitingAiMessage = ({ onInterruptButtonClick }: ChatAwaitingAiMessageProps) => {
  return (
    <Grid columns="40px 1fr" gap="8px" alignItemsStart>
      <AiIconWrapper isLoading />
      <Grid.Item>
        <StyledMessageContent>
          Working on it...{' '}
          <Link as={Button} onClick={onInterruptButtonClick}>
            Interrupt?
          </Link>
        </StyledMessageContent>
      </Grid.Item>
    </Grid>
  );
};

export default ChatAwaitingAiMessage;
