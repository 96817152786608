import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import _get from 'lodash/get';

import UpdatedIndicator from 'components/Common/UpdatedIndicator';
import Body from 'storybook/stories/molecules/Body';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';

const OptionField = ({
  canEdit,
  value,
  optionName,
  optionNumber,
  hasEdits,
  onChange = () => {},
}) => {
  return (
    <Flex column gap="8px">
      <Label htmlFor={`option-${optionNumber}`}>
        Option {optionNumber} {optionName ? `- ${optionName}` : ''}{' '}
        {hasEdits && <UpdatedIndicator className="ml-1" />}
      </Label>
      {/* eslint-disable-next-line no-nested-ternary */}
      {canEdit && optionName ? (
        <Input
          type="text"
          className="form-control form-control-sm"
          name={`option-${optionNumber}`}
          aria-label={optionName || `Option ${optionNumber}`}
          value={value}
          onChange={onChange}
        />
      ) : optionName && value ? (
        <Body>{value}</Body>
      ) : (
        <Body color="bodyTextDisabled">None</Body>
      )}
    </Flex>
  );
};

const SelectedVariantOptions = ({
  variant = {},
  originalVariant = {},
  canEdit,
  options = [],
  onChangeOption,
}) => {
  const { option1, option2, option3 } = variant;

  const sortedOptions = [...options].sort((a, b) => a.position - b.position); // sort on render, will never be more than 3

  const option1Name = _get(sortedOptions, ['0', 'name']);
  const option2Name = _get(sortedOptions, ['1', 'name']);
  const option3Name = _get(sortedOptions, ['2', 'name']);

  return (
    <Grid columns="repeat(3, 1fr)" gap="16px">
      <OptionField
        canEdit={canEdit}
        value={option1}
        optionName={option1Name}
        optionNumber={1}
        hasEdits={originalVariant.option1 !== option1}
        onChange={onChangeOption('option1')}
      />
      <OptionField
        canEdit={canEdit}
        value={option2}
        optionName={option2Name}
        optionNumber={2}
        hasEdits={originalVariant.option2 !== option2}
        onChange={onChangeOption('option2')}
      />
      <OptionField
        canEdit={canEdit}
        value={option3}
        optionName={option3Name}
        optionNumber={3}
        hasEdits={originalVariant.option3 !== option3}
        onChange={onChangeOption('option3')}
      />
    </Grid>
  );
};

export default SelectedVariantOptions;
