import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import Answer from 'containers/ReportingPage/Answer';
import ReportCardActionsDropdown from 'containers/ReportingPage/ReportCardActionsDropdown';
import { addDays, parseISO } from 'date-fns';
import Card from 'storybook/stories/cells/Card';
import Tooltip from 'storybook/stories/cells/Tooltip';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import Spinner from 'storybook/stories/molecules/Spinner';
import type { Question } from 'types/models/question';
import { askReportingQuestion } from 'utils/api/reporting';
import { prettyDateTime } from 'utils/date';

interface RefreshTooltipProps {
  question?: Question;
}

const RefreshTooltip: React.FC<RefreshTooltipProps> = ({ question }) => {
  const refreshDate = question?.answeredAt && addDays(parseISO(question.answeredAt), 1);

  if (!refreshDate) return null;

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <Icon name="info" color="iconDefault" />
      </Tooltip.Trigger>
      <Tooltip.Content>This answer will refresh on {prettyDateTime(refreshDate)}.</Tooltip.Content>
    </Tooltip>
  );
};

interface ReportCardProps {
  askedQuestion: Question;
  refetchQuestions: () => void;
  onExpandQuestionClick: (question?: Question) => void;
}

const ReportCard: React.FC<ReportCardProps> = ({
  askedQuestion,
  refetchQuestions,
  onExpandQuestionClick,
}) => {
  /**
   * Queries
   */

  const askingQuestion = useQuery({
    queryKey: ['askReportingQuestion', askedQuestion.id],
    queryFn: () => askReportingQuestion(askedQuestion.id),
  });

  const answeredQuestion = askingQuestion.data?.data;

  /**
   * Render
   */

  return (
    <Card>
      <Card.Head>
        <Flex gap="16px" alignItemsStart>
          <Flex.Item grow={1}>
            <Heading variant="Headings/H4" color="bodyTextSecondary">
              {askedQuestion.question}
            </Heading>
          </Flex.Item>

          <Flex gap="8px">
            <RefreshTooltip question={answeredQuestion} />

            <ReportCardActionsDropdown
              refetchQuestions={refetchQuestions}
              question={answeredQuestion}
              onExpandQuestionClick={onExpandQuestionClick}
            />
          </Flex>
        </Flex>
      </Card.Head>

      <Card.Body>
        {askingQuestion.isLoading ? (
          <Flex justifyCenter>
            <Spinner />
          </Flex>
        ) : (
          <Answer answer={answeredQuestion?.answer} />
        )}
      </Card.Body>
    </Card>
  );
};

export default ReportCard;
