import Flex from '@react-css/flex';
import Spinner from 'components/Common/Spinner';
import ContractDeleteSVG from 'images/contract_delete.svg';
import { useSelector } from 'react-redux';
import { selectIsBuyer } from 'store/selectors/me/company';
import { COMPLETED, SKIPPED, useFetchGetStarted } from 'store/slices/getStarted';
import NoResultsCard from 'storybook/stories/cells/Card/NoResultsCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

interface EmptyInvoicesContentProps {
  isBuyer: boolean;
  hasConfiguredPayment: boolean;
}

const EmptyInvoicesContent = ({ isBuyer, hasConfiguredPayment }: EmptyInvoicesContentProps) => {
  const configurePaymentMessage = isBuyer
    ? 'To pay your vendors, you must add a card on file.'
    : 'Modern Dropship uses Stripe to automate billing. You can create or connect an existing Stripe account in Billing Settings.';

  const callToAction = isBuyer ? 'Manage Payment Method' : 'Connect Stripe';

  return (
    <>
      {!hasConfiguredPayment && (
        <Body variant="Body/Regular" color="bodyTextSecondary">
          {configurePaymentMessage}
        </Body>
      )}

      <Flex gap="16px">
        <SecondaryButton as={SupportLink} article="8lwln18tin" $iconName="help">
          Learn How Invoicing Works
        </SecondaryButton>

        {!hasConfiguredPayment && (
          <PrimaryButton as={Link} to="/settings/dropshipping/billing" $iconName="credit_card">
            {callToAction}
          </PrimaryButton>
        )}
      </Flex>
    </>
  );
};

const EmptyInvoicesPage = () => {
  const { connectStripe, addPaymentMethod } = useSelector((state: any) => state.getStarted);
  const { loading } = useFetchGetStarted();
  const isBuyer = useSelector(selectIsBuyer);

  const acceptedStates = [COMPLETED, SKIPPED];

  const hasConfiguredPayment = isBuyer
    ? acceptedStates.includes(addPaymentMethod.status)
    : acceptedStates.includes(connectStripe.status);

  if (loading) {
    return <Spinner />;
  }

  return (
    <NoResultsCard
      data-testid="empty-invoices-page"
      imageSrc={ContractDeleteSVG}
      title="You don't have any invoices yet"
      description="Invoices are automatically created and paid within two hours after shipping each order. "
    >
      <EmptyInvoicesContent isBuyer={isBuyer} hasConfiguredPayment={hasConfiguredPayment} />
    </NoResultsCard>
  );
};

export default EmptyInvoicesPage;
