import { useMemo, useState } from 'react';
import Modal from 'storybook/stories/cells/Modal';

import Button from 'components/Common/Button';
import Message, { useMessage } from 'components/Common/Message';
import { deletePriceList } from 'utils/api/priceLists';

const DeletePriceListModal = ({ partnerList, priceList, onDismiss, show, onPriceListDeleted }) => {
  const [deleting, setDeleting] = useState(false);
  const { showErrorMessage, messageState, hideMessage } = useMessage();

  const assignedTo = useMemo(() => {
    if (!partnerList || !priceList) return [];
    return partnerList.filter((partner) => partner.pricingId === priceList.id);
  }, [partnerList, priceList]);
  const isAssigned = assignedTo.length > 0;

  const onCloseModal = () => {
    hideMessage();
    setDeleting(false);
    onDismiss();
  };

  const onDelete = async () => {
    const priceId = priceList.id;
    setDeleting(true);
    try {
      await deletePriceList(priceId);
      onCloseModal();
      onPriceListDeleted();
    } catch (err) {
      showErrorMessage({ title: 'Error deleting price list', message: err.message });
      setDeleting(false);
    }
  };

  return (
    <Modal.Root
      show={show}
      size="md"
      aria-labelledby="delete-product-modal-title"
      centered
      onHide={onCloseModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isAssigned ? 'Delete Conflict' : 'Confirm Pirce List Delete'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Message
          show={messageState.show}
          onClose={hideMessage}
          kind={messageState.kind}
          className="mt-0"
        >
          <p className="m-0 mb-1">
            <strong>{messageState.title}</strong>
          </p>
          <p className="m-0">{messageState.message}</p>
        </Message>

        {isAssigned ? (
          <>
            <h4 className="mb-4">You cannot delete this price list right now</h4>
            <p>
              This price list cannot be deleted because it is assigned to the following partners:
            </p>
            <ul className="mb-4">
              {assignedTo.map((partner) => {
                const partnerName = partner.buyerName || partner.buyerCompanyId;
                return <li key={partnerName}>{partnerName}</li>;
              })}
            </ul>
          </>
        ) : (
          <>
            <h4 className="mb-4">Are you sure?</h4>
            <p className="mb-4">
              By deleting this price list you will lose your price list PERMANENTLY. You will have
              to add your price list again if you want to use it.
            </p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {isAssigned ? (
          <Button size="sm" color="white" type="button" onClick={onCloseModal}>
            Dismiss
          </Button>
        ) : (
          <>
            <Button size="sm" color="white" type="button" onClick={onCloseModal}>
              Dismiss
            </Button>
            <Button
              size="sm"
              type="submit"
              color="danger"
              className="ml-3"
              disabled={deleting}
              onClick={onDelete}
            >
              Delete Price List
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal.Root>
  );
};

export default DeletePriceListModal;
