/* eslint-disable jsx-a11y/label-has-associated-control */
import _get from 'lodash/get';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import Button from 'components/Common/Button';
import CardFormField from 'components/Common/CardFormField';
import FormField, { FormFieldLabel } from 'components/Common/FormField';
import Spinner from 'components/Common/Spinner';
import { useSafeState } from 'utils/hooks';
import { validators } from 'utils/sftpUser';

const DescriptionText = styled.p`
  margin-bottom: 0.313rem;
`;

export const getExternalSFTPAuthObject = (company) => {
  if (!company || !company.activeAuth) {
    return {};
  }
  if (Object.keys(company.activeAuth).length === 0) {
    return {};
  }

  const externalSftpAuth = company.activeAuth['external-sftp'];
  return externalSftpAuth || {};
};

const ArchiveMethodDescription = ({ archiveMethod }) => {
  switch (archiveMethod) {
    case 'move':
      return (
        <DescriptionText className="text-muted" style={{ fontSize: '0.688rem' }}>
          After the file is processed, we will move it into your archive directory. The SFTP user
          should have delete permission, write permission and the archive directory should exist.
        </DescriptionText>
      );
    case 'delete':
      return (
        <DescriptionText className="text-muted" style={{ fontSize: '0.688rem' }}>
          After the file is processed, we will delete the file. You can retrieve any processed file
          from Modern Dropship. The SFTP user should have delete permission.
        </DescriptionText>
      );
    default:
      return <br />;
  }
};

const ExternalSFTPSettingsForm = ({
  company,
  onSubmit,
  showSubmitButton,
  disableSubmitButton,
  submitButtonText,
}) => {
  const [archiveMethod, setArchiveMethod] = useSafeState(
    _get(company, 'sftp.archiveMethod.method', 'delete')
  );

  const [showPassword, setShowPassword] = useSafeState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: _get(getExternalSFTPAuthObject(company), 'username', ''),
      password: _get(getExternalSFTPAuthObject(company), 'password', ''),
      host: _get(getExternalSFTPAuthObject(company), 'address', ''),
      port: _get(getExternalSFTPAuthObject(company), 'port', ''),
      hostKey: _get(getExternalSFTPAuthObject(company), 'hostkey', ''),
      uploadDirectory: _get(company, 'sftp.uploadDirectory', ''),
      downloadDirectory: _get(company, 'sftp.downloadDirectory', ''),
      archiveMethod,
      archiveDirectory: _get(company, 'sftp.archiveMethod.directory', 'outbound/archived/'),
    },
  });

  const passwordDisplay = {
    name: 'internal-sftp-password-display',
    icon: showPassword ? 'visibility_off' : 'visibility',
    event: setShowPassword,
    eventData: !showPassword,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardFormField
        id="external-sftp-host-form-field"
        label="Host"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register('host', validators.host)}
        error={errors.host}
      />
      <CardFormField
        id="external-sftp-port-form-field"
        label="Port"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register('port', validators.port)}
        error={errors.port}
      />
      <CardFormField
        id="external-sftp-username-form-field"
        label="Username"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register('username', validators.username)}
        error={errors.username}
      />
      <CardFormField
        data-private
        type={showPassword ? 'text' : 'password'}
        id="external-sftp-password-form-field"
        label="Password"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register('password', validators.password)}
        appendInput={[passwordDisplay]}
        placeholder="Password needs to be at least 12 characters"
        error={errors.password}
      />
      <FormField
        type="text"
        size="sm"
        id="external-sftp-hostkey"
        label="Host Key"
        placeholder="Enter an SSH public key for your SFTP server"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register('hostKey', validators.hostKey)}
        error={errors.hostKey}
        isTextarea
      />
      <CardFormField
        id="external-sftp-upload-directory-form-field"
        label="Upload Location"
        tooltipText="This is the SFTP server directory to which Modern Dropship will upload EDI files."
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register('uploadDirectory', validators.directory)}
        error={errors.uploadDirectory}
      />
      <CardFormField
        id="external-sftp-download-directory-form-field"
        label="Download Location"
        tooltipText="This is the SFTP server directory from which Modern Dropship will retrieve your product and order files."
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register('downloadDirectory', validators.directory)}
        error={errors.downloadDirectory}
      />
      <div className="form-group">
        <FormFieldLabel size="sm" htmlFor="external-sftp-archive">
          Archiving
        </FormFieldLabel>

        <ArchiveMethodDescription archiveMethod={archiveMethod} />

        <select
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('archiveMethod', validators.archiveMethod)}
          onChange={(event) => setArchiveMethod(event.target.value)}
        >
          <option value="delete">Delete</option>
          <option value="move">Move</option>
        </select>

        {archiveMethod.toLowerCase() === 'move' && (
          <CardFormField
            type="text"
            size="sm"
            id="external-sftp-archive-directory"
            label="Archive Location"
            placeholder="outbound/archived/"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('archiveDirectory', validators.directory)}
            error={errors.archiveDirectory}
          />
        )}
      </div>

      {showSubmitButton && (
        <div className="d-flex justify-content-end">
          <Button size="sm" color="primary" type="submit" disabled={disableSubmitButton}>
            {disableSubmitButton ? <Spinner color="white" small /> : submitButtonText}
          </Button>
        </div>
      )}
    </form>
  );
};

export default ExternalSFTPSettingsForm;
