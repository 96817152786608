/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useMutation } from '@tanstack/react-query';
import Button from 'components/Common/Button';
import DoneCheckmark from 'components/Common/DoneCheckmark';
import download from 'downloadjs';
import useAlertQueue from 'hooks/useAlertQueue';
import useScopedSentryMessage from 'hooks/useScopedSentryMessage';
import { acknowledgeConsumableAction } from 'store/slices/partners';
import Icon from 'storybook/stories/molecules/Icon';
import { getDocument } from 'utils/api/documents';

const HintText = styled.p`
  margin-top: 0.313rem;
  margin-bottom: 0.188rem;
`;
const AcknowledgeText = styled.p`
  padding-top: 0.313rem;
  padding-bottom: 0.188rem;
`;

const AcknowledgementAction = ({ disable, action, refreshPage }) => {
  const dispatch = useDispatch();
  const { cardTitle, cardText, required, documentId, completed } = action;
  const [error, setError] = useState('');
  const [hasSeenDocument, setHasSeenDocument] = useState(false);
  const alertQueue = useAlertQueue();
  const sendSentryError = useScopedSentryMessage('error');

  const downloadingDocument = useMutation({
    mutationFn: () => getDocument(documentId),
    onSuccess: async (blob) => {
      download(blob);
    },
    onError: (apiError) => {
      setError('Unable to download file');
      alertQueue.addErrorAlert('Something went wrong', 'Please contact support@convictional.com');
      sendSentryError('Unable to download file', { extra: { apiError } });
    },
  });

  const onViewDocument = (e) => {
    e.preventDefault();
    setHasSeenDocument(true);

    if (!disable) {
      downloadingDocument.mutate();
    }
  };
  const onAcknowledgeDocument = (e) => {
    e.preventDefault();
    if (!disable) {
      dispatch(acknowledgeConsumableAction(action.id)).then(() => {
        // Refresh the page data
        if (refreshPage) {
          refreshPage();
        }
      });
    }
  };

  return (
    <div className="single-action-wrapper align-items-center row">
      <div className="col-auto">
        <DoneCheckmark done={completed} />
      </div>
      <div className="ml-n2 col">
        {error && <div className="alert alert-danger alert-dismissible fade show">{error}</div>}
        <h4 className="mb-2">
          <span className="single-action-title">{cardTitle}</span>
          {required ? null : <span className="badge badge-soft-info">Optional</span>}
          {(hasSeenDocument || completed) && (
            <Button outline size="sm" color="secondary" onClick={onViewDocument}>
              View Document
            </Button>
          )}
        </h4>
        <HintText className="text-muted single-action-description">{cardText}</HintText>
        {completed ? (
          <AcknowledgeText className="acknowledge-completed-text">
            <Icon name="done">
              <span className="acknowledge-completed-inner-text">Acknowledged</span>
            </Icon>
          </AcknowledgeText>
        ) : hasSeenDocument ? (
          <Button size="sm" outline color="primary" onClick={onAcknowledgeDocument}>
            I acknowledge, I have seen and read through the document.
          </Button>
        ) : (
          <Button size="sm" outline color="secondary" onClick={onViewDocument}>
            View Document
          </Button>
        )}
      </div>
    </div>
  );
};

export default AcknowledgementAction;
