import Flex from '@react-css/flex';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Input from 'storybook/stories/molecules/Input';

interface CreateNamespaceRowProps {
  onAddNewNamespace: (namespace: string) => void;
}

const CreateNamespaceRow = ({ onAddNewNamespace }: CreateNamespaceRowProps) => {
  const [newNamespace, setNewNamespace] = useState<string>('');

  const onCreateNamespaceClick = () => {
    onAddNewNamespace(newNamespace);
    setNewNamespace('');
  };

  return (
    <Flex column gap="16px">
      <Heading variant="Headings/H3">Create a Namespace</Heading>

      <Flex alignItemsCenter>
        <Input
          value={newNamespace}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setNewNamespace(event.target.value)}
          placeholder="Enter a namespace"
        />

        <TertiaryButton
          disabled={!newNamespace}
          $iconName="add_circle"
          style={{ width: 'max-content' }}
          onClick={onCreateNamespaceClick}
        >
          Create Namespace
        </TertiaryButton>
      </Flex>
    </Flex>
  );
};

export default CreateNamespaceRow;
