import { useEffect } from 'react';

const useSellerOnlyPage = ({ isSeller, history }) => {
  useEffect(() => {
    if (!isSeller) {
      history.replace('/orders');
    }
  }, [isSeller, history]);
};

export default useSellerOnlyPage;
