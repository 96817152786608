import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useMutation } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import { sampleSize } from 'lodash';
import type React from 'react';
import { useForm } from 'react-hook-form';
import elevate from 'storybook/mixins/elevate';
import Card from 'storybook/stories/cells/Card';
import Button from 'storybook/stories/molecules/Button';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import styled from 'styled-components';
import type { Question } from 'types/models/question';
import { useBoolean } from 'usehooks-ts';
import { createReportingQuestion } from 'utils/api/reporting';
import type ApiError from 'utils/ApiError';

export const INSPIRATION_PROMPTS = [
  'What partners are closest to finishing onboarding?',
  'Do I have any partners that are under-performing?',
  'Which partners have the lowest order cancel rate?',
];

const InspirationButton = styled(Card)`
  cursor: pointer;

  &:hover {
    ${elevate('4')}
  }
`;

interface FormFields {
  question: string;
}

interface InspirationAccordionProps {
  setQuestionField: (question: FormFields['question']) => void;
}

const InspirationAccordion: React.FC<InspirationAccordionProps> = ({ setQuestionField }) => {
  const accordionVisibility = useBoolean(false);
  const inspirationPrompts = sampleSize(INSPIRATION_PROMPTS, 3);

  return (
    <Flex column gap="8px">
      <Flex.Item alignSelfCenter>
        <TertiaryButton
          $iconName={accordionVisibility.value ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          $iconAfter
          onClick={accordionVisibility.toggle}
        >
          Want Some Inspiration?
        </TertiaryButton>
      </Flex.Item>

      {accordionVisibility.value && (
        <Grid columns="repeat(3, 1fr)" gap="16px">
          {inspirationPrompts.map((question, index) => (
            <InspirationButton
              key={question}
              as={Button}
              onClick={() => setQuestionField(question)}
              data-testid={`inspiration-prompt-${index}`}
            >
              {question}
            </InspirationButton>
          ))}
        </Grid>
      )}
    </Flex>
  );
};

interface CreateReportCardProps {
  refetchQuestions: () => void;
}

const CreateReportCard: React.FC<CreateReportCardProps> = ({ refetchQuestions }) => {
  const alertQueue = useAlertQueue();

  /**
   * Mutations
   */

  const creatingQuestion = useMutation({
    mutationFn: (params: Partial<Question>) => createReportingQuestion(params),
    onSuccess: () => {
      alertQueue.addSuccessAlert('Success', 'Question saved');
      refetchQuestions();
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Error', error.message);
    },
  });

  /**
   * Form
   */

  const { handleSubmit, register, formState, setValue, reset } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: { question: '' },
  });

  /**
   * Event Handlers
   */

  const onSubmit = handleSubmit(({ question }) => {
    creatingQuestion.mutate({ question });
    reset();
  });

  /**
   * Render
   */

  return (
    <Card>
      <Flex column gap="16px">
        <form onSubmit={onSubmit}>
          <Flex column gap="8px">
            <Label htmlFor="question">
              You can ask questions about orders, partners, products, etc.
            </Label>

            <Flex gap="24px">
              <Flex.Item grow={1}>
                <Input
                  id="question"
                  isFullWidth
                  placeholder="Type your question here, or get some inspiration below."
                  {...register('question', { required: 'This field is required' })}
                />
              </Flex.Item>

              <PrimaryButton
                disabled={!formState.isValid || creatingQuestion.isLoading}
                type="submit"
              >
                Create Report
              </PrimaryButton>
            </Flex>
          </Flex>
        </form>

        <InspirationAccordion
          setQuestionField={(question: string) =>
            setValue('question', question, {
              shouldTouch: true,
              shouldDirty: true,
              shouldValidate: true,
            })
          }
        />
      </Flex>
    </Card>
  );
};

export default CreateReportCard;
