import useAllPartners from 'hooks/useAllPartners';
import { useSelector } from 'react-redux';
import type { Options, SingleValue } from 'react-select';
import { selectIsSeller } from 'store/selectors/me/company';
import type { ReactSelectOption } from 'types/general';
import type { RecordFacet } from 'types/models/search';

const usePartnerFilterSelectOptions = <
  Facets extends { buyerId: RecordFacet; sellerId: RecordFacet },
  Filters extends { buyerId?: string; sellerId?: string },
>(
  facets: Facets,
  filters?: Filters
) => {
  const isSeller = useSelector(selectIsSeller);
  const { partners } = useAllPartners({ params: { withDeleted: true } });

  const partnerCompanyIdsByResultCount = isSeller ? facets.buyerId : facets.sellerId;
  const partnerCompanyIds = Object.keys(partnerCompanyIdsByResultCount);

  // Find the partners that match the company IDs supplied in the facet results
  const filteredPartners = partners.filter((partner) =>
    partnerCompanyIds.includes(
      isSeller ? partner.buyerCompanyObjectId : partner.sellerCompanyObjectId
    )
  );

  // Create the select option with the proper information based on commerce type
  const partnerSelectOptions: Options<ReactSelectOption> = [
    {
      value: '',
      label: 'All',
    },
    ...filteredPartners.map((partner) => ({
      value: isSeller ? partner.buyerCompanyObjectId : partner.sellerCompanyObjectId,
      label: `${isSeller ? partner.buyerName : partner.sellerName} (${
        partnerCompanyIdsByResultCount[
          isSeller ? partner.buyerCompanyObjectId : partner.sellerCompanyObjectId
        ]
      })${partner.deletedAt ? ' (Deleted)' : ''}`,
    })),
  ];

  // Determine the default based on applied filters
  const defaultPartnerSelectOption: SingleValue<ReactSelectOption> =
    partnerSelectOptions.find(
      (option) => option.value === (isSeller ? filters?.buyerId : filters?.sellerId)
    ) || partnerSelectOptions[0];

  return { partnerSelectOptions, defaultPartnerSelectOption };
};

export default usePartnerFilterSelectOptions;
