import { useQuery } from '@tanstack/react-query';
import _isEmpty from 'lodash/isEmpty';
import { useMemo, useState } from 'react';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import styled from 'styled-components';

import Message from 'components/Common/Message';
import pluralize from 'pluralize';
import Icon from 'storybook/stories/molecules/Icon';
import { getPartners } from 'utils/api/partners';

const ExpandButton = styled(TertiaryButton)`
  padding: 0;
  text-align: left;
`;

interface CollapsableValidationErrorsProps {
  text: string;
  errorList: { fieldPath: string; message: string }[];
}

const CollapsableValidationErrors = ({ text, errorList }: CollapsableValidationErrorsProps) => {
  const [showErrors, setShowErrors] = useState(false);

  return (
    <>
      <ExpandButton onClick={() => setShowErrors((prevState) => !prevState)}>
        <Icon name={showErrors ? 'expand_less' : 'expand_more'} position="after">
          {text}
        </Icon>
      </ExpandButton>

      {showErrors && (
        <ul className="mb-1">
          {errorList.map((err) => {
            return (
              <li key={err.fieldPath + err.message}>
                <strong>{err.fieldPath}</strong> - {err.message}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

interface SellerValidationAlertProps {
  validationErrorsByBuyer: Record<string, { fieldPath: string; message: string }[]>;
}

type ErrorsByBuyer = {
  [buyerCompanyObjectId: string]: Array<
    | { fieldPath: string; message: string }
    | { fieldPath: string; message: string; buyerCompanyObjectId: string }
  >;
};

const SellerValidationAlert = ({ validationErrorsByBuyer }: SellerValidationAlertProps) => {
  const fetchingPartners = useQuery({
    queryKey: ['getAllPartners'],
    queryFn: () => getPartners({ limit: 250 }),
  });
  const partners = fetchingPartners.data?.data ?? [];

  // colapse the variant errors into a single error
  const errorListByBuyer = useMemo(() => {
    if (_isEmpty(validationErrorsByBuyer)) return {};
    const buyerCompanyObjectIds = Object.keys(validationErrorsByBuyer);
    const errorsByBuyer = buyerCompanyObjectIds.reduce((accumulator, buyerId) => {
      const withoutVariantErrors = validationErrorsByBuyer[buyerId].filter(
        (err) => !/^variants/.test(err.fieldPath)
      );
      const numVariantErrors =
        validationErrorsByBuyer[buyerId].length - withoutVariantErrors.length;
      // join new variants aggregated error
      accumulator[buyerId] =
        numVariantErrors === 0
          ? withoutVariantErrors
          : [
              ...withoutVariantErrors,
              {
                fieldPath: 'variants',
                message: `${numVariantErrors} errors on variant level fields`,
                buyerCompanyObjectId: buyerId,
              },
            ];
      return accumulator;
    }, {} as ErrorsByBuyer);
    return errorsByBuyer;
  }, [validationErrorsByBuyer]);

  if (_isEmpty(validationErrorsByBuyer) || _isEmpty(partners)) return null;
  const buyerCompanyObjectIds = Object.keys(validationErrorsByBuyer);

  return (
    <Message show kind="warning" onClose={() => {}}>
      <p className="m-0">
        <strong>
          This product does not pass validation rules set up by your{' '}
          {pluralize('partner', buyerCompanyObjectIds.length)}
        </strong>
      </p>

      {buyerCompanyObjectIds.map((buyerCompanyObjectId) => {
        const errorList = errorListByBuyer[buyerCompanyObjectId] || []; // errorList with variants condensed to single error message
        const numErrors = validationErrorsByBuyer[buyerCompanyObjectId].length; // use the original list for number of errors
        const partnership = partners.find(
          (partner) => partner.buyerCompanyObjectId === buyerCompanyObjectId
        );
        const buyerName = partnership
          ? partnership.buyerName || partnership.buyerCompanyId
          : `Buyer ${buyerCompanyObjectId}`;
        if (numErrors === 0) {
          return null;
        }
        return (
          <CollapsableValidationErrors
            key={buyerCompanyObjectId}
            text={`${buyerName} - ${pluralize('validation error', numErrors, true)}`}
            errorList={errorList}
          />
        );
      })}
    </Message>
  );
};

interface BuyerValidationAlertProps {
  validationErrorList: { fieldPath: string; message: string }[];
}

const BuyerValidationAlert = ({ validationErrorList }: BuyerValidationAlertProps) => {
  // collapse the variant errors into a single line with count of errors
  const errorList = useMemo(() => {
    if (_isEmpty(validationErrorList)) return [];
    const withoutVariantErrors = validationErrorList.filter(
      (err) => !/^variants/.test(err.fieldPath)
    );
    const numVariantErrors = validationErrorList.length - withoutVariantErrors.length;
    if (numVariantErrors === 0) return withoutVariantErrors;
    return [
      ...withoutVariantErrors,
      { fieldPath: 'variants', message: `${numVariantErrors} errors on variant level fields` },
    ];
  }, [validationErrorList]);

  if (_isEmpty(validationErrorList)) return null;
  return (
    <Message show kind="warning" onClose={() => {}}>
      <p className="m-0">
        <strong>This product does not pass your validation rules</strong>
      </p>
      <CollapsableValidationErrors
        text={pluralize('validation error', validationErrorList.length, true)}
        errorList={errorList}
      />
    </Message>
  );
};

interface ProductValidationAlertProps {
  validationErrorsByBuyer: Record<string, { fieldPath: string; message: string }[]>;
  validationErrorList: { fieldPath: string; message: string }[];
  isSeller: boolean;
}

const ProductValidationAlert = ({
  validationErrorsByBuyer,
  validationErrorList,
  isSeller,
}: ProductValidationAlertProps) => {
  return isSeller ? (
    <SellerValidationAlert validationErrorsByBuyer={validationErrorsByBuyer} />
  ) : (
    <BuyerValidationAlert validationErrorList={validationErrorList} />
  );
};

export default ProductValidationAlert;
