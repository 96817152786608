import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import Link from 'storybook/stories/molecules/Link';
import type { Company } from 'types/models/company';
import EDISettings from '../SettingsPage/DropshippingTab/IntegrationsSettings/EDISettings';

interface ConfigureEDISettingsCardProps {
  done: boolean;
  company: Company;
}

const ConfigureEDISettingsCard = ({ done, company }: ConfigureEDISettingsCardProps) => {
  return (
    <GetStartedCard
      isComplete={done}
      title="Configure EDI Settings"
      completedUi={
        <Body as="p">
          Manage your EDI Settings from your{' '}
          <Link to="/settings/dropshipping/integrations">Integration settings</Link>.
        </Body>
      }
    >
      <EDISettings company={company} isHorizontal isTitleVisible={false} />
    </GetStartedCard>
  );
};

export default ConfigureEDISettingsCard;
