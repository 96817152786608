import { merge } from 'lodash';
import materialSymbol from 'storybook/mixins/materialSymbol';
import Link from 'storybook/stories/molecules/Link';
import styled, { css } from 'styled-components';

// Base

const Base = styled.div`
  display: flex;
  flex-direction: column;
`;

// Links

enum MenuLinkKind {
  Default = 'default',
  Destructive = 'destructive',
}

interface MenuLinkProps {
  /**
   * The name of the icon to display to the left of the content
   */
  $iconName: string;
  /**
   * Indicate whether or not the link is active.
   */
  $isActive?: boolean;
  /**
   * The kind of the link
   */
  kind?: `${MenuLinkKind}`;
}

const MenuLink = styled(Link)<MenuLinkProps>`
  all: unset;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 100px;
  color: ${({ theme }) => theme.color.bodyTextSecondary};

  ${({ $iconName }) => materialSymbol({ name: $iconName })};

  &:hover {
    color: ${({ theme }) => theme.color.bodyTextPrimary};
    text-decoration: none;
  }

  ${({ theme, kind }) => {
    switch (kind) {
      case MenuLinkKind.Destructive:
        return css`
          color: ${theme.color.error500};

          &:hover {
            color: ${theme.color.error500};
          }
        `;
      default:
        return css``;
    }
  }};

  ${({ theme, $isActive }) =>
    $isActive &&
    css`
      background: ${theme.color.blue050};
      color: ${theme.color.bodyTextLinks} !important; // override hover color
    `}
`;

/**
 * A `Menu` provides layout for a list of `Menu.Link` components. Expects to be in some kind
 * of container.
 */
const Menu = merge(Base, {
  Link: MenuLink,
});

export default Menu;
