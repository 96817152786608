import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import download from 'downloadjs';
import { isNumber, map } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useBoolean } from 'usehooks-ts';

import Spinner from 'components/Common/Spinner';
import ReturnOrderItemModal from 'containers/OrderPage/Returns/ReturnOrderItemModal';
import Card from 'storybook/stories/cells/Card';
import Table from 'storybook/stories/cells/Table';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import { Platform } from 'types/models/company';
import type { Order20210101, OrderItem, OrderShippingMethod } from 'types/models/order';
import type { Return } from 'types/models/return';
import type { TaxItem } from 'types/models/tax';
import { getPackingSlipForOrder } from 'utils/api/orders';
import { humanizeMoney } from 'utils/currencies';
import { prettyDate, prettyTime } from 'utils/date';
import { useSafeState } from 'utils/hooks';
import { HST_SUPPORTED_PROVINCES } from 'utils/platform';
import { formatPriceWithCurrency } from 'utils/prices';
import { calculateHstTaxAmount, friendlyTaxType } from 'utils/taxes';

import CancelOrderItemsModal from 'containers/OrderPage/CancelOrderItemsModal';
import CancelOrderModal from 'containers/OrderPage/CancelOrderModal';
import { BoldText } from 'containers/OrderPage/Shared.styles';
import Dropdown from 'storybook/stories/cells/Dropdown';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import type { Attribute } from 'types/general';

// Header container for the card and generate packing slip button
const TableIndentContainer = styled(Flex)`
  flex-direction: row;
  width: 100%;
`;

// Customized table to remove left padding so it displayed properly
const OrderSummaryTableWrapper = styled.div`
  ${Table.TD} {
    padding-left: 0px;
  }
`;

// Middle row gap between in and the last row for formatting
const MiddleRow = styled.div`
  padding-right: 75px;
`;

// Style to keep the tax disclaimers from wrapping far away
const TaxDisclaimer = styled.span`
  display: inline-block;
  width: 65%;
`;

const RemovedInfoRow = styled.span`
  display: flex;
  font-style: italic;

  &:last-child {
    margin-bottom: 24px;
  }
`;

const CanceledItemTR = styled(Table.TR)`
  text-decoration: line-through;
`;

// getReturnableQuantity returns the quantity of an item that can be returned.
export const getReturnableQuantity = (
  item: OrderItem,
  order: Order20210101,
  returns?: Return[]
) => {
  if (!returns) return item.quantity;
  if (item.cancelled) return 0;
  if (!order.fulfillments) return 0;

  const totalReturnedQuantity = returns
    .filter((returnItem) => returnItem.orderItemId === item._id)
    .reduce((total, current) => total + current.quantity, 0);

  return item.quantity - totalReturnedQuantity;
};

// Missing billing address row
const RequireBillingAddress = () => {
  return (
    <Table.TR valign="top" align="right" bold>
      <Table.TD colSpan={2}>
        <MiddleRow>
          <p className="mb-0">Sales Tax</p>
          <TaxDisclaimer className="text-muted small mb-0">
            You must <Link to="/settings/company/locations">set a billing address</Link> to get tax
            estimations. If you have updated your billing address, new orders will have taxes
            calculated on them.
          </TaxDisclaimer>
        </MiddleRow>
      </Table.TD>
      <Table.TD>-</Table.TD>
    </Table.TR>
  );
};

type OrderTotalRowProps = {
  order: Order20210101;
  orderFeeDollarAmount: number;
};

// Order Total row (Order Subtotal + taxes + order fee)
const OrderTotalRow = ({ order, orderFeeDollarAmount }: OrderTotalRowProps) => {
  let orderTotal = order.totalPrice; // this is a dollar amount

  // Add order total and taxes together
  map(order.taxes, (orderTaxItem) => {
    orderTotal += orderTaxItem.amount; // this is a dollar amount
  });

  // Add order fee to order total
  if (orderFeeDollarAmount > 0) {
    orderTotal += orderFeeDollarAmount; // this is a money object, so amount in cents
  }

  return (
    <Table.TR bold className="mb-0" align="right">
      <Table.TD />
      <Table.TD colSpan={2}>Order Total</Table.TD>
      <Table.TD>{formatPriceWithCurrency(orderTotal.toFixed(2), order.currency)}</Table.TD>
    </Table.TR>
  );
};

// Line item property details to be added to an order item row
const generateLineItemProperties = (attributes: Record<string, Attribute>) => {
  return Object.entries(attributes).map(([key, value]) => {
    const property = Object.values(value)[0];
    return (
      <p className="small text-muted mb-0">
        {key}: {property}
      </p>
    );
  });
};

const DropdownButton = styled.button`
  all: unset;
`;

interface MoreActionsDropdownMenuProps {
  item: OrderItem;
  setItemToRemove: React.Dispatch<React.SetStateAction<OrderItem>>;
  setItemToReturn: React.Dispatch<React.SetStateAction<OrderItem>>;
  shouldShowRemoveButton: boolean;
  shouldShowRequestReturnButton: boolean;
  showCancelItemModal: () => void;
  showReturnItemModal: () => void;
}

const MoreActionsDropdownMenu = ({
  item,
  setItemToRemove,
  setItemToReturn,
  shouldShowRemoveButton,
  shouldShowRequestReturnButton,
  showCancelItemModal,
  showReturnItemModal,
}: MoreActionsDropdownMenuProps) => {
  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        <Icon as="button" color="blue500" name="more_vert" size="24px" aria-label="More actions" />
      </Dropdown.Trigger>
      <Dropdown.Content>
        {({ close }) => {
          return (
            <Flex column gap="16px">
              {shouldShowRequestReturnButton && (
                <DropdownButton
                  onClick={() => {
                    setItemToReturn(item);
                    close();
                    showReturnItemModal();
                  }}
                >
                  Request Return
                </DropdownButton>
              )}

              {shouldShowRemoveButton && (
                <DropdownButton
                  onClick={() => {
                    setItemToRemove(item);
                    close();
                    showCancelItemModal();
                  }}
                >
                  Remove from Order
                </DropdownButton>
              )}
            </Flex>
          );
        }}
      </Dropdown.Content>
    </Dropdown>
  );
};

type OrderItemRowProps = {
  item: OrderItem;
  setItemToRemove: React.Dispatch<React.SetStateAction<OrderItem>>;
  setItemToReturn: React.Dispatch<React.SetStateAction<OrderItem>>;
  index: number;
  currency?: string;
  shouldShowCancelItemButton: boolean;
  shouldShowRequestReturnButton: boolean;
  showCancelItemModal: () => void;
  showReturnItemModal: () => void;
};

// Order items row for presenting name/SKU, line item properties, cost and quantity
const OrderItemRow = ({
  item,
  index,
  setItemToRemove,
  setItemToReturn,
  currency = 'USD',
  shouldShowCancelItemButton,
  shouldShowRequestReturnButton,
  showCancelItemModal,
  showReturnItemModal,
}: OrderItemRowProps) => {
  const shouldShowDropdownMenu = shouldShowCancelItemButton || shouldShowRequestReturnButton;

  return (
    <Table.TR valign="top">
      <Table.TD>
        {shouldShowDropdownMenu && (
          <MoreActionsDropdownMenu
            item={item}
            setItemToRemove={setItemToRemove}
            setItemToReturn={setItemToReturn}
            shouldShowRemoveButton={shouldShowCancelItemButton}
            shouldShowRequestReturnButton={shouldShowRequestReturnButton}
            showCancelItemModal={showCancelItemModal}
            showReturnItemModal={showReturnItemModal}
          />
        )}
      </Table.TD>
      <Table.TD>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/products/${item.productId}`}
          className="mb-0"
        >
          {item.title || `Item #${index + 1}`}
        </a>
        <p className="small text-muted mb-0">SKU: {item.sku || 'No SKU'}</p>
        {item.buyerAttributes && generateLineItemProperties(item.buyerAttributes)}
      </Table.TD>
      <Table.TD align="right">
        <MiddleRow>
          {item.quantity} x {formatPriceWithCurrency(item.price, currency)}
        </MiddleRow>
      </Table.TD>
      <Table.TD align="right">
        {formatPriceWithCurrency(item.quantity * item.price, currency)}
      </Table.TD>
    </Table.TR>
  );
};

type CancelledOrderItemRowProps = {
  item: OrderItem;
  index: number;
  currency?: string;
};

const CancelledOrderItemRow = ({ item, index, currency }: CancelledOrderItemRowProps) => {
  return (
    <>
      <CanceledItemTR valign="top">
        <Table.TD colSpan={2}>
          <p className="mb-0">{item.title || `Item #${index + 1}`}</p>
          <p className="small text-muted mb-0">SKU: {item.sku || 'No SKU'}</p>
        </Table.TD>
        <Table.TD align="right">
          <MiddleRow>
            {item.quantity} x {formatPriceWithCurrency(item.price, currency)}
          </MiddleRow>
        </Table.TD>
        <Table.TD align="right">
          {formatPriceWithCurrency(item.quantity * item.price, currency)}
        </Table.TD>
      </CanceledItemTR>

      <Table.TR>
        <Table.TD colSpan={4}>
          <RemovedInfoRow>
            <BoldText>Item Removed:</BoldText>
            {prettyDate(item.cancelledDate)} at {prettyTime(item.cancelledDate)}
          </RemovedInfoRow>

          {item?.cancelledReason && (
            <RemovedInfoRow>
              <BoldText>Reason:</BoldText>
              {item.cancelledReason}
            </RemovedInfoRow>
          )}
        </Table.TD>
      </Table.TR>
    </>
  );
};

type TaxSummaryRowProps = {
  item: TaxItem;
  currency?: string;
  lastItem?: boolean;
};

// Non-HST Tax summary row for orders
const TaxSummaryRow = ({ item, currency = 'USD', lastItem }: TaxSummaryRowProps) => {
  return (
    <Table.TR className="mb-0" valign="top" align="right" bold>
      <Table.TD colSpan={2}>
        <MiddleRow>
          <p className="mb-0">{friendlyTaxType(item.type)}</p>
          {lastItem && (
            <TaxDisclaimer className="text-muted small mb-0">
              We use a 3rd party service to calculate tax estimates.
            </TaxDisclaimer>
          )}
        </MiddleRow>
      </Table.TD>
      <Table.TD align="right">{formatPriceWithCurrency(item.amount, currency)}</Table.TD>
    </Table.TR>
  );
};

type HSTSummaryRowProps = {
  amount: number;
};

// HST Specific summary row
const HSTSummaryRow = ({ amount }: HSTSummaryRowProps) => {
  return (
    <Table.TR data-testid="harmonized-sales-tax" valign="top" align="right" bold>
      <Table.TD colSpan={2}>
        <MiddleRow>
          <p className="mb-0">Harmonized Sales Tax (HST)</p>
          <TaxDisclaimer className="text-muted small mb-0">
            We use a 3rd party service to calculate a tax estimate by combining GST and PST.
          </TaxDisclaimer>
        </MiddleRow>
      </Table.TD>
      <Table.TD>C${amount.toFixed(2)}</Table.TD>
    </Table.TR>
  );
};

const TaxNotCalculated = () => {
  return (
    <Table.TR className="mb-0" valign="top" align="right">
      <Table.TD colSpan={4} align="right">
        Taxes Not Calculated.{' '}
        <SupportLink article="8lwln18tin#stripe_taxes">Learn more</SupportLink>
      </Table.TD>
    </Table.TR>
  );
};

type TaxSummaryProps = {
  isBillingAddressNeeded: boolean;
  shouldUseHst: boolean;
  currency: string;
  taxItems: Order20210101['taxes'];
};

const TaxSummary = ({
  isBillingAddressNeeded,
  shouldUseHst,
  currency,
  taxItems,
}: TaxSummaryProps) => {
  const safeTaxItems = taxItems || [];

  if (isBillingAddressNeeded) return <RequireBillingAddress />;
  if (safeTaxItems.length === 0) return <TaxNotCalculated />;

  if (shouldUseHst) {
    return <HSTSummaryRow amount={calculateHstTaxAmount(safeTaxItems)} />;
  }

  return (
    <>
      {safeTaxItems.map((item) => {
        return <TaxSummaryRow key={item.type} item={item} currency={currency} />;
      })}
    </>
  );
};

interface OrderFeeRowProps {
  order: Order20210101;
  orderFeeDollarAmount: number;
}

const OrderFeeRow = ({ order, orderFeeDollarAmount }: OrderFeeRowProps) => {
  if (!order.orderFee || orderFeeDollarAmount === 0) return null;

  return (
    <Table.TR className="mb-0" valign="top" align="right" bold>
      <Table.TD colSpan={3}>Order Fee</Table.TD>
      <Table.TD align="right">
        {formatPriceWithCurrency(orderFeeDollarAmount, order.orderFee.appliedFee.currency)}
      </Table.TD>
    </Table.TR>
  );
};

const ShippingMethodRow = ({ shippingMethod }: { shippingMethod: OrderShippingMethod }) => {
  return (
    <Table.TR className="mb-0" valign="top" align="right">
      <Table.TD colSpan={3}>Shipping - {shippingMethod.title}</Table.TD>
      <Table.TD align="right">{humanizeMoney(shippingMethod.price)}</Table.TD>
    </Table.TR>
  );
};

type OrderSubtotalRowProps = {
  order: Order20210101;
};

// Order Subtotal (pre tax)
const OrderSubtotalRow = ({ order }: OrderSubtotalRowProps) => {
  return (
    <Table.TR bold className="mb-0" align="right">
      <Table.TD colSpan={3}>Order Subtotal</Table.TD>
      <Table.TD>{formatPriceWithCurrency(order.totalPrice.toFixed(2), order.currency)}</Table.TD>
    </Table.TR>
  );
};

export type OrderSummaryProps = {
  order: Order20210101;
  returns?: Return[];
  isSeller: boolean;
  showErrorMessage: any;
  orderPlatform: Platform;
  refetchReturns: () => void;
};

const OrderSummary = ({
  order,
  returns,
  isSeller,
  showErrorMessage,
  orderPlatform,
  refetchReturns,
}: OrderSummaryProps) => {
  const dispatch = useDispatch();

  // State

  const [generatingSlip, setGeneratingSlip] = useSafeState(false);
  const [itemsToUpdate, setItemsToUpdate] = useState([] as OrderItem[]);
  const [itemToRemove, setItemToRemove] = useState({} as OrderItem);
  const [itemToReturn, setItemToReturn] = useState({} as OrderItem);
  const [items, setItems] = useState(
    (order.items || []).filter((item) => !item.cancelled) ?? ([] as OrderItem[])
  );

  const shouldShowCancelOrderModal = useBoolean(false);
  const shouldShowReturnItemModal = useBoolean(false);
  const shouldShowCancelItemModal = useBoolean(false);

  // Helpers

  // Determine if we should display HST versus standard tax summary
  const shouldUseHst = HST_SUPPORTED_PROVINCES.includes(order.billingAddress?.state ?? '');

  // Confirm that the order is not missing a billing address for auto billing
  const isBillingAddressNeeded = order.taxStatus === 'missingBillingAddress';

  const alreadyCanceledItems = order.items?.filter((item) => item.cancelled) ?? [];

  const shouldDisplayPackingSlipButton =
    isSeller && items.length > 0 && itemsToUpdate?.length === 0;

  const shouldDisplayCancelItemButton = orderPlatform === Platform.Other && !order.shipped;

  const orderFeeDollarAmount =
    order.orderFee && isNumber(order.orderFee.appliedFee.amount / 100)
      ? order.orderFee.appliedFee.amount / 100
      : 0;

  // Side Effects

  useEffect(() => {
    setItems((order.items || []).filter((item) => !item.cancelled));
  }, [order.items]);

  // Event Handlers

  const generatePackingSlip = () => {
    setGeneratingSlip(true);

    getPackingSlipForOrder(order._id)
      .then((data) => {
        download(new Blob([data]), 'packingslip.pdf', 'application/pdf');
        setGeneratingSlip(false);
      })
      .catch((error: { message: string }) => {
        showErrorMessage({
          message: error.message,
          title: 'Error downloading packing slip',
        });
      });
  };

  const resetItemsToUpdate = () => {
    setItemsToUpdate([] as OrderItem[]);
  };

  return (
    <>
      <Card className="card p-4">
        <Flex column gap="16px">
          <TableIndentContainer>
            <Flex.Item grow={1}>
              <h4>Order Summary</h4>
            </Flex.Item>
            <Flex.Item>
              {generatingSlip && <Spinner color="primary" small className="mr-3" />}
              {shouldDisplayPackingSlipButton && (
                <PrimaryButton size="small" onClick={generatePackingSlip}>
                  Generate Packing Slip
                </PrimaryButton>
              )}
            </Flex.Item>
          </TableIndentContainer>

          <Grid rows="auto">
            <OrderSummaryTableWrapper>
              <Table>
                <Table.TBody>
                  {items.map((item, idx) => (
                    <OrderItemRow
                      item={item}
                      key={item._id}
                      index={idx}
                      setItemToRemove={setItemToRemove}
                      setItemToReturn={setItemToReturn}
                      currency={order.currency}
                      shouldShowCancelItemButton={shouldDisplayCancelItemButton}
                      shouldShowRequestReturnButton={
                        !isSeller && getReturnableQuantity(item, order, returns) > 0
                      }
                      showCancelItemModal={shouldShowCancelItemModal.setTrue}
                      showReturnItemModal={shouldShowReturnItemModal.setTrue}
                    />
                  ))}

                  {alreadyCanceledItems.map((item, idx) => (
                    <CancelledOrderItemRow
                      item={item}
                      index={idx}
                      currency={order.currency}
                      key={item._id}
                    />
                  ))}

                  <OrderSubtotalRow order={order} />

                  <Table.TR>
                    <Table.TD colSpan={4}>
                      <hr className="mt-3 mb-3" />
                    </Table.TD>
                  </Table.TR>

                  <TaxSummary
                    isBillingAddressNeeded={isBillingAddressNeeded}
                    shouldUseHst={shouldUseHst}
                    currency={order.currency}
                    taxItems={order.taxes}
                  />

                  <OrderFeeRow order={order} orderFeeDollarAmount={orderFeeDollarAmount} />

                  {order.shippingMethods?.map((shippingMethod) => {
                    return (
                      <ShippingMethodRow
                        key={shippingMethod.code}
                        shippingMethod={shippingMethod}
                      />
                    );
                  })}

                  <OrderTotalRow order={order} orderFeeDollarAmount={orderFeeDollarAmount} />
                </Table.TBody>
              </Table>
            </OrderSummaryTableWrapper>
          </Grid>
        </Flex>
      </Card>

      <CancelOrderModal
        order={order}
        showModal={shouldShowCancelOrderModal.value}
        onDismiss={shouldShowCancelOrderModal.setFalse}
        dispatch={dispatch}
      />

      <CancelOrderItemsModal
        order={order}
        items={[itemToRemove]}
        resetItemsToUpdate={resetItemsToUpdate}
        isSeller={isSeller}
        showModal={shouldShowCancelItemModal.value}
        onDismiss={shouldShowCancelItemModal.setFalse}
      />

      <ReturnOrderItemModal
        order={order}
        item={itemToReturn}
        shouldShowModal={shouldShowReturnItemModal.value}
        maxReturnableItems={getReturnableQuantity(itemToReturn, order, returns)}
        onDismiss={shouldShowReturnItemModal.setFalse}
        refetchReturns={refetchReturns}
      />
    </>
  );
};

export default OrderSummary;
