import type {
  Address,
  Custom,
  MerchandisingSettings,
  Metafields,
  MetafieldSchema,
} from 'types/general';
import type { ShippingSettings } from 'types/models/shipping';

export enum CommerceType {
  None = '',
  Buyer = 'buyer',
  Seller = 'seller',
}

export enum Platform {
  Shopify = 'shopify',
  WooCommerce = 'woocommerce',
  BigCommerce = 'bigcommerce',
  Magento2 = 'magento2',
  CsvSftp = 'csv-sftp',
  Other = 'other',
  ExternalSftp = 'external-sftp',
  EdiSftp = 'edi-sftp',
  EdiExternalSftp = 'edi-sftp-external',
}

export type CompanyActiveAuth = Record<
  string,
  | CompanyShopifySettings
  | CompanyMagento2Auth
  | CompanyWooCommerceAuth
  | CompanyBigCommerceAuth
  | CompanyExternalSFTPAuth
>;

export type CompanyTerms = {
  provider: string;
  providerId: string;
  approved: boolean;
  approvedDate: string;
  denied: boolean;
  deniedDate: string;
  amountAvailable: number;
  amountAuthorized: number;
  started: boolean;
  startedDate: string;
  updatedDate: string;
  applied: boolean;
  appliedDate: string;
  applicationId: string;
};

export type CompanyDefaults = {
  priceDiscount: number;
  priceListID: string;
};

export type CompanySFTPSettings = {
  downloadDirectory: string;
  uploadDirectory: string;
  archive: { method: string; directory: string };
};

export type CompanyEDIData = {
  isaID: string;
  isaIDQualifier: string;
  counters: {
    interchangeCtrlNum: number;
    functionalGroupNumber: number;
    orderNum: number;
    invoiceNum: number;
  };
  postFulfillment: string;
  warehouseMetafieldNamespace: string;
  warehouseDefaultMetafieldKey: string;
  acceptedFileExtensions: string[];
  errors: any[]; // TODO
  characterEncoding: string;
};

export type CompanyPrivacy = {
  disableSudoAccess: boolean;
};

export type CompanySyncing = {
  postInvoicesDate: string;
  getProductsStart: string;
  getProductsEnd: string;
  getEDIAcknowledgements: boolean;
  postEDIAcknowledgements: boolean;
  postEDIInventoryUpdate: boolean;
  postEDIInvoices: boolean;
  getEDIInvoices: boolean;
  getEDIInventoryUpdates: boolean;
  getOrders: boolean;
  postOrders: boolean;
  lastEDIInventoryUpdate: string;
};

export type CompanyGettingStarted = {
  allStepsCompleted: boolean;
  allStepsCompletedAt: string;
  inviteTeamCompleted: string;
  invitePartnerCompleted: string;
  sftpFileUploadCompleted: boolean;
  ediSpecsReviewCompleted: boolean;
  testOrderCompleted: boolean;
};

export type CompanyNotifications = {
  ordersEmail: string;
  ordersNotify: boolean;
};

export type CompanyWebhook = {
  ID: number;
  Key: string;
};

export type CompanyFlags = {
  skipBuyerNotifications: boolean;
  ignoreInventoryType: boolean;
  acceptPartialOrders: boolean;
  notifyCriticalErrors: boolean;
  syncUnpublishedProducts: boolean;
  mergeAllTags: boolean;
  useNewCSVProductWorker: boolean;
  overwriteComparedAtPrice: boolean;
  useRetailPriceForComparedAt: boolean;
  pushProductsAsTaxable: boolean;
};

export type CompanyPlan = {
  chargeOrderFee: boolean;
  orderFee: number;
};

export type CompanyUser = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
};

export interface CompanyReturnsSettings {
  syncToPlatform: boolean;
  shouldRefundOnReturnComplete: boolean;
}

interface CompanyPaymentTerms {
  numBillingDelayDays: number;
}

export type CompanyEasyPostSettings = {
  connected: boolean;
};

export interface CompanyDefaultBillingSettings {
  generateOrderInvoices?: boolean;
  autoTax?: boolean;
  postInvoices?: boolean;
  paymentTerms: CompanyPaymentTerms;
}

export interface CompanyDomain {
  url: string;
  isVerified: boolean;
}

export interface CompanyShopifySettings {
  platform: Platform;
  store: string;
  permissions: string;
}

export interface CompanyMagento2Auth {
  platform: Platform;
  platformUrl: string;
  secretID: string;
  storeView: string;
}

export interface CompanyWooCommerceAuth {
  platform: Platform;
  platformUrl: string;
}

export interface CompanyBigCommerceAuth {
  platform: Platform;
  platformUrl: string;
  bigCommerceClientID: string;
}

export interface CompanyExternalSFTPAuth {
  platform: Platform;
  username: string;
  address: string;
  port: number;
  secretID: string;
}

export interface CompanyDataExports {
  active: boolean;
  externalSFTP: CompanyExternalSFTPAuth;
}

export type CompanyMetafieldSchemas = Record<string, Record<string, MetafieldSchema>>;

export interface Company {
  _id: string;
  legacyCompanyId: string;
  url: string;
  users: CompanyUser[];
  commerceType: CommerceType;
  name: string;
  legalName: string;
  logoId: string;
  crmEmail: string;
  created: string;
  updated: string;
  plan: CompanyPlan;
  flags: CompanyFlags;
  productPlatform: Platform;
  orderPlatform: Platform;
  inventoryPlatform: Platform;
  maxShipTime: number;
  maxReturnRate: number;
  webhooks: CompanyWebhook[];
  gateway?: string;
  gettingStarted: CompanyGettingStarted;
  relatedCustomer?: string;
  syncing: CompanySyncing;
  privacy: CompanyPrivacy;
  timeZone: string;
  ediData: CompanyEDIData;
  sftp: CompanySFTPSettings;
  enableOrderPayments: boolean;
  defaultBillingSettings: CompanyDefaultBillingSettings;
  oAuth2Integrations: string[];
  domains: CompanyDomain[];
  merchandisingSettings: MerchandisingSettings;
  locations: Address[];
  easypost: CompanyEasyPostSettings;
  shippingSettings: ShippingSettings;
  returnsSettings: CompanyReturnsSettings;

  shopify: CompanyShopifySettings;
  magento2: CompanyMagento2Auth;
  woocommerce: CompanyWooCommerceAuth;
  bigCommerce: CompanyBigCommerceAuth;

  contactEmail: string;
  metafieldSchemas: CompanyMetafieldSchemas;
  metafields: Metafields;
  dataExports: CompanyDataExports;

  stripeAccountId?: string;
  stripeCustomerId?: string;
  stripeUSCustomerId?: string;
  enableShippingLinesShopify?: boolean;
  billing: Address;
  apiKey: string;
  testKey: string;
  defaults: CompanyDefaults;
  custom: Custom[];
  terms: CompanyTerms;
}

export interface CompanyResponse extends Company {
  productPlatformActive: boolean;
  orderPlatformActive: boolean;
  activeAuth: CompanyActiveAuth;
}
