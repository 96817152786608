import type { LegacyBuyerProduct } from 'types/models/legacy-buyer-product';
import type { SellerProduct } from 'types/models/seller-product';

export const getSelectedProducts = <Type extends SellerProduct | LegacyBuyerProduct>(
  products: Type[],
  selectedIds: string[]
) =>
  products.reduce((accumulator, product) => {
    if (selectedIds.includes(product.id)) accumulator.push(product);
    return accumulator;
  }, [] as SellerProduct[]);

export const getVariantCountsBySku = <Type extends SellerProduct | LegacyBuyerProduct>(
  products: Type[]
) =>
  products
    .map((product) => product.variants)
    .flat()
    .reduce(
      (accumulator, variant) => {
        accumulator[variant.sku] = accumulator[variant.sku] ? accumulator[variant.sku] + 1 : 1;
        return accumulator;
      },
      {} as Record<string, number>
    );

export const getDuplicateVariantSkus = <Type extends SellerProduct | LegacyBuyerProduct>(
  products: Type[]
) =>
  Object.entries(getVariantCountsBySku(products)).reduce((accumulator, [sku, count]) => {
    if (count > 1) accumulator.push(sku);
    return accumulator;
  }, [] as string[]);
