import * as Sentry from '@sentry/react';
import useChatConversation, { type UseChatConversationOptions } from 'hooks/useChatConversation';
import { noop } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import {
  ConversationMode,
  WebsocketMessageType,
  type ChatMessage,
  type HistoryMessage,
  type WebsocketConversationMessageFromClient,
  type WebsocketConversationMessageFromServer,
} from 'types/models/conversation';
import config from 'utils/config';
import { refreshToken } from 'utils/request';
import { v4 as uuid } from 'uuid';

const log = (...args: any[]) => {
  if (config.isTest) return noop;
  return console.log(args);
};

const convertToChatMessage = ({
  message_id,
  content,
  type,
}:
  | HistoryMessage
  | WebsocketConversationMessageFromClient
  | WebsocketConversationMessageFromServer): ChatMessage => {
  const message: ChatMessage = {
    content,
    type,
    key: uuid(),
  };

  if (message_id) {
    message.id = message_id as string;
  }

  return message;
};

interface UseChatOptions extends UseChatConversationOptions {
  /**
   * Used to override the websocket URL to connect to for chat
   */
  websocketUrl?: string;
}

/**
 * Used to handle the websocket connection and sending messages to the websocket for chat.
 */
const useChat = ({ websocketUrl, ...chatConversationOptions }: UseChatOptions = {}) => {
  const {
    destroyConversationData,
    updateConversationData,
    setConversationData,
    conversation,
    currentPartnerName,
    currentPartnerCompanyId,
  } = useChatConversation(chatConversationOptions);

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isAwaitingWebsocketResponse, setIsAwaitingWebsocketResponse] = useState(false);

  /**
   * Websocket Connection
   */

  const url = useMemo(() => {
    if (websocketUrl) return websocketUrl;

    if (conversation)
      return `${config.chatApiURL}/conversations/${conversation.id}/chat/${conversation.authToken}`;

    return null;
  }, [conversation, websocketUrl]);

  const { sendJsonMessage, lastJsonMessage } =
    useWebSocket<WebsocketConversationMessageFromServer | null>(url, {
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: (attemptNumber) => Math.min(attemptNumber ** 2 * 1000, 10000),
      retryOnError: true,
      onOpen: (event) => {
        log('Websocket connection opened', event);
      },
      onClose: (event) => {
        log('Websocket connection closed', event);
      },
      onMessage: (event) => {
        log('Websocket message received', event);
      },
      onError: (event: Event) => {
        log('Websocket error', event);

        // Assume the error was due to an expired token and refresh it
        // Disabled for test mode because testing this stuff is a true pain
        if (!config.isTest) refreshToken();

        Sentry.withScope((scope) => {
          scope.setExtra('event', event);
          Sentry.captureMessage('Error event from websocket', 'error');
        });
      },
    });

  /**
   * Chat Helpers
   */

  const sendHumanMessage = useCallback(
    (content: string) => {
      setIsAwaitingWebsocketResponse(true);

      const message: WebsocketConversationMessageFromClient = {
        content,
        mode: ConversationMode.Support,
        conversation_id: conversation?.id || '',
        type: WebsocketMessageType.HUMAN,
        partner: currentPartnerCompanyId || '',
      };

      log('Sending message', message);

      sendJsonMessage(message);

      setMessages((prev) => [...prev, convertToChatMessage(message)]);
    },
    [conversation, currentPartnerCompanyId, sendJsonMessage]
  );

  const sendInterruptMessage = useCallback(() => {
    const message = { type: WebsocketMessageType.INTERRUPT };

    log('Sending interrupt message', message);

    setIsAwaitingWebsocketResponse(true);
    sendJsonMessage(message);
  }, [sendJsonMessage]);

  const sendModeChangedMessage = useCallback(
    (mode: ConversationMode.Support, partnerId?: string) => {
      const message = { type: WebsocketMessageType.MODE, mode, partner: partnerId };

      log('Sending mode changed message', message);

      setIsAwaitingWebsocketResponse(true);
      sendJsonMessage(message);
    },
    [sendJsonMessage]
  );

  /**
   * Helpers
   */

  const resetChat = useCallback(() => {
    sendInterruptMessage();
    destroyConversationData();
    setMessages([]);
  }, [destroyConversationData, sendInterruptMessage]);

  /**
   * Side Effects
   */

  // Parses each message from the websocket and adds it to the message array
  useEffect(() => {
    if (lastJsonMessage === null) return;

    // Report any errors from the server to Sentry
    if (lastJsonMessage.type === WebsocketMessageType.ERROR) {
      console.error('Chat error from LLM', lastJsonMessage);

      Sentry.withScope((scope) => {
        scope.setExtra('error', lastJsonMessage);
        Sentry.captureMessage('Chat error from LLM', 'error');
      });
    }

    // All messages, but Status messages, are final messages
    if (lastJsonMessage.type !== WebsocketMessageType.STATUS) {
      setIsAwaitingWebsocketResponse(false);
    }

    // If we're joining the conversation, change the mode
    if (lastJsonMessage.type === WebsocketMessageType.JOINED) {
      sendModeChangedMessage(ConversationMode.Support);
    }

    // If we're joining the conversation and there is history, replace the message array
    if (
      lastJsonMessage.type === WebsocketMessageType.JOINED &&
      lastJsonMessage.history.length > 0
    ) {
      const historyMessages = lastJsonMessage.history.map((historyMessage) =>
        convertToChatMessage(historyMessage)
      );

      setMessages(historyMessages);

      return;
    }

    // Otherwise this is a normal message. Push the new message onto the message array
    setMessages((prev) => [...prev, convertToChatMessage(lastJsonMessage)]);
  }, [lastJsonMessage, sendModeChangedMessage]);

  return {
    messages,
    isAwaitingWebsocketResponse,
    setIsAwaitingWebsocketResponse,
    sendHumanMessage,
    sendInterruptMessage,
    sendModeChangedMessage,
    resetChat,
    destroyConversationData,
    updateConversationData,
    setConversationData,
    conversation,
    currentPartnerName,
    currentPartnerCompanyId,
  };
};

export default useChat;
