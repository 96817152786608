/* eslint-disable jsx-a11y/label-has-associated-control */
import { useQuery } from '@tanstack/react-query';
import UpdatedIndicator from 'components/Common/UpdatedIndicator';
import useAlertQueue from 'hooks/useAlertQueue';
import Tooltip from 'storybook/stories/cells/Tooltip';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import Input from 'storybook/stories/molecules/Input';
import Link from 'storybook/stories/molecules/Link';
import Toggle from 'storybook/stories/molecules/Toggle';
import type { CompanyResponse } from 'types/models/company';
import type { SellerProduct } from 'types/models/seller-product';
import { getPartnerByCompanyId } from 'utils/api/partners';
import { getPlatformUrl, getShopifyStore } from 'utils/platform';

const getPlatformProductUrl = (
  product: SellerProduct,
  company: CompanyResponse,
  isSeller: boolean
): string => {
  if (!isSeller) return '';
  const platform = company.productPlatform;
  const { code } = product;

  if (platform === 'shopify' && code) {
    return `https://${getShopifyStore(company)}.myshopify.com/admin/products/${code}`;
  }
  if (platform === 'woocommerce' && code) {
    const url = getPlatformUrl(company, 'woocommerce');
    if (url) {
      return `${url}/wp-admin/post.php?post=${code}&action=edit`;
    }
  }
  if (platform === 'bigcommerce' && code) {
    const url = getPlatformUrl(company, 'bigcommerce');
    if (url) {
      return `${url}/manage/products/${code}`;
    }
  }
  return '';
};

interface ProductDetailsProps {
  product: SellerProduct;
  company: CompanyResponse;
  isSeller: boolean;
  edits: Record<string, string>;
  onEditProductField: (key: string) => (value: boolean | string) => void;
  canEdit: boolean;
  validationErrors?: string[];
}

const ProductDetails = ({
  product,
  company,
  isSeller,
  edits,
  onEditProductField,
  canEdit,
  validationErrors,
}: ProductDetailsProps) => {
  const alertQueue = useAlertQueue();

  const active = Object.prototype.hasOwnProperty.call(edits, 'active')
    ? edits.active
    : product.active || false;
  const vendor = Object.prototype.hasOwnProperty.call(edits, 'vendor')
    ? edits.vendor
    : product.vendor || '';
  const platformUrl = isSeller ? getPlatformProductUrl(product, company, isSeller) : '';
  const partnerCompanyId = isSeller ? null : product.companyObjectId;

  const onChangeActive = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    onEditProductField('active')(checked);
  };

  const onChangeField =
    (key: string) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      onEditProductField(key)(value);
    };

  const fetchingPartner = useQuery({
    queryKey: ['partner', partnerCompanyId],
    queryFn: () => getPartnerByCompanyId(partnerCompanyId!),
    enabled: !!partnerCompanyId,
    onError: () => {
      alertQueue.addErrorAlert('Error', 'Error retrieving partner for product');
    },
  });
  const partner = fetchingPartner.data;

  const cardStyle = (validationErrors?.length ?? 0) > 0 ? { border: '1px solid #ffe4a0' } : {};

  return (
    <div className="card p-4 overflow-auto" id="product-details-card" style={cardStyle}>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">
          {isSeller ? (
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Icon name="help" position="after">
                  Active Status
                </Icon>
              </Tooltip.Trigger>
              <Tooltip.Content>
                Active products are visible to your partners, inactive products are not.
              </Tooltip.Content>
            </Tooltip>
          ) : (
            'Active Status'
          )}

          {Object.prototype.hasOwnProperty.call(edits, 'active') ? (
            <UpdatedIndicator className="ml-2" style={{ marginBottom: '2px' }} />
          ) : null}
        </h4>
        {isSeller ? (
          <div className="custom-control custom-switch" id="toggle-active-switch">
            <Toggle
              id="active-toggle"
              checked={active}
              onChange={onChangeActive}
              name="active-toggle"
            />
          </div>
        ) : (
          <p className="m-0">
            {active ? (
              <span className="badge badge-soft-primary">Active</span>
            ) : (
              <span className="badge badge-soft-secondary">Not Active</span>
            )}
          </p>
        )}
      </div>
      <hr className="mt-4 mb-4" />
      <div className="row align-items-center mb-3">
        <div className="col">
          <Heading variant="Headings/H3" className="mb-0">
            Modern Dropship ID
          </Heading>
        </div>
        <div className="col-auto">
          <p className="mb-0 text-muted">{product.id}</p>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col">
          <Heading variant="Headings/H3" className="mb-0">
            Code
          </Heading>
        </div>
        <div className="col-auto">
          {platformUrl ? (
            <a
              href={platformUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-1 text-right"
            >
              {product.code}
            </a>
          ) : (
            <p className="mb-0 text-muted">{product.code}</p>
          )}
        </div>
      </div>

      <hr className="mt-4 mb-4" />

      <div className="row align-items-center mb-3">
        <div className="col-3">
          <Heading variant="Headings/H3" className="mb-0 text-nowrap">
            Brand{' '}
            {Object.prototype.hasOwnProperty.call(edits, 'vendor') ? (
              <UpdatedIndicator className="ml-1" />
            ) : null}
          </Heading>
        </div>
        <div className="col-9">
          {canEdit && isSeller ? (
            <Input
              className="form-control form-control-sm"
              aria-label="Brand"
              value={vendor}
              onChange={onChangeField('vendor')}
              name="vendor"
            />
          ) : (
            <p className="mb-0 text-right text-muted" aria-label="Brand">
              {vendor || 'Not set'}
            </p>
          )}
        </div>
      </div>
      {!isSeller && !fetchingPartner.isLoading && partner && (
        <div className="row align-items-center mb-3">
          <div className="col">
            <Heading variant="Headings/H3" className="mb-0">
              Partner{' '}
            </Heading>
          </div>
          <div className="col-auto">
            <Link to={`/partners/${partner._id}`} className="mb-0 text-right text-muted">
              {partner.sellerName || 'Seller Partner'}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
