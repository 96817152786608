import type { Placement } from '@floating-ui/react';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';
import { merge } from 'lodash';
import {
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import elevate from 'storybook/mixins/elevate';
import typography from 'storybook/mixins/typography';
import styled from 'styled-components';

interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

/**
 * Hooks
 */

export function useTooltip({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  });

  const { context } = data;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = createContext<ContextType>(null);

const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

/**
 * Base
 */

const Tooltip = ({ children, ...options }: { children: React.ReactNode } & TooltipOptions) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
};

/**
 * Trigger
 */

const TriggerWrapper = styled.button`
  all: unset;
`;

const TooltipTrigger = forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(({ children, asChild = false, ...props }, propRef) => {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    );
  }

  return (
    <TriggerWrapper
      type="button"
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </TriggerWrapper>
  );
});

/**
 * Content
 */

const ContentWrapper = styled.div`
  ${elevate('2')};
  border-radius: 4px;
  padding: 8px 12px;
  width: max-content;
  max-width: 400px;
  background: ${({ theme }) => theme.color.gray900};
  color: ${({ theme }) => theme.color.white};
  ${typography('Body/Body Small')};
  z-index: 1031; // Left menu is 1030

  img {
    max-width: 100%;
    display: inline-block;
  }
`;

type TooltipContentProps = React.HTMLProps<HTMLDivElement>;

const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
  ({ style, ...props }, propRef) => {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.open) return null;

    return (
      <FloatingPortal>
        <ContentWrapper
          ref={ref}
          style={{
            ...context.floatingStyles,
            ...style,
          }}
          {...context.getFloatingProps(props)}
        />
      </FloatingPortal>
    );
  }
);

/**
 * This is a Floating UI Tooltip taken from here:
 *
 * - Base documentation: https://floating-ui.com/docs/tooltip
 * - Copied Example: https://codesandbox.io/s/xenodochial-grass-js3bo9?file=/src/App.tsx
 *
 * Most of the time you will pass a `asChild` prop to `Tooltip.Trigger` in order to use any element as the anchor.
 * Doing so may create a warning in your console around passing a `ref` to a function component. The tooltip will
 * likely not work as expected. To fix this, wrap your anchor component in a `forwardRef` call.
 */
export default merge(Tooltip, {
  Trigger: TooltipTrigger,
  Content: TooltipContent,
});
