import { createRoot } from 'react-dom/client';
import * as serviceWorker from 'serviceWorker';
import 'simplebar-react/dist/simplebar.min.css';

import App from 'containers/App/App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

serviceWorker.unregister();
