import { useState } from 'react';

import Flex from '@react-css/flex';
import { useAppSelector } from 'store';
import { selectIsSeller } from 'store/selectors/me/company';
import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import Heading from 'storybook/stories/molecules/Heading';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import type { Partner } from 'types/models/partner';
import type { UpdatePartnerParams } from 'utils/api/partners';

type PartnerInformationParams = {
  partner: Partner;
  updatePartner: (params: UpdatePartnerParams) => void;
};

const PartnerInformation = ({ partner, updatePartner }: PartnerInformationParams) => {
  const [buyerAssignedCode, setBuyerAssignedCode] = useState(partner.buyerAssignedCode);
  const [sellerAssignedCode, setSellerAssignedCode] = useState(partner.sellerAssignedCode);

  const isSeller = useAppSelector(selectIsSeller);

  const onAssignedCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (isSeller) {
      setSellerAssignedCode(value);
      updatePartner({ sellerAssignedCode: value });
    } else {
      setBuyerAssignedCode(value);
      updatePartner({ buyerAssignedCode: value });
    }
  };

  return (
    <Card>
      <Card.Head>
        <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
          Partner Information
        </Heading>
      </Card.Head>

      <Card.Body>
        <Flex column gap="24px">
          <Flex column gap="4px">
            <Label htmlFor="assignedCode">Your code for your partner&apos;s business</Label>
            <Flex.Item>
              <Input
                id="assignedCode"
                value={isSeller ? sellerAssignedCode : buyerAssignedCode}
                onChange={onAssignedCodeChange}
                placeholder="Enter a code here..."
              />
            </Flex.Item>
          </Flex>

          <Flex column gap="4px">
            <Body variant="Body/Header">Your partner&apos;s code for your business</Body>
            {isSeller ? buyerAssignedCode : sellerAssignedCode}
          </Flex>

          <Flex column gap="4px">
            <Body variant="Body/Header">Your partner&apos;s product platform</Body>
            {isSeller ? partner.buyerProductPlatform : partner.sellerProductPlatform}
          </Flex>

          <Flex column gap="4px">
            <Body variant="Body/Header">Your partner&apos;s order platform</Body>
            {isSeller ? partner.buyerOrderPlatform : partner.sellerOrderPlatform}
          </Flex>

          <Flex column gap="4px">
            <Body variant="Body/Header">This partnership&apos;s Modern Dropship ID</Body>
            {partner._id}
          </Flex>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default PartnerInformation;
