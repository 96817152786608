/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import Modal from 'storybook/stories/cells/Modal';

import SubmitFormButton from 'containers/PartnerPage/Actions/Common/SubmitFormButton';
import { getActionsSent, updateConsumableAction } from 'store/slices/partners';

import { isRejected } from '@reduxjs/toolkit';
import { ACTIONS_LIMIT, BTN_DELAY } from 'containers/PartnerPage/Actions/Shared';
import { useAppDispatch, useAppSelector } from 'store';
import { selectMe, selectPartners } from 'store/selectors/me';
import './CreateActionCardModal.css';

export type EditActionCardModalState = {
  id: string;
  cardTitle: string;
  cardText: string;
  required: boolean;
  kind: string;
};

type EditActionCardModalProps = {
  partnerId: string;
  cardEditDetails: EditActionCardModalState;
  setShowEditModal: (show: boolean) => void;
  show: boolean;
};

const EditActionCardModal = ({
  partnerId,
  cardEditDetails,
  setShowEditModal,
  show,
}: EditActionCardModalProps) => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [required, setRequired] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const { creatingAction } = useAppSelector(selectPartners);
  const { company } = useAppSelector(selectMe);

  useEffect(() => {
    if (cardEditDetails) {
      setTitle(cardEditDetails.cardTitle);
      setText(cardEditDetails.cardText);
      setRequired(cardEditDetails.required);
    }
  }, [cardEditDetails]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (title === '') {
      setError('Error: Title is required');
      return;
    }

    if (!company) {
      setError('Error: Company is required');
      return;
    }

    const body = {
      cardTitle: title,
      cardText: text,
      required,
    };
    dispatch(updateConsumableAction({ actionId: cardEditDetails.id, body })).then((action) => {
      if (isRejected(action)) {
        setError(action.error.message || 'Error: Failed to update action');
      } else {
        setSuccess(true);
        setTimeout(() => {
          setError('');
          setSuccess(false);
          dispatch(
            getActionsSent({
              assignedFrom: company._id,
              partnerId,
              page: 0,
              limit: ACTIONS_LIMIT,
            })
          );
        }, BTN_DELAY);
      }
    });
  };

  return (
    <Modal.Root show={show} onHide={() => setShowEditModal(false)} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Action</Modal.Title>
      </Modal.Header>

      <form onSubmit={onSubmit}>
        <Modal.Body>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="form-group">
            <label htmlFor="edit-action-modal-title">Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Ex. Partnership Agreement"
              id="edit-action-modal-title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="edit-action-modal-text">Text</label>
            <textarea
              className="form-control"
              placeholder="Describe the required steps."
              id="edit-action-modal-text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <div className="form-group">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="edit-action-modal-required"
                checked={required}
                onChange={(e) => setRequired(e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="edit-action-modal-required">
                Required
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <SubmitFormButton
            id="edit-action-modal-submit"
            label="Save"
            successfulLabel="Saved"
            success={success}
            loading={creatingAction}
          />
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
};

export default EditActionCardModal;
