/* eslint-disable jsx-a11y/label-has-associated-control */
import { useForm } from 'react-hook-form';

import Spinner from 'components/Common/Spinner';
import { updateCompanySLA } from 'store/slices/settings/sla';

import Flex from '@react-css/flex';
import { isRejected } from '@reduxjs/toolkit';
import useAlertQueue from 'hooks/useAlertQueue';
import { useAppDispatch, useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import FormField from 'storybook/stories/cells/FormField';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import UnitInput from 'storybook/stories/molecules/Input/UnitInput';
import { SettingsFooter, SettingsMain, SettingsPageHeader } from '../SettingsLayout';

type ServiceLevelFormFields = {
  maxShipTime: number;
  maxReturnRate: number;
};

const ServiceLevelsSettings = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const alertQueue = useAlertQueue();
  const formId = 'sla-settings-form';
  const { isUpdatingSLA } = useAppSelector(({ settings }) => settings.sla);

  const { handleSubmit, register, formState, reset } = useForm<ServiceLevelFormFields>({
    defaultValues: {
      maxShipTime: company?.maxShipTime || undefined,
      maxReturnRate: company?.maxReturnRate || undefined,
    },
  });

  const { isDirty } = formState;

  const onFormSubmit = (data: ServiceLevelFormFields) => {
    dispatch(updateCompanySLA(data)).then((action) => {
      if (isRejected(action)) {
        alertQueue.addErrorAlert(
          'Something went wrong',
          action.error.message || 'Please try again'
        );
        return;
      }
      alertQueue.addSuccessAlert('Success', 'Service Levels were updated');
    });
    reset({
      maxShipTime: data.maxShipTime > 0 ? data.maxShipTime : undefined,
      maxReturnRate: data.maxReturnRate > 0 ? data.maxReturnRate : undefined,
    });
  };

  return (
    <>
      <SettingsMain>
        <SettingsPageHeader title="Service Levels" />
        <form id={formId} onSubmit={handleSubmit(onFormSubmit)}>
          <Flex column gap="16px">
            <FormField id="maxShipTime" labelText="Expected Maximum Ship Time for Partners">
              <UnitInput
                id="maxShipTime"
                type="number"
                {...register('maxShipTime', {
                  setValueAs: (value) => (value === '' ? 0 : Number(value)),
                })}
                className={`form-control form-control-appended form-control-sm ${
                  formState.errors.maxShipTime ? 'is-invalid' : ''
                }`}
                placeholder="48"
                unit="hours"
              />
              {formState.errors.maxShipTime ? (
                <div className="invalid-feedback d-block">
                  {formState.errors.maxShipTime.message}
                </div>
              ) : null}

              <Body variant="Body/Caption" color="bodyTextSecondary">
                Average number of hours over the last 30 days from order received until order
                shipped
              </Body>
            </FormField>
            <FormField id="maxReturnRate" labelText="Expected Maximum Return Rate for Partners">
              <UnitInput
                id="maxReturnRate-input"
                type="number"
                {...register('maxReturnRate', {
                  setValueAs: (value) => (value === '' ? 0 : Number(value)),
                })}
                className={`form-control form-control-appended form-control-sm ${
                  formState.errors.maxReturnRate ? 'is-invalid' : ''
                }`}
                placeholder="10.99"
                unit="%"
              />
              {formState.errors.maxReturnRate ? (
                <div className="invalid-feedback d-block">
                  {formState.errors.maxReturnRate.message}
                </div>
              ) : null}
              <Body variant="Body/Caption" color="bodyTextSecondary">
                Average number of orders returned (as percent) over the last 30 days
              </Body>
            </FormField>
          </Flex>
        </form>
      </SettingsMain>
      <SettingsFooter>
        <PrimaryButton
          kind="action"
          type="submit"
          disabled={!isDirty || isUpdatingSLA}
          form={formId}
        >
          {isUpdatingSLA ? <Spinner color="white" className="mx-2" small /> : 'Save'}
        </PrimaryButton>
      </SettingsFooter>
    </>
  );
};

export default ServiceLevelsSettings;
