import Flex from '@react-css/flex';
import ConfirmationModal from 'components/Common/ConfirmationModal';
import BuyerDetails from 'containers/PartnerPage/PartnerDetails/BuyerDetails';
import SellerDetails from 'containers/PartnerPage/PartnerDetails/SellerDetails';
import { useState, type ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { selectIsBuyer, selectIsSeller } from 'store/selectors/me/company';
import Card from 'storybook/stories/cells/Card';
import { ButtonKinds } from 'storybook/stories/molecules/Button';
import Heading from 'storybook/stories/molecules/Heading';
import Toggle from 'storybook/stories/molecules/Toggle';
import type { Partner } from 'types/models/partner';
import { useBoolean } from 'usehooks-ts';
import type { UpdatePartnerParams } from 'utils/api/partners';

export type PartnerDetailsProps = {
  partner: Partner;
  updatePartner: (params: UpdatePartnerParams) => void;
};

const PartnerDetails = ({ partner, updatePartner }: PartnerDetailsProps) => {
  const [active, setActive] = useState(partner.active);
  const confirmationModalVisibility = useBoolean(false);

  const isSeller = useSelector(selectIsSeller);
  const isBuyer = useSelector(selectIsBuyer);

  // Get prices

  const onActiveChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (checked) {
      setActive(checked);
      updatePartner({ active: checked });
      return;
    }

    confirmationModalVisibility.setTrue();
  };

  return (
    <>
      <Card>
        <Card.Head>
          <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
            Details
          </Heading>
        </Card.Head>

        <Card.Body>
          <Flex column gap="16px">
            <Heading variant="Headings/H4">Partnership Settings</Heading>

            <Flex column gap="24px">
              <Toggle
                name="active"
                onChange={onActiveChange}
                checked={active}
                description={
                  active
                    ? 'This partnership is active. Orders will be synced.'
                    : 'This partnership is inactive. Orders will not be synced.'
                }
              >
                Active Partnership
              </Toggle>

              {isBuyer && <BuyerDetails partner={partner} updatePartner={updatePartner} />}
              {isSeller && <SellerDetails partner={partner} updatePartner={updatePartner} />}
            </Flex>
          </Flex>
        </Card.Body>
      </Card>

      <ConfirmationModal
        show={confirmationModalVisibility.value}
        onConfirm={() => {
          confirmationModalVisibility.setFalse();
          setActive(false);
          updatePartner({ active: false });
        }}
        onCancel={() => {
          confirmationModalVisibility.setFalse();
        }}
        confirmButtonKind={ButtonKinds.Destructive}
        confirmText="Deactivate Partnership"
      >
        Deactivating this partnership{' '}
        <em>
          will delist{' '}
          {isBuyer ? "this partner's products from your" : "your products from this partner's"}{' '}
          store{' '}
        </em>
        and prevent processing orders.
      </ConfirmationModal>
    </>
  );
};

export default PartnerDetails;
