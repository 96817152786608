import Flex from '@react-css/flex';
import ChipList from 'storybook/stories/cells/ChipList';
import Body from 'storybook/stories/molecules/Body';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Icon from 'storybook/stories/molecules/Icon';
import StatusIcon from 'storybook/stories/molecules/Icon/StatusIcon';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import styled from 'styled-components';
import type { Partner } from 'types/models/partner';
import { useBoolean } from 'usehooks-ts';
import { groupPendingGettingStartedSteps } from 'utils/partners';

const OnboardingStepContainer = styled(Flex)`
  margin-top: 8px;
  padding: 8px 16px;
`;

type OnboardingStepProps = {
  partner: Partner;
};

const OnboardingSteps = ({ partner }: OnboardingStepProps) => {
  const shouldShowMoreInfo = useBoolean(false);
  const { gettingStarted } = partner;

  if (!gettingStarted) return null;

  const groupedSteps = groupPendingGettingStartedSteps(gettingStarted);

  if (groupedSteps.length === 0) return null;

  return (
    <OnboardingStepContainer column gap="8px">
      <Flex.Item>
        <Flex gap="16px" alignItemsCenter>
          <Body variant="Body/Header" color="bodyTextSecondary">
            Pending Steps:
          </Body>
          <Flex.Item grow={1}>
            {!shouldShowMoreInfo.value && (
              <ChipList items={groupedSteps.map((group) => group.label)} />
            )}
          </Flex.Item>
          <TertiaryButton
            kind="action"
            size="small"
            $iconAfter
            $iconName={shouldShowMoreInfo.value ? 'expand_less' : 'expand_more'}
            onClick={() => {
              shouldShowMoreInfo.toggle();
            }}
          >
            {shouldShowMoreInfo.value ? 'Less' : 'More'} Info
          </TertiaryButton>
        </Flex>
      </Flex.Item>
      {shouldShowMoreInfo.value && (
        <Flex column gap="8px">
          <Body variant="Body/Body Small" color="bodyTextSecondary">
            Your partner has the following onboarding steps left to complete:
          </Body>
          {groupedSteps.map((group) => {
            return (
              <Flex.Item key={group.label}>
                <StatusIcon variant="unchecked">{group.label}</StatusIcon>
                <Flex column>
                  <ul>
                    {group.steps.map((step) => {
                      return (
                        <li>
                          {step.articleId ? (
                            <Icon
                              as={SupportLink}
                              article={step.articleId}
                              name="quick_reference"
                              position="after"
                            >
                              <Body variant="Body/Body Small" color="bodyTextSecondary">
                                {step.label}
                              </Body>
                            </Icon>
                          ) : (
                            <Body variant="Body/Body Small" color="bodyTextSecondary">
                              {step.label}
                            </Body>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </Flex>
              </Flex.Item>
            );
          })}
        </Flex>
      )}
    </OnboardingStepContainer>
  );
};

export default OnboardingSteps;
