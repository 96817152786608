import { useMutation } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import useOnboardingAttributeParams from 'hooks/useOnboardingAttributeParams';
import useOnboardingStorage from 'hooks/useOnboardingStorage';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';
import { refreshMe } from 'store/slices/me';
import { CommerceType, Platform } from 'types/models/company';
import type { UserOnboardingAttributes } from 'types/models/user';
import type ApiError from 'utils/ApiError';
import { updateUser } from 'utils/api/users';

const useShopifyAppInstallOnboardingAttributes = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const { addSuccessAlert, addErrorAlert } = useAlertQueue();
  const { onboardingStorage } = useOnboardingStorage();
  const { source, temporaryTokenId, storeUrl, companyName, companyUrl } =
    useOnboardingAttributeParams();
  const processed = useRef<boolean>(false);

  const updateOnboardingAttributes = useMutation({
    mutationFn: ({ id, ...params }: UserOnboardingAttributes & { id: string }) =>
      updateUser(id, { onboardingAttributes: params }),
    onSuccess: async () => {
      // Update redux state for the user
      await dispatch(refreshMe());
      // Show success message
      addSuccessAlert(
        'Installation Complete!',
        'Create your Modern Dropship account to get started'
      );
    },
    onError: (error: ApiError) => {
      addErrorAlert('Something went wrong.', error.message);
    },
  });

  useEffect(() => {
    if (processed.current) {
      return;
    }
    // If the user exists, update their onboarding attributes
    if (
      onboardingStorage &&
      user &&
      source === 'shopify' &&
      temporaryTokenId &&
      storeUrl &&
      companyName &&
      companyUrl
    ) {
      updateOnboardingAttributes.mutate({
        id: user._id,
        platform: Platform.Shopify,
        source: Platform.Shopify,
        storeUrl,
        companyName,
        companyUrl,
        temporaryTokenId,
        commerceType: onboardingStorage.commerceType ?? CommerceType.Seller,
        // Set defaults to prevent null values
        firstName: '',
        lastName: '',
        referralCompanyId: '',
      });
      processed.current = true;
    }
  }, [
    addSuccessAlert,
    source,
    temporaryTokenId,
    storeUrl,
    companyName,
    companyUrl,
    updateOnboardingAttributes,
    user,
    onboardingStorage,
  ]);
};

export default useShopifyAppInstallOnboardingAttributes;
