import styled from 'styled-components';

import { HELLO_SIGN, TERMS_AND_CONDITIONS } from 'containers/PartnerPage/Actions/Kinds';
import Chip from 'storybook/stories/molecules/Chip';

const EmptyPreviewWrapper = styled.div`
  padding: 1.875rem;
  padding-top: 7.813rem;
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;
const PreviewContentWrapper = styled.div`
  border: 1px solid #d2ddec;
  border-radius: 0.375rem;
  padding: 0.625rem 0.938rem;
`;

const EmailPreviewWrapper = styled.div`
  padding: 0.625rem 0.938rem;
`;
const SignersText = styled.p`
  margin-bottom: 8px;
`;
const SubjectText = styled.p`
  margin-bottom: 0rem;
`;
const MessageText = styled.p`
  margin-bottom: 0rem;
  white-space: pre-line;
  margin-top: 0.625rem;
`;

const PreviewCreateActionEmail = ({ kind, emailSubject, emailMessage, signers }) => {
  if (kind !== HELLO_SIGN && kind !== TERMS_AND_CONDITIONS) {
    return null;
  }

  if (emailSubject === '' && signers === '') {
    return (
      <EmptyPreviewWrapper>
        <h3 className="mb-2">Email Preview</h3>
        <p className="text-muted">
          Add an email subject and we will show you what the email being sent to your partner will
          look like.
        </p>
      </EmptyPreviewWrapper>
    );
  }
  return (
    <EmailPreviewWrapper>
      <h3 className="mb-2">Email Preview</h3>
      <p className="text-muted">
        Below you will see a sample of the email that your partner will see.
      </p>
      <PreviewContentWrapper>
        {signers && (
          <SignersText>
            <span>
              <b>To:</b>{' '}
            </span>
            {signers.split(',').map((signer) => (
              <Chip key={signer}>{signer}</Chip>
            ))}
          </SignersText>
        )}
        <SubjectText>
          <b>Subject:</b> {emailSubject}
        </SubjectText>
        <MessageText>{emailMessage}</MessageText>
      </PreviewContentWrapper>
    </EmailPreviewWrapper>
  );
};

export default PreviewCreateActionEmail;
