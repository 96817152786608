import PropTypes from 'prop-types';
import styled from 'styled-components';

import Spinner from 'components/Common/Spinner';

const Wrapper = styled.div`
  display: flex;
  min-height: 160px;
  justify-content: center;
  align-items: center;
  background: #e9eff6;
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 20px;
`;

const CenterText = styled.div`
  text-align: 'center';
`;

const ActiveMappingEmpty = (props) => {
  const { loading, hasSchemas = false } = props;

  if (loading) {
    return (
      <Wrapper>
        <CenterText>
          <Spinner />
        </CenterText>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <CenterText>
        <h4>You don&apos;t have an active mapping for this document</h4>
        <p className="mb-0">
          {hasSchemas ? 'activate one your mappings below or ' : ''}upload an example file to create
          one
        </p>
      </CenterText>
    </Wrapper>
  );
};

export default ActiveMappingEmpty;

ActiveMappingEmpty.propTypes = {
  hasSchemas: PropTypes.bool,
};
