import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from 'components/Common/Button';
import Message, { useMessage } from 'components/Common/Message';
import Spinner from 'components/Common/Spinner';
import DashboardPage from 'components/Dashboard/DashboardPage';
import ActiveMappingCard from 'containers/EDISchemas/ActiveMappingCard';
import ActiveMappingEmpty from 'containers/EDISchemas/ActiveMappingEmpty';
import AvailableMappings from 'containers/EDISchemas/AvailableMappings';
import {
  createSchema,
  defaultSchema,
  generateSchema,
  listSchemas,
  setLiveSchema,
} from 'store/slices/edi/schema';
import Icon from 'storybook/stories/molecules/Icon';
import { ACCOUNT_TYPE_SELLER } from 'utils/constants';
import { validateMappings } from 'utils/edi';

import { format } from 'date-fns';
import { useParams } from 'react-router';
import Page from 'storybook/stories/cells/Page';
import Tooltip from 'storybook/stories/cells/Tooltip';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import MappingConfiguration from './MappingConfiguration';

const ListView = styled.div`
  display: flex;
`;

const List = styled.div`
  flex: 1;
`;

const CreateNew = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px 10px 20px;
  padding: 20px;
  > p {
    font-weight: 600;
  }
  > input {
    width: 200px;
    margin-bottom: 15px;
  }
`;

const EDISchemaPage = () => {
  const { documentNumber } = useParams();
  const { schemaLoading, schema, schemaListLoading, schemaListLoaded, schemaList } = useSelector(
    ({ ediSchemas }) => ediSchemas
  );
  const { company } = useSelector(({ me }) => me);

  /* Computed values */
  const hasSchemas = !!(schemaListLoaded && schemaList.length);
  // There is currently only one endpoint for returning schemas - we should add
  // a new one that returns only the currently active schema for a document and
  // use that instead of pulling it out of the larger list.
  const activeSchema = schemaListLoaded && schemaList.find((s) => s.live);
  const inactiveSchemas = schemaListLoaded && schemaList.filter((s) => !s.live);

  const dispatch = useDispatch();

  const { showErrorMessage, showSuccessMessage, hideMessage, messageState } = useMessage();

  // Views
  const [LIST_VIEW, CREATE_VIEW] = ['LIST_VIEW', 'CREATE_VIEW'];
  const [view, setView] = useState(LIST_VIEW);

  /* Select document and upload */
  const [doc, setDoc] = useState(null);
  const onUpload = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    reader.onload = () => {
      const params = {
        documentNumber,
        body: {
          bytes: Array.from(new Uint8Array(reader.result)),
        },
      };
      dispatch(generateSchema(params)).then((action) => {
        if (action.error) {
          showErrorMessage({ title: 'Error generating mapping', message: action.error.message });
        } else {
          setView(CREATE_VIEW);
        }
      });
    };
    reader.onerror = (error) => {
      showErrorMessage({ title: 'Failed to upload example file', message: error });
    };
    reader.readAsArrayBuffer(doc);
  };

  const getSchemaList = () => {
    const params = { page: 0, limit: 25 };
    dispatch(listSchemas({ documentNumber, params })).then((action) => {
      if (action.error) {
        showErrorMessage({ title: 'Error getting mapping', message: action.error.message });
      }
    });
  };

  const onUseDefault = () => {
    const params = { documentNumber };
    dispatch(defaultSchema(params)).then((action) => {
      if (action.error) {
        showErrorMessage({ title: 'Error generating mapping', message: action.error.message });
      } else {
        showSuccessMessage({
          title: 'Default mapping successfully activated',
          message: `${documentNumber} documents will be processed using Modern Dropship's default mappings`,
        });
        getSchemaList();
      }
    });
  };

  useEffect(() => {
    getSchemaList();
    // eslint-disable-next-line
  }, [documentNumber]);

  /* Create schema */
  const onCreate = (e) => {
    e.preventDefault();
    const params = { documentNumber, schema };
    dispatch(createSchema(params)).then((action) => {
      if (action.error) {
        showErrorMessage({ title: 'Error saving mapping', message: action.error.message });
      } else {
        showSuccessMessage({
          title: 'Mapping successfully created',
          message: `To start processing ${documentNumber} documents using your new mapping, activate it below`,
        });
        getSchemaList();
        setView(LIST_VIEW);
      }
    });
  };

  /* Set Live Schema */
  const onSetLiveSchema = (schemaID) => {
    const params = {
      documentNumber,
      schemaID,
    };
    dispatch(setLiveSchema(params)).then((action) => {
      if (action.error) {
        showErrorMessage({ title: 'Error activating mapping', message: action.error.message });
      } else {
        showSuccessMessage({
          title: 'Mapping successfully activated',
          message: `Your ${documentNumber} documents will now be processed using this mapping configuration`,
        });
        getSchemaList();
      }
    });
  };

  const isInvalidMappingSchema = () => {
    const errors = validateMappings(schema.mappings);
    return errors.length > 0 || schema.name === '';
  };

  const errorTree = () => {
    const map = {};
    const errors = validateMappings(schema.mappings);
    errors.forEach((error) => {
      if (!map[error.segmentID]) {
        map[`${error.segmentID}`] = {};
      }
      if (!map[error.segmentID][error.segmentIndex]) {
        map[error.segmentID][error.segmentIndex] = {};
      }
      if (!map[error.segmentID][error.segmentIndex][error.elementIndex]) {
        map[error.segmentID][error.segmentIndex][error.elementIndex] = '';
      }
      map[error.segmentID][error.segmentIndex][error.elementIndex] = error.message;
    });
    return map;
  };

  const elementMappingErrors = errorTree();
  const title = hasSchemas ? 'Mapping Schemas' : 'Configure Schema';

  const formatDate = (date) => format(new Date(date), 'yyyy-MM-dd');

  return (
    <DashboardPage>
      <Page>
        <Page.Head title={`${title}: ${documentNumber}`}>
          {view === CREATE_VIEW && (
            <PrimaryButton size="small" disabled={isInvalidMappingSchema()} onClick={onCreate}>
              Save
            </PrimaryButton>
          )}
        </Page.Head>

        <Page.Body>
          <Message show={messageState.show} onClose={hideMessage} kind={messageState.kind}>
            <p className="m-0 mb-1">
              <strong>{messageState.title}</strong>
            </p>
            <p className="m-0">{messageState.message}</p>
          </Message>
          {view === LIST_VIEW && (
            <ListView>
              <List>
                {activeSchema ? (
                  <ActiveMappingCard activeMappingSchema={activeSchema} formatDate={formatDate} />
                ) : (
                  <ActiveMappingEmpty loading={schemaListLoading} hasSchemas={hasSchemas} />
                )}
                {inactiveSchemas.length > 0 && (
                  <AvailableMappings
                    mappingSchemas={inactiveSchemas}
                    onSetLiveSchema={onSetLiveSchema}
                    formatDate={formatDate}
                  />
                )}
              </List>
              <div>
                {company.commerceType === ACCOUNT_TYPE_SELLER &&
                  documentNumber !== '832' && ( // there is no default schema for the 832 at this time
                    <CreateNew className="card">
                      <Tooltip>
                        <Tooltip.Trigger asChild>
                          <Icon name="help" position="after">
                            Use default mapping
                          </Icon>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                          Sellers using Modern Dropship&apos;s default EDI specifications can use
                          our default mapping
                        </Tooltip.Content>
                      </Tooltip>
                      <Button
                        block
                        color="primary"
                        className="use-default"
                        onClick={() => onUseDefault()}
                      >
                        {schemaLoading ? <Spinner color="white" small /> : 'Activate default'}
                      </Button>
                    </CreateNew>
                  )}
                <CreateNew className="card">
                  <p>Create new mapping</p>
                  <input type="file" onChange={(e) => setDoc(e.target.files[0])} />
                  <Button
                    block
                    color="primary"
                    onClick={(e) => onUpload(e)}
                    disabled={doc === null}
                  >
                    {schemaLoading ? <Spinner color="white" small /> : 'Upload'}
                  </Button>
                </CreateNew>
              </div>
            </ListView>
          )}
          {view === CREATE_VIEW && (
            <MappingConfiguration documentNumber={documentNumber} errors={elementMappingErrors} />
          )}
        </Page.Body>
      </Page>
    </DashboardPage>
  );
};

export default EDISchemaPage;
