import styled, { css } from 'styled-components';

export const RemovedRow = styled.div`
  border-radius: 4px;
  padding: 8px;
  margin: 4px;
  grid-column: 1 / -1;

  ${({ theme }) => css`
    background-color: ${theme.color.error100};
    border: 1px solid ${theme.color.error500};
    color: ${theme.color.error700};
  `}
`;

export const EditableInput = styled.input`
  padding: 8px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const editingBorder = {
  border: '2px dashed lightgrey',
  borderRadius: '10px',
  padding: '10px',
};
