import Flex from '@react-css/flex';
import { MessageContent } from 'storybook/stories/species/Chat/Shared.styles';
import styled from 'styled-components';
import type { ChatMessage } from 'types/models/conversation';

const StyledMessageContent = styled(MessageContent)`
  background-color: ${({ theme }) => theme.color.blue500};
  color: ${({ theme }) => theme.color.white};
  max-width: 80%;
`;

interface ChatHumanMessageProps {
  content: ChatMessage['content'];
}

const ChatHumanMessage = ({ content }: ChatHumanMessageProps) => {
  return (
    <Flex justifyEnd>
      <StyledMessageContent>{content}</StyledMessageContent>
    </Flex>
  );
};

export default ChatHumanMessage;
