import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'storybook/stories/molecules/Icon';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Summary = styled.div`
  flex: 1;
  min-width: fit-content;
  > p {
    margin-bottom: 0px;
    font-weight: 600;
    color: #228aff;
  }
  > span {
    font-size: 12px;
  }
`;

const ActiveMessage = styled.div`
  display: flex;
  min-width: fit-content;
  align-items: center;
  color: #00d97e;
  > i {
    margin-right: 5px;
    margin-top: 4px;
  }
  > span {
    font-size: 12px;
  }
`;

const ActiveMappingCard = (props) => {
  const { activeMappingSchema = {}, formatDate = () => {} } = props;
  return (
    <Container className="card mb-10 flex">
      <Summary>
        <p>{activeMappingSchema.name}</p>
        <span>{`Created ${formatDate(activeMappingSchema.created)}`}</span>
      </Summary>
      <ActiveMessage>
        <Icon name="check_circle">mapping is active</Icon>
      </ActiveMessage>
    </Container>
  );
};

ActiveMappingCard.propTypes = {
  activeMappingSchema: PropTypes.object,
  formatDate: PropTypes.func,
};

export default ActiveMappingCard;
