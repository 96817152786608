import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';

const SubmitFormButton = ({ id, label, successfulLabel, success, loading }) => {
  if (success) {
    return (
      <PrimaryButton size="small" $iconAfter $iconName="check">
        {successfulLabel}
      </PrimaryButton>
    );
  }
  if (loading) {
    return (
      <PrimaryButton size="large" disabled>
        <span
          className="spinner-border spinner-border-sm"
          style={{ height: '12px', width: '12px' }}
          role="status"
          aria-hidden="true"
        />
      </PrimaryButton>
    );
  }
  return (
    <PrimaryButton size="small" type="submit" id={id}>
      {label}
    </PrimaryButton>
  );
};

export default SubmitFormButton;
