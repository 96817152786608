import useAlertQueue from 'hooks/useAlertQueue';
import type React from 'react';
import type { CSSProperties } from 'react';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

interface StyledUploadProps {
  borderRadius?: CSSProperties['borderRadius'];
}

const StyledUpload = styled.div<StyledUploadProps>`
  background-color: ${({ theme }) => theme.color.gray100};
  border-radius: ${({ borderRadius }) => borderRadius}; // Use prop or default to 16px
  border: 1px dashed ${({ theme }) => theme.color.gray200};
  padding: 16px;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  display: block;
`;

export interface UploadedFile {
  file: Uint8Array;
  fileName: string;
  fileType: string;
}

interface UploadProps {
  children?: React.ReactNode;
  onUpload: (file: UploadedFile) => void;
  borderRadius?: CSSProperties['borderRadius'];
}

const FileUpload = ({ children, onUpload, borderRadius = '16px' }: UploadProps) => {
  const [file, setFile] = useState<File | null>(null);
  const alertQueue = useAlertQueue();

  const onFileChangeOrDrop = useCallback(
    (selectedFile: File | null) => {
      if (selectedFile) {
        const reader = new FileReader();

        reader.onloadend = (e) => {
          if (e.target && e.target.result instanceof ArrayBuffer) {
            const arrayBuffer = e.target.result;
            const fileByteArray = new Uint8Array(arrayBuffer);
            onUpload({
              file: fileByteArray,
              fileName: selectedFile.name,
              fileType: selectedFile.type,
            });
          }
        };

        reader.onerror = () => {
          alertQueue.addErrorAlert('Error', 'Error reading file');
        };

        reader.readAsArrayBuffer(selectedFile);
      }
    },
    [onUpload, alertQueue]
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    if (files.length > 0) {
      setFile(files[0]);
    }
  };

  useEffect(() => {
    if (file) {
      onFileChangeOrDrop(file);
      setFile(null);
    }
  }, [file, onFileChangeOrDrop]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <StyledUpload onDrop={handleDrop} onDragOver={handleDragOver} borderRadius={borderRadius}>
      <StyledLabel htmlFor="file">
        {children}
        <input id="file" type="file" onChange={handleFileChange} style={{ display: 'none' }} />
      </StyledLabel>
    </StyledUpload>
  );
};

export default FileUpload;
