import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useMutation } from '@tanstack/react-query';
import { DIMENSION_OPTIONS, WEIGHT_OPTIONS } from 'containers/OrderPage/ShippingLabels/constants';
import {
  calculateTotalWeight,
  getItemQuantities,
} from 'containers/OrderPage/ShippingLabels/helpers';
import useAlertQueue from 'hooks/useAlertQueue';
import React from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import Select from 'storybook/stories/molecules/Select';
import styled from 'styled-components';
import type { OrderItem } from 'types/models/order';
import type { ShippingLabelQuantities } from 'types/models/shipping';
import type { CreateShippingLabelParams } from 'utils/api/orders';
import { createShippingLabel } from 'utils/api/orders';
import { downloadShippingLabel } from 'utils/api/shipping';

const Wrapper = styled.div`
  max-width: 125px;
`;

interface ShippingLabelFormData {
  quantities: ShippingLabelQuantities;
  weight: number;
  weightUnit: { label: string; value: string };
  width: number;
  height: number;
  depth: number;
  dimensionsUnit: string;
}

interface OrderItemsProps {
  items: OrderItem[];
}
const OrderItems = ({ items }: OrderItemsProps) => {
  const { control } = useFormContext();

  return (
    <Flex column gap="24px">
      <Heading variant="Headings/H2">Select Items</Heading>

      <Grid columns="auto 1fr" gap="32px">
        {items?.map((item: OrderItem, index: number) => {
          return (
            <React.Fragment key={item._id}>
              <Grid.Item>
                <Flex column gap="6px">
                  <Label htmlFor={`quantity-${index}`} isRequired>
                    Quantity to include in label
                  </Label>

                  <Controller
                    name={`quantity-${index}`}
                    control={control}
                    defaultValue={1}
                    render={({ field }) => (
                      <Input placeholder="1" type="number" min="1" max={item.quantity} {...field} />
                    )}
                  />
                </Flex>
              </Grid.Item>
              <Grid.Item alignSelfEnd>
                <Flex column>
                  <Body color="blue500">{item.title}</Body>
                  <Body color="gray500">SKU: {item.sku}</Body>
                </Flex>
              </Grid.Item>
            </React.Fragment>
          );
        })}
      </Grid>
    </Flex>
  );
};

const ParcelInfo = () => {
  const { register, control } = useFormContext();

  return (
    <Flex column gap="24px">
      <Heading variant="Headings/H2">Parcel Info</Heading>
      {/* Weight Input */}
      <Flex gap="16px">
        <Flex column gap="6px">
          <Label htmlFor="weight" isRequired>
            Weight
          </Label>
          <Input
            type="number"
            min="1"
            placeholder="e.g. 20.8"
            {...register('weight', { required: true, valueAsNumber: true })}
          />
        </Flex>

        <Flex column gap="6px">
          <Label htmlFor="weightUnit" isRequired>
            Unit
          </Label>
          <Controller
            name="weightUnit"
            control={control}
            render={({ field }) => (
              <Select
                isSearchable={false}
                inputId="weightUnit-select"
                options={WEIGHT_OPTIONS}
                defaultValue={WEIGHT_OPTIONS[0]}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Flex>
      </Flex>

      {/* Dimensions Inputs */}
      <Flex wrap gap="10px">
        <Wrapper>
          <Flex column gap="6px">
            <Label htmlFor="width">Width</Label>
            <Input
              type="number"
              min="1"
              placeholder="e.g. 12"
              {...register('width', { required: false, valueAsNumber: true })}
            />
          </Flex>
        </Wrapper>

        <Wrapper>
          <Flex column gap="6px">
            <Label htmlFor="height">Height</Label>
            <Input
              type="number"
              min="1"
              placeholder="e.g. 8"
              {...register('height', { required: false, valueAsNumber: true })}
            />
          </Flex>
        </Wrapper>

        <Wrapper>
          <Flex column gap="6px">
            <Label htmlFor="depth">Depth</Label>
            <Input
              type="number"
              min="1"
              placeholder="e.g. 14"
              {...register('depth', { required: false, valueAsNumber: true })}
            />
          </Flex>
        </Wrapper>

        <Wrapper>
          <Flex column gap="6px">
            <Label htmlFor="dimensionsUnits">Unit</Label>
            <Controller
              name="dimensionUnit"
              control={control}
              defaultValue={DIMENSION_OPTIONS[0]}
              render={({ field }) => (
                <Select
                  isSearchable={false}
                  inputId="dimensionUnit-select"
                  options={DIMENSION_OPTIONS}
                  defaultValue={DIMENSION_OPTIONS[0]}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Flex>
        </Wrapper>
      </Flex>
    </Flex>
  );
};

interface CreateShippingLabelModalProps {
  shouldShow: boolean;
  onDismiss: () => void;
  orderId: string;
  items: OrderItem[];
  refetchShippingLabels: () => void;
}

const CreateShippingLabelModal = ({
  shouldShow,
  onDismiss,
  orderId,
  items,
  refetchShippingLabels,
}: CreateShippingLabelModalProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const formMethods = useForm<ShippingLabelFormData>({
    mode: 'onChange',
    defaultValues: {
      quantities: getItemQuantities(items),
      weight: calculateTotalWeight(items),
      weightUnit: WEIGHT_OPTIONS[0], // Backend always returns this in grams
      dimensionsUnit: 'in',
    },
  });

  const openingShippingLabel = useMutation({
    mutationFn: (labelId: string) => downloadShippingLabel(labelId),
    onSuccess: (res) => {
      refetchShippingLabels();
      window.open(res.data, '_blank', 'noopener,noreferrer');
    },
    onError: (error: { message: string }) => {
      addErrorAlert('Something went wrong', error.message);
      console.error('Could not open shipping label', error);
    },
  });

  const creatingLabel = useMutation({
    mutationFn: (data: CreateShippingLabelParams) => createShippingLabel(orderId, data),
    onSuccess: (res) => {
      formMethods.reset();
      onDismiss();
      openingShippingLabel.mutate(res.data.id);
      addSuccessAlert('Success', 'You have created a shipping label for this Order.');
    },
    onError: (error: { message: string }) => {
      addErrorAlert('Something went wrong', error.message);
      console.error('Error creating shipping label', error);
    },
  });

  const onSubmit = (data: ShippingLabelFormData) => {
    const dimensions = data.dimensionsUnit
      ? {
          width: data.width,
          height: data.height,
          length: data.depth,
          units: data.dimensionsUnit,
        }
      : undefined;

    const body: CreateShippingLabelParams = {
      quantities: data.quantities,
      weight: {
        value: data.weight,
        units: data.weightUnit.value,
      },
      dimensions,
    };

    addSuccessAlert('Pending', 'Attempting to create a shipping label.');
    creatingLabel.mutate(body);
  };

  return (
    <Modal.Root show={shouldShow} onHide={onDismiss} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Create a Shipping Label</Modal.Title>
      </Modal.Header>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
          <Modal.Body>
            <Flex column gap="32px">
              <OrderItems items={items} />

              <ParcelInfo />
            </Flex>
          </Modal.Body>

          <Modal.Footer>
            <SecondaryButton
              aria-label="cancel-button"
              size="large"
              onClick={onDismiss}
              kind="destructive"
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              aria-label="generate-shipping-label-button"
              disabled={!formMethods.formState.isValid || formMethods.formState.isSubmitting}
              size="large"
              kind="action"
              type="submit"
            >
              Generate Shipping Label
            </PrimaryButton>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal.Root>
  );
};

export default CreateShippingLabelModal;
