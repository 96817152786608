import type { PerOrderFee } from 'types/models/per-order-fee';

export interface Invoice {
  id: string;
  created: string;
  updated: string;
  buyerCode: string;
  sellerCode: string;
  items: InvoiceItem[];
  taxes: TaxItem[] | null;
  finalized?: boolean;
  source: InvoicePoster;
  posted: boolean;
  currency: string;
  amountTotalCents: number;
  amountPaidCents: number;
  amountRemainingCents: number;
  download: string;
  hostedURL?: string;
  paidDate: string;
  buyerCompanyId: string;
  sellerCompanyId: string;
  EDISent?: boolean;
  status: InvoiceStatus;
  toBePostedOnDate: string;
  voidReason: string;
  orderFee?: PerOrderFee;
}

export interface InvoiceItem {
  _id: string;
  amountCents: number;
  reference: string;
  referenceId: string;
}

export interface TaxItem {
  amountCents: number;
  amountPercent: number;
  type: string;
}

/*
                   ┌────┐
                 ┌►│Paid│
┌─────┐   ┌────┐ │ └────┘
│Draft├──►│Open├─┤
└─────┘   └────┘ │ ┌────┐
                 └►│Void│
                   └────┘
*/
export enum InvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Void = 'void',
}

export enum InvoicePoster {
  None = '',
  Stripe = 'stripe',
  StripeCharge = 'stripecharge', // Deprecated
  External = 'external', // Deprecated
}
