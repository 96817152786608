import Flex from '@react-css/flex';
import type { PartnerFilters } from 'containers/PartnersPage/PartnerFilters';
import PartnerCard from 'containers/PartnersPage/PartnerSearch/PartnerCard';
import PartnerSearchForm from 'containers/PartnersPage/PartnerSearch/PartnerSearchForm';
import { InnerCard } from 'containers/PartnersPage/PartnerSearch/Shared.styles';
import PageList from 'storybook/stories/cells/PageList';
import PageNavigation from 'storybook/stories/cells/PageNavigation';
import type { Partner } from 'types/models/partner';

type PartnerSearchProps = {
  partners: Partner[];
  isBuyer: boolean;
  page: number;
  limit: number;
  setPage: (page: number) => void;
  setPartnerName: (partnerName: string) => void;
  filters: PartnerFilters;
  setFilters: (filters: PartnerFilters) => void;
};

const PartnerSearch = ({
  partners,
  isBuyer,
  page,
  limit,
  setPage,
  setPartnerName,
  filters,
  setFilters,
}: PartnerSearchProps) => {
  const showPaging = partners.length >= limit || page !== 0;
  const showNext = partners.length >= limit;

  return (
    <Flex column gap="8px">
      <PartnerSearchForm
        filters={filters}
        setFilters={setFilters}
        onUserFinishedTyping={setPartnerName}
      />

      {partners?.map((partner) => (
        <PartnerCard
          key={partner._id}
          isBuyer={isBuyer}
          partner={partner}
          partnerId={partner._id}
        />
      ))}

      {showPaging && (
        <InnerCard>
          <Flex justifyEnd alignItemsCenter gap="16px">
            <PageList currentPage={page + 1} />
            <PageNavigation
              hasPrevious={page > 0}
              hasNext={showNext}
              onPreviousClick={(e) => {
                e.preventDefault();
                if (page < 1) {
                  return;
                }
                setPage(page - 1);
              }}
              onNextClick={(e) => {
                e.preventDefault();
                setPage(page + 1);
              }}
            />
          </Flex>
        </InnerCard>
      )}
    </Flex>
  );
};
export default PartnerSearch;
