import Flex from '@react-css/flex';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SettingsMain, SettingsPageHeader } from 'containers/SettingsPage/SettingsLayout';
import useAlertQueue from 'hooks/useAlertQueue';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import { selectCompanyId } from 'store/selectors/me/company';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Toggle from 'storybook/stories/molecules/Toggle';
import { getCompany, updateCompany, type CompanyFlagsUpdate } from 'utils/api/companies';
import type ApiError from 'utils/ApiError';

const CompanyFlagsSettings = () => {
  const companyId = useAppSelector(selectCompanyId);
  const alertQueue = useAlertQueue();

  /**
   * Form
   */

  type FormFields = CompanyFlagsUpdate & {
    enableShippingLinesShopify: boolean;
  };

  const { handleSubmit, register, reset, watch } = useForm<FormFields>({
    mode: 'onChange',
  });

  const overwriteComparedAtPrice = watch('overwriteComparedAtPrice');
  const useRetailPriceForComparedAt = watch('useRetailPriceForComparedAt');

  /**
   * Queries
   */

  const fetchingCompany = useQuery({
    queryKey: ['getCompany', companyId] as const,
    queryFn: () => getCompany(companyId!),
    enabled: !!companyId,
    onSuccess: ({ enableShippingLinesShopify, flags }) => {
      reset({ enableShippingLinesShopify, ...flags });
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Something went wrong', error.message);
    },
  });

  /**
   * Mutations
   */

  const updatingCompany = useMutation({
    mutationFn: (data: FormFields) => {
      const { enableShippingLinesShopify, ...flags } = data;
      return updateCompany(companyId!, { enableShippingLinesShopify, flags });
    },
    onSuccess: () => {
      alertQueue.addSuccessAlert('Company flags updated', 'Company flags have been updated');
      fetchingCompany.refetch();
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Something went wrong', error.message);
    },
  });

  /**
   * Event Handlers
   */

  const onSubmit = handleSubmit((values) => {
    const data = { ...values };

    // Ensure that only one of the compared at price flags is set
    if (values.overwriteComparedAtPrice) {
      data.useRetailPriceForComparedAt = false;
    }

    if (values.useRetailPriceForComparedAt) {
      data.overwriteComparedAtPrice = false;
    }

    updatingCompany.mutate(data);
  });

  /**
   * Render
   */

  return (
    <SettingsMain>
      <SettingsPageHeader title="Company Flags" />

      <form onSubmit={onSubmit}>
        <Flex column gap="16px">
          <Toggle {...register('skipBuyerNotifications')}>Skip Buyer Notifications</Toggle>

          <Toggle {...register('ignoreInventoryType')}>Ignore Inventory Type</Toggle>

          <Toggle
            {...register('overwriteComparedAtPrice')}
            disabled={!!useRetailPriceForComparedAt}
          >
            Overwrite Compared At Price
          </Toggle>

          <Toggle
            {...register('useRetailPriceForComparedAt')}
            disabled={!!overwriteComparedAtPrice}
          >
            Use Retail Price for Compared At
          </Toggle>

          <Toggle {...register('pushProductsAsTaxable')}>Push Products as Taxable</Toggle>

          <Toggle {...register('enableShippingLinesShopify')}>Enable Shipping Lines Shopify</Toggle>

          <Flex justifyEnd>
            <PrimaryButton type="submit" disabled={updatingCompany.isLoading}>
              Submit
            </PrimaryButton>
          </Flex>
        </Flex>
      </form>
    </SettingsMain>
  );
};

export default CompanyFlagsSettings;
