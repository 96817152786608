import type { BaseQueryParams, Response } from 'types/api';
import type { PartnerAction, Terms } from 'types/models/partner-action';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'utils/request';

export interface ListConsumableActionTemplatesQuery extends BaseQueryParams {
  kind?: string;
}

export type ListConsumableActionTemplatesResponse = Response<PartnerAction[]>;

export const listConsumableActionTemplates = (
  query: ListConsumableActionTemplatesQuery
): Promise<ListConsumableActionTemplatesResponse> => getRequest(`/action-templates`, query);

export interface CreateConsumableActionTemplateBody {
  kind: string;
  cardTitle: string;
  cardText: string;
  required: boolean;
  documentId?: string;
  emailSubject?: string;
  emailMessage?: string;
  optionalDocId?: string;
  terms?: Terms;
}

export type CreateConsumableActionTemplateResponse = Response<{ id: string }>;

export const createConsumableActionTemplate = (
  body: CreateConsumableActionTemplateBody
): Promise<CreateConsumableActionTemplateResponse> => postRequest(`/action-templates`, body);

export const deleteConsumableActionTemplate = (id: string): Promise<Response<null>> =>
  deleteRequest(`/action-templates/${id}`);

export interface UpdateConsumableActionTemplateBody {
  cardTitle?: string;
  cardText?: string;
  required?: boolean;
  applyToNewPartners?: boolean;
}

export const updateConsumableActionTemplate = (
  id: string,
  body: UpdateConsumableActionTemplateBody
): Promise<Response<null>> => patchRequest(`/action-templates/${id}`, body);
