import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import Flex from '@react-css/flex';
import elevate from 'storybook/mixins/elevate';
import typography from 'storybook/mixins/typography';

type LabelWrapperProps = Pick<ToggleProps, 'disabled'>;

const LabelWrapper = styled.label<LabelWrapperProps>`
  display: inline-flex;
  align-items: start;
  flex-wrap: nowrap;
  position: relative;
  gap: 8px;

  ${typography('Navigation/Nav Link')};
  line-height: 20px;

  ${({ theme, disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          color: ${theme.color.bodyTextDisabled};
          font-style: italic;
        `
      : css`
          cursor: pointer;
          color: ${theme.color.bodyTextPrimary};
        `}
`;

const CheckboxInput = styled.input.attrs(() => ({ type: 'checkbox' }))`
  opacity: 0;
  position: absolute;
  inset: 0;
  cursor: inherit;
`;

const ToggleTrack = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  width: 40px;
  min-width: 40px;
  height: 20px;
  position: relative;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.gray300};

  // Inside the ToggleTrack we create a Handle UI
  &::before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.color.white};
  }

  /* FOCUS STATE */

  // Provides an outline
  ${CheckboxInput}:focus + & {
    outline: 1px solid ${({ theme }) => theme.color.blue500};
  }

  // Removes the outline if the keyboard has not been used to focus
  ${CheckboxInput}:focus:not(:focus-visible) + & {
    outline: none;
  }

  /* CHECKED STATE */

  // Changes the background color
  ${CheckboxInput}:checked + & {
    background-color: ${({ theme }) => theme.color.blue500};
    box-shadow: inset 1px 3px 2px rgba(0, 0, 0, 0.15);
  }

  // Moves the handle to the right
  ${CheckboxInput}:checked + &::before {
    left: auto;
    right: 2px;
    ${elevate('1')}
  }

  /* DISABLED STATE */

  ${CheckboxInput}:disabled + & {
    background-color: ${({ theme }) => theme.color.gray200};
  }
`;

const DescriptionWrapper = styled.span`
  ${typography('Inputs/Input Message')};
  color: ${({ theme }) => theme.color.bodyTextSecondary};
  padding-left: 48px; // Toggle track plus flex gap
`;

interface ToggleProps extends React.ComponentProps<typeof CheckboxInput> {
  /**
   * The name of the form control
   */
  name: string;
  /**
   * The text accompanying the toggle
   */
  children?: React.ReactNode;
  /**
   * Descriptive text, only appears if `children` is also present
   */
  description?: string;
}

/**
 * An accessible toggle control. Additional props passed directly to the underlying styled checkbox `input`.
 */
const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({ name, children, disabled, description, ...rest }, ref) => (
    <Flex column gap="8px">
      <LabelWrapper disabled={disabled}>
        <CheckboxInput name={name} ref={ref} disabled={disabled} {...rest} />
        <ToggleTrack />
        {children}
      </LabelWrapper>
      {children && description && <DescriptionWrapper>{description}</DescriptionWrapper>}
    </Flex>
  )
);

export default Toggle;
