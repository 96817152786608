import Flex from '@react-css/flex';
import { TableMeta } from 'storybook/stories/cells/SearchResult/shared/Styles';
import Tooltip from 'storybook/stories/cells/Tooltip';
import StatusIcon, { StatusIconVariants } from 'storybook/stories/molecules/Icon/StatusIcon';
import type { Order } from 'types/models/order';
import { getOrderReturnStatus, OrderReturnSummary } from 'utils/return';

interface CancelledStatusIconProps {
  partialCondition: boolean;
  fullCondition: boolean;
  name: string;
}

const PartialStatusIcon = ({ partialCondition, fullCondition, name }: CancelledStatusIconProps) => {
  let variant = StatusIconVariants.Unchecked;

  if (partialCondition && !fullCondition) variant = StatusIconVariants.Warning;
  else if (fullCondition) variant = StatusIconVariants.Checked;

  return (
    <StatusIcon variant={variant}>
      <TableMeta>{name}</TableMeta>
    </StatusIcon>
  );
};

interface OrderReturnStatusProps {
  order: Order;
  children?: React.ReactNode;
}

export const OrderReturnStatus = ({ order, children }: OrderReturnStatusProps) => {
  let variant: StatusIconVariants;
  let tip: string | undefined;
  switch (getOrderReturnStatus(order)) {
    case OrderReturnSummary.HasPendingReturns:
      variant = StatusIconVariants.Warning;
      tip = 'This order has returns that are pending approval.';
      break;
    case OrderReturnSummary.PartiallyReturned:
      variant = StatusIconVariants.PartialSuccess;
      tip = 'Some items in this order have been returned.';
      break;
    case OrderReturnSummary.FullyReturned:
      variant = StatusIconVariants.Checked;
      tip = 'All items in this order have been returned.';
      break;
    default:
      variant = StatusIconVariants.Unchecked;
      break;
  }

  const icon = (
    <StatusIcon variant={variant} data-testid="order-return-status">
      {children}
    </StatusIcon>
  );

  if (tip) {
    return (
      <Tooltip>
        <Tooltip.Trigger>{icon}</Tooltip.Trigger>
        <Tooltip.Content>{tip}</Tooltip.Content>
      </Tooltip>
    );
  }
  return icon;
};

interface OrderStatusProps {
  order: Order;
}

const OrderStatus = ({ order }: OrderStatusProps) => (
  <Flex gap="16px" wrap>
    <StatusIcon variant={order.posted ? 'checked' : 'unchecked'}>
      <TableMeta>Confirmed</TableMeta>
    </StatusIcon>

    <PartialStatusIcon
      partialCondition={order.hasCancellations}
      fullCondition={order.isCancelled}
      name="Cancelled"
    />

    <StatusIcon variant={order.shipped ? 'checked' : 'unchecked'}>
      <TableMeta>Shipped</TableMeta>
    </StatusIcon>

    <StatusIcon variant={order.billed ? 'checked' : 'unchecked'}>
      <TableMeta>Billed</TableMeta>
    </StatusIcon>

    <StatusIcon variant={order?.invoice?.paid ? 'checked' : 'unchecked'}>
      <TableMeta>Paid</TableMeta>
    </StatusIcon>

    <OrderReturnStatus order={order}>
      <TableMeta>Returned</TableMeta>
    </OrderReturnStatus>
  </Flex>
);

export default OrderStatus;
