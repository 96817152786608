import { noop, range } from 'lodash';
import styled, { css } from 'styled-components';

import resetButtonStyles from 'storybook/mixins/resetButtonStyles';

const ELLIPSIS = '...';

/**
 * Returns an array of numbers & strings used for displaying the page list.
 * Uses 3 as the basis for the number of page items to group together.
 */
export const getPageNumberTemplate = (currentPage: number, lastPage?: number) => {
  let safeCurrentPage = currentPage > 1 ? currentPage : 1;

  if (!lastPage) return [safeCurrentPage];

  if (currentPage > lastPage) safeCurrentPage = lastPage;

  const isGroupAtStart = safeCurrentPage <= 3;
  const isGroupAtEnd = safeCurrentPage > lastPage - 3;
  const isPaginationShort = lastPage <= 4;

  if (isPaginationShort) return range(1, lastPage + 1, 1);

  if (isGroupAtStart) return [1, 2, 3, ELLIPSIS, lastPage];

  if (isGroupAtEnd) return [1, ELLIPSIS, lastPage - 2, lastPage - 1, lastPage];

  return [
    1,
    ELLIPSIS,
    safeCurrentPage - 1,
    safeCurrentPage,
    safeCurrentPage + 1,
    ELLIPSIS,
    lastPage,
  ];
};

const Wrapper = styled.div`
  display: inline-flex;
  align-items: baseline;

  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.color.gray600};
    gap: 16px;
  `}
`;

const List = styled.div`
  margin: 0;
  list-style: none;
  display: inline-flex;
  align-items: baseline;
  gap: 16px;
`;

interface ListItemProps {
  isActive?: boolean;
}

const activeItemStyles = css`
  font-weight: 700;
  border-bottom: 2px solid transparent;

  ${({ theme }) => css`
    color: ${theme.color.blue500};
  `}
`;

const ListItem = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))<ListItemProps>`
  ${resetButtonStyles};

  ${({ theme, isActive }) =>
    isActive &&
    css`
      ${activeItemStyles};
      border-color: ${theme.color.blue500};
    `}

  &:hover {
    ${activeItemStyles}
  }
`;

interface PageListProps {
  /**
   * The page number that the user is currently on
   */
  currentPage: number;
  /**
   * The total number of pages
   */
  lastPage?: number;
  /**
   * Callback for clicking a page list item
   */
  onPageClick?: (pageNumber: number) => void;
}

const PageList = ({ currentPage, lastPage, onPageClick = noop }: PageListProps) => {
  const pageNumbers = getPageNumberTemplate(currentPage, lastPage);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
    event.preventDefault();
    onPageClick(pageNumber);
  };

  return (
    <Wrapper>
      <span>Page</span>
      <List>
        {pageNumbers.map((page, index) =>
          page === ELLIPSIS ? (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index}>{page}</span>
          ) : (
            <ListItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              isActive={currentPage === page}
              onClick={(event) => onClick(event, page as number)}
            >
              {page}
            </ListItem>
          )
        )}
      </List>
    </Wrapper>
  );
};

export default PageList;
