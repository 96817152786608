import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import useFloatingChatPrompt from 'hooks/useFloatingChatPrompt';
import ContentPasteSearchSVG from 'images/content_paste_search.svg';
import GroupAddSVG from 'images/group_add.svg';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsBuyer } from 'store/selectors/me/company';
import NoResultsCard from 'storybook/stories/cells/Card/NoResultsCard';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { getPartners } from 'utils/api/partners';

const EmptyOrdersPage = () => {
  const isBuyer = useSelector(selectIsBuyer);

  const fetchingPartner = useQuery({
    queryKey: ['getPartners', { limit: 1 }],
    queryFn: () => getPartners({ limit: 1 }),
  });
  const hasPartner = (fetchingPartner.data?.data || []).length > 0;

  const { tryPrompting } = useFloatingChatPrompt();

  useEffect(() => {
    tryPrompting();
  }, [tryPrompting]);

  if (fetchingPartner.isLoading) return null;

  return (
    <NoResultsCard
      data-testid="empty-page"
      imageSrc={hasPartner ? ContentPasteSearchSVG : GroupAddSVG}
      title={
        hasPartner ? "You don't have any orders yet" : 'Invite a partner to start processing orders'
      }
      description={
        hasPartner
          ? 'Your orders will appear here. In the meantime, you can invite more partners to accelerate your time to the first order and scale your business.'
          : undefined
      }
    >
      <Flex gap="16px">
        <SecondaryButton
          as={SupportLink}
          article={isBuyer ? 'myjn4aiax9' : 'xhpwmqqltq'}
          $iconName="help"
        >
          Guide
        </SecondaryButton>

        <PrimaryButton as={Link} to="/partners" $iconName="group_add">
          Go to Partners
        </PrimaryButton>
      </Flex>
    </NoResultsCard>
  );
};

export default EmptyOrdersPage;
