import config from 'utils/config';

const { isStaging, pathBasename } = config;

const useStagingBuild = (): string | null => {
  if (isStaging && pathBasename) {
    return pathBasename;
  }
  return null;
};

export default useStagingBuild;
