import Flex from '@react-css/flex';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

const GenerateCSVMappingsCard = ({ done, disabled }) => {
  return (
    <GetStartedCard
      isComplete={done}
      title="Create CSV Mappings"
      completedUi={
        <Body as="p">
          Your CSV Mappings have been created. Product syncing should begin shortly.{' '}
          <SupportLink article="delewtxyn5#step_3_2_configure_csv_mappings">More Info</SupportLink>
        </Body>
      }
    >
      {disabled ? (
        <Body as="p">
          Once you finish uploading your product CSV file, above, continue with this step.
        </Body>
      ) : (
        <>
          <Body as="p">
            Before we can start syncing your product data, you&apos;ll need to map the data in your
            product CSV file to Modern Dropship&apos;s product fields. To begin this process, please
            navigate to the Products page.{' '}
            <SupportLink article="delewtxyn5#creating_csv_mappings">More Info</SupportLink>
          </Body>

          <Flex justifyEnd>
            <PrimaryButton size="small" as={Link} to="/products">
              Go to Products
            </PrimaryButton>
          </Flex>
        </>
      )}
    </GetStartedCard>
  );
};

export default GenerateCSVMappingsCard;
