import Grid from '@react-css/grid';
import Button from 'storybook/stories/molecules/Button';
import Icon from 'storybook/stories/molecules/Icon';

import Input from 'storybook/stories/molecules/Input';

const AttributeRow = ({ name, value, onChangeName, onChangeValue, onDelete, canEdit, index }) => (
  <Grid columns="3fr 3fr 1fr" gap="8px" alignItems="center">
    <Grid.Item>
      {canEdit ? (
        <Input
          type="text"
          aria-label={`Edit attribute ${index + 1} name`}
          value={name}
          onChange={(evt) => onChangeName(evt.target.value)}
          placeholder="Add name"
          isFullWidth
        />
      ) : (
        <p className="mb-0" style={{ whiteSpace: 'break-spaces' }}>
          {name}
        </p>
      )}
    </Grid.Item>
    <Grid.Item>
      {canEdit ? (
        <div>
          <Input
            aria-label={`Edit attribute ${index + 1} value`}
            type="text"
            value={value}
            onChange={(evt) => onChangeValue(evt.target.value)}
            placeholder="Add value"
            isFullWidth
          />
        </div>
      ) : (
        <p className="mb-0" style={{ whiteSpace: 'break-spaces' }}>
          {value}
        </p>
      )}
    </Grid.Item>
    <Grid.Item>
      {canEdit && (
        <Icon
          name="delete"
          as={Button}
          color="error700"
          aria-label={`Delete attribute ${index + 1}`}
          onClick={onDelete}
        />
      )}
    </Grid.Item>
  </Grid>
);

export default AttributeRow;
