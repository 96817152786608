import Flex from '@react-css/flex';
import { useQuery } from '@tanstack/react-query';
import _isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useDocumentTitle } from 'usehooks-ts';

import Button from 'components/Common/Button';
import Message, { useMessage } from 'components/Common/Message';
import DashboardPage from 'components/Dashboard/DashboardPage';
import useAlertQueue from 'hooks/useAlertQueue';
import { updateOrder, useFetchOrder } from 'store/slices/ordersV2';
import { getOrderReturns } from 'utils/api/orders';
import { getPartnerByCompanyId } from 'utils/api/partners';
import {
  ACCOUNT_TYPE_SELLER,
  PLATFORM_EDI_EXTERNAL_SFTP,
  PLATFORM_EDI_SFTP,
} from 'utils/constants';

import LoadingOrderPage from 'containers/OrderPage/LoadingOrderPage';
import NoOrderPage from 'containers/OrderPage/NoOrderPage';
import OrderAttributesSeller from 'containers/OrderPage/OrderAttributes';
import OrderCustomFields from 'containers/OrderPage/OrderCustomFields';
import OrderDetails from 'containers/OrderPage/OrderDetails';
import OrderEDIRecords from 'containers/OrderPage/OrderEDIRecords';
import OrderFlaggedAlert from 'containers/OrderPage/OrderFlaggedAlert';
import OrderFulfillments from 'containers/OrderPage/OrderFulfillments';
import OrderHistory from 'containers/OrderPage/OrderHistory';
import { OrderInvoice } from 'containers/OrderPage/OrderInvoice';
import OrderNotes from 'containers/OrderPage/OrderNotes';
import OrderPageHeader from 'containers/OrderPage/OrderPageHeader';
import OrderShippingInfo from 'containers/OrderPage/OrderShippingInfo';
import OrderSummary from 'containers/OrderPage/OrderSummary';
import OrderReturns from 'containers/OrderPage/Returns';
import ShippingLabels from 'containers/OrderPage/ShippingLabels';
import { useLocation, useParams } from 'react-router';
import breakpoints from 'utils/breakpoints';

const PageContainer = styled.div`
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
`;

const FlexContainer = styled(Flex)`
  gap: 32px;
  ${breakpoints({
    values: {
      xs: {
        'flex-direction': 'column',
      },
      sm: {
        'flex-direction': 'column',
      },
      md: {
        'flex-direction': 'column',
      },
      lg: {
        'flex-direction': 'row',
      },
      xl: {
        'flex-direction': 'row',
      },
      xxl: {
        'flex-direction': 'row',
      },
    },
  })}
`;

const OrderPage = () => {
  useDocumentTitle('Order Details');

  const { orderId } = useParams();
  const location = useLocation();
  const mainContentRef = useRef();
  const dispatch = useDispatch();
  const { addErrorAlert } = useAlertQueue();
  const { company, user } = useSelector(({ me }) => me);
  const order = useSelector(({ orders }) => orders.byId[orderId]);
  const { commerceType } = company;
  const { orderLoading, orderLoadingError } = useFetchOrder(dispatch, orderId);
  const { showSuccessMessage, showErrorMessage, messageState, hideMessage } = useMessage();
  const [edits, setEdits] = useState({}); // object representing edited order data
  const [updating, setUpdating] = useState(false);
  const showSave = !_isEmpty(edits);
  const [showBottomSave, setShowBottomSave] = useState(false);
  const backUrl =
    location.state && location.state.backUrlQueryParams
      ? `/orders?${location.state.backUrlQueryParams}`
      : '/orders';

  // flags for which cards to show
  const isSeller = commerceType === ACCOUNT_TYPE_SELLER;
  const partnerCompanyId =
    order && (isSeller ? order.buyerCompanyObjectId : order.sellerCompanyObjectId);
  const isEDIPlatform =
    company.orderPlatform === PLATFORM_EDI_EXTERNAL_SFTP ||
    company.orderPlatform === PLATFORM_EDI_SFTP;

  // effect to show save on bottom if header scrolls out of view
  useEffect(() => {
    let handle;
    const mainContentEl = mainContentRef.current;

    if (showSave) {
      handle = () => {
        if (mainContentEl.scrollTop > 90 && !showBottomSave) {
          setShowBottomSave(true);
        } else if (mainContentEl.scrollTop <= 90 && showBottomSave) {
          setShowBottomSave(false);
        }
      };
      handle();
      mainContentEl.addEventListener('scroll', handle);
    }

    return () => mainContentEl.removeEventListener('scroll', handle);
  }, [showSave, showBottomSave, mainContentRef]);

  const onChangeEdits = (key) => (value) => {
    setEdits({ ...edits, [key]: value });
  };

  const onDiscardEdits = () => {
    setEdits({});
    setShowBottomSave(false);
  };

  const onSaveEdits = () => {
    setUpdating(true);
    dispatch(updateOrder({ id: order._id, updates: edits })).then((action) => {
      if (action.error) {
        showErrorMessage({ title: 'Error updating order', message: action.error.message });
        mainContentRef.current.scrollTo(0, 0);
      } else {
        setEdits({});
      }
      setUpdating(false);
    });
  };

  const { data: partner } = useQuery({
    queryKey: ['orderPartner', partnerCompanyId],
    queryFn: () => getPartnerByCompanyId(partnerCompanyId),
    staleTime: 60000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!partnerCompanyId,
    onError: (err) =>
      showErrorMessage({ message: err.message, title: 'Error fetching order partner' }),
  });

  const fetchingReturns = useQuery({
    queryKey: ['getOrderReturns', orderId],
    queryFn: () => getOrderReturns(orderId),
    onError: (error) => {
      addErrorAlert('Something went wrong', error.message);
      console.error('Unable to fetch returns for order', error);
    },
  });

  const cancelledItems = order && order.items ? order.items.filter((item) => item.cancelled) : [];
  const orderItems = order?.items ?? [];
  const customFields = order && order.custom ? order.custom : [];
  const orderCancelled = cancelledItems.length === orderItems.length;

  return (
    <DashboardPage mainContentRef={mainContentRef}>
      <PageContainer>
        {!order && orderLoading && <LoadingOrderPage />}
        {!order && !orderLoading && <NoOrderPage error={orderLoadingError} />}
        {order && (
          <>
            <OrderPageHeader
              order={order}
              isSeller={isSeller}
              orderCancelled={orderCancelled}
              dispatch={dispatch}
              showSave={showSave}
              onSaveEdits={onSaveEdits}
              updating={updating}
              onDiscardEdits={onDiscardEdits}
              backUrl={backUrl}
            />
            <OrderFlaggedAlert
              order={order}
              isSeller={isSeller}
              dispatch={dispatch}
              showSuccessMessage={showSuccessMessage}
              showErrorMessage={showErrorMessage}
            />
            <Message show={messageState.show} onClose={hideMessage} kind={messageState.kind}>
              <p className="m-0 mb-1">
                <strong>{messageState.title}</strong>
              </p>
              <p className="m-0">{messageState.message}</p>
            </Message>
            <FlexContainer>
              <Flex.Item grow={5}>
                <OrderSummary
                  order={order}
                  returns={fetchingReturns.data?.data}
                  isSeller={isSeller}
                  showErrorMessage={showErrorMessage}
                  orderPlatform={company.orderPlatform}
                  refetchReturns={fetchingReturns.refetch}
                />
                <OrderReturns
                  order={order}
                  isSeller={isSeller}
                  returns={fetchingReturns.data?.data}
                  refetchReturns={fetchingReturns.refetch}
                />
                {isSeller && <ShippingLabels order={order} partner={partner} />}
                <OrderFulfillments
                  order={order}
                  isSeller={isSeller}
                  orderPlatform={company.orderPlatform}
                  dispatch={dispatch}
                  orderCancelled={orderCancelled}
                />
                <OrderInvoice
                  order={order}
                  partner={partner}
                  billedValue={
                    Object.prototype.hasOwnProperty.call(edits, 'billed')
                      ? edits.billed
                      : order.billed
                  }
                  onBilledChange={onChangeEdits('billed')}
                  isSeller={isSeller}
                  user={user}
                  dispatch={dispatch}
                  showErrorMessage={showErrorMessage}
                />
                {isEDIPlatform && <OrderEDIRecords order={order} user={user} />}
                <OrderAttributesSeller order={order} isSeller={isSeller} />
                <OrderHistory orderId={orderId} />
              </Flex.Item>
              <Flex.Item grow={3}>
                <OrderNotes
                  note={
                    Object.prototype.hasOwnProperty.call(edits, 'note') ? edits.note : order.note
                  }
                  onNoteChange={onChangeEdits('note')}
                />
                <OrderShippingInfo order={order} />
                <OrderDetails order={order} company={company} />
                {customFields.length > 0 && <OrderCustomFields customFields={customFields} />}
              </Flex.Item>
            </FlexContainer>
          </>
        )}
      </PageContainer>
      {showSave && showBottomSave && (
        <>
          <div className="list-alert alert alert-dark border fade show" role="alert">
            <div className="row align-items-center">
              <div className="col">
                <p className="mb-0">Unsaved changes</p>
              </div>
              <div className="col-auto">
                <Button size="sm" color="white-20" className="mr-2" onClick={onDiscardEdits}>
                  Discard
                </Button>

                <Button color="primary" size="sm" onClick={onSaveEdits} disabled={updating}>
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div style={{ height: '4rem' }} />
        </>
      )}
    </DashboardPage>
  );
};

export default OrderPage;
