import Flex from '@react-css/flex';
import type { EditablePriceListEntry } from 'containers/PriceListPage/BulkEditing/context';
import { TableViewOptions } from 'containers/PriceListPage/BulkEditing/context';
import { ClickableBodyText } from 'storybook/stories/cells/SearchResult/shared/Styles';
import Body from 'storybook/stories/molecules/Body';
import { ProductImage } from '../shared/ProductImage';

const handleProductClick = (e: React.MouseEvent<HTMLDivElement>, productId: string) => {
  const url = `/products/${productId}`;
  window.open(url, '_blank', 'noopener,noreferrer');
  e.stopPropagation(); // Prevents the row's selected state from being toggled
};

interface RowHeaderProps {
  row: EditablePriceListEntry;
  viewMode: TableViewOptions;
}

const RowStart = ({ row, viewMode }: RowHeaderProps) => {
  return (
    <Flex alignItemsCenter gap="16px" onClick={(e) => handleProductClick(e, row.entry.productId)}>
      <ProductImage alt={`Image for ${row.entry.variant.sku}`} src={row.imgSrc} />

      {viewMode === TableViewOptions.TITLES && (
        <Flex column>
          <ClickableBodyText variant="Body/Regular" color="blue500">
            {row.entry.productTitle}
          </ClickableBodyText>
          <Body variant="Body/Body Small" color="bodyTextSecondary">
            {row.entry.variant.title}
          </Body>
        </Flex>
      )}

      {viewMode === TableViewOptions.SKU && (
        <ClickableBodyText variant="Body/Regular" color="blue500">
          {row.entry.variant.sku}
        </ClickableBodyText>
      )}
    </Flex>
  );
};

export default RowStart;
